(function ($) {
    rter.mapController('password.forgot', function (context) {
        var $submitButton, $loader,
            $successAlert, $errorAlert;

        return {
            actions: {
                submit: function (e) {
                    var isLoading = $submitButton.is(':disabled'),
                        $form = $(this);

                    e.preventDefault();

                    if (isLoading) return false;

                    $submitButton.prop('disabled', true);
                    $loader.show();
                    $form.hide();
                    $successAlert.hide();
                    $errorAlert.hide();

                    ridestyler.ajax.send({
                        action: 'User/ResetPassword',
                        data: {
                            Email: $form.find('#username').val()
                        },
                        callback: function (response) {
                            if (response.Success)
                                $successAlert.show();
                            else {
                                $errorAlert.show();

                                if (response.Message)
                                    $errorAlert.find('span').text(response.Message);
                            }

                            $loader.hide();
                            $form.show();
                            $submitButton.prop('disabled', false);
                        }
                    });
                }
            },

            viewCreated: function ($view) {
                $successAlert = $view.find('.alert-success');
                $errorAlert = $view.find('.alert-danger');
                $loader = $view.find('.loader');
                $submitButton = $view.find('button[type=submit]');
            }
        };
    });

    rter.mapController('password.reset', function (context) {
        var $submitButton, $loader,
            $successAlert, $errorAlert;

        return {
            actions: {
                submit: function (e) {
                    var isLoading = $submitButton.is(':disabled'),
                        $form = $(this),
                        $password = $form.find('#password'),
                        $passwordConfirm = $form.find('#password-confirm');

                    e.preventDefault();

                    if (isLoading) return false;

                    var password = $password.val();
                    if (password != $passwordConfirm.val()) {
                        uifactory.alert.show({
                            text: "Passwords did not match.",
                            type: "error"
                        });

                        if (password.length)
                            $passwordConfirm.focus();
                        else
                            $password.focus();

                        return false;
                    }

                    $submitButton.prop('disabled', true);
                    $loader.show();
                    $form.hide();
                    $successAlert.hide();
                    $errorAlert.hide();

                    ridestyler.ajax.send({
                        action: 'User/ChangePassword',
                        data: {
                            Token: context.state.token,
                            NewPassword: $password.val()
                        },
                        callback: function (response) {
                            if (response.Success) {
                                $successAlert.show();

                                setTimeout(function () {
                                    rter.routes.go('/login');
                                }, 3000);
                            } else $errorAlert.show();

                            $loader.hide();
                            $form.show();
                            $submitButton.prop('disabled', false);
                        }
                    });
                }
            },

            viewCreated: function ($view) {
                $successAlert = $view.find('.alert-success');
                $errorAlert = $view.find('.alert-danger');
                $loader = $view.find('.loader');
                $submitButton = $view.find('button[type=submit]');
            }
        };
    });
})(jQuery);