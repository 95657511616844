(function ($) {
	rter.mapController('app.vehicle.model', function (context) {
		var grid = null;
		var $filterForm, $filterButton;
        var $backToMakesButton;

		var filters = {};

		return {
			viewWillLoad: function(ctx) {
				filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
			},
			viewCreated: function ($view) {
				$filterForm = rstools.vehicle.utils.createVehicleFilterForm(context, {
					onReset: filtersUpdated,
					onSubmit: filtersUpdated,
					filters: filters
				});

				// #region Create the datagrid
				grid =
					datagrid.create({
						container: $view.find('div.data-wrapper'),
						allowShowAll: true,
						fields: [
							{ name: 'VehicleMakeName', caption: 'Make' },
							{ name: 'VehicleModelName', caption: 'Model' },
                            {
                                name: 'VehicleModelNotes',
                                caption: 'Notes',
                                colClass: 'text-center',
                                sort: false,
                                columnRenderer: rstools.notes.createColumnRenderer('vehicle.model'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
                            },
							{ name: 'actions', caption: '', width: 155, sort: false }
						],
						rowCallback: function ($tr, model) {
							if (model.VehicleModelArchived) $tr.addClass('archived-data-object');
						},
						headerActions: [
							rstools.shared.datagrid.headerActions.manageColumns
						],
						getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
						getAction: 'vehicle/GetModelDescriptions',
						countAction: 'vehicle/CountModels',
						resultListField: 'Models',
						baseQueryData: {
							TireOptionRequirement: 0,
							FitmentRequirement: 0
						},
						actions: [
							{
                                title: 'List Configurations',
                                icon: 'fa fa-th-list',
                                id: 'list',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    var filters = {
                                        VehicleModels: []
                                    };

                                    for (var i = 0; i < rows.length; i++)
                                        filters.VehicleModels.push(rows[i].VehicleModelID);

                                    rter.routes.go('/app/vehicle/configuration', { filters: filters });
                                }
                            }
						],
						search: filters,
						namespace: 'vehicle-model',
						rowSelect: function (model) {
							grid.action.getByID('list').callback([model]);
						}
					});
				
				grid.page.on('pageLoaded', function () {
					var resultCount = grid.page.getTotalCount();
					var search = filters.Search && filters.Search.toString();

					if (!resultCount && search && search.indexOf('[') !== 0) {
						uifactory.alert.show('No results found for "' + search + '". Searching by model name...', 'info');
						filters.Search = '[VehicleModelName[LIKE]' + search + ']';

						$filterForm.syncObjectToForm(filters);
						filtersUpdated();
					}
				});

				var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

				$backToMakesButton = $('<a/>', {
                    'class': 'btn btn-info',
                    'append': [
                        uifactory.create.icon('caret-left'),
                        ' Makes'
                    ],
                    'href': '#!/app/vehicle/make'
                }).appendTo($leftButtons);

				$filterButton = uifactory.create.filterButton({
					menu: $filterForm
				})
					.appendTo($leftButtons)
					.trigger('filters-updated', [Object.keys(filters).length]);

				var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

				// rstools.user.isSystemAdmin() && $('<button/>', {
				// 	'class': 'btn btn-primary pull-right',
				// 	'append': [
				// 		'Create Model &nbsp;',
				// 		uifactory.create.icon('plus')
				// 	],
				// 	'appendTo': $rightButtons,
				// 	'click': function() {
				// 		rstools.vehicle.model.create();
				// 	}
				// });
			},
			viewLoaded: function (ctx) {
				// Update filters from the current ctx
				// or grab them from the original context
				filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

                rstools.utils.setBackButtonTargetFromContext($backToMakesButton, ctx, '/app/vehicle/make');

				$filterForm.syncObjectToForm(filters);
			}
		};

		function filtersUpdated() {
			$filterForm && $filterForm.syncFormToObject(filters);
			$filterButton && $filterButton.trigger('filters-updated', [Object.keys(filters).length]);
			console.log(filters.Search);

			grid.search(filters);
			rstools.state.set('filters', filters);
		}
	});
})(jQuery);