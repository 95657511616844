$(document).ready(function () {
    $.reject({
        reject: {
            msie: 8
        }
    });
});

window.onerror = function (message, source, lineNumber, columnNumber, error) {
    uifactory.alert.show(message, 'error');
    return false;
};