var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var rstools;
(function (rstools) {
    var plugins;
    (function (plugins) {
        plugins.registeredPlugins = [];
        plugins.activePlugins = [];
        var uniquePluginIDIncrement = 0;
        ///// Plugin
        var Plugin = /** @class */ (function () {
            function Plugin(settings) {
                this.type = settings.type;
                this.settings = settings;
                this.data = settings.data;
            }
            Plugin.prototype.triggerCallback = function (callback, data) {
                if (typeof callback === 'function')
                    callback.call(this, data);
            };
            Plugin.prototype.onActivated = function (data) {
                this.triggerCallback(this.settings.onActivated, data);
                plugins.activePlugins.push(this);
            };
            Plugin.prototype.onDeactivated = function () {
                this.triggerCallback(this.settings.onDeactivated);
                // Remove this plugin from activePlugins
                for (var index = 0; index < plugins.activePlugins.length; index++) {
                    if (plugins.activePlugins[index] !== this)
                        continue;
                    plugins.activePlugins.splice(index, 1);
                    break;
                }
            };
            Plugin.prototype.notifyRegistered = function () {
                this.triggerCallback(this.settings.onRegistered);
            };
            Plugin.prototype.activate = function (data) {
                this.data = $.extend(this.data || {}, data);
                this.preformActivateAction(data);
                this.onActivated(data);
            };
            /**
             * @virtual
             */
            Plugin.prototype.receiveMessage = function (message) { };
            return Plugin;
        }());
        plugins.Plugin = Plugin;
        ///// Plugin type-checking
        var pluginIs = {
            page: function (plugin) {
                return plugin.type === 'page';
            }
        };
        ///// PagePlugin
        var PagePlugin = /** @class */ (function (_super) {
            __extends(PagePlugin, _super);
            function PagePlugin(settings) {
                var _this = _super.call(this, settings) || this;
                _this.type = 'page';
                _this.settings = settings;
                return _this;
            }
            PagePlugin.prototype.preformActivateAction = function () {
                switch (this.settings.target) {
                    case 'modal':
                        this.showInModal();
                        break;
                    case 'blank':
                        this.showInNewTab();
                        break;
                }
            };
            PagePlugin.prototype.showInNewTab = function () {
                var pluginWindow = window.open(this.settings.url, '_blank');
                pluginWindow.focus();
            };
            PagePlugin.prototype.showInModal = function () {
                var _this = this;
                var iframe = document.createElement('iframe');
                iframe.src = this.settings.url;
                var modal = uifactory.modal.create({
                    body: iframe,
                    footer: false,
                    cancelable: true,
                    destroyOnHidden: true
                });
                modal.$modal
                    .addClass('modal-full')
                    .on("hidden.bs.modal", function () {
                    _this.onDeactivated();
                });
                modal.show();
            };
            return PagePlugin;
        }(Plugin));
        plugins.PagePlugin = PagePlugin;
        ///// event registering
        datagrid.on('gridWillCreate', function (data) {
            var dataGridOptions = data;
            var _loop_1 = function (plugin) {
                var pluginGridSettings = plugin.settings.gridSettings;
                // Skip plugins without grid settings
                if (!pluginGridSettings)
                    return "continue";
                // Convert to array
                pluginGridSettings = $.makeArray(pluginGridSettings);
                // Check each setting in the array
                for (var _i = 0, pluginGridSettings_1 = pluginGridSettings; _i < pluginGridSettings_1.length; _i++) {
                    var _a = pluginGridSettings_1[_i], match = _a.match, actions = _a.actions;
                    // Invalid match type
                    if (typeof match !== 'object')
                        continue;
                    var settingsMatch = true;
                    for (var key in match) {
                        if (!match.hasOwnProperty(key))
                            continue;
                        // Every key must be in the data grid's options and have the same value
                        if (key in dataGridOptions === false || match[key] !== dataGridOptions[key]) {
                            settingsMatch = false;
                            break;
                        }
                    }
                    // Skip datagrids with non-matching settings
                    if (!settingsMatch)
                        continue;
                    // Add in our plugin's actions
                    {
                        var gridActions = dataGridOptions.actions || [];
                        var _loop_2 = function (action) {
                            var oldCallback = action.callback;
                            action.callback = function (rows) {
                                oldCallback.call(plugin, rows);
                            };
                            gridActions.push(action);
                        };
                        for (var _b = 0, actions_1 = actions; _b < actions_1.length; _b++) {
                            var action = actions_1[_b];
                            _loop_2(action);
                        }
                        dataGridOptions.actions = gridActions;
                    }
                }
            };
            for (var _i = 0, registeredPlugins_1 = plugins.registeredPlugins; _i < registeredPlugins_1.length; _i++) {
                var plugin = registeredPlugins_1[_i];
                _loop_1(plugin);
            }
        });
        function register(pluginSettings) {
            var plugin;
            if (pluginSettings.type === 'page')
                plugin = new PagePlugin(pluginSettings);
            else
                throw "Invalid plugin type";
            plugins.registeredPlugins.push(plugin);
            plugin.notifyRegistered();
            return plugin;
        }
        plugins.register = register;
        function connect(win, plugin, origin) {
            if (!win || typeof win.postMessage !== 'function')
                return console.error('Invalid plugin window to connect to');
            var env = window['environment'];
            if (env === 'debug')
                env = 'alpha';
            else if (env === 'live')
                env = '';
            plugin.id = ++uniquePluginIDIncrement;
            var message = rstools.messaging.formatMessage({
                namespace: 'rstools-plugin',
                name: 'connect',
                data: {
                    environment: env,
                    data: plugin.data,
                    id: plugin.id
                }
            });
            win.postMessage(message, origin || getOrigin(plugin.settings.url));
        }
        plugins.connect = connect;
        function receiveMessage(message) {
            if (message.name === 'connect') {
                for (var _i = 0, activePlugins_1 = plugins.activePlugins; _i < activePlugins_1.length; _i++) {
                    var plugin = activePlugins_1[_i];
                    if (pluginIs.page(plugin)) {
                        var connectURL = message.data.url;
                        if (urlsMatch(connectURL, plugin.settings.url)) {
                            // This plugin is a page plugin with the same origin as the message
                            connect(message.source, plugin, message.origin);
                        }
                    }
                }
                return;
            }
            // The message namespace has more than one part,
            // the message has an ID as the second part
            if (message.namespaceParts.length > 1) {
                var pluginID = parseInt(message.namespaceParts[1]);
                for (var _a = 0, activePlugins_2 = plugins.activePlugins; _a < activePlugins_2.length; _a++) {
                    var plugin = activePlugins_2[_a];
                    if (plugin.id === pluginID) {
                        plugin.receiveMessage(message);
                        break;
                    }
                }
            }
            for (var _b = 0, activePlugins_3 = plugins.activePlugins; _b < activePlugins_3.length; _b++) {
                var plugin = activePlugins_3[_b];
                if (pluginIs.page(plugin)) {
                    if (plugin.settings.url.indexOf(message.origin) === 0) {
                        // This plugin is a page plugin with the same origin as the message
                        connect(message.source, plugin, message.origin);
                    }
                }
            }
        }
        plugins.receiveMessage = receiveMessage;
        ///// Private functions
        function getURLBase(url) {
            url = url.replace(/[#?].*/, '');
            if (url.charAt(url.length - 1) === '/')
                url = url.substr(0, url.length - 1);
            return url;
        }
        function getFullURL(url) {
            if (url.charAt(0) === '/')
                url = location.origin + url;
            return url;
        }
        function urlsMatch(a, b) {
            a = getFullURL(a);
            b = getFullURL(b);
            return getURLBase(a) === getURLBase(b);
        }
        function getOrigin(url) {
            var originMatch = url.match(/^.+?[^\/:](?=[?\/]|$)/);
            return originMatch ? originMatch[0] : undefined;
        }
    })(plugins = rstools.plugins || (rstools.plugins = {}));
})(rstools || (rstools = {}));
;
