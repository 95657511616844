(function ($) {
    rter.mapController('app.boltpattern', function (context) {
        var grid = null;
        var $filterForm, $filterButton;

        var filters = {};

        return {
            viewWillLoad: function(ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($view) {
                $filterForm = uifactory.create.filterForm({
                    filters: filters,

                    allowShowAll: true,

                    headerActions: [
                        rstools.shared.datagrid.headerActions.manageColumns
                    ],
                    getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,

                    fields: [
                        {
                            name: 'Search',
                            type: 'text-search',
                            label: 'Search',
                            required: false
                        },
                        {
                            label: 'Bolt Count',
                            required: false,
                            group: 'Bolt Pattern',
                            name: 'BoltCount',
                            type: 'number'
                        },
                        {
                            label: 'Spacing (mm)',
                            required: false,
                            name: 'SpacingMillimeters',
                            group: 'Bolt Pattern',
                            parseAsRange: true,
                            labelHintSummary: 'Range',
                            labelHintText: 'You may enter a range into this filter.'
                        },
                        {
                            label: 'Spacing (inches)',
                            required: false,
                            name: 'SpacingInches',
                            group: 'Bolt Pattern',
                            parseAsRange: true,
                            labelHintSummary: 'Range',
                            labelHintText: 'You may enter a range into this filter.'
                        },
                        { 
                            name: 'BoltPatternDataStatus', 
                            caption: 'Status',
                            colClass: 'text-center',
                            requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                            visible: false,
                            format: rstools.datastatus.createColumn('boltpattern')
                        },
                        {
                            name: 'BoltPatterNotes',
                            caption: 'Notes',
                            colClass: 'text-center',
                            sort: false,
                            columnRenderer: rstools.notes.createColumnRenderer('boltpattern'),
                            requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                            visible: false
                        }
                    ],
                    onSubmit: filtersUpdated,
                    onReset: filtersUpdated
                });

                // #region Create the datagrid
                grid =
                    datagrid.create({
                        container: $view.find('div.data-wrapper'),
                        allowShowAll: true,

						headerActions: [
							rstools.shared.datagrid.headerActions.manageColumns
						],
						getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,

                        fields: [
                            { name: 'BoltPatternDescription', caption: 'Description' },
                            { name: 'BoltPatternBoltCount', caption: 'Bolt Count'},
                            { name: 'BoltPatternSpacingMM', caption: "Spacing (mm)"},
                            { name: 'BoltPatternSpacingIN', caption: "Spacing (inches)"},
                            { name: 'actions', caption: '', width: 100, sort: false }
                        ],
                    rowCallback: function ($tr, pricingGroup) {
                            if (pricingGroup.PricingGroupArchived) $tr.addClass('archived-data-object');
                        },
                        actions: [
                           
                        ],
                        getAction: 'BoltPattern/GetBoltPatterns',
                        countAction: 'BoltPattern/CountBoltPatterns',
                        resultListField: 'BoltPatterns',
                        baseQueryData: {},
                        search: filters,
                        namespace: 'boltpattern'
                    });
                // #endregion

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .prependTo(grid.topControls)
                    .trigger('filters-updated', [Object.keys(filters).length]);

                var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

                $('<button/>', {
                    'class': 'btn btn-primary pull-right',
                    'append': [
                        'Create Pricing Group &nbsp;',
                        uifactory.create.icon('plus')
                    ],
                    'appendTo': $rightButtons,
                    'click': function () {
                        rstools.pricing.group.create();
                    }
                });
            },
            viewLoaded: function (ctx) {
                // Update filters from the current ctx
                // or grab them from the original context
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

                $filterForm.syncObjectToForm(filters);
            }
        };

        function filtersUpdated() {
            $filterForm && $filterForm.syncFormToObject(filters);
            $filterButton && $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(filters);
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);