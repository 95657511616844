(function ($) {
    rter.mapController('app.organization.list', function (context) {
        var grid = null;
        var filters = {};
        var $breadcrumbs;

        var SUBSCRIPTION_STATUS = {
            1: "Active",
            2: "Expired",
            3: "Cancelled",
            4: "Suspended"
        };

        return {
            actions: {
                search: function () {
                    filters.search = $(this).find('input.search-input').val();
                    if (!filters.search && typeof filters.search !== 'undefined')
                        delete filters.search;

                    grid.search(filters);
                    filtersUpdated();
                },
                create: function () {
                    rstools.organization.create({
                        Organization_OrganizationID: filters.ParentOrganizationID || rstools.auth.user.getActiveOrganizationID()
                    })
                },
                reset: function () {
                    rter.url.set("/app/organization/list", {});

                    grid.search({
                        OrganizationID: rstools.auth.user.getActiveOrganizationID()
                    });

                    updateBreadcrumbs([]);
                }
            },
            viewCreated: function ($view) {
                filters = $.extend(filters, context.state ? context.state['filters'] : {});

                $view.find('input.search-input').val(rstools.state.get('search'));
                $breadcrumbs = $view.find('.breadcrumb ol').on('click', 'button', function () {
                    var $this = $(this),
                        $li = $this.closest('li'),
                        $prevLi = $li.prevAll('li');

                    var breadcrumbs = $prevLi.find('button').add($this).map(function () {
                        var $crumb = $(this);
                        return $crumb.text() + "-" + $crumb.data('organization-id')
                    }).get();

                    goToOrganization({
                        OrganizationID: $this.data('organization-id'),
                        OrganizationName: $this.text()
                    }, breadcrumbs);
                });

                grid =
                    datagrid.create({
                        container: $view.find('div.data-wrapper'),
                        selectEnabled: false,
                        fields: [
                            { name: 'OrganizationName', caption: 'Name' },
                            { name: 'OrganizationContactEmail', caption: 'Contact Email' },
                            { name: 'actions', caption: '', width: 155, sort: false, colClass: "text-center" },
                            {
                                name: 'Subscription',
                                width: 120,
                                sort: false,
                                colClass: "text-center",
                                format: function (r) {
                                    var subscription = r.Subscription,
                                        status = subscription && SUBSCRIPTION_STATUS[subscription.OrganizationSubscriptionStatus] || "None",
                                        cls = status == "Active" ? "-success" : "-default";

                                    if (false && rstools.auth.user.hasRole(rstools.roles.SYSTEM_ADMIN))
                                        return $("<button/>", {
                                            "class": "btn btn-block btn" + cls,
                                            "text": status
                                        }).click(function () {
                                            rter.routes.go('/app/organization/subscription', {
                                                filters: {
                                                    OrganizationID: r.OrganizationID
                                                }
                                            })
                                        });
                                    else
                                        return $("<span/>", { "class": "label large label" + cls, "text": status });
                                }
                            }
                        ],
                        actions: [
                            {
                                title: 'View Suborganizations',
                                icon: 'fa fa-arrow-down',
                                multiple: false,
                                fields: ['actions'],
                                callback: function (rows) {
                                    goToOrganization(rows[0]);
                                }
                            },
                            {
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: false,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.organization.edit(rows[0].OrganizationID);
                                }
                            },
                            {
                                title: 'Create a Suborganization',
                                icon: 'fa fa-plus',
                                multiple: false,
                                forMenu: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.organization.create({
                                        Organization_OrganizationID: rows[0].OrganizationID
                                    });
                                }
                            },
                            {
                                title: 'Create User',
                                icon: 'fa fa-user',
                                multiple: false,
                                forMenu: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.user.create({
                                        User_OrganizationID: rows[0].OrganizationID
                                    });
                                }
                            },
                            {
                                title: 'Manage Logos',
                                icon: 'fa fa-picture-o',
                                multiple: false,
                                forMenu: false,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.organization.manageLogos(rows[0].OrganizationID);
                                }
                            },
                            {
                                title: 'Delete Organization',
                                icon: 'fa fa-trash',
                                menuClassName: 'dropdown-menu-item-danger',
                                multiple: false,
                                forMenu: true,
                                fields: ['actions'],
                                callback: function(rows) {
                                    var org = rows[0];
                                    var deleteRequest = {
                                        action: 'Organization/Delete',
                                        data: {
                                            OrganizationID: org.OrganizationID
                                        }
                                    };

                                    var modal = uifactory.create.confirmActionModal({
                                        titles: [org.OrganizationName],
                                        verb: 'delete',
                                        noun: 'organization',
                                        actionRequest: deleteRequest,
                                        showFailureAlert: false,
                                        description: 'This will delete all settings and preferences associated with the organization. Deleting an organization cannot be undone.',
                                        verifyPhrase: 'Delete',
                                        onActionCompleted: function() {
                                            rstools.events.dataChanged('organization', 'deleted');
                                        },
                                        onActionFailed: function(response) {
                                            if (response.Code === 602) {
                                                modal.shake();
                                                modal.$body.append($('<div/>', {
                                                    'class': 'alert alert-danger',
                                                    'text': 'There are users within this organization, are you sure you want to delete it?',
                                                    'css': {
                                                        margin: '20px 20px 0'
                                                    }
                                                }));
                                                modal.$footer.find('.btn-danger').text('Yes, delete with users');
                                                deleteRequest.data.DeleteUsers = true;
                                            } else {
                                                uifactory.alert.show({
                                                    type: 'error',
                                                    text: org.OrganizationName + ' could not be deleted: ' + response.Message
                                                });
                                            }
                                        }
                                    });

                                    modal.show();
                                }
                            }
                        ],
                        getAction: 'Organization/GetOrganizationDescriptions',
                        countAction: 'Organization/CountOrganizations',
                        resultListField: 'Organizations',
                        search: $.extend(filters.ParentOrganizationID ? undefined : {
                            OrganizationID: rstools.auth.user.getActiveOrganizationID()
                        }, filters),
                        rowCallback: function (tr, r) {
                            if (!r.ChildOrganizationCount)
                                tr.find('.fa-arrow-down').parent().prop('disabled', true);
                        }
                    });

                updateBreadcrumbs();
            }
        };

        function goToOrganization(o, breadcrumbs) {
            filters.ParentOrganizationID = o.OrganizationID;
            
            if (!breadcrumbs) {
                breadcrumbs = getBreadcrumbs();
                breadcrumbs.push(o.OrganizationName + '-' + o.OrganizationID);
            }

            var urlData = rter.url.get();
            rter.routes.go(urlData.path, $.extend(urlData.state, {
                b: breadcrumbs.join('_'),
                filters: filters
            }));

            grid.search(filters);
            updateBreadcrumbs();
        }


        function filtersUpdated() {
            rstools.state.set('filters', filters);
        }

        function getBreadcrumbs() {
            var breadcrumbs = rstools.state.get('b') || "";
            breadcrumbs = breadcrumbs ? breadcrumbs.split('_') : [];

            return breadcrumbs;
        }

        function updateBreadcrumbs(breadcrumbs) {
            breadcrumbs = breadcrumbs || getBreadcrumbs();

            $breadcrumbs
                .empty()
                .append($.map(breadcrumbs, function (b, i) {
                    var $li = $("<li/>");

                    b = b.split('-');

                    var text = b.slice(0, -1).join('-'),
                        id = b[b.length - 1];

                    $("<button/>", {
                        "class": "btn " + (i ? "btn-default" : "btn-success"),
                        "text": text,
                        "data-organization-id": id
                    }).appendTo($li);

                    return $li;
                }))
                .parent().toggleClass('hide', !breadcrumbs.length)
        }
    });
})(jQuery);