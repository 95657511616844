rter.routes.set({
	name: 'index',
	controller: controller.index,

	routes: [
		{
			name: 'login',
			controller: controller.login
		},

		{
			name: 'logout',
			controller: controller.logout,
			requireSubscription: false
		},

		{
			name: 'password',
			routes: [
				{
					name: "forgot",
					controller: controller.password.forgot
				},
				{
					name: "reset",
					controller: controller.password.reset
				}
			]
		},
		{
			name: 'app',
			controller: controller.app,
			routes: [
				{
					name: 'account',
					routes: [
						{
							name: 'subscription',
							requiredRoles: rstools.roles.ORGANIZATION_ADMIN,
							controller: controller.app.account.subscription,
							requireSubscription: false
						},
						{
							name: 'user',
							requireSubscription: false,
							routes: [
								{
									name: 'settings',
									controller: controller.app.account.user.settings
								}
							]
						},
						{
							name: 'organization',
							requiredRoles: rstools.roles.ORGANIZATION_ADMIN,
							routes: [
								{
									name: 'settings',
									controller: controller.app.account.organization.settings
								}
							]
						}
					]
				},
				{
					name: 'admin',
					requiredRoles: rstools.roles.SYSTEM_ADMIN,
					routes: [
						{
							name: 'account',
							routes: [
								{
									name: 'create',
									controller: controller.app.admin.account.create
								}
							]
						},
						{
							name: 'resource',
							controller: controller.app.admin.resource
						}
					]
				},
				{
					name: 'dashboard',
					controller: controller.app.dashboard
				},
				{
					name: 'dashboard-beta',
					controller: controller.app.dashboard.beta,
					requiredRoles: rstools.roles.SYSTEM_ADMIN
				},
				{
					name: 'organization',
					requiredRoles: rstools.roles.ORGANIZATION_ADMIN,
					routes: [
						{
							name: 'user',
							controller: controller.app.organization.user
						},
						{
							name: 'key',
							controller: controller.app.organization.key,
							routes: [
								{
									name: 'create',
									controller: controller.app.organization.key.create
								},
								{
									name: 'edit',
									controller: controller.app.organization.key.edit
								}
							]
						},
						{
							name: 'pricing',
							controller: controller.app.organization.pricing
						},
						{
							name: 'create',
							controller: controller.app.organization.create
						},
						{
							name: 'list',
							controller: controller.app.organization.list
						},
						{
							name: 'edit',
							controller: controller.app.organization.edit
						},
						{
							name: 'subscription',
							requiredRoles: rstools.roles.SYSTEM_ADMIN,
							controller: controller.app.organization.subscription
						},
						{
							name: 'shard',
							requiredRoles: rstools.roles.SYSTEM_ADMIN,
							controller: controller.app.organization.shard
						},
						{
							name: 'import',
							requiredRoles: rstools.roles.SYSTEM_ADMIN,
							controller: controller.app.organization.import
						},
						{
							name: 'promotion',
							controller: controller.app.organization.promotion
						},
						{
							name: 'datasource',
							requiredRoles: rstools.roles.SYSTEM_ADMIN,
							controller: controller.app.organization.datasource
						}
					]
				},
				{
					name: 'setup',
					controller: controller.app.setup
				},
				{
					name: 'data',
					requiredRoles: rstools.roles.SYSTEM_ADMIN,
					routes: [
						{
							name: 'aces',
							controller: controller.app.data.aces,
							hideFooter: true
						},
						{
							name: 'wheelpros',
							controller: controller.app.data.wheelpros,
							hideFooter: true
						}
					]
				},
				{
					name: 'wheel',
					routes: [
						{
							name: 'brand',
							controller: controller.app.wheel.brand,
							routes: [
								{
									name: 'sort',
									controller: controller.app.wheel.brand.sort
								}
							]
						},
						{
							name: 'model',
							controller: controller.app.wheel.model
						},
						{
							name: 'fitment',
							controller: controller.app.wheel.fitment
						},
						{
							name: 'promo',
							requiredRoles: rstools.roles.WHEELS,
							controller: controller.app.wheel.promo
						},
						{
							name: 'pricing',
							routes: [
								{
									name: 'fitment',
									controller: controller.app.wheel.pricing.fitment
								},
								{
									name: 'model',
									controller: controller.app.wheel.pricing.model
								}
							]
						}
					]
				},
				{
					name: 'tire',
					routes: [
						{
							name: 'brand',
							controller: controller.app.tire.brand
						},
						{
							name: 'model',
							controller: controller.app.tire.model
						},
						{
							name: 'fitment',
							controller: controller.app.tire.fitment
						},
						{
							name: 'size',
							controller: controller.app.tire.size
						},
						{
							name: 'pricing',
							routes: [
								{
									name: 'fitment',
									controller: controller.app.tire.pricing.fitment
								},
								{
									name: 'model',
									controller: controller.app.tire.pricing.model
								}
							]
						}
					]
				},
				{
				    name: 'message',
				    routes: [
						{
						    name: 'channel',
						    controller: controller.app.message.channel
						},
						{
						    name: 'list',
						    controller: controller.app.message.list
						}
				    ]
				},
				{
					name: 'report',
					routes: [
						{
							name: 'start',
							controller: controller.app.report.start
						}
					]
				},
				{
					name: 'vehicle',
					routes: [
						{
							name: 'make',
							controller: controller.app.vehicle.make
						},
						{
							name: 'model',
							controller: controller.app.vehicle.model
						},
						{
							name: 'configuration',
							controller: controller.app.vehicle.configuration
						},
						{
							name: 'fitment',
							controller: controller.app.vehicle.fitment
						}
					]
				}
			]
		}
	]
}, false);