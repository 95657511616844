(function ($) {
    rter.mapController('app.wheel.pricing.model', function (context) {
        var grid = null;
        var $filterForm, $filterButton;
        var filters = {};

        return {
            viewWillLoad: function (ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($view) {
                $filterForm = uifactory.create.filterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });

                grid =
                    datagrid.create({
                        container: $view.find('div.data-wrapper'),
                        allowShowAll: true,

                        headerActions: [
                            rstools.shared.datagrid.headerActions.manageColumns
                        ],
                        getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
                        fields: [
                            { name: 'WheelBrandName', caption: 'Brand' },
                            { name: 'DataSourceDescription', caption: 'Data Source', sort: false },
                            { name: 'WheelModelName', caption: 'Model' },
                            { name: 'WheelModelFinishDescription', caption: 'Finish' },
                            {
                                name: '!Price',
                                caption: 'Price',
                                format: function (r) {
                                    return rstools.pricing.format.prices(rstools.pricing.utils.getBestPricingType(r.Pricing));
                                }
                            },
                            {
								name: 'WheelModelNotes',
								caption: 'Notes',
								colClass: 'text-center',
								sort: false,
								columnRenderer: rstools.notes.createColumnRenderer('wheel.model'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
							},
                            { name: 'actions', caption: '', width: 100, sort: false, colClass: 'text-center' }
                        ],
                        actions: [
                            {
                                title: 'View Fitments',
                                id: 'list',
                                icon: 'fa fa-th-list',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rter.routes.go('/app/wheel/pricing/fitment', {
                                        filters: {
                                            PricingGroupID: filters.PricingGroupID,
                                            WheelModels: rstools.wheel.model.utils.getIDs(rows)
                                        }
                                    });
                                }
                            }
                        ],
                        
                        getAction: 'Wheel/GetModelDescriptions',
                        countAction: 'Wheel/CountModelDescriptions',
                        resultListField: 'Models',
                        baseQueryData: {
                            SelectBrandsOnly: false,
                            IncludePricing: true,
                            HasPricing: true,
                            HasFitments: false,
                            UsePromotionOrder: false,
                            UseBrandPriorities: false
                        },

                        search: filters,
                        namespace: 'wheel-model',
                        rowSelect: function (model) {
                            grid.action.getByID('list').callback([model]);                            
                        }
                    });

                $filterForm = rstools.pricing.utils.createFilterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });

                var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .appendTo($leftButtons)
                    .trigger('filters-updated', [Object.keys(filters).length]);
            },
            viewLoaded: function (ctx) {
                $filterForm.syncObjectToForm(filters);
            }
        };
    });

    function filtersUpdated() {
        $filterForm.syncFormToObject(filters);
        $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

        grid.search(filters);
        rstools.state.set('filters', filters);
    }
})(jQuery);