(function ($) {
    window.rstools.setup = function () {
        var cachedSetupStatus = false;

        var InclusionState = rstools.constants.InclusionState;

        function alert(text, type) {
            uifactory.alert.show({
                type: type || 'error',
                text: text
            });
        }

        function getSettings() {
            return rstools.data.provider.get({
                preferredProvider: 'organizationsettings',
                namespace: 'SetupSettings'
            });
        }

        function generateURL(interfaceType, accessType, settings) {
            return rstools.utils.generateInterfaceURL(interfaceType, accessType === 'website' ? settings.get('websiteKey') : undefined);
        }

        function toggleBrands () {
            var id = this.id;
            var type = id.indexOf('wheel') >= 0 ? 'wheel' : 'tire';
            var action = id.substr(0, 3) === 'all' ? 'addClass' : 'removeClass';
            var $list = $(this).closest('#choose-' + type + '-brands-toolbar').next().children('.selection-list');

            $list.children().not('.locked')[action]('selected');
            $list.change();
        }

        function completeBrandStep(settings, $container, $saveButton) {
            var type = $saveButton.prop('id').indexOf('wheel') >= 0 ? 'wheel' : 'tire',
                typeu = type.substr(0, 1).toUpperCase() + type.substr(1);

            $saveButton.prop('disabled', true);

            var $list = $container.find('.selection-list');
            var list = $list.data('selectionList');

            $('#choose-' + type + '-brands-toolbar', $container).hide();
            $list.parent().addClass('loading');

            var brands = $list.data('brands');
            var selectedBrandIDs = list.value();
            var enabledBrands = [], disabledBrands = [];

            for (var i = 0; i < brands.length; i++) {
                var brand = brands[i];
                var brandIDKey = typeu + 'BrandID';
                var brandID = brand[brandIDKey];

                if (rstools.inclusion.locked(brand)) continue;

                var brandEnabled = false;
                for (var j = 0; j < selectedBrandIDs.length; j++) {
                    if (selectedBrandIDs[j] === brandID) {
                        brandEnabled = true;
                        break;
                    }
                }

                var brandSum = {};
                brandSum[brandIDKey] = brandID;

                if (brandEnabled) enabledBrands.push(brandSum);
                else disabledBrands.push(brandSum);
            }

            var brandsEnabled = enabledBrands.length ? rstools.utils.apiDeferred({
                action: typeu + '/ChangeBrandInclusion',
                data: {
                    Objects: enabledBrands,
                    Status: InclusionState.Optional
                }
            }) : $.Deferred().resolve();

            var brandsDisabled = disabledBrands.length ? rstools.utils.apiDeferred({
                action: typeu + '/ChangeBrandInclusion',
                data: {
                    Objects: disabledBrands,
                    Status: InclusionState.None
                }
            }) : $.Deferred().resolve();

            return $.when(brandsEnabled, brandsDisabled)
                .always(function () {
                    $saveButton.prop('disabled', false);
                    $list.show();
                })
                .done(function() {
                    settings.set(type + 'BrandsSetup', true);
                    alert('Your ' + type + ' brands have been saved.', 'success');
                    $('#choose-' + type + '-brands-toolbar', $container).show();
                })
                .fail(function(response) {
                    alert("There was a problem saving your " + type + " brand preferences: " + response.Message);
                });
        }

        function updateBrandCount($brandCount, count) {
            var t = (count || 'No') + ' brand';
            if (count != 1) t += 's';

            $brandCount.text(
                count ?
                    count == 1 ? '1 brand selected' : count + ' brands selected' :
                    'No brands selected'
            );
        }

        function createBrandList(options) {
            var type = options.type,
                typeu = type.substr(0, 1).toUpperCase() + type.substr(1);

            var $container = options.$container;
            var $listContainer = $container.find('#choose-' + type + '-brands');
            var $saveButton = $container.find('#save-' + type + '-list');
            var $brandCount = $container.find('#selected-' + type + '-brand-count');

            var listLoaded = $.Deferred();
            var $list = uifactory.create.selectionList({
                options: {
                    action: typeu + '/GetBrandDescriptions',
                    data: {
                        SelectBrandsOnly: false
                    },
                    responseKey: 'Brands',
                    buildOptions: function (brands) {
                        $list.data('brands', brands);
                        return $.map(brands, function (brand) {
                            return {
                                label: function () {
                                    var li = this;
                                    var name = document.createTextNode(brand[typeu + 'BrandName']);

                                    if (options.logo) {
                                        var logo = document.createElement('img');
                                        var spacer = document.createElement('span');

                                        spacer.style.height = '55px';
                                        spacer.style.width = '115px';
                                        spacer.style.display = 'inline-block';
                                        spacer.style.verticalAlign = 'middle';

                                        var logoSettings = {
                                            Width: 100,
                                            Height: 55,
                                            PositionX: 1,
                                            PositionY: 1
                                        };

                                        logoSettings[typeu + 'Brand'] = brand[typeu + 'BrandID'];
                                        logoSettings[typeu + 'BrandResourceType'] = 'Logo';

                                        logo.src = '/images/gui/transparent.gif';
                                        logo.setAttribute('data-original', ridestyler.ajax.url(typeu + '/BrandImage', logoSettings));

                                        logo.style.marginRight = '15px';
                                        logo.style.width = '100px';
                                        logo.style.height = '55px';

                                        logo.onerror = function () {
                                            li.removeChild(logo);
                                        };

                                        logo.onload = function () {
                                            li.removeChild(spacer);
                                            logo.onerror = logo.onload = undefined;
                                        };

                                        li.appendChild(spacer);
                                        li.appendChild(logo);
                                    }

                                    li.appendChild(name);

                                    if (rstools.inclusion.locked(brand)) {
                                        var lock = document.createElement('button');

                                        lock.className = 'circle-icon-button circle-icon-button-locked';
                                        lock.disabled = true;
                                        lock.title = "This brand is " + (rstools.inclusion.enabled(brand) ? 'required' : 'disabled') + " by a parent organization";
                                        lock.style.position = 'absolute';
                                        lock.style.right = '15px';
                                        lock.style.top = '50%';
                                        lock.style.marginTop = '-13px';

                                        li.appendChild(lock);
                                        li.className += ' locked';
                                        li.onclick = function () { alert("This brand cannot be " + (rstools.inclusion.enabled(brand) ? "removed" : "added") + " because it is required by a parent organization."); };
                                    }
                                },
                                value: brand[typeu + 'BrandID']
                            };
                        });
                    }
                },
                onLoad: function () {
                    if (options.logo) {
                        $listContainer.find('img').lazyload({
                            container: $listContainer
                        });
                    }

                    listLoaded.resolve();
                },
                valueType: 'array'
            }).appendTo($listContainer);
            var list = $list.data('selectionList');

            ridestyler.ajax.send({
                action: typeu + '/GetBrands',
                callback: function (response) {
                    listLoaded.done(function () {
                        var brands = response.Brands;

                        $list.children().each(function () {
                            var brandID = this.getAttribute('data-value');

                            for (var i = brands.length - 1; i >= 0; i--) {
                                if (brands[i][typeu + 'BrandID'] === brandID) {
                                    $(this).addClass('selected');
                                    return;
                                }
                            }

                            $(this).removeClass('selected');
                        });

                        $list.change().show();
                        $('#choose-' + type + '-brands-toolbar', $container).show();

                        updateBrandCount($brandCount, brands.length);

                        $listContainer
                            .removeClass('loading')
                            .trigger('scroll');
                    });
                }
            });

            $list.on('change', function () {
                $saveButton.prop('disabled', !list.hasSelections());
                updateBrandCount($brandCount, $list.children('.selected').length);
            });
        }

        function filterBrands() {
            var $filterInput = $(this);
            var type = this.id.indexOf('wheel') >= 0 ? 'wheel' : 'tire';
            var $toolbar = $filterInput.closest('#choose-' + type + '-brands-toolbar');
            var $scrollContainer = $toolbar.next();
            var $list = $scrollContainer.find('.selection-list');
            var search = this.value.toLowerCase();
            var $brandRows = $list.children();
            var $noResultsEl = $list.data('noResultsEl');

            if ($noResultsEl) {
                $noResultsEl.remove();
                $list.removeData('noResultsEl');
                $noResultsEl = undefined;
            }

            if (!search) $brandRows.show();
            else {
                var filteredCount = 0;
                $brandRows.each(function() {
                    var $this = $(this);
                    var text = $this.text().toLowerCase();
                    var visible = text.indexOf(search) >= 0;

                    visible && filteredCount++;

                    $this[visible ? 'show' : 'hide']();
                });

                if (!filteredCount) {
                    $list.data('noResultsEl', $('<li/>', {
                        'append': [
                            'No brands match your search. ',
                            $('<u/>', {
                                'text': 'Clear your search'
                            })
                        ],
                        'appendTo': $list,
                        'click': function() {
                            $filterInput.val('');
                            filterBrands.call($filterInput[0]);
                        }
                    }));
                }
            }

            $scrollContainer.trigger('scroll');
        }

        return {
            /**
             * Returns a promise that resolves with the status of whether or 
             * not setup is complete.
             *
             * @param   {IRstoolsDataProvider} settings  Settings to use to detect completion or nothing to retrieve them
             *
             * @return  {jQuery.Deferred}                A promise that resolves with a boolean dictating whether or not the organization
             *                                           has completed setup or rejects on error.
             */
            isComplete: function (settings) {
                var d = new $.Deferred();

                if (cachedSetupStatus) return d.resolve(true);

                if (!settings) settings = getSettings();

                var legacyKeys = ["PUBSET_TOOLS_QUICKSETUP_API", "PUBSET_TOOLS_QUICKSETUP_BRANDS", "PUBSET_TOOLS_QUICKSETUP_ORG"];

                // First do a request to see if the legacy setup has been completed
                ridestyler.ajax.send({
                    action: 'Organization/GetSettings',
                    data: {
                        Keys: legacyKeys
                    },
                    callback: function(response) {
                        if (!response.Success) return d.reject(response);

                        var legacySettings = response.Settings;

                        var legacySetupComplete = true;

                        // Legacy setup is complete if all legacy setup keys are "true"
                        for (var i = 0; i < legacyKeys.length; i++) {
                            if (legacySettings[legacyKeys[i]] !== "true") {
                                legacySetupComplete = false;
                                break;
                            }
                        }

                        // If legacy setup is complete, setup is complete
                        if (legacySetupComplete) return d.resolve(true);

                        // Retrieve setup settings
                        settings.ensureCache()
                            .done(function () {
                                var setupProgress = rstools.setup.getProgress(settings);
                                var setupComplete = (setupProgress.progress / setupProgress.total) >= 1;

                                if (setupComplete) cachedSetupStatus = true;

                                d.resolve(setupComplete);
                            })
                            .fail(function () {
                                d.reject();
                            });
                    }
                });

                return d;
            },

            /**
             * Hides or shows the banner
             *
             * @param  {boolean}  bannerVisible  If true, show the banner, otherwise
             *                                   hide it.
             */
            setBannerVisible: function (bannerVisible) {
                var $banner = $('.banner-notification');

                if (bannerVisible) $banner.fadeIn();
                else $banner.hide();

                $('body').toggleClass('banner-visible', bannerVisible);
            },

            /**
             * Updates the setup banner's visibility, detecting wheter or not the organization
             * has hidden it and if his organization has completed it.
             */
            updateBanner: function () {
                var path = rter.url.get().path;

                rstools.setup.setBannerVisible(false);

                // If we're in the app controller and not on the setup page
                if (path.indexOf('/app/') === 0 && path.indexOf('/app/setup') < 0) {
                    var settings = getSettings();

                    settings.retrieveCache()
                        .done(function () {
                            var hideBanner = settings.get('hideBanner');

                            // If the organization settings say to hide the banner, hide it
                            if (hideBanner) rstools.setup.setBannerVisible(false);
                            else {
                                // Get the status of whether or not setup is complete
                                rstools.setup.isComplete(settings)
                                    .done(function (isComplete) {
                                        // Show the banner if setup isn't complete
                                        rstools.setup.setBannerVisible(!isComplete);
                                    });
                            }
                        });
                }
            },

            /**
             * Hide the banner and set it to not show up again
             */
            hideBanner: function () {
                getSettings().set('hideBanner', true);

                rstools.setup.setBannerVisible(false);
            },    

            steps: [
                {
                    container: 'select-product-types',
                    initialize: function($container) {
                        $container.on('ifToggled', '#product-preferences-form', function() {
                            var $form = $(this);

                            // Submit button is enabled if at least one checkbox is checked
                            $container.find('#save-product-preferences').prop('disabled', $form.find(':checked').length <= 0);
                        });
                    },
                    isComplete: function(settings) {
                        var selectedProducts = settings.get('selectedProducts');
                        return selectedProducts && selectedProducts.length;
                    },
                    clearComplete: function(settings) { settings.remove('selectedProducts'); },
                    complete: function(settings, $container) {
                        var wheelsPreference = $container.find('#wheels-preference').prop('checked');
                        var tiresPreference = $container.find('#tires-preference').prop('checked');

                        var selectedProducts = [];

                        if (wheelsPreference) selectedProducts.push('wheels');
                        if (tiresPreference) selectedProducts.push('tires');

                        settings.set('selectedProducts', selectedProducts);
                    }
                },
                {
                    container: 'setup-wheel-brands',
                    initialize: function($container) {
                        createBrandList({
                            $container: $container,
                            type: 'wheel',
                            logo: true
                        });

                        $container
                            .on('click', '#all-wheels,#no-wheels', toggleBrands)
                            .on('keyup', '#choose-wheels-filter', filterBrands);
                    },
                    onShown: function($container, settings) {
                        $container.find('#choose-wheel-brands').trigger('scroll');
                    },
                    willApply: function (settings) {
                        var selectedProducts = settings.get('selectedProducts');

                        if (!selectedProducts) return undefined;
                        return $.inArray('wheels', selectedProducts) >= 0;
                    },
                    applies: function (settings) {
                        var selectedProducts = settings.get('selectedProducts');
                        return selectedProducts && $.inArray('wheels', selectedProducts) >= 0;
                    },
                    isComplete: function(settings) {
                        return settings.get('wheelBrandsSetup');
                    },
                    clearComplete: function (settings) { settings.remove('wheelBrandsSetup'); },
                    complete: completeBrandStep
                },
                {
                    container: 'setup-tire-brands',
                    initialize: function($container) {
                        createBrandList({
                            $container: $container,
                            type: 'tire'
                        });

                        $container
                            .on('click', '#all-tires,#no-tires', toggleBrands)
                            .on('keyup', '#choose-tires-filter', filterBrands);
                    },
                    willApply: function (settings) {
                        var selectedProducts = settings.get('selectedProducts');

                        if (!selectedProducts) return undefined;
                        return $.inArray('tires', selectedProducts) >= 0;
                    },
                    applies: function (settings) {
                        var selectedProducts = settings.get('selectedProducts');
                        return selectedProducts && $.inArray('tires', selectedProducts) >= 0;
                    },
                    isComplete: function (settings) {
                        return settings.get('tireBrandsSetup');
                    },
                    clearComplete: function (settings) { settings.remove('tireBrandsSetup'); },
                    complete: completeBrandStep
                },
                {
                    container: 'setup-website-access',
                    initialize: function ($container, settings) {
                        var update = function() {
                            var key = settings.get('websiteKey');
                            var interfaceType = settings.get('websiteInterface');

                            if (key) $('#your-website-key', $container).removeClass('loading').text(key);

                            if (key && interfaceType) {
                                $container.find('#api-key-next').prop('disabled', false);

                                var url = generateURL(interfaceType, 'website', settings);

                                $container.find('#setup-website-url').val(url);
                                $container.find('#setup-website-url-open').attr({
                                    target: '_blank',
                                    href: url
                                }).removeClass('disabled');
                            }
                        };
                        var selectAPIKey = function (key) {
                            settings.set('websiteKey', key);
                            update();
                        };

                        $container.on('ifChecked', 'input', function () {
                            settings.set('websiteInterface', this.value);
                            update();
                        });

                        ridestyler.ajax.send({
                            action: 'ApiAccessKey/GetKeys',
                            data: {
                                Organization: rstools.auth.user.getActiveOrganizationID(),
                                Count: 1
                            },
                            callback: function (response) {
                                if (response.Success && response.Keys.length) selectAPIKey(response.Keys[0].ApiAccessKeyValue);
                                else {
                                    ridestyler.ajax.send({
                                        action: 'ApiAccessKey/CreateKey',
                                        data: {
                                            OrganizationID: rstools.auth.user.getActiveOrganizationID()
                                        },
                                        callback: function (createResponse) {
                                            if (createResponse.Success) selectAPIKey(createResponse.Key.ApiAccessKeyValue);
                                            else alert("<strong>There was a problem creating the key:</strong> " + createResponse.Message);
                                        }
                                    });
                                }

                            }
                        });
                    },
                    onSettingsLoaded: function($container, settings) {
                        // Select a user selected interface type
                        var interfaceType = settings.get('websiteInterface');

                        interfaceType && $container.find('input[name=interface-type]').each(function () {
                            if (this.value === interfaceType) {
                                $(this).iCheck('check');

                                return false;
                            }
                        });
                    },
                    willApply: function () {
                        var subscription = rstools.user.subscription;
                        return subscription && subscription.OrganizationSubscriptionSessionCount > 0;
                    },
                    clearComplete: function (settings, $container) {
                        settings.remove('websiteInterface');

                        $container.find('input[name=interface-type]').iCheck('uncheck');
                        $container.find('#api-key-next').prop('disabled', true);
                        $container.find('#setup-website-url').val('');
                        $container.find('#setup-website-url-open').removeAttr('href').removeAttr('target').addClass('disabled');
                    },
                    isComplete: function (settings) {
                        var key = settings.get('websiteKey');
                        return typeof key === 'string' && key.length >= 32 && settings.get('websiteInterface');
                    }
                },
                {
                    container: 'setup-instore-access',
                    initialize: function ($container, settings) {
                        $container.find('#num-user-licenses').text(rstools.user.subscription.OrganizationSubscriptionUserCount);
                        $container.find('#your-username').text(rstools.user.email);

                        $container.on('ifChecked', 'input', function () {
                            var interfaceType = this.value;

                            settings.set('instoreInterface', interfaceType);
                            $container.find('#instore-next').prop('disabled', false);

                            var url = generateURL(interfaceType, 'store', settings);

                            $container.find('#setup-instore-url').val(url);
                            $container.find('#setup-instore-url-open').attr({
                                target: '_blank',
                                href: url
                            }).removeClass('disabled');
                        });
                    },
                    willApply: function () {
                        var subscription = rstools.user.subscription;
                        return subscription && subscription.OrganizationSubscriptionUserCount > 0;
                    },
                    isComplete: function (settings) { return settings.get('instoreInterface'); },
                    clearComplete: function(settings, $container) {
                        settings.remove('instoreInterface');

                        $container.find('input[name=interface-type]').iCheck('uncheck');
                        $container.find('#instore-next').prop('disabled', true);
                        $container.find('#setup-instore-url').val('');
                        $container.find('#setup-instore-url-open').removeAttr('href').removeAttr('target').addClass('disabled');
                    }
                },
                {
                    container: 'account-setup-finished',
                    initialize: function($container, settings) {
                        $('#setup-email-us', $container).get(0).href += '%20(%23' + rstools.auth.user.getActiveOrganizationID() + ')';
                    },
                    update: function($container, settings) {
                        var $summary = $('#setup-summary', $container),
                            $colContainer = $('#setup-summary-columns', $container).empty();

                        var instoreInterface = settings.get('instoreInterface'), hasInstoreInterface = !!instoreInterface,
                            websiteInterface = settings.get('websiteInterface'), hasWebsiteInterface = !!websiteInterface,
                            interfaceCount = hasInstoreInterface + hasWebsiteInterface;

                        $summary.append([
                            'You have setup ',
                            $('<strong/>', {text: interfaceCount}),
                            ' ways of displaying the RideStyler interface:'
                        ]);

                        var columnWidth = 12 / interfaceCount;
                        var buildInterfaceColumn = function (interfaceType, accessType) {
                            var $col = $('<div/>', { 'class': 'col-xs-' + columnWidth });

                            $('<h4/>', {
                                'text': accessType === 'store' ? 'In-store' : 'On your website',
                                'css': {
                                    marginBottom: 5
                                },
                                'appendTo': $col
                            });

                            $('<p/>', {
                                'appendTo': $col,
                                'text': accessType === 'store' ?
                                    'Use this link in your in-store kiosks' :
                                    'Display or use this link on your website'
                            });

                            var $form = $('<div/>', { 'class': 'form-horizontal' }).appendTo($col);
                            
                            if (accessType === 'store') {
                                $('<div/>', {
                                    'class': 'form-group',
                                    'append': [
                                        $('<div/>', { 'class': 'col-sm-3 control-label', 'text': 'Username' }),
                                        $('<div/>', { 'class': 'col-sm-8 form-control-static', 'text': rstools.user.email })
                                    ],
                                    'appendTo': $form
                                });
                            } else {
                                $('<div/>', {
                                    'class': 'form-group',
                                    'append': [
                                        $('<div/>', { 'class': 'col-sm-3 control-label', 'text': 'Website Key' }),
                                        $('<div/>', { 'class': 'col-sm-8 form-control-static', 'text': settings.get('websiteKey') })
                                    ],
                                    'appendTo': $form
                                });
                            }

                            var url = generateURL(interfaceType, accessType, settings);
                            $('<div/>', {
                                'class': 'form-group',
                                'append': [
                                    $('<div/>', { 'class': 'col-sm-3 control-label', 'text': 'Interface URL' }),
                                    $('<div/>', {
                                        'class': 'col-sm-8',
                                        'append': $('<div/>', {
                                            'class': 'input-group',
                                            'append': [
                                                $('<input/>', {
                                                    'type': 'url',
                                                    'class': 'form-control',
                                                    'readonly': true,
                                                    'value': url,
                                                    'append': uifactory.create.icon('link')
                                                }),
                                                $('<a/>', {
                                                    'class': 'input-group-btn btn btn-primary',
                                                    'target': '_blank',
                                                    'href': url,
                                                    'append': uifactory.create.icon('link')
                                                })
                                            ]
                                        })
                                    })
                                ],
                                'appendTo': $form
                            });

                            $col.appendTo($colContainer);
                        };

                        if (hasWebsiteInterface) buildInterfaceColumn(websiteInterface, 'website');
                        if (hasInstoreInterface) buildInterfaceColumn(instoreInterface, 'store');
                    }
                }
            ],
            getNextStep: function(settings) {
                var steps = rstools.setup.steps;

                for (var i = 0; i < steps.length; i++) {
                    var step = steps[i];

                    if (typeof step.willApply === 'function' && !step.willApply(settings)) continue;
                    if (typeof step.applies === 'function' && !step.applies(settings)) continue;
                    if (!step.isComplete || !step.isComplete(settings)) return step;
                }
                return undefined;
            },
            getProgress: function(settings) {
                var steps = rstools.setup.steps;
                var progress = 0;
                var count = 0;

                for (var i = 0; i < steps.length; i++) {
                    var step = steps[i];

                    if (typeof step.willApply === 'function') {
                        var willApply = step.willApply(settings);

                        if (willApply === false) continue;
                    }

                    if (typeof step.isComplete === 'function') {
                        if (step.isComplete(settings)) progress++;
                        count++;
                    }
                }

                return {
                    progress: progress,
                    total: count
                };
            }
        };
    }();
})(jQuery);