(function () {
    var SubscriptionInterval = rstools.constants.SubscriptionInterval;

    function subscriptionIntervalToPeriod(interval) {
        switch (interval) {
            case SubscriptionInterval.Monthly:
                return "per month";
            case SubscriptionInterval.Weekly:
                return "per week";
            case SubscriptionInterval.Yearly:
                return "per year";
        }
    }

    function formatMoney(money) {
        if (isNaN(money)) return '?';
        if (typeof money === 'string') money = parseFloat(money);
        return '$' + money.toFixed(2);
    }

    rstools.subscription = {
        update: function (settings) {
            settings = $.extend({
                title: 'Update Subscription'
            }, settings);

            var modal = uifactory.modal.create({
                header: settings.title,
                cancelable: true,
                footer: false
            });

            var onError = function(message) {
                uifactory.alert.show({
                    type: 'error',
                    text: message
                });

                modal.$modal.removeClass('loading');
            };

            var $body = modal.$body;
            var $selectionContainer = $('<div/>').appendTo($body);
            var $verificationContainer = $('<div/>').appendTo($body).hide();
            var $entryContainer = $('<div/>').appendTo($body).hide();

            // Plan Selection
            var $planDropdown;
            var plans;
            {
                // Instructions
                {
                    var instructionContainer = document.createElement('p');
                    instructionContainer.appendChild(document.createTextNode("How do you plan on using RideStyler for your organization? Choose an option from the dropdown below:"));
                    $selectionContainer.append(instructionContainer);
                }

                // Plan Dropdown
                {
                    $planDropdown = uifactory.create.dropdown({
                        buttonClass: 'btn-default btn-block'
                    }).addClass('btn-block').appendTo($selectionContainer);

                    ridestyler.ajax.send({
                        action: 'Subscription/Plans',
                        callback: function (response) {
                            plans = response.Results;

                            $planDropdown.trigger('rs-dropdown-loaded', [[
                            {
                                label: 'Choose a plan',
                                value: false
                            }, '-'].concat($.map(plans, function (plan) {
                                return {
                                    label: plan.Description,
                                    append: $('<div/>', { 'class': 'small', 'text': '$' + plan.BaseFee + ' ' + subscriptionIntervalToPeriod(plan.Interval) }),
                                    value: plan.Code
                                }
                            }), true)]);
                        }
                    });
                }

                // Selected plan display
                {
                    var planDisplay = document.createElement('dl');
                    planDisplay.className = 'dl-horizontal large';
                    planDisplay.style.marginBottom = '10px';

                    var $planContainer = $('<div/>', {
                        'class': 'clearfix',
                        'css': {
                            'marginTop': 10,
                            'display': 'none'
                        }
                    }).append(planDisplay);

                    var addPlanAttribute = function (title, type) {
                        var term = document.createElement('dt');
                        var desc = document.createElement('dd');
                        var label = document.createElement('span');

                        term.appendChild(document.createTextNode(title));
                        label.className = 'label label-' + (type || 'success');
                        desc.appendChild(label);

                        planDisplay.appendChild(term);
                        planDisplay.appendChild(desc);

                        return $(label);
                    };

                    var $locationsValue = addPlanAttribute("Locations");
                    var $requestsValue = addPlanAttribute("Web Visualizer Credits");
                    var $priceValue = addPlanAttribute("Price", 'default');

                    $planDropdown.on('change', function () {
                        var selectedPlanCode = $.data(this, 'rs-dropdown').selectedOption.value;
                        var selectedPlan;

                        for (var i = 0; i < plans.length; i++) {
                            if (plans[i].Code === selectedPlanCode) {
                                selectedPlan = plans[i];
                                break;
                            }
                        }

                        if (!selectedPlan) {
                            $planContainer.slideUp();
                            return;
                        }

                        $locationsValue.text(selectedPlan.AllowedUserCount);
                        $requestsValue.text(selectedPlan.AllowedRequestCount);
                        $priceValue.text('$' + selectedPlan.BaseFee + ' ' + subscriptionIntervalToPeriod(selectedPlan.Interval));

                        $planContainer.slideDown();
                    });

                    uifactory.create.form({
                        fields: [
                            {
                                name: 'CouponCode',
                                label: 'Coupon Code',
                                required: false
                            }
                        ],
                        actions: [
                            {
                                buttonClass: 'btn btn-primary',
                                action: 'submit',
                                label: 'Continue'
                            }
                        ],
                        beforeSubmit: function (data) {
                            modal.$modal.addClass('loading');

                            var plan = {
                                RequestedPlan: $planDropdown.data('rs-dropdown').selectedOption.value
                            };

                            if (data.CouponCode) plan.CouponCode = data.CouponCode;

                            ridestyler.ajax.send({
                                action: 'Subscription/Estimate',
                                data: plan,
                                callback: function (estimate) {
                                    if (!estimate.Success) return onError(estimate.Message);

                                    updatePlanEstimate(estimate);

                                    modal.$modal.removeClass('loading');

                                    $selectionContainer.hide();
                                    $verificationContainer.show();
                                }
                            })
                        }
                    }).appendTo($planContainer);

                    $selectionContainer.append($planContainer);
                }
            }

            // Plan Verification
            function updatePlanEstimate(estimate) {
                var isNew = !estimate.SubscriptionEstimateCurrentTotal;
                
                var instructions = {
                    'new': 'Please verify your new subscription:',
                    'changed': 'Please verify the following changes to your subscription:'
                };

                $('<p/>', { text: instructions[isNew ? 'new' : 'changed'], appendTo: $verificationContainer });

                var $changeList = $('<dl/>', { 'class': 'dl-horizontal large', appendTo: $verificationContainer });

                if (!isNew) {
                    $changeList.append($('<dt/>', { text: 'Previous:' }));
                    $changeList.append($('<dd/>', { text: formatMoney(estimate.SubscriptionEstimateCurrentTotal) }));
                }

                $changeList.append($('<dt/>', { text: 'Total:' }));
                $changeList.append($('<dd/>', { text: formatMoney(estimate.SubscriptionEstimateNewTotal) + ' per month' }));

                $changeList.append($('<dt/>', { text: 'Discount:' }));

                $changeList.append($('<dd/>', {
                    text: '-' + formatMoney(estimate.SubscriptionEstimateTotalDiscount) + '*',
                    title: estimate.SubscriptionEstimateCouponDescription
                }));

                $changeList.append($('<dt/>', { text: 'Due Now:' }));
                $changeList.append($('<dd/>', { text: formatMoney(estimate.SubscriptionEstimateTotalDue) }));

                var $buttons = $('<div/>', { 'class': 'text-right' }).appendTo($verificationContainer);

                uifactory.create.button({
                    'class': 'btn-primary',
                    'text': 'Continue'
                }).appendTo($buttons).on('click', function () {
                    $verificationContainer.hide();
                    $entryContainer.show();
                });
            }

            // Credit card entry
            {
                $('<p/>', { text: 'Please enter your payment info below:', appendTo: $entryContainer });

                uifactory.create.form({
                    fields: [
                        {
                            name: 'CardHolderName',
                            label: 'Name on Card',
                            placeholder: 'Enter card holder\'s name ...'
                        },
                        {
                            name: 'CardNumber',
                            label: 'Card Number',
                            type: 'cc-number',
                            placeholder: 'Enter credit card number ...'
                        },

                        {
                            name: 'CardExpiration',
                            label: 'Card Expiration',
                            type: 'cc-expiry',
                            placeholder: 'Expiration Date (MM/YY) ...'
                        },
                        {
                            name: 'CardCVC',
                            label: 'Card CVC',
                            type: 'cc-cvc',
                            placeholder: 'Security Code ...'
                        }
                    ],
                    actions: [
                        {
                            buttonClass: 'btn btn-primary',
                            action: 'submit',
                            label: 'Continue'
                        }
                    ],
                    beforeSubmit: function (data) {
                        var expDate = $.payment.cardExpiryVal(data.CardExpiration);

                        modal.$modal.addClass('loading');

                        Stripe.card.createToken({
                            name: data.CardHolderName,
                            number: data.CardNumber,
                            exp_month: expDate.month,
                            exp_year: expDate.year,
                            cvc: data.CardCVC
                        }, function (status, stripeResponse) {
                            if (stripeResponse.error) {
                                modal.$modal.removeClass('loading');

                                return uifactory.alert.show({
                                    text: stripeResponse.error,
                                    type: 'error'
                                });
                            }

                            ridestyler.ajax.send({
                                action: "Subscription/Modify",
                                data: {
                                    RequestedPlan: $planDropdown.data('rs-dropdown').selectedOption.value,
                                    PaymentToken: stripeResponse.id,
                                    CouponCode: $body.find('[name=CouponCode]').val()
                                },
                                callback: function (response) {
                                    if (response.Success) {
                                        modal.hide();

                                        uifactory.alert.show({
                                            text: "Your subscription was successfully updated.",
                                            type: 'success'
                                        });

                                        location.reload();
                                    } else {
                                        modal.$modal.removeClass('loading');

                                        uifactory.alert.show({
                                            text: "Error processing payment with server",
                                            type: 'error'
                                        });
                                    }
                                }
                            });
                        });
                    }
                }).appendTo($entryContainer);
            }

            modal.show();
        },
        cancel: function() {
            var modal = uifactory.modal.create({
                header: 'Cancel Subscription',
                cancelable: true,
                body: [
                    $('<h4/>', {
                        text: 'Are you sure you would like to cancel your subscription?'
                    }),
                    $('<p/>', {
                        append: $('<strong/>', {
                            text: 'Your service will stop working once your current paid subscription period has ended.'
                        })
                    })
                ],
                actions: [
                    {
                        'title': 'No',
                        'callback': function() {
                            modal.hide();
                        }
                    },
                    {
                        'title': 'Yes, cancel my subscription',
                        'class': 'btn btn-danger',
                        'callback': function () {
                            modal.$modal.addClass('loading');

                            ridestyler.ajax.send({
                                action: "Subscription/Modify",
                                data: {
                                    RequestedCancellation: true
                                },
                                callback: function (response) {
                                    if (response.Success) {
                                        modal.hide();

                                        uifactory.alert.show({
                                            text: "Your subscription was successfully updated. Your account will be cancelled at the end of the current period.",
                                            type: 'success'
                                        });

                                        location.reload();
                                    } else {
                                        modal.$modal.removeClass('loading');

                                        uifactory.alert.show({
                                            text: "Error processing payment with server",
                                            type: 'error'
                                        });
                                    }
                                }
                            });
                        }
                    }
                ]
            });

            modal.show();
        }
    };
})();