(function ($) {
    function createDataSourceForm(type, settings) {
        var url;

        settings = $.extend({
            data: {},
            filters: {},
            submitSuccessCallback: undefined,
            onCancel: undefined
        }, settings);

        var data = settings.data;

        switch (type) {
            case "edit":
                url = 'DataSource/EditDataSource';
                break;
            case "create":
                url = 'DataSource/CreateDataSource';
                break;
        }

        if (typeof url !== 'string') return undefined;

        return uifactory.create.form({
            url: url,
            submitErrorMessage: 'There was a problem ' + (type === 'create' ? 'creating' : 'editing') + ' the data source: {Message}.',

            submitSuccessCallback: function () {
                var verbPast = (type === 'create' ? 'created.' : 'edited.');

                uifactory.alert.show({
                    text: "The data source has been " + verbPast
                });

                if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback();
            },

            fields: [
                {
                    label: 'Name',
                    name: 'DataSourceDescription',
                    helpText: 'The name of the data source',
                    maxLength: 50,
                    value: data.DataSourceDescription
                },
                {
                    label: 'Short Code',
                    name: 'DataSourceCode',
                    maxLength: 5,
                    helpText: 'A short 5 or less character code describing the data source',
                    value: data.DataSourceCode
                },
                {
                    label: 'Organization',
                    name: "DataSourceOwner_OrganizationID",
                    value: data.DataSourceOwner_OrganizationID,
                    helpText: 'The organization that the data source will be created under',
                    type: 'static',
                    ridestyler: {
                        textKey: 'OrganizationName',
                        responseKey: 'Organizations',
                        action: 'Organization/GetOrganizations',
                        data: {
                            OrganizationID: data.DataSourceOwner_OrganizationID
                        }
                    }
                }
            ],
            baseData: settings.filters,

            actions: [
                {
                    label: type === 'create' ? 'Create' : 'Save',
                    icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
                    buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
                    action: 'submit'
                },

                {
                    label: 'Cancel',
                    action: settings.onCancel
                }
            ]
        });
    }

    rstools.datasource = {
        create: function (dataSourceData) {
            if (!rstools.user.isSystemAdmin()) throw "You must be a system admin to create a data shard.";

            dataSourceData = dataSourceData || {};
            dataSourceData.DataSourceOwner_OrganizationID = rstools.auth.user.getActiveOrganizationID();

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: 'Create a Data Source',
                body: createDataSourceForm('create', {
                    data: dataSourceData,
                    submitSuccessCallback: function () {
                        rstools.events.dataChanged('datasource', 'create');
                        modal.hide();
                    },
                    onCancel: function () {
                        modal.hide();
                    }
                })
            });

            modal.show();
        },
        edit: function (dataSourceData) {
            if (!rstools.user.isSystemAdmin()) throw "You must be a system admin to edit a data shard.";

            var dataSourceLoaded = rstools.utils.apiDeferred({
                action: 'DataSource/GetDataSources',
                responseKey: 'DataSources',
                data: {
                    DataSource: dataSourceData.DataSourceID
                }
            }).done(function(dataSources) {
                dataSourceData = dataSources[0];
            });

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: $('<h4/>')
            });

            modal.$modal.addClass('loading');

            $.when(dataSourceLoaded)
                .done(function () {
                    modal.$modal.removeClass('loading');

                    modal.settings.header.html("<strong>Edit Data Source:</strong> " + dataSourceData.DataSourceCode);

                    createDataSourceForm('edit', {
                        data: dataSourceData,
                        filters: {
                            DataSourceID: dataSourceData.DataSourceID
                        },
                        submitSuccessCallback: function () {
                            rstools.events.dataChanged('datasource', 'edit');
                            modal.hide();
                        },
                        onCancel: function () {
                            modal.hide();
                        }
                    }).appendTo(modal.$body);

                    modal.setDisplayID(dataSourceData.DataSourceID);
                })
                .fail(function () {
                    modal.hide();
                    uifactory.alert.show({ type: 'error', text: 'There was an error loading information about the data source.' });
                });

            modal.show();
        }
    };
})(jQuery);