(function ($) {
    rter.mapController('app.account.user.settings', function (context) {
        return {
            viewWillCreate: function ($view) {
                var user = rstools.auth.user.get('User');

                uifactory.create.form({
                    formClass: 'form-horizontal',

                    fields: [
                        {
                            label: 'Email',
                            name: 'UserEmail',
                            disabled: true,
                            value: user.UserEmail
                        }
                    ],
                    actions: [
                        {
                            label: 'Reset Password',
                            buttonClass: 'btn btn-danger',
                            action: function () {
                                ridestyler.ajax.send({
                                    action: 'User/ResetPassword',
                                    data: {
                                        Email: user.UserEmail
                                    },
                                    callback: function (response) {
                                        uifactory.alert.show({ text: 'An email containing instructions on resetting your password has been requested.' });
                                    }
                                });

                                return false;
                            }
                        }
                    ]
                }).appendTo($view.find('.well'));
            }
        };
    });
})(jQuery);