(function ($) {
    rter.mapController('app.wheel.fitment', function (context) {
        var grid = null;
        var $filterForm, $filterButton;
        var $createButton;
        var $backToModelsButton;

        var $headerPaste;

        var filters = {};

        var createCombinedFilters = function (rows, forAPI, loosenFilters) {
            return rstools.utils.getFiltersFor('wheel.fitment', {
                rows: rows,
                forAPI: forAPI,
                loosenFilters: loosenFilters,
                filters: filters
            });
        };

        return {
            viewWillLoad: function (ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($view) {
                $filterForm = rstools.wheel.utils.createWheelFilterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });

                //Create Fitment data grid
                grid =
                    datagrid.create({
                        container: $view.find('div.data-wrapper'),
                        allowShowAll: true,
                        allowCutCopy: true,

                        headerActions: [
                            rstools.shared.datagrid.headerActions.manageColumns
                        ],
                        getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,

                        fields: [
                            { name: 'WheelFitmentPartNumber', format: function(r) {
                                return r.PartNumber || $('<span/>', { 'class': 'text-muted', 'text': 'None' });
                            }, caption: 'Part #', colClass: 'breakAll' },
                            { name: 'WheelBrandName', caption: 'Brand', sort: false },
                            { name: 'DataSourceDescription', caption: 'Data Source', sort: false },
                            { name: 'WheelModelName', caption: 'Model', sort: false },
                            { name: 'WheelModelFinishDescription', caption: 'Finish', sort: false },
                            {
                                name: 'WheelFitmentBoltPattern',
                                caption: 'Bolt Pattern',
                                sort: false,
                                format: function (r) {
                                    return rstools.utils.addAbbreviations(r.BoltPatternDescription, [
                                        ['"', 'in', 'inches'],
                                        ["mm", "millimeters"]
                                    ]);
                                }
                            },
                            {
                                name: 'WheelFitmentResource',
                                caption: 'Images',
                                colClass: 'no-padding',
                                sort: false,
                                columnRenderer: function ($tds, data) {
                                    return rstools.resource.utils.columnRenderer($tds, data, {
                                        resourceMatchesObject: function (fitmentResource, fitment) {
                                            return fitment.WheelFitmentID === fitmentResource.WheelFitmentResource_WheelFitmentID;
                                        },
                                        getResourceType: function (fitmentResource) {
                                            return rstools.constants.getString(rstools.constants.WheelFitmentResourceType, fitmentResource.WheelFitmentResourceType);
                                        },
                                        getResourceID: function (fitmentResource) {
                                            return fitmentResource.WheelFitmentResource_ResourceID;
                                        },
                                        showStatusOnly: !rstools.user.isSystemAdmin(),
                                        request: {
                                            action: 'Wheel/GetFitmentResources',
                                            responseKey: 'Resources',
                                            data: {
                                                UsePromotionOrder: false,
                                                HasFitments: false,
                                                SelectBrandsOnly: false,
                                                WheelFitments: rstools.wheel.fitment.utils.getIDs(data)
                                            }
                                        },
                                        resourceTypes: ['Side', 'Angled', 'Catalog']
                                    });
                                }
                            },
                            {
                                name: 'WheelFitmentDiameterMin',
                                caption: 'Diameter',
                                format: function (r) {
                                    return r.DiameterMin == r.DiameterMax ?
                                    [r.DiameterMin, uifactory.create.abbr('in', 'inches')] :
                                    [r.DiameterMin, uifactory.create.abbr('in', 'inches'), ' - ', r.DiameterMax, uifactory.create.abbr('in', 'inches')];
                                }
                            },
                            {
                                name: 'WheelFitmentWidthMin',
                                caption: 'Width',
                                format: function (r) {
                                    return r.WidthMin == r.WidthMax ?
                                    [r.WidthMin, uifactory.create.abbr('in', 'inches')] :
                                    [r.WidthMin, uifactory.create.abbr('in', 'inches'), ' - ', r.WidthMax, uifactory.create.abbr('in', 'inches')];
                                }
                            },
                            {
                                name: 'WheelFitmentOffsetMin',
                                caption: 'Offset',
                                format: function (r) {
                                    if (r.OffsetMin == r.OffsetMax) return rstools.utils.format.offset(r.OffsetMin);

                                    var v = rstools.utils.format.offset(r.OffsetMin);
                                    v.push(' - ');
                                    v = v.concat(rstools.utils.format.offset(r.OffsetMax));

                                    return v;
                                }
                            },
                            {
                                name: 'MaxWeight',
                                caption: 'Max Weight',
                                format: function(r) {
                                    if (r.MaxWeight < 0) return $('<span/>', { 'class': 'text-muted', 'text': 'None' });
                                    return [r.MaxWeight, uifactory.create.abbr('lbs', 'pounds')];
                                },
                                visible: false
                            },
                            {
                                name: 'WheelFitmentCenterboreMM',
                                caption: 'Centerbore',
                                format: function (r) {
                                    var cb = r.CenterboreMM;

                                    if (cb)
                                        return [cb, uifactory.create.abbr('mm', 'millimeters')];

                                    return $('<span/>', { 'class': 'text-muted', 'text': 'N/S' });
                                }
                            },
                            {
                                name: 'WheelCenterCapType',
                                caption: 'Center Cap Type',
                                visible: false,
                                format: ['WheelCenterCapType.WheelCenterCapTypeName']
                            },
                            {
                                name: 'WheelHoleType',
                                caption: 'Hole Type',
                                visible: false,
                                format: ['WheelHoleType.WheelHoleTypeName']
                            },
                            {
                                name: 'WheelFitmentDataStatus', 
                                caption: 'Status',
                                colClass: 'text-center',
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false,
                                format: rstools.datastatus.createColumn('wheel.fitment')
                            },
                            rstools.shared.columns.createLastUpdatedColumn('WheelFitmentUpdated'),
                            {
                                name: 'WheelFitmentNotes',
                                caption: 'Notes',
                                colClass: 'text-center',
                                sort: false,
                                columnRenderer: rstools.notes.createColumnRenderer('wheel.fitment'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
                            },
                            { name: 'actions', caption: '', width: 50, sort: false }
                        ],
                        actions: [
                            {
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: true,
                                forMenu: true,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                callback: function (rows) {
                                    rstools.wheel.fitment.edit(createCombinedFilters(rows, true));
                                }
                            },
                            {
                                title: 'Images',
                                icon: 'fa fa-picture-o',
                                multiple: true,
                                forMenu: true,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                callback: function (rows) {
                                    rstools.wheel.fitment.manageResources(createCombinedFilters(rows, true));
                                }
                            },
                            rstools.shared.actions.createArchiveAction("wheel.fitment", {
								defaultFilters: rstools.wheel.utils.loosenFilters('fitments')
							}),
                            rstools.shared.actions.createUnarchiveAction("wheel.fitment", {
								defaultFilters: rstools.wheel.utils.loosenFilters('fitments')
							}),
                            {
                                title: 'Duplicate',
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                icon: 'fa fa-clone',
                                multiple: true,
                                fields: ['actions'],
                                forMenu: true,
                                callback: function (rows) {
                                    rstools.wheel.fitment.duplicate(createCombinedFilters(rows, true));
                                }
                            },
                            {
                                title: 'Manage Promotions',
                                icon: 'fa fa-tags',
                                multiple: true,
                                forMenu: true,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.WHEELS],
                                callback: function (rows) {
                                    rstools.promotion.manage('wheel.fitment', createCombinedFilters(rows, true));
                                }
                            },
                            {
                                title: 'Edit Pricing',
                                icon: 'fa fa-usd',
                                multiple: true,
                                forMenu: true,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.WHEELS],
                                callback: function (rows) {
                                    rstools.pricing.edit({
                                        action: 'Wheel/GetPrices',
                                        countAction: 'Wheel/CountPrices',
                                        updateAction: 'Wheel/EditPrice',
                                        createAction: 'Wheel/CreatePrice',
                                        deleteAction: 'Wheel/DeletePrice',
                                        namespace: 'wheel-fitment-pricing', 
                                        filters: createCombinedFilters(rows, true, true)
                                    });
                                }
                            },
                            rstools.meta.actions.edit('wheel.fitment'),
                            rstools.datastatus.actions.markValid('wheel.fitment'),
                            rstools.datastatus.actions.markInvalid('wheel.fitment'),
                            rstools.datastatus.actions.markNeedsReviewed('wheel.fitment'),
                            rstools.datastatus.actions.markReviewed('wheel.fitment')
                        ],
                        rowCallback: function ($tr, fitmentDescription) {
                            if (fitmentDescription.WheelFitmentArchived) $tr.addClass('archived-data-object');
                        },
                        getAction: 'Wheel/GetFitmentDescriptions',
                        countAction: 'Wheel/CountFitmentDescriptions',
                        resultListField: 'Fitments',
                        baseQueryData: {
                            SelectBrandsOnly: false,
                            UsePromotionOrder: false,
                            UseBrandPriorities: false,
                            HasFitments: false
                        },
                        search: filters
                    });


                if (rstools.user.isSystemAdmin()) {
                    var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

                    $headerPaste =  $('<button/>', {
                        'class': 'btn btn-default',
                        'append': uifactory.create.icon('clipboard'),
                        'title': 'Paste Fitments',
                        'appendTo': $rightButtons,
                        'click': function() {
                            var clipboard = datagrid.getClipboard();
                            var clipboardIDs;

                            var modelID = 'WheelModel' in filters ? filters.WheelModel : filters.WheelModels[0];

                            if (clipboard.from.indexOf("Wheel/GetFitment") >= 0) {
                                clipboardIDs = $.map(clipboard.rows, function (fitment) {
                                    return fitment.WheelFitmentID;
                                });

                                if (clipboard.action === 'copy') 
                                    rstools.wheel.fitment.copyTo({
                                        WheelFitments: clipboardIDs
                                    }, modelID);

                                else if (clipboard.action === 'cut')
                                    rstools.wheel.fitment.moveTo({
                                        WheelFitments: clipboardIDs
                                    }, modelID, {
                                        callback: function () {
                                            datagrid.clearClipboard();
                                        }
                                    });
                            }
                        }
                    });

                    $headerPaste[datagrid.hasClipboardMatching('Wheel/GetFitment') ? 'show' : 'hide']();

                    rstools.events.on('datagridClipboardChanged', function (clipboard) {
                        var hasModelFilter = 'WheelModels' in filters || 'WheelModel' in filters;

                        $headerPaste[datagrid.hasClipboardMatching('Wheel/GetFitment') && hasModelFilter ? 'show' : 'hide']();
                    });

                    $createButton = $('<button/>', {
                        'class': 'btn btn-primary',
                        'append': [
                            'Create Fitment &nbsp;',
                            uifactory.create.icon('plus')
                        ],
                        'appendTo': $rightButtons,
                        'click': function() {
                            var firstModelID;

                            if ('WheelModels' in filters && filters.WheelModels.length) firstModelID = filters.WheelModels[0];
                            else if (filters.WheelModel) firstModelID = filters.WheelModel;

                            rstools.wheel.fitment.create({
                                WheelFitment_WheelModelID: firstModelID
                            });
                        }
                    });
                }
                
                var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

                $backToModelsButton = $('<a/>', {
                    'class': 'btn btn-info',
                    'append': [
                        uifactory.create.icon('caret-left'),
                        ' Models'
                    ],
                    'href': '#!/app/wheel/model'
                }).appendTo($leftButtons);

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .appendTo($leftButtons)
                    .trigger('filters-updated', [Object.keys(filters).length]);
            },
            viewLoaded: contextChanged,
            viewChainChanged: function (ctx) {
                contextChanged(ctx);
                grid.search(rstools.wheel.utils.filterFormDataToWheelFilters(filters));
            }
        };

        function contextChanged(ctx) {
            filters = ctx.state.filters || {};

            $filterForm.syncObjectToForm(filters);

            var hasModelFilter = 'WheelModels' in filters || 'WheelModel' in filters;

            $createButton && $createButton[hasModelFilter ? 'show' : 'hide']();

            $headerPaste && $headerPaste[datagrid.hasClipboardMatching('Wheel/GetFitment') && hasModelFilter ? 'show' : 'hide']();
            
            rstools.utils.setBackButtonTargetFromContext($backToModelsButton, ctx, '/app/wheel/model');
        }

        function filtersUpdated(doNotSetState) {
            $filterForm.syncFormToObject(filters);
            $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(rstools.wheel.utils.filterFormDataToWheelFilters(filters));

            if (doNotSetState !== false)
                rstools.state.set('filters', filters);
        }
    });
})(jQuery);