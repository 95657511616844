(function ($) {
    rter.mapController('app.admin.account.create', function (context) {
        var resetTimeout, $form;

        return {
            actions: {
                create: function (e) {
                    e && e.preventDefault();

                    var formDataArray = $form.serializeArray(),
                        formData = {};

                    $.each(formDataArray, function (i, d) { formData[d.name] = d.value; });

                    $form.find('.form-group').removeClass('has-error');
                    if (!formData['Email']) return error('The Email field is required.', 'Email');
                    if (!formData['Password']) return error('The Password field is required', 'Password');

                    ridestyler.ajax.send({
                        action: 'Account/Create',
                        data: formData,
                        callback: function (response) {
                            if (response.Success) {
                                var message = "Account for " + formData.CompanyName + " has been created.";

                                if (formData['SendWelcomeEmail'] === 'true') message += " The organization admin will receive an email shortly.";

                                uifactory.alert.show({ text: message });

                                reset();
                            } else {
                                error("There was a problem creating the account.");
                            }
                        }
                    });
                },
                reset: function () {
                    var $this = $(this);

                    if ($this.hasClass('btn-danger')) {
                        $this.removeClass('btn-danger').text('Reset');
                        clearTimeout(resetTimeout);
                    } else {
                        $this.addClass('btn-danger').text('Cancel (3)');

                        var seconds = 3;
                        var updateTimeout = function () {
                            seconds -= 1;

                            if (seconds == 0) {
                                $this.text('Reset').removeClass('btn-danger');
                                reset();
                            } else {
                                $this.text('Cancel (' + seconds + ')');
                                resetTimeout = setTimeout(updateTimeout, 1000);
                            }
                        };

                        resetTimeout = setTimeout(updateTimeout, 1000);
                    }
                }
            },
            viewWillCreate: function ($view) {
                $form = $view.find('form');
            }
        };

        function error(text, fieldName) {
            uifactory.alert.show({
                text: text,
                type: 'error'
            });

            if (fieldName) {
                var field = document.getElementsByName(fieldName);

                if (field.length) {
                    field = field[0];
                    $(field).closest('.form-group').addClass('has-error');
                    field.focus();
                }
            }

            return false;
        }

        function reset() {
            $form.get(0).reset();
            $form.find('.form-group').removeClass('has-error')
                .find(':checkbox').iCheck('update');
        }
    });
})(jQuery);