(function ($) {
    var dropdownOptionsFromObject = rstools.utils.dropdownOptionsFromObject;
    var loadPaginatedList = rstools.utils.loadPaginatedList;
    var findLikeData = rstools.utils.findLikeData;

    function createPricingGroupForm(type, settings) {
        var url, responseKey;

        settings = $.extend({
            isMultiple: false,
            data: {},
            filters: {},
            submitSuccessCallback: undefined,
            onCancel: undefined
        }, settings);

        var data = settings.data;
        var s = settings.isMultiple ? 's' : '';

        switch (type) {
            case "edit":
                url = 'Organization/EditPricingGroup';
                responseKey = 'Count';
                break;
            case "create":
                url = 'Organization/CreatePricingGroup';
                responseKey = 'PricingGroup';
                break;
        }

        if (typeof url !== 'string') return undefined;

        var fields = $.map([
            {
                label: 'Name',
                name: 'PricingGroupName',
                maxLength: 128,
                helpText: 'The name of the pricing group' + s,
                value: data.PricingGroupName,
                required: !settings.isMultiple,
                hasMultipleValues: settings.isMultiple && 'PricingGroupName' in data === false
            },
            {
                label: 'Organization Permission',
                name: 'PricingGroup_OrganizationPermissionID',
                helpText: 'The organization permission group that should have access to the pricing group' + s,
                value: data.PricingGroup_OrganizationPermissionID,
                required: !settings.isMultiple,
                hasMultipleValues: settings.isMultiple && 'PricingGroup_OrganizationPermissionID' in data === false,

                type: 'search',

                url: 'Organization/GetOrganizationPermissions',
                responseKey: 'Results',
                resultMap: function(organizationPermission) {
                    return {
                        id: organizationPermission.OrganizationPermissionID,
                        name: organizationPermission.OrganizationPermissionName
                    };
                },
                idColumnName: 'OrganizationPermissionID'
            }
        ], function (field) {
            // Modify fields dynamically here
            if (type === "create") field.hasMultipleValues = false;

            return field;
        });

        return uifactory.create.form({
            url: url,
            responseKey: responseKey,

            submitErrorMessage: 'There was a problem ' + (type === 'create' ? 'creat' : 'edit') + 'ing the pricing group' + s + ': {Message}.',

            submitSuccessCallback: function (response) {
                var verbPast = (type === 'create' ? 'created.' : 'edited.');

                uifactory.alert.show({
                    text: !settings.isMultiple ? "The pricing group has been " + verbPast : "The pricing groups have been " + verbPast
                });

                if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback(response);
            },

            fields: fields,
            baseData: settings.filters,

            actions: [
                {
                    label: type === 'create' ? 'Create' : 'Save',
                    icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
                    buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
                    action: 'submit'
                },

                {
                    label: 'Cancel',
                    action: settings.onCancel
                }
            ]
        });
    }

    function comparePrices(a, b) {
        var aValid = a && !isNaN(a);
        var bValid = b && !isNaN(b);
        
        if (aValid != bValid) return false;
        if (!aValid && !bValid) return true;

        return a.toFixed(2) === b.toFixed(2);
    }

    rstools.pricing = {
        settings: {
            filters: [
                {
                    name: 'Search',
                    type: 'text-search',
                    label: 'Search',
                    required: false
                },
                {
                    type: 'search',
                    label: 'Pricing Group',
                    group: 'Pricing',
                    name: 'PricingGroupID',
                    required: true,

                    url: 'Organization/GetPricingGroups',
                    responseKey: 'Results',
                    resultMap: function(pricingGroup) {
                        return {
                            id: pricingGroup.PricingGroupID,
                            name: pricingGroup.PricingGroupName
                        };
                    },
                    idColumnName: 'PricingGroupID'
                },
                {
                    label: 'Include Archived',
                    required: false,
                    group: 'Other',
                    name: 'IncludeArchived',
                    type: 'checkbox',
                    requiredRoles: [rstools.roles.SYSTEM_ADMIN]
                }
            ]
        },

        format: {
            price: function (price) {
                if (!price || isNaN(price)) return '-';
                return '$' + price.toFixed(2);
            },

            prices: function (prices) {
                var formatPrice = rstools.pricing.format.price;

                if (!prices || prices.length === 0) return undefined; 
                if (!isNaN(prices)) return formatPrice(prices);
                if (prices.length === 1) return formatPrice(prices[0]);

                var priceMin = formatPrice(prices[0]);
                var priceMax = formatPrice(prices[1]);

                if (priceMin === priceMax || priceMax === '-') return priceMin;
                if (priceMin === '-') return priceMax;
                
                return priceMin + ' - ' + priceMax;
            }
        },

        edit: function (settings) {
            settings = $.extend({
                action: 'Wheel/GetPrices',
                countAction: 'Wheel/CountPrices',
                updateAction: 'Wheel/EditPrice',
                createAction: 'Wheel/CreatePrice',
                deleteAction: 'Wheel/DeletePrice',
                responseKey: 'Prices',
                namespace: 'wheel-fitment-pricing',
                filters: {}
            }, settings);

            var filters = settings.filters;

            if ('PricingGroupID' in settings === false) {
                rstools.pricing.group.select({
                    onSelect: function (pricingGroupID) {
                        settings.PricingGroupID = pricingGroupID;
                        rstools.pricing.edit(settings);
                    }
                });
                return;
            }

            var pricingGroup = settings.PricingGroupID;
            
            var modal = uifactory.modal.create({
                cancelable: true,
                header: 'Edit Prices'
            });

            var buildModal = function (pricing) {
                modal.$body.empty();
                modal.$footer.empty();

                var likePricing = {};
                var groupedPricing = {};

                var amountKey, typeKey, idKey, fitmentKey, fitmentKeyBase;
                var fitmentIDs;

                // Build like pricing dictionary, grab keys, get fitmentIDs
                {
                    var dataTypes = {};

                    $.each(pricing, function (i, price) {
                        if (!amountKey || !typeKey) for (var k in price) if (price.hasOwnProperty(k)) {
                            if (k.substr(-6) === 'Amount') amountKey = k;
                            else if (k.substr(-17) === 'PricingDataTypeID') typeKey = k;
                            else if (k.substr(-9) === 'PricingID') idKey = k;
                            else if (k.substr(-9) === 'FitmentID') {
                                fitmentKey = k;
                                
                                fitmentKeyBase = fitmentKey.split('_')[1];
                                fitmentKeyBase = fitmentKeyBase.substr(0, fitmentKeyBase.length-2);
                            }
                        }

                        var fitmentID = price[fitmentKey];
                        var amount = price[amountKey];
                        var dataType = price[typeKey];

                        if (fitmentID in groupedPricing === false) groupedPricing[fitmentID] = {};

                        groupedPricing[fitmentID][dataType] = amount;
                        dataTypes[dataType] = true;
                    });

                    $.each(dataTypes, function (dataType) {
                        $.each(groupedPricing, function (fitmentID, fitmentPrices) {
                            if (dataType in fitmentPrices === false)
                                return likePricing[dataType] = false;

                            if (dataType in likePricing) {
                                if (!comparePrices(likePricing[dataType], fitmentPrices[dataType]))
                                    return likePricing[dataType] = false;
                            } else {
                                likePricing[dataType] = fitmentPrices[dataType];
                            }
                        });
                    });

                    fitmentIDs = Object.keys(groupedPricing);
                }

                // Create table
                var table = document.createElement('table');
                    table.className = 'table table-striped no-borders';

                var tbody = document.createElement('tbody');
                    table.appendChild(tbody);

                // Build the table rows from pricing
                $.each(likePricing, function (dataTypeID, amount) {
                    var tr = document.createElement('tr');
                    var updateButton, deleteButton, amountInput;

                    // Create the data type label
                    {
                        var nameTd = document.createElement('td');
                            nameTd.appendChild(document.createTextNode(rstools.constants.PricingDataType[dataTypeID]));
                            tr.appendChild(nameTd);
                        
                        if (amount === false) {
                            var multipleValuesWarning = document.createElement('span');
                                multipleValuesWarning.className = 'text-warning';
                                multipleValuesWarning.style.marginLeft = '5px';
                                multipleValuesWarning.title = 'This field has multiple values specified.';
                                uifactory.create.icon('th').appendTo(multipleValuesWarning);
                                nameTd.appendChild(multipleValuesWarning);
                        }
                    }

                    // Add a static dollar sign column
                    {
                        var dollarTd = document.createElement('td');
                            dollarTd.appendChild(document.createTextNode('$'));
                            dollarTd.style.width = '16px';
                            dollarTd.style.paddingRight = 0;
                            tr.appendChild(dollarTd);
                    }

                    // Create the amount input cell
                    {
                        var amountTd = document.createElement('td');
                            tr.appendChild(amountTd);
                        
                        amountInput = document.createElement('input');
                        amountInput.className = 'form-control';
                        amountTd.appendChild(amountInput);
                        if (amount) amountInput.value = amount;
                        
                        amountInput.onkeyup = function () {
                            var isNumber = this.value && isNaN(this.value) === false;

                            amountTd.className = isNumber ? '' : 'has-error';

                            updateButton.disabled = !isNumber || this.value == amount;
                        };
                    }

                    // Create the update button cell
                    {
                        var updateTd = document.createElement('td');
                            updateTd.style.width = '88px';
                            tr.appendChild(updateTd);
                       
                        updateButton = document.createElement('button');
                        updateButton.className = 'btn btn-success';
                        updateButton.disabled = true;
                        updateButton.style.width = '72px';
                        updateButton.appendChild(document.createTextNode('Update'));
                        updateTd.appendChild(updateButton);

                        updateButton.onclick = function () {
                            // Set modal loading
                            modal.$modal.addClass('loading');

                            // Create pricing for any that are missing it
                            var createPricingDeferred;
                            {
                                // Get a list of fitment IDs that do not have this pricing type
                                var fitmentIDsMissingPricingType = $.map(fitmentIDs, function (fitmentID) {
                                    return dataTypeID in groupedPricing[fitmentID] ? undefined : fitmentID;
                                });

                                if (fitmentIDsMissingPricingType.length) {
                                    // Build the request
                                    var createData = {
                                        PricingGroup: pricingGroup,
                                        PricingDataType: dataTypeID,
                                        Amount: amountInput.value
                                    };

                                    createData[fitmentKeyBase + 's'] = fitmentIDsMissingPricingType;                 

                                    // Create the pricing for the data type
                                    createPricingDeferred = rstools.utils.apiDeferred({
                                        action: settings.createAction,
                                        data: createData
                                    });
                                } else {
                                    createPricingDeferred = $.Deferred().resolve();
                                }
                            }

                            // Update pricing for existing pricing IDs
                            var updatePricingDeferred;
                            {
                                 // Get all pricing IDs for this data type
                                var pricingIDs = $.map(pricing, function (price) {
                                    if (price[typeKey] != dataTypeID) return undefined;
                                    return price[idKey];
                                });

                                // Build the request
                                var updateFilters = {};
                                updateFilters[amountKey] = amountInput.value;
                                updateFilters[idKey + 's'] = pricingIDs;
                           
                                updatePricingDeferred = rstools.utils.apiDeferred({
                                    action: settings.updateAction,
                                    data: updateFilters
                                });
                            }

                            $.when(createPricingDeferred, updatePricingDeferred)
                                .done(function () {
                                    uifactory.alert.show({text: 'The pricing has been updated'});
                                })
                                .fail(function (response) {
                                    rstools.api.utils.showResponseError('There was an error updating the pricing', response);
                                })
                                .always(reloadModal);
                        };
                    }

                    // Create the delete button cell
                    {
                        var deleteTd = document.createElement('td');
                            deleteTd.style.width = '53px';
                            tr.appendChild(deleteTd);
                        
                        deleteButton = document.createElement('button');
                        deleteButton.className = 'btn btn-danger';
                        deleteButton.style.width = '37px';
                        uifactory.create.icon('trash').appendTo(deleteButton);
                        deleteTd.appendChild(deleteButton);

                        deleteButton.onclick = function () {
                            // Get all pricing IDs for this data type
                            var pricingIDs = $.map(pricing, function (price) {
                                if (price[typeKey] != dataTypeID) return undefined;
                                return price[idKey];
                            });

                            // Generate the request
                            var deleteFilters = {};
                            deleteFilters[idKey + 's'] = pricingIDs;

                            // Set the whole model loading
                            modal.$modal.addClass('loading');

                            // Delete the pricing rows
                            ridestyler.ajax.send({
                                action: settings.deleteAction,
                                data: deleteFilters,
                                callback: function (response) {
                                    if (response.Success) {
                                        reloadModal();
                                        rstools.events.dataChanged(settings.namespace, 'delete');
                                        uifactory.alert.show({text: 'The ' + rstools.constants.PricingDataType[dataTypeID] + ' pricing has been deleted.'});
                                    } else {
                                        // There was an error, show it and cancel loading
                                        rstools.api.utils.showResponseError('There was an error deleting the pricing', response);
                                        modal.$modal.removeClass('loading');
                                    }
                                }
                            });
                        };
                    }

                    tbody.appendChild(tr);
                });

                modal.$body.append(table);

                // Build the add data type footer elements
                {
                    // Get a list of all data types not currently spoken for 
                    var addDataTypeOptions = $.map(dropdownOptionsFromObject(rstools.constants.PricingDataType), function (option) {
                        if (option.value in likePricing) return undefined;
                        return option;
                    });

                    if (addDataTypeOptions.length) {
                        // We have data types that the user can create, create the dropdown
                        var $addDataTypeDropdown = uifactory.create.dropdown({
                            options: addDataTypeOptions 
                        });

                        // Create the add button
                        {
                            var addButton = document.createElement('button');
                                addButton.className = 'btn btn-success';
                                uifactory.create.icon('plus').appendTo(addButton);

                                addButton.onclick = function () {
                                    // Set the modal loading
                                    modal.$modal.addClass('loading');

                                    // Build the request
                                    var createData = {
                                        PricingGroup: pricingGroup,
                                        PricingDataType: $addDataTypeDropdown.data('rsDropdown').selectedOption.value 
                                    };                        

                                    // Create the pricing for the data type
                                    ridestyler.ajax.send({
                                        action: settings.createAction,
                                        data: $.extend({}, settings.filters, createData),
                                        callback: function (response) {
                                            if (response.Success) {
                                                reloadModal();
                                            } else {
                                                // There was an error creating the pricing
                                                modal.$modal.removeClass('loading');
                                                rstools.api.utils.showResponseError('There was an error creating the pricing', response);
                                            }
                                        }
                                    });
                                };
                        }
                        
                        modal.$footer.append('Add Pricing Type: ', $addDataTypeDropdown, ' ', addButton).show();
                    } else {
                        modal.$footer.hide();
                    }
                }

                modal.$modal.removeClass('loading');
            };
            var onPricingLoadingFailed = function (response) {
                modal.hide();
                rstools.api.utils.showResponseError('There was an error loading pricing', response);
            };
            var reloadModal = function () {
                // Reload the modal
                loadPaginatedList(settings.action, settings.countAction, settings.responseKey, settings.filters)
                    .done(buildModal)
                    .fail(onPricingLoadingFailed);
            };

            modal.$modal.addClass('loading');
            reloadModal();

            modal.show();
        },

        utils: {
            createFilterForm: function(ctx, settings) {
                var pageName = ctx.routes[ctx.routes.length - 1].name;
                
                settings = settings || {
                    filters: undefined,
                    onSubmit: undefined,
                    onReset: undefined
                };

                var stateFilters = ctx.state.filters || {};

                // Store the first field with a group of Pricing for later use
                var firstPricingGroupIndex = false;

                // Grab all applicable filters for use as fields in the filter form
                var fields = $.map(rstools.pricing.settings.filters, function (filter, i) {
                    if (filter.for && !~$.inArray(pageName, filter.for)) return undefined;
                    if (filter.group === 'Pricing' && firstPricingGroupIndex === false) firstPricingGroupIndex = i;
                    return filter;
                });

                var filterFieldMap = {};

                rstools.tire.utils.createStaticFilterFields(stateFilters, filterFieldMap);

                // Move filters from filterFieldMap if they're active
                // to be in the first Wheel group spot
                for (var k in filterFieldMap)
                    if (k in stateFilters)
                        fields.push(filterFieldMap[k]);
                
                var groupSort = {
                    'Pricing': 1,
                    'Wheel': 2,
                    'Tire': 3,
                    'Other': 4
                };
                fields.sort(function (fieldA, fieldB) {
                    fieldA = fieldA.group in groupSort ? groupSort[fieldA.group] : 0;
                    fieldB = fieldB.group in groupSort ? groupSort[fieldB.group] : 0;

                    return fieldA - fieldB;
                });

                return uifactory.create.filterForm({
                    filters: settings.filters,
                    fields: fields,
                    onSubmit: settings.onSubmit,
                    onReset: settings.onReset
                });
            },
            createItemNumberDisplay: function (pricing) {
                var itemNumbers = [];
                var itemNumberKeyEnding = 'ItemNumber';

                for (var type in pricing) if (pricing.hasOwnProperty(type)) {
                    var price = pricing[type];

                    for (var k in price) if (price.hasOwnProperty(k)) {
                        if (k.substr(-itemNumberKeyEnding.length) !== itemNumberKeyEnding) continue;

                        var itemNumber = price[k];

                        if (itemNumber) {
                            itemNumbers.push(itemNumber);
                            break;
                        }
                    }
                }

                if (itemNumbers.length === 0) return undefined;

                itemNumbers = itemNumbers.unique();

                if (itemNumbers.length === 1) return document.createTextNode(itemNumbers[0]);
                
                var a = document.createElement('a');
                    a.href = '#';
                    a.title = itemNumbers.join("\n");

                a.appendChild(document.createTextNode(itemNumbers.length + ' item numbers'));

                $(a).tooltip();
                
                return a;
            },
            getBestPricingType: function (pricing) {
                if (!pricing) return undefined;

                if ('Retail' in pricing) return pricing.Retail;

                for (var type in pricing) if (pricing.hasOwnProperty(type)) {
                    // Pick the best pricing type here
                    return pricing[type];
                }
            },
            compare: comparePrices
        }
    };

    rstools.pricing.group = {
        select: function (settings) {
            settings = $.extend({
                header: 'Select a Pricing Group',
                onSelect: undefined
            }, settings);

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                startHidden: false,
                header: 'Select a Pricing Group',
                body: uifactory.create.form({
                    fields: [
                        {
                            type: 'search',
                            name: 'PricingGroupID',
                            required: true,

                            url: 'Organization/GetPricingGroups',
                            responseKey: 'Results',
                            resultMap: function(pricingGroup) {
                                return {
                                    id: pricingGroup.PricingGroupID,
                                    name: pricingGroup.PricingGroupName
                                };
                            },
                            requestData: {
                                Organization: rstools.auth.user.getActiveOrganizationID() 
                            },
                            idColumnName: 'PricingGroupID',
                            validate: function (formData) {
                                if (!formData.PricingGroupID) return "You must select a pricing group.";
                            }
                        }
                    ],
                    actions: [
                        {
                            action: function () {
                                var $this = $(this);

                                rstools.pricing.group.create({
                                    onCreate: function (newPricingGroup) {
                                        $this.closest('form').syncObjectToForm({
                                            PricingGroupID: newPricingGroup.PricingGroupID
                                        });
                                    }
                                });
                            },
                            buttonClass: 'btn pull-left btn-success',
                            label: 'Create Pricing Group',
                            icon: 'plus'
                        },
                        {
                            action: 'submit',
                            buttonClass: 'btn btn-primary',
                            label: 'Select'
                        }
                    ],
                    beforeSubmit: function (formData) {
                        if (typeof settings.onSelect === 'function')
                            settings.onSelect(formData.PricingGroupID);
                        modal.hide();
                    }
                })
            });
        },
        edit: function (filters) {
            var pricingGroups = [];

            var pricingGroupsLoaded = loadPaginatedList('Organization/GetPricingGroups', 'Organization/CountPricingGroups', 'Results', filters)
                .done(function (groups) { pricingGroups = groups; });

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: $('<h4/>')
            });

            modal.$modal.addClass('loading');

            $.when(pricingGroupsLoaded)
                .done(function () {
                    var isMultiple = pricingGroups.length > 1;

                    modal.$modal.removeClass('loading');

                    modal.settings.header.html(isMultiple ? "Edit " + pricingGroups.length + " Pricing Groups" : "Edit Pricing Group");

                    createPricingGroupForm('edit', {
                        isMultiple: isMultiple,
                        data: isMultiple ? findLikeData(pricingGroups) : pricingGroups[0],
                        filters: filters,
                        submitSuccessCallback: function () {
                            rstools.events.dataChanged('pricing-group', 'edit');
                            modal.hide();
                        },
                        onCancel: function() {
                            modal.hide();
                        }
                    }).appendTo(modal.$body);

                    modal.setDisplayID($.map(pricingGroups, function (b) { return b.PricingGroupID; }));
                })
                .fail(function () {
                    modal.hide();
                    uifactory.alert.show({ type: 'error', text: 'There was an error loading information about the pricing group(s).' });
                });

            modal.show();
        },
        create: function (settings) {
            settings = $.extend({
                data: undefined,
                onCreate: undefined
            }, settings);

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: 'Create a Pricing Group',
                body: createPricingGroupForm('create', {
                    data: settings.data,
                    submitSuccessCallback: function (pricingGroup) {
                        if (typeof settings.onCreate === 'function') settings.onCreate(pricingGroup);

                        rstools.events.dataChanged('pricing-group', 'create');
                        modal.hide();
                    },
                    onCancel: function () {
                        modal.hide();
                    }
                })
            });

            modal.show();
        },
        utils: {
            getIDs: function (pricingGroups) {
				return $.map(pricingGroups, function (pricingGroup) {
					return pricingGroup.PricingGroupID;
				});
			},
			getTitles: function (pricingGroups) {
				return $.map(pricingGroups, function (pricingGroup) {
					return pricingGroup.PricingGroupName;
				});
			}
        }
    };
})(jQuery);