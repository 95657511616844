(function ($) {
	var formFieldGUID = 0;
	var formGroupGUID = 0;
	var formTargetGUID = 0;

	uifactory.create.form = function (settings) {
		var originalSettings = settings;

		settings = $.extend({
			fields: [],
			actions: [],
			defaultValues: {},

			container: undefined,

			'formClass': '',
			'actionContainerClass': 'text-right',
			'outerActionContainerClass': 'form-group',
			'fieldGroupClass': 'panel panel-body with-dropdown',
			'fieldGroupHeaderClass': '',
			'fieldGroupHeaderElement': 'h4',
			'fieldGroupsCollapsable': true,

			labelColumns: 2,
			fieldColumns: 10,

			url: undefined,
			baseData: {},
			urlAction: 'ridestyler',
			validate: true,
			submitErrorMessage: 'There was a problem submitting your request: {Message}',

			onReset: undefined,
			shouldSubmit: undefined,
			beforeSubmit: undefined,
			submitCallback: undefined,
			submitSuccessCallback: undefined,
			submitErrorCallback: undefined
		}, originalSettings);

		settings.urlAction = settings.urlAction.toLowerCase();

		var fieldValidateRules = [];

		var validate = function (formData) {
			var validationDeferredRules = [];
			var validationErrors = [];

			var createError = function (message, rule) {
				return {
					message: message,
					group: rule.group,
					label: rule.label
				};
			};

			formData = formData || getFormData();

			// Loop through all of the validation rules defined by the fields
			$.each(fieldValidateRules, function (i, rule) {
				// If the rule is not valid, or does not have a validation function, skip it
				if (!rule || typeof rule.validate !== 'function')
					return; // continue;

				if (rule.deferredValidate) {
					validationDeferredRules.push(rule);
					
					return; // continue;
				}

				// Validate the data using the validation rule
				var validateResult = rule.validate(formData, rule, settings);

				// If a string was returned, there was an error
				if (typeof validateResult === 'string') {
					validationErrors.push(createError(validateResult, rule));

					return false; // break;
				}
			});

			// If we have any non-deferred valiation errors we can go ahead an error out
			// using the first error, without calling the deferred validation methods
			if (validationErrors.length)
				return $.Deferred().reject(validationErrors[0]);
				
			// Otherwise we need to run the deferred validation rules one at a time, until
			// they all succeed, or one errors out
			if (validationDeferredRules.length) {
				/** @type {JQueryDeferred} */
				var deferred;
				
				// Chain validation rules
				for (i = 0; i < validationDeferredRules.length; i++) (function (rule) {
					// Use the first deferred as a starting point
					if (!deferred) {
						deferred = rule.validate(formData, rule, settings).then(null, function (errorMessage) {
							return createError(errorMessage, rule);
						});
						
						return;
					}

					// Chain the deferreds
					deferred = deferred.then(function () {
						return rule.validate(formData, rule, settings).then(null, function (errorMessage) {
							return createError(errorMessage, rule);
						});
					});
				})(validationDeferredRules[i]);

				return deferred;
			} else {
				// Valid!
				return $.Deferred().resolve();
			}
		};

		var $form = $('<form/>', {
			'class': settings.formClass,
			'role': 'form',
			'noValidate': true
		});

		var getFormData = function () {
			var formData = {};
			$form.syncFormToObject(formData);
			
			return $.extend({}, settings.baseData, formData);
		};

		if (settings.container) $form.appendTo(settings.container);

		var $inputGroupContainer = $form;

		var i;

		if (settings.urlAction === 'upload') {
			var targetID = 'rs-form-target-' + (formTargetGUID++).toString();

			$form.attr({
				'method': 'post',
				'enctype': 'multipart/form-data',
				'target': targetID,
				'action': settings.url
			});
		}

		$form
			.on('submit', function(e, submitOptions) {
				submitOptions = $.extend({
					skipShouldSubmit: false,
					skipValidation: false
				}, submitOptions);

				// If true, submit the form traditionally
				var shouldSubmitForm = false;

				var skipValidation = submitOptions.skipValidation;
				var skipShouldSubmit = submitOptions.skipShouldSubmit;

				var formData = getFormData();

				if (settings.validate && !skipValidation) {
					$form.find('.form-group').removeClass('has-error');

					$form.addClass('loading');

					validate(formData)
						.always(function() {
							$form.removeClass('loading');
						})
						.done(function() {
							submitOptions.skipValidation = true;

							// Submit, skip validation
							$form.trigger('submit', [submitOptions]);
						})
						.fail(function(failure) {
							var errorText = 'The form is invalid please check each field and try again.';

							if (failure) {
								failure.group && failure.group.addClass('has-error');
								errorText = failure.label ? "<strong>" + failure.label + ":</strong> " + failure.message : failure.message;
							}

							uifactory.alert.show({
								text: errorText,
								type: 'error',
								stack: 'rs-form-error'
							});
						});

					return false;
				}

				// Check settings.shouldSubmit to see if the settings says this form should submit
				if (!skipShouldSubmit && typeof settings.shouldSubmit === 'function') {
					var shouldSubmitResponse = settings.shouldSubmit.call($form, formData);

					// If it's a deferred object wait for it to resolve then continue
					if (typeof shouldSubmitResponse === 'object' && typeof shouldSubmitResponse.then === 'function') { 
						shouldSubmitResponse.done(function () {
							submitOptions.skipShouldSubmit = true;

							// Submit, skip should submit check
							$form.trigger('submit', [submitOptions]);
						});

						return;
					}

					if (!shouldSubmitResponse) {
						return;
					}
				}

				if (typeof settings.beforeSubmit === 'function')
					settings.beforeSubmit.call($form, formData);

				function handleFormRSResponse(response) {
					if (typeof settings.submitCallback === 'function') settings.submitCallback(response);

					if (response.Success) {
						if (typeof settings.submitSuccessCallback === 'function') {
							if (typeof settings.responseKey === 'string') settings.submitSuccessCallback(response[settings.responseKey]);
							else settings.submitSuccessCallback(response);
						}
					} else {
						if (typeof settings.submitErrorCallback === 'function') settings.submitErrorCallback(response);

						if (typeof settings.submitErrorMessage === 'string') {
							uifactory.alert.show({
								text: settings.submitErrorMessage.replace(/{Message}/ig, response.Message ? response.Message : "Please try again."),
								type: 'error'
							});
						}
					}

					$form.removeClass('loading');
				}

				if (typeof settings.url === 'string') {
					$form.addClass('loading');

					if (settings.urlAction === 'ridestyler') {
						//#region Submit the form using ridestyler.ajax.send
						ridestyler.ajax.send({
							action: settings.url,
							data: formData,
							callback: handleFormRSResponse
						});
						//#endregion
					} else if (settings.urlAction === 'upload') {
						var $progress, $progressBar, progressTextNode;

						// Create the progress UI
						{
							$progress = $('<div/>', {
								"class": 'progress progress-floating'
							});

							$progressBar = $('<div/>', {
								"class": 'progress-bar progress-bar-striped active'
							});

							progressTextNode = document.createTextNode("Uploading...");

							// Remove any previously found floating progress
							$('.progress-floating', $form).remove();

							// Add the progress bar to the structure
							$progressBar.append(progressTextNode).appendTo($progress);
							$progress.appendTo($form);
							
							// Animate the progress bar in
							$progress.fadeIn();
						}


						// Send the request
						ridestyler.ajax.send({
							action: settings.url,
							data: formData,
							afterCreateXHR: function (xhr) {
								xhr.upload.addEventListener("progress", function(evt) {
									if (evt.lengthComputable) {
										var percentComplete = evt.loaded / evt.total * 100;
	
										$progressBar.width(percentComplete + '%');
										progressTextNode.nodeValue = percentComplete.toFixed(2) + "%";
									}
								}, false);
	
								xhr.addEventListener("progress", function() {
									$progressBar.width('100%');
									progressTextNode.nodeValue = "Waiting for API...";
								}, false);
							}
						})
							.done(handleFormRSResponse)
							.fail(function() {
								$progressBar.addClass('progress-bar-danger');

								handleFormRSResponse({
									Success: false,
									Message: 'There was a problem communicating with the RideStyler API'
								});
							})
							.always(function () {
								$progress.fadeOut(function () {
									$progress.remove();
								});
							});
					}
				}

				if (!shouldSubmitForm) e.preventDefault();

				return undefined;
			})
			.on('reset', function(e) {
				e.preventDefault();

				var defaultValues = {};
				var field;

				for (i = 0; i < settings.fields.length; i++) {
					field = settings.fields[i];

					if (field.name) {
						if (field.type === 'checkbox') {
							if (field.checked) {
								if (field.dataType === 'data') defaultValues[field.name] = field.value;
								else defaultValues[field.name] = true;
							}
						}
						else if (typeof field.value !== "undefined")
							defaultValues[field.name] = field.value;
					}
				}

				$form.syncObjectToForm(defaultValues);

				if (typeof settings.onReset === 'function') settings.onReset();
			});

		$form.addClass('rs-form');
		$('<div/>', { 'class': 'loader' }).appendTo($form);

		if ($form.hasClass('form-horizontal'))
			settings.actionContainerClass += ' col-sm-offset-' + settings.labelColumns + ' col-sm-' + settings.fieldColumns;
		else if ($form.hasClass('filter-form') && $form.hasClass('panel')) {
			$('<div/>', {
				'class': 'panel-heading',
				'append': $('<h4/>', {
					'append': [
						uifactory.create.icon('filter'),
						" Filters",
						$('<button/>', {
							'type': 'reset',
							'title': 'Reset Filters',
							'class': 'btn btn-sm btn-default filter-clear',
							'append': uifactory.create.icon('times')
						})
					]
				}),
				'appendTo': $form
			});

			$inputGroupContainer = $('<div/>', {
				'class': 'panel-body filter-panel-body',
				'appendTo': $form
			});

			settings.outerActionContainerClass = originalSettings.outerActionContainerClass || 'panel-footer filter-panel-footer';
			settings.actionContainerClass = originalSettings.actionContainerClass || '';
		}

		var fields = [];

		for (i = 0; i < settings.fields.length; i++) (function (field, i) {
			var originalField = field;

			field = settings.fields[i] = $.extend({
				'type': 'text',
				'id': 'rs-form-field-' + (formFieldGUID++).toString(),
				'name': undefined,
				'disabled': false,
				'required': true,
				'requiredRoles': undefined,

				'placeholder': undefined,
				'value': undefined,
				'validate': undefined,
				'hasMultipleValues': false,
				'dataType': undefined,

				'group': undefined,

				'groupClass': 'form-group',
				'labelClass': 'control-label',
				'fieldContainerClass': '',
				'fieldClass': 'form-control',
				'labelHintSummary': undefined,
				'labelHintText': undefined,
				'noLabel': false
			}, settings.fields[i]);

			if (field.value === undefined) delete field.value;

			field.originalField = originalField;

			// If we don't have the required roles for this field don't add it to the form
			if (field.requiredRoles && !rstools.auth.user.hasRole(field.requiredRoles))
				return;

			if (!field.name) field.name = field.id;

			if ($form.hasClass('form-horizontal')) {
				field.labelClass += ' col-sm-' + settings.labelColumns;
				field.fieldContainerClass += ' col-sm-' + settings.fieldColumns;
			}

			var $input,

				$group,

				$labelContainer,
				$label,

				$inputContainer;

			$group = $('<div/>', { 'class': field.groupClass });

			if (field.hidden) $group.css("display", "none");

			if (!field.noLabel) {
				$labelContainer = $('<div/>', { 'class': field.labelClass }).appendTo($group);
				$label = $('<label/>', { 'for': field.id, 'text': field.label }).appendTo($labelContainer);

				if (field.labelHintSummary)
					$('<small/>', {
						html: field.labelHintSummary,
						title: field.labelHintText
					}).appendTo($label);

				if (field.hasMultipleValues)
					$label.append(
						$('<span/>', { 'class': 'text-warning', 'append': uifactory.create.icon('th') })
							.css('marginLeft', 5)
							.attr('title', 'This field has multiple values specified.')
					);

				if (field.actions) {
					field.actions = $.makeArray(field.actions);

					$labelContainer.append($.map(field.actions, function (action) {
						if (!action) return undefined;

						var button = document.createElement('button');

						button.type = 'button';
						button.className = action['class'] || 'btn btn-tiny btn-default pull-right';

						if (action.disabled) button.disabled = true;

						if (action.alt) button.title = action.alt;

						if (action.icon) {
							if (action.icon instanceof $) action.icon.clone().appendTo(button);
							else uifactory.create.icon(action.icon).appendTo(button);

							if (action.title) button.appendChild(document.createTextNode(' '));
						}

						if (action.title) button.appendChild(document.createTextNode(action.title));
						if (typeof action.callback === 'function') button.onclick = action.callback;

						return button;
					}));
				}
			}

			if ($form.hasClass('form-inline')) {
				$inputContainer = $group;
			} else {
				$inputContainer = $('<div/>', { 'class': field.fieldContainerClass }).appendTo($group);
			}

			$input = uifactory.create.formInput[field.type] || uifactory.create.formInput.text;
			$input = $input(field).appendTo($inputContainer);

			if (typeof field.on === 'object')
				$inputContainer.on(field.on);

			if (typeof field.helpText === 'string')
				$('<p/>', { 'class': 'help-block', 'html': field.helpText }).appendTo($inputContainer);

			fieldValidateRules[i] = typeof field.validate === 'function' ? {
				validate: field.validate,
				deferredValidate: field.deferredValidate,
				group: $group,
				label: field.label,
				field: field
			} : undefined;

			fields.push({
				field: field,
				group: field.group,

				container: $group,
				inputContainer: $inputContainer,
				input: $input,
				labelContainer: $labelContainer,
				label: $label,
			});
		})(settings.fields[i], i);

		var groupContainers = {};

		if (settings.fieldGroupsCollapsable)
			$inputGroupContainer.on('click', '.field-group-header', function() {
				var $target = $($(this).attr('data-target'));
				$.fn.collapse.call($target, 'toggle');
				return false;
			}).on('hide.bs.collapse show.bs.collapse', function (e) {
				$(e.target).prev().toggleClass('collapsed', e.type === 'hide');
			});

		for (i = 0; i < fields.length; i++) {
			var field = fields[i];
			var group = field.group;
			var $field = field.container;

			if (!$field) continue;

			if (!group) $field.appendTo($inputGroupContainer);
			else {
				if (group in groupContainers === false) {
					var groupID = formGroupGUID++;

					var $groupHeader = $('<' + settings.fieldGroupHeaderElement + '/>', {
						'class': settings.fieldGroupHeaderClass + ' field-group-header',
						'text': group
					}).appendTo($inputGroupContainer);

					var $groupContainer = groupContainers[group] = $('<div/>', {
						'class': settings.fieldGroupClass,
						'id': 'rs-form-group-' + groupID
					}).appendTo($inputGroupContainer);

					if (settings.fieldGroupsCollapsable) {
						$groupHeader
							.attr({
								'data-toggle': 'collapse',
								'data-target': '#rs-form-group-' + groupID
							})
							.prepend($('<i/>', { 'class': 'collapse-indicator' }))
							.addClass('collapsed clickable');

						$groupContainer.addClass('collapse');
					}
				}

				groupContainers[group].append($field);
			}

			var type = field.field.type;

			if (type in uifactory.formInputSettings)
				uifactory.formInputSettings[type](field, {
					$form: $form,
					settings: settings
				});
		}

		if (settings.actions.length) {
			var $actionContainer = $('<div/>', { 'class': settings.actionContainerClass })
				.appendTo($('<div/>', { 'class': settings.outerActionContainerClass }).appendTo($form));

			for (i = 0; i < settings.actions.length; i++) {
				(function(action) {
					if (!action) return;

					action = $.extend({
						'buttonClass': 'btn btn-default',
						'label': 'Unnamed',
						'disabled': false,
						'id': ''
					}, action);

					var $button = action.action === 'link' ? $('<a/>', {
						'href': action.href,
						'id': action.id,
						'target': action.target || '_blank',
						'class': action.buttonClass,
						'html': action.label,
						'disabled': action.disabled
					}) : $('<button/>', {
						'type': action.action === 'submit' ? 'submit' : 'button',
						'class': action.buttonClass,
						'id': action.id,
						'html': action.label,
						'disabled': action.disabled,
						'click': function(e) {
							e.preventDefault();

							if (typeof action.action === 'function') action.action.apply(this, arguments);
							else if (action.action === 'submit') $form.submit();
						}
					});

					if (action.icon)
						uifactory.create.icon(action.icon).css('marginRight', 5).prependTo($button);

					$button.prependTo($actionContainer);
				})(settings.actions[i]);
			}
		}

		return $form
			.data('form', {
				validate: validate,
				getFormData: getFormData
			})
			.on('click', '.control-label', function() {
				$('#' + $(this).find('label').prop('for')).focus();
			});
	};

	uifactory.create.filterForm = function(settings) {
		settings = settings || {
			fields: undefined,
			filters: undefined,

			onReset: undefined,
			onSubmit: undefined
		};

		var $form;

		function updateHeaders() {
			var $headers = $form.find('.field-group-header');

			$headers.next().each(function (i) {
				var $this = $(this),
					data = {},
					dataCount = 0;

				$this.syncFormToObject(data);

				for (var k in data) 
					if (data.hasOwnProperty(k))
						dataCount++;

				$headers.eq(i).toggleClass('active', dataCount > 0 || $this.find('.static-filter').length > 0);
			});
		}

		var formSettings = $.extend({
			formClass: 'panel panel-primary with-dropdown filter-form',
			actions: [
				{
					'buttonClass': 'btn btn-primary btn-block',
					'label': 'Filter',
					'action': 'submit'
				}
			]
		}, settings, {
			onReset: function () {
				updateHeaders();
				if (typeof settings.onReset === "function") settings.onReset();
			},
			beforeSubmit: function (formData) {
				updateHeaders();
				if (typeof settings.onSubmit === "function") settings.onSubmit(formData);
			}
		});

		$form = uifactory.create.form(formSettings);

		updateHeaders();

		return $form;
	};
})(jQuery);