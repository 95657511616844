var rstools;
(function (rstools) {
    var messaging;
    (function (messaging) {
        window.addEventListener('message', function (event) {
            if (typeof event.data !== 'string')
                return;
            var parsedMessage = parseMessage(event);
            switch (parsedMessage.namespaceParts[0]) {
                case 'rstools-plugin':
                    rstools.plugins.receiveMessage(parsedMessage);
                    break;
            }
        }, false);
        function formatMessage(message) {
            var namespace = message.namespace, name = message.name, data = message.data;
            var key = namespace ? namespace + ':' + name : name;
            if (data)
                return key + '|' + JSON.stringify(data);
            return key;
        }
        messaging.formatMessage = formatMessage;
        function parseMessage(messageEvent) {
            var message = messageEvent.data;
            var separatorIndex = message.indexOf('|');
            var namespace;
            var name;
            var data;
            if (separatorIndex < 0) {
                name = message;
                data = undefined;
            }
            else {
                name = message.substring(0, separatorIndex);
                data = message.substring(separatorIndex + 1);
            }
            var namespaceSeparatorIndex = name.lastIndexOf(':');
            namespace = name.substring(0, namespaceSeparatorIndex);
            name = name.substring(namespaceSeparatorIndex + 1);
            if (typeof data === 'string') {
                data = JSON.parse(data);
            }
            return {
                namespace: namespace,
                namespaceParts: namespace.split(':'),
                name: name,
                data: data,
                origin: messageEvent.origin,
                source: messageEvent.source
            };
        }
        messaging.parseMessage = parseMessage;
    })(messaging = rstools.messaging || (rstools.messaging = {}));
})(rstools || (rstools = {}));
