
(function ($) {
    rter.mapController('index', function (context) {
        function updateRequiredRolesElements() {
            // Find elements on the page with data-required-roles and show or
            // hide them based on the role status
            $('[data-required-roles]').each(function () {
                var $this = $(this),
                    requiredRoles = $this.data('requiredRoles');

                var isHidden = false;
                $.each(requiredRoles.split(','), function (i, role) {
                    if (!role || typeof role !== "string") return;

                    var inverted = role[0] === '!';
                    var hasRole = rstools.auth.user.hasRole(inverted ? role.substr(1) : role);

                    if (inverted && hasRole || !inverted && !hasRole) {
                        $this.hide();
                        isHidden = true;
                        return false;
                    }
                });

                if (!isHidden) $this.show();
            });
        }

        rstools.events.on('userchanged', updateRequiredRolesElements);

        return {
            viewWillLoad: function (context) {
            },
            viewWillCreate: function ($view) {
            },
            viewCreated: function ($view) {
            },
            viewWillResume: function ($view) {
            },
            viewResumed: function ($view) {
            },
            viewLoaded: function (context) {
            },
            viewWillUnload: function ($view) {
            },
            viewWillDetatch: function ($view) {
            },
            viewDetatched: function ($view) {
            },
            viewWillRemove: function ($view) {
            },
            viewRemoved: function ($view) {
            },
            viewUnloaded: function ($view) {
            },
            viewChainChanged: function (context) {
                
            },
            viewStateChanged: function (context) {
                updateRequiredRolesElements();

                if (typeof context.$outlet != 'undefined') {
                    context.$outlet.find('input[type="checkbox"],input[type="radio"]').not("[data-icheck]").iCheck({
                        checkboxClass: 'icheckbox_square-blue',
                        radioClass: 'iradio_square-blue',
                        increaseArea: '20%' // optional
                    }).attr('data-icheck', true);
                }
            }
        };
    });
})(jQuery);