(function ($) {
    rter.mapController("app.message.list", function (context) {
        var grid = null;
        var filters = {};
        var $filterForm, $filterButton;

        return {
            viewWillLoad: function(ctx) {
                filters = $.extend(filters, ctx.state ? context.state["filters"] : {});
            },
            viewCreated: function ($view, ctx) {
                $filterForm = rstools.message.utils.createFilterForm(ctx, {
                    filters: filters,
                    onSubmit: filtersUpdated,
                    onReset: filtersUpdated
                });

                grid = datagrid.create({
                    container: $view.find("div.data-wrapper"),
                    allowShowAll: true,
                    fields: [
                        { name: "MessageTitle", caption: "Title" },
                        {
                            name: "MessageTags",
                            caption: "Tags",
                            sort: false,
                            format: rstools.message.createTagLabels
                        },
                        rstools.shared.columns.createLastUpdatedColumn("MessageUpdated", true),
                        { name: "actions", colClass: "text-right", caption: "", width: 155, sort: false }
                    ],
                    actions: [
                        {
                            title: "View Message",
                            icon: "fa fa-eye",
                            multiple: false,
                            fields: ["actions"],
                            callback: function (rows) {
                                var message = rows[0];

                                rstools.message.view(message);
                            }
                        },
                        rstools.shared.actions.createArchiveAction("message"),
                        rstools.shared.actions.createUnarchiveAction("message")
                    ],
                    
                    rowCallback: function ($tr, message) {
                        if (message.MessageArchived) $tr.addClass("archived-data-object");
                    },

                    getAction: "Message/Get",
                    countAction: "Message/Count",
                    resultListField: "Messages",
                    namespace: "message"
                });

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .prependTo(grid.topControls)
                    .trigger("filters-updated", [Object.keys(filters).length]);
            },
            viewLoaded: function (ctx) {
                // Update filters from the current ctx
				// or grab them from the original context
                filters = $.extend(filters, ctx.state ? context.state["filters"] : {});

                $filterForm.syncObjectToForm(filters);
            }
        };

        function filtersUpdated() {
            $filterForm.syncFormToObject(filters);
            $filterButton.trigger("filters-updated", [Object.keys(filters).length]);
            
            grid.search(filters);
            rstools.state.set("filters", filters);
        }
    });
})(jQuery);