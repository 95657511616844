(function () {
    uifactory.create.dataEditForm = function (namespace, settings) {
        namespace = rstools.utils.getNamespace(namespace);

        settings = $.extend({
            type: undefined, // create: Create a new object, edit: Edit the specified data object
            isMultiple: false, // If true, we're editing multiple data objects with the same form
            data: {}, // The data to pre-populate the form with
            dataFields: {}, // Updates to fields based off of recieved data, keys are the ID of the field, value objects extend the field
            filters: {}, // The match data objects to update 
            submitSuccessCallback: undefined, // A function to call when the data has been successfully submitted
            onCancel: undefined, // A function to call if the cancel button is clicked,
            actions: [], // Additional actions to add to the form,
            beforeSubmit: undefined // A function to call before submitting data to RideStyler, used for modifying the request
        }, settings);

        var type = settings.type;
        var data = settings.data;
        
        if (type !== 'edit' && type !== 'create') throw "You must specify type as `edit` or `create`";        
        if (type === 'edit' && 'editAction' in namespace === false) throw "Missing editAction from namespace";
        if (type === 'create' && 'createAction' in namespace === false) throw "Missing createAction from namespace";
        if ('noun' in namespace === false) throw 'Missing noun from namespace';
        if ('nounPlural' in namespace === false) throw 'Missing nounPlural from namespace';
        if ('settings' in namespace === false) throw 'Missing settings from namespace';
        if ('fields' in namespace.settings === false) throw 'Missing fields from namespace.settings';

        var url = settings.type === 'edit' ? namespace.editAction : namespace.createAction;

        var fields = $.map(namespace.settings.fields, function (field) {
            field = $.extend({}, field);

            // If we're creating a new object, the field will never have multiple values
            if (type === 'create') field.hasMultipleValues = false;
            
            // If the field has a function for hasMultipleValues, call it to populate the property
            else if (typeof field.hasMultipleValues === 'function') field.hasMultipleValues = field.hasMultipleValues.call(field, data);
            
            // If the field's type is static, don't mark the field as having multiple values
            else if (type === 'create' || field.type === 'static') field.hasMultipleValues = false;

            // Otherwise, mark it if it was pulled out of the data object by findLikeData
            else field.hasMultipleValues = field.name in data === false;

            // Set value from the data
            if (typeof field.applyValue === 'function') field.value = field.applyValue.call(field, data);
            else if (field.name && field.name in data) field.value = data[field.name];

            // No fields are required if we're editing multiple objects
            if (settings.isMultiple) field.required = false;

            // Extend fields with values from the settings
            if (field.id && field.id in settings.dataFields) {
                field = $.extend({}, field, settings.dataFields[field.id]);
            }

            return field;
        });

        var actions = [
            {
                label: type === 'create' ? 'Create' : 'Save',
                icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
                buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
                action: 'submit'
            },
            {
                label: 'Cancel',
                action: settings.onCancel
            }
        ];

        if (settings.actions) actions = actions.concat(settings.actions);

        var noun = settings.isMultiple ? namespace.nounPlural : namespace.noun;
        var typeImmediateVerb = type === 'create' ? 'creating' : 'editing';
        var typePastVerb = type === 'create' ? 'created' : 'edited';
        
        return uifactory.create.form({
            url: url,
            submitErrorMessage: 'There was a problem ' + typeImmediateVerb + ' the ' + noun + ': {Message}.',

            submitSuccessCallback: function () {
                uifactory.alert.show({
                    text: !settings.isMultiple ? 
                        "The " + noun + " has been " + typePastVerb : 
                        "The " + noun + " have been " + typePastVerb
                });

                if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback();
            },

            fields: fields,
            baseData: settings.filters,
            beforeSubmit: settings.beforeSubmit,
            actions: actions
        });
    };
})();