(function ($) {
    rter.mapController('app.tire.fitment', function (context) {
        var grid = null;
        var $filterForm, $filterButton;
        var $createButton;
        var $backToModelsButton;

        var $headerPaste;

        var filters = {};

        var createCombinedFilters = function (rows, forAPI, loosenFilters) {
            return rstools.utils.getFiltersFor('tire.fitment', {
                rows: rows,
                forAPI: forAPI,
                loosenFilters: loosenFilters,
                filters: filters
            });
        };

        return {
            viewWillLoad: function (ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($outlet) {
                grid =
                    datagrid.create({
                        container: $outlet.find('div.data-wrapper'),

                        allowShowAll: true,
                        allowCutCopy: true,

                        headerActions: [
                            rstools.shared.datagrid.headerActions.manageColumns
                        ],
                        getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,

                        fields: [
                            { 
                                name: 'TireFitmentPartNumber', 
                                caption: 'Part #', 
                                format: function (/** @type {TireFitmentDescriptionModel} */ fitment) {
                                    var partNumber = fitment.TireFitmentPartNumber;

                                    if (!partNumber) {
                                        var element = document.createElement('span');
                                            element.className = 'text-muted';
                                            element.appendChild(document.createTextNode('None'));

                                        return element;
                                    }

                                    return partNumber;
                                }
                            },
                            
                            { name: 'TireBrandName', caption: 'Brand', sort: false },
                            { name: 'DataSourceDescription', caption: 'Data Source', sort: false },
                            { name: 'TireModelName', caption: 'Model', sort: false },

                            {
                                name: 'TireFitmentResource',
                                caption: 'Images',
                                colClass: 'no-padding',
                                sort: false,
                                columnRenderer: function ($tds, data) {
                                    return rstools.resource.utils.columnRenderer($tds, data, {
                                        resourceMatchesObject: function (fitmentResource, fitment) {
                                            return fitment.TireFitmentID === fitmentResource.TireFitmentResource_TireFitmentID;
                                        },
                                        getResourceType: function (fitmentResource) {
                                            return rstools.constants.getString(rstools.constants.TireFitmentResourceType, fitmentResource.TireFitmentResourceType);
                                        },
                                        getResourceID: rstools.tire.fitment.resource.utils.getResourceID,
                                        showStatusOnly: !rstools.user.isSystemAdmin(),
                                        request: {
                                            action: 'Tire/GetFitmentResources',
                                            responseKey: 'Resources',
                                            data: {
                                                UsePromotionOrder: false,
                                                HasFitments: false,
                                                SelectBrandsOnly: false,
                                                TireFitments: rstools.tire.fitment.utils.getIDs(data),
                                                TireFitmentResourceTypes: ['Side', 'Tread', 'Catalog'],
                                                Variant: rstools.constants.TireFitmentResourceVariant.PrimarySidewall
                                            }
                                        },
                                        resourceTypes: ['Side', 'Tread', 'Catalog']
                                    });
                                }
                            },
                            {
                                name: 'TireFitmentResourceOther',
                                caption: 'Images (Optional)',
                                colClass: 'no-padding',
                                sort: false,
                                visible: false,
                                columnRenderer: function ($tds, data) {
                                    return rstools.resource.utils.columnRenderer($tds, data, {
                                        resourceMatchesObject: function (fitmentResource, fitment) {
                                            return fitment.TireFitmentID === fitmentResource.TireFitmentResource_TireFitmentID;
                                        },
                                        getResourceType: function (fitmentResource) {
                                            return rstools.constants.getString(rstools.constants.TireFitmentResourceType, fitmentResource.TireFitmentResourceType);
                                        },
                                        getResourceID: rstools.tire.fitment.resource.utils.getResourceID,
                                        showStatusOnly: !rstools.user.isSystemAdmin(),
                                        request: {
                                            action: 'Tire/GetFitmentResources',
                                            responseKey: 'Resources',
                                            data: {
                                                UsePromotionOrder: false,
                                                HasFitments: false,
                                                SelectBrandsOnly: false,
                                                TireFitments: rstools.tire.fitment.utils.getIDs(data),
                                                TireFitmentResourceTypes: ['Optional1', 'Optional2', 'Optional3'],
                                                Variant: rstools.constants.TireFitmentResourceVariant.PrimarySidewall
                                            }
                                        },
                                        resourceTypes: ['Option 1', 'Option 2', 'Option 3']
                                    });
                                }
                            },
                            {
                                name: 'TireFitmentResourceVariant',
                                caption: 'Alternate Sidewall Images',
                                colClass: 'no-padding',
                                sort: false,
                                visible: false,
                                columnRenderer: function ($tds, data) {
                                    return rstools.resource.utils.columnRenderer($tds, data, {
                                        resourceMatchesObject: function (fitmentResource, fitment) {
                                            return fitment.TireFitmentID === fitmentResource.TireFitmentResource_TireFitmentID;
                                        },
                                        getResourceType: function (fitmentResource) {
                                            return rstools.constants.getString(rstools.constants.TireFitmentResourceType, fitmentResource.TireFitmentResourceType);
                                        },
                                        getResourceID: rstools.tire.fitment.resource.utils.getResourceID,
                                        showStatusOnly: !rstools.user.isSystemAdmin(),
                                        request: {
                                            action: 'Tire/GetFitmentResources',
                                            responseKey: 'Resources',
                                            data: {
                                                UsePromotionOrder: false,
                                                HasFitments: false,
                                                SelectBrandsOnly: false,
                                                TireFitments: rstools.tire.fitment.utils.getIDs(data),
                                                TireFitmentResourceTypes: ['Side', 'Tread', 'Catalog'],
                                                Variant: rstools.constants.TireFitmentResourceVariant.AlternateSidewall
                                            }
                                        },
                                        resourceTypes: ['Side', 'Tread', 'Catalog']
                                    });
                                }
                            },
                            {
                                name: 'TireFitmentResourceVariantOther',
                                caption: 'Alternate Sidewall Images (Optional)',
                                colClass: 'no-padding',
                                sort: false,
                                visible: false,
                                columnRenderer: function ($tds, data) {
                                    return rstools.resource.utils.columnRenderer($tds, data, {
                                        resourceMatchesObject: function (fitmentResource, fitment) {
                                            return fitment.TireFitmentID === fitmentResource.TireFitmentResource_TireFitmentID;
                                        },
                                        getResourceType: function (fitmentResource) {
                                            return rstools.constants.getString(rstools.constants.TireFitmentResourceType, fitmentResource.TireFitmentResourceType);
                                        },
                                        getResourceID: rstools.tire.fitment.resource.utils.getResourceID,
                                        showStatusOnly: !rstools.user.isSystemAdmin(),
                                        request: {
                                            action: 'Tire/GetFitmentResources',
                                            responseKey: 'Resources',
                                            data: {
                                                UsePromotionOrder: false,
                                                HasFitments: false,
                                                SelectBrandsOnly: false,
                                                TireFitments: rstools.tire.fitment.utils.getIDs(data),
                                                TireFitmentResourceTypes: ['Optional1', 'Optional2', 'Optional3'],
                                                Variant: rstools.constants.TireFitmentResourceVariant.AlternateSidewall
                                            }
                                        },
                                        resourceTypes: ['Option 1', 'Option 2', 'Option 3']
                                    });
                                }
                            },
                            {
                                name: 'TireFitmentWheelWidthMin',
                                caption: 'Width',
                                colClass: 'text-center',
                                format: function (r) {
                                    return r.TireFitmentWheelWidthMin == r.TireFitmentWheelWidthMax ?
                                    [r.TireFitmentWheelWidthMin, uifactory.create.abbr('in', 'inches')] :
                                    [r.TireFitmentWheelWidthMin, uifactory.create.abbr('in', 'inches'), ' - ', r.TireFitmentWheelWidthMax, uifactory.create.abbr('in', 'inches')];
                                }
                            },
                            { name: 'TireFitmentUTQG', caption: 'UTQG', visible: false },
                            {
                                name: 'TireFitmentPrimary_TireSidewallID',
                                caption: 'Sidewall',
                                colClass: 'text-center',
                                format: function (r) {
                                    if (r.TireSidewall)
                                        return rstools.tire.sidewall.createAbbrElement(r.TireSidewall);
                                }
                            },
                            {
                                name: 'TireFitmentSecondary_TireSidewallID',
                                caption: 'Sidewall Alternative',
                                visible: false,
                                colClass: 'text-center',
                                format: function (r) {
                                    if (r.TireSidewallAlt)
                                        return rstools.tire.sidewall.createAbbrElement(r.TireSidewallAlt);
                                }
                            },
                            {
                                name: 'TireFitment_TireSizeID',
                                caption: 'Tire Size',
                                format: function (r) {
                                    return r.TireSize.Description;
                                }
                            },
                            { 
                                name: 'TireFitmentUseType', 
                                caption: 'Use Type',
                                colClass: 'text-center small',
                                visible: false,
                                format: function (/** @type {TireFitmentDataObject} */ fitment) {
                                    return rstools.constants.TireUseTypeFlags[fitment.TireFitmentUseType];
                                }
                            },
                            {
                                name: 'TireFitmentWarrantyType',
                                caption: 'Warranty',
                                colClass: 'text-center',
                                format: function (/** @type {TireFitmentDataObject} */ fitment) {
                                    /** @type {TireWarrantyType} */ 
                                    var TireWarrantyType = rstools.constants.TireWarrantyType;
                                    
                                    if (fitment.TireFitmentWarrantyType == TireWarrantyType.Mileage) {
                                        return [fitment.TireFitmentWarrantyMileage, uifactory.create.abbr('mi', 'miles')];
                                    } else {
                                        return TireWarrantyType[fitment.TireFitmentWarrantyType];
                                    }
                                }
                            },
                            { 
                                name: 'TireFitmentDataStatus', 
                                caption: 'Status',
                                colClass: 'text-center',
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false,
                                format: rstools.datastatus.createColumn('tire.fitment')
                            },
                            rstools.shared.columns.createLastUpdatedColumn('TireFitmentUpdated'),
                            {
                                name: 'TireFitmentNotes',
                                caption: 'Notes',
                                colClass: 'text-center',
                                sort: false,
                                columnRenderer: rstools.notes.createColumnRenderer('tire.fitment'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
                            },
                            { name: 'actions', caption: '', width: 50, sort: false }
                        ],
                        actions: [
                            {
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: true,
                                forMenu: true,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                callback: function (rows) {
                                    rstools.tire.fitment.edit(createCombinedFilters(rows));
                                }
                            },
                            {
                                title: 'Images',
                                icon: 'fa fa-picture-o',
                                multiple: true,
                                forMenu: true,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                callback: function (rows) {
                                    rstools.tire.fitment.manageResources(createCombinedFilters(rows, true));
                                }
                            },
                            {
                                title: 'Duplicate',
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                icon: 'fa fa-clone',
                                multiple: true,
                                fields: ['actions'],
                                forMenu: true,
                                callback: function (rows) {
                                    rstools.tire.fitment.duplicate(createCombinedFilters(rows, true, true));
                                }
                            },
                            {
                                title: 'Manage Promotions',
                                icon: 'fa fa-tags',
                                multiple: true,
                                forMenu: true,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.TIRES],
                                callback: function (rows) {
                                    rstools.promotion.manage('tire.fitment', createCombinedFilters(rows, true));
                                }
                            },
                            {
                                title: 'Edit Pricing',
                                icon: 'fa fa-usd',
                                multiple: true,
                                forMenu: true,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.TIRES],
                                callback: function (rows) {
                                    rstools.pricing.edit({
                                        action: 'Tire/GetPrices',
                                        countAction: 'Tire/CountPrices',
                                        updateAction: 'Tire/EditPrice',
                                        createAction: 'Tire/CreatePrice',
                                        deleteAction: 'Tire/DeletePrice',
                                        namespace: 'tire-fitment-pricing', 
                                        filters: createCombinedFilters(rows, true, true)
                                    });
                                }
                            },
                            rstools.shared.actions.createArchiveAction("tire.fitment", {
								defaultFilters: rstools.tire.utils.loosenFilters('fitments')
							}),
                            rstools.shared.actions.createUnarchiveAction("tire.fitment", {
								defaultFilters: rstools.tire.utils.loosenFilters('fitments')
							}),
                            rstools.meta.actions.edit('tire.fitment'),
                            rstools.datastatus.actions.markValid('tire.fitment'),
                            rstools.datastatus.actions.markInvalid('tire.fitment'),
                            rstools.datastatus.actions.markNeedsReviewed('tire.fitment'),
                            rstools.datastatus.actions.markReviewed('tire.fitment')
                        ],
                        getAction: 'Tire/GetFitmentDescriptions',
                        countAction: 'Tire/CountFitmentDescriptions',
                        resultListField: 'Fitments',
                        baseQueryData: {
                            SelectBrandsOnly: false,
                            UsePromotionOrder: false,
                            UseBrandPriorities: false,
                            HasFitments: false
                        },
                        
                        rowCallback: function ($tr, /** @type {TireFitmentDescriptionModel} */ fitmentDescription) {
                            if (fitmentDescription.TireFitmentArchived) $tr.addClass('archived-data-object');
                        },

                        search: rstools.tire.utils.filterFormDataToTireFilters(filters),
                        namespace: 'tire-fitment'
                    });

                $filterForm = rstools.tire.utils.createTireFilterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });

                if (rstools.user.isSystemAdmin()) {
                    var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

                    $headerPaste =  $('<button/>', {
                        'class': 'btn btn-default',
                        'append': uifactory.create.icon('clipboard'),
                        'title': 'Paste Fitments',
                        'appendTo': $rightButtons,
                        'click': function() {
                            var clipboard = datagrid.getClipboard();
                            var clipboardIDs;

                            var modelID = 'TireModel' in filters ? filters.TireModel : filters.TireModels[0];

                            if (clipboard.from.indexOf("Tire/GetFitment") >= 0) {
                                clipboardIDs = $.map(clipboard.rows, function (fitment) {
                                    return fitment.TireFitmentID;
                                });

                                if (clipboard.action === 'copy') 
                                    rstools.tire.fitment.copyTo({
                                        TireFitments: clipboardIDs
                                    }, modelID);

                                else if (clipboard.action === 'cut')
                                    rstools.tire.fitment.moveTo({
                                        TireFitments: clipboardIDs
                                    }, modelID, {
                                        callback: function () {
                                            datagrid.clearClipboard();
                                        }
                                    });
                            }
                        }
                    });

                    $headerPaste[datagrid.hasClipboardMatching('Tire/GetFitment') ? 'show' : 'hide']();

                    rstools.events.on('datagridClipboardChanged', function () {
                        var hasModelFilter = 'TireModels' in filters || 'TireModel' in filters;
                        $headerPaste[datagrid.hasClipboardMatching('Tire/GetFitment') && hasModelFilter ? 'show' : 'hide']();
                    });

                    $createButton = $('<button/>', {
                        'class': 'btn btn-primary',
                        'append': [
                            'Create Fitment &nbsp;',
                            uifactory.create.icon('plus')
                        ],
                        'appendTo': $rightButtons,
                        'click': function() {
                            var firstModelID;

                            if ('TireModels' in filters && filters.TireModels.length) firstModelID = filters.TireModels[0];
                            else if (filters.TireModel) firstModelID = filters.TireModel;

                            rstools.tire.fitment.create({
                                TireFitment_TireModelID: firstModelID
                            });
                        }
                    });
                }

                var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

                $backToModelsButton = $('<a/>', {
                    'class': 'btn btn-info',
                    'append': [
                        uifactory.create.icon('caret-left'),
                        ' Models'
                    ],
                    'href': '#!/app/tire/model'
                }).appendTo($leftButtons);

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .appendTo($leftButtons)
                    .trigger('filters-updated', [Object.keys(filters).length]);
            },
            viewLoaded: contextChanged,
            viewChainChanged: function (ctx) {
                contextChanged(ctx);
                grid.search(rstools.tire.utils.filterFormDataToTireFilters(filters));
            }
        };

        function contextChanged(ctx) {
            filters = ctx.state.filters || {};

            $filterForm.syncObjectToForm(filters);

            var hasModelFilter = 'TireModels' in filters || 'TireModel' in filters;

            $createButton && $createButton[hasModelFilter ? 'show' : 'hide']();

            $headerPaste && $headerPaste[datagrid.hasClipboardMatching('Tire/GetFitment') && hasModelFilter ? 'show' : 'hide']();
            
            rstools.utils.setBackButtonTargetFromContext($backToModelsButton, ctx, '/app/tire/model');
        }

        function filtersUpdated() {
            $filterForm.syncFormToObject(filters);
            $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(rstools.tire.utils.filterFormDataToTireFilters(filters));
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);