(function ($) {
    rter.mapController('logout', function (context) {
        function logOut() {
            ridestyler.auth.set();

            uifactory.alert.show({
                text: "You have been logged out."
            });

            rter.routes.go('/login');
        }

        return {
            viewLoaded: function (context) {
                setTimeout(logOut, 1000);
            }
        };
    });
})(jQuery);