(function ($) {
	rter.mapController('app.wheel.brand', function (context) {
		var grid = null;
		var $filterForm, $filterButton;

		var filters = {};

		var createCombinedFilters = function (rows, forAPI, loosenFilters) {
            return rstools.utils.getFiltersFor('wheel.brand', {
                rows: rows,
                forAPI: forAPI,
                loosenFilters: loosenFilters,
                filters: filters
            });
        };

		return {
			viewWillLoad: function(ctx) {
				filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
			},
			viewCreated: function ($view) {
				$filterForm = rstools.wheel.utils.createWheelFilterForm(context, {
					onReset: filtersUpdated,
					onSubmit: filtersUpdated,
					filters: filters
				});

				grid =
					datagrid.create({
						container: $view.find('div.data-wrapper'),
						allowShowAll: true,
						fields: [
							{
								name: 'WheelBrandLogo',
								caption: 'Logo',
								colClass: 'text-center',
								sort: false,
								columnRenderer: rstools.shared.columns.createResourceColumn('wheel.brand', {
									baseFilters: {
										UsePromotionOrder: false,
										HasFitments: false,
										SelectBrandsOnly: false
									},
									width: 100,
									height: 55,

									noImageElement: 'No Logo',

									onImageLoaded: function ($image, brand) {
										$image.wrap($('<a/>', {
											href: '#',
											title: 'Manage Logo',
											click: function () {
												rstools.wheel.brand.manageResources(createCombinedFilters([brand], true), {
													header: brand.WheelBrandName
												});

												return false;
											}
										}));
									}
								})
							},
							{ name: 'WheelBrandName', caption: 'Brand' },
							{ name: 'DataSourceCode', caption: 'Source', sort: false },
							rstools.shared.columns.createLastUpdatedColumn('WheelBrandUpdated'),
                            {
								name: 'WheelBrandNotes',
								caption: 'Notes',
								colClass: 'text-center',
								sort: false,
								columnRenderer: rstools.notes.createColumnRenderer('wheel.brand'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
							},
							{ name: 'actions', caption: '', width: 155, sort: false },
							{
								name: 'include',
								caption: '',
								allowToBeHidden: false,
								width: '100px',
								requiredRoles: [rstools.roles.WHEELS],
								format: function (r) {
									return rstools.wheel.brand.createBrandInclusionButtons(r);
								},
								sort: false
							}
						],
						actions: [
							{
								title: 'Manage Promotions',
								icon: 'fa fa-tags',
								multiple: true,
								fields: ['actions'],
								requiredRoles: [rstools.roles.WHEELS],
								callback: function (rows) {
                                    rstools.promotion.manage('wheel.brand', createCombinedFilters(rows, true));
								}
							},
							{
								title: 'Enable',
								icon: 'fa fa-check',
								multiple: true,
								fields: [],
								requiredRoles: [rstools.roles.WHEELS],
								appliesTo: function (brand) {
									return rstools.inclusion.unlocked(brand) && rstools.inclusion.disabled(brand);
								},
								callback: function (rows) {
									rows = $.map(rows, function(brand) {
										if (rstools.inclusion.locked(brand) || rstools.inclusion.enabled(brand)) return undefined;

										return {
											WheelBrandID: brand.WheelBrandID
										};
									});

									ridestyler.ajax.send({
										action: 'Wheel/ChangeBrandInclusion',
										data: {
											Objects: rows,
											Status: 1
										},
										callback: function (response) {
											if (response.Success) {
												for (var i = 0; i < length; i++)
													rows[i].InclusionStatus = 1;

												rstools.events.dataChanged('wheel-brand', 'inclusion-changed');
												grid.refresh();
											}
										}
									});
								}
							},
							{
								title: 'Edit',
								requiredRoles: [rstools.roles.SYSTEM_ADMIN],
								forMenu: true,
								icon: 'fa fa-pencil',
								multiple: true,
								fields: ['actions'],
								callback: function (rows) {
									rstools.wheel.brand.edit(createCombinedFilters(rows, true));
								}
							},
							{
								title: 'Images',
								icon: 'fa fa-picture-o',
								multiple: true,
								fields: ['actions'],
								callback: function (rows) {
									var modalSettings = {};

									if (rows.length === 1)
										modalSettings.header = '<strong>' + (rstools.user.isSystemAdmin() ? 'Manage' : 'View') + 
																' Brand Resources</strong>: ' + rows[0].WheelBrandName;

									rstools.wheel.brand.manageResources(createCombinedFilters(rows, true), modalSettings);
								}
							},
                            rstools.shared.actions.createArchiveAction("wheel.brand", {
								defaultFilters: rstools.wheel.utils.loosenFilters('brands')
							}),
                            rstools.shared.actions.createUnarchiveAction("wheel.brand", {
								defaultFilters: rstools.wheel.utils.loosenFilters('brands')
							}),
							rstools.meta.actions.edit('wheel.brand'),
							{
								title: 'List Models',
								icon: 'fa fa-th-list',
								id: 'list',
								multiple: true,
								fields: ['actions'],
								callback: function (rows) {
									rter.routes.go('/app/wheel/model', { filters: createCombinedFilters(rows) });
								}
							}
						],
						rowCallback: function ($tr, brandDescription) {
							if (brandDescription.WheelBrandArchived) $tr.addClass('archived-data-object');
						},
						headerActions: [
							rstools.shared.datagrid.headerActions.manageColumns
						],
						getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
						headerActionColumn: 'include',
						getAction: 'wheel/getbranddescriptions',
						countAction: 'wheel/countbranddescriptions',
						resultListField: 'Brands',
						baseQueryData: {
							SelectBrandsOnly: !rstools.user.hasRole(rstools.roles.WHEELS),
							UsePromotionOrder: false,
							UseBrandPriorities: false,
							HasFitments: false
						},
						search: rstools.wheel.utils.filterFormDataToWheelFilters(filters),
						namespace: 'wheel-brand',
						dataChangedExceptions: ['inclusion-changed'],
						rowSelect: function (brand) {
							grid.action.getByID('list').callback([brand]);							
						}
					});

				$filterButton = uifactory.create.filterButton({
						menu: $filterForm
					})
						.prependTo(grid.topControls)
						.trigger('filters-updated', [Object.keys(filters).length]);

				var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

				rstools.user.isSystemAdmin() && $('<button/>', {
					'class': 'btn btn-primary pull-right',
					'append': [
						'Create Brand &nbsp;',
						uifactory.create.icon('plus')
					],
					'appendTo': $rightButtons,
					'click': function() {
						rstools.wheel.brand.create();
					}
				});
			},
			viewLoaded: function (ctx) {
				// Update filters from the current ctx
				// or grab them from the original context
				filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

				$filterForm.syncObjectToForm(filters);
			}
		};

		function filtersUpdated() {
			$filterForm && $filterForm.syncFormToObject(filters);
			$filterButton && $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

			grid.search(rstools.wheel.utils.filterFormDataToWheelFilters(filters));
			rstools.state.set('filters', filters);
		}
	});
})(jQuery);