(function () {
	var textInputTypes = ['email', 'text', 'number', 'checkbox', 'text-search', 'password'];
	var formFieldGUID = 0;
	var formGroupGUID = 0;

	var formInputTypes = {
		'cc-cvc': function(field) {
			var $input = $('<input/>', {
				'type': 'text',
				'disabled': field.disabled,
				'placeholder': field.placeholder,
				'required': field.required,
				'class': field.fieldClass,
				'id': field.id,
				'name': field.name
			}).payment('formatCardCVC');

			if (!field.validate) field.validate = function (formData) {
				var value = $input.val();
				if (field.required && !value) return 'This field is required.';

				var cardType = $.payment.cardType(formData[field.CardNumberField || 'CardNumber']);
				if ($.payment.validateCardCVC(value, cardType) == false) return "Invalid CVC for card";

				return undefined;
			};

			return $input;
		},
		'cc-expiry': function (field) {
			var $input = $('<input/>', {
				'type': 'text',
				'disabled': field.disabled,
				'placeholder': field.placeholder,
				'required': field.required,
				'class': field.fieldClass,
				'id': field.id,
				'name': field.name
			}).payment('formatCardExpiry');

			if (!field.validate) field.validate = function () {
				var value = $input.val();
				if (field.required && !value) return 'This field is required.';

				var expDate = $.payment.cardExpiryVal(value);
				if ($.payment.validateCardExpiry(expDate.month, expDate.year) == false) return "Invalid expiration date";

				return undefined;
			};

			return $input;
		},
		'cc-number': function (field) {
			var $input = $('<input/>', {
				'type': 'text',
				'disabled': field.disabled,
				'required': field.required,
				'placeholder': field.placeholder,
				'class': field.fieldClass,
				'id': field.id,
				'name': field.name
			}).payment('formatCardNumber');

			if (!field.validate) field.validate = function () {
				var value = $input.val();
				if (field.required && !value) return 'This field is required.';

				var cardType = $.payment.cardType(value);
				if ($.payment.validateCardNumber(value) == false || cardType == null) return "Invalid card number.";

				return undefined;
			};

			return $input;
		},
		'container': function (field) {
			return $('<div/>', {
				'class': field.fieldClass,
				'html': field.value,
				'id': field.id
			}).removeClass('form-control');
		},
		'datetime': function (field) {
			var dateTimeFormat = "MMM DD YYYY hh:mm A";

			field.value = field.value ? rstools.utils.momentFromDatabaseDateTime(field.value) : undefined;

			var formattedValue = field.value ? field.value.format(dateTimeFormat) : undefined;

			var $input = $('<input/>', {
				'type': 'text',
				'disabled': field.disabled,
				'required': field.required,
				'class': field.fieldClass,
				'id': field.id,
				'name': field.name,
				'data-type': 'datetime',
				'autocomplete': 'off',
				'value': formattedValue,
				'on': {
					'click': function() {
						$input.filthypillow('show');
					},
					'keyup': function (e) {
						if (e.keyCode == 8 || e.keyCode == 46) {
							$input.val('');
							$input.filthypillow("hide");
						} else if (e.keyCode == 27) {
							$input.filthypillow('hide');
						} else if (e.keyCode == 13) {
							return;
						} else {
							$input.filthypillow("show");
						}
					},
					'fp:save': function(e, dateObj) {
						$input.val(dateObj.format(dateTimeFormat));
						$input.filthypillow("hide");
					}
				}
			}).filthypillow({
				saveOnDateSelect: true,
				initialDateTime: function() {
					var value = field.value;

					if ($input) {
						var inputValue = $input.val();

						if (inputValue) value = moment(inputValue, dateTimeFormat);
					} 

					return value || new Date();
				}
			});

			return $input;
		},
		'dropdown': function (field) {
			var originalField = field;

			field = $.extend({
				buttonClass: 'btn-default',
				initialOptions: undefined,
				finalOptions: undefined,
				responseKey: undefined,
				multiple: false,
				clearWhenFalse: false
			}, field);

			if ($.isArray(field.options)) {
				if ($.isArray(field.initialOptions))
					field.options = field.initialOptions.concat(field.options);

				if ($.isArray(field.finalOptions))
					field.options = field.options.concat(field.finalOptions);
			}

			var $input = uifactory.create.dropdown({
				options: field.options,
				value: field.value,
				buttonClass: field.buttonClass,
				multiple: field.multiple,
				clearWhenFalse: field.clearWhenFalse,
				selectedValueFilter: field.selectedValueFilter
			});

			var dropdown = $input.data('rs-dropdown');

			dropdown.elements.group.addClass('btn-block');
			dropdown.elements.button.addClass('btn-block');
			dropdown.elements.menu.addClass('dropdown-menu-full');

			if (typeof field.url === 'string') {
				field.reload = originalField.reload = field.originalField.reload = function () {
					$input.trigger('rs-dropdown-loaded', [undefined, true]);

					ridestyler.ajax.send({
						action: field.url,
						data: field.data,
						callback: function (response) {
							if (!response.Success || !response[field.responseKey]) return;
							var options = $.map(response[field.responseKey], field.resultMap);

							if ($.isArray(field.initialOptions))
								options = field.initialOptions.concat(options);

							if ($.isArray(field.finalOptions))
								options = options.concat(field.finalOptions);

							$input.trigger('rs-dropdown-loaded', [options, true]);
						}
					});
				};

				field.reload();
			}

			$input
				.attr({
					'id': field.id,
					'data-type': field.dataType,
					'data-name': field.name
				})
				.change(function () {
					dropdown.elements.button.focus();
				});

			if (field.disabled) $input.addClass('disabled');
			if (field.fieldClass) $input.addClass(field.fieldClass);
			if (typeof field.onChange === 'function') $input.change(field.onChange);

			if (!field.validate) field.validate = originalField.validate = field.originalField.validate = function () {
				if (field.required) {
					if (dropdown.settings.multiple) {
						if (!dropdown.selectedOptions || !dropdown.selectedOptions.length || !dropdown.selectedOptions[0].value) return 'This field is required';
					} else if (!dropdown.selectedOption || !dropdown.selectedOption.value) {
						return 'This field is required';
					}
				}

				return undefined;
			};

			return $input.removeClass('form-control');
		},
		'file': function (field) {
			var $input = $('<input/>', {
				'type': 'file',
				'disabled': field.disabled,
				'required': field.required,
				'class': field.fieldClass,
				'id': field.id,
				'name': field.name,
				'accept': field.accept
			});
			
			if (typeof field.onChange === 'function') $input.change(field.onChange);

			if (!field.validate) field.validate = function () {
				var input = $input.get(0);
				if (field.required && !input.value) return 'This field is required.';
				return undefined;
			};

			return $input;
		},
		'hidden': function (field) {
			return $('<input/>', {
				'type': 'hidden',
				'id': field.id,
				'name': field.name,
				'value': field.value
			});
		},
		'image-upload': function (field) {
			field = $.extend({
				requestAction: '',
				requestData: {},
				uploadAction: '',
				uploadData: undefined,
				width: 150,
				height: 70,
				positionX: 'Center',
				positionY: 'Center',
				noImageText: undefined,
				successMessage: undefined,
				failureMessage: undefined
			}, field);

			return uifactory.create.imageUploader({
				src: ridestyler.ajax.url(field.requestAction, $.extend({
					Width: field.width,
					Height: field.height,
					PositionX: field.positionX,
					PositionY: field.positionY
				}, field.requestData)),
				noImageText: field.noImageText,
				successMessage: field.successMessage,
				uploadAction: field.uploadAction,
				uploadParams: field.uploadData || field.requestData
			});
		},
		'range': function (field) {
			var originalField = field;

			field = $.extend({
				minFieldID: field.id + '-min',
				maxFieldID: field.id + '-max',
				minPlaceholder: undefined,
				maxPlaceholder: undefined,
				minFieldName: undefined,
				maxFieldName: undefined,
				minRequired: field.required,
				maxRequired: field.required
			}, field);

			if (!$.isArray(field.value)) field.value = [];
			else {
				field.value = $.map(field.value, function (value) {
					return isNaN(value) ? false : value;
				});
				field.value.length = 2;
			}

			var $rangeContainer = $('<div/>', { 'class': 'row' }),
				$leftContainer = $('<div/>', { 'class': 'col-sm-5' }).appendTo($rangeContainer),
				$middleContainer = $('<div/>', { 'class': 'col-sm-2 text-muted text-center', 'text': 'to' }).appendTo($rangeContainer),
				$rightContainer = $('<div/>', { 'class': 'col-sm-5' }).appendTo($rangeContainer),
				$combinedContainer = $('<div/>', { 'class': 'col-sm-12' }).appendTo($rangeContainer);

			var $minInput, $maxInput, $combinedInput;

			$minInput = $('<input/>', {
				'type': 'number',
				'required': field.minRequired,
				'class': field.fieldClass,
				'id': field.minFieldID,
				'placeholder': field.minPlaceholder,
				'value': field.value[0],
				'name': field.minFieldName,
				'change': function() {
					$combinedInput.val(this.value);
				}
			}).appendTo($leftContainer);

			$maxInput = $('<input/>', {
				'type': 'number',
				'required': field.maxRequired,
				'class': field.fieldClass,
				'id': field.maxFieldID,
				'placeholder': field.maxPlaceholder,
				'value': field.value[1],
				'name': field.maxFieldName
			}).appendTo($rightContainer);

			$combinedInput = $('<input/>', {
				'type': 'number',
				'class': field.fieldClass,
				'required': field.required,
				'id': field.id,
				'placeholder': field.placeholder,
				'value': field.value[0],
				'change': function() {
					$minInput.add($maxInput).val(this.value);
				}
			}).appendTo($combinedContainer);

			// Range Toggle
			var $rangeCheckbox;
			var $label;

			setTimeout(function () {
				$label = $rangeCheckbox.parent().parent().parent().find('label').first();
				$label.attr('for', field.value[0] == field.value[1] ? field.id : field.minFieldID);
			}, 0);

			$('<label/>', {
				'class': 'range-toggle-check pull-right small clickable',
				'append': [
					'Range ',
					$rangeCheckbox = $('<input/>', {
						'type': 'checkbox',
						'change': function () {
							$combinedContainer[this.checked ? 'hide' : 'show']();
							$leftContainer.add($middleContainer).add($rightContainer)[this.checked ? 'show' : 'hide']();
							$label.attr('for', this.checked ? field.minFieldID : field.id);
						}
					})
				]
			}).prependTo($rangeContainer);

			if (field.value[0] == field.value[1]) {
				$combinedContainer.show();
				$leftContainer.add($middleContainer).add($rightContainer).hide();
			} else {
				$rangeCheckbox.prop('checked', true);
				$combinedContainer.hide();
			}

			if (!field.validate) field.validate = originalField.validate = field.originalField.validate = function () {
				var min = $minInput.get(0), minV = min.value,
					max = $maxInput.get(0), maxV = max.value;

				if (field.minRequired && (!minV || isNaN(minV))) return 'This field is required.';
				else minV = parseFloat(minV);
				if (field.maxRequired && (!maxV || isNaN(maxV))) return 'This field is required.';
				else maxV = parseFloat(maxV);

				if (minV.length && maxV.length && minV > maxV) return 'This field\'s range is invalid. Values must be between ' + field.min + ' and ' + field.max + '.';

				if (typeof field.min === 'number')
					if (minV.length && minV < field.min || maxV.length && maxV < field.min) return 'This field must be greater than ' + field.min + '.';

				if (typeof field.max === 'number')
					if (minV.length && minV > field.max || maxV.length && maxV > field.max) return 'This field must be less than ' + field.max + '.';

				return undefined;
			};

			return $rangeContainer;
		},
		'search': function (field) {
			var originalField = field;
			
			field = $.extend({
				'dropdownAccessibilityText': 'See more options',
				'dropdownMenuClass': 'dropdown-menu dropdown-menu-full',
				'maxResults': 1999,

				'options': undefined,

				'url': undefined,
				'requestData': {},
				'idColumnName': undefined,
				'applySearch': undefined,
				'value': undefined,
				'valueSelectedCallback': undefined,
				'valueClearedCallback': undefined,
				'searchInputName': ''
			}, field);

			if (field.ridestyler) {
				field.url = field.ridestyler.action;
				field.requestData = field.ridestyler.data;
				field.responseKey = field.ridestyler.responseKey;
				field.idColumnName = field.ridestyler.idColumnName;
			}

			var $inputGroup = $('<div/>', { 'class': 'input-group rs-form-search' });

			var $input = $('<input/>', {
				'type': 'text',
				'disabled': field.disabled,
				'required': field.required,
				'class': field.fieldClass,
				'id': field.id,
				'placeholder': field.placeholder,
				'name': field.searchInputName
			}).appendTo($inputGroup);

			var $hiddenInput = $('<input/>', {
				'name': field.name,
				'type': 'hidden',
				'required': field.required
			}).appendTo($inputGroup);

			var $searchDropdownButton = $('<div/>', {
				'class': 'input-group-btn',
				'append': [
					$('<button/>', {
						'class': 'btn btn-default dropdown-toggle',
						'data-toggle': 'dropdown',
						'aria-expanded': 'false',
						'type': 'button',
						'append': [
							$('<span/>', { 'class': 'caret' }),
							$('<span/>', { 'class': 'sr-only', 'text': field.dropdownAccessibilityText })
						],
						'tabindex': -1
					})
				]
			}).appendTo($inputGroup);

			var $searchDropdown = $('<ul/>', { 'class': field.dropdownMenuClass, 'role': 'menu', 'appendTo': $searchDropdownButton });

			(function () {
				var currentVal = false;

				var setLoading = function () {
					$searchDropdown.empty();
					$searchDropdown.append($('<li/>', { 'class': 'text-center' }).text('Loading...'));
				};

				var getResults = function (response) {
					var results = response[field.responseKey];

					if (typeof field.resultMap === 'function')
						results = $.map(results, field.resultMap);

					return results;
				};

				var createResults = function (results) {
					var hiddenValue = $hiddenInput.val().toString();
					var foundActiveMatch = false;

					for (var j = 0; j < results.length; j++) {
						var result = results[j];

						var $li = $('<li/>').appendTo($searchDropdown);

						$('<a/>', {
							'href': '#',
							'data-value': result.id,
							'html': result.name
						}).appendTo($li);

						if (result.id.toString() === hiddenValue) {
							$li.addClass('active');
							foundActiveMatch = true;
						}
					}

					if (!results.length) {
						$searchDropdown.append($('<li/>', { 'class': 'text-center', 'text': 'No Results' }));
					} else if (!foundActiveMatch) {
						$searchDropdown.children().first().addClass('active');
					}
				};

				var requests = [];

				function abortRequests() {
					for (var j = 0; j < requests; j++) {
						var request = requests[j];
						if (request && typeof request.abort === 'function') request.abort();
					}

					requests = [];
				}

				var setDropdownSearch = function(val) {
					setLoading();
					abortRequests();

					var formData = {
						Start: 0,
						Count: field.maxResults
					};

					if (val) {
						if (typeof field.applySearch === 'function') field.applySearch(val, formData);
						else if (typeof field.applySearch === 'string') formData[field.applySearch] = val;
						else formData.Search = val;
					}

					if (typeof field.url === 'string') {
						requests.push(ridestyler.ajax.send({
							action: field.url,
							data: $.extend(formData, field.requestData),
							callback: function(response) {
								$searchDropdown.empty();

								if (response.Success) {
									var results = getResults(response);
									createResults(results);
								}
							}
						}));
					} else if (typeof field.options === 'object' && field.options.length) {
						$searchDropdown.empty();

						createResults($.map(field.options, function (option) {
							return option.name.toLowerCase().indexOf(val) > -1 ? option : undefined;
						}));
					}
				};

				var search = function () {
					var val = $.trim($input.val()).toLowerCase();

					if (val !== currentVal)
						setDropdownSearch(currentVal = val);
				};

				var setActive = function (target) {
					var $active = $searchDropdown.find('.active');

					if (target instanceof $) {
						$active.removeClass('active');
						target.addClass('active');
						return;
					}

					if ($active.length) $active = $active.removeClass('active')[target]().addClass('active');
					if (!$active.length) $active = $searchDropdown.children()[target === 'next' ? 'first' : 'last']().addClass('active');

					if ($searchDropdownButton.hasClass('open')) {
						var scrollTop = $searchDropdown.scrollTop(),
							activeTop = $active.position().top,
							height = $searchDropdown.innerHeight(),
							activeHeight = $active.height();

						if (activeTop + activeHeight > height)
							$searchDropdown.scrollTop(scrollTop + activeTop);
						else if (activeTop < 0) {
							if (scrollTop - height < scrollTop + activeTop)
								$searchDropdown.scrollTop(Math.max(0, scrollTop - height));
							else
								$searchDropdown.scrollTop(scrollTop + activeTop);
						}
					}
				};

				var clearValue = function () {
					$hiddenInput.val('');
					currentVal = '';

					$input.val('').prop('disabled', false);
					$searchDropdownButton.prop('disabled', false);
					$inputGroup.removeClass('selected');
				};

				var setSelectedValue = function (value, label, triggerCallback) {
					$hiddenInput.val(value);
					$searchDropdownButton.removeClass('open');

					if (!label) {
						if (typeof field.url === 'string') {
							var formData = {
								Start: 0,
								Count: field.maxResults
							};

							if (typeof field.idColumnName === 'string') formData.Search = '[' + field.idColumnName + '[=]' + value + ']';
							else return undefined;

							$input.val("Loading...").prop('disabled', true);
							$searchDropdownButton.prop('disabled', true);

							ridestyler.ajax.send({
								action: field.url,
								data: $.extend(formData, field.requestData),
								callback: function (response) {
									if (response.Success) {
										var results = getResults(response);

										if (results.length) {
											var id = results[0].id,
												name = results[0].name;

											if (id && name)
												setSelectedValue(id, name, triggerCallback);
										}
									}
								}
							});
						} else if (typeof field.options === 'object' && field.options.length) {
							for (var i = 0; i < field.options.length; i++)
								if (field.options[i].id == value)
									return setSelectedValue(value, field.options[i].name, triggerCallback);
						}

						return undefined;
					}

					currentVal = $.trim(label.toLowerCase());

					$input.val(label).prop('disabled', false);
					$searchDropdownButton.prop('disabled', false);
					$inputGroup.addClass('selected');

					if (typeof field.valueSelectedCallback === 'function' && (typeof triggerCallback === 'undefined' || triggerCallback))
						field.valueSelectedCallback.call($input, value, label);

					return undefined;
				};

				var selectActiveDropdownValue = function () {
					var $active = $searchDropdown.find('.active').first();

					if (!$searchDropdownButton.hasClass('open') || !$active.length) return;

					var $a = $active.find('a');

					var value = $a.data('value'), label = $a.text();

					setSelectedValue(value, label);
				};

				var searchTimeout;

				$input.on({
					'keydown': function (e) {
						var wasOpen = $searchDropdownButton.hasClass('open');

						if ($.inArray(e.which, [27, 9, 16]) < 0) // Not Tab, Esc or Shift
							$searchDropdownButton.addClass('open');

						if (!wasOpen && (e.which === 38 || e.which === 40))
							setDropdownSearch('');

						if (e.which === 38) {
							// Up
							setActive('prev');
							return false;
						} else if (e.which === 40) {
							// Down
							setActive('next');
							return false;
						} else if (e.which === 13) {
							// Enter
							selectActiveDropdownValue();
							return false;
						}

						return undefined;
					},
					'keyup': function (e) {
						if (e.which === 27 && $searchDropdownButton.hasClass('open')) {
							e.preventDefault();
							e.stopPropagation();

							// Esc
							$input.blur();
							$searchDropdownButton.removeClass('open');
							return false;
						}

						if (searchTimeout) clearTimeout(searchTimeout);

						var val = $.trim($input.val()).toLowerCase();

						if (val === currentVal) return undefined;

						$inputGroup.removeClass('selected');

						if ($hiddenInput.val()) {
							$hiddenInput.val('');

							if (typeof field.valueClearedCallback === 'function')
								field.valueClearedCallback.call($input);
						}

						setLoading();

						searchTimeout = setTimeout(function () {
							search();
						}, field.options ? 0 : 500);

						return undefined;
					}
				});

				$searchDropdown.on('click', 'a', function () {
					setActive($(this).parent());
					selectActiveDropdownValue();

					return false;
				});

				$searchDropdownButton.on({
					'show.bs.dropdown': function () {
						setDropdownSearch('');
					}
				});

				$inputGroup.data('rsSearch', {
					name: field.name,
					next: function () {setActive('next');},
					prev: function () {setActive('prev');},
					setActive: setActive,
					setSelectedValue: setSelectedValue,
					clearValue: clearValue
				});

				if (field.value) {
					if (typeof field.value === 'object') setSelectedValue(field.value.id, field.value.name, false);
					else if (typeof field.value !== 'undefined') setSelectedValue(field.value, undefined, false);
				}
			})();

			if (!field.validate) field.validate = originalField.validate = field.originalField.validate = function () {
				var input = $hiddenInput.get(0);

				if (field.required && !$.trim(input.value)) return "This field is required.";

				return undefined;
			};

			return $inputGroup;
		},
		'static': function (field) {
			field = $.extend({
				'text': undefined,
				'ridestyler': undefined,
				'onFail': undefined
			}, field);

			var $input = $('<div/>', {
				'class': field.fieldClass,
				'id': field.id,
				'html': field.text
			}).removeClass('form-control');

			if (typeof field.name === "string" && typeof field.value !== "undefined")
				$('<input/>', {
					type: 'hidden',
					name: field.name,
					value: field.value
				}).appendTo($input);

			if (typeof field.ridestyler === "object") {
				field.ridestyler = $.extend({
					textKey: undefined,
					responseKey: undefined,
					action: undefined,
					data: undefined
				}, field.ridestyler);

				$input.append($('<div/>', { 'class': 'loading-indicator center-block', 'css': { 'display': 'block' } }));

				rstools.utils.apiDeferred(field.ridestyler)
					.done(function (response) {
						var k = field.ridestyler.textKey;
						var format = field.ridestyler.format;
						var v;

						if ($.isArray(response)) {
							if (response.length > 10)
								v = response.length + ' results';
							else
								v = $.map(response, function(r) {
									if (typeof format === 'function') return format(r);
									else return r[k];
								}).unique().join(', ');

						} else {
							v = 'Error retrieving data';
							field.onFail();
						}

						$input.html(v);
					})
					.fail(function() {
						$input.text('Error retrieving data');
					})
					.fail(field.onFail);
			}

			return $input;
		},
		'suggest': function (field) {
			var originalField = field;
			
			var ms;

			field = $.extend({
				'multiple': false,
				'urlAction': 'ridestyler',
				'requestKey': 'Search',
				'customEntries': false
			}, field);

			field.magicSuggest = $.extend({
				'cls': field.fieldClass === 'form-control' ? undefined : field.fieldClass,
				'inputCfg': {
					'id': field.id,
					'class': 'ms-input'
				},
				'name': field.name,
				'allowFreeEntries': field.customEntries,
				'noSuggestionText': field.noSuggestionText,
				'maxSelection': 1,
				'data': field.url
			}, field.magicSuggest);

			// Change maxSelection based off of multiple option
			field.magicSuggest.maxSelection = (function () {
				// If multiple is disabled, only one item may be selected
				if (!field.multiple) return 1;

				// Otherwise check for a maxSelection in options and use it or default
				return typeof field.magicSuggest.maxSelection !== 'undefined' ? field.magicSuggest.maxSelection : 10;
			})();

			var $input = $('<div/>');

			// Setup magic suggest later after the input is added to the DOM
			setTimeout(function () {
				ms = $input.magicSuggest(field.magicSuggest);

				ms.container.data('config', field);

				if (typeof field.magicSuggest.on === 'object')
					$(ms).on(field.magicSuggest.on);

				$(ms).on({
					'beforeload': function() {
						ms.combobox
							.html($('<div/>', { 'class': 'text-center', 'html': 'Loading...' }))
							.css('height', '');
					},
					'keyup': function (e, m, keyEvent) {
						if (keyEvent.keyCode === 27 && ms.combobox.is(':visible'))
							keyEvent.stopPropagation();
					}
				}); 
			}, 0);

			if (!field.validate) field.validate = originalField.validate = field.originalField.validate  = function () {
				if (field.required && !ms.getValue().length) return 'This field is required.';
				return undefined;
			};

			return $input;
		}
	};

	uifactory.formInputSettings = {
		'dropdown': function (settings) {
			if (settings.label)
				settings.label.after(' ');
		},
		'hidden': function (settings) {
			if (settings.container && settings.input)
				settings.container.replaceWith(settings.input);
		},
		'range': function (settings) {
			if (settings.labelContainer && settings.input)
				settings.input.find('.range-toggle-check').appendTo(settings.labelContainer);
		},
		'checkbox': function (settings, form) {
			if (settings.input) {
				if (settings.label) {
					if (form && form.$form.hasClass('form-horizontal'))
						settings.labelContainer.addClass('no-padding-top');
					else
						settings.label.prepend([settings.input, ' &nbsp;']);
				}

				settings.input.iCheck({
					checkboxClass: 'icheckbox_square-blue',
					radioClass: 'iradio_square-blue',
					increaseArea: '20%'
				});
			}
		}
	};

	// Additional field type setup
	(function () {
		var createTextInputField = function (field) {
			var textFieldTypeMap = {
				'text-search': 'search'
			};

			if (field.type in textFieldTypeMap)
				field.type = textFieldTypeMap[field.type];

			var $input = $('<input/>', {
				'type': field.type,
				'disabled': field.disabled,
				'required': field.required,
				'class': field.fieldClass,
				'id': field.id,
				'placeholder': field.placeholder,
				'value': field.value,
				'name': field.name,
				'data-type': field.dataType
			});

			if (field.type === 'checkbox') {
				if (!field.dataType) $input.attr('data-type', field.dataType = 'boolean');

				if (field.checked) $input.prop('checked', field.checked);
			} else if (field.type === 'search')
				$input.attr('results', field.results || 5);

			if (field.maxLength > 0)
				$input.prop('maxLength', field.maxLength);

			if (!field.validate) field.validate = function () {
				var input = $input.get(0);

				if (field.required && !$.trim(input.value)) return 'This field is required.';
				if (field.maxLength > 0 && input.value.length > field.maxLength) return 'This field can only be ' + field.maxLength + ' characters long.';

				if (field.type === 'number' && input.value.length > 0) {
					if (typeof field.min === 'number' && input.value < field.min) return 'This field must be greater than ' + field.min + '.';
					if (typeof field.max === 'number' && input.value > field.max) return 'This field must be less than ' + field.max + '.';
				}

				return undefined;
			};

			return $input;
		};

		$.each(textInputTypes, function (i, type) {
			formInputTypes[type] = createTextInputField;
		});
	})();

	uifactory.create.formInput = formInputTypes;
})();