(function () {
	var InclusionState = rstools.constants.InclusionState;

	rstools.inclusion = {
		/**
		 * Returns true if the object's InclusionStatus indicates that the object
		 * is locked
		 *
		 * @param   {object}  o  The object to look at
		 *
		 * @return  {boolean}    Whether or not the object is "locked"
		 */
		locked: function (o) {
			var s = o.InclusionStatus;

			return (s & InclusionState.Required) === InclusionState.Required ||
				   (s & InclusionState.Excluded) === InclusionState.Excluded;
		},

		/**
		 * Returns true if the object's InclusionStatus indicates that the object
		 * is unlocked.
		 *
		 * @param   {object}  o  The object to look at
		 *
		 * @return  {boolean}    Whether or not the object is "unlocked"
		 */
		unlocked: function (o) {return !rstools.inclusion.locked(o);},

		/**
		 * Returns true if the object's InclusionStatus indicates that the object
		 * is enabled.
		 *
		 * @param   {object}  o  The object to look at
		 *
		 * @return  {boolean}    Whether or not the object is "enabled"
		 */
		enabled: function (o) {
			var s = o.InclusionStatus;

			return (s & InclusionState.Optional) === InclusionState.Optional || 
				   (s & InclusionState.Required) === InclusionState.Required;
		},

		/**
		 * Returns true if the object's InclusionStatus indicates that the object
		 * is disabled.
		 *
		 * @param   {object}  o  The object to look at
		 *
		 * @return  {boolean}    Whether or not the object is "disabled"
		 */
		disabled: function (o) {return !rstools.inclusion.enabled(o);},
	};
})();