(function ($) {
    rstools.shared = {
        datagrid: {
            headerActions: {
                manageColumns: {
                    title: "Show/Hide Columns",
                    icon: "cog",
                    callback: function() {
                        var grid = this;
                        var userGridSettings = rstools.data.provider.get({
                            namespace: grid.settings.storageIdentifier || grid.settings.getAction + "@" + rter.url.get().path
                        });
                        var $selectionList;

                        var modal = uifactory.modal.create({
                            header: "Customize Columns",
                            cancelable: true,
                            actions: [
                                {
                                    "class": "btn btn-success",
                                    "title": "Save",
                                    "icon": "disk",
                                    "callback": function () {
                                        var columns = $selectionList.data("selectionList").value();
                                        var selectedOne = false;

                                        for (var k in columns) {
                                            if (columns[k]) selectedOne = true;

                                            columns[k] = {
                                                visible: columns[k]
                                            };
                                        }

                                        if (!selectedOne) {
                                            modal.showFooterError("You must select at least one column.", true);
                                            modal.shake();
                                            return;
                                        }

                                        modal.$modal.addClass("loading");

                                        userGridSettings.setAsync("columns", columns)
                                            .done(function() {
                                                modal.hide();
                                                grid.updateColumnSettings();
                                            });
                                    }
                                }
                            ]
                        });

                        var getColumnSettings = function() {
                            var d = $.Deferred();

                            userGridSettings.getAsync("columns")
                                .done(function(columns) {
                                    columns = columns || {};

                                    for (var j = 0; j < grid.fields.length; j++) {
                                        var field = grid.fields[j];

                                        if (field.name in columns === false)
                                            columns[field.name] = {
                                                visible: field.visible !== false
                                            };
                                    }

                                    d.resolve(columns);
                                });

                            return d;
                        };  

                        var instructions = document.createElement("p");
                        instructions.innerHTML = "Select which columns you would like to use from the list below.";
                        modal.$body.append(instructions);


                        modal.$modal.addClass("loading");

                        getColumnSettings().done(function (fieldSettings) {
                            var fields = $.map(grid.fields, function (field) {
                                var name = field.name;
                                var label = field.caption;

                                if (field.allowToBeHidden === false || name === "actions") return undefined;

                                return {
                                    label: label,
                                    value: name,
                                    selected: fieldSettings[name].visible
                                };
                            });

                            $selectionList = uifactory.create.selectionList({
                                options: fields
                            }).appendTo(modal.$body);

                            modal.$modal.removeClass("loading");
                        });

                        modal.show();
                    }
                }
            },
            retrieveColumnSettings: function() {
                var userGridSettings = rstools.data.provider.get({
                    namespace: this.settings.storageIdentifier || this.settings.getAction + "@" + rter.url.get().path
                });

                return userGridSettings.getAsync("columns");
            }
        },
        modals: {
            archive: function (namespace, rows, settings) {
                namespace = rstools.utils.getNamespace(namespace);

                settings = $.extend({
                    onArchived: undefined,
                    defaultFilters: undefined
                }, settings);
                
                if (typeof namespace.utils.getTitles !== "function") throw "Missing utils.getTitles from namespace";
                if (typeof namespace.dataNamespace !== "string") throw "Missing dataNamespace from namespace";
                if (typeof namespace.noun !== "string") throw "Missing noun from namespace";
                if (typeof namespace.archiveAction !== "string") throw "Missing archiveAction from namespace";
                if (typeof namespace.utils.createFilters !== "function") throw "Missing utils.createFilters from namespace";

                var defaultFilters = rstools.utils.resolveParameter(settings.defaultFilters) || {};

                var modal = uifactory.create.confirmActionModal({
                    titles: namespace.utils.getTitles(rows),
                    noun: namespace.noun,
                    actionRequest: {
                        action: namespace.archiveAction,
                        data: $.extend(defaultFilters, namespace.utils.createFilters(rows))
                    },
                    onActionCompleted: function () {
                        rstools.events.dataChanged(namespace.dataNamespace, "archive");

                        if (typeof settings.onArchived === "function")
                            settings.onArchived(rows);
                    }
                });

                modal && modal.show();
            },
            unarchive: function (namespace, rows, settings) {
                namespace = rstools.utils.getNamespace(namespace);

                settings = $.extend({
                    onUnarchived: undefined,
                    defaultFilters: undefined
                }, settings);

                if (typeof namespace.utils.createFilters !== "function") throw "Missing utils.createFilters from namespace";
                if (typeof namespace.utils.getTitles !== "function") throw "Missing utils.getTitles from namespace";
                if (typeof namespace.dataNamespace !== "string") throw "Missing dataNamespace from namespace";
                if (typeof namespace.noun !== "string") throw "Missing noun from namespace";
                if (typeof namespace.unarchiveAction !== "string") throw "Missing unarchiveAction from namespace";

                var defaultFilters = rstools.utils.resolveParameter(settings.defaultFilters) || {};

                var modal = uifactory.create.confirmActionModal({
                    titles: namespace.utils.getTitles(rows),
                    noun: namespace.noun,
                    verb: "unarchive",
                    actionButtonClass: "btn btn-success",
                    actionRequest: {
                        action: namespace.unarchiveAction,
                        data: $.extend(defaultFilters, namespace.utils.createFilters(rows))
                    },
                    onActionCompleted: function () {
                        rstools.events.dataChanged(namespace.dataNamespace, "unarchive");
                        
                        if (typeof settings.onUnarchived === "function")
                            settings.onUnarchived(rows);
                    }
                });

                modal && modal.show();
            }
        },
        actions: {
            createArchiveAction: function (namespace, settings) {
                namespace = rstools.utils.getNamespace(namespace);
                settings = $.extend({
                    defaultFilters: undefined
                }, settings);

                if (typeof namespace.utils.isUnarchived !== "function") throw "Missing utils.isUnarchived from namespace";

                return {
                    title: "Archive",
                    icon: "fa fa-trash",
                    forMenu: true,
                    multiple: true,
                    requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                    appliesTo: namespace.utils.isUnarchived,
                    fields: ["actions"],
                    callback: function (rows) {
                        rstools.shared.modals.archive(namespace, rows, settings);
                    }
                };
            },
            createUnarchiveAction: function (namespace, settings) {
                namespace = rstools.utils.getNamespace(namespace);
                settings = $.extend({
                    defaultFilters: undefined
                }, settings);

                if (typeof namespace.utils.isArchived !== "function") throw "Missing utils.isArchived from namespace";

                return {
                    title: "Unarchive",
                    icon: uifactory.create.icon({
                        name: "trash",
                        stack: "ban"
                    }),
                    forMenu: true,
                    multiple: true,
                    requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                    appliesTo: namespace.utils.isArchived,
                    fields: ["actions"],
                    callback: function (rows) {
                        rstools.shared.modals.unarchive(namespace, rows, settings);
                    }
                };
            },
            createPasteAction: function (copyNamespace, targetNamespace, settings) {
                copyNamespace = rstools.utils.getNamespace(copyNamespace);
                targetNamespace = rstools.utils.getNamespace(targetNamespace);

                settings = settings || {};

                var from = settings.from || copyNamespace.getAction.replace(/s$/, '');

                if (typeof from !== 'string') throw "getAction not specified in copyNamespace";
                if (typeof targetNamespace.utils.getIDs !== 'function') throw "getIDs missing from targetNamespace.utils";
                if (typeof copyNamespace.utils.createFilters !== 'function') throw "createFilters missing from copyNamespace.utils";
                if (typeof copyNamespace.copyTo !== 'function') throw "copyTo missing from copyNamespace";
                if (typeof copyNamespace.moveTo !== 'function') throw "moveTo missing from copyNamespace";

                /**
                 * Returns true if the the clipboard matches the copyNamespace
                 * @param {DataGridClipboard} clipboard 
                 */
                function clipboardMatchesCopyNamespace(clipboard) {
                    return typeof clipboard.from === 'string' && clipboard.from.indexOf(from) >= 0;
                }

                return {
                    title: 'Paste',
                    icon: 'fa fa-clipboard',
                    multiple: false,
                    fields: ['actions'],
                    forMenu: true,
                    appliesTo: function () {
                        var clipboard = datagrid.getClipboard();

                        // Rows are not defined
                        if (!clipboard.rows) return false;

                        // From was not specified in clipboard, or the from filter was not matched
                        if (!clipboardMatchesCopyNamespace(clipboard)) return false;

                        // Show if there is stuff in the clipboard
                        return clipboard.rows.length;
                    },
                    callback: function (rows) {
                        var clipboard = datagrid.getClipboard();

                        // From was not specified in clipboard, or the from filter was not matched
                        if (typeof clipboard.from !== 'string' || clipboard.from.indexOf(from) < 0) return;

                        var targetID = targetNamespace.utils.getIDs(rows)[0];
                        var clipboardFilters = copyNamespace.utils.createFilters(clipboard.rows);

                        copyNamespace[clipboard.action === 'copy' ? 'copyTo' : 'moveTo'](clipboardFilters, targetID, {
                            callback: function () {
                                if (clipboard.action === 'cut') datagrid.clearClipboard();
                                if (typeof settings.callback === 'function') settings.callback(clipboard);
                            }
                        });
                   }
               };
            }
        },
        columns: {
            createLastUpdatedColumn: (function () {
                var formatUpdateTimestamp = function (timestamp) {
                    var m = rstools.utils.momentFromDatabaseDateTime(timestamp);

                    if (m.year() < 2000) {
                        var unkown = document.createElement("span");
                        unkown.className = "text-muted";
                        unkown.appendChild(document.createTextNode("-"));

                        return unkown;
                    }

                    var time = document.createElement("time");
                    time.setAttribute("datetime", time.datetime = m.toISOString());
                    time.appendChild(document.createTextNode(m.calendar(null, {
                        sameDay : "[Today at] LT",
                        nextDay : "[Tomorrow at] LT",
                        nextWeek : "dddd [at] LT",
                        lastDay : "[Yesterday at] LT",
                        lastWeek : "[Last] dddd",
                        sameElse : "L"
                    })));

                    return time;
                };

                return function (updatedColumn, defaultVisible) {
                    if (typeof defaultVisible !== "boolean") defaultVisible = false;

                    return {
                        name: updatedColumn,
                        caption: "Last Updated",
                        visible: defaultVisible,
                        format: function (row) {
                            return formatUpdateTimestamp(row[updatedColumn]);
                        }
                    };
                };
            })(),
            createResourceColumn: function (namespace, settings) {
                namespace = rstools.utils.getNamespace(namespace);

                // Check for namespace properties
                if (typeof namespace.getResources !== 'function')
                    throw "Missing getResources from namespace";

                settings = $.extend({
                    // The class of loader to use
                    loadingStyleClass: 'light loading-small',

                    // The class cells recieve while cell data is loading
                    loadingClass: 'loading',

                    // The class cells recieve once image data starts loading
                    loadingImageClass: 'loading-image',

                    // Base filters that will be overriden by namespace.utils.createFilters
                    baseFilters: undefined,

                    // Filters to be used instead of calling namespace.utils.createFilters and using baseFilters
                    filters: undefined,

                    // Called when an image has successfully loaded. Return false to prevent default logic
                    onImageLoaded: undefined,

                    // Called when an image has encountered an error loading. Return false to prevent default logic
                    onImageError: undefined,

                    // This is the element that replaces the image on error, assuming the error handler didn't return false
                    errorElement: 'Error',

                    // This is the element that is appended to the cell when there is no resource attached to a data object
                    noImageElement: 'No Image',

                    // The width to request the resource at
                    width: 100,

                    // The height to request the resource at
                    height: 100,

                    // The horizontal positioning of the image
                    positionX: 1,

                    // The vertical positioning of the image
                    positionY: 1
                }, settings);

                // Check additional namespace properties based on settings
                {
                    if (typeof settings.filters !== 'undefined' && typeof namespace.utils.createFilters !== 'function')
                        throw "Missing utils.createFilters from namespace";
                }

                var dataObjectIDField, resourceDataObjectIDField;
                function resourceMatchesDataObject(resource, dataObject) {
                    if (typeof dataObjectIDField === 'undefined') {
                        dataObjectIDField = rstools.data.utils.detectIDField(dataObject);
                        resourceDataObjectIDField = rstools.data.utils.detectIDField(resource, dataObjectIDField);
                    }

                    return resource[resourceDataObjectIDField] == dataObject[dataObjectIDField];
                }
                
                return function ($tds, data) {
                    $tds
                        .empty()
                        .addClass(settings.loadingStyleClass + ' ' + settings.loadingClass);

                    var requestFilters = settings.filters ?
                        settings.filters : 
                        $.extend(
                            {}, 
                            settings.baseFilters, 
                            namespace.utils.createFilters(data)
                        );
                    
                    namespace.getResources(requestFilters)
                        .done(function (resources) {
                            var resourceIDField;

                            $tds.each(function (dataIndex) {
                                var $td = $(this);
                                var dataObject = data[dataIndex];

                                for (var resourceIndex = 0; resourceIndex < resources.length; resourceIndex++) {
                                    var resource = resources[resourceIndex];
                                    
                                    // Find the resource link matching the data object
                                    if (!resourceMatchesDataObject(resource, dataObject)) continue;

                                    // Get the resource ID field if we haven't already
                                    if (typeof resourceIDField === 'undefined')
                                        resourceIDField = rstools.data.utils.detectIDField(resource, 'ResourceID');

                                    $td.addClass(settings.loadingImageClass);

                                    var image = document.createElement('img');
                                        image.onload = image.onerror = function (e) {
                                            var $image = $(this);
                                            var preventDefault = false;
                                            
                                            if (e.type === 'error') {
                                                // When the image has an error loading

                                                if (typeof settings.onImageError === 'function') {
                                                    preventDefault = settings.onImageError.call(this, $image, dataObject) === false;

                                                    if (preventDefault) return;
                                                }

                                                $image.replaceWith(settings.errorElement);
                                            } else {
                                                // When the image has an error loaded successfully

                                                if (typeof settings.onImageLoaded === 'function') {
                                                    preventDefault = settings.onImageLoaded.call(this, $image, dataObject) === false;

                                                    if (preventDefault) return;
                                                }

                                                $(this).closest('td').removeClass(settings.loadingClass);
                                            }
                                        };
                                        image.src = ridestyler.ajax.url('Resource/Image', {
                                            Width: settings.width,
                                            Height: settings.height,
                                            PositionX: settings.positionX,
                                            PositionY: settings.positionY,

                                            Resource: resource[resourceIDField]
                                        });

                                    $td.html(image);
                                }
                            });
                        })
                        .always(function () {
                            $tds
                                .withoutClasses(settings.loadingImageClass)
                                .removeClass(settings.loadingClass)
                                .html(settings.noImageElement);
                        });
                };
            }
        }
    };
})(jQuery);