(function ($) {
    rter.mapController('app.tire.pricing.fitment', function (context) {
        var grid = null;
        var filters = {};
        var $filterForm, $filterButton;
        var $backToModelsButton;

        return {
            viewWillLoad: function (ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($outlet) {
                grid =
                    datagrid.create({
                        container: $outlet.find('div.data-wrapper'),

                        headerActions: [
                            rstools.shared.datagrid.headerActions.manageColumns
                        ],
                        getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,

                        fields: [
                            { name: 'TireFitmentPartNumber', caption: 'Part #' },
                            {
                                name: 'TireFitment_TireSidewallID',
                                caption: 'Sidewall',
                                format: function (r) {
                                    return r.TireSidewall.TireSidewallName;
                                }
                            },
                            {
                                name: 'TireFitment_TireSizeID',
                                caption: 'Tire Size',
                                format: function (r) {
                                    return r.TireSize.Description;
                                }
                            },
							{
								name: 'ItemNumber',
								caption: 'Item Number',
								sort: false,
								visible: false,
								format: function (r) {
									return rstools.pricing.utils.createItemNumberDisplay(r.Pricing);
								}
							},
                            {
                                name: '!Price',
                                caption: 'Price',
                                format: function (r) {
                                    if (r.Pricing)
                                        return rstools.pricing.format.price(rstools.pricing.utils.getBestPricingType(r.Pricing).TirePricingAmount);
                                }
                            },
                            {
                                name: 'TireFitmentNotes',
                                caption: 'Notes',
                                colClass: 'text-center',
                                sort: false,
                                columnRenderer: rstools.notes.createColumnRenderer('tire.fitment'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
                            },
                            { name: 'actions', caption: '', width: 50, sort: false }
                        ],
                        actions: [
                            {
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.pricing.edit({
                                        action: 'Tire/GetPrices',
                                        countAction: 'Tire/CountPrices',
                                        updateAction: 'Tire/EditPrice',
                                        createAction: 'Tire/CreatePrice',
                                        deleteAction: 'Tire/DeletePrice',
                                        namespace: 'tire-fitment-pricing',
                                        filters: rstools.tire.utils.loosenFilters('fitments', {
                                            TireFitments: rstools.tire.fitment.utils.getIDs(rows)
                                        }),
                                        PricingGroupID: filters.PricingGroupID
                                    });
                                }
                            }
                        ],
                        getAction: 'Tire/GetFitmentDescriptions',
                        countAction: 'Tire/CountFitmentDescriptions',
                        resultListField: 'Fitments',
                        baseQueryData: {
                            SelectBrandsOnly: false,
                            IncludePricing: true,
                            HasPricing: true
                        },
                        search: filters,
                        namespace: 'tire-fitment-pricing'
                    });

                $filterForm = rstools.pricing.utils.createFilterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });

                var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

                $backToModelsButton = $('<a/>', {
                    'class': 'btn btn-info',
                    'append': [
                        uifactory.create.icon('caret-left'),
                        ' Models'
                    ],
                    'href': '#!/app/tire/pricing/model'
                }).appendTo($leftButtons);

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .appendTo($leftButtons)
                    .trigger('filters-updated', [Object.keys(filters).length]);
            },
            viewLoaded: function (ctx) {
                // Update filters from the current ctx
                // or grab them from the original context
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

                if ($filterForm)
                    $filterForm.syncObjectToForm(filters);

                if (ctx.previousContext && ctx.previousContext.path === '/app/tire/pricing/model') {
                    var previousState = rter.utils.toParamString(ctx.previousState);

                    $backToModelsButton.attr('href', '#!/app/tire/pricing/model' + (previousState ? "?" + previousState : ""));
                } else {
                    $backToModelsButton.attr('href', '#!/app/tire/pricing/model');
                }
            }
        };

        function filtersUpdated() {
            $filterForm.syncFormToObject(filters);
            $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(filters);
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);