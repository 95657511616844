(function ($) {
    var formID = 0;

    window.uifactory.create.imageUploader = function (settings) {
        settings = $.extend({
            noImageText: 'No Image',          // Text to be displayed to the user when there is no image returned
            src: undefined,                   // The dynamic source URL of the IMG
            uploadAction: undefined,          // The RS action to send uploaded files to
            uploadParams: {},                 // Additional parameters to pass along to the upload action
            fileInputName: 'file',            // The name of the file input which gets passed to upload action
            iconClass: 'fa fa-upload',        // The type of icon to show with the uploader
            imageClass: 'img-responsive',     // The class to add to the image element
            useTraditionalPost: false,        // Whether or not to use form IFRAME posting instead of an XHR post
            successMessage: 'Image uploaded', // The message to display to the user when the image is successfully uploaded
            failureMessage: undefined         // The message to display to the user when there's a problem uploading the image. By default it shows the server error message.
        }, settings);

        var $container,
            $noImage,
            $uploadOverlay,
            $uploadInput, uploadInput,
            $uploadTarget,
            $img;

        // A generated ID for the IFRAME target
        var targetID = 'image-uploader-target-' + (formID++);
        var targetURL = ridestyler.ajax.url(settings.uploadAction, settings.uploadParams);

        var useIFRAME = settings.useTraditionalPost || 'withCredentials' in new XMLHttpRequest();


        function submitFile(file) {
            var d = new $.Deferred();

            if (file) {
                var formData = new FormData();

                formData.append(settings.fileInputName, file);

                $.ajax({
                    url: targetURL,
                    type: 'POST',
                    data: formData,

                    dataType: 'json',
                    cache: false,
                    contentType: false,
                    processData: false
                }).done(function (response) {
                    if (!response) d.reject();

                    if (response.Success) d.resolve();
                    else d.reject(response.Message);
                }).fail(function () {
                    d.reject();
                });
            } else {
                if (useIFRAME || 'files' in uploadInput === false || !uploadInput.files.length) {
                    // Wait for the IFRAME to finish loading
                    $uploadTarget.one('load error', function () {
                        d.resolve();
                    });

                    $uploadOverlay.submit();
                } else {
                    return submitFile(uploadInput.files[0]);
                }
            }

            return d;
        }

        // #region Create DOM Elements
        {
            // The overall container housing the image uploader component
            $container = $('<div/>', {
                'class': 'image-uploader loading'
            });

            // The element which is shown if there is was an error (no image returned)
            $noImage = $('<div/>', {
                'class': 'no-image-text',
                'html': settings.noImageText
            }).appendTo($container);

            // The overlay that gets shown when the user hovers over the image uploader component
            $uploadOverlay = $('<form/>', {
                'class': 'upload-overlay',
                'append': [
                    $('<i/>', { 'class': settings.iconClass })
                ],
                'method': 'post',
                'enctype': 'multipart/form-data',
                'target': targetID,
                'action': targetURL,

                'on': {
                    
                }
            }).appendTo($container);

            // The file input which users upload files into
            $uploadInput = $('<input/>', {
                'type': 'file',
                'name': settings.fileInputName,

                'on': {
                    'change': function () {
                        if (!$uploadInput.val()) return false;

                        $container.addClass('loading').removeClass('no-image');

                        var fileSubmitted = submitFile().always(function () {
                            $container.removeClass('loading');

                            // Reload the image
                            // COMPATIBILITY: Does this work in all browsers?
                            $img.attr('src', $img.attr('src'));

                            $uploadOverlay.get(0).reset();
                        });

                        if (!useIFRAME) {
                            fileSubmitted.done(function () {
                                uifactory.alert.show({ text: settings.successMessage });
                            }).fail(function (message) {
                                uifactory.alert.show({
                                    text: settings.failureMessage ? settings.failureMessage : message,
                                    type: 'error',
                                    duration: 10000
                                });
                            });
                        }
                    },

                    'dragenter': function (e) {
                        e.stopPropagation();
                        e.preventDefault();

                        $container.addClass('file-over');

                        console.debug('dragenter', e.target, e);
                    },
                    'dragleave': function (e) {
                        e.stopPropagation();
                        e.preventDefault();

                        $container.removeClass('file-over');
                        console.debug('dragleave', e.target, e);
                    },
                    'drop': function (e) {
                        e.stopPropagation();
                        e.preventDefault();

                        $container.removeClass('file-over').addClass('loading');

                        var fileSubmitted = submitFile(e.originalEvent.dataTransfer.files[0]).always(function () {
                            $container.removeClass('loading');

                            // Reload the image
                            // COMPATIBILITY: Does this work in all browsers?
                            $img.attr('src', $img.attr('src'));

                            $uploadOverlay.get(0).reset();
                        }).done(function () {
                                uifactory.alert.show({ text: settings.successMessage });
                        }).fail(function (message) {
                            uifactory.alert.show({
                                text: settings.failureMessage ? settings.failureMessage : message,
                                type: 'error',
                                duration: 10000
                            });
                        });
                    }
                }
            }).appendTo($uploadOverlay);

            uploadInput = $uploadInput.get(0);

            // The target IFRAME of the form where upload requests get sent
            if (useIFRAME) {
                $uploadTarget = $('<iframe/>', {
                    'id': targetID,
                    'name': targetID,

                    'width': 0,
                    'height': 0,

                    'css': {
                        'visibility': 'hidden',
                        'position': 'absolute'
                    }
                }).appendTo($container);
            }

            // The displayed IMG
            $img = $('<img/>', {
                'class': settings.imageClass,

                'src': settings.src,

                'on': {
                    load: function () {
                        $container.removeClass('loading');
                    },
                    error: function () {
                        $container.addClass('no-image').removeClass('loading');
                    }
                }
            }).appendTo($container);
        }
        // #endregion

        

        return $container;
    };
})(jQuery);