(function($) {
    function createBrandForm(type, settings) {
        var url;

        settings = $.extend({
            isMultiple: false,
            data: {},
            filters: {},
            submitSuccessCallback: undefined,
            onCancel: undefined
        }, settings);

        var data = settings.data;
        var s = settings.isMultiple ? 's' : '';

        switch (type) {
            case "edit":
                url = 'Wheel/EditBrand';
                break;
            case "create":
                url = 'Wheel/CreateBrand';
                break;
        }

        if (typeof url !== 'string') return undefined;

        var dataSourceField;
        var fields = $.map([
            {
                label: 'Name',
                name: 'WheelBrandName',
                maxLength: 128,
                helpText: 'The name of the wheel brand' + s,
                value: data.WheelBrandName,
                required: !settings.isMultiple,
                hasMultipleValues: 'WheelBrandName' in data === false
            },
            dataSourceField = {
                label: 'Data Source',
                name: 'WheelBrand_DataSourceID',
                type: 'dropdown',
                required: !settings.isMultiple,

                url: 'DataSource/GetDataSources',
                responseKey: 'DataSources',
                resultMap: function (dataSource) {
                    return {
                        value: dataSource.DataSourceID,
                        label: dataSource.DataSourceDescription
                    };
                },
                data: {
                    sort: {
                        DataSourceDescription: 'Ascending'
                    }
                },
                initialOptions: [{ label: 'Choose a data source', value: false }, '-'],
                value: data.DataSourceID,
                hasMultipleValues: 'DataSourceID' in data === false,
                actions: rstools.user.isSystemAdmin() ? [
                    {
                        icon: 'plus',
                        title: 'New Data Source',
                        callback: function() {
                            rstools.datasource.create();
                        }
                    }
                ] : undefined
            }
        ], function (field) {
            // Modify fields dynamically here
            if (type === "create") field.hasMultipleValues = false;

            return field;
        });

        var $form = uifactory.create.form({
            url: url,
            submitErrorMessage: 'There was a problem ' + (type === 'create' ? 'creat' : 'edit') + 'ing the wheel brand' + s + ': {Message}.',

            submitSuccessCallback: function () {
                var verbPast = (type === 'create' ? 'created.' : 'edited.');

                uifactory.alert.show({
                    text: !settings.isMultiple ? "The wheel brand has been " + verbPast : "The wheel brands have been " + verbPast
                });

                if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback();
            },

            fields: fields,
            baseData: settings.filters,

            actions: [
                {
                    label: type === 'create' ? 'Create' : 'Save',
                    icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
                    buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
                    action: 'submit'
                },

                {
                    label: 'Cancel',
                    action: settings.onCancel
                }
            ]
        });

        rstools.events.dataChanged(function(e, changed) {
            if (changed === 'datasource') dataSourceField.reload();
        }, $form);

        return $form;
    }

    var loosenFilters = rstools.wheel.utils.loosenFilters;
    var loadPaginatedList = rstools.utils.loadPaginatedList;
    var findLikeData = rstools.utils.findLikeData;

    var ResourceAssetType = rstools.constants.ResourceAssetType;
    var WheelBrandResourceType = rstools.constants.WheelBrandResourceType;

    rstools.wheel.brand = {
        edit: function (wheelFilters) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.WHEELS)) return;

            var brandDescriptions = [];

            loosenFilters('brand', wheelFilters);

            var brandDescriptionsLoaded = loadPaginatedList('Wheel/GetBrandDescriptions', 'Wheel/CountBrands', 'Brands', wheelFilters).done(function (brands) {
                brandDescriptions = brands;
            });

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: $('<h4/>')
            });

            modal.$modal.addClass('loading');

            $.when(brandDescriptionsLoaded)
                .done(function () {
                    var isMultiple = brandDescriptions.length > 1;

                    modal.$modal.removeClass('loading');

                    modal.settings.header.html(isMultiple ? "Edit " + brandDescriptions.length + " Wheel Brands" : "<strong>Edit Brand:</strong> " + brandDescriptions[0].WheelBrandName);

                    createBrandForm('edit', {
                        isMultiple: isMultiple,
                        data: isMultiple ? findLikeData(brandDescriptions) : brandDescriptions[0],
                        filters: wheelFilters,
                        submitSuccessCallback: function () {
                            rstools.events.dataChanged('wheel-brand', 'edit');
                            modal.hide();
                        },
                        onCancel: function () {
                            modal.hide();
                        }
                    }).appendTo(modal.$body);

                    modal.setDisplayID($.map(brandDescriptions, function (b) { return b.WheelBrandID; }));
                })
                .fail(function () {
                    modal.hide();
                    uifactory.alert.show({ type: 'error', text: 'There was an error loading information about the wheel brand(s).' });
                });

            modal.show();
        },
        create: function (wheelData) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.WHEELS)) return;

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: 'Create a Wheel Brand',
                body: createBrandForm('create', {
                    data: wheelData,
                    submitSuccessCallback: function () {
                        rstools.events.dataChanged('wheel-brand', 'create');
                        modal.hide();
                    },
                    onCancel: function () {
                        modal.hide();
                    }
                })
            });

            modal.show();
        },
        manageResources: function (wheelBrandResourceFilters, settings) {
            var filters = wheelBrandResourceFilters;

            settings = $.extend({
                header: (rstools.user.isSystemAdmin() ? 'Manage' : 'View') + ' Wheel Brand Resources'
            }, settings);

            loosenFilters('brandResources', filters);

            var modal = uifactory.modal.create({
                header: settings.header,
                footer: false,
                cancelable: true
            });

            var $row = $('<div/>', { 'class': 'row' }).appendTo(modal.$body);
            var $column = $('<div/>', { 'class': 'col-sm-4 col-sm-offset-4' }).appendTo($row);

            function loadResources() {
                loadPaginatedList('Wheel/GetBrandResources', 'Wheel/CountBrandResources', 'Resources', filters)
                    .done(function (brandResources) {
                        function mapBrandResourceID(brs) { return brs.WheelBrandResource_ResourceID; }

                        uifactory.create.resourceThumbnail({
                            resources: $.map(brandResources, mapBrandResourceID).unique(),
                            title: "Logo",
                            imageParameters: {
                                Width: 200,
                                Height: 200,
                                PositionX: 1,
                                PositionY: 1
                            },
                            onLink: function () {
                                rstools.resource.list({
                                    mode: 'select',
                                    onSelect: function (resource) {
                                        modal.$modal.addClass('loading');

                                        ridestyler.ajax.send({
                                            action: 'Wheel/LinkBrandResource',
                                            data: $.extend({}, filters, {
                                                ResourceID: resource.ResourceID,
                                                WheelBrandResourceType: WheelBrandResourceType.Logo
                                            }),
                                            callback: function (response) {
                                                if (response.Success) {
                                                    loadResources();
                                                    rstools.events.dataChanged('wheel-brand', 'resource-link');
                                                } else {
                                                    uifactory.alert.show({ type: 'error', text: 'There was an error linking the resources(s): ' + response.Message });
                                                    modal.$modal.removeClass('loading');
                                                }
                                            }
                                        });
                                    },
                                    filters: {
                                        AssetType: ResourceAssetType.WheelBrandImage
                                    },
                                    selectFilter: function (r) {
                                        return r.ResourceAssetType === ResourceAssetType.WheelBrandImage;
                                    }
                                });
                            },
                            onUnlink: function () {
                                var confirm = uifactory.modal.create({
                                    type: 'confirmation',
                                    cancelable: true,
                                    header: 'Unlink Brand Resources',
                                    confirmationYes: 'Unlink',
                                    confirmationYesClass: 'btn btn-danger',
                                    body: 'Are you sure you wish to unlink all logos from the selected wheel brand?',
                                    confirmationCallback: function (saidYes) {
                                        if (saidYes) {
                                            modal.$modal.addClass('loading');

                                            ridestyler.ajax.send({
                                                action: 'Wheel/UnlinkBrandResource',
                                                data: $.extend({}, filters, {
                                                    WheelBrandResourceType: WheelBrandResourceType.Logo
                                                }),
                                                callback: function (response) {
                                                    if (response.Success) {
                                                        loadResources();
                                                        confirm.hide();
                                                        rstools.events.dataChanged('wheel-brand', 'resource-unlink');
                                                    } else {
                                                        uifactory.alert.show({ type: 'error', text: 'There was an error unlinking the resources(s).' });
                                                        modal.$modal.removeClass('loading');
                                                    }
                                                }
                                            });
                                        }
                                    }
                                });

                                confirm.show();
                            }
                        }).appendTo($column.empty());

                        modal.$modal.removeClass('loading');
                    });
            }

            loadResources();

            modal.$modal.addClass('loading');

            modal.show();
        },
        createBrandInclusionButtons: function (brand) {
            return uifactory.create.brandInclusionButtons({
                brand: brand,
                objectType: 'wheel-brand',
                action: 'Wheel/ChangeBrandInclusion'
            });
        },
        utils: {
            getIDs: function (brands) {
                return $.map(brands, function (brand) {
                    return brand.WheelBrandID;
                });
            },
            getTitles: function (brands) {
                return $.map(brands, function (brand) {
                    return brand.WheelBrandName;
                });
            },
            createFilters: function (brands, loosen) {
                var filters = {
                    WheelBrands: rstools.wheel.brand.utils.getIDs(brands)
                };

                if (loosen) rstools.wheel.utils.loosenFilters('brand', filters);
                
                return filters;
            },
            isArchived: function(/** @type {WheelBrandDescriptionModel} */ brand) {
                return brand.WheelBrandArchived;
            },
            isUnarchived: function (/** @type {WheelBrandDescriptionModel} */ brand) {
                return !brand.WheelBrandArchived;
            }
        },

        getResources: function (filters) {
            return rstools.utils.apiDeferred({ action: 'Wheel/GetBrandResources', responseKey: 'Resources', data: filters });
        },

        noun: 'wheel brand',
        nounPlural: 'wheel brands',
        dataNamespace: 'wheel-brand',

        getAction: 'Wheel/GetBrands',
        getActionResponseKey: 'Brands',
        countAction: 'Wheel/CountBrands',

        archiveAction: 'Wheel/ArchiveBrands',
        unarchiveAction: 'Wheel/UnarchiveBrands',
        
		getMetaAction: 'Wheel/GetBrandMeta',
		setMetaAction: 'Wheel/SetBrandMeta',
		clearMetaAction: 'Wheel/ClearBrandMeta',
        
        getPromotionStatusAction: 'Promotion/GetWheelFitmentStatus',
        setPromotionStatusAction: 'Promotion/SetWheelFitmentStatus'
    };
})(jQuery);