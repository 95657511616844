(function ($) {
	rter.mapController('app.wheel.pricing.fitment', function (context) {
		var grid = null;
		var filters = {};
		var $filterForm, $filterButton;
		var $backToModelsButton;

		return {
			viewWillLoad: function (ctx) {
				filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
			},
			viewCreated: function ($view) {
				grid =
                    datagrid.create({
                        container: $view.find('div.data-wrapper'),

                        headerActions: [
                            rstools.shared.datagrid.headerActions.manageColumns
                        ],
                        getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,

						fields: [
							{ name: 'WheelFitmentPartNumber', format: function(r) {
								return r.PartNumber || $('<span/>', { 'class': 'text-muted', 'text': 'None' });
							}, caption: 'Part #', colClass: 'breakAll' },
							{ name: 'WheelModelFinishDescription', caption: 'Finish', sort: false },
							{
								name: 'BoltPatterns',
								caption: 'Bolt Pattern',
								format: function (r) {
									return rstools.utils.addAbbreviations(r.BoltPatternDescription, [
										['"', 'in', 'inches'],
										["mm", "millimeters"]
									]);
								}
							},
							{
								name: 'ItemNumber',
								caption: 'Item Number',
								sort: false,
								visible: false,
								format: function (r) {
									return rstools.pricing.utils.createItemNumberDisplay(r.Pricing);
								}
							},
                            {
                                name: '!Price',
                                caption: 'Price',
                                format: function (r) {
									if (r.Pricing)
                                    	return rstools.pricing.format.price(rstools.pricing.utils.getBestPricingType(r.Pricing).WheelPricingAmount);
                                }
                            },
                            {
								name: 'WheelFitmentNotes',
								caption: 'Notes',
								colClass: 'text-center',
								sort: false,
								columnRenderer: rstools.notes.createColumnRenderer('wheel.fitment'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
							},
							{ name: 'actions', caption: '', width: 50, sort: false }
						],
						actions: [
							{
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.pricing.edit({
                                        action: 'Wheel/GetPrices',
                                        countAction: 'Wheel/CountPrices',
                                        updateAction: 'Wheel/EditPrice',
                                        createAction: 'Wheel/CreatePrice',
                                        deleteAction: 'Wheel/DeletePrice',
                                        namespace: 'wheel-fitment-pricing',
                                        filters: rstools.wheel.utils.loosenFilters('fitment', {
                                            WheelFitments: rstools.wheel.fitment.utils.getIDs(rows)
                                        }),
										PricingGroupID: filters.PricingGroupID
                                    });
                                }
                            }
						],
						rowCallback: function ($tr, fitmentDescription) {
							if (fitmentDescription.WheelFitmentArchived) $tr.addClass('archived-data-object');
						},
						getAction: 'Wheel/GetFitmentDescriptions',
						countAction: 'Wheel/CountFitmentDescriptions',
						resultListField: 'Fitments',
						baseQueryData: {
							SelectBrandsOnly: false,
							UsePromotionOrder: false,
							UseBrandPriorities: false,
							HasFitments: false,
                            IncludePricing: true,
                            HasPricing: true
						},
						search: filters
					});

                $filterForm = rstools.pricing.utils.createFilterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });
								
				var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

				$backToModelsButton = $('<a/>', {
					'class': 'btn btn-info',
					'append': [
						uifactory.create.icon('caret-left'),
						' Models'
					],
					'href': '#!/app/wheel/pricing/model'
				}).appendTo($leftButtons);

				$filterButton = uifactory.create.filterButton({
					menu: $filterForm
				})
					.appendTo($leftButtons)
					.trigger('filters-updated', [Object.keys(filters).length]);
			},
			viewLoaded: function (ctx) {
                // Update filters from the current ctx
                // or grab them from the original context
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

                if ($filterForm)
                    $filterForm.syncObjectToForm(filters);

                if (ctx.previousContext && ctx.previousContext.path === '/app/wheel/pricing/model') {
                    var previousState = rter.utils.toParamString(ctx.previousState);

                    $backToModelsButton.attr('href', '#!/app/wheel/pricing/model' + (previousState ? "?" + previousState : ""));
                } else {
                    $backToModelsButton.attr('href', '#!/app/wheel/pricing/model');
                }
            }
		};

		function filtersUpdated() {
			$filterForm.syncFormToObject(filters);
            $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(filters);
            rstools.state.set('filters', filters);
		}
	});
})(jQuery);