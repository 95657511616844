
(function ($) {
    rter.mapController('app.wheel.promo', function (context) {
        var filters = {}, $list;

        return {
            actions: {
                save: function () {
                    var changes = [];

                    $list.find('li').each(function () {
                        $this = $(this);

                        var id = $this.data('id');

                        var status = -1;
                        if ($this.find('.btn.active').hasClass('button-exclude'))
                            status = 0;
                        else if ($this.find('.btn.active').hasClass('button-include'))
                            status = 4;

                        if (status != -1) {
                            changes.push({
                                PromotionID: id,
                                Status: status
                            });
                        }
                    });

                    var data = {
                        changes: changes,
                        filters: filters
                    };

                    $list.addClass('loading').find('li').fadeTo(250, 0.5);

                    ridestyler.ajax.send({
                        action: 'Promotion/SetWheelFitmentStatus',
                        data: data,
                        callback: function (response) {
                            if (response.Success) {
                                uifactory.alert.show({
                                    text: 'You promotion changes have been saved!',
                                    stack: 'wheel_promo'
                                });
                            } else {
                                uifactory.alert.show({
                                    text: response.Message,
                                    stack: 'wheel_promo',
                                    type: 'error'
                                });
                            }

                            $list.find('li').fadeTo(250, 1, function () {
                                $list.removeClass('loading');
                            });
                        }
                    });
                }
            },
            viewWillLoad: function (context) {
            },
            viewWillCreate: function ($view) {
            },
            viewCreated: function ($view) {
                filters = $.extend(filters, context.state ? context.state['filters'] : {});
                $list = $view.find('ul.promo-list');

                $view.find('.result-column').addClass('loading');

                ridestyler.ajax.send({
                    action: 'Promotion/GetWheelFitmentStatus',
                    data: filters,
                    callback: function (response) {
                        $list.find('li').remove();
                        if (response.Success) {
                            if (response.PromotionStatus.length == 0) {
                                $view.find('.no-promo-warning').fadeIn();

                            } else {
                                rstools.template.get('promo/row').done(function () {
                                    for (var i = 0; i < response.PromotionStatus.length; i++) {
                                        var p = response.PromotionStatus[i];

                                        var $li = rstools.template.render('promo/row', {
                                            text: p.Promotion.PromotionName,
                                            id: p.Promotion.PromotionID
                                        }, {
                                            async: false
                                        });

                                        if (p.Status === 0) {
                                            $li.find('.button-exclude').addClass('active').find('input').prop('checked', true);
                                        } else if (p.Status === 4) {
                                            $li.find('.button-include').addClass('active').find('input').prop('checked', true);
                                        }

                                        $list.append($li);
                                    }

                                    $view.find('.has-promo-section').fadeIn();
                                });
                            }
                        }

                        $view.find('.result-column').removeClass('loading');
                    }
                });
            }
        };
    });
})(jQuery);