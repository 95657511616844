(function () {
    /** @type {TireFitmentResourceType} */
    var TireFitmentResourceType = rstools.constants.TireFitmentResourceType;
    var ResourceAssetType = rstools.constants.ResourceAssetType;
    var TireFitmentResourceVariant = rstools.constants.TireFitmentResourceVariant;

    /** @type {TireWarrantyType} */ 
    var TireWarrantyType = rstools.constants.TireWarrantyType;

    /** @type {TireUseTypeFlags} */
    var TireUseTypeFlags = rstools.constants.TireUseTypeFlags;

    var tireFitmentResourceTypeToAssetType = {};
	tireFitmentResourceTypeToAssetType[TireFitmentResourceType.Side] = ResourceAssetType.TireImageSide;
	tireFitmentResourceTypeToAssetType[TireFitmentResourceType.Tread] = ResourceAssetType.TireImageTread;
	tireFitmentResourceTypeToAssetType[TireFitmentResourceType.Catalog] = ResourceAssetType.TireImageCatalog;
    tireFitmentResourceTypeToAssetType[TireFitmentResourceType.Optional1] = ResourceAssetType.TireOptionalResource;
    tireFitmentResourceTypeToAssetType[TireFitmentResourceType.Optional2] = ResourceAssetType.TireOptionalResource;
    tireFitmentResourceTypeToAssetType[TireFitmentResourceType.Optional3] = ResourceAssetType.TireOptionalResource;

    rstools.tire.fitment = {
        manageResources: function(tireFilters, settings) {
			var filters = tireFilters;

			settings = $.extend({
				header: (rstools.user.isSystemAdmin() ? 'Manage' : 'View') + ' Tire Fitment Resources'
			}, settings);

			if (!filters) throw new TypeError("You must pass in filters to rstools.tire.fitment.manageResources.");

			rstools.tire.utils.loosenFilters('fitmentResources', filters);

			var modal = uifactory.modal.create({
				header: settings.header,
				footer: false,
				cancelable: true
			});

            modal.$header.addClass('no-border-bottom no-padding-bottom');

            var selectVariant = function (variant) {
                // loadResources refreshes the current view
                var loadResources = function () {selectVariant(variant);};

                modal.$body.empty();
                modal.$modal.addClass('loading');

                // Setup tab panel
                var tabPanel = uifactory.create.tabPanel({
                    tabs: [
                        { 'label': 'Images' },
                        { 'label': 'Optional Images' }
                    ]
                });
                tabPanel.$panel.appendTo(modal.$body);

                // Setup the sidewall selector
                {
                    var $sidewallContainer = $('<div/>')
                        .append($('<strong/>').text('Sidewall: '))
                        .addClass('pull-right')
                        .prependTo(tabPanel.$panel);


                    uifactory.create.dropdown({
                        options: [
                            {
                                label: 'Primary',
                                value: TireFitmentResourceVariant.PrimarySidewall
                            },
                            {
                                label: 'Alternate',
                                value: TireFitmentResourceVariant.AlternateSidewall
                            }
                        ],
                        right: true,
                        value: variant
                    }).appendTo($sidewallContainer).on('change', function () {
                        var selectedOption = $(this).data('rsDropdown').selectedOption.value;
                        selectVariant(selectedOption);
                    });
                }
                
                var $columns;

                // Setup column containers
                {
                    var $rowImages = $('<div/>', { 'class': 'row' }).appendTo(tabPanel.tabs[0].$pane),
                        $rowOtherImages = $('<div/>', { 'class': 'row' }).appendTo(tabPanel.tabs[1].$pane);

                    var createColumn = function ($row) {
                        return $('<div/>', { 'class': 'col-sm-4' }).appendTo($row); 
                    };

                    $columns = {
                        side: createColumn($rowImages),
                        tread: createColumn($rowImages),
                        catalog: createColumn($rowImages),

                        optional1: createColumn($rowOtherImages),
                        optional2: createColumn($rowOtherImages),
                        optional3: createColumn($rowOtherImages)
                    };
                }

                // Load data
                rstools.utils.loadPaginatedList('Tire/GetFitmentResources', 'Tire/CountFitmentResources', 'Resources', $.extend({
                    Variant: variant
                }, filters))
                    .done(function(fitmentResources) {
                        var tireFitmentResources = {
                            side: [],
                            tread: [],
                            catalog: [],

                            optional1: [],
                            optional2: [],
                            optional3: []
                        };

                        for (var i = 0; i < fitmentResources.length; i++)
                            switch (fitmentResources[i].TireFitmentResourceType) {
                                case TireFitmentResourceType.Tread:
                                    tireFitmentResources.tread.push(fitmentResources[i]);
                                    break;

                                case TireFitmentResourceType.Side:
                                    tireFitmentResources.side.push(fitmentResources[i]);
                                    break;

                                case TireFitmentResourceType.Catalog:
                                    tireFitmentResources.catalog.push(fitmentResources[i]);
                                    break;

                                case TireFitmentResourceType.Optional1:
                                    tireFitmentResources.optional1.push(fitmentResources[i]);
                                    break;

                                case TireFitmentResourceType.Optional2:
                                    tireFitmentResources.optional2.push(fitmentResources[i]);
                                    break;

                                case TireFitmentResourceType.Optional3:
                                    tireFitmentResources.optional3.push(fitmentResources[i]);
                                    break;
                            }

                        function createResourceThumbnail(title, key, tireFitmentResourceType) {
                            var resources = tireFitmentResources[key];
                            var resourceAssetType = tireFitmentResourceTypeToAssetType[tireFitmentResourceType];

                            var $thumbnail = uifactory.create.resourceThumbnail({
                                resources: rstools.tire.fitment.resource.utils.getResourceIDs(resources).unique(),
                                title: title,
                                imageParameters: {
                                    Width: 200,
                                    Height: 200,
                                    PositionX: 1,
                                    PositionY: 1
                                },
                                onLink: function() {
                                    rstools.resource.list({
                                        mode: 'select',
                                        onSelect: function(resource) {
                                            modal.$modal.addClass('loading');

                                            ridestyler.ajax.send({
                                                action: 'Tire/LinkFitmentResource',
                                                data: $.extend({}, filters, {
                                                    ResourceID: resource.ResourceID,
                                                    TireFitmentResourceType: tireFitmentResourceType,
                                                    TireFitmentResourceVariant: variant
                                                }),
                                                callback: function(response) {
                                                    if (response.Success) {
                                                        loadResources();
                                                        rstools.events.dataChanged('tire-model', 'resource-link');
                                                        rstools.events.dataChanged('tire-fitment', 'resource-link');
                                                    } else {
                                                        rstools.api.utils.showResponseError('There was an error linking the resources(s)', response);
                                                        modal.$modal.removeClass('loading');
                                                    }
                                                }
                                            });
                                        },
                                        filters: {
                                            AssetType: resourceAssetType
                                        },
                                        selectFilter: function(r) {
                                            return r.ResourceAssetType === resourceAssetType;
                                        }
                                    });
                                },
                                onUnlink: function() {
                                    var confirm = uifactory.modal.create({
                                        type: 'confirmation',
                                        cancelable: true,
                                        header: 'Unlink Fitment Resources',
                                        body: 'Are you sure you wish to unlink all "' + title + '" images from the selected tire fitments/models?',
                                        confirmationYes: 'Unlink',
                                        confirmationYesClass: 'btn btn-danger',
                                        confirmationCallback: function(saidYes) {
                                            if (saidYes) {
                                                modal.$modal.addClass('loading');

                                                ridestyler.ajax.send({
                                                    action: 'Tire/UnlinkFitmentResource',
                                                    data: $.extend({}, filters, {
                                                        TireFitmentResourceType: tireFitmentResourceType,
                                                        TireFitmentResourceVariant: variant
                                                    }),
                                                    callback: function(response) {
                                                        if (response.Success) {
                                                            loadResources();
                                                            confirm.hide();
                                                            rstools.events.dataChanged('tire-model', 'resource-unlink');
                                                            rstools.events.dataChanged('tire-fitment', 'resource-unlink');
                                                        } else {
                                                            rstools.api.utils.showResponseError('There was an error unlinking the resources(s)', response);
                                                            modal.$modal.removeClass('loading');
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    });

                                    confirm.show();
                                }
                            });

                            // Create the priority star
                            if (resources.length > 0) {
                                var $priorityStar;

                                var priorityCount = 0;
                                var priorityThreshold = 10;
                                
                                // Count the number of priority resources
                                for (var i = 0; i < resources.length; i++) 
                                    if (resources[i].TireFitmentResourcePriority >= priorityThreshold)
                                        priorityCount++;

                                var priorityIcon = 'star';

                                // If there's multiple resources of this type and they're not
                                // all priority use a half star
                                if (priorityCount > 0 && resources.length > priorityCount)
                                    priorityIcon = 'star-half';

                                $priorityStar = uifactory.create.icon(priorityIcon);

                                if (priorityCount === 0) {
                                    $priorityStar
                                        .addClass('transition-opacity transparent hover-opaque');
                                    $thumbnail
                                        .hover(function () { // Fade In
                                            $priorityStar.css('opacity', 0.5);
                                        }, function () { // Fade Out
                                            $priorityStar.css('opacity', 0);
                                        });
                                }

                                if (rstools.user.isSystemAdmin()) {
                                    /**
                                     * If true set priority for these resources, otherwise clear it
                                     */
                                    var setPriority = priorityCount < resources.length;
                                    
                                    $priorityStar
                                        .addClass('clickable')
                                        .attr('title', setPriority ? 'Make Primary' : 'Clear Priority')
                                        .tooltip({
                                            placement: 'right'
                                        })
                                        .click(function () {
                                            modal.$modal.addClass('loading');

                                            var fitmentResourceIDs = [];
                                            for (var type in tireFitmentResources) {
                                                for (var i = 0; i < tireFitmentResources[type].length; i++) {
                                                    fitmentResourceIDs.push(rstools.tire.fitment.resource.utils.getID(tireFitmentResources[type][i]));
                                                }
                                            }

                                            ridestyler.ajax.send({
                                                action: 'Tire/SetFitmentResourcePriority',
                                                data: {
                                                    FitmentResources: fitmentResourceIDs,
                                                    PriorityType: setPriority ? tireFitmentResourceType : null,
                                                    Priority: 0
                                                },
                                                callback: function(response) {
                                                    var s = resources.length > 1 ? 's' : '';
                                                    var asPrimary = setPriority ? ' as primary' : ' as not primary';
                                                    if (response.Success) {
                                                        var was = resources.length > 1 ? 'were' : 'was';
                                                        uifactory.alert.show({text: 'The image' + s + ' ' + was + ' marked' + asPrimary, type: 'success'});
                                                        loadResources();
                                                    } else {
                                                        rstools.api.utils.showResponseError('There was a problem marking the image' + s + asPrimary, response);
                                                        modal.$modal.removeClass('loading');
                                                    }
                                                }
                                            });
                                        });
                                }

                                $priorityStar.addClass('text-primary');

                                // Make it float :)
                                $thumbnail.prepend($('<div/>', {
                                    append: $priorityStar,

                                    css: {
                                        position: 'absolute',
                                        fontSize: '1.7em',
                                        paddingLeft: '3px'
                                    }
                                }));
                            }

                            return $thumbnail;
                        }

                        createResourceThumbnail("Side", 'side', TireFitmentResourceType.Side).appendTo($columns.side.empty());
                        createResourceThumbnail("Tread", 'tread', TireFitmentResourceType.Tread).appendTo($columns.tread.empty());
                        createResourceThumbnail("Catalog", 'catalog', TireFitmentResourceType.Catalog).appendTo($columns.catalog.empty());
                        createResourceThumbnail("Option 1", 'optional1', TireFitmentResourceType.Optional1).appendTo($columns.optional1.empty());
                        createResourceThumbnail("Option 2", 'optional2', TireFitmentResourceType.Optional2).appendTo($columns.optional2.empty());
                        createResourceThumbnail("Option 3", 'optional3', TireFitmentResourceType.Optional3).appendTo($columns.optional3.empty());

                        modal.$modal.removeClass('loading');
                    });
            };

            selectVariant(TireFitmentResourceVariant.PrimarySidewall);

			modal.show();
		},

        utils: {
            getTitles: function (rows, filter) {
                return $.map(rows, function (row) {
                    if (filter && !filter(row)) return undefined;

					var title = "";

					if (row.TireSize) title += row.TireSize.Description + ';';
					if (row.TireSidewall) title += " (" + row.TireSidewall.TireSidewallName + ")";

					return title || "Tire fitment ID " + row.TireFitmentID;
                });
            },

            getIDs: function (rows, filter) {
                return $.map(rows, function (row) {
                    if (filter && !filter(row)) return undefined;
                    return row.TireFitmentID;
                });
            },

            /**
             * @param {TireFitmentDescriptionModel[]} fitments
             */
            objectListFromFitmentDescriptions: function (fitments) {
                return uifactory.create.objectList({
                    objects: fitments,

                    headers: [
                        "Part #",
                        "Brand",
                        "Model",
                        "Size",
                        "Sidewall"
                    ],

                    columns: [
                        'TireFitmentPartNumber',
                        /**
                         * @param {TireFitmentDescriptionModel} fitment
                         */
                        function (fitment) {
                            var small = document.createElement('small');
                                small.appendChild(document.createTextNode('(' + (fitment.DataSourceCode || fitment.DataSourceDescription) + ')'));

                            return [fitment.TireBrandName, ' ', small];
                        },
                        'TireModelName',
                        'TireSize.Description',
                        'TireSidewall.TireSidewallName'
                    ]
                });
            },

            createFilters: function (fitments, loosen) {
                var filters = {
                    TireFitments: rstools.tire.fitment.utils.getIDs(fitments)
                };

                if (loosen) rstools.tire.utils.loosenFilters('fitment', filters);

                return filters;
            },

            isUnarchived: function (/** @type {TireFitmentDataObject} */ fitment) {
                return !fitment.TireFitmentArchived;
            },

            isArchived:  function (/** @type {TireFitmentDataObject} */ fitment) {
                return fitment.TireFitmentArchived;
            },
        },

        settings: {
            fields: [
                {
                    label: 'Brand',
                    type: 'static',
                    id: 'tire-fitment-edit-brand-display'
                },
                {
                    label: 'Model',
                    name: 'TireFitment_TireModelID',
                    type: 'static',
                    id: 'tire-fitment-edit-model-display'
                },  
                {
                    label: 'Part Number',
                    type: 'text',
                    name: 'TireFitmentPartNumber',
                    maxLength: 50,
                    required: true,
                    deferredValidate: true,
                    validate: function(data, rule, formSettings) {
                        var partNumber = $.trim(data.TireFitmentPartNumber);
                        var deferred = $.Deferred();

                        var filters = formSettings.baseData;
                        var type = formSettings.url === rstools.tire.fitment.editAction ? 'edit' : 'create';

                        var modal;

                        if (!partNumber) {
                            modal = uifactory.modal.create({
                                type: 'confirmation',
                                cancelable: true,
                                minimizable: false,
                                header: 'Empty Part Number',
                                confirmationYes: type === 'create' ? 'Create' : 'Save',
                                confirmationYesClass: 'btn btn-warning',
                                body: "You're trying to " + (type === 'create' ? 'create a tire fitment with ' : 'edit a tire fitment to have ') +
                                    'no part number. Are you sure you wish to do this?',
                                confirmationCallback: function(saidYes) {
                                    if (saidYes)
                                        deferred.resolve();
                                    else
                                        deferred.reject('Are you sure you don\'t want this tire fitment to have a part number?');

                                    modal.hide();
                                },
                                startHidden: false
                            });
                        } else {
                            var getPartNumberFitments = rstools.utils.apiDeferred({
                                action: 'Tire/GetFitments',
                                data: rstools.tire.utils.loosenFilters('fitments', {TireFitmentPartNumber: partNumber}),
                                responseKey: 'Fitments'
                            });

                            var getApplicableFitments = type === 'edit' ? rstools.utils.apiDeferred({
                                action: 'Tire/GetFitments',
                                data: rstools.tire.utils.loosenFilters('fitments', filters),
                                responseKey: 'Fitments'
                            }) : $.Deferred().resolve([]);

                            $.when(getPartNumberFitments, getApplicableFitments)
                                .done(function(partNumberFitments, applicableFitments) {
                                    var otherPartNumberCount = partNumberFitments.length;

                                    if (type === 'edit') {
                                        // Remove specified ID numbers from the count
                                        for (var applicableFitmentIndex = 0; applicableFitmentIndex < data.TireFitments.length; applicableFitmentIndex++) {
                                            for (var partNumberFitmentIndex = 0; partNumberFitmentIndex < partNumberFitments.length; partNumberFitmentIndex++) {
                                                if (partNumberFitments[partNumberFitmentIndex].TireFitmentID === applicableFitments[applicableFitmentIndex].TireFitmentID) {
                                                    otherPartNumberCount--;
                                                    break;
                                                }
                                            }
                                        }
                                    }

                                    // Confirm if the user specifies a part number that already exists
                                    if (otherPartNumberCount > 0) {
                                        modal = uifactory.modal.create({
                                            type: 'confirmation',
                                            cancelable: true,
                                            minimizable: false,
                                            header: 'Part Number Already Exists',
                                            confirmationYes: type === 'create' ? 'Create' : 'Save',
                                            confirmationYesClass: 'btn btn-warning',
                                            body: "You're trying to " + (type === 'create' ? 'create a tire fitment with ' : 'edit a tire fitment to have ') +
                                                'a part number that matches ' + otherPartNumberCount + ' other fitments. Are you sure you wish to do this?',
                                            confirmationCallback: function(saidYes) {
                                                if (saidYes)
                                                    deferred.resolve();
                                                else
                                                    deferred.reject('The part number matched ' + otherPartNumberCount + ' other fitments.');

                                                modal.hide();
                                            },
                                            startHidden: false
                                        });
                                    } else {
                                        deferred.resolve();
                                    }
                                })
                                .fail(function() {
                                    deferred.reject('Unable to verify part number.');
                                });
                        }

                        deferred.fail(function () {
                            if (modal && modal.$modal.hasClass('in'))
                                modal.hide();
                        });

                        return deferred;
                    }
                },
                {
                    label: 'Sidewall',
                    name: 'TireFitmentPrimary_TireSidewallID',
                    type: 'dropdown',
                    url: 'Tire/GetSidewalls',
                    responseKey: 'Sidewalls',
                    idColumnName: 'TireSidewallID',
                    required: true,
                    data: rstools.tire.utils.loosenFilters('fitments', {}),
                    initialOptions: [
                        { 
                            value: false,
                            label: 'Select a Sidewall'
                        },
                        '-'
                    ],
                    resultMap: function (/** @type {TireSidewallDataObject} */ sidewall) {
                        return {
                            value: sidewall.TireSidewallID,
                            label: sidewall.TireSidewallName
                        };
                    }
                },
                {
                    label: 'Secondary Sidewall',
                    name: 'TireFitmentSecondary_TireSidewallID',
                    type: 'dropdown',
                    url: 'Tire/GetSidewalls',
                    responseKey: 'Sidewalls',
                    idColumnName: 'TireSidewallID',
                    required: false,
                    data: rstools.tire.utils.loosenFilters('fitments', {}),
                    initialOptions: [
                        { 
                            value: false,
                            label: 'No Secondary Sidewall'
                        },
                        '-'
                    ],
                    resultMap: function (/** @type {TireSidewallDataObject} */ sidewall) {
                        return {
                            value: sidewall.TireSidewallID,
                            label: sidewall.TireSidewallName
                        };
                    }
                },
                {
                    label: 'Size',
                    type: 'text',
                    name: 'Size',
                    required: true,
                    deferredValidate: true,
                    validate: function (data, rule) {
                        var sizeDescription = data.Size || "";
                        
                        if (!sizeDescription.trim() && rule.field.required)
                            return $.Deferred().reject("You must specify a tire size for tire fitments");

                        return rstools.utils.apiDeferred({
                            action: 'Tire/VerifySize',
                            data: {
                                Size: sizeDescription
                            }
                        }).then(function (/** @type {RideStylerBaseResponse} */ response) {
                            var d = $.Deferred();

                            /** @type {boolean} */ 
                            var isValid = response.Valid;

                            if (isValid) d.resolve();
                            else d.reject(response.Message);

                            return d;
                        }, function (/** @type {RideStylerBaseResponse} */ response) {
                            return response.Message;
                        });
                    }
                },
                {
                    label: 'Wheel Width',
                    type: 'range',
                    minFieldName: 'TireFitmentWheelWidthMin',
                    maxFieldName: 'TireFitmentWheelWidthMax',
                    min: 3,
                    max: 20,
                    required: true,
                    hasMultipleValues: function (data) {
                        return this.minFieldName in data === false || this.maxFieldName in data === false;
                    },
                    applyValue: function (data) {
                        return [data.TireFitmentWheelWidthMin, data.TireFitmentWheelWidthMax];
                    }
                },
                {
                    label: 'Uniform Tire Quality Grading (UTQG)',
                    type: 'text',
                    name: 'TireFitmentUTQG',
                    required: false
                },
                {
                    label: 'Warranty Type',
                    name: 'TireFitmentWarrantyType',
                    type: 'dropdown',
                    initialOptions: [{ 'label': 'Warranty Type', value: undefined }, '-'],
                    options: rstools.utils.dropdownOptionsFromObject(TireWarrantyType),
                    onChange: function () {
                        var value = $(this).data('rs-dropdown').selectedOption.value;

                        $(this)
                            .closest('form')
                            .find('input[name=TireFitmentWarrantyMileage]')
                            .closest('.form-group')
                            .css('display', value == TireWarrantyType.Mileage ? '' : 'none');
                    }
                },
                {
                    label: 'Warranty Mileage',
                    name: 'TireFitmentWarrantyMileage',
                    type: 'number',
                    hidden: true,
                    min: 1000,
                    validate: function (data, rule) {
                        if (data.TireFitmentWarrantyType == TireWarrantyType.Mileage) {
                            var mileage = data.TireFitmentWarrantyMileage;
                            
                            if (!mileage) return 'This field is required';
                            
                            if (mileage < rule.field.min) return 'This field must be greater or equal to 1,000 miles';
                        }
                    }
                },
				{
					type: 'dropdown',
					name: 'TireFitmentUseType',
					label: 'Use Type',
                    options: rstools.utils.dropdownOptionsFromObject(rstools.constants.TireUseTypeFlags),
                    multiple: true,
                    clearWhenFalse: true,
					initialOptions: [{ label: 'Choose a Use Type', value: false }, '-'],
					parseAsMultiple: 'TireFitmentUseType',
					labelHintSummary: 'Multiple',
					labelHintText: 'You may select multiple values for this filter.',
                    validate: function (data, rule) {
                        var useType = data.TireFitmentUseType;

                        if (!useType && rule.field.required)
                            return "This field is required";

                        if (~useType.indexOf(TireUseTypeFlags.AllVehicles) && useType != TireUseTypeFlags.AllVehicles)
                            return "The All Vehicles use type cannot be combined with other use types";
                    },
                    selectedValueFilter: function (value, selectedValue) {
                        if (typeof value === 'string' && !isNaN(value)) value = parseInt(value);
                        if (typeof selectedValue === 'string' && !isNaN(selectedValue)) selectedValue = parseInt(selectedValue);

                        if (value === 0)
                            return selectedValue === 0;
                        else if (typeof value === 'number' && typeof selectedValue === 'number')
                            return (value & selectedValue) === value;

                        return value === selectedValue;
                    }
				}
            ]
        },

        create: function (fitmentData) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;

            var modal = uifactory.modal.create({
                cancelable: true,
                footer: false,
                header: 'Create a Tire Fitment',
                startHidden: false,
                loadData: {
                    Models: {
                        action: 'Tire/GetModelDescriptions',
                        data: rstools.tire.utils.loosenFilters('fitment', {
                            TireModel: fitmentData.TireFitment_TireModelID
                        }),
                        responseKey: 'Models',
                        required: true
                    }
                },
                bodyFormat: function (data) {
                    var modelDescriptions = data.Models;

                    return uifactory.create.dataEditForm('tire.fitment', {
                        data: fitmentData,
                        type: 'create',
                        dataFields: {
                            'tire-fitment-edit-brand-display': {
                                text: $.map(modelDescriptions, function(/** @type {TireModelDescriptionModel}*/ model) {
                                    return model.TireBrandName + ' (' + model.DataSourceDescription + ')';
                                }).unique().join(', ')
                            },
                            'tire-fitment-edit-model-display': {
                                text: $.map(modelDescriptions, function(/** @type {TireModelDescriptionModel}*/ model) {
                                    return model.TireModelName;
                                }).unique().join(', ')
                            }
                        },
                        submitSuccessCallback: function() {
                            rstools.events.dataChanged('tire-fitment', 'create');
                            modal.hide();
                        },
                        onCancel: function() {
                            modal.hide();
                        }
                    });
                },
                onFail: function (response) {
                    rstools.api.utils.showResponseError('There was an error gathering data for the fitment', response);
                }
            });
        },

        edit: function (filters) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;

            rstools.tire.utils.loosenFilters('fitments', filters);

            var modal = uifactory.modal.create({
                cancelable: true,
                footer: false,
                startHidden: false,
                loadData: {
                    'Fitments': rstools.utils.loadPaginatedList('Tire/GetFitments', 'Tire/CountFitments', 'Fitments', filters, true),
                    'Models': rstools.utils.loadPaginatedList('Tire/GetModelDescriptions', 'Tire/CountModels', 'Models', filters, true),
                    'Sizes': rstools.utils.loadPaginatedList('Tire/GetSizes', 'Tire/CountSizes', 'Sizes', filters, true)
                },
                headerFormat: function (data) {
                    var numFitments = data.Fitments.length;

                    return numFitments > 1 ? 'Edit ' + numFitments + ' Tire Fitments' : 'Edit Tire Fitment';
                },
                bodyFormat: function (data) {
                    var fitments = data.Fitments;
                    var sizes = data.Sizes;
                    var isMultiple = fitments.length > 1;
                    var modelDescriptions = data.Models;

                    // Store tire size descriptions in fitment.Size
                    $.each(fitments, function (fitmentsIndex, fitment) {
                        for (var i = 0; i < sizes.length; i++) {
                            var tireSize = sizes[i];
                            
                            if (fitment.TireFitment_TireSizeID == tireSize.TireSizeID) {
                                fitment.Size = tireSize.TireSizeDescription;
                                return;
                            }
                        }
                    });

                    return uifactory.create.dataEditForm('tire.fitment', {
                        type: 'edit',
                        isMultiple: isMultiple,
                        data: isMultiple ? rstools.utils.findLikeData(fitments, ['TireFitment_TireModelID']) : fitments[0],
                        dataFields: {
                            'tire-fitment-edit-brand-display': {
                                text: $.map(modelDescriptions, function(/** @type {TireModelDescriptionModel}*/ model) {
                                    return model.TireBrandName + ' (' + model.DataSourceDescription + ')';
                                }).unique().join(', ')
                            },
                            'tire-fitment-edit-model-display': {
                                text: $.map(modelDescriptions, function(/** @type {TireModelDescriptionModel}*/ model) {
                                    return model.TireModelName;
                                }).unique().join(', ')
                            }
                        },
                        filters: filters,
                        submitSuccessCallback: function() {
                            rstools.events.dataChanged('tire-fitment', 'edit');
                            modal.hide();
                        },
                        onCancel: function() {
                            modal.hide();
                        }
                    });
                },
                idFormat: function (data) {
                    return rstools.tire.fitment.utils.getIDs(data.Fitments);
                },
                onFail: function (response) {
                    rstools.api.utils.showResponseError('There was an error gathering data for the fitment', response);
                }
            });
        },
        
        copyTo: function (filters, tireModelID, settings) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;

            settings = $.extend({
                header: undefined,
                callback: undefined
            }, settings);

            rstools.tire.utils.loosenFilters('fitment', filters);

            var modal = uifactory.modal.create({
                cancelable: true,
                header: settings.header,
                startLoading: true,
                actions: [
                    {
                        'class': 'btn btn-default',
                        'title': 'Cancel',
                        'callback': function () { modal.hide(); }
                    },
                    {
                        'class': 'btn btn-primary',
                        'title': 'Copy Fitments',
                        'callback': function () {
                            modal.$modal.addClass('loading');

                            rstools.utils.apiDeferred({ action: 'Tire/CopyFitmentsToModel', data: $.extend({
                                ToTireModel: tireModelID
                            }, filters)})
                                .done(function () {
                                    rstools.events.dataChanged('tire-fitment', 'copy');

                                    if (typeof settings.callback === 'function') settings.callback();

                                    modal.hide();

                                    uifactory.alert.show({ 
                                        type: 'success', 
                                        text: "The tire fitments were copied."
                                    });
                                })
                                .fail(function (response) {
                                    modal.$modal.removeClass('loading');
                                    rstools.api.utils.showResponseError('There was an error copying the fitments', response);
                                });
                        }
                    }
                ],
                loadData: {
                    Fitments: rstools.utils.loadPaginatedList('Tire/GetFitmentDescriptions', 'Tire/CountFitments', 'Fitments', filters, true),
                    ModelDescription: {
						action: 'Tire/GetModelDescriptions',
						data: rstools.tire.utils.loosenFilters('fitment', {
                            TireModel: tireModelID
                        }),
						responseKey: 'Models',
						first: true,
						required: true
					}
                },
                headerFormat: settings.header ? undefined : function (data) {
                    return "<strong>Copy Fitments into:</strong> " + data.ModelDescription.TireModelName;
                },
                idFormat: function (data) {
                    return rstools.tire.fitment.utils.getIDs(data.Fitments);
                },
                bodyFormat: function (data) {
                    var fitments = data.Fitments;
                    var multipleFitments = fitments.length > 1;
                    var s = multipleFitments ? 's' : '';

                    return [
                        $('<p/>').text('Are you sure you wish to copy the following fitment' + s + ' into ' + data.ModelDescription.TireModelName + '?'),
                        rstools.tire.fitment.utils.objectListFromFitmentDescriptions(fitments).addClass('no-margin')
                    ];
                },
                onFail: function (response) {
                   rstools.api.utils.showResponseError('There was a problem loading information for copying fitments', response); 
                },
                startHidden: false
            });
        },
        moveTo: function (filters, tireModelID, settings) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;

            settings = $.extend({
                header: undefined,
                callback: undefined
            }, settings);

            rstools.tire.utils.loosenFilters('fitment', filters);

            var modal = uifactory.modal.create({
                cancelable: true,
                header: settings.header,
                startLoading: true,
                actions: [
                    {
                        'class': 'btn btn-default',
                        'title': 'Cancel',
                        'callback': function () { modal.hide(); }
                    },
                    {
                        'class': 'btn btn-primary',
                        'title': 'Move Fitments',
                        'callback': function () {
                            modal.$modal.addClass('loading');

                            rstools.utils.apiDeferred({ action: 'Tire/MoveFitments', data: $.extend({
                                ToTireModel: tireModelID
                            }, filters)})
                                .done(function () {
                                    rstools.events.dataChanged('tire-fitment', 'move');

                                    if (typeof settings.callback === 'function') settings.callback();

                                    modal.hide();

                                    uifactory.alert.show({ 
                                        type: 'success', 
                                        text: "The tire fitments were moved."
                                    });
                                })
                                .fail(function (response) {
                                    modal.$modal.removeClass('loading');
                                    rstools.api.utils.showResponseError("There was an error moving the fitments", response);
                                });
                        }
                    }
                ],
                loadData: {
                    Fitments: rstools.utils.loadPaginatedList('Tire/GetFitmentDescriptions', 'Tire/CountFitments', 'Fitments', filters, true),
                    
                    ModelDescription: {
						action: 'Tire/GetModelDescriptions',
						data: rstools.tire.utils.loosenFilters('fitment', {
                            TireModel: tireModelID
                        }),
						responseKey: 'Models',
						first: true,
						required: true
					}
                },
                headerFormat: settings.header ? undefined : function (data) {
                    /** @type {TireModelDescriptionModel} */
                    var modelDescription = data.ModelDescription;

                    return "<strong>Move Fitments into:</strong> " + modelDescription.TireModelName;
                },
                idFormat: function (data) {
                    return rstools.tire.fitment.utils.getIDs(data.Fitments);
                },
                bodyFormat: function (data) {
                    /** @type {TireModelDescriptionModel} */
                    var model = data.ModelDescription;

                    /** @type {TireFitmentDescriptionModel[]} */
                    var fitments = data.Fitments;

                    var multipleFitments = fitments.length > 1;
                    var s = multipleFitments ? 's' : '';

                    return [
                        $('<p/>').text('Are you sure you wish to move the following fitment' + s + ' into ' + model.TireModelName + '?'),
                        rstools.tire.fitment.utils.objectListFromFitmentDescriptions(fitments).addClass('no-margin')
                    ];
                },
                onFail: function (response) {
                    modal.hide();
                    rstools.api.utils.showResponseError('There was a problem loading information for moving fitments', response);
                },
                startHidden: false
            });
        },

        duplicate: function (filters, settings) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;

            settings = $.extend({
                header: undefined,
                callback: undefined
            }, settings);

            rstools.tire.utils.loosenFilters('fitment', filters);

            var $optionSelections = uifactory.create.selectionList({
                options: [
                    {
                        label: 'Include Promotions',
                        value: 'CopyPromotions',
                        selected: true
                    },
                    {
                        label: 'Include Pricing',
                        value: 'CopyPricing',
                        selected: true
                    },
                    {
                        label: 'Link Images',
                        value: 'CopyFitmentResources',
                        selected: true
                    }
                ]
            }).addClass('collapse');

            var modal = uifactory.modal.create({
                cancelable: true,
                header: settings.header,
                startLoading: true,
                loadData: {
                    Fitments: rstools.utils.loadPaginatedList('Tire/GetFitmentDescriptions', 'Tire/CountFitments', 'Fitments', filters)
                },
                headerFormat: settings.header ? undefined : function (data) {
                    var fitments = data.Fitments;
                    var numberOfFitments = fitments.length;
                    var multipleFitments = numberOfFitments > 1;
                    var s = multipleFitments ? 's' : '';

                    return "Duplicate " + numberOfFitments + " Tire Fitment" + s;
                },
                idFormat: function (data) {
                    return rstools.tire.fitment.utils.getIDs(data.Fitments);
                },
                bodyFormat: function (data) {
                    /** @type {TireFitmentDescriptionModel[]} */
                    var fitments = data.Fitments;

                    var numberOfFitments = fitments.length;
                    var multipleFitments = numberOfFitments > 1;
                    var s = multipleFitments ? 's' : '';

                    var $icon = uifactory.create.icon('plus');

                    return [
                        $('<p/>').text('Are you sure you wish to duplicate the following fitment' + s + '?'),

                        rstools.tire.fitment.utils.objectListFromFitmentDescriptions(fitments),

                        $('<h5/>', {
                            'class': 'clickable',
                            'click': function () {
                                $optionSelections.collapse('toggle');
                                $icon.toggleClass('fa-plus').toggleClass('fa-minus');
                            }
                        }).append([$icon, ' Options']),

                        $optionSelections
                    ];
                },
                onFail: function (response) {
                    rstools.api.utils.showResponseError("There was an error loading information about the fitments to duplicate", response);
                },
                actions: [
                    {
                        'class': 'btn btn-default',
                        'title': 'Cancel',
                        'callback': function () { modal.hide(); }
                    },
                    {
                        'class': 'btn btn-primary',
                        'icon': 'clone',
                        'title': 'Duplicate',
                        'callback': function () {
                            modal.$modal.addClass('loading');

                            rstools.utils.apiDeferred({
                                action: 'Tire/DuplicateFitments',
                                data: $.extend({}, filters, $optionSelections.data('selectionList').value())
                            })
                                .done(function () {
                                    rstools.events.dataChanged('tire-fitment', 'duplicate');

                                    modal.hide();

                                    uifactory.alert.show({ 
                                        type: 'success', 
                                        text: "The tire fitments were duplicated."
                                    });
                                })
                                .fail(function (response) {
                                    modal.$modal.removeClass('loading');

                                    rstools.api.utils.showResponseError("There was an error duplicating the fitments", response);
                                });
                        }
                    }
                ],
                startHidden: false
            });
        },

        noun: 'tire fitment',
        nounPlural: 'tire fitments',
        dataNamespace: 'tire-fitment',

        updateDataStatusAction: 'Tire/ModifyFitmentDataStatus',

        archiveAction: 'Tire/ArchiveFitments',
        unarchiveAction: 'Tire/UnarchiveFitments',
        
        editAction: 'Tire/EditFitments',
        createAction: 'Tire/CreateFitment',

        getAction: 'Tire/GetFitments',
        getActionResponseKey: 'Fitments',
        countAction: 'Tire/CountFitments',

		getMetaAction: 'Tire/GetFitmentMeta',
		setMetaAction: 'Tire/SetFitmentMeta',
		clearMetaAction: 'Tire/ClearFitmentMeta',

        getPromotionStatusAction: 'Promotion/GetTireFitmentStatus',
        setPromotionStatusAction: 'Promotion/SetTireFitmentStatus'
    };
})();