rstools.datastatus = (function () {
    var assert = rstools.utils.assert;
    var DataStatus = rstools.constants.DataStatus;
    var UpdateDataStatusMode = rstools.constants.UpdateDataStatusMode;

    var namespaceToFieldMap = {};

    function getDataStatus(namespace, dataObject) {
        assert(dataObject, 'invalid data object');

        var field;

        if (namespace) field = namespaceToFieldMap[namespace];
        if (field) return dataObject[field];

        for (var key in dataObject) {
            if (dataObject.hasOwnProperty(key) && key.substr(-10) === 'DataStatus') {
                if (namespace) namespaceToFieldMap[namespace] = key;
                return dataObject[key];
            }
        }
    }

    function createStatusLabel(status) {
        var className = 'default';
        var text = status;

        if (status === DataStatus.Invalid) {
            className = 'danger';
            text = 'Invalid'; 
        } else if (status === DataStatus.Valid) {
            className = 'success';
            text = 'Valid';
        } else if (status === DataStatus.NeedsReview) {
            className = 'warning';
            text = 'Needs Review';
        } else if (status === DataStatus.PendingDelete) {
            className = 'default';
            text = 'Pending Delete';
        }
        
        var label = document.createElement('span');
            label.className = 'label label-block label-' + className;
            label.appendChild(document.createTextNode(text));

        return label;
    }
    
    return {
        createColumn: function (namespace) {
            return function (dataObject) {
                var hasFlag = function (status, flag) {
                    return (status & flag) === flag;
                };
                
                var status = getDataStatus(namespace, dataObject);

                var labels = [];

                var pendingDelete = hasFlag(status, DataStatus.PendingDelete);

                if (pendingDelete) labels.push(createStatusLabel(DataStatus.PendingDelete));

                if (hasFlag(status, DataStatus.Valid)) {
                    if (!pendingDelete) labels.push(createStatusLabel(DataStatus.Valid));
                }
                else labels.push(createStatusLabel(DataStatus.Invalid));
                
                if (hasFlag(status, DataStatus.NeedsReview)) labels.push(createStatusLabel(DataStatus.NeedsReview));

                return labels;
            };
        },
        actions: {
            markValid: function (namespaceTokens) {
                var namespace = rstools.utils.getNamespace(namespaceTokens);

                return {
                    title: 'Mark as Valid',
                    icon: 'fa fa-thumbs-up',
                    forMenu: true,
                    multiple: true,
                    single: true,
                    fields: ['actions'],
                    requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                    appliesTo: function (modelDescription) {
                        return (getDataStatus(namespaceTokens, modelDescription) & DataStatus.Valid) !== DataStatus.Valid;
                    },
                    callback: function (rows, grid) {
                        rows = $.grep(rows, this.appliesTo);

                        var ids = namespace.utils.getIDs(rows);

                        var modal = uifactory.create.confirmActionModal({
                            titles: namespace.utils.getTitles(rows),
                            noun: namespace.noun,
                            verb: 'mark',
                            verbTrail: 'as valid',
                            actionButtonClass: 'btn btn-success',
                            actionRequest: {
                                action: namespace.updateDataStatusAction,
                                data: {
                                    UpdateRequests: $.map(rows, function (row, i) {
                                        var dataStatus = getDataStatus(namespaceTokens, row);
                                        var id = ids[i];

                                        // Create a modify data status operation to add the valid flag
                                        var addValidDataStatus = {
                                            ID: id,
                                            Mode: UpdateDataStatusMode.Add,
                                            DataStatus: DataStatus.Valid
                                        };

                                        // If this is pending delete, create a data status operation to remove the pending delete flag
                                        if ((dataStatus & DataStatus.PendingDelete) === DataStatus.PendingDelete) {
                                            var removePendingDelete = {
                                                ID: id,
                                                Mode: UpdateDataStatusMode.Remove,
                                                DataStatus: DataStatus.PendingDelete
                                            };

                                            return [
                                                removePendingDelete,
                                                addValidDataStatus
                                            ];
                                        }

                                        return addValidDataStatus;
                                    })
                                }
                            },
                            onActionCompleted: function () {
                                grid.refresh();
                            }
                        });

                        modal && modal.show();
                    }
                };
            },
            markInvalid: function (namespaceTokens) {
                var namespace = rstools.utils.getNamespace(namespaceTokens);

                return {
                    title: 'Mark as Invalid',
                    icon: 'fa fa-thumbs-down',
                    forMenu: true,
                    multiple: true,
                    single: true,
                    fields: ['actions'],
                    requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                    appliesTo: function (modelDescription) {
                        return (getDataStatus(namespaceTokens, modelDescription) & DataStatus.Valid) === DataStatus.Valid;
                    },
                    callback: function (rows, grid) {
                        var modal = uifactory.create.confirmActionModal({
                            titles: namespace.utils.getTitles(rows, this.appliesTo),
                            noun: namespace.noun,
                            verb: 'mark',
                            verbTrail: 'as invalid',
                            actionButtonClass: 'btn btn-success',
                            actionRequest: {
                                action: namespace.updateDataStatusAction,
                                data: {
                                    UpdateRequests: $.map(namespace.utils.getIDs(rows, this.appliesTo), function (id) {
                                        return {
                                            ID: id,
                                            Mode: UpdateDataStatusMode.Remove,
                                            DataStatus: DataStatus.Valid
                                        };
                                    })
                                }
                            },
                            onActionCompleted: function () {
                                grid.refresh();
                            }
                        });

                        modal && modal.show();
                    }
                };
            },
            markNeedsReviewed: function (namespaceTokens) {
                var namespace = rstools.utils.getNamespace(namespaceTokens);

                return {
                    title: 'Mark as Needs Review',
                    icon: 'fa fa-comment',
                    forMenu: true,
                    multiple: true,
                    single: true,
                    fields: ['actions'],
                    requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                    appliesTo: function (modelDescription) {
                        return (getDataStatus(namespaceTokens, modelDescription) & DataStatus.NeedsReview) !== DataStatus.NeedsReview;
                    },
                    callback: function (rows, grid) {
                        var modal = uifactory.create.confirmActionModal({
                            titles: namespace.utils.getTitles(rows, this.appliesTo),
                            noun: namespace.noun,
                            verb: 'mark',
                            verbTrail: 'as "Needs Reviewed"',
                            actionButtonClass: 'btn btn-success',
                            actionRequest: {
                                action: namespace.updateDataStatusAction,
                                data: {
                                    UpdateRequests: $.map(namespace.utils.getIDs(rows, this.appliesTo), function (id) {
                                        return {
                                            ID: id,
                                            Mode: UpdateDataStatusMode.Add,
                                            DataStatus: DataStatus.NeedsReview
                                        };
                                    })
                                }
                            },
                            onActionCompleted: function () {
                                grid.refresh();
                            }
                        });

                        modal && modal.show();
                    }
                };
            },
            markReviewed: function (namespaceTokens) {
                var namespace = rstools.utils.getNamespace(namespaceTokens);

                return {
                    title: 'Mark as Reviewed',
                    icon: 'fa fa-comment-o',
                    forMenu: true,
                    multiple: true,
                    single: true,
                    fields: ['actions'],
                    requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                    appliesTo: function (modelDescription) {
                        return (getDataStatus(namespaceTokens, modelDescription) & DataStatus.NeedsReview) === DataStatus.NeedsReview;
                    },
                    callback: function (rows, grid) {
                        var modal = uifactory.create.confirmActionModal({
                            titles: namespace.utils.getTitles(rows, this.appliesTo),
                            noun: namespace.noun,
                            verb: 'mark',
                            verbTrail: 'as reviewed',
                            actionButtonClass: 'btn btn-success',
                            actionRequest: {
                                action: namespace.updateDataStatusAction,
                                data: {
                                    UpdateRequests: $.map(namespace.utils.getIDs(rows, this.appliesTo), function (id) {
                                        return {
                                            ID: id,
                                            Mode: UpdateDataStatusMode.Remove,
                                            DataStatus: DataStatus.NeedsReview
                                        };
                                    })
                                }
                            },
                            onActionCompleted: function () {
                                grid.refresh();
                            }
                        });

                        modal && modal.show();
                    }
                };
            },
        }
    };
})();