(function () {
    var loadPaginatedList = rstools.utils.loadPaginatedList;
    var findLikeData = rstools.utils.findLikeData;
    var loosenFilters = rstools.tire.utils.loosenFilters;

    /** @type {TireFitmentResourceType} */
    var TireFitmentResourceType = rstools.constants.TireFitmentResourceType;

    var Provider = {
        createModelForm: function (type, settings) {
            var url;

            settings = $.extend({
                isMultiple: false,
                data: {},
                filters: {},
                submitSuccessCallback: undefined,
                onCancel: undefined
            }, settings);

            var data = settings.data;
            var s = settings.isMultiple ? 's' : '';

            switch (type) {
                case "edit":
                    url = 'Tire/EditModels';
                    break;
                case "create":
                    url = 'Tire/CreateModel';
                    break;
            }

            if (typeof url === 'undefined')
                throw new Error("Could not detect which URL to post model data to");

            var fields = $.map([
                {
                    label: 'Name',
                    name: 'TireModelName',
                    maxLength: 128,
                    helpText: 'The name of the tire model' + s,
                    value: data.TireModelName,
                    required: !settings.isMultiple,
                    hasMultipleValues: 'TireModelName' in data === false
                },
                {
                    label: 'Brand',
                    name: 'TireModel_TireBrandID',
                    searchInputName: 'TireBrandName',

                    type: 'search',
                    required: !settings.isMultiple,

                    url: 'Tire/GetBrandDescriptions',
                    responseKey: 'Brands',
                    idColumnName: 'TireBrandID',
                    requestData: {
                        SelectBrandsOnly: false,
                        UsePromotionOrder: false,
                        HasFitments: false
                    },
                    resultMap: function (tireBrand) {
                        return {
                            id: tireBrand.TireBrandID,
                            name: tireBrand.TireBrandName + ' (' + tireBrand.DataSourceCode + ')'
                        };
                    },
                    applySearch: function (search, request) {
                        request.Search = '[TireBrandName[Like]%' + search + '%]';
                    },
                    value: data.TireBrandID ? 
                        data.TireBrandName ? 
                            {
                                id: data.TireBrandID,
                                name: data.TireBrandName + ' (' + data.DataSourceCode + ')'
                            } : {
                                id: data.TireBrandID
                            } :
                        undefined,
                    hasMultipleValues: 'TireBrandID' in data === false
                },
                {
                    label: 'Attributes',
                    name: 'TireModelAttributes',
                    type: 'suggest',
                    helpText: 'Attach attributes to this tire model',
                    multiple: true,
                    magicSuggest: {
                        data: rstools.tire.settings.attributes,
                        maxSelection: null,
                        value: data.Attributes
                    },
                    required: false,
                    hasMultipleValues: 'Attributes' in data === false
                }
            ], function (field) {
                // Modify fields dynamically here
                if (type === "create") field.hasMultipleValues = false;

                return field;
            });

            var actions = [
                {
                    label: type === 'create' ? 'Create' : 'Save',
                    icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
                    buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
                    action: 'submit'
                },
                {
                    label: 'Cancel',
                    action: settings.onCancel
                }
            ];

            return uifactory.create.form({
                url: url,
                submitErrorMessage: 'There was a problem ' + (type === 'create' ? 'creat' : 'edit') + 'ing the tire model' + s + ': {Message}.',

                submitSuccessCallback: function () {
                    uifactory.alert.show({
                        text: "The tire model" + s + " has been " + (type === 'create' ? 'creat' : 'edit') + "ed."
                    });

                    if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback();
                },

                fields: fields,
                baseData: settings.filters,

                actions: actions
            });
        }
    };

    rstools.tire.model = {
        utils: {
            isUnarchived: function (/** @type {TireModelDataObject} */ model) {
                return !model.TireModelArchived;
            },
            isArchived: function (/** @type {TireModelDataObject} */ model) {
                return model.TireModelArchived;
            },
            getTitles: function (rows, filter) {
                return $.map(rows, function (row) {
                    if (filter && !filter(row)) return undefined;

                    var title = row.TireModelName;

                    if (row.Sidewalls && row.Sidewalls.length) {
                        title += " (<em>" + $.map(row.Sidewalls, function (sidewall) {
                            return sidewall.TireSidewallName;
                        }).join(', ') + "</em>)";
                    }

                    return title;
                });
            },

            getIDs: function (rows, filter) {
                return $.map(rows, function (row) {
                    if (filter && !filter(row)) return undefined;
                    return row.TireModelID;
                });
            },
            
            createFilters: function (models, loosen) {
                var filters = {
                    TireModels: rstools.tire.model.utils.getIDs(models)
                };

                if (loosen) rstools.tire.utils.loosenFilters('model', filters);

                return filters;
            }
        },

        edit: function (tireFilters) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;

            loosenFilters('model', tireFilters);

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: $('<h4/>'),
                startHidden: false,
                loadData: {
                    ModelDescriptions: loadPaginatedList('Tire/GetModelDescriptions', 'Tire/CountModels', 'Models', tireFilters)
                },
                headerFormat: function (data) {
                    /** @type {TireModelDescriptionModel[]} */
                    var modelDescriptions = data.ModelDescriptions;
                    var isMultiple = modelDescriptions.length > 1;

                    return isMultiple ? "Edit " + modelDescriptions.length + " Tire Models" : "<strong>Edit Model:</strong> " + modelDescriptions[0].TireModelName;
                },
                bodyFormat: function (data) {
                    /** @type {TireModelDescriptionModel[]} */
                    var modelDescriptions = data.ModelDescriptions;
                    var isMultiple = modelDescriptions.length > 1;

                    return Provider.createModelForm('edit', {
                        isMultiple: isMultiple,
                        data: isMultiple ? findLikeData(modelDescriptions) : modelDescriptions[0],
                        filters: tireFilters,
                        submitSuccessCallback: function () {
                            rstools.events.dataChanged('tire-model', 'edit');
                            modal.hide();
                        },
                        onCancel: function () {
                            modal.hide();
                        },
                        onFail: function (response) {
                            rstools.api.utils.showResponseError('There was an error loading information about the tire model(s)', response);
                        }
                    });
                }
            });
        },
        duplicate: function (filters, settings) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;

            var modelDescriptions = [];

            settings = $.extend({
                header: undefined
            }, settings);

            loosenFilters('model', filters);

            var modelDescriptionsLoaded;

            if ($.isArray(filters)) {
                // If filters is an array assume we were passed in a list of tire model descriptions

                modelDescriptions = filters;
                filters = rstools.tires.model.utils.createFilters(modelDescriptions);
                modelDescriptionsLoaded = $.Deferred().resolve();
            } else {
                // Otherwise load the models from the API

                modelDescriptionsLoaded = loadPaginatedList('Tire/GetModelDescriptions', 'Tire/CountModels', 'Models', filters).done(function(models) {
                    modelDescriptions = models;
                });
            }

            // Create a modal
            var modal = uifactory.modal.create({
                cancelable: true,
                header: settings.header ? settings.header : $('<h4/>'),
                startLoading: true
            });

            // If we don't already have a header specified, wait for the model descriptions to load
            // and use them to generate a header
            if (!settings.header) modelDescriptionsLoaded.done(function () {
                modal.settings.header.html(
                    modelDescriptions.length > 1 ? 
                        "Duplicate " + modelDescriptions.length + " Tire Models" : 
                        "<strong>Duplicate Tire Model:</strong> " + modelDescriptions[0].TireModelName);
            });

            // Create our options selections list
            var $optionSelections = uifactory.create.selectionList({
                options: [
                    {
                        label: 'Include Fitments',
                        value: 'CopyFitments',
                        selected: true
                    },
                    {
                        label: 'Include Attributes',
                        value: 'CopyAttributes',
                        selected: true
                    },
                    {
                        label: 'Include Pricing',
                        value: 'CopyPricing',
                        selected: true
                    },
                    {
                        label: 'Include Promotions',
                        value: 'CopyPromotions',
                        selected: true
                    },
                    {
                        label: 'Link Images',
                        value: 'CopyFitmentResources',
                        selected: true
                    }
                ]
            }).addClass('collapse');

            // Wait for model description to be loaded
            $.when(modelDescriptionsLoaded)
                .done(function () {
                    modal.setDisplayID(rstools.tire.model.utils.getIDs(modelDescriptions));

                    modal.$body.append($('<p/>').text('Are you sure you wish to duplicate the following model' + (modelDescriptions.length > 1 ? 's?' : '?')));

                    modal.$body.append(uifactory.create.objectList({
                        objects: modelDescriptions,

                        image: function (/** @type {TireModelDescriptionModel} */model) {
                            var type = false;

                            if (model.HasCatalogImage) type = TireFitmentResourceType.Catalog;
                            else if (model.HasTreadImage) type = TireFitmentResourceType.Tread;
                            else if (model.HasSideImage) type = TireFitmentResourceType.Side;

                            if (!type) return false;

                            return ridestyler.ajax.url('Tire/Image', {
                                Width: 50,
                                Height: 50,
                                PositionX: 1,
                                PositionY: 1,
                                TireModel: model.TireModelID,
                                TireFitmentResourceType: type
                            });
                        },

                        headers: [
                            'Image',
                            'Brand',
                            'Model'
                        ],

                        columns: [
                            function (/** @type {TireModelDescriptionModel} */ model) {
                                var small = document.createElement('small');
                                    small.appendChild(document.createTextNode('(' + model.DataSourceCode + ')'));

                                return [model.TireBrandName, ' ', small];
                            },
                            'TireModelName'
                        ]
                    }));

                    var $icon = uifactory.create.icon('plus');

                    modal.$body.append([
                        $('<h5/>', {
                            'class': 'clickable',
                            'click': function () {
                                $optionSelections.collapse('toggle');
                                $icon.toggleClass('fa-plus').toggleClass('fa-minus');
                            }
                        }).append([$icon, ' Options']),
                        $optionSelections
                    ]);

                    modal.$footer.append([
                        $('<button/>', {
                            'class': 'btn btn-default',
                            'append': [
                                'Cancel'
                            ],
                            'click': function () {
                                modal.hide();
                            }
                        }),
                        $('<button/>', {
                            'class': 'btn btn-primary',
                            'append': [
                                uifactory.create.icon('clone'),
                                ' Duplicate'
                            ],
                            'click': function () {
                                modal.$modal.addClass('loading');

                                rstools.utils.apiDeferred({
                                    action: 'Tire/DuplicateModels',
                                    data: $.extend({}, filters, $optionSelections.data('selectionList').value())
                                })
                                    .done(function () {
                                        rstools.events.dataChanged('tire-model', 'duplicate');

                                        modal.hide();

                                        uifactory.alert.show({ 
                                            type: 'success', 
                                            text: "The tire models were duplicated."
                                        });
                                    })
                                    .fail(function () {
                                        modal.$modal.removeClass('loading');
                                        uifactory.alert.show({ 
                                            type: 'error', 
                                            text: "There was an error duplicating the models."
                                        });
                                    });
                            }
                        })
                    ]);

                    modal.$modal.removeClass('loading');
                })
                .fail(function (response) {
                    modal.hide();
                    rstools.api.utils.showResponseError("There was an error loading information about the models to duplicate", response);
                });

            modal.show();
        },
        merge: function (filters, settings) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;


            settings = $.extend({}, settings);

            var modal = uifactory.modal.create({
                cancelable: true,
                header: settings.header || "Merge Tire Models",
                startLoading: true
            });

            loosenFilters('model', filters);

            loadPaginatedList('Tire/GetModelDescriptions', 'Tire/CountModels', 'Models', filters)
                .done(function (modelDescriptions) {
                    var selectedModel, otherModels;

                    modal.setDisplayID($.map(modelDescriptions, function (/** @type {TireModelDescriptionModel} */ model) {
                        return model.TireModelID;
                    }));

                    modal.$body.append($('<p/>').text('Are you sure you want to merge the following models into one model? Please select the target model to merge into:'));

                    var $mergeButton = $('<button/>', {
                        'class': 'btn btn-primary',
                        'append': [
                            uifactory.create.icon('code-fork'),
                            ' Merge'
                        ],
                        'disabled': true,
                        'click': function () {
                            modal.$modal.addClass('loading');

                            rstools.utils.apiDeferred({
                                action: 'Tire/MergeModels',
                                data: {
                                    ToTireModel: selectedModel.TireModelID,
                                    TireModels: $.map(otherModels, function (m) {
                                        return m.TireModelID;
                                    })
                                }
                            })
                                .done(function () {
                                    rstools.events.dataChanged('tire-model', 'merge');

                                    modal.hide();

                                    uifactory.alert.show({ 
                                        type: 'success', 
                                        text: "The tire models were merged into " + selectedModel.TireModelName + ". Old models were archived."
                                    });
                                })
                                .fail(function (response) {
                                    modal.$modal.removeClass('loading');
                                    rstools.api.utils.showResponseError("There was an error merging the models", response);
                                });
                        }
                    });

                    var objectListSettings = {
                        image: function (model) {
                            var type = false;

                            if (model.HasCatalogImage) type = TireFitmentResourceType.Catalog;
                            else if (model.HasTreadImage) type = TireFitmentResourceType.Tread;
                            else if (model.HasSideImage) type = TireFitmentResourceType.Side;

                            if (!type) return false;

                            return ridestyler.ajax.url('Tire/Image', {
                                Width: 50,
                                Height: 50,
                                PositionX: 1,
                                PositionY: 1,
                                TireModel: model.TireModelID,
                                TireFitmentResourceType: type
                            });
                        },

                        headers: [
                            'Image',
                            'Brand',
                            'Model',
                            ''
                        ],

                        columns: [
                            function (/** @type {TireModelDescriptionModel} */ model) {
                                var small = document.createElement('small');
                                    small.appendChild(document.createTextNode('(' + model.DataSourceCode + ')'));

                                return [model.TireBrandName, ' ', small];
                            },
                            function (model) {
                                var a = document.createElement('a');
                                    a.appendChild(document.createTextNode(model.TireModelName));
                                    a.href = '/#!/app/tire/model?' + rter.utils.toParamString({
                                        filters: {
                                            TireModels: [model.TireModelID]
                                        }
                                    });
                                    a.target = '_blank';
                                    a.className = 'primary-link';

                                return a;
                            },

                            function (model) {
                                return $('<button/>', {
                                    'class': 'btn btn-default',
                                    'text': 'Select',
                                    'title': 'Select this model as the target model.',
                                    'data-action': 'select-model',
                                    'click': onSelectModel
                                }).data('model', model);
                            }
                        ]
                    };

                    var onSelectModel = function () {
                        var $this = $(this);

                        selectedModel = $this.data('model');

                        otherModels = $.map(modelDescriptions, function (m) {
                            return m !== selectedModel ? m : undefined;
                        });

                        var $oldObjectList = $objectList;
                        
                        $objectList = uifactory.create.objectList($.extend({
                            objects: otherModels
                        }, objectListSettings));

                        $oldObjectList.replaceWith($objectList);

                        $objectList.find('[data-action=select-model]').removeClass('btn-primary').addClass('btn-default');
                        $this.addClass('btn-primary').removeClass('btn-default');
                        $mergeButton.prop('disabled', false);
                        $actionDisplay.html([
                            'The above models will be merged into ',
                            $('<a/>', {
                                text: selectedModel.TireModelName,
                                href: '/#!/app/tire/model?' + rter.utils.toParamString({
                                    filters: rstools.tire.model.utils.createFilters([selectedModel])
                                }),
                                target: '_blank',
                                'class': 'primary-link'
                            }),
                            '.'
                        ]);
                    };

                    var $actionDisplay = $('<p/>');

                    var $objectList = uifactory.create.objectList($.extend({
                        objects: modelDescriptions
                    }, objectListSettings));

                    modal.$body.append($objectList, $actionDisplay);

                    modal.$footer.append([
                        $('<button/>', {
                            'class': 'btn btn-default',
                            'append': [
                                'Cancel'
                            ],
                            'click': function () {
                                modal.hide();
                            }
                        }),
                        $mergeButton
                    ]);

                    modal.$modal.removeClass('loading');
                })
                .fail(function (response) {
                    modal.hide();

                    rstools.api.utils.showResponseError("There was an error loading information about the models to duplicate", response);
                });

            modal.show();
        },

        
        create: function (tireData) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: 'Create a Tire Model',
                body: Provider.createModelForm('create', {
                    data: tireData,
                    submitSuccessCallback: function () {
                        rstools.events.dataChanged('tire-model', 'create');
                        modal.hide();
                    },
                    onCancel: function () {
                        modal.hide();
                    }
                })
            });

            modal.show();
        },

        noun: 'tire model',
        nounPlural: 'tire models',
        dataNamespace: 'tire-model',

        getAction: 'Tire/GetModels',
        getActionResponseKey: 'Models',
        countAction: 'Tire/CountModels',

        archiveAction: 'Tire/ArchiveModels',
        unarchiveAction: 'Tire/UnarchiveModels',

        updateDataStatusAction: 'Tire/ModifyModelDataStatus',
        
		getMetaAction: 'Tire/GetModelMeta',
		setMetaAction: 'Tire/SetModelMeta',
		clearMetaAction: 'Tire/ClearModelMeta',

        getPromotionStatusAction: 'Promotion/GetTireFitmentStatus',
        setPromotionStatusAction: 'Promotion/SetTireFitmentStatus'
    };
})();