(function ($) {
    window.rstools = function () {
        return {
            storage: function () {
                var storage = window.localStorage;

                // Override storage if not supported
                if (!Modernizr.localstorage || !Modernizr.json) {
                    storage = {};

                    return {
                        get: function (k) { return storage[k]; },
                        set: function (k, v) { return (storage[k] = v); }
                    };
                }

                return {
                    get: function(key) {
                        var v = storage.getItem(key);

                        // Auto-parse value
                        if (typeof v === "string") {
                            try {
                                v = $.parseJSON(v);
                            } catch (e) {
                                v = undefined;
                            }
                        } else {
                            v = undefined;
                        }

                        return v;
                    },
                    set: function (key, value) {
                        storage.setItem(key, JSON.stringify(value));
                    }
                };
            }(),

            events: $({}),

            state: function () {
                return {
                    get: function (key) {
                        var v = null;
                        var urlData = rter.url.get();
                        var cache = urlData.state;
                        if (typeof cache !== 'undefined') {
                            v = cache[encodeURIComponent(key)];
                        }

                        // Auto-parse our numbers to prevent conversion issues later
                        if (typeof v === 'string' && isNaN(v) === false)
                            v = parseFloat(v);

                        return v;
                    },
                    set: function (key, value, replace) {
                        var urlData = rter.url.get();
                        var cache = urlData.state || {};

                        if (typeof key === 'string')
                            cache[encodeURIComponent(key)] = value;
                        else if (typeof key === 'object') {
                            for (var k in key) {
                                if (key.hasOwnProperty(k)) {
                                    cache[encodeURIComponent(k)] = key[k];
                                }
                            }

                            replace = value;
                        }

                        rter.url.set(urlData.path, cache, replace);
                    },
                    unset: function (key, replace) {
                        var urlData = rter.url.get();
                        var cache = urlData.state || {};

                        if ($.isArray(key)) {
                            for (var i = key.length - 1; i >= 0; i--) {
                                delete cache[encodeURIComponent(key[i])];
                            }
                        } else delete cache[encodeURIComponent(key)];

                        rter.url.set(urlData.path, cache, replace);
                    },
                    update: function (values) {
                        var urlData = rter.url.get();
                        var cache = urlData.state || {};

                        if (values) {
                            for (var k in values) {
                                if (values.hasOwnProperty(k)) {
                                    if (typeof values[k] === 'undefined') delete cache[encodeURIComponent(k)];
                                    else cache[encodeURIComponent(k)] = values[k];
                                }
                            }
                        }

                        rter.url.set(urlData.path, cache);
                    }
                };
            }(),

            roles: {
                SYSTEM_ADMIN:       "System Admin",
                ORGANIZATION_ADMIN: "Organization Admin",
                WHEELS:             "Manage Wheels",
                TIRES:              "Manage Tires",
                VEHICLES:           "Manage Vehicles"
            },

            template: function () {
                return {
                    render: function (path, model, options) {
                        options = $.extend({
                            autoWrap: true,

                            // Allow turning off deferred logic if you know for sure that the
                            // template exists on the page before calling render (if you called
                            // template.get beforehand, for example).
                            async: true
                        }, options);

                        var template, src, renderedTemplate;
                        var d = rstools.template.get(path, options).done(function (templateSrc) {
                            template = Handlebars.compile(templateSrc);
                            src = template(model);
                            renderedTemplate = src;

                            if (options.autoWrap) renderedTemplate = $(src);
                        }).then(function () {
                            return renderedTemplate;
                        });

                        return options.async ? d : renderedTemplate;
                    },
                    get: function (path, options) {
                        options = $.extend({
                            templatePath: '/templates/',
                            useRemoteTemplates: true,
                            async: true
                        }, options);

                        // Parse our template ID selector and use it to search on page for template
                        var $tmpl = $('#' + path.replace(/\//g, '\\/'));

                        // If we couldn't find a template, and we can use remote templates, let's try that!
                        if ($tmpl.length === 0 && options.useRemoteTemplates) {
                            var tpath = options.templatePath + path + '.html';

                            return $.ajax({
                                dataType: 'html',
                                url: tpath,
                                async: options.async,
                                cache: false
                            }).then(function (src) {
                                $tmpl = $('<script type="text/x-handlebars-template" id="' + path + '">' + src + '</script>');
                                $('body').append($tmpl);

                                return src;
                            });
                        }

                        return new $.Deferred().resolve($tmpl.html());
                    }
                };
            }(),

            log: function () {
                if (rstools.auth.user.hasRole(rstools.roles.SYSTEM_ADMIN) && window.console && window.console.log) {
                    console.log.apply(console, arguments);
                }
            },

            api: {
                utils: {
                    showResponseError: function (message, response) {
                        uifactory.alert.show({
                            type: 'error',
                            text: response.Message ? message + ': ' + response.Message : message
                        });
                    }
                }
            }
        };
    }();

    var $window = $(window);

    window.datagrid = {
        settings: {
            hasRole: function() { return rstools.auth.user.hasRole.apply(rstools.auth.user, arguments); },
            events: {
                pageLoaded: function() { $window.trigger('resize.unveil'); }
            }
        }
    };
})(jQuery);