
(function ($) {
    rter.mapController('app.dashboard', function (context) {
        return {
            viewWillLoad: function (context) {
            },
            viewWillCreate: function ($view) {
            },
            viewCreated: function ($view) {
            },
            viewWillResume: function ($view) {
            },
            viewResumed: function ($view) {
            },
            viewLoaded: function (context) {
            },
            viewWillUnload: function ($view) {
            },
            viewWillDetatch: function ($view) {
            },
            viewDetatched: function ($view) {
            },
            viewWillRemove: function ($view) {
            },
            viewRemoved: function ($view) {
            },
            viewUnloaded: function ($view) {
            }
        };
    });
})(jQuery);