(function ($) {
    var dataShardOrg;

    window.rstools.datashard = {
        initSwitcher: function ($targetView) {
            var user = rstools.auth.user.get(),
                dataShard = user.DataShard || user.User.DataShard;

            if (!user || !dataShard) return;

            var $dataShardSwitcher = $targetView.find('#data-shard-switcher').on('click', function () {
                if (dataShardOrg) {
                    rstools.datashard.showModal();
                }
            }).prop('disabled', true);

            var $dataShardSwitcherText = $dataShardSwitcher.find('small');

            ridestyler.ajax.send({
                action: 'Organization/GetOrganizations',
                data: {
                    OrganizationID: dataShard.DataShard_OrganizationID
                },
                callback: function (response) {
                    if (response.Success && response.Organizations.length) {
                        dataShardOrg = response.Organizations[0];

                        $dataShardSwitcher.prop('disabled', false);
                        $dataShardSwitcherText.text(dataShardOrg.OrganizationName);

                        rstools.auth.user.update('ActiveOrganization', dataShardOrg, true);
                    } else if ('Organization' in user) {
                        var $staticOrg = $('<span/>', {id: 'data-shard-switcher'});

                        $staticOrg.append(uifactory.create.icon('building').css('margin', '0 5px 0 15px'), $dataShardSwitcherText.text(user.Organization.OrganizationName));

                        $dataShardSwitcher.parent()
                            .after($staticOrg)
                            .end().remove();
                    } else {
                        $dataShardSwitcherText.text('-');
                    }
                }
            });
        },

        showModal: function () {
            var user = rstools.auth.user.get(),
                dataShard = user.DataShard;

            var rsModal = uifactory.modal.create({
                'cancelable': true,
                'header': 'Choose a Different Organization'
            });

            rsModal.$body.append(
                $('<p/>').text('Choose the organization that you wish to manage. Your current organization is: ')
                    .append($('<strong/>').text(dataShardOrg.OrganizationName))
                    .append($('<span/>').text(" (Data Shard: " + dataShard.DataShardDescription + ")."))
            );

            var dataShardRequestData = {};

            var $changeOrgForm = uifactory.create.form({
                formClass: 'form-horizontal',

                url: 'DataShard/ActivateShard',
                submitErrorMessage: 'There was a problem setting your active organization and data source.',

                submitSuccessCallback: function () {
                    uifactory.alert.show({ text: "Your active organization and data source have been set." });
                    window.location.reload();
                },

                labelColumns: 3,
                fieldColumns: 9,

                fields: [
                    {
                        label: 'Organization',
                        name: 'DataShard_OrganizationID',
                        type: 'search',

                        url: 'Organization/GetOrganizations',
                        requestData: {
                            OrganizationID: rstools.auth.user.getOrganizationID(),
                            IncludeNetwork: true
                        },
                        responseKey: 'Organizations',
                        resultMap: function (organization) {
                            return {
                                id: organization.OrganizationID,
                                name: organization.OrganizationName
                            };
                        },
                        applySearch: function (search, request) {
                            request.Search = '[OrganizationName[Like]%' + search + '%]';
                        },
                        valueSelectedCallback: function (organizationID) {
                            $changeOrgForm.addClass('loading');
                            $saveButton.prop('disabled', true);

                            ridestyler.ajax.send({
                                action: 'DataShard/GetDataShards',
                                data: {
                                    Organization: organizationID
                                },
                                callback: function (response) {
                                    if (response.Success) {
                                        var shards = $.map(response.Shards, function (dataShard) {
                                            return {
                                                label: dataShard.DataShardDescription,
                                                value: dataShard.DataShardID
                                            };
                                        });

                                        $selectDataShardDropdown.trigger('rs-dropdown-loaded', [shards]);

                                        if (shards.length > 1)
                                            $selectDataShardGroup.show();

                                        $saveButton.prop('disabled', false);
                                    } else {
                                        uifactory.alert.show({ type: 'error', text: 'There was a problem getting the data shards for this organization.' });
                                    }

                                    $changeOrgForm.removeClass('loading');
                                }
                            });
                        },

                        valueClearedCallback: function () {
                            $saveButton.prop('disabled', true);
                        }
                    },
                    {
                        label: 'Data Shard',
                        type: 'container',
                        id: 'select-data-shard-container'
                    },
                    {
                        name: 'DataShardDefault',
                        value: 'true',
                        type: 'hidden'
                    }
                ]
            }).appendTo(rsModal.$body);

            var $selectDataShardContainer = $changeOrgForm.find('#select-data-shard-container'),
                $selectDataShardGroup = $selectDataShardContainer.closest('.form-group'),
                $selectDataShardDropdown = uifactory.create.dropdown().attr('data-name', 'DataShardID');

            $selectDataShardContainer.empty().append($selectDataShardDropdown);
            $selectDataShardGroup.hide();

            $('<button/>', { 'class': 'btn btn-default', 'type': 'button', 'data-dismiss': 'modal' })
                .html("Cancel")
                .appendTo(rsModal.$footer);

            var $saveButton = $('<button/>', { 'class': 'btn btn-success', 'type': 'button', 'disabled': true })
                .text("Save")
                .on('click', function () {
                    var rsDropdown = $selectDataShardDropdown.data('rsDropdown');

                    if (rsDropdown) {
                        var selectedOption = rsDropdown.selectedOption;

                        if (selectedOption) {
                            var dataShardId = selectedOption.value,
                                data = {};

                            $changeOrgForm.addClass('loading');
                            $saveButton.prop('disabled', true);

                            $changeOrgForm.syncFormToObject(data);

                            ridestyler.ajax.send({
                                action: 'DataShard/ActivateShard',
                                data: data,
                                callback: function (response) {
                                    if (response.Success) {
                                        uifactory.alert.show({ text: 'Your organization and data shard have been switched. Please wait while we refresh your interface.' });
                                        window.location.reload();
                                    } else {
                                        uifactory.alert.show({ type: 'error', text: 'There was a problem setting your organization.' });
                                        $saveButton.prop('disabled', false);
                                    }

                                    $changeOrgForm.removeClass('loading');
                                }
                            });
                        }
                    }
                })
                .appendTo(rsModal.$footer);


            rsModal.show();
        },

        columns: {
            name: function ($tds, data, idKey) {
                $tds.addClass('light loading loading-small');

                ridestyler.ajax.send({
                    action: 'DataShard/GetDataShards',
                    data: {
                        DataShards: $.map(data, function (d) {
                            return d[idKey];
                        }).unique()
                    },
                    callback: function (response) {
                        if (response.Success) {
                            var shardNameMap = {};

                            for (var i = response.Shards.length - 1; i >= 0; i--) {
                                var d = response.Shards[i];

                                shardNameMap[d.DataShardID] = d.DataShardDescription;
                            }

                            for (i = data.length - 1; i >= 0; i--)
                                $tds.eq(i).text(shardNameMap[data[i][idKey]]);
                        } else {
                            $tds.text('Error');
                            console.error("Error retrieving data shard names: ", response);
                        }

                        $tds.removeClass('loading');
                    }
                })
            }
        }
    };

})(jQuery);