(function ($) {
    rter.mapController('app.account.organization.settings', function () {
        return {
            viewWillLoad: function() {
            },

            viewWillCreate: function ($view) {
                var organization = rstools.auth.user.get("Organization");

                $('#organization-settings-container', $view).append(rstools.organization.utils.createForm('edit', {
                    filters: {
                        OrganizationID: organization.OrganizationID
                    },
                    hideParentOrganization: true,
                    submitSuccessCallback: function () {
                        rstools.events.dataChanged('organization', 'edit');
                    }
                }).addClass('loading'));
            },

            viewAttached: function ($view) {
                var $form = $view.find('.rs-form');
                var organizationID = rstools.auth.user.getOrganizationID();

                var getSettings = rstools.utils.apiDeferred({
                    action: 'Organization/GetSettings',
                    data: {
                        OrganizationID: organizationID,
                        Keys: $.map(rstools.organization.settings.settingsFields, function (settingField) {
                            return settingField.key;
                        })
                    }
                });

                var getOrganization = rstools.utils.apiDeferred({
                    action: 'Organization/GetOrganizations',
                    data: { OrganizationID: organizationID },
                });

                $.when(getSettings, getOrganization)
                    .done(function (settingsResponse, organizationResponse) {
                        var organization = organizationResponse.Organizations[0];
                        var settings = settingsResponse.Settings;

                        for (var k in settings) {
                            if (settings.hasOwnProperty(k)) {
                                settings['Settings.' + k] = rstools.utils.parse.bool(settings[k]);
                            }
                        }

                        $form.syncObjectToForm($.extend({}, organization, settings)).removeClass('loading');
                    })
                    .fail(function (response) {
                        rstools.api.utils.showResponseError(response);
                    })
            }
        };
    });
})(jQuery);