(function ($) {
    rter.mapController('app.organization.user', function (context) {
        var grid = null;
        var filters = {}, defaultFilters;

        var organizationID,
            $filterForm;

        return {
            actions: {
                filter: function () {
                    var $form = $(this).closest('form');

                    $form.syncFormToObject(filters);

                    if (!filters.Organization) delete filters.Organization;

                    grid.search(filters);
                    filtersUpdated();
                },
                create: function () {
                    rstools.user.create();
                }
            },
            viewWillCreate: function ($view) {
                $filterForm = $view.find('.filter-form');
                filters = $.extend(filters, context.state ? context.state['filters'] : {});
                defaultFilters = { Organization: organizationID };

                // If the filter form isn't visible, change the panel's header into a body
                // so we don't get the line below it
                if (!rstools.auth.user.hasRole(rstools.roles.SYSTEM_ADMIN))
                    $filterForm.siblings('.panel-heading').addClass('panel-body').removeClass('panel-heading');
                else
                    $filterForm.syncObjectToForm(filters);

                organizationID = rstools.auth.user.getActiveOrganizationID();
            },
            viewCreated: function ($view) {

                var term = rstools.state.get('search');

                grid =
                    datagrid.create({
                        container: $view.find('div.data-wrapper'),
                        fields: [
                            { name: 'UserName', caption: 'Name' },
                            { name: 'DataShard.DataShardDescription', caption: 'Active Shard', sort: false },
                            {
                                name: 'Roles',
                                format: function (r) {
                                    return $.map(r.Roles, function (role) {
                                        var $a = $('<abbr/>', {
                                            "title": role,
                                            "class": "label label-default"
                                        });

                                        switch (role) {
                                            case rstools.roles.SYSTEM_ADMIN:
                                                return $a.text("System");
                                            case rstools.roles.ORGANIZATION_ADMIN:
                                                return $a.text("Organization");
                                            case rstools.roles.WHEELS:
                                                return $a.text("Wheels");
                                            case rstools.roles.TIRES:
                                                return $a.text("Tires");
                                            case rstools.roles.VEHICLES:
                                                return $a.text("Vehicle");
                                        }
                                    });
                                },
                                sort: false,
                                width: 100
                            },
                            {
                                name: 'actions',
                                caption: '',
                                width: 100,
                                sort: false,
                                colClass: 'text-center'
                            }
                        ],
                        actions: [
                            {
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: false,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.user.edit(rows[0].UserID);
                                }                                
                            },
                            {
                                title: 'Reset Password',
                                icon: 'fa fa-refresh',
                                multiple: false,
                                fields: ['actions'],
                                forMenu: true,
                                callback: function (rows) {
                                    rstools.user.resetPassword(rows[0].UserEmail);
                                }
                            },
                            {
                                title: 'Delete',
                                icon: 'fa fa-trash',
                                menuClassName: 'dropdown-menu-item-danger',
                                className: 'btn-danger',
                                fields: ['actions'],
                                forMenu: true,
                                multiple: true,
                                callback: function (rows) {
                                    var userIDs = [],
                                        titles = [];

                                    for (var i = 0; i < rows.length; i++) {
                                        userIDs.push(rows[i].UserID);
                                        titles.push(rows[i].UserEmail);
                                    }

                                    var modal = uifactory.create.confirmActionModal({
                                        titles: titles,
                                        verb: 'delete',
                                        noun: 'user',
                                        actionRequest: {
                                            action: 'User/DeleteUsers',
                                            data: {
                                                UserIDs: userIDs
                                            }
                                        },
                                        onActionCompleted: function () {
                                            rstools.events.dataChanged('user', 'delete');
                                        },
                                        description: 'You cannot undo this action.'
                                    });

                                    modal && modal.show();
                                }
                            }
                        ],
                        getAction: 'User/GetUsers',
                        countAction: 'User/CountUsers',
                        resultListField: 'Users',
                        baseQueryData: {
                            Organization: organizationID
                        },
                        search: $.extend({}, defaultFilters, filters)
                    });



                $filterForm.on('reset', function () {
                    $filterForm.syncObjectToForm({
                        Organization: false
                    }).syncFormToObject(filters);
                    grid.search(filters);
                    filtersUpdated();
                });

                $view.find('input.search-input').val(term);
            }
        };

        function filtersUpdated() {
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);