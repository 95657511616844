(function ($) {
    rter.mapController('app', function (context) {
        return {
            actions: {
                'hide-setup': function () {
                    uifactory.confirm({
                        title: 'Hide Setup Guide Banner',
                        message: 'Are you sure you wish to hide the setup guide banner? ' +
                                 'You can always access the setup guide in the user menu, in ' +
                                 'the upper-right corner of the screen.',
                        confirmationYes: 'Hide the Banner',
                        confirmationNo: 'Cancel',
                        callback: function (confirmed) {
                            if (confirmed) {
                                rstools.setup.hideBanner();
                            }
                        }
                    });
                }
            },
            viewWillLoad: function (context) {
            },
            viewWillCreate: function ($view) {
                rstools.ui.initializeNavBar($('#primary-nav', $view));

                if (environment !== ENVIRONMENTS.Live)
                    $('.navbar-brand', $view).attr('data-environment', environment);
            },
            viewCreated: function ($view, context) {
                this.viewChainChanged(context);
            },
            viewWillResume: function ($view) {
            },
            viewResumed: function ($view) {
            },
            viewLoaded: function (context) {
            },
            viewWillUnload: function ($view) {
            },
            viewWillDetatch: function ($view) {
            },
            viewDetatched: function ($view) {
            },
            viewWillRemove: function ($view) {
            },
            viewRemoved: function ($view) {
            },
            viewUnloaded: function ($view) {
            },
            viewChainChanged: function (context) {
                rstools.ui.onAppViewChanged(context);
                rstools.setup.updateBanner();
            }
        };
    });
})(jQuery);