(function ($) {
    var FieldComparisonStatus = rstools.constants.FieldComparisonStatus;

    function notEqual(a, b) {
        a = $.trim(a);
        b = $.trim(b);

        return a.toLowerCase() !== b.toLowerCase();
    }
    function doesNotContain(a, b) {
        b = $.trim(b);
        return a.toLowerCase().indexOf(b.toLowerCase()) < 0;
    }
    function contains(a, b) {
        return !doesNotContain(a, b);
    }
    function strictComparisonStatus(a, b) {
        if (notEqual(a, b)) return FieldComparisonStatus.Invalid;
        return FieldComparisonStatus.Success;
    }
    function looseComparisonStatus(a, b, kindaEqualState) {
        if (notEqual(a, b)) {
            if (doesNotContain(a, b)) return FieldComparisonStatus.Invalid;
            return kindaEqualState || FieldComparisonStatus.Warning;
        }

        return FieldComparisonStatus.Success;
    }

    rstools.field = {};
    rstools.field.comparison = {
        compareYears: function (a, b) {
            return strictComparisonStatus(a, b);
        },

        compareMakes: function (a, b) {
            return looseComparisonStatus(a, b);
        },

        compareModels: function (a, b, bMustBeInA) {
            if (notEqual(a, b)) {
                if (doesNotContain(a, b) && (doesNotContain(b, a) || bMustBeInA)) return FieldComparisonStatus.Invalid;
                return FieldComparisonStatus.Warning;
            }
            return FieldComparisonStatus.Success;
        },

        compareTokens: function(a, b, settings) {
            var scorer = new TokenScorer(a, b);

            $.extend(scorer, settings = settings || {});

            var score = scorer.score();

            if (score.score >= 1) return FieldComparisonStatus.Success;

            if (typeof settings.adjust === "function")
                score = settings.adjust(scorer, score, a, b);
            
            if (score.score > 0) {
                if (score.matches.a.length === scorer.a.length || score.matches.b.length === scorer.b.length)
                    return FieldComparisonStatus.Warning;
            }

            return FieldComparisonStatus.Invalid;
        },

        /**
         * Returns the lowest FieldComparisonStatus in params
         * @returns {FieldComparisonStatus}
         */
        all: function () {
            var status = Infinity;

            for (var i = 0; i < arguments.length; i++) {
                var arg = arguments[i];

                if (arg <= FieldComparisonStatus.Invalid) return FieldComparisonStatus.Invalid;
                if (arg < status) status = arg;
            }

            if (status === Math.Infinity) return FieldComparisonStatus.Invalid;

            return status;
        }
    };
})(jQuery);