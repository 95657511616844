(function() {
	rstools.vehicle.model = {
		utils: {
			getIDs: function (makes) {
				return $.map(makes, function (make) {
					return make.VehicleModelID;
				});
			},

			getTitles: function (makes) {
				return $.map(makes, function (make) {
					return make.VehicleModelName;
				});
			}
		}
	};
})();