(function ($) {
    rter.mapController('app.organization.subscription', function (context) {
        var filters;

        var $bar,
            $name,
            $status,
            $form,
            $cancel,
            $userCount, $sessionCount;

        return {
            actions: {
                save: function () {
                    showLoading();

                    ridestyler.ajax.send({
                        action: "Subscription/Set",
                        data: {
                            OrganizationID: filters.OrganizationID,
                            UserCount: $userCount.val(),
                            SessionCount: $sessionCount.val()
                        },
                        callback: function (response) {
                            if (response.Success) {
                                uifactory.alert.show({
                                    text: "Organization subscription has been updated."
                                });
                            } else {
                                console.error(response);

                                uifactory.alert.show({
                                    text: "There was an error updating the subscription.",
                                    type: 'error'
                                });
                            }

                            refreshView();
                        }
                    });
                },

                cancel: function () {
                    showLoading();

                    ridestyler.ajax.send({
                        action: "Subscription/Set",
                        data: {
                            OrganizationID: filters.OrganizationID,
                            Cancel: true
                        },
                        callback: function (response) {
                            if (response.Success) {
                                uifactory.alert.show({
                                    text: "Organization subscription cancelled."
                                });
                            } else {
                                console.error(response);

                                uifactory.alert.show({
                                    text: "There was an error canceling the subscription.",
                                    type: 'error'
                                });
                            }

                            refreshView();
                        }
                    });
                }
            },
            viewWillCreate: function ($view) {
                filters = $.extend(filters, context.state ? context.state['filters'] : {});

                $bar = $('.bar', $view);
                $name = $('#organization-name', $view);
                $status = $('#subscription-status', $view);
                $userCount = $('#user-count', $view);
                $sessionCount = $('#session-count', $view);
                $form = $('form', $view);
                $cancel = $('#cancel-subscription', $view);

                filters.OrganizationID && ridestyler.ajax.send({
                    action: "Organization/GetOrganizations",
                    data: {
                        OrganizationID: filters.OrganizationID
                    },
                    callback: function (response) {
                        if (response.Success && response.Organizations.length) {
                            var org = response.Organizations[0];

                            $form.removeClass('hide');
                            $cancel.prop('disabled', !org.Subscription);

                            if (!org.Subscription) {
                                $userCount.val(0);
                                $sessionCount.val(0);

                                $status.removeClass('label-success').addClass('label-danger')
                                    .find('span').text('Inactive').end()
                                    .find('i').attr('class', 'fa fa-times');
                            } else {
                                $userCount.val(org.Subscription.OrganizationSubscriptionUserCount);
                                $sessionCount.val(org.Subscription.OrganizationSubscriptionSessionCount);
                            }

                            $name.text(org.OrganizationName).removeClass('hide');
                            $bar.removeClass('loading').find('.hide').removeClass('hide');
                        }
                    }
                });
            }
        };

        function showLoading() {
            $bar.addClass('loading').children().addClass('hide');
            $form.addClass('hide');
        }

        function refreshView() {
            var url = rter.url.get();
            rter.routes.go(url.path, url.state);
        }
    });
})(jQuery);