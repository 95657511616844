
(function ($) {
    rter.mapController('login', function (context) {
        return {
            viewWillLoad: function (context) {

            },
            viewWillCreate: function ($view) {

            },
            viewCreated: function ($outlet) {
                $outlet.find('#login-form').submit(function () {
                    var $this = $(this);
                    var username = $this.find('#username').val();
                    var password = $this.find('#password').val();
                    var missingFields = [];

                    $outlet.find('.form-group').removeClass('has-error');

                    if (username.length == 0)
                        missingFields.push('username');
                    if (password.length == 0)
                        missingFields.push('password');

                    if (missingFields.length > 0) {
                        for (var x = 0; x < missingFields.length; x++) {
                            $('#' + missingFields[x]).closest('.form-group').addClass('has-error');
                        }
                        showError('Missing information!', 'Please complete the following fields: ' + missingFields.join(', '));
                        return false;
                    }

                    $this.fadeOut(function () {
                        $outlet.find('.loader').fadeIn(function () {
                            rstools.auth.start({ UserName: username, Password: password })
                                .done(function () {
                                    var next = rstools.state.get('p') || '/app/dashboard';
                                    var nextState = rstools.state.get('ps');

                                    rter.routes.go(next, nextState);
                                })
                                .fail(function (startData) {
                                    $outlet.find('.loader').fadeOut(function () {
                                        $outlet.find('form').fadeIn();
                                        showError('Login failed!', startData.message);
                                    });
                                });
                        });
                    });

                    return false;
                });
            },
            viewWillResume: function ($view) {

            },
            viewResumed: function ($view) {

            },
            viewLoaded: function (context) {
            },
            viewWillUnload: function ($view) {

            },
            viewWillDetatch: function ($view) {

            },
            viewDetatched: function ($view) {

            },
            viewWillRemove: function ($view) {

            },
            viewRemoved: function ($view) {
            },
            viewUnloaded: function ($view) {

            }
        };

        function showError(title, message) {
            context.$outlet.find('.login-error')
                .html('<strong>' + title + '</strong> ' + message)
                .fadeOut(function () {
                    $(this).fadeIn();
                });
        }
    });
})(jQuery);