(function ($) {
    var dropdownOptionsFromObject = rstools.utils.dropdownOptionsFromObject;
    var loadPaginatedList = rstools.utils.loadPaginatedList;
    var findLikeData = rstools.utils.findLikeData;

    var PromotionType = rstools.constants.PromotionType;

    /** @type {LinkageState} */
    var LinkageState = rstools.constants.LinkageState;

    function createPromotionForm(type, settings) {
        var url;

        settings = $.extend({
            isMultiple: false,
            data: {},
            filters: {},
            submitSuccessCallback: undefined,
            onCancel: undefined
        }, settings);

        var data = settings.data;
        var s = settings.isMultiple ? 's' : '';

        switch (type) {
            case "edit":
                url = 'Promotion/EditPromotion';
                break;
            case "create":
                url = 'Promotion/CreatePromotion';
                break;
        }

        if (typeof url !== 'string') return undefined;
        var validatePromotionDate = function(formData) {
            if ('PromotionEndDate' in formData && 'PromotionStartDate' in formData) {
                if (moment(formData['PromotionEndDate']) < moment(formData['PromotionStartDate'])) {
                    return 'Start Date must be earlier than End date.';
                }
            }
            return undefined;
        };

        var fields = $.map([
            {
                label: 'Name',
                name: 'PromotionName',
                maxLength: 50,
                helpText: 'The name of the promotion' + s,
                value: data.PromotionName,
                required: !settings.isMultiple,
                hasMultipleValues: 'PromotionName' in data === false
            },
            {
                label: 'Description',
                name: 'PromotionDescription',
                maxLength: 256,
                value: data.PromotionDescription,
                required: !settings.isMultiple,
                hasMultipleValues: 'PromotionDescription' in data === false
            },
            {
                label: 'Notes',
                name: 'PromotionNotes',
                maxLength: 512,
                value: data.PromotionNotes,
                required: false,
                hasMultipleValues: 'PromotionNotes' in data === false
            },
            {
                label: 'Priority',
                name: 'PromotionPriority',
                type: 'number',
                helpText: 'This defines in what order the promotion' + (settings.isMultiple ? 's are' : ' is') + ' displayed.',
                required: false,
                value: data.PromotionPriority,
                hasMultipleValues: 'PromotionPriority' in data === false
            },
            {
                type: 'dropdown',
                label: 'Promotion Type',
                options: [{ label: 'Choose a promotion type', value: false }, '-'].concat(dropdownOptionsFromObject(PromotionType)),
                name: 'PromotionType',
                value: data.PromotionType,
                required: !settings.isMultiple,
                hasMultipleValues: 'PromotionType' in data === false
            },
            {
                label: 'Start Date',
                name: 'PromotionStartDate',
                type: 'datetime',
                required: false,
                value: data.PromotionStartDate,
                validate: validatePromotionDate,
                hasMultipleValues: 'PromotionStartDate' in data === false
            },
            {
                label: 'End Date',
                name: 'PromotionEndDate',
                type: 'datetime',
                required: false,
                value: data.PromotionEndDate,
                validate: validatePromotionDate,
                hasMultipleValues: 'PromotionEndDate' in data === false
            }
        ], function (field) {
            // Modify fields dynamically here
            if (type === "create") field.hasMultipleValues = false;

            return field;
        });

        return uifactory.create.form({
            url: url,
            submitErrorMessage: 'There was a problem ' + (type === 'create' ? 'creat' : 'edit') + 'ing the promotion' + s + ': {Message}.',

            submitSuccessCallback: function () {
                var verbPast = (type === 'create' ? 'created.' : 'edited.');

                uifactory.alert.show({
                    text: !settings.isMultiple ? "The promotion has been " + verbPast : "The promotions have been " + verbPast
                });

                if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback();
            },

            fields: fields,
            baseData: $.extend({
                'PromotionStartDate': '',
                'PromotionEndDate': ''
            }, settings.filters),

            actions: [
                {
                    label: type === 'create' ? 'Create' : 'Save',
                    icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
                    buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
                    action: 'submit'
                },

                {
                    label: 'Cancel',
                    action: settings.onCancel
                }
            ]
        });
    }

    rstools.promotion = {
        edit: function (filters) {
            var promos = [];

            var promosLoaded = loadPaginatedList('Promotion/GetPromotions', 'Promotion/CountPromotions', 'Promotions', $.extend({
                    DisableRestrictions: true
                }, filters))
                .done(function (promotions) { promos = promotions; });

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: $('<h4/>')
            });

            modal.$modal.addClass('loading');

            $.when(promosLoaded)
                .done(function () {
                    var isMultiple = promos.length > 1;

                    modal.$modal.removeClass('loading');

                    modal.settings.header.html(isMultiple ? "Edit " + promos.length + " Promotions" : "Edit Promotion");

                    createPromotionForm('edit', {
                        isMultiple: isMultiple,
                        data: isMultiple ? findLikeData(promos) : promos[0],
                        filters: filters,
                        submitSuccessCallback: function () {
                            rstools.events.dataChanged('promotion', 'edit');
                            modal.hide();
                        },
                        onCancel: function() {
                            modal.hide();
                        }
                    }).appendTo(modal.$body);

                    modal.setDisplayID($.map(promos, function (b) { return b.PromotionID; }));
                })
                .fail(function () {
                    modal.hide();
                    uifactory.alert.show({ type: 'error', text: 'There was an error loading information about the promotion(s).' });
                });

            modal.show();
        },
        create: function (promoData) {
            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: 'Create a Promotion',
                body: createPromotionForm('create', {
                    data: promoData,
                    submitSuccessCallback: function () {
                        rstools.events.dataChanged('promotion', 'create');
                        modal.hide();
                    },
                    onCancel: function () {
                        modal.hide();
                    }
                })
            });

            modal.show();
        },
        manage: function (namespace, filters, settings) {
            namespace = rstools.utils.getNamespace(namespace);

            if ('getPromotionStatusAction' in namespace === false) throw 'Missing getPromotionStatusAction from namespace';
            if ('setPromotionStatusAction' in namespace === false) throw 'Missing setPromotionStatusAction from namespace';

            settings = $.extend({
                header: 'Manage Promotions',
            }, settings);

            var $promotionList = $('<div/>', {
                'class': 'list-group promo-list'
            });

            var modal = uifactory.modal.create({
                header: settings.header,
                cancelable: true,
                body: [
                    $('<p/>', {
                        'text': 'Use the list below to manage the promo inclusion status for your selected objects. Once you have adjusted your settings, click the "Save Promotions" button to commit your changes.'
                    }),
                    document.createElement('hr'),
                    $promotionList
                ],
                actions: [
                    {
                        'icon': 'floppy-o',
                        'class': 'btn btn-success',
                        'title': 'Save',
                        'callback': function () {
                            var changes = $promotionList.children().map(function () {
                                var $this = $(this);
                                var $activeBtn = $this.find('.btn.active');

                                return {
                                    PromotionID: $this.data('id'),
                                    Status: $this.find('.btn.active').hasClass('button-include') ? 
                                        LinkageState.Complete :
                                        LinkageState.None
                                };
                            }).get();

                            var data = {
                                changes: changes,
                                filters: filters
                            };

                            $promotionList.addClass('loading').find('li').fadeTo(250, 0.5);

                            ridestyler.ajax.send({
                                action: namespace.setPromotionStatusAction,
                                data: data,
                                callback: function (response) {
                                    if (response.Success) {
                                        uifactory.alert.show({
                                            text: 'You promotion changes have been saved!'
                                        });
                                    } else {
                                        uifactory.alert.show({
                                            text: response.Message,
                                            type: 'error'
                                        });
                                    }

                                    $promotionList.find('li').fadeTo(250, 1, function () {
                                        $promotionList.removeClass('loading');
                                    });
                                }
                            });
                        }
                    }
                ],
                loadData: {
                    GetPromotionStatus: {
                        action: namespace.getPromotionStatusAction,
                        data: filters,
                        responseKey: 'PromotionStatus'
                    },
                    LoadPromotionRowTemplate: rstools.template.get('promo/row')
                },
                onFail: function (response) {
                    rstools.api.utils.showResponseError('There was an error loading promotion data', response);
                },
                onLoad: function (data) {
                    /** @type {PromotionStatusModel[]} */
                    var promotionStatuses = data.GetPromotionStatus;

                    if (!promotionStatuses || !promotionStatuses.length) {
                        $promotionList.append($('<a/>', {
                            'class': 'list-group-item',
                            'href': '#!/app/organization/promotion',
                            'text': "You don't have any promotions enabled for your account. Click here to manage promotions.",
                            'click': function () {
                                modal.hide();
                            }
                        }));

                        modal.$footer.remove();
                    } else {
                        for (var i = 0; i < promotionStatuses.length; i++) {
                            var p = promotionStatuses[i];

                            var $li = rstools.template.render('promo/row', {
                                text: p.Promotion.PromotionName,
                                id: p.Promotion.PromotionID
                            }, {
                                async: false
                            });

                            if (p.Status === 0) {
                                $li.find('.button-exclude').addClass('active').find('input').prop('checked', true);
                            } else if (p.Status === 4) {
                                $li.find('.button-include').addClass('active').find('input').prop('checked', true);
                            }

                            $promotionList.append($li);
                        }
                    }
                }
            });

            modal.show();
        }
    };
})(jQuery);