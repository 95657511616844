(function ($) {
    window.uifactory.create.confirmActionModal = function (settings) {
        if (typeof settings === 'undefined' || !settings.titles || !settings.actionRequest)
            throw new Error("Invalid settings");

        var isPlural = settings.titles.length > 1;

        settings = $.extend({
            titles: [],
            verb: 'archive',
            verbPast: undefined,
            verbTrail: '',
            noun: 'item',
            pluralNoun: undefined,
            actionButtonClass: 'btn btn-danger',
            actionButtonText: undefined,
            actionRequest: undefined,
            onActionCompleted: undefined,
            onActionFailed: undefined,
            showTitles: true,
            description: undefined,
            verifyPhrase: undefined,
            showSuccessAlert: true,
            showFailureAlert: true
        }, settings);

        if (settings.verbTrail) settings.verbTrail = ' ' + $.trim(settings.verbTrail);
        
        if (!settings.titles.length) {
            uifactory.alert.show({
                text: "There's nothing to " + settings.verb + settings.verbTrail + "."
            });

            return undefined;
        }

        if (typeof settings.pluralNoun === 'undefined') settings.pluralNoun = settings.noun + 's';

        if (typeof settings.header === 'undefined') {
            settings.header = "Are you sure you wish to " + settings.verb + " ";

            if (isPlural) settings.header += "these " + settings.pluralNoun + settings.verbTrail + "?";
            else settings.header += "this " + settings.noun + settings.verbTrail + "?";
        }

        if (typeof settings.actionButtonText === 'undefined')
            settings.actionButtonText = settings.verb[0].toUpperCase() + settings.verb.substr(1) + settings.verbTrail;

        if (typeof settings.verbPast === 'undefined') {
            if (settings.verb.substr(-1).toLowerCase() === 'e') settings.verbPast = settings.verb + 'd';
            else settings.verbPast = settings.verb + 'ed';
        }

		var modal = uifactory.modal.create({
		    type: 'confirmation',
            cancelable: true,
			header: settings.header,
			confirmationYesClass: settings.actionButtonClass,
			confirmationYes: settings.actionButtonText,
			confirmationCallback: function (confirmed) {
			    if (confirmed) {
			        var $actionButton = this.$footer.find('button:contains(' + settings.actionButtonText + ')').prop('disabled', true);
                    
			        ridestyler.ajax.send($.extend(settings.actionRequest, {
			            callback: function (response) {
			                $actionButton.prop('disabled', false);
                            
			                if (response.Success && settings.showSuccessAlert || !response.Success && settings.showFailureAlert) 
			                    uifactory.alert.show({
			                        type: response.Success ? 'success' : 'error',
			                        text: response.Success ?
                                        isPlural ?
                                            settings.titles.length + ' ' + settings.pluralNoun + ' have been ' + settings.verbPast + settings.verbTrail + '.' :
                                            settings.showTitles ?
                                                settings.titles[0] + ' has been ' + settings.verbPast + settings.verbTrail + '.' :
                                                'The ' + settings.noun + ' has been ' + settings.verbPast + settings.verbTrail + '.' :
                                        isPlural ?
                                            'The ' + settings.pluralNoun + ' could not be ' + settings.verbPast + settings.verbTrail + ':' + response.Message :
                                            settings.showTitles ? 
                                                settings.titles[0] + ' could not be ' + settings.verbPast + settings.verbTrail + ':' + response.Message : 
                                                'The ' + settings.noun + ' could not be ' + settings.verbPast + settings.verbTrail + ':' + response.Message
			                    });

			                if (response.Success) {
			                    modal.hide();

			                    if (typeof settings.onActionCompleted === 'function') settings.onActionCompleted(response);
			                } else {
			                    delete modal.confirmed;
			                    if (typeof settings.onActionFailed === 'function') settings.onActionFailed(response);
			                }
			            }
			        }))
			    }
			}
		});

        $('<ul/>', {
            append: $.map(settings.titles, function (title) {
                return $('<li/>', {
                    append: title
                });
            })
        }).appendTo(modal.$body);

        if (settings.description)
            modal.$body.append($('<p/>').append(settings.description));

        if (typeof settings.verifyPhrase === 'string') {
            var $yesButton = modal.$footer.find('button:contains(' + modal.settings.confirmationYes + ')').prop('disabled', true);

            $('<p/>', { 'text': 'To confirm please type the phrase "' + settings.verifyPhrase + '" below:', 'appendTo': modal.$body });
            $('<div/>', {
                'class': 'form-group',
                'appendTo': modal.$body,
                'append': [
                    $('<input/>', {
                        'type': 'text',
                        'class': 'form-control',
                        'maxlength': settings.verifyPhrase.length,
                        'on': {
                            'keyup': function () {
                                $yesButton.prop('disabled', this.value.toLowerCase() !== settings.verifyPhrase.toLowerCase());
                            }
                        }
                    })
                ]
            });
        }

        modal.$modal.on('shown.bs.modal', function () {
            modal.$footer.find('button:contains(' + modal.settings.confirmationNo + ')').focus();
        });

		return modal;
	};
})(jQuery);