rstools.tire.sidewall = (function () {
    var TireSidewallAbbrToName = rstools.constants.TireSidewallAbbrToName;


    return {
        /**
         * Creates a sidewall ABBR element for the passed in sidewall
         * @param {TireSidewallDataObject|string} sidewall The sidewall or sidewall abbreviation to create
         * @returns {HTMLElement}
         */
        createAbbrElement: function (sidewall) {
            var abbr, description, className;

            if (typeof sidewall === 'string') {
                abbr = sidewall;

                if (abbr in TireSidewallAbbrToName) 
                    description = TireSidewallAbbrToName[abbr];
            } else if ('TireSidewallAbbr' in sidewall) {
                abbr = sidewall.TireSidewallAbbr;
                description = sidewall.TireSidewallName;
            }

            className = abbr;

            if (abbr === '?') className = 'Unknown';

            var el = document.createElement('abbr');
                el.className = 'sidewall ' + className;
                el.appendChild(document.createTextNode(abbr));
            
            if (description) el.title = description;

            return el;
        }
    };
})();