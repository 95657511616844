(function () {
	var loosenFilters = rstools.vehicle.utils.loosenFilters;
	var loadPaginatedList = rstools.utils.loadPaginatedList;

	var VehicleResourceType = rstools.constants.VehicleResourceType;

	rstools.vehicle.configuration = {
		columns: {
			vehicleName: function ($tds, data, configurationIDKey) {
				$tds.addClass('light loading loading-small');
	
				ridestyler.ajax.send({
					action: 'Vehicle/GetDescriptions',
					data: {
						VehicleConfigurations: $.map(data, function (d) {
							return d[configurationIDKey];
						}).unique(),
						TireOptionRequirement: 0,
						FitmentRequirement: 0
					},
					callback: function (response) {
						if (response.Success) {
							var configurationNameMap = {};
	
							for (var i = response.Descriptions.length - 1; i >= 0; i--) {
								var d = response.Descriptions[i];
	
								configurationNameMap[d.ConfigurationID] = d.FullDescription;
							}
	
							for (i = data.length - 1; i >= 0; i--)
								$tds.eq(i).text(configurationNameMap[data[i][configurationIDKey]]);
						} else {
							$tds.text('Error');
							console.error("Error retrieving vehicle fitment configuration names: ", response);
						}
	
						$tds.removeClass('loading');
					}
				});
			}
		},
		utils: {
			getIDs: function (configurations) {
				return $.map(configurations, function (configuration) {
					if ('ConfigurationID' in configuration) return configuration.ConfigurationID;
	
					return configuration.VehicleConfigurationID;
				});
			},
			getTitles: function (configurations) {
				return $.map(configurations, function (configuration) {
					if ('FullDescription' in configuration) return configuration.FullDescription;
	
					return configuration.VehicleConfigurationName;
				});
			}
		},
		manageResources: function (filters, settings) {
			settings = $.extend({
				header: 'Manage Vehicle Resources'
			}, settings);
	
			if (!filters) throw new TypeError("You must pass in filters to rstools.vehicle.configuration.manageResources.");
	
			loosenFilters('resources', filters);

			var modal = uifactory.modal.create({
				header: settings.header,
				footer: false,
				cancelable: true
			});

            var $row = $('<div/>', { 'class': 'row' }).appendTo(modal.$body);

            var $columns = {
                side: $('<div/>', { 'class': 'col-sm-6' }).appendTo($row),
                angled: $('<div/>', { 'class': 'col-sm-6' }).appendTo($row)
			};

			var groupedVehicleResources = {
				/** @type {ridestyler.DataObjects.VehicleResourceDataObject[]} */
				side: undefined,

				/** @type {ridestyler.DataObjects.VehicleResourceDataObject[]} */
				angle: undefined
			};
			
			function loadResources() {
                loadPaginatedList('Vehicle/GetResources', 'Vehicle/CountResources', 'Resources', filters)
                    .done(function(vehicleResources) {
                        groupedVehicleResources = {
                            side: [],
                            angle: []
						};

						/**
						 * @param {ridestyler.DataObjects.VehicleResourceDataObject} vehicleResource
						 * @returns {string} The ResourceID of the linked resource
						 */
						function mapResourceID(vehicleResource) { return vehicleResource.VehicleResource_ResourceID; }

						/**
						 * @param {"Side"|"Angle"} title
						 */
						function createResourceThumbnail(title) {
							var key = title.toLowerCase();

							var resources = groupedVehicleResources[key];
							var vehicleResourceType = VehicleResourceType[title];

							// TODO, Finish me!
						}


						for (var i = 0; i < vehicleResources.length; i++) {
							var vehicleResource = vehicleResources[i];

							switch (vehicleResource.VehicleResourceType) {
								case VehicleResourceType.Side:
									groupedVehicleResources.side.push(vehicleResource);
									break;
								case VehicleResourceType.Angle:
									groupedVehicleResources.angle.push(vehicleResource);
									break;
							}
							
						}
                    });
            }

            loadResources();

            modal.$modal.addClass('loading');

            modal.show();
		}
	};

})();