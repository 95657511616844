rstools.constants = {
    /**
     * Returns the value of a constant as the "pretty" string value regardless of whether it was passed in
     * as an integer or string.
     * 
     * @param  {object}     constant The constant to return a value for
     * @param  {string|int} value    The constant's value to return
     * @return {string}              The constant value in string "pretty" format
     */
    getString: function (constant, value) {
        if (isNaN(value) || value in constant === false) return value;
        return constant[value];
    },

    /**
     * Returns the value of a constant as the integer value regardless of whether it was passed in
     * as an integer or string.
     * 
     * @param  {object}     constant The constant to return a value for
     * @param  {string|int} value    The constant's value to return
     * @return {int}                 The constant value in integer format
     */
    getValue: function (constant, value) {
        if (isNaN(value) === false || value in constant === false) return value;
        return constant[value];
    },

    DataStatus: {
        Valid:         1, 1: 'Valid',
        NeedsReview:   2, 2: 'Needs Review',
        Invalid:       0, 0: 'Invalid',
        PendingDelete: 4, 4: 'Pending Delete'
    },
    UpdateDataStatusMode: {
        Add: 0,
        Remove: 1,
        Replace: 2
    },
    PromotionType: {
        Sale:     1, 1: 'Sale',
        Closeout: 2, 2: 'Closeout',
        Feature:  3, 3: 'Feature',
        Special:  4, 4: 'Special'
    },
    FieldComparisonStatus: {
        Success: 2,
        Warning: 1,
        Invalid: 0
    },
    InclusionState: {
        None: 0,
        Optional: 1,
        Excluded: 2,
        Required: 5,
        ByChild: 8,
        ByParent: 16
    },
    SubscriptionInterval: {
        Monthly: 1, 1: "Monthly",
        Yearly:  2, 2: "Yearly",
        Weekly:  3, 3: "Weekly"
    },
    WheelFitmentResourceType: {
        Side:    1, 1: "Side",
        Angled:  2, 2: "Angled",
        Catalog: 3, 3: "Catalog"
    },
    TireFitmentResourceType: {
        Side:      1,  1:  "Side",
        Catalog:   2,  2:  "Catalog",
        Tread:     3,  3:  "Tread",
        Optional1: 10, 10: "Option 1",
        Optional2: 11, 11: "Option 2",
        Optional3: 12, 12: "Option 3"
    },
    TireFitmentResourceVariant: {
        PrimarySidewall:   1, 1: 'Primary Sidewall',
        AlternateSidewall: 2, 2: 'Alternate Sidewall'
    },
    TireSidewallAbbrToName: {
        '?':	'Unknown',
        'BSL':	'Black Serrated Letters',
        'BSW':	'Black Sidewall',
        'OWL':	'Outlined White Letters',
        'RWL':	'Raised White Letters',
        'RBL':	'Raised Black Letters',
        'WSW':	'White Sidewall',
        'ORWL':	'Outlined Raised White Letters',
        'VSB':	'Vertical Serrated Band',
        'BLT':	'Black Letters',
        'WS':	'White Stripe'
    },
    TireSidewallIDAbbrLookup: {
        1:	'?',    '?': 1,    // Unknown
        2:	'BSL',  'BSL': 2,  // Black Serrated Letters
        3:	'BSW',  'BSW': 3,  // Black Sidewall
        4:	'OWL',  'OWL': 4,  // Outlined White Letters
        5:	'RWL',  'RWL': 5,  // Raised White Letters
        6:	'RBL',  'RBL': 6,  // Raised Black Letters
        7:	'WSW',  'WSW': 7,  // White Sidewall
        8:	'ORWL', 'ORWL': 8, // Outlined Raised White Letters
        9:	'VSB',  'VSB': 9,  // Vertical Serrated Band
        10:	'BLT',  'BLT': 10, // Black Letters
        12:	'WS',   'WS': 12   // White Stripe
    },
    TireSidewallIDNameLookup: {
        1:  'Unknown',                       '?': 1,   
        2:  'Black Serrated Letters',        'BSL': 2, 
        3:  'Black Sidewall',                'BSW': 3, 
        4:  'Outlined White Letters',        'OWL': 4, 
        5:  'Raised White Letters',          'RWL': 5, 
        6:  'Raised Black Letters',          'RBL': 6, 
        7:  'White Sidewall',                'WSW': 7, 
        8:  'Outlined Raised White Letters', 'ORWL': 8, 
        9:  'Vertical Serrated Band',        'VSB': 9, 
        10: 'Black Letters',                 'BLT': 10,
        12: 'White Stripe',                  'WS': 12 
    },
    TireWarrantyType: {
        Unknown: 0, 0: "Unknown",
        None:    1, 1: "None",
        Limited: 2, 2: "Limited",
        Mileage: 3, 3: "Mileage"
    },
    WheelBrandResourceType: {
        Logo: 1, 1: "Logo"
    },
    TireBrandResourceType: {
        Logo: 1, 1: "Logo"
    },
    TireUseTypeFlags: {
        0:  "All Vehicles", "AllVehicles": 0,
        1:  "Truck",       "Truck": 1,
        2:  "SUV",         "SUV": 2,
        4:  "Crossover",   "Crossover": 4,
        8:  "Van",         "Van": 8,
        16: "Car",         "Car": 16
    },
    WheelCenterCapType: {
        1: "Unknown",      "Unknown": 1,
        2: "Bolt-On",      "Bolt-On": 2,
        3: "Push-Through", "Push-Through": 3,
        4: "Snap-On",      "Snap-On": 4,
        5: "None",         "None": 5,
        6: "Snap-In",      "Snap-In": 6,
        7: "Screw-On",     "Screw-On": 7
    },
    WheelHoleType: {
        1: "Unknown",   "Unknown":  1,
        2: "Spline",    "Spline":   2,
        3: "Bulge",     "Bulge":    3,
        4: "Both",      "Both":     4,
        5: "Mag Seat",  "Mag Seat": 5,
        6: "ET",        "ET":       6,
        7: "OEM Bolt",  "OEM Bolt": 7,
        8: "OEM Lug",   "OEM Lug":  8  
    },
    LinkageState: {
        0: "None",     "None":     0,
        2: "Partial",  "Partial":  2,
        4: "Complete", "Complete": 4
    },
    PricingDataType: {
        MSRP: 1,   1: 'MSRP',
        Cost: 3,   3: 'Cost',
        Retail: 5, 5: 'Retail',
        WD: 8,     8: 'WD'
    },
    ResourceFileType: {
        Image:       1, 1: "Image",
        Video:       2, 2: "Video",
        PDF:         3, 3: "PDF",
        AssetBundle: 4, 4: "Asset Bundle"
    },
    ResourceAssetType: {
        Unspecified:              1,  1:  'Unspecified',
        VehicleImageBundleSide:   10, 10: 'Vehicle Image Bundle (Side)',
        VehicleImageBundleAngled: 11, 11: 'Vehicle Image Bundle (Angled)',
        WheelImageSide:           20, 20: 'Wheel Image (Side)',
        WheelImageAngled:         21, 21: 'Wheel Image (Angled)',
        WheelImageCatalog:        22, 22: 'Wheel Image (Catalog)',
        WheelBrandImage:          29, 29: 'Wheel Brand Image',
        TireImageSide:            30, 30: 'Tire Image (Side)',
        TireImageCatalog:         32, 32: 'Tire Image (Catalog)',
        TireImageTread:           33, 33: 'Tire Image (Tread)',
        TireBrandImage:           38, 38: 'Tire Brand Image',
        TireOptionalResource:     39, 39: 'Tire Optional Resource',
        OrganizationImage:        40, 40: 'Organization Image'
    },
    TireSizeType:
    {
        'Unknown': 0,         0: 'Unknown',
        'Metric': 1,          1: 'Metric',
        'Flotation': 2,       2: 'Flotation',
        'Asymmetrical': 3,    3: 'Asymmetrical',
        'FlotationLegacy': 4, 4: 'Flotation (Legacy)',
        'Alphanumeric': 5,    5: 'Alphanumeric',
        'Millimetric': 6,     6: 'Millimetric'
    },
    VehicleFitmentPositionType: {
        'Both': 0,  0: 'Both',
        'Front': 1, 1: 'Front',
        'Rear': 2,  2: 'Rear'
    },
    VehicleReferenceType: {
        ACES: 1,      1: 'ACES',
        WheelPros: 2, 2: 'Wheel Pros'
    },
    VehicleResourceType: {
        Side: 1,  1: 'Side',
        Angle: 2, 2: 'Angle'
    },
    VehicleDriveType: {
        '2WD': 1, 1: '2WD',
        '4WD': 2, 2: '4WD',
        'AWD': 3, 3: 'AWD'
    },
    VehicleDoorType: {
        '1DR': 1, 1: '1DR',
        '2DR': 2, 2: '2DR',
        '3DR': 3, 3: '3DR',
        '4DR': 4, 4: '4DR',
        '5DR': 5, 5: '5DR'
    },
    VehicleStyleDescription: {
        'Unspecified': 1, 1: 'Unspecified',
        'Sedan': 2,       2: 'Sedan',
        'Coupe': 3,       3: 'Coupe',
        'Roadster': 4,    4: 'Roadster',
        'Wagon': 5,       5: 'Wagon',
        'Hatchback': 6,   6: 'Hatchback',
        'Targa Top': 7,   7: 'Targa Top',
        'Convertible': 8, 8: 'Convertible'
    },
    VehicleStyleType: {
        'Car': 1, 1: 'Car',
        'Van': 2, 2: 'Van',
        'Pickup': 3, 3: 'Pickup/Truck', 'Truck': 3,
        'SUV': 4, 4: 'SUV',
        'Crossover': 5, 5: 'Crossover'
    },
    VehicleStyleTypeClass: {
        'Car': 1, 1: 'Car',
        'Light Truck': 2, 2: 'Light Truck'
    },
    VehicleCabType: {
        'None': 1,          1:   'None',
        'Regular Cab': 5,   5:   'Regular Cab',
        'Crew Cab': 6,      6:   'Crew Cab',
        'Extended Cab': 7,  7:   'Extended Cab',
        'King Cab': 8,      8:   'King Cab',
        'Quad Cab': 9,      9:   'Quad Cab',
        'Super Cab': 10,    10:  'Super Cab',
        'Standard Cab': 11, 11:  'Standard Cab',
        'Access Cab': 12,   12:  'Access Cab',
        'Xtra Cab': 13,     13:  'Xtra Cab',
        'Double Cab': 14,   14:  'Double Cab',
        'Crew Max': 15,     15:  'Crew Max',
        'Super Crew': 16,   16:  'Super Crew',
        'Mega Cab': 17,     17:  'Mega Cab'
    },
    VehicleCabTypeClass: {
        'None': 1,              1: 'None',
        'Standard Cab': 2,      2: 'Standard Cab',
        'Extended Cab': 3,      3: 'Extended Cab',
        'Crew Cab': 4,          4: 'Crew Cab',
        'Extended Crew Cab': 5, 5: 'Extended Crew Cab',
    },
    VehicleBedLength: {
        'N/A': 1,       1: 'N/A',
        'Short Bed': 2, 2: 'Short Bed',
        'Long Bed': 3,  3: 'Long Bed'
    },
    VcDbReferenceFields: {
        BaseVehicleID: 1,       1: "BaseVehicleID",
        BedConfigID: 2,         2: "BedConfigID",
        BedLengthID: 3,         3: "BedLengthID",
        BedTypeID: 4,           4: "BedTypeID",
        BodyNumDoorsID: 5,      5: "BodyNumDoorsID",
        BodyStyleConfigID: 6,   6: "BodyStyleConfigID",
        BodyTypeID: 7,          7: "BodyTypeID",
        DriveTypeID: 8,         8: "DriveTypeID",
        EngineBaseID: 9,        9: "EngineBaseID",
        EngineConfigID: 10,     10: "EngineConfigID",
        FuelTypeID: 11,         11: "FuelTypeID",
        MakeID: 12,             12: "MakeID",
        ModelID: 13,            13: "ModelID",
        RegionID: 14,           14: "RegionID",
        SubModelID: 15,         15: "SubModelID",
        VehicleID: 16,          16: "VehicleID",
        VehicleTypeGroupID: 17, 17: "VehicleTypeGroupID",
        VehicleTypeID: 18,      18: "VehicleTypeID",
        YearID: 19,             19: "YearID"
    },
    WheelProsReferenceFields: {
        VehicleCD: 1, 1: 'VehicleCD'
    },
    KeyCode: {
        Cmd: -1, // Matches Ctrl on Windows, Command on mac
        Ctrl: -2,
        Meta: -3,
        Super: -3,
        Shift: -4,
        Option: -5,
        Alt: -5
    }
};