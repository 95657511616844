var __extends = this.__extends || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    __.prototype = b.prototype;
    d.prototype = new __();
};

(function () {
    rstools.data = {
        provider: (function () {
            var RstoolsMemoryDataProvider = (function () {
                function RstoolsMemoryDataProvider(settings) {
                    this.cache = {};
                }
                RstoolsMemoryDataProvider.prototype.get = function (k) {
                    return this.cache[k];
                };
                RstoolsMemoryDataProvider.prototype.set = function (k, v) {
                    this.cache[k] = v;
                };
                RstoolsMemoryDataProvider.prototype.clear = function () {
                    this.cache = {};
                };
                RstoolsMemoryDataProvider.prototype.remove = function (k) {
                    delete this.cache[k];
                };
                RstoolsMemoryDataProvider.prototype.getAsync = function (k) {
                    return $.Deferred().resolve(this.cache[k]);
                };
                RstoolsMemoryDataProvider.prototype.setAsync = function (k, v) {
                    this.cache[k] = v;
                    return $.Deferred().resolve();
                };
                RstoolsMemoryDataProvider.prototype.removeAsync = function (k) {
                    this.remove(k);
                    return $.Deferred().resolve();
                };
                RstoolsMemoryDataProvider.prototype.clearAsync = function () {
                    this.clear();
                    return $.Deferred().resolve();
                };

                RstoolsMemoryDataProvider.tag = "memory";
                
                return RstoolsMemoryDataProvider;
            })();
            var RstoolsCacheDataProvider = (function () {
                function RstoolsCacheDataProvider() {
                    this.cache = undefined;
                    this.batch = false;
                }

                RstoolsCacheDataProvider.prototype['get'] = function (k) {
                    if (!k)
                        return undefined;
                    if (!this.cache)
                        this.retrieveCache();
                    return this.cache[k];
                };
                RstoolsCacheDataProvider.prototype['set'] = function (k, v) {
                    this.setAsync(k, v);
                };
                RstoolsCacheDataProvider.prototype.clear = function () {
                    this.clearAsync();
                };
                RstoolsCacheDataProvider.prototype.remove = function (k) {
                    this.removeAsync(k);
                };
                RstoolsCacheDataProvider.prototype._pushCache = function () {
                    if (this.batch)
                        return $.Deferred().resolve();
                    return this.pushCache();
                };
                RstoolsCacheDataProvider.prototype.pushCache = function () {
                    throw "Not Implemented";
                };
                RstoolsCacheDataProvider.prototype.retrieveCache = function () {
                    throw "Not Implemented";
                };
                RstoolsCacheDataProvider.prototype.ensureCache = function () {
                    return this.cache ? $.Deferred().resolve() : this.retrieveCache();
                };
                RstoolsCacheDataProvider.prototype.getAsync = function (k) {
                    var _this = this;
                    if (!k)
                        return $.Deferred().resolve(undefined);
                    if (!this.cache)
                        return this.retrieveCache().then(function () { return _this.cache[k]; });
                    return $.Deferred().resolve(this.cache[k]);
                };
                RstoolsCacheDataProvider.prototype.setAsync = function (k, v) {
                    var _this = this;
                    if (!k)
                        return $.Deferred().resolve();
                    if (!this.cache)
                        return this.retrieveCache().done(function () { return _this.setAsync(k, v); });
                    this.cache[k] = v;
                    return this._pushCache();
                };
                RstoolsCacheDataProvider.prototype.removeAsync = function (k) {
                    var _this = this;
                    if (!k)
                        return $.Deferred().resolve();
                    if (!this.cache)
                        return this.retrieveCache().then(function () { return _this.removeAsync(k); });
                    delete this.cache[k];
                    return this._pushCache();
                };
                RstoolsCacheDataProvider.prototype.clearAsync = function () {
                    this.cache = {};
                    return this._pushCache();
                };
                RstoolsCacheDataProvider.prototype.startBatch = function () {
                    this.batch = true;
                };
                RstoolsCacheDataProvider.prototype.endBatch = function () {
                    this.batch = false;
                    return this.pushCache();
                };

                return RstoolsCacheDataProvider;
            })();
            var RstoolsRidestylerDataProvider = (function (_super) {
                __extends(RstoolsRidestylerDataProvider, _super);

                function RstoolsRidestylerDataProvider() {
                    _super.apply(this, arguments);
                }
                RstoolsRidestylerDataProvider.prototype.pushCache = function () {
                    var deferred = $.Deferred();
                    ridestyler.ajax.send({
                        action: this.setAction,
                        data: {
                            Settings: [
                                {
                                    Key: this.namespace,
                                    Value: JSON.stringify(this.cache)
                                }
                            ]
                        },
                        callback: $.proxy(function (response) {
                            if (!response.Success)
                                return deferred.reject();
                            return deferred.resolve();
                        }, this)
                    });
                    return deferred;
                };
                RstoolsRidestylerDataProvider.prototype.retrieveCache = function () {
                    var _this = this;
                    var deferred = $.Deferred();
                    ridestyler.ajax.send({
                        action: this.getAction,
                        data: {
                            Keys: [this.namespace]
                        },
                        callback: function (response) {
                            if (!response.Success || 'Settings' in response === false)
                                return deferred.reject();
                            var settings = response.Settings || {};
                            if (_this.namespace in settings)
                                _this.cache = $.parseJSON(settings[_this.namespace]) || {};
                            else {
                                _this.cache = {};
                            }
                            return deferred.resolve();
                        }
                    });
                    return deferred;
                };
                return RstoolsRidestylerDataProvider;
            })(RstoolsCacheDataProvider);
            var RstoolsUserSettingsDataProvider = (function (_super) {
                __extends(RstoolsUserSettingsDataProvider, _super);
                function RstoolsUserSettingsDataProvider(settings) {
                    this.namespace = settings.namespace;
                    this.getAction = 'User/GetSettings';
                    this.setAction = 'User/SetSettings';
                    _super.call(this);
                }
                RstoolsUserSettingsDataProvider.tag = "usersettings";
                return RstoolsUserSettingsDataProvider;
            })(RstoolsRidestylerDataProvider);
            var RstoolsOrganizationSettingsDataProvider = (function (_super) {
                __extends(RstoolsOrganizationSettingsDataProvider, _super);
                function RstoolsOrganizationSettingsDataProvider(settings) {
                    this.namespace = settings.namespace;
                    this.getAction = 'Organization/GetSettings';
                    this.setAction = 'Organization/SetSettings';
                    _super.call(this);
                }
                RstoolsOrganizationSettingsDataProvider.tag = "organizationsettings";
                return RstoolsOrganizationSettingsDataProvider;
            })(RstoolsRidestylerDataProvider);
            var RstoolsSingleSettingDataProvider = (function () {
                function RstoolsSingleSettingDataProvider(settingName) {
                    this.settingName = settingName;
                }
                RstoolsSingleSettingDataProvider.prototype.getAsync = function (settingName) {
                    var deferred = $.Deferred();
                    settingName = settingName || this.settingName;
                    ridestyler.ajax.send({
                        action: this.getAction,
                        data: {
                            Keys: [settingName || this.settingName]
                        },
                        callback: $.proxy(function (response) {
                            if (!response.Success || 'Settings' in response === false)
                                return deferred.reject();
                            var settings = response.Settings || {};
                            if (settingName in settings)
                                return deferred.resolve($.parseJSON(settings[settingName]));
                            else
                                return deferred.resolve(undefined);
                        }, this)
                    });
                    return deferred;
                };
                RstoolsSingleSettingDataProvider.prototype.setAsync = function () {
                    var deferred = $.Deferred();
                    var settingName = this.settingName;
                    var value;
                    if (typeof arguments[0] === "string") {
                        settingName = arguments[0];
                        value = arguments[1];
                    }
                    else {
                        value = arguments[0];
                    }
                    ridestyler.ajax.send({
                        action: this.setAction,
                        data: {
                            Settings: [
                                {
                                    Key: settingName,
                                    Value: JSON.stringify(value)
                                }
                            ]
                        },
                        callback: $.proxy(function (response) {
                            if (!response.Success)
                                return deferred.reject();
                            return deferred.resolve();
                        }, this)
                    });
                    return deferred;
                };
                return RstoolsSingleSettingDataProvider;
            })();
            var RstoolsUserSingleSettingDataProvider = (function (_super) {
                __extends(RstoolsUserSingleSettingDataProvider, _super);
                function RstoolsUserSingleSettingDataProvider(settingName) {
                    this.getAction = 'User/GetSettings';
                    this.setAction = 'User/SetSettings';
                    _super.call(this, settingName);
                }
                return RstoolsUserSingleSettingDataProvider;
            })(RstoolsSingleSettingDataProvider);
            var RstoolsOrganizationSingleSettingDataProvider = (function (_super) {
                __extends(RstoolsOrganizationSingleSettingDataProvider, _super);
                function RstoolsOrganizationSingleSettingDataProvider(settingName) {
                    this.getAction = 'Organization/GetSettings';
                    this.setAction = 'Organization/SetSettings';
                    _super.call(this, settingName);
                }
                return RstoolsOrganizationSingleSettingDataProvider;
            })(RstoolsSingleSettingDataProvider);
            var RstoolsLocalStorageDataProvider = (function (_super) {
                __extends(RstoolsLocalStorageDataProvider, _super);
                function RstoolsLocalStorageDataProvider(settings) {
                    this.namespace = settings.namespace;
                    _super.call(this);
                }
                RstoolsLocalStorageDataProvider.enabled = function () {
                    return Modernizr.localstorage && Modernizr.localstorage;
                };
                RstoolsLocalStorageDataProvider.prototype.clear = function () {
                    this.cache = {};
                    localStorage.removeItem(this.namespace);
                };
                RstoolsLocalStorageDataProvider.prototype.pushCache = function () {
                    var cache = this.cache;
                    if (!cache) {
                        this.clear();
                        return $.Deferred().resolve();
                    }
                    localStorage.setItem(this.namespace, JSON.stringify(cache));
                    return $.Deferred().resolve();
                };
                RstoolsLocalStorageDataProvider.prototype.retrieveCache = function () {
                    var cache = localStorage.getItem(this.namespace);
                    if (cache) {
                        try {
                            this.cache = $.parseJSON(cache);
                        }
                        catch (e) {
                        }
                    }
                    else {
                        this.cache = {};
                    }
                    return $.Deferred().resolve();
                };
                RstoolsLocalStorageDataProvider.tag = "localstorage";
                return RstoolsLocalStorageDataProvider;
            })(RstoolsCacheDataProvider);
            var RstoolsCookieDataProvider = (function (_super) {
                __extends(RstoolsCookieDataProvider, _super);
                function RstoolsCookieDataProvider(settings) {
                    this.namespace = settings.namespace;
                    _super.call(this);
                }
                RstoolsCookieDataProvider.enabled = function () {
                    return navigator.cookieEnabled && Modernizr.json;
                };
                RstoolsCookieDataProvider.prototype.clear = function () {
                    this.cache = {};
                    Cookies.expire(this.namespace);
                };
                RstoolsCookieDataProvider.prototype.pushCache = function () {
                    var cache = this.cache;
                    if (!cache) {
                        this.clear();
                        return $.Deferred().resolve();
                    }
                    Cookies.set(this.namespace, JSON.stringify(cache));
                    return $.Deferred().resolve();
                };
                RstoolsCookieDataProvider.prototype.retrieveCache = function () {
                    var cookie = Cookies.get(this.namespace);
                    if (cookie) {
                        try {
                            return this.cache = $.parseJSON(cookie);
                        }
                        catch (e) {
                        }
                    }
                    this.cache = {};
                    return $.Deferred().resolve();
                };
                RstoolsCookieDataProvider.tag = "cookie";
                return RstoolsCookieDataProvider;
            })(RstoolsCacheDataProvider);

            return {
                RstoolsMemoryDataProvider: RstoolsMemoryDataProvider,
                RstoolsCacheDataProvider: RstoolsCacheDataProvider,
                RstoolsRidestylerDataProvider: RstoolsRidestylerDataProvider,
                RstoolsUserSettingsDataProvider: RstoolsUserSettingsDataProvider,
                RstoolsOrganizationSettingsDataProvider: RstoolsOrganizationSettingsDataProvider,
                RstoolsSingleSettingDataProvider: RstoolsSingleSettingDataProvider,
                RstoolsUserSingleSettingDataProvider: RstoolsUserSingleSettingDataProvider,
                RstoolsOrganizationSingleSettingDataProvider: RstoolsOrganizationSingleSettingDataProvider,
                RstoolsLocalStorageDataProvider: RstoolsLocalStorageDataProvider,
                RstoolsCookieDataProvider: RstoolsCookieDataProvider,

                get: function get(settings) {
                    var providers = [
                        RstoolsUserSettingsDataProvider,
                        RstoolsOrganizationSettingsDataProvider,
                        RstoolsLocalStorageDataProvider,
                        RstoolsCookieDataProvider,
                        RstoolsMemoryDataProvider
                    ];

                    var preferredProvider = settings.preferredProvider;

                    // Filter providers that are enabled
                    providers = $.map(providers, function (provider) {
                        var enabled = true;

                        if (typeof provider.enabled === 'function')
                            enabled = provider.enabled();

                        return enabled ? provider : undefined;
                    });

                    preferredProvider && providers.sort(function (a, b) {
                        if (a.tag === preferredProvider) return -1; // Use A first
                        if (b.tag === preferredProvider) return 1;  // Use B first
                        
                        return 0; // No preference
                    });

                    return new providers[0](settings);
                }
            };
        })(),
        utils: {
            /**
             * By default returns the identifier field for a data object by returning the first
             * field ending in 'ID' without an underscore. If idFieldSearch is defined the first
             * field ending in idFieldSearch is returned.
             */
            detectIDField: function (dataObject, idFieldSearch) {
                if (!dataObject) return undefined;

                if (idFieldSearch && idFieldSearch[0] !== '_')
                    idFieldSearch = '_' + idFieldSearch;

                for (var k in dataObject) {
                    if (idFieldSearch) {
                        // Return the first property ending in idFieldSearch
                        if (k.substr(-idFieldSearch.length) === idFieldSearch)
                            return k;
                    } else {
                        // Return the first property ending in "ID" without a "_"
                        if (dataObject.hasOwnProperty(k) && k.substr(-2) === 'ID' && k.indexOf('_') === -1)
                            return k;    
                    }
                }

                return undefined;
            }
        }
    };

    rstools.events.dataChanged(function (e, changed, what) {
        $('.data-table').each(function () {
            var grid = $.data(this.parentElement, 'grid');
            var nameSpace = grid.settings.namespace;
            var dataChangedExceptions = grid.settings.dataChangedExceptions;
            if (dataChangedExceptions && dataChangedExceptions.length)
                dataChangedExceptions = $.map(dataChangedExceptions, function (except) { return (except === what ? except : undefined); });
            if (grid && (!nameSpace || nameSpace === changed))
                if (!dataChangedExceptions || dataChangedExceptions.length < 1)
                    grid.refresh();
        });
    });
})();