(function () {
	uifactory.loading = {
		show: function (options) {
			if (typeof options === 'string') options = {text: options};

			options = $.extend({
				target: 'body'
			}, options);

			uifactory.loading.hide();

			var $container = $('<div/>', {
				'class': 'full-loading',
				'append': [
					uifactory.create.icon({name: 'cog', spin: true}).addClass('full-loading-icon')
				]
			});

			if (options.text) {
				$('<div/>', {
					'class': 'full-loading-text',
					'append': options.text,
					'appendTo': $container
				});
			}

			if (typeof options.onCancel === "function") {
				$('<a/>', {
					'class': 'full-loading-cancel',
					'href': '#',
					'click': function () {
						options.onCancel();
						return false;
					},
					'text': 'Cancel',
					'appendTo': $container
				});
			}

			$container.appendTo(options.target);
		},
		hide: function () {
			$('.full-loading').remove();
		},

		button: {
			setLoading: function ($button, settings) {
				if (typeof settings !== "object") settings = {loading: typeof settings === "undefined" ? true : !!settings};

				settings = $.extend({
					loading: true,
					disable: true
				}, settings);

				if (settings.disable) $button.prop('disabled', !settings.loading);

				if (settings.loading)
					$button
						.data('children', $button.prop('childNodes'))
						.empty()
						.append(uifactory.create.icon({
							name: 'spinner',
							spin: true
						}))
				else
					$button
						.empty()
						.append($button.data('children'))
						.removeData('children');
			}
		},

		buttonGroup: {
			setLoading: function ($group, loading, className) {
				if (loading) {
					var $groupChildren = $group.children();
					var $loadingIndicatorButton = $('<button/>');

					$loadingIndicatorButton
						.addClass(className || $groupChildren.first().attr('class'))
						.addClass("btn-group-loading")
						.removeClass('open');

					$loadingIndicatorButton.append(uifactory.create.icon({
						name: 'spinner',
						spin: true
					}));

					$loadingIndicatorButton.appendTo($group);

					$group.data('children', $groupChildren.remove());
				} else {
					$group.empty().append($group.data('children')).removeData('children');
				}
			}
		}
	};
})();