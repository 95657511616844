(function ($) {
	rter.mapController('app.vehicle.fitment', function (context) {
		var grid = null;
		var $filterForm, $filterButton;
        var $backToConfigurationsButton;

		var filters = {};

		return {
			viewWillLoad: function(ctx) {
				filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
			},
			viewCreated: function ($view) {
				$filterForm = rstools.vehicle.utils.createVehicleFilterForm(context, {
					onReset: filtersUpdated,
					onSubmit: filtersUpdated,
					filters: filters
				});

				// #region Create the datagrid
				grid =
					datagrid.create({
						container: $view.find('div.data-wrapper'),
						allowShowAll: true,
						fields: [
							{
								name: 'VehicleFitment_VehicleConfigurationID',
								caption: 'Vehicle Configuration',
								columnRenderer: function ($tds, fitments) {
									return rstools.vehicle.configuration.columns.vehicleName($tds.empty(), fitments, 'VehicleFitment_VehicleConfigurationID');
								},
								sort: false
							},
							{
								name: 'DataShardName',
								caption: 'Data Shard',
								visible: false,
								columnRenderer: function ($tds, fitments) {
									return rstools.datashard.columns.name($tds, fitments, 'VehicleFitment_DataShardID');
								},
								sort: false
							},
							{
								name: 'VehicleFitment_BoltPatternID',
								caption: 'Bolt Pattern',
								columnRenderer: function ($tds, fitments) {
									return rstools.boltpattern.columns.description($tds, fitments, 'VehicleFitment_BoltPatternID');
								},
								sort: false
							},
							{
								name: 'VehicleFitmentHub',
								caption: 'Hub',
								format: function (f) {
									return [f.VehicleFitmentHub, uifactory.create.abbr('mm', 'millimeters')];
								}
							},
							{
								name: 'VehicleFitmentOffsetMin',
								caption: 'Offset',
								format: function (f) {
									if (f.VehicleFitmentOffsetMin == f.VehicleFitmentOffsetMax) return rstools.utils.format.offset(f.VehicleFitmentOffsetMin);

									var v = rstools.utils.format.offset(f.VehicleFitmentOffsetMin);
									v.push(' - ');
									v = v.concat(rstools.utils.format.offset(f.VehicleFitmentOffsetMax));

									return v;
								}
							},
							{
								name: 'VehicleFitmentDiameterMin',
								caption: 'Diameter',
								format: function (f) {
									return f.VehicleFitmentDiameterMin == f.VehicleFitmentDiameterMax ?
										[f.VehicleFitmentDiameterMin, uifactory.create.abbr('in', 'inches')] :
										[f.VehicleFitmentDiameterMin, uifactory.create.abbr('in', 'inches'), ' - ', f.VehicleFitmentDiameterMax, uifactory.create.abbr('in', 'inches')];
								}
							},
							{
								name: 'VehicleFitmentWidthMin',
								caption: 'Width',
								format: function (f) {
									return f.VehicleFitmentWidthMin == f.VehicleFitmentWidthMax ?
										[f.VehicleFitmentWidthMin, uifactory.create.abbr('in', 'inches')] :
										[f.VehicleFitmentWidthMin, uifactory.create.abbr('in', 'inches'), ' - ', f.VehicleFitmentWidthMax, uifactory.create.abbr('in', 'inches')];
								}
							},
							{
								name: 'VehicleFitmentPosition',
								caption: 'Position',
								format: function (f) {
									return rstools.constants.VehicleFitmentPositionType[f.VehicleFitmentPosition];
								},
								sort: false
							},
                            {
                                name: 'VehicleFitmentNotes',
                                caption: 'Notes',
                                colClass: 'text-center',
                                sort: false,
                                columnRenderer: rstools.notes.createColumnRenderer('vehicle.fitment'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
                            },
							{ name: 'actions', caption: '', width: 155, sort: false }
						],
						rowCallback: function ($tr, makeDescription) {
							if (makeDescription.VehicleMakeArchived) $tr.addClass('archived-data-object');
						},
						headerActions: [
							rstools.shared.datagrid.headerActions.manageColumns
						],
						getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
						getAction: 'vehicle/GetFitments',
						countAction: 'vehicle/CountFitments',
						resultListField: 'Fitments',
						baseQueryData: {
							TireOptionRequirement: 0,
							FitmentRequirement: 0
						},
						search: filters,
						namespace: 'vehicle-fitment'
					});

				grid.body.addClass('rows-clickable').on('click', 'tr', function () {
					var action = grid.action.getByID('list');

					if (action) {
						var index = $(this).data('row');
						var model = grid.page.getObjectAtIndex(index);

						action.callback([model]);
					}
				});

                var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

                $backToConfigurationsButton = $('<a/>', {
                    'class': 'btn btn-info',
                    'append': [
                        uifactory.create.icon('caret-left'),
                        ' Configurations'
                    ],
                    'href': '#!/app/vehicle/configuration'
                }).appendTo($leftButtons);

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .appendTo($leftButtons)
					.trigger('filters-updated', [Object.keys(filters).length]);

				var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

				// rstools.user.isSystemAdmin() && $('<button/>', {
				//  'class': 'btn btn-primary pull-right',
				//  'append': [
				//      'Create Model &nbsp;',
				//      uifactory.create.icon('plus')
				//  ],
				//  'appendTo': $rightButtons,
				//  'click': function() {
				//      rstools.vehicle.model.create();
				//  }
				// });
			},
			viewLoaded: function (ctx) {
				// Update filters from the current ctx
				// or grab them from the original context
				filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

                rstools.utils.setBackButtonTargetFromContext($backToConfigurationsButton, ctx, '/app/vehicle/configuration');

				$filterForm.syncObjectToForm(filters);
			}
		};

		function filtersUpdated() {
			$filterForm && $filterForm.syncFormToObject(filters);
			$filterButton && $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

			grid.search(filters);
			rstools.state.set('filters', filters);
		}
	});
})(jQuery);