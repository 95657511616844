(function($) {
    function createModelForm(type, settings) {
        var url;

        settings = $.extend({
            isMultiple: false,
            actions: undefined,
            data: {},
            filters: {},
            submitSuccessCallback: undefined,
            onCancel: undefined,
            onLoad: undefined,
            onLoadFailed: undefined
        }, settings);

        var data = settings.data;
        var s = settings.isMultiple ? 's' : '';

        switch (type) {
            case "edit":
                url = 'Wheel/EditModel';
                break;
            case "create":
                url = 'Wheel/CreateModel';
                break;
        }

        if (typeof url === 'undefined')
            throw new Error("Could not detect which URL to post model data to");

        var fields = $.map([
            {
                label: 'Name',
                name: 'WheelModelName',
                maxLength: 128,
                helpText: 'The name of the wheel model' + s,
                value: data.WheelModelName,
                required: !settings.isMultiple,
                hasMultipleValues: 'WheelModelName' in data === false
            },
            {
                label: 'Brand',
                name: 'WheelModel_WheelBrandID',
                searchInputName: 'WheelBrandName',

                type: 'search',
                required: !settings.isMultiple,

                url: 'Wheel/GetBrandDescriptions',
                responseKey: 'Brands',
                idColumnName: 'WheelBrandID',
                requestData: {
                    SelectBrandsOnly: false,
                    UsePromotionOrder: false,
                    HasFitments: false
                },
                resultMap: function (wheelBrand) {
                    return {
                        id: wheelBrand.WheelBrandID,
                        name: wheelBrand.WheelBrandName + ' (' + wheelBrand.DataSourceCode + ')'
                    };
                },
                applySearch: function (search, request) {
                    request.Search = '[WheelBrandName[Like]%' + search + '%]';
                },
                value: data.WheelBrandID ? 
                    data.WheelBrandName ? 
                        {
                            id: data.WheelBrandID,
                            name: data.WheelBrandName + ' (' + data.DataSourceCode + ')'
                        } : {
                            id: data.WheelBrandID
                        } :
                    undefined,
                hasMultipleValues: 'WheelBrandID' in data === false
            },
            {
                label: 'Finish',
                name: 'WheelModelFinishDescription',
                id: 'wheel-model-finish-description-input',
                maxLength: 128,
                helpText: 'The finish of the wheel model' + s,
                value: data.WheelModelFinishDescription,
                required: !settings.isMultiple,
                hasMultipleValues: 'WheelModelFinishDescription' in data === false
            },
            {
                label: 'Finish Categories',
                name: 'WheelModelFinishCategories',
                id: 'wheel-model-finish-categories-input',
                type: 'suggest',
                helpText: 'Categories that are used to search for the model by finish',
                multiple: true,
                magicSuggest: {
                    data: settings.FinishCategories || ridestyler.ajax.url('Wheel/GetFinishes', {
                        SelectBrandsOnly: false
                    }),
                    resultsField: 'Finishes',
                    valueField: 'WheelFinishCategoryID',
                    displayField: 'WheelFinishCategoryName',
                    maxSelection: null,
                    value: data.WheelModelFinishCategories
                },
                required: false,
                hasMultipleValues: 'WheelModelFinishCategories' in data === false
            },
            {
                label: 'Attributes',
                name: 'WheelModelAttributes',
                type: 'suggest',
                helpText: 'Attach attributes to this wheel model',
                multiple: true,
                magicSuggest: {
                    data: rstools.wheel.settings.attributes,
                    maxSelection: null,
                    value: data.Attributes
                },
                required: false,
                hasMultipleValues: 'Attributes' in data === false
            }
        ], function (field) {
            // Modify fields dynamically here
            if (type === "create") field.hasMultipleValues = false;

            return field;
        });

        var actions = [
            {
                label: type === 'create' ? 'Create' : 'Save',
                icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
                buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
                action: 'submit'
            },
            {
                label: 'Cancel',
                action: settings.onCancel
            }
        ];

        if ($.isArray(settings.actions)) actions = actions.concat(settings.actions);

        var $form = uifactory.create.form({
            url: url,
            submitErrorMessage: 'There was a problem ' + (type === 'create' ? 'creat' : 'edit') + 'ing the wheel model' + s + ': {Message}.',

            submitSuccessCallback: function () {
                uifactory.alert.show({
                    text: "The wheel model" + s + " has been " + (type === 'create' ? 'creat' : 'edit') + "ed."
                });

                if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback();
            },

            fields: fields,
            baseData: settings.filters,

            actions: actions
        });

        var wheelFinishCategories = [];

        loadPaginatedList('Wheel/GetFinishes', undefined, 'Finishes', { SelectBrandsOnly: false }).done(function (finishCategories) {
            wheelFinishCategories = finishCategories;
        }).then(settings.onLoad, settings.onLoadFailed);

        $form.find('#wheel-model-finish-description-input').on('change', function () {
            var value = this.value;
            var $finishCategorySuggest = $('#wheel-model-finish-categories-input', $form);
            var ms = $finishCategorySuggest.parent().parent().data('magicSuggest');

            if (ms.getValue().length) return;

            var wheelFinishCategoryIDs = [];

            for (var k = 0; k < wheelFinishCategories.length; k++) {
                var wheelFinishCategory = wheelFinishCategories[k];
                var name = wheelFinishCategory.WheelFinishCategoryName.split('/');
                var threshold = 0.25;

                for (var i = 0; i < name.length; i++)
                    if (value.score(name[i], 0.1) >= threshold) {
                        wheelFinishCategoryIDs.push(wheelFinishCategory.WheelFinishCategoryID);
                        break;
                    }
            }

            ms.setValue(wheelFinishCategoryIDs);
        });

        return $form;
    }

    var loosenFilters = rstools.wheel.utils.loosenFilters;
    var apiDeferred = rstools.utils.apiDeferred;
    var loadPaginatedList = rstools.utils.loadPaginatedList;
    var findLikeData = rstools.utils.findLikeData;

    var WheelFitmentResourceType = rstools.constants.WheelFitmentResourceType;

    var verifyWheelAdmin = function () { return rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.WHEELS); };

    rstools.wheel.model = {
        edit: function (wheelFilters) {
            if (!verifyWheelAdmin()) return;

            var wheelModelDescriptions = [];
            var wheelFinishLinks = [];

            loosenFilters('model', wheelFilters);

            var wheelModelDescriptionsLoaded = loadPaginatedList('Wheel/GetModelDescriptions', 'Wheel/CountModels', 'Models', wheelFilters).done(function(models) {
                wheelModelDescriptions = models;
            });

            var wheelFinishesLinksLoaded = loadPaginatedList('Wheel/GetFinishLinks', 'Wheel/CountFinishLinks', 'Links', wheelFilters).done(function(links) {
                wheelFinishLinks = links;
            });

            var modal = uifactory.modal.create({
                cancelable: true,
                footer: false,
                header: $('<h4/>')
            });

            modal.$modal.addClass('loading');

            var failCallback = function() {
                modal.hide();
                uifactory.alert.show({ type: 'error', text: 'There was an error loading information about the wheel model(s).' });
            };

            $.when(wheelModelDescriptionsLoaded, wheelFinishesLinksLoaded)
                .done(function() {
                    var isMultiple = wheelModelDescriptions.length > 1;


                    for (var i = 0; i < wheelModelDescriptions.length; i++) {
                        wheelModelDescriptions[i].WheelModelFinishCategories = [];

                        for (var j = 0; j < wheelFinishLinks.length; j++) {
                            if (wheelFinishLinks[j].WheelFinishCategoryLink_WheelModelID === wheelModelDescriptions[i].WheelModelID)
                                wheelModelDescriptions[i].WheelModelFinishCategories.push(wheelFinishLinks[j].WheelFinishCategoryLink_WheelFinishCategoryID);
                        }
                    }

                    modal.settings.header.html(isMultiple ? "Edit " + wheelModelDescriptions.length + " Wheel Models" : "<strong>Edit Wheel Model:</strong> " + wheelModelDescriptions[0].WheelModelName);

                    createModelForm('edit', {
                        isMultiple: isMultiple,
                        data: isMultiple ? findLikeData(wheelModelDescriptions) : wheelModelDescriptions[0],
                        filters: wheelFilters,
                        submitSuccessCallback: function () {
                            rstools.events.dataChanged('wheel-model', 'edit');
                            modal.hide();
                        },
                        onCancel: function() {
                            modal.hide();
                        },
                        onLoad: function() {
                            modal.$modal.removeClass('loading');
                        },
                        onLoadFailed: failCallback
                    }).appendTo(modal.$body);

                    modal.setDisplayID($.map(wheelModelDescriptions, function(m) { return m.WheelModelID; }));
                })
                .fail(failCallback);

            modal.show();
        },
        create: function (modelData) {
            if (!verifyWheelAdmin()) return;

            var modal = uifactory.modal.create({
                cancelable: true,
                footer: false,
                header: 'Create a Wheel Model'
            });

            var appendModelForm = function() {
                createModelForm('create', {
                    data: modelData,
                    submitSuccessCallback: function () {
                        rstools.events.dataChanged('wheel-model', 'create');

                        modal.hide();
                    },
                    onCancel: function () {
                        modal.hide();
                    }
                }).appendTo(modal.$body);
            };

            if ('WheelBrandID' in modelData && !('WheelBrandName' in modelData && 'DataSourceCode' in modelData)) {
                modal.$modal.addClass('loading');

                rstools.utils.apiDeferred({
                    action: 'Wheel/GetBrandDescriptions',
                    data: {
                        WheelBrand: modelData.WheelBrandID,
                        SelectBrandsOnly: false,
                        IncludeArchived: true
                    },
                    responseKey: 'Brands'
                })
                    .done(function (brands) {
                        modal.$modal.removeClass('loading');
                        $.extend(modelData, brands[0]);
                        appendModelForm();
                    })
                    .fail(function() {
                        modal.hide();
                        uifactory.alert.show({ type: 'error', text: 'There was an error loading information about the wheel brand.' });
                    });
            } else appendModelForm();

            modal.show();
        },

        manageFinishes: function (wheelFilters, settings) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.WHEELS)) return;

            settings = $.extend({
                header: undefined
            }, settings);

            var modal = uifactory.modal.create({
                cancelable: true,
                footer: false,
                header: settings.header ? settings.header : $('<h4/>')
            });

            var onError = function(text) {
                modal.hide();
                uifactory.alert.show({ type: 'error', text: text });
            };

            modal.$modal.addClass('loading');

            var modelsCounted = apiDeferred({
                responseKey: 'Count',
                action: 'Wheel/CountModels',
                data: wheelFilters
            });

            var modelInfoLoaded = $.Deferred();
            var allFinishesLoaded = apiDeferred({
                responseKey: 'Finishes',
                action: 'Wheel/GetFinishes',
                data: {
                    SelectBrandsOnly: false
                }
            }).fail(function() {
                onError('There was a problem retrieving a list of all finishes.');
            });
            var finishesLoaded = apiDeferred({
                responseKey: 'Finishes',
                action: 'Wheel/GetFinishes',
                data: $.extend({
                    SelectBrandsOnly: false
                }, wheelFilters)
            }).fail(function() {
                onError('There was a problem retrieving a list of finishes for the specified filters.');
            });

            if (!settings.header) {
                modelsCounted
                    .done(function(count) {
                        if (count < 1)
                            onError("There were no wheel models matching the specified filters.");
                        else if (count == 1) {
                            apiDeferred({
                                    responseKey: 'Models',
                                    action: 'Wheel/GetModels',
                                    data: wheelFilters
                                })
                                .done(function(models) {
                                    if (models && models.length)
                                        modal.settings.header.html("Manage Finishes <small>for " + models[0].WheelModelName + "</small>");
                                    else
                                        modal.settings.header.html("Manage Finishes <small>1 model</small>");
                                })
                                .fail(function() {
                                    modal.settings.header.html("Manage Finishes <small>1 model</small>");
                                })
                                .always(function() {
                                    modelInfoLoaded.resolve();
                                });
                        } else {
                            modal.settings.header.html("Manage Finishes <small>" + count + " models</small>");
                            modelInfoLoaded.resolve();
                        }
                    })
                    .fail(function() {
                        onError("There was an error counting the wheel models.");
                    });
            } else modelInfoLoaded.resolve();

            $.when(allFinishesLoaded, finishesLoaded, modelInfoLoaded)
                .done(function(allFinishes, modelFinishes) {
                    var fields = $.map(allFinishes, function(finish) {
                        var finishLinked = false;

                        for (var i = 0; i < modelFinishes.length; i++)
                            if (modelFinishes[i].WheelFinishCategoryID === finish.WheelFinishCategoryID) {
                                finishLinked = true;
                                break;
                            }

                        return {
                            type: 'checkbox',
                            name: finish.WheelFinishCategoryID,
                            label: finish.WheelFinishCategoryName,
                            checked: finishLinked
                        };
                    });

                    $('<p/>', { text: 'Select the finish categories that apply:', appendTo: modal.$body });

                    var $form = uifactory.create.form({
                        fields: fields,
                        actions: [
                            {
                                label: 'Save',
                                icon: 'fa fa-floppy-o',
                                buttonClass: 'btn btn-primary',
                                action: 'submit'
                            }
                        ],
                        beforeSubmit: function(formData) {
                            var wheelFinishCategoryIDs = [];

                            for (var wheelFinishCategoryID in formData)
                                wheelFinishCategoryIDs.push(wheelFinishCategoryID);

                            $form.addClass('loading');

                            apiDeferred({
                                    action: 'Wheel/SetFinishLinks',
                                    data: $.extend({}, wheelFilters, {
                                        FinishCategories: wheelFinishCategoryIDs
                                    })
                                })
                                .done(function() {
                                    modal.hide();
                                    uifactory.alert.show({
                                        type: 'success',
                                        text: 'The finishes for the model(s) have been updated.'
                                    });
                                    rstools.events.dataChanged('wheel-model', 'finish-links');
                                })
                                .fail(function() {
                                    $form.removeClass("loading");
                                    uifactory.alert.show({
                                        type: 'error',
                                        text: 'There was an error saving the finishes for the model(s).'
                                    });
                                });
                        }
                    }).appendTo(modal.$body);

                    modal.$modal.removeClass('loading');
                });

            modal.show();
        },
        duplicate: function (wheelFilters, settings) {
            if (!verifyWheelAdmin()) return;

            var wheelModelDescriptions = [];

            loosenFilters('model', wheelFilters);

            var wheelModelDescriptionsLoaded = loadPaginatedList('Wheel/GetModelDescriptions', 'Wheel/CountModels', 'Models', wheelFilters).done(function(models) {
                wheelModelDescriptions = models;
            });

            settings = settings || {
            };

            var modal = uifactory.modal.create({
                cancelable: true,
                header: settings.header ? settings.header : $('<h4/>'),
                startLoading: true
            });

            if (!settings.header) wheelModelDescriptionsLoaded.done(function () {
                modal.settings.header.html(
                    wheelModelDescriptions.length > 1 ? 
                        "Duplicate " + wheelModelDescriptions.length + " Wheel Models" : 
                        "<strong>Duplicate Wheel Model:</strong> " + wheelModelDescriptions[0].WheelModelName);
            });

            var $optionSelections = uifactory.create.selectionList({
                options: [
                    {
                        label: 'Include Fitments',
                        value: 'CopyFitments',
                        selected: true
                    },
                    {
                        label: 'Include Promotions',
                        value: 'CopyPromotions',
                        selected: true
                    },
                    {
                        label: 'Include Pricing',
                        value: 'CopyPricing',
                        selected: true
                    },
                    {
                        label: 'Link Images',
                        value: 'CopyFitmentResources',
                        selected: true
                    },
                    {
                        label: 'Link Finish Categories',
                        value: 'CopyFinishCategories',
                        selected: true
                    }
                ]
            }).addClass('collapse');

            $.when(wheelModelDescriptionsLoaded)
                .done(function () {
                    modal.setDisplayID($.map(wheelModelDescriptions, function (model) {
                        return model.WheelModelID;
                    }));

                    modal.$body.append($('<p/>').text('Are you sure you wish to duplicate the following model' + (wheelModelDescriptions.length > 1 ? 's?' : '?')));

                    modal.$body.append(uifactory.create.objectList({
                        objects: wheelModelDescriptions,

                        image: function (model) {
                            var type = false;

                            if (model.HasCatalogImage) type = WheelFitmentResourceType.Catalog;
                            else if (model.HasAngleImage) type = WheelFitmentResourceType.Angled;
                            else if (model.HasSideImage) type = WheelFitmentResourceType.Side;

                            if (!type) return false;

                            return ridestyler.ajax.url('Wheel/Image', {
                                Width: 50,
                                Height: 50,
                                PositionX: 1,
                                PositionY: 1,
                                WheelModel: model.WheelModelID,
                                WheelFitmentResourceType: type
                            });
                        },

                        headers: [
                            'Image',
                            'Brand',
                            'Model',
                            'Finish'
                        ],

                        columns: [
                            function (model) {
                                var small = document.createElement('small');
                                    small.appendChild(document.createTextNode('(' + model.DataSourceCode + ')'));

                                return [model.WheelBrandName, ' ', small];
                            },
                            'WheelModelName',
                            'WheelModelFinishDescription'
                        ]
                    }));

                    var $icon = uifactory.create.icon('plus');

                    modal.$body.append([
                        $('<h5/>', {
                            'class': 'clickable',
                            'click': function () {
                                $optionSelections.collapse('toggle');
                                $icon.toggleClass('fa-plus').toggleClass('fa-minus');
                            }
                        }).append([$icon, ' Options']),
                        $optionSelections
                    ]);

                    modal.$footer.append([
                        $('<button/>', {
                            'class': 'btn btn-default',
                            'append': [
                                'Cancel'
                            ],
                            'click': function () {
                                modal.hide();
                            }
                        }),
                        $('<button/>', {
                            'class': 'btn btn-primary',
                            'append': [
                                uifactory.create.icon('clone'),
                                ' Duplicate'
                            ],
                            'click': function () {
                                modal.$modal.addClass('loading');

                                apiDeferred({
                                    action: 'Wheel/DuplicateModels',
                                    data: $.extend({}, wheelFilters, $optionSelections.data('selectionList').value())
                                })
                                    .done(function () {
                                        rstools.events.dataChanged('wheel-model', 'duplicate');

                                        modal.hide();

                                        uifactory.alert.show({ 
                                            type: 'success', 
                                            text: "The wheel models were duplicated."
                                        });
                                    })
                                    .fail(function () {
                                        modal.$modal.removeClass('loading');
                                        uifactory.alert.show({ 
                                            type: 'error', 
                                            text: "There was an error duplicating the models."
                                        });
                                    });
                            }
                        })
                    ]);

                    modal.$modal.removeClass('loading');
                })
                .fail(function (response) {
                    modal.hide();
                    rstools.api.utils.showResponseError("There was an error loading information about the models to duplicate", response);
                });

            modal.show();
        },
        merge: function (wheelFilters, settings) {
            if (!verifyWheelAdmin()) return;

            var wheelModelDescriptions = [];

            loosenFilters('model', wheelFilters);

            var wheelModelDescriptionsLoaded = loadPaginatedList('Wheel/GetModelDescriptions', 'Wheel/CountModels', 'Models', wheelFilters).done(function(models) {
                wheelModelDescriptions = models;
            });

            settings = settings || {
            };

            var modal = uifactory.modal.create({
                cancelable: true,
                header: settings.header || "Merge Wheel Models",
                startLoading: true
            });

            var selectedModel, otherModels;

            $.when(wheelModelDescriptionsLoaded)
                .done(function () {
                    modal.setDisplayID($.map(wheelModelDescriptions, function (model) {
                        return model.WheelModelID;
                    }));

                    modal.$body.append($('<p/>').text('Are you sure you want to merge the following models into one model? Please select the target model to merge into:'));

                    var $mergeButton = $('<button/>', {
                        'class': 'btn btn-primary',
                        'append': [
                            uifactory.create.icon('code-fork'),
                            ' Merge'
                        ],
                        'disabled': true,
                        'click': function () {
                            modal.$modal.addClass('loading');

                            apiDeferred({
                                action: 'Wheel/MergeModels',
                                data: {
                                    ToWheelModel: selectedModel.WheelModelID,
                                    WheelModels: $.map(otherModels, function (m) {
                                        return m.WheelModelID;
                                    })
                                }
                            })
                                .done(function () {
                                    rstools.events.dataChanged('wheel-model', 'merge');

                                    modal.hide();

                                    uifactory.alert.show({ 
                                        type: 'success', 
                                        text: "The wheel models were merged into " + selectedModel.WheelModelName + ". Old models were archived."
                                    });
                                })
                                .fail(function (response) {
                                    modal.$modal.removeClass('loading');
                                    rstools.api.utils.showResponseError("There was an error merging the models", response);
                                });
                        }
                    });

                    var objectListSettings = {
                        image: function (model) {
                            var type = false;

                            if (model.HasCatalogImage) type = WheelFitmentResourceType.Catalog;
                            else if (model.HasAngleImage) type = WheelFitmentResourceType.Angled;
                            else if (model.HasSideImage) type = WheelFitmentResourceType.Side;

                            if (!type) return false;

                            return ridestyler.ajax.url('Wheel/Image', {
                                Width: 50,
                                Height: 50,
                                PositionX: 1,
                                PositionY: 1,
                                WheelModel: model.WheelModelID,
                                WheelFitmentResourceType: type
                            });
                        },

                        headers: [
                            'Image',
                            'Brand',
                            'Model',
                            'Finish',
                            ''
                        ],

                        columns: [
                            function (model) {
                                var small = document.createElement('small');
                                    small.appendChild(document.createTextNode('(' + model.DataSourceCode + ')'));

                                return [model.WheelBrandName, ' ', small];
                            },
                            function (model) {
                                var a = document.createElement('a');
                                    a.appendChild(document.createTextNode(model.WheelModelName));
                                    a.href = '/#!/app/wheel/model?' + rter.utils.toParamString({
                                        filters: {
                                            WheelModels: [model.WheelModelID]
                                        }
                                    });
                                    a.target = '_blank';
                                    a.className = 'primary-link';

                                return a;
                            },
                            'WheelModelFinishDescription',

                            function (model) {
                                return $('<button/>', {
                                    'class': 'btn btn-default',
                                    'text': 'Select',
                                    'title': 'Select this model as the target model.',
                                    'data-action': 'select-model',
                                    'click': onSelectModel
                                }).data('model', model);
                            }
                        ]
                    };

                    var onSelectModel = function () {
                        var $this = $(this);
                        selectedModel = $this.data('model');
                        otherModels = $.map(wheelModelDescriptions, function (m) {
                            return m !== selectedModel ? m : undefined;
                        });

                        var $oldObjectList = $objectList;
                        
                        $objectList = uifactory.create.objectList($.extend({
                            objects: otherModels
                        }, objectListSettings));

                        $oldObjectList.replaceWith($objectList);

                        $objectList.find('[data-action=select-model]').removeClass('btn-primary').addClass('btn-default');
                        $this.addClass('btn-primary').removeClass('btn-default');
                        $mergeButton.prop('disabled', false);
                        $actionDisplay.html([
                            'The above models will be merged into ',
                            $('<a/>', {
                                text: selectedModel.WheelModelName,
                                href: '/#!/app/wheel/model?' + rter.utils.toParamString({
                                    filters: {
                                        WheelModels: [selectedModel.WheelModelID]
                                    }
                                }),
                                target: '_blank',
                                'class': 'primary-link'
                            }),
                            '.'
                        ]);
                    };

                    var $actionDisplay = $('<p/>');

                    var $objectList = uifactory.create.objectList($.extend({
                        objects: wheelModelDescriptions
                    }, objectListSettings));

                    modal.$body.append($objectList, $actionDisplay);

                    modal.$footer.append([
                        $('<button/>', {
                            'class': 'btn btn-default',
                            'append': [
                                'Cancel'
                            ],
                            'click': function () {
                                modal.hide();
                            }
                        }),
                        $mergeButton
                    ]);

                    modal.$modal.removeClass('loading');
                })
                .fail(function (response) {
                    modal.hide();

                    rstools.api.utils.showResponseError("There was an error loading information about the models to duplicate", response);
                });

            modal.show();
        },

        utils: {
            getTitles: function (rows, filter) {
                return $.map(rows, function (row) {
                    if (filter && !filter(row)) return undefined;
                    return row.WheelModelName + " (<em>" + row.WheelModelFinishDescription + "</em>)";
                });
            },

            getIDs: function (rows, filter) {
                return $.map(rows, function (row) {
                    if (filter && !filter(row)) return undefined;
                    return row.WheelModelID;
                });
            },

            createFilters: function (models, loosen) {
                var filters = {
                    WheelModels: rstools.wheel.model.utils.getIDs(models)
                };

                if (loosen) rstools.wheel.utils.loosenFilters('model', filters);

                return filters;
            },
            isArchived: function(/** @type {WheelModelDescriptionModel} */ model) {
                return model.WheelModelArchived;
            },
            isUnarchived: function (/** @type {WheelModelDescriptionModel} */ model) {
                return !model.WheelModelArchived;
            }
        },

        noun: 'wheel model',
        nounPlural: 'wheel models',
        updateDataStatusAction: 'Wheel/ModifyModelDataStatus',
        dataNamespace: 'wheel-model',

        getAction: 'Wheel/GetModels',
        getActionResponseKey: 'Models',
        countAction: 'Wheel/CountModels',

        archiveAction: 'Wheel/ArchiveModels',
        unarchiveAction: 'Wheel/UnarchiveModels',
        
		getMetaAction: 'Wheel/GetModelMeta',
		setMetaAction: 'Wheel/SetModelMeta',
		clearMetaAction: 'Wheel/ClearModelMeta',
        
        getPromotionStatusAction: 'Promotion/GetWheelFitmentStatus',
        setPromotionStatusAction: 'Promotion/SetWheelFitmentStatus'
    };
})(jQuery);