(function ($) {
    var sessionExpiredTimeout;
    var onSessionExpired = function () {
        var route = rter.url.get();
        var state = route.state;
        var loginState = { p: route.path };

        if (state) {
            for (var k in state) {
                if (state.hasOwnProperty(k)) {
                    loginState.ps = JSON.stringify(state);
                    break;
                }
            }
        }

        rter.routes.go('/login', loginState);
        uifactory.alert.show({text: 'Your session has expired. Please login again.', type: 'error'});
    };

    rstools.auth = function () {
        return {
            initialize: function () {
                rter.routes.filters.add(function (route, state) {
                    var filterResolved = new $.Deferred();

                    rstools.auth.validate(route).then()
                        .done(function () {
                            switch (route.path) {
                                case "/":
                                    rter.routes.go('/app/dashboard');
                                    filterResolved.reject();

                                default:
                                    filterResolved.resolve();
                            }
                        })
                        .fail(function (route, validateData) {
                            if (!validateData.handled) {
                                if (route.path == '/' || route.path.indexOf('/app') === 0) {
                                    var loginState = { p: route.path };

                                    if (state) {
                                        for (var k in state) {
                                            if (state.hasOwnProperty(k)) {
                                                loginState.ps = JSON.stringify(state);
                                                break;
                                            }
                                        }
                                    }

                                    rter.routes.go('/login', loginState);
                                    filterResolved.reject();
                                } else {
                                    filterResolved.resolve();
                                }
                            } else filterResolved.reject();

                            if (validateData.message)
                                uifactory.alert.show({
                                    type: "error",
                                    text: validateData.message,
                                    stack: validateData.stack || 'auth-validate'
                                });
                        });

                    return filterResolved;
                });
            },

            validate: function (route) {
                var d = new $.Deferred();

                ridestyler.ajax.send({
                    action: "Auth/Status",
                    callback: function (response) {
                        var sessionValid = response.Success,
                            
                            validateData = {
                                validateResponse: response,
                                sessionValid: sessionValid
                            };

                        if (sessionValid == false) {
                            if (route.path.indexOf('/app') === 0)
                                validateData.message = "You need to be logged in to see that page.";

                            d.reject(route, validateData);
                            return;
                        }

                        if (response.SessionExpiration)
                                rstools.auth.setExpiration(response.SessionExpiration);

                        var user = $.extend({}, rstools.auth.user.get(), {
                            Roles: response.User.Roles,
                            Organization: response.Organization,
                            User: response.User,
                            HasSubscription: response.ActiveSubscription
                        });

                        // Set the user, don't trigger event because later call will
                        rstools.auth.user.set(user, response.ActiveSubscription);
                        
                        if (response.ActiveSubscription == false) {
                            var routeValid = rstools.auth.validateRoute(route);

                            if (!routeValid.valid) {
                                validateData.stack = "auth-invalid-route";
                                validateData.message = "You cannot see that page: " + routeValid.reason + '.';
                                validateData.handled = true;

                                rter.routes.go('/app/account/subscription');

                                d.reject(route, validateData);
                            } else d.resolve(route, validateData);
                        } else {
                            // Get extra objects from RS using the API
                            var getDataShard = rstools.auth.user.guaranteeFromAPI("DataShard/CurrentShard", "DataShard", "Shard", true),
                                getSubscription = rstools.auth.user.guaranteeFromAPI("Subscription/Current", "Subscription", "Subscription", true);

                            $.when(getDataShard, getSubscription)
                                .done(function () {
                                    // Trigger userchanged because we didn't when we got the extra objects
                                    rstools.events.trigger('userchanged');

                                    var routeValid = rstools.auth.validateRoute(route);

                                    if (!routeValid.valid) {
                                        validateData.stack = "auth-invalid-route";
                                        validateData.message = "You cannot see that page: " + routeValid.reason + '.';
                                        validateData.handled = true;

                                        rter.routes.go('/app/dashboard');

                                        d.reject(route, validateData);
                                    } else d.resolve(route, validateData);
                                })
                                .fail(function () {
                                    validateData.message = 'There was a problem retrieving your current data shard.';
                                    d.reject(route, validateData);
                                });
                        }
                    }
                });

                return d;
            },

            start: function (data) {
                var d = new $.Deferred();

                ridestyler.auth.start({
                    data: data,
                    callback: function (response) {
                        var sessionValid = response.Success,

                            startData = {
                                startResponse: response,
                                sessionValid: sessionValid
                            };

                        if (sessionValid) {
                            var user = {
                                Roles: response.Roles
                            };

                            // Set the user, don't trigger event because later call will
                            rstools.auth.user.set(user, true);

                            rstools.auth.user.updateFromAPI("DataShard/CurrentShard", "DataShard", "Shard")
                                .done(function () {
                                    d.resolve(startData);
                                })
                                .fail(function () {
                                    startData.message = 'There was a problem retrieving your current data shard.';
                                    d.reject(startData);
                                });
                        } else {
                            startData.message = 'Please verify your username and password and try again.';
                            d.reject(startData);
                        }
                    }
                });

                return d;
            },

            validateRoute: function (route) {
                var hasSubscription = !!rstools.auth.user.get('HasSubscription');
                var requireSubscription = true;

                // Go through the route and check the required roles of it and all of
                // its parents
                while (route) {
                    var requiredRoles = route.requiredRoles;

                    if (typeof requiredRoles !== 'undefined') {
                        requiredRoles = $.makeArray(requiredRoles);

                        for (var i = requiredRoles.length - 1; i >= 0; i--) {
                            if (!rstools.auth.user.hasRole(requiredRoles[i])) {
                                return {
                                    valid: false,
                                    reason: "You're missing the " + requiredRoles[i] + " role"
                                };
                            }
                        }
                    }

                    if (route.requireSubscription === false)
                        requireSubscription = false;
                    else if (route.requireSubscription === true && !hasSubscription)
                        return {
                            valid: false,
                            reason: 'You need a subscription'
                        };

                    route = route.parent;
                }

                if (requireSubscription && !hasSubscription)
                    return {
                        valid: false,
                        reason: 'You need a subscription'
                    };

                return {
                    valid: true
                };
            },

            user: (function () {
                var user = false;

                return {
                    get: function (k) {
                        if (typeof k === 'undefined')
                            return user;

                        return user[k];
                    },
                    set: function (newUser, quiet) {
                        user = typeof newUser === 'object' ? newUser : false;

                        if (!quiet)
                            rstools.events.trigger('userchanged');

                        return user;
                    },
                    update: function (k, v, quiet) {
                        if (user)
                            user[k] = v;

                        if (!quiet)
                            rstools.events.trigger('userchanged');
                    },


                    // Updates a property on the current user (userKey) with a API
                    // call to RS using action. responseKey is the key that is used
                    // to grab data from the response to update the user with; by
                    // default it's userKey.
                    updateFromAPI: function (action, userKey, responseKey, quiet) {
                        var d = new $.Deferred();

                        if (!user) return d.reject();

                        ridestyler.ajax.send({
                            action: action, callback: function (response) {
                                if (response.Success) {
                                    responseKey = responseKey || userKey;
                                    user[userKey] = response[responseKey];

                                    if (!quiet)
                                        rstools.events.trigger('userchanged');

                                    d.resolve();
                                } else d.reject();
                            }
                        });

                        return d;
                    },

                    // Same as updateFromAPI, but resolves if the key already exists in the user
                    guaranteeFromAPI: function (action, userKey, responseKey, quiet) {
                        if (!user) return new $.Deferred().reject();
                        if (typeof user[userKey] !== 'undefined') return new $.Deferred().resolve();
                        return rstools.auth.user.updateFromAPI(action, userKey, responseKey, quiet);
                    },

                    hasRole: function (role) {
                        if (!user) return false;

                        // If is array make sure user has access to all specified roles
                        if ($.isArray(role) || arguments.length > 1) {
                            var roles = arguments.length > 1 ? arguments : role;

                            for (var i = 0; i < roles.length; i++)
                                if (!rstools.auth.user.hasRole(roles[i])) return false;
                            return true;
                        }

                        return user.Roles.indexOf(role) >= 0;
                    },

                    verifyRole: function(role) {
                        var hasRole = rstools.auth.user.hasRole.apply(this, arguments);
                        
                        if (!hasRole) {
                            var roles = $.isArray(role) ? role : arguments;
                            var s = roles.length > 1 ? 's' : '';

                            uifactory.alert.show({
                                type: 'error',
                                text: 'You\'re missing' + (roles.length > 1 ? ' one of' : '') + ' the following role' + s + ': ' + Array.prototype.join.call(roles, ', ')
                            });
                        }

                        return hasRole;
                    },

                    getOrganization: function () { return user && user.Organization ? user.Organization : undefined; },
                    getOrganizationID: function () { return user && user.Organization ? user.Organization.OrganizationID : undefined; },
                    getActiveOrganizationID: function () {
                        if (!user) return false;
                        return user.DataShard && user.DataShard.DataShard_OrganizationID || this.getOrganizationID();
                    },
                    getOrganizationName: function () { return user && user.Organization ? user.Organization.OrganizationName : undefined; }
                };
            })(),

            setExpiration: function (expiration) {
                expiration = rstools.utils.momentFromDatabaseDateTime(expiration).diff();

                if (sessionExpiredTimeout) clearTimeout(sessionExpiredTimeout);

                // If the session isn't already expired give it 5 extra seconds before refreshing
                if (expiration >= 0) expiration += 5000;

                sessionExpiredTimeout = setTimeout(onSessionExpired, expiration);
            }
        };
    }();

    rstools.auth.initialize();
})(jQuery);