(function ($) {
    rter.mapController('app.organization.shard', function (context) {
        var grid = null;

        return {
            actions: {
                search: function () {
                    var term = $(this).find('input.search-input').val();
                    grid.search(term);

                    if (term) {
                        rstools.state.set('search', term);
                    } else {
                        rstools.state.unset('search');
                    }
                }
            },
            viewCreated: function ($outlet) {
                var term = rstools.state.get('search');
                grid =
                    datagrid.create({
                        container: $outlet.find('div.data-wrapper'),
                        fields: [
                            { name: 'Organization.OrganizationName', caption: 'Organization' },
                            { name: 'DataShardDescription', caption: 'Description' },
                            {
                                name: 'usershard',
                                caption: '',
                                format: function (r) {
                                    if (r.IsSessionUserDefault)
                                        return $('<i class="fa fa-check-circle" data-toggle="tooltip" data-placement="top" title="Your Current Shard" />').tooltip();

                                    return '';
                                },
                                sort: false
                            },
                            {
                                name: 'orgdefault',
                                caption: '',
                                format: function (r) {
                                    if (r.DataShardDefault)
                                        return $('<i class="fa fa-bookmark" data-toggle="tooltip" data-placement="top" title="Organization Default" />').tooltip();

                                    return '';
                                },
                                sort: false
                            },
                            { name: 'actions', caption: '', width: '120px', sort: false },
                            {
                                name: 'menu', caption: '', width: '75px', sort: false,
                                format: function (r) {
                                    var options = [];

                                    if (r.IsSessionUserDefault == false) {
                                        options.push({
                                            text: 'Set as your active shard',
                                            action: function () {
                                                ridestyler.ajax.send({
                                                    action: 'DataShard/ActivateShard',
                                                    data: r,
                                                    callback: function (response) {
                                                        if (response.Success) {
                                                            uifactory.alert.show({ text: 'Your shard has been changed!', stack: 'user_shard_activated' });
                                                            grid.refresh();
                                                            rstools.auth.user.update('DataShard', r);
                                                        }
                                                    }
                                                });
                                            }
                                        });
                                    }

                                    var $button = uifactory.create.button(
                                        {
                                            content: $('<i class="fa fa-cog"/>'),
                                            options: options,
                                            disabled: (options.length == 0),
                                            rightMenu: true
                                        });

                                    return $button;
                                }
                            }
                        ],
                        actions: [
                            /*{
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    alert('TODO: Go to edit interface.');
                                }
                            },
                            {
                                title: 'Delete',
                                icon: 'fa fa-trash-o',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    alert('TODO: Go to delete interface.');
                                }
                            }*/
                        ],
                        getAction: 'DataShard/GetDataShardDescriptions',
                        countAction: 'DataShard/CountDataShardDescriptions',
                        resultListField: 'Shards',
                        search: term || null
                    });

                $outlet.find('input.search-input').val(term);
            }
        };
    });
})(jQuery);