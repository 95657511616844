(function ($) {
    rter.mapController('app.tire.brand', function (context) {
        var grid = null;
        var filters = {};
        var $filterForm, $filterButton;

        var createCombinedFilters = function (rows, forAPI, loosenFilters) {
            return rstools.utils.getFiltersFor('tire.brand', {
                rows: rows,
                forAPI: forAPI,
                loosenFilters: loosenFilters,
                filters: filters
            });
        };

        return {
            viewWillLoad: function (ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($outlet) {
                grid =
                    datagrid.create({
                        container: $outlet.find('div.data-wrapper'),
                        allowShowAll: true,


                        headerActionColumn: 'include',
                        headerActions: [
                            rstools.shared.datagrid.headerActions.manageColumns
                        ],
                        getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
                        
                        fields: [
                            {
								name: 'TireBrandLogo',
								caption: 'Logo',
								colClass: 'text-center',
								sort: false,
								columnRenderer: rstools.shared.columns.createResourceColumn('tire.brand', {
									baseFilters: {
										UsePromotionOrder: false,
										HasFitments: false,
										SelectBrandsOnly: false
									},
                                    
									width: 100,
									height: 55,

                                    noImageElement: 'No Logo',

									onImageLoaded: function ($image, brand) {
										$image.wrap($('<a/>', {
											href: '#',
											title: 'Manage Logo',
											click: function () {
												rstools.tire.brand.manageResources(createCombinedFilters([brand], true), {
													header: rstools.tire.brand.utils.getTitles([brand], {
                                                        html: true
                                                    })
												});

												return false;
											}
										}));
									}
								})
							},
                            { name: 'TireBrandName', caption: 'Brand' },
                            { name: 'DataSourceCode', caption: 'Source', sort: false },
                            rstools.shared.columns.createLastUpdatedColumn('TireBrandUpdated'),
                            {
                                name: 'TireBrandNotes',
                                caption: 'Notes',
                                colClass: 'text-center',
                                sort: false,
                                columnRenderer: rstools.notes.createColumnRenderer('tire.brand'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
                            },
                            { name: 'actions', caption: '', width: '160px', sort: false },
                            {
                                name: 'include',
                                caption: '',
                                allowToBeHidden: false,
                                width: '100px',
                                format: function (r) {
                                    return uifactory.create.brandInclusionButtons({
                                        brand: r,
                                        objectType: 'tire-brand',
                                        action: 'Tire/ChangeBrandInclusion'
                                    });
                                },
                                sort: false
                            }
                        ],
                        actions: [
                            {
								title: 'Manage Promotions',
								icon: 'fa fa-tags',
								multiple: true,
								fields: ['actions'],
								requiredRoles: [rstools.roles.TIRES],
								callback: function (rows) {
                                    rstools.promotion.manage('tire.brand', createCombinedFilters(rows, true));
								}
							},
                            {
                                title: 'List Models',
                                icon: 'fa fa-th-list',
                                id: 'list',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rter.routes.go('/app/tire/model', { 
                                        filters: createCombinedFilters(rows)
                                    });
                                }
                            },
                            {
								title: 'Edit',
								requiredRoles: [rstools.roles.SYSTEM_ADMIN],
								forMenu: true,
								icon: 'fa fa-pencil',
								multiple: true,
								fields: ['actions'],
								callback: function (rows) {
									rstools.tire.brand.edit(createCombinedFilters(rows), true);
								}
							},
                            {
                                title: 'Enable Brands',
                                icon: 'fa fa-check',
                                multiple: true,
                                fields: [],
                                requiredRoles: [rstools.roles.TIRES],
                                callback: function (rows) {
                                    ridestyler.ajax.send({
                                        action: 'Tire/ChangeBrandInclusion',
                                        data: {
                                            Objects: rows,
                                            Status: 1
                                        },
                                        callback: function (response) {
                                            if (response.Success) {
                                                for (var i = 0; i < rows.length; i++)
                                                    rows.InclusionStatus = 1;

                                                grid.refresh();
                                            }
                                        }
                                    });
                                }
                            },
                            rstools.shared.actions.createArchiveAction("tire.brand", {
								defaultFilters: rstools.tire.utils.loosenFilters('brands')
							}),
                            rstools.shared.actions.createUnarchiveAction("tire.brand", {
								defaultFilters: rstools.tire.utils.loosenFilters('brands')
							}),
							{
								title: 'Images',
								icon: 'fa fa-picture-o',
								multiple: true,
								fields: ['actions'],
								callback: function (rows) {
									var modalSettings = {};

									if (rows.length === 1)
										modalSettings.header = '<strong>' + (rstools.user.isSystemAdmin() ? 'Manage' : 'View') + 
																' Brand Resources</strong>: ' + rstools.tire.brand.utils.getTitles(rows[0]);

									rstools.tire.brand.manageResources(createCombinedFilters(rows, true), modalSettings);
								}
							},
                            rstools.meta.actions.edit('tire.brand'),
                        ],
						rowCallback: function ($tr, brandDescription) {
							if (brandDescription.TireBrandArchived) $tr.addClass('archived-data-object');
						},
                        getAction: 'Tire/GetBrandDescriptions',
                        countAction: 'Tire/CountBrandDescriptions',
                        resultListField: 'Brands',
                        baseQueryData: {
                            SelectBrandsOnly: false,
                        },
                        search: rstools.tire.utils.filterFormDataToTireFilters(filters),
                        namespace: 'tire-brand',
                        dataChangedExceptions: ['inclusion-changed'],
                        rowSelect: function (brand) {
                            grid.action.getByID('list').callback([brand]);
                        }
                    });

                $filterForm = rstools.tire.utils.createTireFilterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .prependTo(grid.topControls)
                    .trigger('filters-updated', [Object.keys(filters).length]);

                    

				var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

				rstools.user.isSystemAdmin() && $('<button/>', {
					'class': 'btn btn-primary pull-right',
					'append': [
						'Create Brand &nbsp;',
						uifactory.create.icon('plus')
					],
					'appendTo': $rightButtons,
					'click': function() {
						rstools.tire.brand.create();
					}
				});
            },
            viewLoaded: function (ctx) {
                // Update filters from the current ctx
                // or grab them from the original context
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

                if ($filterForm)
                    $filterForm.syncObjectToForm(filters);
            }
        };

        function filtersUpdated() {
            $filterForm.syncFormToObject(filters);
            $filterButton.trigger('filters-updated', [Object.keys(filters).length]);
            
			grid.search(rstools.tire.utils.filterFormDataToTireFilters(filters));
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);