(function ($) {
	rter.mapController('app.vehicle.make', function (context) {
		var grid = null;
		var $filterForm, $filterButton;

		var filters = {};

		return {
			viewWillLoad: function(ctx) {
				filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
			},
			viewCreated: function ($view) {
				$filterForm = rstools.vehicle.utils.createVehicleFilterForm(context, {
					onReset: filtersUpdated,
					onSubmit: filtersUpdated,
					filters: filters
				});

				grid =
					datagrid.create({
						container: $view.find('div.data-wrapper'),
						allowShowAll: true,
						fields: [
							{ name: 'VehicleMakeName', caption: 'Make' },
                            {
                                name: 'VehicleMakeNotes',
                                caption: 'Notes',
                                colClass: 'text-center',
                                sort: false,
                                columnRenderer: rstools.notes.createColumnRenderer('vehicle.make'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
                            },
							{ name: 'actions', caption: '', width: 75, sort: false }
						],
						rowCallback: function ($tr, makeDescription) {
							if (makeDescription.VehicleMakeArchived) $tr.addClass('archived-data-object');
						},
						headerActions: [
							rstools.shared.datagrid.headerActions.manageColumns
						],
						actions: [
							{
                                title: 'List Models',
                                icon: 'fa fa-th-list',
                                id: 'list',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    var filters = {
                                        VehicleMakes: []
                                    };

                                    for (var i = 0; i < rows.length; i++)
                                        filters.VehicleMakes.push(rows[i].VehicleMakeID);

                                    rter.routes.go('/app/vehicle/model', { filters: filters });
                                }
                            }
						],
						getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
						getAction: 'vehicle/GetMakes',
						countAction: 'vehicle/CountMakes',
						resultListField: 'Makes',
						baseQueryData: {
							TireOptionRequirement: 0,
							FitmentRequirement: 0
						},
						search: filters,
						namespace: 'vehicle-make',
						rowSelect: function (make) {
							grid.action.getByID('list').callback([make]);
						}
					});

				$filterButton = uifactory.create.filterButton({
					menu: $filterForm
				})
					.prependTo(grid.topControls)
					.trigger('filters-updated', [Object.keys(filters).length]);

				var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

				// rstools.user.isSystemAdmin() && $('<button/>', {
				// 	'class': 'btn btn-primary pull-right',
				// 	'append': [
				// 		'Create Make &nbsp;',
				// 		uifactory.create.icon('plus')
				// 	],
				// 	'appendTo': $rightButtons,
				// 	'click': function() {
				// 		rstools.vehicle.make.create();
				// 	}
				// });
			},
			viewLoaded: function (ctx) {
				// Update filters from the current ctx
				// or grab them from the original context
				filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

				$filterForm.syncObjectToForm(filters);
			}
		};

		function filtersUpdated() {
			$filterForm && $filterForm.syncFormToObject(filters);
			$filterButton && $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

			grid.search(filters);
			rstools.state.set('filters', filters);
		}
	});
})(jQuery);