(function ($) {
    function createFitmentForm(type, settings) {
        var url;


        settings = $.extend({
            isMultiple: false,
            data: {},
            filters: {},
            submitSuccessCallback: undefined,
            onCancel: undefined
        }, settings);

        var data = settings.data;
        var s = settings.isMultiple ? 's' : '';

        switch (type) {
            case "edit":
                url = 'Wheel/EditFitment';
                break;
            case "create":
                url = 'Wheel/CreateFitment';
                break;
        }

        var fields = $.map([
            {
                label: 'Brand',
                type: 'static',
                text: $.map(settings.models, function(model) {
                    return model.WheelBrandName + ' (' + model.DataSourceDescription + ')';
                }).unique().join(', ')
            },
            {
                label: 'Model',
                name: 'WheelFitment_WheelModelID',
                value: data.WheelFitment_WheelModelID,
                type: 'static',
                text: $.map(settings.models, function(model) {
                    return model.WheelModelName;
                }).unique().join(', ')
            },
            {
                label: 'Bolt Pattern',
                name: 'BoltPatterns',

                type: 'dropdown',
                dataType: 'array',
                required: !settings.isMultiple,
                multiple: true,
                clearWhenFalse: true,

                url: 'BoltPattern/GetBoltPatterns',
                responseKey: 'BoltPatterns',
                idColumnName: 'BoltPatternID',
                requestData: {},
                resultMap: function (boltPattern) {
                    return {
                        value: boltPattern.BoltPatternID,
                        label: boltPattern.BoltPatternDescription
                    };
                },
                initialOptions: [
                    { 
                        value: false,
                        label: 'Select Bolt Patterns'
                    },
                    '-'
                ],
                value: data.BoltPatterns,
                hasMultipleValues: 'BoltPatterns' in data === false
            },
            {
                label: 'Max Weight',
                type: 'number',
                name: 'WheelFitmentMaxWeight',
                maxLength: 10,
                min: -1,
                max: 10000,
                value: data.WheelFitmentMaxWeight,
                required: !settings.isMultiple,
                hasMultipleValues: 'WheelFitmentMaxWeight' in data === false
            },
            {
                label: 'Diameter',
                type: 'range',
                minFieldName: 'WheelFitmentDiameterMin',
                maxFieldName: 'WheelFitmentDiameterMax',
                min: 10,
                max: 40,
                value: [data.WheelFitmentDiameterMin, data.WheelFitmentDiameterMax],
                required: !settings.isMultiple,
                hasMultipleValues: 'WheelFitmentDiameterMin' in data === false
            },
            {
                label: 'Width',
                type: 'range',
                minFieldName: 'WheelFitmentWidthMin',
                maxFieldName: 'WheelFitmentWidthMax',
                min: 3,
                max: 100,
                value: [data.WheelFitmentWidthMin, data.WheelFitmentWidthMax],
                required: !settings.isMultiple,
                hasMultipleValues: 'WheelFitmentWidthMin' in data === false
            },
            {
                label: 'Offset',
                type: 'range',
                minFieldName: 'WheelFitmentOffsetMin',
                maxFieldName: 'WheelFitmentOffsetMax',
                min: -250,
                max: 160,
                value: [data.WheelFitmentOffsetMin, data.WheelFitmentOffsetMax],
                required: !settings.isMultiple,
                hasMultipleValues: 'WheelFitmentOffsetMin' in data === false
            },
            {
                label: 'Centerbore (MM)',
                type: 'number',
                name: 'WheelFitmentCenterboreMM',
                maxLength: 10,
                min: 1,
                max: 5000,
                value: data.WheelFitmentCenterboreMM,
                required: false,
                hasMultipleValues: 'WheelFitmentCenterboreMM' in data === false
            },
            {
                label: 'Center Cap',
                name: 'WheelFitment_WheelCenterCapTypeID',
                type: 'dropdown',
                required: true,
                options: [{ 'label': 'Center Cap', value: undefined }, '-'].concat(rstools.utils.dropdownOptionsFromObject(rstools.constants.WheelCenterCapType)),
                value: data.WheelFitment_WheelCenterCapTypeID,
                hasMultipleValues: 'WheelFitment_WheelCenterCapTypeID' in data === false
            },
            {
                label: 'Wheel Hole Type',
                name: 'WheelFitment_WheelHoleTypeID',
                searchInputName: 'WheelHoleTypeName',
                type: 'dropdown',
                required: true,
                options: [{ 'label': 'Wheel Hole Type', value: undefined }, '-'].concat(rstools.utils.dropdownOptionsFromObject(rstools.constants.WheelHoleType)),
                value: data.WheelFitment_WheelHoleTypeID,
                hasMultipleValues: 'WheelFitment_WheelHoleTypeID' in data === false
            },
            {
                label: 'Part Number',
                type: 'text',
                name: 'WheelFitmentPartNumber',
                maxLength: 50,
                value: data.WheelFitmentPartNumber,
                hasMultipleValues: 'WheelFitmentPartNumber' in data === false,
                deferredValidate: true,
                validate: function(data) {
                    var partNumber = $.trim(data.WheelFitmentPartNumber);
                    var deferred = $.Deferred();

                    if (!partNumber) {
                        var confirm = uifactory.modal.create({
                            type: 'confirmation',
                            cancelable: true,
                            minimizable: false,
                            header: 'Empty Part Number',
                            confirmationYes: type === 'create' ? 'Create' : 'Save',
                            confirmationYesClass: 'btn btn-warning',
                            body: "You're trying to " + (type === 'create' ? 'create a wheel fitment with ' : 'edit a wheel fitment to have ') +
                                'no part number. Are you sure you wish to do this?',
                            confirmationCallback: function(saidYes) {
                                if (saidYes)
                                    deferred.resolve();
                                else
                                    deferred.reject('Are you sure you don\'t want this wheel fitment to have a part number?');

                                confirm.hide();
                            }
                        });

                        confirm.show();
                    } else {
                        rstools.utils.apiDeferred({
                            action: 'Wheel/GetFitments',
                            data: {
                                ApplyGlobalFilters: false,
                                SelectBrandsOnly: false,
                                PartNumber: partNumber
                            },
                            responseKey: 'Fitments'
                        })
                        .done(function(fitments) {
                            var otherPartNumberCount = fitments.length;

                            if (type === 'edit') {
                                if ('WheelFitments' in data) {
                                    // Remove specified ID numbers from the count
                                    for (var i = 0; i < data.WheelFitments.length; i++) {
                                        for (var j = 0; j < fitments.length; j++) {
                                            if (fitments[j].WheelFitmentID === data.WheelFitments[i]) {
                                                otherPartNumberCount--;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }

                            // Confirm if the user specifies a part number that already exists
                            if (otherPartNumberCount > 0) {
                                var confirm = uifactory.modal.create({
                                    type: 'confirmation',
                                    cancelable: true,
                                    minimizable: false,
                                    header: 'Part Number Already Exists',
                                    confirmationYes: type === 'create' ? 'Create' : 'Save',
                                    confirmationYesClass: 'btn btn-warning',
                                    body: "You're trying to " + (type === 'create' ? 'create a wheel fitment with ' : 'edit a wheel fitment to have ') +
                                        'a part number that matches ' + otherPartNumberCount + ' other fitments. Are you sure you wish to do this?',
                                    confirmationCallback: function(saidYes) {
                                        if (saidYes)
                                            deferred.resolve();
                                        else
                                            deferred.reject('The part number matched ' + otherPartNumberCount + ' other fitments.');

                                        confirm.hide();
                                    }
                                });

                                confirm.show();
                            } else {
                                deferred.resolve();
                            }
                        })
                        .fail(function() {
                            deferred.reject('Unable to verify part number.');
                        });
                    }

                    return deferred;
                }
            }
        ], function (field) {
            // Modify fields dynamically here
            if (type === "create") field.hasMultipleValues = false;

            return field;
        });

        var actions = [
            {
                label: type === 'create' ? 'Create' : 'Save',
                icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
                buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
                action: 'submit'
            },
            {
                label: 'Cancel',
                action: settings.onCancel
            }
        ];

        if (!settings.isMultiple && type !== 'create') {
            actions.push({
                label: 'Images',
                icon: 'fa fa-picture-o',
                buttonClass: 'btn btn-default pull-left',
                action: function () {
                    rstools.wheel.fitment.manageResources({
                        WheelFitment: data.WheelFitmentID
                    });
                }
            });
        }

        return uifactory.create.form({
            url: url,
            submitErrorMessage: 'There was a problem ' + (type === 'create' ? 'creat' : 'edit') + 'ing the wheel fitment' + s + ': {Message}.',

            submitSuccessCallback: function () {
                uifactory.alert.show({
                    text: !settings.isMultiple ? "The wheel fitment has been " + (type === 'create' ? 'creat' : 'edit') + "ed." : "The wheel fitments have been " + type + "ed."
                });

                if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback();
            },

            fields: fields,
            baseData: settings.filters,
            beforeSubmit: function (formData) {
                var partNumberKey = 'WheelFitmentPartNumber';

                if (partNumberKey in data && partNumberKey in formData === false)
                    formData[partNumberKey] = '';
            },
            actions: actions
        });
    }

    var loosenFilters = rstools.wheel.utils.loosenFilters;
    var loadPaginatedList = rstools.utils.loadPaginatedList;
    var findLikeData = rstools.utils.findLikeData;

    var WheelFitmentResourceType = rstools.constants.WheelFitmentResourceType;
    var ResourceAssetType = rstools.constants.ResourceAssetType;

    var wheelFitmentResourceTypeToResourceAssetType = {};
    wheelFitmentResourceTypeToResourceAssetType[WheelFitmentResourceType.Side] = ResourceAssetType.WheelImageSide;
    wheelFitmentResourceTypeToResourceAssetType[WheelFitmentResourceType.Angled] = ResourceAssetType.WheelImageAngled;
    wheelFitmentResourceTypeToResourceAssetType[WheelFitmentResourceType.Catalog] = ResourceAssetType.WheelImageCatalog;

    var verifyWheelAdmin = function () { return rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.WHEELS); };
    var showError = function (text) { return uifactory.alert.show({type: 'error', text: text}); };

    rstools.wheel.fitment = {
        edit: function (wheelFilters) {
            if (!verifyWheelAdmin()) return;

            var wheelFitments = [];
            var wheelModelDescriptions = [];
            var boltPatternLinks = [];

            loosenFilters('fitment', wheelFilters);

            var wheelFitmentsLoaded = loadPaginatedList('Wheel/GetFitments', 'Wheel/CountFitments', 'Fitments', wheelFilters).done(function(fitments) {
                wheelFitments = fitments;
            });

            var wheelModelDescriptionsLoaded = loadPaginatedList('Wheel/GetModelDescriptions', 'Wheel/CountModels', 'Models', wheelFilters).done(function (models) {
                wheelModelDescriptions = models;
            });

            var boltPatternLinksLoaded = loadPaginatedList('Wheel/GetBoltPatternLinks', 'Wheel/CountBoltPatternLinks', 'BoltPatternLinks', wheelFilters).done(function (links) {
                boltPatternLinks = links;
            });

            var modal = uifactory.modal.create({
                cancelable: true,
                footer: false,
                header: $('<h4/>')
            });

            modal.$modal.addClass('loading');

            $.when(wheelFitmentsLoaded, wheelModelDescriptionsLoaded, boltPatternLinksLoaded)
                .done(function() {
                    var isMultiple = wheelFitments.length > 1;

                    modal.$modal.removeClass('loading');

                    modal.settings.header.html(isMultiple ? "Edit " + wheelFitments.length + " Wheel Fitments" : "Edit Wheel Fitment");

                    // Add our bolt patterns into the wheel fitment objects
                    $.each(wheelFitments, function (j, wheelFitment) {
                        var linkedBoltPatternIDs = [];

                        for (var i = boltPatternLinks.length - 1; i >= 0; i--) {
                            var boltPatternLink = boltPatternLinks[i];

                            if (boltPatternLink.WheelBoltPatternLink_WheelFitmentID === wheelFitment.WheelFitmentID)
                                linkedBoltPatternIDs.push(boltPatternLink.WheelBoltPatternLink_BoltPatternID);
                        }

                        wheelFitment.BoltPatterns = linkedBoltPatternIDs;
                    });

                    createFitmentForm('edit', {
                        isMultiple: isMultiple,
                        data: isMultiple ? findLikeData(wheelFitments, ['WheelFitment_WheelModelID']) : wheelFitments[0],
                        models: wheelModelDescriptions,
                        filters: wheelFilters,
                        submitSuccessCallback: function() {
                            rstools.events.dataChanged('wheel-fitment', 'edit');
                            modal.hide();
                        },
                        onCancel: function() {
                            modal.hide();
                        }
                    }).appendTo(modal.$body);

                    modal.setDisplayID($.map(wheelFitments, function(f) { return f.WheelFitmentID; }));
                })
                .fail(function() {
                    modal.hide();
                    uifactory.alert.show({ type: 'error', text: 'There was an error loading information about the wheel fitment(s).' });
                });

            modal.show();
        },
        create: function (fitmentData) {
            if (!verifyWheelAdmin()) return;

            var wheelFilters = {
                WheelModel: fitmentData.WheelFitment_WheelModelID
            };

            var wheelModelDescriptions;

            loosenFilters('fitment', wheelFilters);

            var wheelModelDescriptionLoaded = rstools.utils.apiDeferred({
                action: 'Wheel/GetModelDescriptions',
                data: wheelFilters,
                responseKey: 'Models'
            }).done(function (models) {
                wheelModelDescriptions = models;
            });

            var modal = uifactory.modal.create({
                cancelable: true,
                footer: false,
                header: 'Create a Wheel Fitment'
            });
            

            modal.$modal.addClass('loading');

            $.when(wheelModelDescriptionLoaded)
                .done(function () {

                    modal.$modal.removeClass('loading');

                    createFitmentForm('create', {
                        data: fitmentData,
                        models: wheelModelDescriptions,
                        submitSuccessCallback: function() {
                            rstools.events.dataChanged('wheel-fitment', 'create');
                            modal.hide();
                        },
                        onCancel: function() {
                            modal.hide();
                        }
                    }).appendTo(modal.$body);
                })
                .fail(function () {
                    modal.hide();
                    uifactory.alert.show({ type: 'error', text: 'There was an error loading information about the wheel model to create the fitment under.' });
                });

            modal.show();
        },
        manageResources: function(wheelFitmentResourceFilters, settings) {
            var filters = wheelFitmentResourceFilters;

            settings = $.extend({
                header: (rstools.user.isSystemAdmin() ? 'Manage' : 'View') + ' Wheel Fitment Resources'
            }, settings);

            if (!filters) throw new TypeError("You must pass in filters to rstools.wheel.fitment.manageResources.");

            loosenFilters('fitmentResources', filters);

            var modal = uifactory.modal.create({
                header: settings.header,
                footer: false,
                cancelable: true
            });

            var $row = $('<div/>', { 'class': 'row' }).appendTo(modal.$body);

            var $columns = {
                side: $('<div/>', { 'class': 'col-sm-4' }).appendTo($row),
                angled: $('<div/>', { 'class': 'col-sm-4' }).appendTo($row),
                catalog: $('<div/>', { 'class': 'col-sm-4' }).appendTo($row)
            };

            var wheelFitmentResources;

            function loadResources() {
                loadPaginatedList('Wheel/GetFitmentResources', 'Wheel/CountFitmentResources', 'Resources', filters)
                    .done(function(fitmentResources) {
                        wheelFitmentResources = {
                            side: [],
                            angled: [],
                            catalog: []
                        };

                        for (var i = 0; i < fitmentResources.length; i++)
                            switch (fitmentResources[i].WheelFitmentResourceType) {
                            case WheelFitmentResourceType.Angled:
                                wheelFitmentResources.angled.push(fitmentResources[i]);
                                break;

                            case WheelFitmentResourceType.Side:
                                wheelFitmentResources.side.push(fitmentResources[i]);
                                break;

                            case WheelFitmentResourceType.Catalog:
                                wheelFitmentResources.catalog.push(fitmentResources[i]);
                                break;
                            }

                        function mapWheelFitmentResourceID(fitmentResource) { return fitmentResource.WheelFitmentResource_ResourceID; }

                        function createResourceThumbnail(title) {
                            var key = title.toLowerCase();
                            var resources = wheelFitmentResources[key];
                            var wheelFitmentResourceType = WheelFitmentResourceType[title];
                            var resourceAssetType = wheelFitmentResourceTypeToResourceAssetType[wheelFitmentResourceType];

                            return uifactory.create.resourceThumbnail({
                                resources: $.map(resources, mapWheelFitmentResourceID).unique(),
                                title: title,
                                imageParameters: {
                                    Width: 200,
                                    Height: 200,
                                    PositionX: 1,
                                    PositionY: 1
                                },
                                onLink: function() {
                                    rstools.resource.list({
                                        mode: 'select',
                                        onSelect: function(resource) {
                                            modal.$modal.addClass('loading');

                                            ridestyler.ajax.send({
                                                action: 'Wheel/LinkFitmentResource',
                                                data: $.extend({}, filters, {
                                                    ResourceID: resource.ResourceID,
                                                    WheelFitmentResourceType: wheelFitmentResourceType
                                                }),
                                                callback: function(response) {
                                                    if (response.Success) {
                                                        loadResources();
                                                        rstools.events.dataChanged('wheel-model', 'resource-link');
                                                        rstools.events.dataChanged('wheel-fitment', 'resource-link');
                                                    } else {
                                                        uifactory.alert.show({ type: 'error', text: 'There was an error linking the resources(s): ' + response.Message });
                                                        modal.$modal.removeClass('loading');
                                                    }
                                                }
                                            });
                                        },
                                        filters: {
                                            AssetType: resourceAssetType
                                        },
                                        selectFilter: function(r) {
                                            return r.ResourceAssetType === resourceAssetType;
                                        }
                                    });
                                },
                                onUnlink: function() {
                                    var confirm = uifactory.modal.create({
                                        type: 'confirmation',
                                        cancelable: true,
                                        header: 'Unlink Fitment Resources',
                                        confirmationYes: 'Unlink',
                                        confirmationYesClass: 'btn btn-danger',
                                        body: 'Are you sure you wish to unlink all ' + title + ' images from the selected wheel fitments/models?',
                                        confirmationCallback: function(saidYes) {
                                            if (saidYes) {
                                                modal.$modal.addClass('loading');

                                                ridestyler.ajax.send({
                                                    action: 'Wheel/UnlinkFitmentResource',
                                                    data: $.extend({}, filters, {
                                                        WheelFitmentResourceType: wheelFitmentResourceType
                                                    }),
                                                    callback: function(response) {
                                                        if (response.Success) {
                                                            loadResources();
                                                            confirm.hide();
                                                            rstools.events.dataChanged('wheel-model', 'resource-unlink');
                                                            rstools.events.dataChanged('wheel-fitment', 'resource-unlink');
                                                        } else {
                                                            uifactory.alert.show({ type: 'error', text: 'There was an error unlinking the resources(s).' });
                                                            modal.$modal.removeClass('loading');
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    });

                                    confirm.show();
                                }
                            });
                        }

                        createResourceThumbnail("Side").appendTo($columns.side.empty());
                        createResourceThumbnail("Angled").appendTo($columns.angled.empty());
                        createResourceThumbnail("Catalog").appendTo($columns.catalog.empty());

                        modal.$modal.removeClass('loading');
                    });
            }

            loadResources();

            modal.$modal.addClass('loading');

            modal.show();
        },
        copyTo: function (wheelFilters, wheelModelID, settings) {
            if (!verifyWheelAdmin()) return;

            var wheelFitments = [];
            var wheelModelDescription;

            settings = settings || {
            };

            var modal = uifactory.modal.create({
                cancelable: true,
                header: settings.header ? settings.header : $('<h4/>'),
                startLoading: true,
                actions: [
                    {
                        'class': 'btn btn-default',
                        'title': 'Cancel',
                        'callback': function () { modal.hide(); }
                    },
                    {
                        'class': 'btn btn-primary',
                        'title': 'Copy Fitments',
                        'callback': function () {
                            rstools.utils.apiDeferred({ action: 'Wheel/CopyFitmentsToModel', data: $.extend({
                                ToWheelModel: wheelModelID
                            }, wheelFilters)})
                                .done(function () {
                                    rstools.events.dataChanged('wheel-fitment', 'copy');

                                    if (typeof settings.callback === 'function') settings.callback();

                                    modal.hide();

                                    uifactory.alert.show({ 
                                        type: 'success', 
                                        text: "The wheel fitments were copied."
                                    });
                                })
                                .fail(function () {
                                    modal.$modal.removeClass('loading');
                                    uifactory.alert.show({ 
                                        type: 'error', 
                                        text: "There was an error copying the fitments."
                                    });
                                });
                        }
                    }
                ]
            });

            loosenFilters('fitment', wheelFilters);

            var wheelFitmentsLoaded = loadPaginatedList('Wheel/GetFitmentDescriptions', 'Wheel/CountFitments', 'Fitments', wheelFilters).done(function(fitments) {
                wheelFitments = fitments;
            });

            var wheelModelDescriptionLoaded = loadPaginatedList('Wheel/GetModelDescriptions', 'Wheel/CountModels', 'Models', loosenFilters('model', {WheelModel: wheelModelID})).done(function (models) {
                wheelModelDescription = models[0];
            });


            if (!settings.header) wheelModelDescriptionLoaded.done(function () {
                modal.settings.header.html("<strong>Copy Fitments into:</strong> " + wheelModelDescription.WheelModelName);
            });

            $.when(wheelFitmentsLoaded, wheelModelDescriptionLoaded)
                .done(function () {
                    modal.$body.append($('<p/>').text('Are you sure you wish to copy the following fitment' + (wheelFitments.length > 1 ? 's' : '') + ' into ' + wheelModelDescription.WheelModelName + '?'));
                    modal.$body.append(rstools.wheel.fitment.utils.objectListFromFitmentDescriptions(wheelFitments).addClass('no-margin'));

                    modal.$modal.removeClass('loading');
                })
                .fail(function () {
                    modal.hide();
                    showError('There was a problem loading information for copying fitments.');
                });

            modal.show();
        },
        moveTo: function (wheelFilters, wheelModelID, settings) {
            if (!verifyWheelAdmin()) return;

            var wheelFitments = [];
            var wheelModelDescription;

            settings = settings || {
            };

            var modal = uifactory.modal.create({
                cancelable: true,
                header: settings.header ? settings.header : $('<h4/>'),
                startLoading: true,
                actions: [
                    {
                        'class': 'btn btn-default',
                        'title': 'Cancel',
                        'callback': function () { modal.hide(); }
                    },
                    {
                        'class': 'btn btn-primary',
                        'title': 'Move Fitments',
                        'callback': function () {
                            rstools.utils.apiDeferred({ action: 'Wheel/MoveFitments', data: $.extend({
                                ToWheelModel: wheelModelID
                            }, wheelFilters)})
                                .done(function () {
                                    rstools.events.dataChanged('wheel-fitment', 'move');

                                    if (typeof settings.callback === 'function') settings.callback();

                                    modal.hide();

                                    uifactory.alert.show({ 
                                        type: 'success', 
                                        text: "The wheel fitments were moved."
                                    });
                                })
                                .fail(function () {
                                    modal.$modal.removeClass('loading');
                                    uifactory.alert.show({ 
                                        type: 'error', 
                                        text: "There was an error moving the fitments."
                                    });
                                });
                        }
                    }
                ]
            });

            loosenFilters('fitment', wheelFilters);

            var wheelFitmentsLoaded = loadPaginatedList('Wheel/GetFitmentDescriptions', 'Wheel/CountFitments', 'Fitments', wheelFilters).done(function(fitments) {
                wheelFitments = fitments;
            });

            var wheelModelDescriptionLoaded = loadPaginatedList('Wheel/GetModelDescriptions', 'Wheel/CountModels', 'Models', loosenFilters('model', {WheelModel: wheelModelID})).done(function (models) {
                wheelModelDescription = models[0];
            });

            if (!settings.header) wheelModelDescriptionLoaded.done(function () {
                modal.settings.header.html("<strong>Move Fitments into:</strong> " + wheelModelDescription.WheelModelName);
            });

            $.when(wheelFitmentsLoaded, wheelModelDescriptionLoaded)
                .done(function () {
                    modal.$body.append($('<p/>').text('Are you sure you wish to move the following fitment' + (wheelFitments.length > 1 ? 's' : '') + ' into ' + wheelModelDescription.WheelModelName + '?'));
                    modal.$body.append(rstools.wheel.fitment.utils.objectListFromFitmentDescriptions(wheelFitments).addClass('no-margin'));

                    modal.$modal.removeClass('loading');
                })
                .fail(function () {
                    modal.hide();
                    showError('There was a problem loading information for moving fitments.');
                });

            modal.show();
        },

        duplicate: function (wheelFilters, settings) {
            if (!verifyWheelAdmin()) return;

            var wheelFitmentDescriptions = [];

            loosenFilters('fitment', wheelFilters);

            var wheelFitmentsLoaded = loadPaginatedList('Wheel/GetFitmentDescriptions', 'Wheel/CountFitments', 'Fitments', wheelFilters).done(function(fitments) {
                wheelFitmentDescriptions = fitments;
            });

            settings = settings || {};

            var modal = uifactory.modal.create({
                cancelable: true,
                header: settings.header ? settings.header : $('<h4/>'),
                startLoading: true
            });

            if (!settings.header) wheelFitmentsLoaded.done(function () {
                var s = wheelFitmentDescriptions.length > 1 ? 's' : '';
                modal.settings.header.html("Duplicate " + wheelFitmentDescriptions.length + " Wheel Fitment" + s);
            });

            var $optionSelections = uifactory.create.selectionList({
                options: [
                    {
                        label: 'Include Promotions',
                        value: 'CopyPromotions',
                        selected: true
                    },
                    {
                        label: 'Include Pricing',
                        value: 'CopyPricing',
                        selected: true
                    },
                    {
                        label: 'Link Images',
                        value: 'CopyFitmentResources',
                        selected: true
                    }
                ]
            }).addClass('collapse');

            $.when(wheelFitmentsLoaded)
                .done(function () {
                    modal.setDisplayID($.map(wheelFitmentDescriptions, function (fitment) {
                        return fitment.WheelFitmentID;
                    }));

                    modal.$body.append($('<p/>').text('Are you sure you wish to duplicate the following fitment' + (wheelFitmentDescriptions.length > 1 ? 's?' : '?')));

                    modal.$body.append(rstools.wheel.fitment.utils.objectListFromFitmentDescriptions(wheelFitmentDescriptions));

                    var $icon = uifactory.create.icon('plus');

                    modal.$body.append([
                        $('<h5/>', {
                            'class': 'clickable',
                            'click': function () {
                                $optionSelections.collapse('toggle');
                                $icon.toggleClass('fa-plus').toggleClass('fa-minus');
                            }
                        }).append([$icon, ' Options']),
                        $optionSelections
                    ]);

                    modal.$footer.append([
                        $('<button/>', {
                            'class': 'btn btn-default',
                            'append': [
                                'Cancel'
                            ],
                            'click': function () {
                                modal.hide();
                            }
                        }),
                        $('<button/>', {
                            'class': 'btn btn-primary',
                            'append': [
                                uifactory.create.icon('clone'),
                                ' Duplicate'
                            ],
                            'click': function () {
                                modal.$modal.addClass('loading');

                                rstools.utils.apiDeferred({
                                    action: 'Wheel/DuplicateFitments',
                                    data: $.extend({}, wheelFilters, $optionSelections.data('selectionList').value())
                                })
                                    .done(function () {
                                        rstools.events.dataChanged('wheel-fitment', 'duplicate');

                                        modal.hide();

                                        uifactory.alert.show({ 
                                            type: 'success', 
                                            text: "The wheel fitments were duplicated."
                                        });
                                    })
                                    .fail(function () {
                                        modal.$modal.removeClass('loading');
                                        uifactory.alert.show({ 
                                            type: 'error', 
                                            text: "There was an error duplicating the fitments."
                                        });
                                    });
                            }
                        })
                    ]);

                    modal.$modal.removeClass('loading');
                })
                .fail(function () {
                    modal.hide();
                    uifactory.alert.show({ 
                        type: 'error', 
                        text: "There was an error loading information about the models to duplicate."
                    });
                });

            modal.show();
        },

        utils: {
            objectListFromFitmentDescriptions: function (fitments) {
                return uifactory.create.objectList({
                    objects: fitments,

                    headers: [
                        "Part #",
                        "Brand",
                        "Model",
                        "Width",
                        "Diameter"
                    ],

                    columns: [
                        'PartNumber',
                        function (fitment) {
                            var small = document.createElement('small');
                                small.appendChild(document.createTextNode('(' + (fitment.DataSourceCode || fitment.DataSourceDescription) + ')'));

                            return [fitment.WheelBrandName, ' ', small];
                        },
                        'WheelModelName',
                        function (f) {
                            return f.WidthMin == f.WidthMax ?
                            [f.WidthMin, uifactory.create.abbr('in', 'inches')] :
                            [f.WidthMin, uifactory.create.abbr('in', 'inches'), ' - ', f.WidthMax, uifactory.create.abbr('in', 'inches')];
                        },
                        function (f) {
                            return f.DiameterMin == f.DiameterMax ?
                            [f.DiameterMin, uifactory.create.abbr('in', 'inches')] :
                            [f.DiameterMin, uifactory.create.abbr('in', 'inches'), ' - ', f.DiameterMax, uifactory.create.abbr('in', 'inches')];
                        }
                    ]
                });
            },

            getTitles: function (rows, filter) {
                return $.map(rows, function (row) {
                    if (filter && !filter(row)) return undefined;

                    var title = "";

                    if (row.PartNumber) title += "Part #: " + row.PartNumber + ';';
                    if (row.BoltPattern) title += " (" + row.BoltPatternDescription + ")";

                    return title || "Wheel fitment ID " + row.WheelFitmentID;
                });
            },

            getIDs: function (rows, filter) {
                return $.map(rows, function (row) {
                    if (filter && !filter(row)) return undefined;
                    return row.WheelFitmentID;
                });
            },

            createFilters: function (rows, loosen) {
                var filters = {
                    WheelFitments: rstools.wheel.fitment.utils.getIDs(rows)
                };

                if (loosen) rstools.wheel.utils.loosenFilters('fitment', filters);

                return filters;
            },
            isArchived: function(/** @type {WheelFitmentDescriptionModel} */ fitment) {
                return fitment.WheelFitmentArchived;
            },
            isUnarchived: function (/** @type {WheelFitmentDescriptionModel} */ fitment) {
                return !fitment.WheelFitmentArchived;
            }
        },

        noun: 'wheel fitment',
        nounPlural: 'wheel fitments',
        dataNamespace: 'wheel-fitment',

        getAction: 'Wheel/GetFitments',
        getActionResponseKey: 'Fitments',
        countAction: 'Wheel/CountFitments',

        archiveAction: 'Wheel/ArchiveFitments',
        unarchiveAction: 'Wheel/UnarchiveFitments',

        updateDataStatusAction: 'Wheel/ModifyFitmentDataStatus',
        getMetaAction: 'Wheel/GetFitmentMeta',
        setMetaAction: 'Wheel/SetFitmentMeta',
        clearMetaAction: 'Wheel/ClearFitmentMeta',
        
        getPromotionStatusAction: 'Promotion/GetWheelFitmentStatus',
        setPromotionStatusAction: 'Promotion/SetWheelFitmentStatus'
    };
})(jQuery);