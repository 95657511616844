/*globals datagrid:true*/

var datagrid = datagrid || {};

(function () {
    var maxRSResultCount = 2000 - 1;
    var maxBulkActionButtonCount = 6;

    var KeyCode = rstools.constants.KeyCode;

    var $window = $(window);

    var clipboard = {
        action: undefined,
        from: undefined,
        rows: undefined
    };

    datagrid = $.extend({
        create: function (options) {
            options = options || {};

            if ('allowCutCopy' in options) {
                if ('allowCut' in options === false) options.allowCut = options.allowCutCopy;
                if ('allowCopy' in options === false) options.allowCopy = options.allowCutCopy;
            }

            // Used for tracking the current error message
            var errorMessage = undefined;

            var settings = $.extend({
                container: null,
                fields: null,
                getAction: null,
                countAction: null,
                resultListField: null,
                search: null,
                sort: {},
                actions: [],
                headerActions: undefined,
                getCount: function (filters, callback) {
                    rstools.log("Data Grid Filters: %o", filters);

                    if (!settings.countAction && !settings.paginationEnabled) {
                        callback(maxRSResultCount);
                        return;
                    }

                    var data = $.extend({}, settings.baseQueryData, filters);

                    ridestyler.ajax.send({
                        action: settings.countAction, data: data, callback: function (response) {
                            if (!response.Success) errorMessage = response.Message;

                            callback(response.Count);
                        }
                    });
                },
                getData: function (start, count, sort, filters, callback) {
                    var data = $.extend({}, settings.baseQueryData, { start: start, count: count });
                    data = $.extend(data, filters);

                    if (sort != null)
                        data.sort = sort;
                    ridestyler.ajax.send({
                        action: settings.getAction, data: data, callback: function (response) {
                            if (!response.Success) errorMessage = response.Message;

                            callback(response[settings.resultListField]);
                        }
                    });
                },
                singleSelect: false,
                selectEnabled: true,
                compact: false,
                pageSize: 50,
                paginationEnabled: true,
                rowSelect: true,
                additionalSort: undefined,
                loadFirstResultSet: true,
                bottomControlsEnabled: false,
                topControlsEnabled: true,
                rowCallback: undefined,

                saveState: true,
                rowIDField: undefined,

                allowCopy: false,
                allowCut: false,

                headerActionColumn: 'actions',

                stickyTableHeadersFixedOffset: $('#primary-nav'),
                stickyTableHeadersScrollableArea: window,
                stickyTableHeadersLeftOffset: 0,

                allowShowAll: false,
                useLoadingProgressBar: true
            }, options);

            // Cannot do anything if we do not have a container
            if (settings.container == null) return undefined;

            raiseGlobalDataGridEvent('gridWillCreate', settings, undefined);

            var grid = function () {
                // Settings and reference elements
                var table, head, body, foot, pageControls = $(),
                    headSelectCheck,
                    topControls, bottomControls, loadingIndicator = $();
                var fields = settings.fields;
                var actions = settings.actions;
                var pageSize = settings.pageSize;
                var shouldLoadAllResults = false;
                var holdSelectionChanges = false;

                var initialSelection = rstools.state.get('s');

                fields = $.map(fields, function(field) {
                    return hasRequiredRoles(field.requiredRoles) ? field : undefined;
                });

                var currentDataSet = [];
                var filterData = {};

                var rebuildStickyHeaders = function() {
                    table.stickyTableHeaders('destroy');
                    head.find('th').css({
                        maxWidth: '',
                        minWidth: ''
                    });
                    table.stickyTableHeaders({
                        fixedOffset: settings.stickyTableHeadersFixedOffset,
                        scrollableArea: settings.stickyTableHeadersScrollableArea,
                        leftOffset: settings.stickyTableHeadersLeftOffset
                    });
                };

                var refreshStickyHeaders = function () {
                    $window.trigger('resize.stickyTableHeaders');
                };

                if (settings.search) {
                    if (typeof settings.search === 'string')
                        filterData.search = settings.search;
                    else if (typeof settings.search === 'object')
                        filterData = settings.search;
                }

                // State tracking variables
                var totalCount = 0;
                var pageCount = 0;

                return {
                    create: function () {
                        settings.container
                            .data('grid', grid)
                            .on('keydown', handleKeyDown);

                        createLoadingIndicatorMarkup(settings.container);

                        if (settings.topControlsEnabled) {
                            topControls = $('<div/>', { 'class': 'data-table-controls' }).appendTo(settings.container);

                            if (settings.paginationEnabled) createPageControlMarkup(topControls);
                            createSelectedRowActionMarkup(topControls);
                        }

                        if (settings.container.hasClass('full-grid-container')) {
                            if (!options.stickyTableHeaderFixedOffset)
                                settings.stickyTableHeadersFixedOffset = {
                                    outerHeight: function () {
                                        return topControls.offset().top + topControls.outerHeight() - $window.scrollTop();
                                    }
                                };

                            if (topControls)
                                grid.page
                                    .on('selectionChanged', function() { table.css('marginTop', topControls.outerHeight()); });
                        }

                        // Expose properties to interface
                        this.fields = fields;

                        table = $('<table/>').addClass('data-table').appendTo(settings.container);
                        head = $('<thead/>').appendTo(table);
                        body = $('<tbody/>').appendTo(table);
                        foot = $('<tfoot/>').appendTo(table);

                        if (settings.bottomControlsEnabled) {
                            bottomControls = $('<div/>', { 'class': 'data-table-controls' }).appendTo(settings.container);

                            if (settings.paginationEnabled) createPageControlMarkup(bottomControls);
                            createSelectedRowActionMarkup(bottomControls);
                        }

                        // Add table classes
                        if (settings.singleSelect) table.addClass('data-table-singleselect');
                        if (settings.compact) table.addClass('data-table-compact');

                        // Create header
                        var hrow = $('<tr/>').appendTo(head);

                        // Checkbox row headers
                        if (!settings.singleSelect && settings.selectEnabled) {
                            $('<th/>', {
                                'class': 'disabled select-cell',
                                'append': headSelectCheck = $('<input/>', {
                                    type: 'checkbox',
                                    'data-icheck': 'disabled'
                                })
                            }).appendTo(hrow).on('click', function() {
                                headSelectCheck.click();
                            });
                            
                            headSelectCheck.on('click', function (e) {
                                var selectedCount = body.find('tr.selected').length,
                                    shouldBeChecked = selectedCount <= 0;

                                holdSelectionChanges = true;

                                e.stopPropagation();

                                headSelectCheck.prop('checked', shouldBeChecked);

                                if (shouldBeChecked) {
                                    body.children(':not(.selected)').find('td.select-cell').click();
                                } else {
                                    body.children('.selected').find('td.select-cell').click();
                                }

                                holdSelectionChanges = false;

                                updateSelectedState(shouldBeChecked ? body.children() : $());
                            });
                        }

                        if (typeof settings.jumpOnLoad === 'undefined' || settings.jumpOnLoad) {
                            grid.page.on('pageLoaded', function() {
                                var $win = $window;

                                $win.scrollTop(0);
                            });
                        }

                        for (var i = 0; i < fields.length; i++) {
                            var f = fields[i];

                            if (f.caption == null)
                                f.caption = f.name;

                            var sortField = f.name;
                            var lastSegmentIndex = f.name.lastIndexOf('.');
                            if (lastSegmentIndex != -1) {
                                sortField = f.name.substr(lastSegmentIndex + 1);
                            }

                            f.ele = $('<th/>').attr('data-field', sortField).addClass(f.colHeaderClass).text(f.caption).appendTo(hrow);

                            if (f.visible === false) f.ele.hide();

                            if (f.width) {
                                f.ele.css({ width: f.width });
                            }

                            if (f.sort === false) {
                                f.ele.addClass('disabled');
                            } else {
                                f.ele.click(function (e) {
                                    var $this = $(this);
                                    var field = $this.data('field');
                                    var sortDirection = $this.data('sort');

                                    grid.sort[e.ctrlKey ? 'add' : 'set'](field, sortDirection = (sortDirection === 'Ascending' ? 'Descending' : 'Ascending'));

                                    $this.data('sort', sortDirection);
                                    grid.page.refresh();

                                    e.preventDefault();

                                    return false;
                                });
                            }
                        }

                        // Build headerActions
                        if ($.isArray(settings.headerActions)) {
                            for (var j = 0; j < settings.headerActions.length; j++) {
                                var headerAction = settings.headerActions[j];

                                if (!headerAction) continue;

                                if (!hasRequiredRoles(headerAction.requiredRoles))
                                    continue;

                                var $headerActionIcon;

                                // Create the icon
                                if (headerAction.icon instanceof $) $headerActionIcon = headerAction.icon.clone();
                                if (["string", "object"].indexOf(typeof headerAction.icon) >= 0) $headerActionIcon = uifactory.create.icon(headerAction.icon);

                                if (!$headerActionIcon) continue;

                                // Figure out where it goes
                                var headerActionFields = $.makeArray(headerAction.fields || settings.headerActionColumn);
                                var $headerActionContainers = $(headerActionFields).map(function(idx, fieldName) {
                                    for (var k = 0; k < fields.length; k++) {
                                        var field = fields[k];

                                        if (field.name === fieldName)
                                            return field.ele.get(0);
                                    }

                                    return undefined;
                                });

                                $headerActionIcon.addClass('clickable pull-right');

                                var $headerActionAnchor = $('<a/>', {
                                    'href': '#',
                                    'title': headerAction.title
                                }).click(false);

                                if (headerAction.id) $headerActionAnchor.attr('id', headerAction.id);

                                if (typeof headerAction.callback === "function")
                                    $headerActionAnchor.click($.proxy(headerAction.callback, grid));

                                $headerActionContainers.append($headerActionAnchor.append($headerActionIcon));
                            }
                        }

                        // Setup row click handler if needed
                        if (settings.selectEnabled && (settings.singleSelect || settings.rowSelect)) {
                            body.addClass('rows-clickable');
                            body.on('click', 'tr', function(e) {
                                var $tr = $(this);
                                var $target = $(e.target);
                                var invalidClickSelector = 'button,a';

                                if ($target.is(invalidClickSelector) || $target.closest(invalidClickSelector).length) return;

                                var rowIndex = $tr.data('row');

                                if (typeof rowIndex === 'undefined' || $tr.hasClass('disabled')) return;

                                if (typeof settings.rowSelect === 'function') {
                                    settings.rowSelect(grid.page.getObjectAtIndex(rowIndex));
                                } else {
                                    var $selectCell = $tr.find('td.select-cell');

                                    holdSelectionChanges = true;
                                    
                                    if ($selectCell.length) {
                                        $selectCell.trigger(e);
                                    } else {
                                        $tr.addClass('selected').siblings().removeClass('selected');
                                        grid.page.trigger('selectionChanged');
                                    }

                                    holdSelectionChanges = false;

                                    updateSelectedState();
                                }
                            });
                        }

                        if (settings.saveState) {
                            grid.page.on('pageChanged', function (i) {
                                var state = {
                                    p: i || undefined
                                };

                                if (!initialSelection) state.s = undefined;

                                rstools.state.update(state);
                            });
                        }

                        // Setup the fixed table headers
                        table.stickyTableHeaders({
                            fixedOffset: settings.stickyTableHeadersFixedOffset,
                            scrollableArea: settings.stickyTableHeadersScrollableArea,
                            leftOffset: settings.stickyTableHeadersLeftOffset
                        });

                        // Expose more properties to the interface
                        this.table = table;
                        this.body = body;
                        this.topControls = topControls;
                        this.bottomControls = bottomControls;
                        this.settings = settings;

                        setLoadingIndicatorEnabled(true);
                        getColumnSettings()
                            .done(function() {
                                setLoadingIndicatorEnabled(false);

                                // Load the first result set
                                if (settings.loadFirstResultSet) grid.refresh();
                            });

                        this.page.trigger('tableCreated');

                        return this;
                    },
                    setPageSize: function (size) {
                        pageSize = size;
                        grid.refresh(true);
                    },
                    getPageSize: function () { return pageSize; },
                    sort: function () {
                        var currentSort = settings.sort;

                        return {
                            getCurrent: function () {
                                return currentSort;
                            },
                            set: function (field, order) {
                                for (var f in currentSort) {
                                    head.find('[data-field="' + f + '"]').removeClass('asc desc');
                                }

                                currentSort = {};
                                currentSort[field] = order;

                                head.find('[data-field="' + field + '"]').addClass(classForOrder(order));
                            },
                            add: function (field, order) {
                                head.find('[data-field="' + field + '"]').removeClass('asc desc').addClass(classForOrder(order));
                                currentSort[field] = order;
                            }
                        };

                        function classForOrder(order) {
                            if (order == 'Ascending')
                                return 'asc';
                            else
                                return 'desc';
                        }
                    }(),
                    search: function (term) {
                        if (!term) {
                            filterData = {};
                        } else {
                            if (typeof term === 'string')
                                filterData.search = term;
                            else if (typeof term === 'object')
                                filterData = term;
                        }

                        grid.refresh(true);
                    },
                    page: function () {
                        var offset = rstools.state.get('p') || 0;
                        var resultOffset = 0;
                        var events = {};

                        if (isNaN(offset)) offset = 0;

                        return {
                            getOffset: function() { return offset; },
                            getResultOffset: function () { return resultOffset; },
                            setResultOffset: function (o) { resultOffset = o; },
                            getTotalCount: function () { return totalCount; },
                            getPageCount: function () { return pageCount; },
                            getStart: function () { return offset * pageSize + 1 + resultOffset; },
                            getSelectedObjects: function ($selection) {
                                var elements = [];

                                ($selection || body.find('tr.selected')).each(function () {
                                    elements.push(currentDataSet[$(this).data('row')]);
                                });

                                return elements;
                            },
                            getObjectAtIndex: function (i) {
                                if (i >= currentDataSet.length) return undefined;
                                return currentDataSet[i];
                            },
                            getObjects: function () {
                                return currentDataSet;
                            },
                            on: function (name, callback) {
                                if (typeof events[name] == 'undefined') {
                                    events[name] = [];
                                }

                                events[name].push(callback);

                                return this;
                            },
                            trigger: raiseEvent,
                            next: function () {
                                grid.page.goTo(offset + 1);
                            },
                            prev: function () {
                                grid.page.goTo(offset - 1);                                
                            },
                            goTo: function (index) {
                                if (index >= 0 && index < pageCount && index != offset) {
                                    offset = index;
                                    refresh();

                                    raiseEvent('pageChanged', offset);
                                }
                            },
                            refresh: function () {
                                refresh();
                            },
                            reset: function () {
                                offset = 0;
                                refresh();
                            }
                        };

                        function raiseEvent(name, data) {
                            var callbacks = events[name] || [];
                            
                            raiseGlobalDataGridEvent(name, data, grid);

                            if (callbacks.length)
                                for (var i = 0; i < callbacks.length; i++)
                                    if (typeof callbacks[i] === "function")
                                        callbacks[i].call(grid, data);
                        }

                        function processData(data) {
                            setLoadingIndicatorEnabled(false);

                            if (fields == null || !fields.length) {
                                fields = [];
                                for (var pn in data[0]) {
                                    fields.push({
                                        name: pn,
                                        caption: pn
                                    });
                                }
                            }

                            currentDataSet = data;

                            if (typeof settings.additionalSort === 'function')
                                currentDataSet.sort(settings.additionalSort);

                            // Clear out contents of the old page
                            body.empty();

                            headSelectCheck && headSelectCheck.prop('checked', false).prop('indeterminate', false);

                            var bodyElementsFragment = document.createDocumentFragment();

                            //TODO: The click cell listener should be generalized so we're not creating a new function for every row
                            var $lastClicked = null;

                            if (typeof data == 'undefined') {
                                console.error('No results returned for table.');
                            } else {
                                if (!data.length) {
                                    var $message = $('<tr/>').append($('<td/>').text('There were no results found matching your filters.').addClass('no-results').attr('colspan', settings.fields.length + 1));
                                    body.append($message);
                                }

                                var fi, f, fname;

                                // Begin creating data rows for the new page
                                for (var i = 0; i < data.length; i++) {
                                    var r = data[i];
                                    var tr = document.createElement('tr');
                                    var selected = false;

                                    if (settings.selectEnabled && initialSelection) {
                                        var rowID = getRowID(r);

                                        if (rowID) selected = $.inArray(rowID, initialSelection) >= 0;
                                    }

                                    bodyElementsFragment.appendChild(tr);

                                    tr.setAttribute('class', (i % 2 === 0) ? 'even' : 'odd');
                                    tr.setAttribute('data-row', i.toString());

                                    if (selected) tr.className += ' selected';

                                    //TODO: The click cell listener should be generalized so we're not creating a new function for every row
                                    if (!settings.singleSelect && settings.selectEnabled) {
                                        $('<td class="select-cell" />').appendTo(tr).click(function (e) {
                                            // Get information for the clicked item
                                            var $this = $(this).find('input');
                                            var $thisRow = $this.closest('tr');
                                            if ($thisRow.hasClass('disabled')) return;
                                            var selected = $thisRow.hasClass('selected');
                                            var rowIndex = $thisRow.data('row');

                                            e.stopPropagation();

                                            // Figure out what we need to do with the item we actually clicked. If we are doing a batch
                                            // we will use this same action for all other items in the batch
                                            var newState = !selected;

                                            // Adjust the clicked item
                                            if (e.shiftKey && $lastClicked != null) {
                                                var $lcRow = $lastClicked.closest('tr');
                                                var lcIndex = $lcRow.data('row');

                                                var $start = (rowIndex < lcIndex) ? $thisRow : $lcRow;
                                                var $end = (rowIndex < lcIndex) ? $lcRow : $thisRow;

                                                var $effectedElements = $start.nextUntil($end).andSelf().add($end);

                                                $effectedElements.each(function () {
                                                    var $row = $(this);

                                                    if ($row.hasClass('disabled')) return;

                                                    var $checkbox = $(this).find('input[type="checkbox"]').first();

                                                    if (newState) {
                                                        $row.addClass('selected');
                                                        $checkbox.prop('checked', true);
                                                    } else {
                                                        $row.removeClass('selected');
                                                        $checkbox.prop('checked', false);
                                                    }
                                                });
                                            } else {
                                                $thisRow.toggleClass('selected', newState);
                                                $this.prop('checked', newState);
                                            }

                                            $lastClicked = $this;
                                            raiseEvent('selectionChanged');

                                            var $selectedRows = body.children('.selected');

                                            if ($selectedRows.length === 0) headSelectCheck.prop('checked', false).prop('indeterminate', false);
                                            else if ($selectedRows.length === currentDataSet.length) headSelectCheck.prop('checked', true).prop('indeterminate', false);
                                            else headSelectCheck.prop('checked', false).prop('indeterminate', true);

                                            $this.focus();

                                            updateSelectedState($selectedRows);

                                        }).append($('<input/>', {type: 'checkbox'}).prop('checked', selected));
                                    }

                                    for (fi = 0; fi < fields.length; fi++) {
                                        f = fields[fi];
                                        fname = f.name;

                                        if (f.visible === false) continue;

                                        var cell = document.createElement('td');
                                        var $cell = $(cell).addClass(f.colClass);

                                        var value = null;
                                        if (typeof f.format == 'function') {
                                            value = f.format(r, $cell);
                                        } else if ($.isArray(f.format)) {
                                            value = "";

                                            for (var j = 0; j < f.format.length; j++)
                                                value += getValueFromTokenString(f.format[j], r) + ' ';

                                            value = value.substring(0, value.length - 1);
                                        } else {
                                            value = getValueFromTokenString(fname, r);
                                        }

                                        if (typeof value !== 'undefined' || value !== null)
                                            $cell.append(value);

                                        var actionControls = createActionListMarkup(fname, r);
                                        actionControls && cell.appendChild(actionControls);

                                        tr.appendChild(cell);
                                    }

                                    if (typeof settings.rowCallback === 'function') settings.rowCallback($(tr), r);
                                }

                                body.append(bodyElementsFragment);

                                for (fi = 0; fi < fields.length; fi++) {
                                    f = fields[fi];

                                    if (f.visible === false) continue;

                                    if (typeof f.columnRenderer === 'function') (function(f) {
                                        var $bodyChildren = body.children();
                                        var fieldIndex = grid.field.getFieldIndex(f.name);

                                        if (typeof fieldIndex !== "number") return;

                                        var $tds = $bodyChildren.map(function () {
                                            return $(this).children().get(fieldIndex);
                                        });

                                        f.columnRenderer.call(grid, $tds, data);
                                    })(f);
                                }
                            }

                            if (initialSelection) {
                                headSelectCheck && headSelectCheck.prop('checked', initialSelection.length === data.length).prop('indeterminate', initialSelection.length < data.length);
                                initialSelection = undefined;
                            } else {
                                updateSelectedState($());
                            }

                            raiseEvent('selectionChanged');
                            raiseEvent('pageLoaded', offset);
                        }

                        function loadAllResults() {
                            var pageDeferreds = [];
                            var pageResults;
                            var totalPages = Math.ceil((totalCount - resultOffset - 1) / maxRSResultCount);
                            var progressPage = 1;

                            for (var i = resultOffset + 1; i <= totalCount; i += maxRSResultCount) (function (i) {
                                var d = new $.Deferred();

                                settings.getData(i, maxRSResultCount, grid.sort.getCurrent(), filterData, function (data) {
                                    progressPage++;

                                    if (totalPages > 1)
                                        setLoadingIndicatorProgress(progressPage / totalPages, "Loading page " + progressPage + " of " + totalPages);

                                    pageResults[$.inArray(d, pageDeferreds)] = data;
                                    d.resolve();
                                });

                                pageDeferreds.push(d);
                            })(i);

                            if (totalPages > 1)
                                setLoadingIndicatorProgress(1/totalPages, "Loading page 1 of " + totalPages);

                            pageResults = new Array(pageDeferreds.length);

                            $.when.apply($, pageDeferreds).done(function () {

                                if (totalPages > 1)
                                    setLoadingIndicatorProgress(-1);
                                
                                setTimeout(function() {
                                    var data = [];
                                    data = data.concat.apply(data, pageResults);
                                    processData(data);
                                }, 0);
                            });
                        }

                        function refresh() {
                            if (!totalCount) {
                                var message = 'There were no results found matching your filters.';

                                if (errorMessage) message = 'There was a problem with your request: ' + errorMessage;

                                var $message = $('<tr/>').append($('<td/>').text(message).addClass('no-results').attr('colspan', settings.fields.length + 1));
                                body.empty();
                                body.append($message);

                                rstools.state.unset(['p', 's']);

                                raiseEvent('selectionChanged');
                                raiseEvent('pageLoaded', offset);
                            } else {
                                if (offset >= pageCount)
                                    offset = pageCount - 1;

                                setLoadingIndicatorEnabled(true);

                                if (shouldLoadAllResults) {
                                    loadAllResults();
                                } else if (settings.paginationEnabled) {
                                    // Request results for the new page
                                    settings.getData(offset * pageSize + 1 + resultOffset, pageSize, grid.sort.getCurrent(), filterData, processData);
                                } else if (!settings.countAction) {
                                    // There's no count action so request the maximum amount of records
                                    settings.getData(0, maxRSResultCount, grid.sort.getCurrent(), filterData, processData);
                                } else {
                                    loadAllResults();
                                }

                                raiseEvent('pageChanged', offset);
                            }
                        }

                        function getValueFromTokenString(tokenString, o) {
                            var tokens = tokenString.split('.');
                            var value = o;

                            for (var ti = 0; ti < tokens.length; ti++) {
                                var t = tokens[ti];
                                value = value[t];
                            }

                            return value;
                        }

                    }(),
                    refresh: function (reset) {
                        setLoadingIndicatorEnabled(true);

                        if (totalCount != 0)
                            grid.page.trigger('totalCountChanged');

                        settings.getCount(filterData, function (count) {
                            setLoadingIndicatorEnabled(false);
                            totalCount = count;
                            grid.page.trigger('totalCountChanged', totalCount);
                            pageCount = Math.ceil(totalCount / pageSize);

                            rebuildPageControlMarkup(0);

                            if (reset)
                                grid.page.reset();
                            else
                                grid.page.refresh();
                        });
                    },
                    remove: function () {
                        table.remove();

                        // Unbind all of our events on our table objects
                        table.off();
                        table.find('*').off();

                        table = head = body = foot = null;
                    },
                    showAllResults: function () {
                        shouldLoadAllResults = true;

                        pageControls.hide();
                        grid.page.reset();
                    },
                    updateColumnSettings: function() {
                        setLoadingIndicatorEnabled(true);
                        getColumnSettings()
                            .done(function () {
                                setLoadingIndicatorEnabled(false);
                                body.empty();
                                grid.page.refresh(true);
                            });
                    },
                    field: {
                        getFieldIndex: function (fieldName, includeDisabled) {
                            if (!fieldName) return undefined;
                            if ($.isArray(fieldName)) {
                                var indexes = {};

                                for (var j = 0; j < fieldName.length; j++)
                                    indexes[fieldName[j]] = grid.field.getFieldIndex(fieldName[j]);

                                return indexes;
                            }

                            var headers = head.children().first().children(includeDisabled ? '' : ':visible');

                            for (var i = 0; i < headers.length; i++) {
                                if (headers[i].getAttribute('data-field') === fieldName) return i;
                            }
                        },
                        hide: function(fieldName) {
                            var field = findField(fieldName);

                            if (field) {
                                field.ele.hide();
                                field.visible = false;
                                grid.page.refresh();
                            }
                        },
                        show: function(fieldName) {
                            var field = findField(fieldName);
                            if (field) {
                                field.ele.show();
                                field.visible = true;
                                grid.page.refresh();
                            }
                        }
                    },
                    action: {
                        getByID: function (id) {
                            var allActions = getAllActions();

                            for (var i = allActions.length - 1; i >= 0; i--)
                                if (allActions[i].id === id)
                                    return allActions[i];
                        }
                    }
                };

                function handleKeyDown(e) {
                    if ($(e.target).is('input:not(:checkbox)')) return;

                    var allActions = getAllActions();

                    for (var i = allActions.length - 1; i >= 0; i--) {
                        var keyboardCombo = allActions[i].keyboardCombo;

                        if (keyboardCombo && rstools.utils.keyboardComboMatchesEvent(keyboardCombo, e)) {
                            runActionOnSelected(allActions[i]);

                            e.stopImmediatePropagation();
                            return false;
                        }
                    }
                }

                function getColumnSettings() {
                    var d = $.Deferred();
                    if (typeof settings.getColumnSettings === 'function') {
                        settings.getColumnSettings.apply(grid).done(function(columnSettings) {
                            if (columnSettings) {
                                for (var i = 0; i < fields.length; i++) {
                                    var field = fields[i];

                                    if (field.name in columnSettings) {
                                        field.visible = columnSettings[field.name].visible;

                                        field.ele[field.visible ? 'show' : 'hide']();
                                    }
                                }

                                rebuildStickyHeaders();
                            }

                            d.resolve();
                        });
                    } else {
                        d.resolve();
                    }

                    return d;
                }

                function findField(fieldName) {
                    for (var i = 0; i < fields.length; i++)
                        if (fields[i].name === fieldName)
                            return fields[i];
                    return undefined;
                }

                function setLoadingIndicatorEnabled(v) {
                    v ? loadingIndicator.show() : loadingIndicator.hide();
                    loadingIndicator.find('.progress-container').hide();
                }

                function setLoadingIndicatorProgress(percent, statusText) {
                    if (!settings.useLoadingProgressBar) return;
                    var $progressContainer = loadingIndicator.find('.progress-container').show();

                    if (percent < 0) $progressContainer.hide();

                    var $progress = $progressContainer.children('.progress');
                    var $status = $progress.next();
                    
                    $progress.children('.progress-bar').css('width', percent * $progressContainer.width());
                    $status.text(statusText);
                }

                function hasRequiredRoles(requiredRoles) {
                    if (!requiredRoles || typeof datagrid.settings.hasRole === 'undefined') return true;

                    requiredRoles = $.makeArray(requiredRoles);

                    for (var j = requiredRoles.length - 1; j >= 0; j--) {
                        if (!datagrid.settings.hasRole(requiredRoles[j]))
                            return false;
                    }

                    return true;
                }

                function setClipboard(action, rows) {
                    clipboard.action = action;
                    clipboard.rows = rows.slice();
                    clipboard.from = settings.getAction;

                    var s = rows.length > 1 ? 's' : '';
                    var v = action === 'copy' ? 'copied' : 'cut';

                    rstools.events.trigger('datagridClipboardChanged', clipboard);

                    uifactory.alert.show({
                        type: 'success',
                        text: rows.length + ' item' + s + ' ' + v
                    });
                }

                function getAllActions() {
                    var allActions = actions.slice();

                    if (settings.allowCopy)
                        allActions.push({
                            title: 'Copy',
                            id: 'copy',
                            icon: 'fa fa-files-o',
                            multiple: true,
                            forMenu: true,
                            requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                            keyboardCombo: [KeyCode.Cmd, 'C'],
                            fields: ['actions'],
                            callback: function (rows) {
                                if (!rows.length) return;

                                // Copy rows
                                setClipboard('copy', rows);
                            }
                        });

                    if (settings.allowCut)
                        allActions.push({
                            title: 'Cut',
                            id: 'cut',
                            icon: 'fa fa-scissors',
                            multiple: true,
                            forMenu: true,
                            requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                            keyboardCombo: [KeyCode.Cmd, 'X'],
                            fields: ['actions'],
                            callback: function (rows) {
                                if (!rows.length) return;
                                
                                // Cut rows
                                setClipboard('cut', rows);
                            }
                        });

                    return allActions;
                }

                function getActionsForField(field, r) {
                    var forField = [];
                    var isForSelectionActions = !field;

                    var allActions = getAllActions();

                    for (var i = 0; i < allActions.length; i++) {
                        var a = allActions[i],
                            shouldBeReturned = false;

                        // If we don't have the required role for the action don't display it
                        if (!hasRequiredRoles(a.requiredRoles))
                            continue;

                        // If we're getting actions for the bulk action bar and it applies to multiple rows
                        if (isForSelectionActions && a.multiple) {
                            // Run custom application logic if it exists
                            // or just return the row
                            if (typeof a.appliesTo === 'function') {
                                var appliesToAtLeastOneRow = false;
                                for (var j = 0; j < r.length; j++) {
                                    if (a.appliesTo(r[j])) {
                                        appliesToAtLeastOneRow = true;
                                        break;
                                    }
                                }

                                shouldBeReturned = appliesToAtLeastOneRow;
                            } else shouldBeReturned = true;

                        // Otherwise this is for a single row, determine if we're in the right column
                        } else if ($.inArray(field, a.fields) !== -1 && a.single !== false) {
                            // Run custom application logic or return the field.
                            if (typeof a.appliesTo === 'function') shouldBeReturned = a.appliesTo(r);
                            else shouldBeReturned = true;
                        }

                        // Return the action if it should be returned.
                        if (shouldBeReturned)
                            forField.push(a);
                    }

                    return forField;
                }

                function createActionListMarkup(field, r) {
                    var isRowActions = typeof field !== 'undefined';
                    var relatedActions = getActionsForField(field, r);
                    var menuActions = [];

                    if (!relatedActions.length) return undefined;

                    var listDiv = document.createElement('div');

                    listDiv.className = "btn-group data-actions";

                    var action, i;

                    for (i = 0; i < relatedActions.length; i++) {
                        action = relatedActions[i];

                        if (action.forMenu) {
                            menuActions.push(action);
                            continue;
                        }

                        listDiv.appendChild(createActionButtonMarkup(action, isRowActions));
                    }

                    if (!isRowActions && relatedActions.length - menuActions.length < maxBulkActionButtonCount) {
                        var movedActions = menuActions.splice(0, maxBulkActionButtonCount - (relatedActions.length - menuActions.length));

                        for (i = 0; i < movedActions.length; i++)
                            listDiv.appendChild(createActionButtonMarkup(movedActions[i], isRowActions));
                    }


                    if (isRowActions === false) listDiv.className += ' align-middle';

                    if (menuActions.length) {
                        var menuGroup = document.createElement('div');

                        menuGroup.setAttribute('class', 'btn-group');
                        menuGroup.setAttribute('role', 'group');

                        var toggleButton = document.createElement('button');

                        $(toggleButton).dropdown();

                        toggleButton.setAttribute('data-toggle', 'dropdown');
                        toggleButton.setAttribute('aria-expanded', 'false');
                        toggleButton.className = 'btn btn-default' + (isRowActions ? ' btn-sm' : '');
                        toggleButton.type = 'button';
                        toggleButton.innerHTML = '<i class="caret"></i>';

                        menuGroup.appendChild(toggleButton);

                        var menuList = document.createElement('ul');

                        menuList.setAttribute('class', 'dropdown-menu dropdown-menu-right');
                        menuList.setAttribute('role', 'menu');

                        for (i = 0; i < menuActions.length; i++) {
                            var li = document.createElement('li');

                            li.appendChild(createActionMenuItemMarkup(menuActions[i], isRowActions));
                            menuList.appendChild(li);
                        }

                        menuGroup.appendChild(menuList);
                        listDiv.appendChild(menuGroup);
                    }

                    $(listDiv).click(false);

                    return listDiv;
                }

                function actionButtonRowCallback(e) {
                    var $this = $(this);

                    runAction($this.data('action'), [grid.page.getObjectAtIndex($this.closest('tr').data('row'))]); 
                    e.preventDefault();
                }

                function actionButtonSelectedCallback(e) {
                    runActionOnSelected($.data(this, 'action'));
                    e.preventDefault();
                }

                function runActionOnSelected(action) {
                    runAction(action, grid.page.getSelectedObjects());
                }

                function runAction(action, objects) {
                    if (action && typeof action.callback === 'function')
                        action.callback(objects, grid);
                }

                function getActionButtonCallback(action, rowOnly) {
                    if (typeof action.callback !== 'function') return $.noop;
                    if (rowOnly) return actionButtonRowCallback;
                    else return actionButtonSelectedCallback;
                }

                function cleanID(id) {
                    if (typeof id === 'string')
                        id = id.replace(/\-/g, '');

                    return id;
                }

                function getRowID(row) {
                    var rowIDField = settings.rowIDField;

                    if (!row) return undefined;

                    if (typeof rowIDField === 'string') return cleanID(row[rowIDField]);
                    if (typeof rowIDField === 'function') return rowIDField(row);

                    var bestIDKey;
                    var bestIDKeyScore = -Infinity;

                    var resultListField = settings.resultListField.substr(0, settings.resultListField.length - 1);
                    for (var k in row) {
                        if (row.hasOwnProperty(k) && k.substr(-2) === 'ID') {
                            var keyScore = k.score(resultListField);

                            if (keyScore > bestIDKeyScore) {
                                bestIDKey = k;
                                bestIDKeyScore = keyScore;
                            }
                        }
                    }

                    settings.rowIDField = bestIDKey;

                    return cleanID(row[bestIDKey]);
                }

                function updateSelectedState($selection) {
                    if (!settings.saveState) return;

                    if (holdSelectionChanges) return;

                    var selection = grid.page.getSelectedObjects($selection);

                    if (selection.length) rstools.state.set('s', $.map(selection, getRowID), true);
                    else rstools.state.unset('s', true);
                }

                function getActionIconEl(a) {
                    if (a.icon instanceof $)
                        return a.icon.get(0).cloneNode(true);
                    else if (typeof a.icon === 'string') {
                        var icon = document.createElement('i');
                        icon.setAttribute('class', a.icon);

                        return icon;
                    }
                }

                function createActionMenuItemMarkup(a, rowOnly) {
                    var actionAnchor = document.createElement('a');

                    if (a.menuClassName) actionAnchor.className = a.menuClassName;
                    actionAnchor.setAttribute('role', 'menuitem');

                    actionAnchor.tabIndex = -1;
                    actionAnchor.href = '#';

                    $.data(actionAnchor, 'action', a);
                    actionAnchor.onclick = getActionButtonCallback(a, rowOnly);

                    if (a.icon) {
                        actionAnchor.appendChild(getActionIconEl(a));

                        var textSpan = document.createElement('span');
                        textSpan.innerHTML = a.title;

                        actionAnchor.appendChild(textSpan);
                    } else {
                        actionAnchor.innerHTML = a.title;
                    }

                    return actionAnchor;
                }

                function createActionButtonMarkup(a, rowOnly) {
                    var button = document.createElement('button');

                    button.className = 'btn ' + (a.className || 'btn-default');
                    button.title = a.title;
                    button.type = 'button';

                    $.data(button, 'action', a);
                    button.onclick = getActionButtonCallback(a, rowOnly);

                    button.appendChild(getActionIconEl(a));

                    if (rowOnly) button.className += ' btn-sm';

                    return button;
                }

                function createSelectedRowActionMarkup(container) {
                    grid.page.on('selectionChanged', function () {
                        var selectedObjects = grid.page.getSelectedObjects();

                        container.find('.bulk-actions').remove();

                        if (selectedObjects.length > 1) {
                            var actionList = createActionListMarkup(undefined, selectedObjects);

                            if (actionList) {
                                actionList.className += ' bulk-actions';
                                container.append(actionList);
                            }
                        }

                        refreshStickyHeaders();
                    });
                }

                function createLoadingIndicatorMarkup(container) {
                    var $loading = $('<div/>', { "class": "data-table-loading" })
                        .appendTo(container)
                        .append($('<div/>', { 'class': 'background' }).append($('<div/>', { 'class': 'loading loading-indicator' })));

                    var $progressContainer = $('<div/>', { 'class': 'progress-container' });

                    $('<div/>', { 'class': 'progress' })
                        .append($('<div/>', { 'class': 'progress-bar' }))
                        .appendTo($progressContainer);

                    $('<div/>', {
                        'class': 'text-center',
                        'appendTo': $progressContainer
                    });

                    loadingIndicator = $loading.append($progressContainer);
                }

                function createPageControlMarkup(container) {
                    var $ul = $('<ul/>', { "class": "pagination align-middle" })
                        .on('click', 'a', pageButtonClick)
                        .appendTo(container);

                    container.addClass('text-center');

                    pageControls = pageControls.add($ul);

                    grid.page.on('pageChanged', function (index) {
                        rebuildPageControlMarkup(index);
                    });
                }

                function rebuildPageControlMarkup(index) {
                    pageControls.empty();

                    if (!shouldLoadAllResults && pageCount > 0) {
                        pageControls.each(function () {
                            var $pageControl = $(this);

                            var target = index + 1,
                                start = target - 2;

                            if (start <= 0) start = 1;

                            var end = start + 4;

                            if (end > pageCount) {
                                end = pageCount;
                                start = Math.max(end - 4, 1);
                            }

                            var firstPage = target == 1,
                                lastPage = target == pageCount;

                            createPageButtonMarkup(0, "&laquo;", false, firstPage).appendTo($pageControl);
                            createPageButtonMarkup("prev", "&lsaquo;", false, firstPage).appendTo($pageControl);

                            for (var i = start; i <= end; i++)
                                createPageButtonMarkup(i - 1, i, i == target).appendTo($pageControl);

                            createPageButtonMarkup("next", "&rsaquo;", false, lastPage).appendTo($pageControl);
                            createPageButtonMarkup(pageCount - 1, "&raquo;", false, lastPage).appendTo($pageControl);

                            if (pageCount > 1 && settings.allowShowAll)
                                createPageButtonMarkup("all", "Show All", false, firstPage && lastPage).appendTo($pageControl);
                        });
                    }
                }

                function pageButtonClick() {
                    var $this = $(this),
                        target = $this.data('target');

                    if (target === 'prev') grid.page.prev();
                    else if (target === 'next') grid.page.next();
                    else if (target === 'all') {
                        if (totalCount < 2000) grid.showAllResults();
                        else {
                            uifactory.modal.create({
                                type: 'confirmation',
                                cancelable: true,
                                header: 'A Lot of Results',
                                body: 'There are ' + totalCount + ' results in this list. Are you sure you wish to show all of them? Doing so may degrade performance.',
                                confirmationYesClass: 'btn btn-primary',
                                confirmationCallback: function(confirmed) {
                                    if (confirmed) {
                                        grid.showAllResults();
                                        this.hide();
                                    }
                                }
                            }).show();
                        }
                    } else grid.page.goTo(target);

                    return false;
                }

                function createPageButtonMarkup(target, text, active, disabled) {
                    var $b = $("<li/>");

                    $("<a/>", {
                        "href": "#",
                        "html": text,
                        "data-target": target,
                        "appendTo": $b
                    });

                    if (active) $b.addClass('active');
                    if (disabled) $b.addClass('disabled');

                    return $b;
                }
            }();

            var createdGrid = grid.create();

            raiseGlobalDataGridEvent('gridDidCreate', settings, createdGrid);

            return createdGrid;
        },

        getClipboard: function () {return clipboard;},
        clearClipboard: function () {
            clipboard = {
                action: undefined,
                from: undefined,
                rows: undefined
            };
        },
        hasClipboardMatching: function (fromStart) {
            if (!clipboard || !clipboard.from || !clipboard.rows) return false;
            if (clipboard.from.indexOf(fromStart) < 0) return false;
            if (!clipboard.rows.length) return false;

            return true;
        },

        settings: {},

        
        on: function (name, callback) {
            var events = datagrid.settings.events;

            if (typeof events[name] === 'undefined') {
                events[name] = [];
            }

            events[name].push(callback);

            return this;
        }
    }, datagrid);

    if ('events' in datagrid.settings === false) datagrid.settings.events = {};

    
    function raiseGlobalDataGridEvent(name, data, grid) {
        var callbacks = [];

        if (name in datagrid.settings.events)
            callbacks = $.makeArray(datagrid.settings.events[name]);

        if (callbacks.length)
            for (var i = 0; i < callbacks.length; i++)
                if (typeof callbacks[i] === "function")
                    callbacks[i].call(grid, data);
    }
})();