(function () {
    var VehicleReferenceType = rstools.constants.VehicleReferenceType;

    rstools.vehicle.showDescription = function (configID) {
        if (typeof configID === 'object' && 'ConfigurationID' in configID)
            configID = configID.ConfigurationID;

        // A custom header so we can inject the name of the vehicle
        // after it loads
        var $header = $('<h4/>');

        // Setup the modal
        var modal = uifactory.modal.create({
            footer: false, cancelable: true,
            customHeader: $header,
            displayID: configID
        });

        // A list of deferreds to wait for
        var configLoaded = new $.Deferred(),
            descriptionLoaded = new $.Deferred(),
            tireOptionsLoaded = new $.Deferred();

        // A list of data to keep
        var vehicleConfig, vehicleDescription, firstTireOption;

        // References to modal elements
        var $modal = modal.$modal.addClass('loading vehicle-description-modal'),
            $body = modal.$body;

        // #region Get Vehicle data from RS
        ridestyler.ajax.send({
            action: 'Vehicle/GetConfigurations',
            data: {
                VehicleConfiguration: configID,
                TireOptionRequirement: 0,
                FitmentRequirement: 0
            },
            callback: function (r) {
                if (r.Success && r.Configurations.length) {
                    vehicleConfig = r.Configurations[0];
                    configLoaded.resolve();
                } else configLoaded.reject();
            }
        });

        ridestyler.ajax.send({
            action: 'Vehicle/GetDescriptions',
            data: {
                VehicleConfiguration: configID,
                TireOptionRequirement: 0,
                FitmentRequirement: 0
            },
            callback: function (r) {
                if (r.Success && r.Descriptions.length) {
                    vehicleDescription = r.Descriptions[0];
                    descriptionLoaded.resolve();
                } else descriptionLoaded.reject();
            }
        });

        ridestyler.ajax.send({
            action: 'Vehicle/GetTireOptions',
            data: {
                VehicleConfiguration: configID,
                Count: 1,
                TireOptionRequirement: 0,
                FitmentRequirement: 0
            },
            callback: function (r) {
                if (r.Success) {
                    if (r.Options.length) firstTireOption = r.Options[0];
                    tireOptionsLoaded.resolve();
                } else tireOptionsLoaded.reject();
            }
        });
        // #endregion

        // #region AJAX Deferreds done
        $.when(descriptionLoaded, tireOptionsLoaded).done(function () {
            if (vehicleDescription.HasSideImage || vehicleDescription.HasAngledImage) {
                // #region Create the image picker at the top of the modal
                (function () {
                    var $container = $("<div/>", { 'class': 'text-right' });
                    var options = [];

                    vehicleDescription.HasSideImage && options.push({ label: 'Side', value: 'Side' });
                    vehicleDescription.HasAngledImage && options.push({ label: 'Angled', value: 'Angle' });

                    var $dropdown = uifactory.create.dropdown({
                        options: options
                    }).attr('data-name', 'Type').appendTo($container);

                    // Create the display image at the top of the modal
                    var $displayImgContainer = $('<div/>', {
                        css: {
                            'minHeight': 142,
                            'background': 'none'
                        }
                    }).prependTo($body);

                    $('<div/>', { 'class': 'loading-indicator' }).appendTo($displayImgContainer);

                    var $displayImg = $('<img/>', {
                        'class': 'img-responsive',
                        'css': {
                            'marginBottom': 20,
                        }
                    }).appendTo($displayImgContainer);

                    function updateImage(type) {
                        $displayImgContainer.addClass('loading');

                        $displayImg.prop('src', ridestyler.ajax.url('Vehicle/Render', {
                            'Type': type,
                            'PaintColor': '#dedede',
                            'Width': 600,
                            'Height': 150,
                            'PositionX': 1,
                            'PositionY': 2,
                            'VehicleConfiguration': configID,
                            'VehicleTireOption': firstTireOption ? firstTireOption.VehicleTireOptionID : undefined
                        })).on("load error", function () {
                            $displayImgContainer.removeClass('loading');
                        });
                    }

                    $dropdown.on('change', function () {
                        var data = {};

                        $dropdown.syncFormToObject(data);

                        updateImage(data.Type);
                    }).change();

                    var dropdown = $dropdown.data('rsDropdown');
                    
                    dropdown.elements.menu.addClass('dropdown-menu-right');
                    dropdown.elements.button.addClass('btn-sm');

                    $container.prependTo($body);
                })();
                // #endregion
            }
        });

        $.when(configLoaded, descriptionLoaded, tireOptionsLoaded)
            .done(function () {
                $modal.removeClass('loading');

                $header.text([
                    vehicleDescription.Year,
                    vehicleDescription.MakeName,
                    vehicleDescription.ModelName,
                    vehicleDescription.TrimName
                ].join(' '));
            })
            .fail(function () {
                modal.hide();
                uifactory.alert.show({ type: 'error', text: 'There was an error loading your vehicle.' });
            });

        //#endregion

        //#region Setup Tab Panel
        var tabs = [
            { 'label': 'Description' },
            { 'label': 'Tire Options' },
            { 'label': 'Fitments' }
        ];

        if (rstools.user.hasRole(rstools.roles.SYSTEM_ADMIN))
            tabs.push({ 'label': 'References' });

        var tabPanel = uifactory.create.tabPanel({
            tabs: tabs
        });

        tabPanel.$panel.appendTo($body);

        var descriptionTab = tabPanel.tabs[0],
            tireOptionsTab = tabPanel.tabs[1],
            fitmentsTab = tabPanel.tabs[2],
            referencesTab = tabPanel.tabs[3];

        //#endregion

        //#region Create the description tab
        (function () {
            $.when(descriptionLoaded, configLoaded).done(function () {
                uifactory.create.descriptionList({
                    properties: [
                        {
                            key: "Year",
                            label: "Year"
                        },
                        {
                            key: "MakeName",
                            label: "Make"
                        },
                        {
                            key: "ModelName",
                            label: "Model",
                            idKey: "VehicleConfiguration_VehicleModelID"
                        },
                        {
                            key: "TrimName",
                            label: "Trim",
                            idKey: "VehicleConfigurationID"
                        },
                        {
                            label: "Style",
                            format: function () {
                                if (vehicleDescription.StyleDescription == 'Unspecified')
                                    return vehicleDescription.StyleType;

                                return vehicleDescription.StyleType + ': ' + vehicleDescription.StyleDescription
                            }
                        },
                        {
                            label: "Doors",
                            format: function () {
                                var doors = vehicleConfig.VehicleConfiguration_VehicleDoorTypeID;
                                return doors > 0 ? doors + ' Doors' : '?';
                            }
                        },
                        {
                            key: "DriveType",
                            label: "Drive Type"
                        },
                        {
                            label: 'Images',
                            format: function () {
                                var imageLabels = [],
                                    createLabel = function (text) {
                                        return $('<span/>', {
                                            'class': 'label label-default',
                                            'html': text
                                        });
                                    };
                            
                                vehicleDescription.HasSideImage && imageLabels.push(createLabel("Side"));
                                vehicleDescription.HasAngledImage && imageLabels.push(createLabel("Angled"));

                                return imageLabels;
                            }
                        },
                        {
                            key: 'BedType',
                            label: 'Bed Type'
                        },
                        {
                            key: "BedLength",
                            label: "Bed Length"
                        },
                        {
                            key: "CabType",
                            label: "Cab Type",
                            format: function () {
                                if (vehicleDescription.CabType == "N/A") return vehicleDescription.CabType;
                                else return vehicleDescription.CabType + ' (' + vehicleDescription.CabTypeClass + ')';
                            }
                        }
                    ],
                    description: vehicleDescription,
                    configuration: vehicleConfig
                }).appendTo(descriptionTab.$pane);
            });
        })();
        //#endregion

        //#region Create the Tire Options tab

        (function () {
            var tireOptionsGrid =
                datagrid.create({
                    container: tireOptionsTab.$pane,
                    fields: [
                        { name: 'VehicleTireOptionFront_TireSizeID', format: ['Front.Description'], caption: 'Front' },
                        { name: 'VehicleTireOptionRear_TireSizeID', format: ['Rear.Description'], caption: 'Rear' }
                    ],
                    getAction: 'Vehicle/GetTireOptionDetails',
                    countAction: 'Vehicle/CountTireOptionDetails',
                    resultListField: 'Details',
                    baseQueryData: {
                        VehicleConfiguration: configID
                    },
                    topControlsEnabled: false,
                    paginationEnabled: false,
                    selectEnabled: false,
                    compact: true,
                    pageSize: 1999
                });
        })();

        //#endregion

        //#region Create the Fitments tab

        (function () {
            var fitmentsGrid =
                datagrid.create({
                    container: fitmentsTab.$pane,
                    fields: [
                        {
                            name: 'VehicleFitmentID', caption: 'ID', colHeaderClass: 'center', format: function (f) {
                                var $container = $("<div/>");

                                $('<a/>', {
                                    'class': 'btn btn-link',
                                    'href': '#',
                                    'text': '#',
                                    'title': f.VehicleFitmentID,
                                    'click': false
                                }).tooltip({
                                    placement: 'right',
                                    html: true,
                                    title: '<small>' + f.VehicleFitmentID + '</small>',
                                    trigger: 'click'
                                }).appendTo($container);

                                return $container;
                            }
                        },
                        { name: 'VehicleFitmentHub', caption: 'Hub' },
                        { name: 'VehicleFitmentOffsetMin', caption: 'Offset Min' },
                        { name: 'VehicleFitmentOffsetMax', caption: 'Offset Max' },
                        { name: 'VehicleFitmentDiameterMin', caption: 'Diameter Min' },
                        { name: 'VehicleFitmentDiameterMax', caption: 'Diameter Max' },
                        { name: 'VehicleFitmentWidthMin', caption: 'Width Min' },
                        { name: 'VehicleFitmentWidthMax', caption: 'Width Max' },
                        {
                            name: 'VehicleFitmentPosition', caption: 'Position', format: function (f) {
                                switch (f.VehicleFitmentPosition) {
                                    case 0: return "Both";
                                    case 1: return "Front";
                                    case 2: return "Rear";
                                    default: return "?";
                                }
                            }
                        }
                    ],
                    getAction: 'Vehicle/GetFitments',
                    countAction: 'Vehicle/CountFitments',
                    resultListField: 'Fitments',
                    baseQueryData: {
                        VehicleConfiguration: configID
                    },
                    topControlsEnabled: false,
                    paginationEnabled: false,
                    selectEnabled: false,
                    compact: true,
                    pageSize: 1999
                });
        })();

        //#endregion

        //#region Create the References tab
        referencesTab && (function () {
            var referencesGrid =
                datagrid.create({
                    container: referencesTab.$pane,
                    fields: [
                        {
                            name: 'VehicleReferenceType', caption: 'Type', format: function (r) {
                                return VehicleReferenceType[r.VehicleReferenceType];
                            }
                        },
                        {
                            name: 'VehicleReferenceField', caption: 'Field', format: function (r) {
                                var vehicleReferenceField = r.VehicleReferenceField;

                                switch (r.VehicleReferenceType) {
                                    case VehicleReferenceType.WheelPros:
                                        return rstools.constants.WheelProsReferenceFields[vehicleReferenceField];
                                    case VehicleReferenceType.ACES:
                                        return rstools.constants.VcDbReferenceFields[vehicleReferenceField];
                                    default:
                                        return vehicleReferenceField;
                                }
                            }
                        },
                        { name: 'VehicleReferenceValue', caption: 'Value' }
                    ],
                    getAction: 'Vehicle/GetReferences',
                    countAction: 'Vehicle/CountReferences',
                    resultListField: 'References',
                    baseQueryData: {
                        VehicleConfiguration: configID
                    },
                    topControlsEnabled: false,
                    paginationEnabled: false,
                    selectEnabled: false,
                    compact: true,
                    pageSize: 1999
                });
        })();
        //#endregion

        // Show the modal
        modal.show();
    };
})();