(function ($) {
    rter.mapController('app.organization.key', function (context) {
        var grid; // The datagrid object

        var filters;

        var $filterForm,
            organizationID;

        return {
            actions: {
                search: function () {
                    filters.search = $(this).find('input.search-input').val();
                    if (!filters.search && typeof filters.search !== 'undefined')
                        delete filters.search;

                    grid.search(filters);
                    filtersUpdated();
                },

                filter: function () {
                    var $form = $(this).closest('form');

                    $form.syncFormToObject(filters);

                    if (!filters.Organization) delete filters.Organization;

                    grid.search(filters);
                    filtersUpdated();
                }
            },
            viewWillCreate: function ($view) {
                filters = $.extend(filters, context.state ? context.state['filters'] : {});

                organizationID = rstools.auth.user.getActiveOrganizationID();

                var userSubscription = rstools.auth.user.get('Subscription');

                if (userSubscription && userSubscription.OrganizationSubscriptionSessionCount < 1)
                    $('#no-location-license-alert', $view).show();

                $filterForm = $view.find('.filter-form')
                    .on('reset', function () {
                        $filterForm.syncObjectToForm({
                            Organization: false
                        }).syncFormToObject(filters);
                        grid.search(filters);
                        filtersUpdated();
                    });

                // If the filter form isn't visible, change the panel's header into a body
                // so we don't get the line below it
                if (!rstools.auth.user.hasRole(rstools.roles.SYSTEM_ADMIN))
                    $filterForm.siblings('.panel-heading').addClass('panel-body').removeClass('panel-heading');
                else
                    $filterForm.syncObjectToForm(filters);

                grid =
                    datagrid.create({
                        container: $view.find('.data-wrapper'),
                        fields: [
                            { name: 'ApiAccessKey_Organization.OrganizationName', caption: 'Organization' },
                            { name: 'ApiAccessKeyValue', caption: 'Key', width: 305 },
                            { name: 'ApiAccessKeyOrigin', caption: 'Allowed Referrer', colClass: 'text-center' },
                            {
                                name: 'ApiAccessKeyStatus',
                                caption: 'Status',
                                width: 110,
                                format: function (r) {
                                    if (r.ApiAccessKeyStatus === 0) return "Invalid";

                                    var $dropdown = uifactory.create.dropdown({
                                        options: [
                                            { label: 'Active', value: 1 },
                                            { label: 'Inactive', value: 2 }
                                        ],
                                        value: r.ApiAccessKeyStatus
                                    });

                                    $dropdown.find('.btn')
                                        .addClass('btn-block')
                                        .removeClass('btn-default')
                                        .toggleClass('btn-danger', r.ApiAccessKeyStatus === 2)
                                        .toggleClass('btn-success', r.ApiAccessKeyStatus === 1);

                                    return $dropdown.on('change', function () {
                                        var dropdown = $dropdown.data('rs-dropdown'),
                                            val = dropdown.selectedOption.value;

                                        dropdown.elements.icon
                                            .removeClass('caret').addClass('fa fa-spinner fa-spin');

                                        dropdown.elements.group
                                            .addClass('loading-options');

                                        dropdown.elements.button
                                            .toggleClass('btn-danger', val === 2)
                                            .toggleClass('btn-success', val === 1);

                                        ridestyler.ajax.send({
                                            action: 'ApiAccessKey/UpdateKey',
                                            data: {
                                                ApiAccessKeyID: r.ApiAccessKeyID,
                                                Status: val
                                            },
                                            callback: function (response) {
                                                if (response.Success)
                                                    r.ApiAccessKeyStatus = val;

                                                dropdown.elements.icon
                                                    .addClass('caret').removeClass('fa fa-spinner fa-spin');

                                                dropdown.updateSelectedOption(r.ApiAccessKeyStatus);

                                                dropdown.elements.button
                                                    .toggleClass('btn-danger', r.ApiAccessKeyStatus === 2)
                                                    .toggleClass('btn-success', r.ApiAccessKeyStatus === 1);

                                                dropdown.elements.group
                                                    .removeClass('loading-options');
                                            }
                                        });
                                    });
                                }
                            },
                            {
                                name: 'actions',
                                caption: '',
                                sort: false,
                                width: 100,
                                format: function (r) {
                                    return $('<div/>', { 'class': 'btn-group' }).append([
                                        $('<button/>', {
                                            "class": "btn btn-default",
                                            "append": $("<i/>", { "class": "fa fa-pencil" }),
                                            "click": function () {
                                                rter.routes.go("/app/organization/key/edit", {
                                                    ApiAccessKeyID: r.ApiAccessKeyID
                                                });
                                            }
                                        }),
                                        $('<button/>', {
                                            "class": "btn btn-primary",
                                            "append": $("<i/>", { "class": "fa fa-link" }),
                                            "data-key": r.ApiAccessKeyValue,
                                            "data-toggle": "modal",
                                            "data-target": "#launch-dialog"
                                        })
                                    ]);
                                }
                            }
                        ],
                        getAction: 'ApiAccessKey/GetKeys',
                        countAction: 'ApiAccessKey/CountKeys',
                        resultListField: 'Keys',
                        baseQueryData: {
                            Organization: organizationID
                        },
                        search: filters
                    });

                $view.find('input.search-input').val(filters.search);

                var currentKey;
                var $launchDialog = $view.find('#launch-dialog').on('show.bs.modal', function (e) {
                    var $modal = $(this),

                        $visualizerCheck = $("#launch-dialog-visualizer-check"),
                        $showcaseCheck = $('#launch-dialog-showcase-check'),
                        $urlInput = $('#launch-dialog-url');

                    currentKey = $(e.relatedTarget).data('key').replace(/-/g, '');

                    $visualizerCheck.add($showcaseCheck).iCheck('uncheck');
                    $urlInput.val("Select a interface above");
                    $('#launch-dialog-go').prop('disabled', true);
                }).on('ifChecked', function (e) {
                    var type = e.target.value,
                        $modal = $(this);

                    $modal.find(':checkbox').each(function () {
                        if (this.value !== type)
                            $(this).iCheck('uncheck');
                    });

                    var url, redirectURL;
                    switch (type) {
                        case "showcase":
                            url = "http://app.ridestyler.net/showcase?";
                            break;
                        case "visualizer":
                            url = "http://app.ridestyler.net/";
                            break;
                    }

                    $('#launch-dialog-url').val(url + currentKey);
                    $('#launch-dialog-go').prop('disabled', false);
                }).on('click', '#launch-dialog-go', function () {
                    window.open($('#launch-dialog-url').val(), '_blank');
                });
            }
        };

        function filtersUpdated() {
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);