(function ($) {
	window.rstools.utils = {
		parse: {
			bool: function (value) {
				switch (typeof value) {
					case "boolean": return value;
					case "string":
						if (!value.length) return false;

						switch (value.charAt(0)) {
							case "y":
							case "Y":
							case "T":
							case "t":
								return true;
							case "N":
							case "n":
							case "F":
							case "f":
								return false;
							default:
								return true;
						}

					default:
						return !!value;
				}
			}
		},

		compare: {
			id: function (a, b) {
				if (typeof a !== 'string') a = a.toString();
				if (typeof b !== 'string') b = b.toString();

				return a === b || a.replace('-', '') === b.replace('-','');
			}
		},

		format: {
			offset: function (o) {
				if (o < -1000) return ["-", $('<span/>', {'html': '&infin;', css: {verticalAlign: 'middle', fontSize: '1.5em'}})];
				else if (o > 1000) return [$('<span/>', { 'html': '&infin;', css: { verticalAlign: 'middle', fontSize: '1.5em' } })];
				else return [o, uifactory.create.abbr('mm', 'millmeters')];
			}
		},

		passwordIsValid: function (password) {
			return /^[a-zA-Z0-9@\._\-!$&?]{6,32}$/.test(password);
		},

		preloadImages: function (sources) {
			sources = $.makeArray(sources);
			var deferreds = new Array(sources.length);

			$.each(sources, function (i, src) {
				if (!src || typeof src !== 'string') return;

				var d = deferreds[i] = new $.Deferred();
				var img = new Image();

				img.src = src;
				img.onload = function () { d.resolve(img); };
				img.onerror = function () { d.reject(img); };
			});

			return deferreds;
		},

		filterFormDataToFilters: (function () {
			function setFilter(fitmentFilters, newFormData, filterKey, filterValue, groupKey)
			{
				if (groupKey) {
					var fitmentFilter = {};
					fitmentFilter['GroupKey'] = groupKey;
					fitmentFilter[filterKey] = filterValue;

					fitmentFilters.push(fitmentFilter);

					return fitmentFilter;
				} else {
					newFormData[filterKey] = filterValue;
				}

				return newFormData;
			}
			function setFilterWithMultiple(newFormData, filterKey, outKey, value) {
				if (value && typeof value === 'string' && value.indexOf(',') > -1)
					newFormData[outKey] = value.split(',');
				else
					newFormData[outKey] = [value];
			}


			return function (formData, filters) {
				var fitmentFilterKeys = {};
				var rangeKeys = {};
				var multipleKeys = {};

				var newFormData = {};
				var fitmentFilters = [];

				var i, key;

				for (i = 0; i < filters.length; i++) {
					var filter = filters[i];

					if (filter.fitmentFilter) fitmentFilterKeys[filter.name] = filter.fitmentFilter;
					if (filter.parseAsRange) rangeKeys[filter.name] = filter.parseAsRange;
					if (filter.parseAsMultiple) multipleKeys[filter.name] = filter.parseAsMultiple;
				}

				for (key in formData) {
					if (!formData.hasOwnProperty(key)) continue;

					var k = key;
					var value = formData[k];
					var isFitmentFilter = k in fitmentFilterKeys;

					if (k in rangeKeys) {
						var listMatches = rstools.utils.getListMatches(value);
						var listMatch, rangeMatch, ff = undefined;
						var fitmentFilterGroup = isFitmentFilter ? k : undefined;

						for (i = 0; i < listMatches.length; i++) {
							listMatch = listMatches[i];
							rangeMatch = rstools.utils.getRangeMatches(listMatch, true);

							if ('min' in rangeMatch) ff = setFilter(fitmentFilters, newFormData, k + 'Min', rangeMatch.min, fitmentFilterGroup);
							if ('max' in rangeMatch) {
								if (ff && fitmentFilterGroup) ff[k + 'Max'] = rangeMatch.max;
								else ff = setFilter(fitmentFilters, newFormData, k + 'Max', rangeMatch.max, fitmentFilterGroup);
							}
							if ('value' in rangeMatch) setFilter(fitmentFilters, newFormData, k, rangeMatch.value, fitmentFilterGroup);
						}

					} else if (k in multipleKeys)
						setFilterWithMultiple(newFormData, k, typeof multipleKeys[k] === 'string' ? multipleKeys[k] : k, value);
					else
						setFilter(fitmentFilters, newFormData, k, value, isFitmentFilter ? 'Filters': undefined);
				}

				if (fitmentFilters.length)
					newFormData['FitmentFilters'] = fitmentFilters;

				return newFormData;
			};
		})(),

		/**
		 * @param {string}  tokenString                              A string separated by dots, indicating a path in the startObject to return
		 * @param {object}  startObject                              The object to return the value from
		 * @param {function (object,object,string):void} [callback]  A function to call while recursing down the path with the parentObject, currentObject and currentToken, respectively
		 */
		getValueFromTokenString: function (tokenString, startObject, callback) {
			var tokens = tokenString.split('.');
			var parentObject = startObject;
			var currentObject = parentObject;

			if (typeof callback !== 'function') callback = $.noop;

			for (var ti = 0; ti < tokens.length; ti++) {
				var currentToken = tokens[ti];
				
				parentObject = currentObject;
				currentObject = parentObject[currentToken];

				callback(parentObject, currentObject, currentToken);
			}

			return currentObject;
		},

		getNamespace: function (namespaceString) {
			if (typeof namespaceString !== 'string') return namespaceString;

			var namespace = rstools.utils.getValueFromTokenString(namespaceString, rstools, function (parentObject, currentObject, currentToken) {
				currentObject._namespace = {
					token: currentToken,
					parent: parentObject
				};
			});

            rstools.utils.assert(namespace, "Could not find the namespace: " + namespace);

			return namespace;
		},

		getValueFromFormat: function (format, o) {
			switch (typeof format) {
				case "string":
					return rstools.utils.getValueFromTokenString(format, o);
				case "function":
					return format(o);
				default:
					return o;
			}
		},

		createMap: function (data, formatKey, formatValue) {
			var map = {};

			for (var i = data.length - 1; i >= 0; i--) {
				var d = data[i];

				var key = rstools.utils.getValueFromFormat(formatKey, d);
				var value = rstools.utils.getValueFromFormat(formatValue, d);

				if (typeof key !== "undefined" && typeof value !== "undefined")
					map[key] = value;
			}

			return map;
		},

		setBackButtonTargetFromContext: function ($button, ctx, backPath) {
            if (ctx.previousContext && ctx.previousContext.path === backPath) {
                var previousState = rter.utils.toParamString(ctx.previousState);

                $button.attr('href', '#!' + backPath + (previousState ? "?" + previousState : ""));
            } else {
                $button.attr('href', '#!' + backPath);
            }
        },

		addAbbreviations: function (string, replace, abbreviation, expandedAbbreviation) {
			if ($.isArray(string)) {
				return $.map(string, function (s) {
					return typeof s === 'string' ?
						rstools.utils.addAbbreviations(s, replace, abbreviation, expandedAbbreviation) :
						s;
				});
			}

			if ($.isArray(replace)) {
				$.each(replace, function (i, r) {
					string = rstools.utils.addAbbreviations(string, r[0], r[1], r.length > 2 ? r[2] : undefined);
				});

				return string;
			}

			var split = string.split(replace);

			if (typeof expandedAbbreviation === "undefined") {
				expandedAbbreviation = abbreviation;
				abbreviation = replace;
			}

			var splitLength = split.length;
			var abbr = uifactory.create.abbr(abbreviation, expandedAbbreviation);

			string = [];

			for (var i = 0; i < splitLength; i++) {
				string.push(split[i]);

				if (i < splitLength - 1)
					string.push(i == 0 ? abbr : abbr.cloneNode(false));
			}

			return string;
		},

		/**
		 * Returns an array of {label:value:} objects with numeric 
		 * keys from object as values and their values as the labels.
		 * @param {object} object The object to create dropdown options from
		 * @returns {Array} 
		 */
		dropdownOptionsFromObject: function (object) {
			var options = [];
			for (var k in object) if (object.hasOwnProperty(k) && !isNaN(k)) options.push({
				label: object[k],
				value: k
			});
			return options;
		},

		dropdownOptionsFromArray: function (array) {
			return $.map(array, function (option) {
				if (typeof option === 'object') return option;

				return {
					label: option,
					value: option
				};
			});
		},

		/**
		 * @typedef getFiltersForSettings
		 * @prop {string}   namespace             The namespace to use when creating filters
		 * @prop {string}   [parentNamespace]     The parent namespace, used for transforming filter form data into API filters
		 * @prop {any[]}    [rows]                Rows to return filters for
		 * @prop {boolean}  [loosenFilters=false] If true, loosen the filters
		 * @prop {any}      [filters]             The base set of filters to use if any
		 * @prop {boolean}  [fromForm=false]      Whether or not these filters are from a filter form
		 * @prop {string[]} [clearParams]         A list of parameters to clear before returning, defaults to: Search
		 *
		 * @param {getFiltersForSettings} settings
		 */
		getFiltersFor: function (settings) {
			'use strict';
			
			if (arguments.length > 1) {
				settings = arguments[1];
				settings.namespace = arguments[0];
			}

			settings = settings || {};

			var namespace = rstools.utils.getNamespace(settings.namespace);
			var parentNamespace = settings.parentNamespace ? rstools.utils.getNamespace(settings.parentNamespace) : namespace._namespace.parent;

			var filters = settings.filters ? $.extend({}, settings.filters) : {};

			// If these are marked as from form data, and is for the API, transform the form fields into API fields
			// if necessary
			if (settings.fromForm && settings.forAPI) {
				if ('filterFormDataToFilters' in parentNamespace.utils === false) throw "Missing 'filterFormDataToFilters' from parentNamespace.utils";

				parentNamespace.utils.filterFormDataToFilters(filters);
			}

			// Get filters for rows passed in
			if (settings.rows) {
				if ('createFilters' in namespace.utils === 'false') throw "Missing createFilters from namespace.utils";

				$.extend(filters, namespace.utils.createFilters(settings.rows));
			}

			// Loosen the filters
			if (settings.loosenFilters) {
				if ('createFilters' in namespace.utils === 'false') throw "Missing createFilters from parentNamespace.utils";
				parentNamespace.utils.loosenFilters(namespace._namespace.token, filters);
			}

			// Delete the list of parameters specified by clearParams
			{
				var clearParams = settings.clearParams || ['Search'];

				for (var i = 0; i < clearParams.length; i++)
					delete filters[clearParams[i]];
			}

			return filters;
		},

		fileSizeFormat: function (size) {
			if (!size) return "0 B";
			var i = Math.floor(Math.log(size) / Math.log(1024));
			return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
		},

		momentFromDatabaseDateTime: function (dateTimeString) {
			// Assume UTC if not timezone passed in
			return moment.utc(dateTimeString).local();
		},

		assert: function (assertion, message) {
			if (!assertion) {
				message = message || 'Assertion Failed';

				if (typeof Error !== 'undefined')
					throw new Error(message);

				throw message;
			}
		},

		findLikeData: function (results, includeAll, keepUnlikeKeys) {
			var likeData = {};

			// A constant denoting that the values for a key were different
			var UNLIKE = {};
			// A constant denoting that the values for this key were not found in this result
			var NOT_FOUND = {};

			if (typeof includeAll === 'boolean') {
				keepUnlikeKeys = includeAll;
				includeAll = [];
			}

			// Keys inside of includeAll show up as an array of values
			// from each result inside of the like data object
			includeAll = $.makeArray(includeAll);

			var allKeys = [];

			var i, j, k, result, value;

			if (results && results.length) {
				for (i = results.length - 1; i >= 0; i--) {
					for (k in results[i]) {
						if (allKeys.indexOf(k) < 0)
							allKeys.push(k);
					}
				}

				for (i = results.length - 1; i >= 0; i--) {
					result = results[i];

					for (j = allKeys.length - 1; j >= 0; j--) {
						k = allKeys[j];
						value = k in result ? result[k] : NOT_FOUND;

						// If the key is in the includeAll list
						if ($.inArray(k, includeAll) >= 0) {
							// Add its value to the key's array in the like data object
							if (!likeData[k]) likeData[k] = [];
							likeData[k].push(value);
						} else if (k in likeData === false) {
							// If the key was not already in like data, add it to like data
							likeData[k] = value;
						} else {
							// If the key is in like data
							if ($.isArray(value) && $.isArray(likeData[k])) {
								// If the key is an array, compare the array values
								if ($(value).not(likeData[k]).length > 0 || $(likeData[k]).not(value).length > 0) {
									// If the result's array does not have the same elements as the array already in like data
									// then remove it
									likeData[k] = UNLIKE;
								}
							} else if (likeData[k] !== value) {
								// If the result's value does not equal the value from like data
								// then remove it
								likeData[k] = UNLIKE;
							}
						}
					}
				}
			}

			if (!keepUnlikeKeys) {
				// Remove any values that were not alike (represented by the UNLIKE constant in the like data object)
				for (k in likeData) if (likeData[k] === UNLIKE) delete likeData[k];
			} else {
				for (k in likeData) if (likeData[k] === UNLIKE) likeData[k] = undefined;
			}

			return likeData;
		},
		apiDeferred: function (settings) {
			var d = new $.Deferred();

			settings = settings || {};
			if (typeof settings.callback === "function") d.always(settings.callback);
			settings.callback = function (response) {
				var args = [response];
				var success = response.Success;

				if (success && settings.responseKey && settings.responseKey in response)
					args[0] = response[settings.responseKey];

				d[success ? 'resolve' : 'reject'].apply(d, args);
			};

			var xhr = ridestyler.ajax.send(settings);

			d.abort = function () {
				xhr.abort();
			};

			return d;
		},
		loadPaginatedList: function (action, countAction, resultKey, data, requireResults) {
			var maxRequestLength = 2000;

			if (requireResults !== true) requireResults = false;

			var countFinished = new $.Deferred();
			var listRetrieved = new $.Deferred();
			var batch = ridestyler.ajax.batch();

			if (typeof countAction === 'string') {
				ridestyler.ajax.send({
					action: countAction,
					data: data,
					callback: function (r) {
						if (!r.Success) countFinished.reject(r);
						else if (requireResults && r.Count === 0) countFinished.reject({ Message: 'No results returned from the API'});
						else countFinished.resolve(r.Count);
					}
				});
			} else {
				countFinished.resolve(maxRequestLength);
			}

			countFinished.done(function (count) {
				for (var i = 0; i < count; i += maxRequestLength) {
					batch.send(i, {
						action: action,
						data: $.extend({
							Start: i,
							Count: maxRequestLength
						}, data)
					});
				}

				batch.execute();
			});

			batch.done(function (batchResults) {
				var results = [];
				var hasResults = false;

				var batchResult;
				for (var k in batchResults) {
					batchResult = batchResults[k];

					results.push({ i: k, results: batchResult });

					if (!batchResult) throw "Invalid result";
					if (resultKey in batchResult === false || !batchResult[resultKey].length) throw "Invalid result key";
					if (batchResult[resultKey].length) hasResults = true;
				}

				if (requireResults && !hasResults) return listRetrieved.reject({
					Message: 'No results returned from the API'
				});

				results.sort(function (a, b) { return a.i - b.i; });

				listRetrieved.resolve($.map(results, function (batchResult) {
					return batchResult.results[resultKey];
				}));
			});

			var rejectDeferred = function (r) { listRetrieved.reject(r); };
			batch.fail(rejectDeferred);
			countFinished.fail(rejectDeferred);

			return listRetrieved;
		},

		/**
		 * Returns a title given a namespace, the rows and a leading header.
		 * 
		 * @param {string}   namespace The namespace to use with utils.getTitles
		 *                             and noun. See rstools.utils.getNamespace
		 * @param {object[]} rows      A list of rows to get the tile of
		 * @param {string}   header    A leading header to put before the title
		 * @returns {string} A title to use
		 */
		getTitle: function (namespace, rows, header) {
			namespace = rstools.utils.getNamespace(namespace);

			if (!namespace.nounPlural) throw "Missing nounPlural from namespace";
            if (!namespace.utils.getTitles) throw "Missing utils.getTitles from namespace";

			if (!rows || !rows.length || !namespace) return header;

			var title;
			if (rows.length === 1) title = namespace.utils.getTitles(rows)[0];
			else title = rows.length + ' ' + namespace.nounPlural;

			return header ?
				'<strong>' + header + ':</strong> ' + title :
				title;
		},

		/**
		 * Resolve a paremeter that can be of many different types into a standard format.
		 * If a function is passed in, it will be called and the return value will be returned.
		 * Otherwise, the value will be returned as-is.
		 */
		resolveParameter: function (option) {
			switch (typeof option) {
				case "function":
					return option();
				default:
					return option;
			}
		},

		/**
		 * Returns an array from a string formatted as a list
		 * @param {string} string The string to parse
		 * @param {bool} numericOnly If true, only numeric values will be returned
		 * @returns {Array} 
		 */
		getListMatches: function (string, numericOnly) {
			if (string && typeof string !== 'string') string = string.toString();
			var listMatches = string.split(/(?:\s*,+\s*)|(?:\s+or\s+)/gi);

			if (numericOnly) {
				var numericMatches = [], value;

				for (var i = 0; i < listMatches.length; i++) {
					value = parseFloat(listMatches[i]);

					if (isNaN(value) === false && isFinite(value))
						numericMatches.push(value);
				}

				return numericMatches;
			}

			return listMatches;
		},

		/**
		 * Returns an object with min, max and/or value keys depending on the 
		 * format of string.
		 * @param {string} string The string to find ranges in
		 * @param {bool} numericOnly Whether or not to force values to be numeric and throw out non-numbers.
		 * @returns {object} An object with min, max and/or value keys describing the range format of string.
		 */
		getRangeMatches: function (string, numericOnly) {
			if (string && typeof string !== 'string') string = string.toString();

			var numericRangeMatch = string.match(/^(?:\s*from\s*)?(.*?)(?:-|to)\s*(.*)$/i);
			var returnValue = {};

			if (numericRangeMatch) {
				if (numericRangeMatch[1]) returnValue.min = numericRangeMatch[1];
				if (numericRangeMatch[2]) returnValue.max = numericRangeMatch[2];
			} else {
				returnValue.value = string;
			}

			for (var k in returnValue) {
				var v = $.trim(returnValue[k]);
				var valid;

				if (numericOnly) {
					v = parseFloat(v);
					valid = !isNaN(v);
				} else {
					valid = v;
				}

				if (valid) returnValue[k] = v;
				else delete returnValue[k];
			}

			return returnValue;
		},

		/**
		 * Returns true if the keyboard combination or key matches the supplied event.
		 * @param  {array|number|char} combo An array of KeyCodes (see rstools.constants.KeyCode) or chars, or a singular KeyCode/char.
		 * @param  {Event}             e     The event to test against
		 * @return {bool}                    Whether or not the combination or key matches the event.
		 */
		keyboardComboMatchesEvent: function (combo, e) {
			if ($.isArray(combo)) {
				for (var i = combo.length - 1; i >= 0; i--)
					if (!rstools.utils.keyboardComboMatchesEvent(combo[i], e))
						return false;
				return true;
			}

			var key = combo;
			var KeyCode = rstools.constants.KeyCode;

			if (key === KeyCode.Ctrl) return e.ctrlKey;
			if (key === KeyCode.Meta) return e.metaKey;
			if (key === KeyCode.Shift) return e.shiftKey;
			if (key === KeyCode.Option) return e.altKey;
			if (key === KeyCode.Cmd) return rstools.compatibility.mac ? e.metaKey : e.ctrlKey;

			if (typeof key === 'number') return e.which === key;
			if (typeof key === 'string')
				return key.length ?
					String.fromCharCode(e.which).toUpperCase() === key[0].toUpperCase() :
					false;
		},

		generateInterfaceURL: function(interfaceType, key) {
			var url;
			switch (environment) {
				case ENVIRONMENTS.Live: url = 'http://app.ridestyler.net';       break;
				case ENVIRONMENTS.Beta: url = 'http://app-beta.ridestyler.net';  break;
				default:                url = 'http://app-alpha.ridestyler.net'; break;
			}

			if (!key || typeof key !== 'string') {
				url += '/login';
				if (interfaceType === 'showcase') url += '?ReturnURL=/showcase';
			} else {
				if (interfaceType === 'showcase') url += '/showcase';

				url += interfaceType === 'showcase' ? '?' : '/';
				url += key.replace(/\-/g, '');
			}

			return url;
		}
	};
})(jQuery);

function TokenScorer(a, b) {
	if ($.isArray(a)) a = a.join(' ');
	if ($.isArray(b)) b = b.join(' ');

	this.a = this.tokenize(a); this.b = this.tokenize(b);

	this.mismatchedTokens = {
		a: [], // Tokens in a not found in b
		b: []  // Tokens in b not found in a
	};
	this.tokenMatchingRules = [];
	this.likeTokens = [];
}
TokenScorer.prototype = {
	tokenize: function (str) {
		str = $.trim(str.toLowerCase());

		return str.split(/\s+|-/);
	},
	getMatches: function (a, b) {
		var matches = [],
			mismatchedTokens = this.mismatchedTokens,
			sourceIndex = a === this.a ? 'a' : 'b';

		$.each(a, function (i, token) {
			if ($.inArray(token, b) >= 0) matches.push(token);
			else mismatchedTokens[sourceIndex].push(token);
		});

		return matches;
	},
	hasToken: function (token, from) {
		return $.inArray(token, this[from]) >= 0;
	},
	tokenMatchesRule: function (token, rule, from) {
		if (typeof rule.match === 'function') return rule.match(token, from);
		else if (rule.match instanceof RegExp) return rule.match.test(token);
		else if (typeof rule.match === 'string') return rule.match === token;
		else if ($.isArray(rule.match)) {
			for (var i = rule.match.length - 1; i >= 0; i--)
				if (this.tokenMatchesRule(token, { match: rule.match[i] }, from)) return true;
		}
		else if (typeof rule.match === 'undefined') return true;

		return false;
	},
	matchMismatchedTokens: function (from) {
		var mismatchedTokens = this.mismatchedTokens[from],
			ignoredTokens = [], matches = [],
			thisScorer = this,
			tokenMatchingRules = this.tokenMatchingRules.concat([
				{
					rule: function (token, from) {
						var matched = false;
						var to = from === 'b' ? 'a' : 'b';

						$.each(thisScorer.likeTokens, function (i, likeTokenArray) {
							likeTokenArray = $.makeArray(likeTokenArray);

							var tokenIndex = $.inArray(token, likeTokenArray);

							if (tokenIndex >= 0) {
								likeTokenArray.splice(tokenIndex, 1);

								$.each(likeTokenArray, function (i, likeToken) {
									if (thisScorer.hasToken(likeToken, to)) {
										matched = true;
										return false; // Break loop
									}
								});

								if (matched) return false; // Break loop
							}
						});

						return matched;
					}
				}
			]);

		$.each(mismatchedTokens, function (i, token) {
			$.each(tokenMatchingRules, function (j, rule) {
				if (thisScorer.tokenMatchesRule(token, rule, from)) {
					var ruleResult = false;

					if (typeof rule.rule === 'function')
						ruleResult = rule.rule.call(thisScorer, token, from);

					if (rule.mode === 'ignore') {
						ignoredTokens.push(token);
						return false; // Break loop
					}

					if (ruleResult) {
						// If ruleResult is true instead of a number matches is incremented by 1
						matches.push(token);
					}

					return false; // Break loop
				}
			});
		});

		return {
			ignoredTokens: ignoredTokens,
			matches: matches
		};
	},
	score: function () {
		var a = this.a, b = this.b;

		var aMatches = this.getMatches(a, b),
			bMatches = this.getMatches(b, a),
			ignoredTokens = [];

		var aMismatchResult = this.matchMismatchedTokens('a'),
			bMismatchResult = this.matchMismatchedTokens('b');

		ignoredTokens = {
			a: aMismatchResult.ignoredTokens,
			b: bMismatchResult.ignoredTokens
		};
		aMatches = aMatches.concat(aMismatchResult.matches);
		bMatches = bMatches.concat(bMismatchResult.matches);

		return {
			score: (aMatches.length + bMatches.length) / (a.length + b.length - ignoredTokens.a.length - ignoredTokens.b.length),

			matches: {
				a: aMatches,
				b: bMatches
			},

			tokens: {
				a: a,
				b: b
			},

			ignored: ignoredTokens,

			total: a.length + b.length
		};
	},
	adjust: function (score, a, b) {
		var aMatches = score.matches.a.length + a,
			bMatches = score.matches.b.length + b;

		score.score = (aMatches + bMatches) / (score.tokens.a.length + score.tokens.b.length - score.ignored.a.length - score.ignored.b.length + a + b);

		return score;
	}
};