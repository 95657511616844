(function ($) {
    rstools.events.on('userchanged', function() {
        updateVisualizerButton();
    });

    function updateVisualizerButton() {
       $('#nav-open-visualizer').css('display', rstools.auth.user.get("HasSubscription") ? '' : 'none'); 
    }

    function initializeVisualizerButton ($navBar) {
        var subscription = rstools.user.subscription;
        var useKey = subscription && subscription.OrganizationSubscriptionSessionCount > 0;

        $('#nav-open-visualizer', $navBar)
            .popover({
                title: 'Open a Visualizer',
                placement: 'bottom',
                html: true,
                trigger: 'focus',
                selector: '#nav-open-visualizer-content',
                content: function () {
                    var $content = $('<div/>', {
                        css: {
                            minWidth: 240
                        },
                        id: 'nav-open-visualizer-content'
                    });
                    var $loader = $('<div/>', {'class': 'loading'}).appendTo($content);
                    var $button = $(this);

                    var hide = function () { $button.popover('hide'); };

                    var loadKey = !useKey ? $.Deferred() : rstools.utils.apiDeferred({
                        action: 'ApiAccessKey/GetKeys',
                        data: {
                            Organization: rstools.auth.user.getActiveOrganizationID(),
                            Count: 1
                        },
                        responseKey: 'Keys'
                    });

                    $.when(rstools.setup.isComplete(), loadKey)
                        .done(function (setupComplete, keys) {
                            $loader.remove();
                            $loader = undefined;

                            var key = keys.length ? keys[0].ApiAccessKeyValue : undefined;

                            if (!setupComplete) {
                                $content.append([
                                    "Your account is not setup yet. To use the visualizer ",
                                    $('<a/>', {
                                        text: 'setup your account',
                                        href: '#!/app/setup'
                                    }).click(hide),
                                    ' first.'
                                ]);

                                return;
                            }

                            $('<label/>', {
                                text: 'Interface:',
                                for: 'show-visualizer-shortcut-interface-dropdown'
                            }).appendTo($content);

                            var $screenshot = $('<img/>', {
                                'class': 'img-responsive center-block img-thumbnail',
                                'src': '/images/gui/screens/default.png',
                                'alt': 'Screenshot of Wheel/Tire Gallery',
                                'css': {
                                    'marginTop': 10
                                }
                            });

                            var url = rstools.utils.generateInterfaceURL('default', key);
                            var $url = $('<input/>', {
                                'type': 'url',
                                'class': 'form-control',
                                'readonly': true,
                                'value': url
                            });
                            var $urlOpen = $('<a/>', {
                                'class': 'btn btn-primary',
                                'append': uifactory.create.icon('link'),
                                'href': url,
                                'target': '_blank',
                                'title': 'Open interface in new tab'
                            });

                            uifactory.create.dropdown({
                                buttonClass: 'btn-primary btn-block',
                                buttonID: 'show-visualizer-shortcut-interface-dropdown',
                                options: [
                                    {
                                        label: 'Wheel/Tire Gallery',
                                        value: 'default'
                                    },
                                    {
                                        label: 'Wheel Showcase',
                                        value: 'showcase'
                                    }
                                ]
                            })
                                .on('change', function () {
                                    var selection = $(this).data('rs-dropdown').selectedOption;

                                    url = rstools.utils.generateInterfaceURL(selection.value, key);

                                    $screenshot
                                        .attr({
                                            'src': '/images/gui/screens/' + selection.value + '.png',
                                            'alt': 'Screenshot of ' + selection.label
                                        });

                                    $url.val(url);
                                    $urlOpen.attr('href', url);
                                })
                                .addClass('btn-block')
                                .appendTo($content)
                                .find('button').focus();

                            $screenshot.appendTo($content);

                            $('<div/>', {
                                'class': 'input-group',
                                'css': {
                                    'marginTop': 10
                                },
                                'append': [
                                    $url,
                                    $('<span/>', {
                                        'class': 'input-group-btn',
                                        'append': $urlOpen
                                    })
                                ]
                            }).appendTo($content);
                        });

                    return $content;
                }
            })
            .on('click', function () { $(this).popover('toggle'); });

        updateVisualizerButton();
    }

    function initializePlugins($navbar) {
        /**
         * Returns the .dropdown-menu for the .dropdown or .dropdown-menu matched by the selector, or a zero
         * length jQuery element;
         * 
         * @param {string} selector A selector selecting a .dropdown-menu or .dropdown element
         * @returns {JQuery}
         */
        var findMenu = function (selector) {
            var $selectedElement = $navbar.find(selector);

            if ($selectedElement.is('.dropdown'))
                return $selectedElement.children('.dropdown-menu');

            return $selectedElement.filter('.dropdown-menu');
        };

        /**
         * @param {rstools.plugins.MenuSettings} menuSettings
         * @returns {HTMLAnchorElement}
         */
        var createMenuOption = function (menuSettings) {
            var $menu = findMenu(menuSettings.selector);

            if (!$menu.length) {
                console.error('Could not find .dropdown-menu or .dropdown matching: ' + menuSettings.selector);
                return undefined;
            }

            var li = document.createElement('li');
            var link = document.createElement('a');
                link.href = '#';
                link.appendChild(document.createTextNode(menuSettings.label));
            
            li.appendChild(link);

            if (typeof menuSettings.insertAfter === 'string' && menuSettings.insertAfter.length) {
                $menu.find(menuSettings.insertAfter).after(li);
            } else {
                $menu.append(li);
            }
            
            return link;
        };

        $.each(rstools.plugins.registeredPlugins, function (i, plugin) {
            var menuSettings = plugin.settings.menuSettings;

            if (!menuSettings) return;

            menuSettings = $.makeArray(menuSettings);

            $.each(menuSettings, function (j, menuSetting) {
                var menuLink = createMenuOption(menuSetting);
                
                if (menuLink) menuLink.onclick = function () {
                    plugin.activate();

                    return false;
                };
            });
        });
    }

    window.rstools.ui = {
        initialize: function () {
            
        },

        initializeNavBar: function ($navBar) {
            var user = rstools.auth.user.get();
            user && $('#user-dropdown-username', $navBar).text(user.User.UserName);

            initializeVisualizerButton($navBar);
            initializePlugins($navBar);

            rstools.datashard.initSwitcher($navBar);
        },

        setFooterHidden: function (hidden) {
            $('.footer')[hidden ? 'hide' : 'show']();
        },

        onAppViewChanged: function (context) {
            $('#nav-open-visualizer').popover('hide');
            rstools.ui.setFooterHidden(rter.routes.get(context.path).hideFooter);
            rstools.ui.navigation.closeMenus();
        },

        navigation: {
            closeMenus: function() {
                $(window).dropdown('toggle');
                $('#primary-navigation-collapse.in').collapse('hide');
            }
        },
        utils: {
        }
    };

    rstools.ui.initialize();
})(jQuery);