(function ($) {
	function loosenFilters(type, filters) {
		filters = filters || {};

		filters.UsePromotionOrder = false;
		filters.HasFitments = false;
		filters.SelectBrandsOnly = false;

		if (type !== 'brandResources' && type !== 'fitmentResources')
			filters.IncludeArchived = true;

		return filters;
	}

	rstools.wheel = {

		settings: {
			filters: [
				{
					name: 'Search',
					type: 'text-search',
					label: 'Search',
					required: false
				},
				{
					'for': ['brand'],
					type: 'dropdown',
					label: 'Has Logo',
					group: 'Resources',
					name: 'HasLogo',
					required: false,
					options: [
						{ 'label': 'Yes or No', value: undefined },
						'-',
						{ 'label': 'Yes', value: 'true' },
						{ 'label': 'No', value: 'false' }
					]
				},
				{
					'for': ['model', 'fitment'],
					type: 'dropdown',
					label: 'Catalog Image',
					group: 'Resources',
					name: 'HasCatalogImage',
					required: false,
					options: [
						{ 'label': 'Yes or No', value: undefined },
						'-',
						{ 'label': 'Yes', value: 'true' },
						{ 'label': 'No', value: 'false' }
					]
				},
				{
					'for': ['model', 'fitment'],
					type: 'dropdown',
					label: 'Angled Image',
					group: 'Resources',
					name: 'HasAngledImage',
					required: false,
					options: [
						{ 'label': 'Yes or No', value: undefined },
						'-',
						{ 'label': 'Yes', value: 'true' },
						{ 'label': 'No', value: 'false' }
					]
				},
				{
					'for': ['model', 'fitment'],
					type: 'dropdown',
					label: 'Side Image',
					group: 'Resources',
					name: 'HasSideImage',
					required: false,
					options: [
						{ 'label': 'Yes or No', value: undefined },
						'-',
						{ 'label': 'Yes', value: 'true' },
						{ 'label': 'No', value: 'false' }
					]
				},
				{
					type: 'dropdown',
					required: false,
					name: 'WheelBrandStatus',
					label: 'Inclusion Status',
					group: 'Wheel',
					parseAsMultiple: 'WheelBrandStatuses',
					options: [
						{ label: 'Choose a brand status', value: false },
						'-',
						{ label: 'Disabled', value: '0,2' },
						{ label: 'Enabled', value: '1,5' }
					]
				},
				{
					type: 'dropdown',
					required: false,
					name: 'WheelFinishCategory',
					label: 'Finish',
					group: 'Wheel',
					url: 'Wheel/GetFinishes',
					data: {
						SelectBrandsOnly: false
					},
					responseKey: 'Finishes',
					resultMap: function (finishCategory) {
						return {
							value: finishCategory.WheelFinishCategoryID,
							label: finishCategory.WheelFinishCategoryName
						};
					},
					initialOptions: [{ label: 'Choose a finish', value: false }, '-']
				},
				{
					type: 'dropdown',
					name: 'Promotion',
					label: 'Promotion',
					group: 'Wheel',
					url: 'Promotion/GetPromotions',
					data: {
						DisableRestrictions: true
					},
					responseKey: 'Promotions',
					resultMap: function(promotion) {
						return {
							value: promotion.PromotionID,
							label: promotion.PromotionName
						};
					},
					required: false,
					initialOptions: [{ label: 'Choose a promotion', value: false }, '-']
				},
				{
					label: 'Bolt Pattern',
					name: 'BoltPattern',
					group: 'Fitment',
					fitmentFilter: true,

					type: 'search',
					required: false,

					url: 'Wheel/GetBoltPatterns',
					responseKey: 'BoltPatterns',
					idColumnName: 'BoltPatternID',
					requestData: {
						SelectBrandsOnly: false,
						ApplyGlobalFilters: false
					},
					resultMap: function(boltPattern) {
						return {
							id: boltPattern.BoltPatternID,
							name: boltPattern.BoltPatternDescription
						};
					}
				},
				{
					label: 'Diameter',
					required: false,
					group: 'Fitment',
					name: 'Diameter',
					fitmentFilter: true,
					parseAsRange: true,
					labelHintSummary: 'Range',
					labelHintText: 'You may enter a range into this filter.'
				},
				{
					label: 'Width',
					required: false,
					name: 'Width',
					group: 'Fitment',
					fitmentFilter: true,
					parseAsRange: true,
					labelHintSummary: 'Range',
					labelHintText: 'You may enter a range into this filter.'
				},
				{
					label: 'Offset',
					required: false,
					group: 'Fitment',
					name: 'Offset',
					fitmentFilter: true,
					parseAsRange: true,
					labelHintSummary: 'Range',
					labelHintText: 'You may enter a range into this filter.'
				},
				{
					label: 'Centerbore',
					required: false,
					group: 'Fitment',
					name: 'Hub',
					fitmentFilter: true,
					parseAsRange: true,
					labelHintSummary: 'Range',
					labelHintText: 'You may enter a range into this filter.'
				},
				{
					label: 'Center Cap',
					name: 'CenterCap',
					group: 'Fitment',
					fitmentFilter: true,
					type: 'dropdown',
					required: false,
					initialOptions: [{ label: 'Choose a center cap', value: false }, '-'],
					options: rstools.utils.dropdownOptionsFromObject(rstools.constants.WheelCenterCapType)
				},
				{
					label: 'Wheel Hole Type',
					name: 'WheelHoleType',
					group: 'Fitment',
					fitmentFilter: true,
					type: 'dropdown',
					required: false,
					initialOptions: [{ label: 'Choose a wheel hole type', value: false }, '-'],
					options: rstools.utils.dropdownOptionsFromObject(rstools.constants.WheelHoleType)
				},
				{
					label: 'Data Source',
					required: false,
					group: 'Other',
					name: 'DataSource',
					type: 'dropdown',
					multiple: true,
					clearWhenFalse: true,

					url: 'DataSource/GetDataSources',
					responseKey: 'DataSources',
					parseAsMultiple: 'DataSources',
					resultMap: function(dataSource) {
						return {
							value: dataSource.DataSourceID,
							label: dataSource.DataSourceDescription
						};
					},
					initialOptions: [{ label: 'Choose a data source', value: false }, '-'],
					labelHintSummary: 'Multiple',
					labelHintText: 'You may select multiple values for this filter.'
				},
				{
					label: 'Include Archived',
					required: false,
					group: 'Other',
					name: 'IncludeArchived',
					type: 'checkbox',
					requiredRoles: [rstools.roles.SYSTEM_ADMIN]
				},
				{
					label: 'Last Updated (min)',
					required: false,
					group: 'Other',
					type: 'datetime',
					name: 'LastUpdatedMin'
				},
				{
					label: 'Last Updated (max)',
					required: false,
					group: 'Other',
					type: 'datetime',
					name: 'LastUpdatedMax'
				},
				{
					for: ['model', 'fitment'],
					label: 'Data Status',
					required: false,
					group: 'Other',
					name: 'DataStatus',
					type: 'dropdown',
					requiredRoles: [rstools.roles.SYSTEM_ADMIN],
					initialOptions: [{ label: 'Any', value: false }, '-'],
					options: rstools.utils.dropdownOptionsFromObject(rstools.constants.DataStatus)
				}
			],
			attributes: ["Dually", "Trailer"]
		},

		utils: {
			loosenFilters: loosenFilters,
			createWheelFilterForm: function (ctx, settings) {
				var pageName = ctx.routes[ctx.routes.length - 1].name;

				settings = settings || {
					filters: undefined,
					onReset: undefined,
					onSubmit: undefined
				};

				var stateFilters = ctx.state.filters || {};

				// Store the first field with a group of Wheel for later use
				var firstWheelGroupIndex = false;

				// Grab all applicable filters for use as fields in the filter form
				var fields = $.map(rstools.wheel.settings.filters, function(filter, i) {
					if (filter.for && !~$.inArray(pageName, filter.for)) return undefined;
					if (filter.group === 'Wheel' && firstWheelGroupIndex === false) firstWheelGroupIndex = i;
					return filter;
				});

				var filterFieldMap = {
					'WheelBrands': {
						type: 'static',
						label: 'Brand',
						group: 'Wheel',
						fieldClass: 'static-filter',
						ridestyler: {
							action: 'Wheel/GetBrandDescriptions',
							data: {
								WheelBrands: stateFilters.WheelBrands,

								UsePromotionOrder: false,
								HasFitments: false,
								SelectBrandsOnly: false
							},
							responseKey: 'Brands',
							format: function (r) {
								return r.WheelBrandName + ' (Data Source: ' + r.DataSourceCode + ')';
							}
						}
					},
					'WheelModels': {
						type: 'static',
						label: 'Model',
						group: 'Wheel',
						fieldClass: 'static-filter',
						ridestyler: {
							action: 'Wheel/GetModelDescriptions',
							data: {
								WheelModels: stateFilters.WheelModels,

								UsePromotionOrder: false,
								HasFitments: false,
								SelectBrandsOnly: false
							},
							responseKey: 'Models',
							format: function(r) {
								return r.WheelModelName + ' (' + r.WheelBrandName + ': ' + r.DataSourceCode + ')';
							}
						}
					}
				};

				// Move filters from filterFieldMap if they're active
				// to be in the first Wheel group spot
				for (var k in filterFieldMap)
					if (k in stateFilters)
						fields.splice(firstWheelGroupIndex, 0, filterFieldMap[k]);

				return uifactory.create.filterForm({
					filters: settings.filters,
					fields: fields,
					onSubmit: settings.onSubmit,
					onReset: settings.onReset
				});
			},

			/**
			 * Converts the tools wheel filter form data to RS WheelFilterModel filters to simplify
			 * the FitmentFilter syntax (and keep it away from rstool's state/URL).
			 * @param {object} formData The form data to convert
			 * @returns {object} WheelFilterModel 
			 */
			filterFormDataToWheelFilters: function (formData) {
				return rstools.utils.filterFormDataToFilters(formData, rstools.wheel.settings.filters);
			}
		}
	};
})(jQuery);