rter.mapController('app.wheel.brand.sort', function (context) {
    function prioritiesShouldOnlyApplyToUser() {
        return !rstools.user.hasRole(rstools.roles.WHEELS) || rstools.state.get('user-only');
    }

    function setPriorities($list, objectIDs) {
        var changeUserPriorities = rstools.utils.apiDeferred({
            action: 'Wheel/ChangeBrandPriorities',
            data: {
                ObjectIdentifiers: objectIDs,
                IgnoreUserPreferences: false
            },
        });

        var changeOrgPriorities = prioritiesShouldOnlyApplyToUser() ?
            $.Deferred().resolve() :
            rstools.utils.apiDeferred({
                action: 'Wheel/ChangeBrandPriorities',
                data: {
                    ObjectIdentifiers: objectIDs,
                    IgnoreUserPreferences: true
                }
            });


        return $.when(changeUserPriorities, changeOrgPriorities)
            .always(function () {
                $list.find('li').fadeTo(250, 1, function () { $list.removeClass('loading'); });
            })
            .done(function () {
                uifactory.alert.show({ text: 'Your priorities have been saved!', stack: 'wheel_priorities_changed' });
            })
            .fail(function () {
                uifactory.alert.show({
                    text: 'There was an error saving your priorities!',
                    stack: 'wheel_priorities_changed',
                    type: 'error'
                });
            });
    }

    return {
        actions: {
            clear: function() {
                var $list = context.$outlet.find('ul.brand-list');

                $list.addClass('loading').find('li').fadeTo(250, 0.5);

                setPriorities($list, []).done(function() {
                    refresh(prioritiesShouldOnlyApplyToUser(), context.$outlet);
                });
            },
            save: function () {
                var objectIDs = [];

                var $list = context.$outlet.find('ul.brand-list');

                $list.addClass('loading').find('li').fadeTo(250, 0.5);

                $list.find('li').each(function () {
                    objectIDs.push(this.getAttribute('data-brand-id'));
                });

                setPriorities($list, objectIDs);
            }
        },
        viewCreated: function ($outlet) {
            var currentUserOnlyState = prioritiesShouldOnlyApplyToUser();

            $outlet.find('#priorities-apply-to-org')
                .on('change ifChanged', function () {
                    var selected = this.checked;
                    rstools.state.set('user-only', !selected);
                    refresh(selected, $outlet);
                })
                .prop('checked', !currentUserOnlyState);

            refresh(!currentUserOnlyState, $outlet);

            $outlet.find('ul.brand-list')
                .on('sortupdate', function() {
                    $(this).find('li input').each(function(i) {
                        this.value = i + 1;
                    });
                })
                .on('change', 'input', function() {
                    var value = this.value;
                    var $li = $(this).parent();

                    var index = $li.index();

                    if (isNaN(parseInt(value))) {
                        this.value = index + 1;
                        return;
                    }

                    var $list = $li.parent();
                    var $listChildren = $list.children();

                    value -= 1;
                    value = Math.min(Math.max(0, value), $listChildren.length);

                    if (value == $listChildren.length) $li.appendTo($list);
                    else if (value <= index) $li.insertBefore($listChildren.eq(value));
                    else $li.insertAfter($listChildren.eq(value));
                    
                    $list.sortable('refreshPositions').trigger('sortupdate');
                });
        }
    };

    function refresh(ignorePref, $outlet) {

        var $list = $outlet.find('ul.brand-list');

        $list.empty().append('<li class="list-group-item">Loading... <i class="fa fa-spinner fa-spin"></i></li>');

        ridestyler.ajax.send({
            action: 'Wheel/GetBrands',
            data: {
                IgnoreUserPreferences: ignorePref,
                UseBrandPriorities: true
            },
            callback: function (r) {
                // Empty our list (this is to protect against 
                $list.removeClass('loading').children().remove();

                // If we got a valid result back then we need to update the UI
                if (r.Success) {
                    if (r.Brands.length === 0) {
                        $('<div class="well">No brands enabled. Use the brand management screen to enable/disable brands.</div>').appendTo($list);
                    } else {
                        // Generate our list items since we actually have valid results
                        for (var i = 0; i < r.Brands.length; i++) {
                            var b = r.Brands[i];
                            var li = document.createElement('li');

                            li.className = 'list-group-item';
                            li.setAttribute('data-brand-id', b.WheelBrandID);

                            var barIcon = document.createElement('i');

                            barIcon.className = "fa fa-bars";
                            barIcon.style.marginRight = "20px";


                            var input = document.createElement('input');

                            input.type = 'text';
                            input.className = 'form-control input-sm input-inline text-center';
                            input.maxLength = 3;
                            input.value = i + 1;
                            input.style.width = "43px";
                            input.style.marginRight = "10px";

                            li.appendChild(barIcon);
                            li.appendChild(input);
                            li.appendChild(document.createTextNode(b.WheelBrandName));

                            $list.append(li);
                        }

                        ridestyler.ajax.send({
                            action: 'Wheel/GetBrandResources',
                            data: {
                                HasFitments: false
                            },
                            callback: function(response) {
                                if (response.Success) {
                                    var brandResources = response.Resources;
                                    var $brandRows = $list.children();

                                    for (var j = 0; j < brandResources.length; j++) {
                                        var brandResource = brandResources[j];

                                        for (var k = 0; k < $brandRows.length; k++) {
                                            var brandRow = $brandRows[k];

                                            if (brandRow.getAttribute('data-brand-id') === brandResource.WheelBrandResource_WheelBrandID) {
                                                brandRow.style.backgroundImage = "url(" + ridestyler.ajax.url('Resource/Image', {
                                                    Resource: brandResource.WheelBrandResource_ResourceID,
                                                    Width: 150,
                                                    Height: 50,
                                                    PositionX: 2,
                                                    PositionY: 1
                                                }) + ')';
                                                brandRow.style.backgroundOrigin = 'content-box';
                                                brandRow.style.backgroundRepeat = 'no-repeat';
                                                brandRow.style.backgroundPosition = 'right';

                                                $brandRows.splice(k, 1);
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        })

                        // Setup our list for drag/drop support
                        $list.sortable().disableSelection();
                    }
                }
            }
        });
    }
});