(function ($) {
    rter.mapController('app.tire.size', function (context) {
        var grid = null;
        var filters = {};
        var $filterForm, $filterButton;

        return {
            viewWillLoad: function (ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($outlet) {
                grid =
                    datagrid.create({
                        container: $outlet.find('div.data-wrapper'),
                        allowShowAll: true,

                        headerActions: [
                            rstools.shared.datagrid.headerActions.manageColumns
                        ],
                        getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
                        
                        fields: [
                            { name: 'TireSizeDescription', caption: 'Description' },
                            { name: 'TireSizeOutsideDiameter', caption: 'Outside Diameter' },
                            { name: 'TireSizeWidth', caption: 'Width' },
                            { name: 'TireSizeInsideDiameter', caption: 'Inside Diameter' },
                            { 
                                name: 'TireSizeType', 
                                caption: 'Type', 
                                format: function (tireSize) {
                                    return rstools.constants.TireSizeType[tireSize.TireSizeType]
                                }   
                            },
                            { 
                                name: 'TireSizeDataStatus', 
                                caption: 'Status',
                                colClass: 'text-center',
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false,
                                format: rstools.datastatus.createColumn('tire.size')
                            },
                            rstools.shared.columns.createLastUpdatedColumn('TireSizeUpdated'),
                            {
                                name: 'TireSizeNotes',
                                caption: 'Notes',
                                colClass: 'text-center',
                                sort: false,
                                columnRenderer: rstools.notes.createColumnRenderer('tire.size'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
                            },
                            { name: 'actions', caption: '', width: '100px', sort: false }
                        ],

                        actions: [
                            rstools.datastatus.actions.markValid('tire.size'),
                            rstools.datastatus.actions.markInvalid('tire.size'),
                            rstools.datastatus.actions.markNeedsReviewed('tire.size'),
                            rstools.datastatus.actions.markReviewed('tire.size')
                        ],

                        getAction: 'Tire/GetSizes',
                        countAction: 'Tire/CountSizes',
                        resultListField: 'Sizes',
                        baseQueryData: {
                            SelectBrandsOnly: false
                        },

                        search: rstools.tire.utils.filterFormDataToTireFilters(filters),
                        namespace: 'tire-size'
                    });
                
                $filterForm = rstools.tire.utils.createTireFilterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });

                var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .appendTo($leftButtons)
                    .trigger('filters-updated', [Object.keys(filters).length]);
            },
            viewLoaded: function (ctx) {
                $filterForm.syncObjectToForm(filters);
            }
        };

        function filtersUpdated() {
            $filterForm.syncFormToObject(filters);
            $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(rstools.tire.utils.filterFormDataToTireFilters(filters));
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);