
(function ($) {
    rter.mapController('app.account.subscription', function (context) {
        return {
            viewWillLoad: function (context) {
            },
            viewWillCreate: function ($view) {
            },
            viewCreated: function ($view) {
            },
            viewWillResume: function ($view) {
            },
            viewResumed: function ($view) {
            },
            viewLoaded: function (context) {
                var $out = context.$outlet;

                ridestyler.ajax.send({
                    action: "Subscription/Current",
                    callback: function (response) {
                        if (response.Subscription) {
                            rstools.auth.user.update('Subscription', response.Subscription);

                            $out.find('#view-nosub').hide();
                            var $v = $out.find('#view-sub').show();

                            $out.find('#sub-user-label').text(response.Subscription.OrganizationSubscriptionUserCount);
                            $out.find('#sub-request-label').text(response.Subscription.OrganizationSubscriptionSessionCount);

                            $out.find('#sub-end')
                                .attr('datetime', response.Subscription.OrganizationSubscriptionEnd + 'Z')
                                .text(new Date(Date.parse(response.Subscription.OrganizationSubscriptionEnd)).toDateString());

                            if (response.IsCancelPending) {
                                $v.find('#sub-cancel-warning').show();
                            } else {
                                $v.find('#sub-cancel-warning').hide();
                            }

                        } else {
                            $out.find('#view-sub').hide();
                            $out.find('#view-nosub').show();
                        }
                    }
                });
            },
            viewWillUnload: function ($view) {
            },
            viewWillDetatch: function ($view) {
            },
            viewDetatched: function ($view) {
            },
            viewWillRemove: function ($view) {
            },
            viewRemoved: function ($view) {
            },
            viewUnloaded: function ($view) {
            }
        };
    });
})(jQuery);