(function() {
    var loosenFilters = rstools.tire.utils.loosenFilters;
    var loadPaginatedList = rstools.utils.loadPaginatedList;

    var TireBrandResourceType = rstools.constants.TireBrandResourceType;
    var ResourceAssetType = rstools.constants.ResourceAssetType;

    /**
     * @typedef GetTitlesSettings
     * @property {boolean} html?        If false (by default), output text, otherwise output HTML elements
     * @property {boolean} dataSource?  If true (by default), display the data source along with the brand name
     */

    /**
     * @typedef TireBrandDescription
     * @property {string} TireBrandName The name of the tire brand
     * @property {string} DataSourceCode? The short data source description
     */

    var UtilityProvider = {
        /**
         * Returns the title to display for a brand
         * @param {TireBrandDescription} brand    The tire brand
         * @param {GetTitlesSettings}    settings
         * @returns {string|HTMLElement} 
         */
        getTitle: function (brand, settings) {
            var brandName = brand.TireBrandName;
            var dataSource = brand.DataSourceCode.trim();

            var displayDataSource = settings.dataSource && dataSource;

            if (settings.html) {
                // Render in HTML
                var frag = document.createElement('span');
                
                // Render the brand name
                frag.appendChild(document.createTextNode(brandName));

                if (displayDataSource) {
                    // Render the data source code
                    var dataSourceLabel = document.createElement('span');

                    // As a label
                    dataSourceLabel.className = 'label label-default';
                    dataSourceLabel.appendChild(document.createTextNode(dataSource));

                    // Append it to the brand name after a space
                    frag.appendChild(document.createTextNode(' '));
                    frag.appendChild(dataSourceLabel);
                }

                return frag;
            } else {
                // Render a string
                return displayDataSource ?
                    brandName + ' (' + dataSource + ')' :
                    brandName;
            }
        },

        createBrandForm: function (type, settings) {
            var url;

            settings = $.extend({
                isMultiple: false,
                data: {},
                filters: {},
                submitSuccessCallback: undefined,
                onCancel: undefined
            }, settings);

            var data = settings.data;
            var s = settings.isMultiple ? 's' : '';

            switch (type) {
                case "edit":
                    url = 'Tire/EditBrand';
                    break;
                case "create":
                    url = 'Tire/CreateBrand';
                    break;
            }

            if (typeof url !== 'string') return undefined;

            var dataSourceField;
            var fields = $.map([
                {
                    label: 'Name',
                    name: 'TireBrandName',
                    maxLength: 128,
                    helpText: 'The name of the tire brand' + s,
                    value: data.TireBrandName,
                    required: !settings.isMultiple,
                    hasMultipleValues: 'TireBrandName' in data === false
                },
                dataSourceField = {
                    label: 'Data Source',
                    name: 'TireBrand_DataSourceID',
                    type: 'dropdown',
                    required: !settings.isMultiple,

                    url: 'DataSource/GetDataSources',
                    responseKey: 'DataSources',
                    resultMap: function (dataSource) {
                        return {
                            value: dataSource.DataSourceID,
                            label: dataSource.DataSourceDescription
                        };
                    },
                    data: {
                        sort: {
                            DataSourceDescription: 'Ascending'
                        }
                    },
                    initialOptions: [{ label: 'Choose a data source', value: false }, '-'],
                    value: data.DataSourceID,
                    hasMultipleValues: 'DataSourceID' in data === false,
                    actions: rstools.user.isSystemAdmin() ? [
                        {
                            icon: 'plus',
                            title: 'New Data Source',
                            callback: function() {
                                rstools.datasource.create();
                            }
                        }
                    ] : undefined
                }
            ], function (field) {
                // Modify fields dynamically here
                if (type === "create") field.hasMultipleValues = false;

                return field;
            });

            var $form = uifactory.create.form({
                url: url,
                submitErrorMessage: 'There was a problem ' + (type === 'create' ? 'creat' : 'edit') + 'ing the tire brand' + s + ': {Message}.',

                submitSuccessCallback: function () {
                    var verbPast = (type === 'create' ? 'created.' : 'edited.');

                    uifactory.alert.show({
                        text: !settings.isMultiple ? "The tire brand has been " + verbPast : "The tire brands have been " + verbPast
                    });

                    if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback();
                },

                fields: fields,
                baseData: settings.filters,

                actions: [
                    {
                        label: type === 'create' ? 'Create' : 'Save',
                        icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
                        buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
                        action: 'submit'
                    },

                    {
                        label: 'Cancel',
                        action: settings.onCancel
                    }
                ]
            });

            rstools.events.dataChanged(function(e, changed) {
                if (changed === 'datasource') dataSourceField.reload();
            }, $form);

            return $form;
        }
    };


    rstools.tire.brand = {
        utils: {
            getIDs: function (brands) {
                return $.map(brands, function (brand) {
                    return brand.TireBrandID;
                });
            },
            /**
             * Returns titles for a list of tire brands
             * @param  {TireBrandDescription[]} brands   The brands to get the titles for
             * @param  {GetTitlesSettings}     settings
             * @return {string|HTMLElement|string[]|HTMLElement[]} If passed an array, returns an array of titles for the brands
             * 													   passed in. Otherwise returns a single title.
             */
            getTitles: function (brands, settings) {
                settings = $.extend({
                    html: false,
                    dataSource: true
                }, settings);
                
                if (!$.isArray(brands)) return UtilityProvider.getTitle(brands, settings);

                return $.map(brands, function (brand) {
                    return UtilityProvider.getTitle(brand, settings);
                });
            },
            createFilters: function (brands, loosen) {
                var filters = {
                    TireBrands: rstools.tire.brand.utils.getIDs(brands)
                };
                
                if (loosen) loosenFilters('brand', filters);

                return filters;
            },
            isUnarchived: function (/** @type {TireBrandDataObject}*/ brand) {
                return !brand.TireBrandArchived;
            },
            isArchived: function (/** @type {TireBrandDataObject}*/ brand) {
                return brand.TireBrandArchived;
            }
        },

        edit: function (tireFilters) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;

            var brandDescriptions = [];

            loosenFilters('brand', tireFilters);

            var brandDescriptionsLoaded = loadPaginatedList('Tire/GetBrandDescriptions', 'Tire/CountBrands', 'Brands', tireFilters).done(function (brands) {
                brandDescriptions = brands;
            });

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: $('<h4/>'),
                startLoading: true
            });

            $.when(brandDescriptionsLoaded)
                .done(function () {
                    var isMultiple = brandDescriptions.length > 1;

                    modal.$modal.removeClass('loading');

                    modal.settings.header.html(isMultiple ? "Edit " + brandDescriptions.length + " Tire Brands" : "<strong>Edit Brand:</strong> " + brandDescriptions[0].TireBrandName);

                    UtilityProvider.createBrandForm('edit', {
                        isMultiple: isMultiple,
                        data: isMultiple ? rstools.utils.findLikeData(brandDescriptions) : brandDescriptions[0],
                        filters: tireFilters,
                        submitSuccessCallback: function () {
                            rstools.events.dataChanged('tire-brand', 'edit');
                            modal.hide();
                        },
                        onCancel: function () {
                            modal.hide();
                        }
                    }).appendTo(modal.$body);

                    modal.setDisplayID($.map(brandDescriptions, function (b) { return b.TireBrandID; }));
                })
                .fail(function (response) {
                    modal.hide();
                    rstools.api.utils.showResponseError('There was an error loading information about the tire brand(s).', response);
                });

            modal.show();
        },
        create: function (tireData) {
            if (!rstools.user.verifyRole(rstools.roles.SYSTEM_ADMIN, rstools.roles.TIRES)) return;

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: 'Create a Tire Brand',
                body: UtilityProvider.createBrandForm('create', {
                    data: tireData,
                    submitSuccessCallback: function () {
                        rstools.events.dataChanged('tire-brand', 'create');
                        modal.hide();
                    },
                    onCancel: function () {
                        modal.hide();
                    }
                })
            });

            modal.show();
        },
        
        manageResources: function (tireBrandResourceFilters, settings) {
            var filters = tireBrandResourceFilters;

            settings = $.extend({
                header: (rstools.user.isSystemAdmin() ? 'Manage' : 'View') + ' Tire Brand Resources'
            }, settings);

            loosenFilters('brandResources', filters);

            var modal = uifactory.modal.create({
                header: settings.header,
                footer: false,
                cancelable: true
            });

            var $row = $('<div/>', { 'class': 'row' }).appendTo(modal.$body);
            var $column = $('<div/>', { 'class': 'col-sm-4 col-sm-offset-4' }).appendTo($row);

            function loadResources() {
                loadPaginatedList('Tire/GetBrandResources', 'Tire/CountBrandResources', 'Resources', filters)
                    .done(function (brandResources) {
                        function mapBrandResourceID(brs) { return brs.TireBrandResource_ResourceID; }

                        uifactory.create.resourceThumbnail({
                            resources: $.map(brandResources, mapBrandResourceID).unique(),
                            title: "Logo",
                            imageParameters: {
                                Width: 200,
                                Height: 200,
                                PositionX: 1,
                                PositionY: 1
                            },
                            onLink: function () {
                                rstools.resource.list({
                                    mode: 'select',
                                    onSelect: function (resource) {
                                        modal.$modal.addClass('loading');

                                        ridestyler.ajax.send({
                                            action: 'Tire/LinkBrandResource',
                                            data: $.extend({}, filters, {
                                                ResourceID: resource.ResourceID,
                                                TireBrandResourceType: TireBrandResourceType.Logo
                                            }),
                                            callback: function (response) {
                                                if (response.Success) {
                                                    loadResources();
                                                    rstools.events.dataChanged('tire-brand', 'resource-link');
                                                } else {
                                                    uifactory.alert.show({ type: 'error', text: 'There was an error linking the resources(s): ' + response.Message });
                                                    modal.$modal.removeClass('loading');
                                                }
                                            }
                                        });
                                    },
                                    filters: {
                                        AssetType: ResourceAssetType.TireBrandImage
                                    },
                                    selectFilter: function (r) {
                                        return r.ResourceAssetType === ResourceAssetType.TireBrandImage;
                                    }
                                });
                            },
                            onUnlink: function () {
                                var confirm = uifactory.modal.create({
                                    type: 'confirmation',
                                    cancelable: true,
                                    header: 'Unlink Brand Resources',
                                    confirmationYes: 'Unlink',
                                    confirmationYesClass: 'btn btn-danger',
                                    body: 'Are you sure you wish to unlink all logos from the selected tire brand?',
                                    confirmationCallback: function (saidYes) {
                                        if (saidYes) {
                                            modal.$modal.addClass('loading');

                                            ridestyler.ajax.send({
                                                action: 'Tire/UnlinkBrandResource',
                                                data: $.extend({}, filters, {
                                                    WheelBrandResourceType: TireBrandResourceType.Logo
                                                }),
                                                callback: function (response) {
                                                    if (response.Success) {
                                                        loadResources();
                                                        confirm.hide();
                                                        rstools.events.dataChanged('tire-brand', 'resource-unlink');
                                                    } else {
                                                        uifactory.alert.show({ type: 'error', text: 'There was an error unlinking the resources(s).' });
                                                        modal.$modal.removeClass('loading');
                                                    }
                                                }
                                            });
                                        }
                                    }
                                });

                                confirm.show();
                            }
                        }).appendTo($column.empty());

                        modal.$modal.removeClass('loading');
                    });
            }

            loadResources();

            modal.$modal.addClass('loading');

            modal.show();
        },

        getResources: function (filters) {
            return rstools.utils.apiDeferred({ action: 'Tire/GetBrandResources', responseKey: 'Resources', data: filters });
        },

        noun: 'tire brand',
        nounPlural: 'tire brands',
        dataNamespace: 'tire-brand',

        archiveAction: 'Tire/ArchiveBrands',
        unarchiveAction: 'Tire/UnarchiveBrands',
        
        getAction: 'Tire/GetBrands',
        getActionResponseKey: 'Brands',
        countAction: 'Tire/CountBrands',
        
        getMetaAction: 'Tire/GetBrandMeta',
        setMetaAction: 'Tire/SetBrandMeta',
        clearMetaAction: 'Tire/ClearBrandMeta',

        getPromotionStatusAction: 'Promotion/GetTireFitmentStatus',
        setPromotionStatusAction: 'Promotion/SetTireFitmentStatus'
    };
})();