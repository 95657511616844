(function ($) {
    rter.mapController('app.message.channel', function (context) {
        var grid = null;
        var filters = {};
        var $filterForm, $filterButton;

        return {
            viewWillLoad: function(ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($view) {
                grid = datagrid.create({
                    container: $view.find('div.data-wrapper'),
                    allowShowAll: true,
                    fields: [
                        { name: 'MessageChannelName', caption: 'Name' },
                        { name: 'actions', colClass: 'text-right', caption: '', width: 155, sort: false }
                    ],
                    actions: [
                        {
                            title: 'List Messages',
                            icon: 'fa fa-th-list',
                            id: 'list',
                            multiple: true,
                            fields: ['actions'],
                            callback: function (channels) {
                                var filters = {
                                    MessageChannels: $.map(channels, function (channel) {
                                        return channel.MessageChannelID;
                                    })
                                };

                                rter.routes.go('/app/message/list', { filters: filters });
                            }
                        }
                    ],
                    getAction: 'Message/GetChannels',
                    countAction: 'Message/CountChannels',
                    resultListField: 'Channels',
                    namespace: 'message-channel',
                    rowSelect: function (channel) {
                        grid.action.getByID('list').callback([channel]);
                    }
                });
            },
            viewLoaded: function (ctx) {
            }
        };

        function filtersUpdated() {
            $filterForm.syncFormToObject(filters);
            $filterButton.trigger('filters-updated', [Object.keys(filters).length]);
            
			grid.search(filters);
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);