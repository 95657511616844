rstools.tire = (function() {
    function loosenFilters (type, filters) {
		filters = filters || {};

        filters.UsePromotionOrder = false;
        filters.HasFitments = false;
        filters.SelectBrandsOnly = false;

        if (type !== 'brandResources' && type !== 'fitmentResources')
            filters.IncludeArchived = true;

        return filters;
    }

    return {
        settings: {
            filters: [
                {
                    name: 'Search',
                    type: 'text-search',
                    label: 'Search',
                    required: false
                },
                {
                    'for': ['brand'],
					type: 'dropdown',
					label: 'Has Logo',
					group: 'Resources',
					name: 'HasLogo',
					required: false,
					options: [
						{ 'label': 'Yes or No', value: undefined },
						'-',
						{ 'label': 'Yes', value: 'true' },
						{ 'label': 'No', value: 'false' }
					]
                },
                {
					'for': ['model', 'fitment'],
					type: 'dropdown',
					label: 'Catalog Image',
					group: 'Resources',
					name: 'HasCatalogImage',
					required: false,
					options: [
						{ 'label': 'Yes or No', value: undefined },
						'-',
						{ 'label': 'Yes', value: 'true' },
						{ 'label': 'No', value: 'false' }
					]
				},
				{
					'for': ['model', 'fitment'],
					type: 'dropdown',
					label: 'Side Image',
					group: 'Resources',
					name: 'HasSideImage',
					required: false,
					options: [
						{ 'label': 'Yes or No', value: undefined },
						'-',
						{ 'label': 'Yes', value: 'true' },
						{ 'label': 'No', value: 'false' }
					]
				},
				{
					'for': ['model', 'fitment'],
					type: 'dropdown',
					label: 'Tread Image',
					group: 'Resources',
					name: 'HasTreadImage',
					required: false,
					options: [
						{ 'label': 'Yes or No', value: undefined },
						'-',
						{ 'label': 'Yes', value: 'true' },
						{ 'label': 'No', value: 'false' }
					]
				},
				{
					type: 'dropdown',
					required: false,
					name: 'TireBrandStatus',
					label: 'Inclusion Status',
					group: 'Tire',
					parseAsMultiple: 'TireBrandStatuses',
					options: [
						{ label: 'Choose a brand status', value: false },
						'-',
						{ label: 'Disabled', value: '0,2' },
						{ label: 'Enabled', value: '1,5' }
					]
				},
				{
					label: 'Sidewalls',
					required: false,
					group: 'Tire',
					name: 'TireSidewalls',
					type: 'dropdown',
					multiple: true,
					clearWhenFalse: true,

					url: 'Tire/GetSidewalls',
					responseKey: 'Sidewalls',
                    data: loosenFilters('sidewall', {}),
					parseAsMultiple: 'TireSidewalls',
					resultMap: function(/** @type {TireSidewallDataObject} */ sidewall) {
						return {
							value: sidewall.TireSidewallID,
							label: sidewall.TireSidewallName
						};
					},
					initialOptions: [{ label: 'Choose a sidewall', value: false }, '-'],
					labelHintSummary: 'Multiple',
					labelHintText: 'You may select multiple values for this filter.'
				},
				{
					type: 'dropdown',
					name: 'Promotion',
					label: 'Promotion',
					group: 'Tire',
					url: 'Promotion/GetPromotions',
					data: {
						DisableRestrictions: true
					},
					responseKey: 'Promotions',
					resultMap: function(promotion) {
						return {
							value: promotion.PromotionID,
							label: promotion.PromotionName
						};
					},
					required: false,
					initialOptions: [{ label: 'Choose a promotion', value: false }, '-']
				},
				{
					type: 'dropdown',
					name: 'UseType',
					label: 'Use Type',
					group: 'Tire',
                    options: rstools.utils.dropdownOptionsFromObject(rstools.constants.TireUseTypeFlags),
					required: false,
                    multiple: true,
					clearWhenFalse: true,
					initialOptions: [{ label: 'Choose a Use Type', value: false }, '-'],
					labelHintSummary: 'Multiple',
					labelHintText: 'You may select multiple values for this filter.',
					selectedValueFilter: function (value, selectedValue) {
						if (selectedValue === undefined || selectedValue === false) return value === false;
						return selectedValue == value;
					}
				},
				{
					type: 'dropdown',
					name: 'WarrantyType',
					label: 'Warranty Type',
					group: 'Tire',
                    options: rstools.utils.dropdownOptionsFromObject(rstools.constants.TireWarrantyType),
					required: false,
					initialOptions: [{ label: 'Choose a Warranty Type', value: false }, '-']
				},
				{
					label: 'Speed Rating',
					required: false,
					group: 'Tire',
					name: 'SpeedRatings',
					type: 'dropdown',
					multiple: true,
					clearWhenFalse: true,

					url: 'Tire/GetSpeedRatings',
					responseKey: 'SpeedRatings',
                    data: loosenFilters('speed-rating', {}),
					parseAsMultiple: 'SpeedRatings',
					resultMap: function(/** @type {TireSpeedRatingDataObject} */ speedRating) {
						return {
							value: speedRating.TireSpeedRatingID,
							label: speedRating.TireSpeedRatingCode
						};
					},
					initialOptions: [{ label: 'Choose a speed rating', value: false }, '-'],
					labelHintSummary: 'Multiple',
					labelHintText: 'You may select multiple values for this filter.'
				},
				{
					label: 'Load Index',
					required: false,
					group: 'Tire',
					name: 'LoadIndexes',
					type: 'dropdown',
					multiple: true,
					clearWhenFalse: true,

					url: 'Tire/GetLoadIndexes',
					responseKey: 'LoadIndexes',
                    data: loosenFilters('load-index', {}),
					parseAsMultiple: 'LoadIndexes',
					resultMap: function(/** @type {TireLoadIndexDataObject} */ loadIndex) {
						return {
							value: loadIndex.TireLoadIndexID,
							label: loadIndex.TireLoadIndexCode
						};
					},
					initialOptions: [{ label: 'Choose a load index', value: false }, '-'],
					labelHintSummary: 'Multiple',
					labelHintText: 'You may select multiple values for this filter.'
				},
                {
					label: 'Diameter',
					required: false,
					group: 'Fitment',
					name: 'Diameter',
					fitmentFilter: true,
					parseAsRange: true,
					labelHintSummary: 'Range',
					labelHintText: 'You may enter a range into this filter.'
				},
                {
					label: 'Outside Diameter',
					required: false,
					group: 'Fitment',
					name: 'OutsideDiameter',
					fitmentFilter: true,
					parseAsRange: true,
					labelHintSummary: 'Range',
					labelHintText: 'You may enter a range into this filter.'
				},
				{
					label: 'Width',
					required: false,
					name: 'Width',
					group: 'Fitment',
					fitmentFilter: true,
					parseAsRange: true,
					labelHintSummary: 'Range',
					labelHintText: 'You may enter a range into this filter.'
				},
				{
					label: 'Offset',
					required: false,
					group: 'Fitment',
					name: 'Offset',
					fitmentFilter: true,
					parseAsRange: true,
					labelHintSummary: 'Range',
					labelHintText: 'You may enter a range into this filter.'
				},
                {
                    label: 'Has Pricing',
                    required: false,
                    group: 'Other',
                    name: 'HasPricing',
                    type: 'checkbox'
                },
                {
                    label: 'Include Archived',
                    required: false,
                    group: 'Other',
                    name: 'IncludeArchived',
                    type: 'checkbox',
                    requiredRoles: [rstools.roles.SYSTEM_ADMIN]
                },
                {
                    for: ['model', 'fitment', 'size'],
                    label: 'Data Status',
                    required: false,
                    group: 'Other',
                    name: 'DataStatus',
                    type: 'dropdown',
                    requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                    initialOptions: [{ label: 'Any', value: false }, '-'],
                    options: rstools.utils.dropdownOptionsFromObject(rstools.constants.DataStatus)
                }
            ],
			attributes: [
                "Winter",
                "Performance",
                "Competition",
                "Traction",
                "All Season",
                "All Terrain"
            ]
        },

        utils: {
            createStaticFilterFields: function (filters, fieldMap) {
                fieldMap = fieldMap || {};

                fieldMap.TireBrands = {
                    type: 'static',
                    label: 'Brand',
                    group: 'Tire',
                    fieldClass: 'static-filter',
                    ridestyler: {
                        action: 'Tire/GetBrandDescriptions',
                        data: {
                            TireBrands: filters.TireBrands,

                            UsePromotionOrder: false,
                            SelectBrandsOnly: false
                        },
                        responseKey: 'Brands',
                        format: function (r) {
                            return r.TireBrandName + ' (Data Source: ' + r.DataSourceCode + ')';
                        }
                    }
                };

                fieldMap.TireModels = {
                    type: 'static',
                    label: 'Model',
                    group: 'Tire',
                    fieldClass: 'static-filter',
                    ridestyler: {
                        action: 'Tire/GetModelDescriptions',
                        data: {
                            TireModels: filters.TireModels,

                            UsePromotionOrder: false,
                            SelectBrandsOnly: false
                        },
                        responseKey: 'Models',
                        format: function (r) {
                            return r.TireModelName + ' (' + r.TireBrandName + ': ' + r.DataSourceCode + ')';
                        }
                    }
                };

                return fieldMap;
            },
            createTireFilterForm: function(ctx, settings) {
                var pageName = ctx.routes[ctx.routes.length - 1].name;
                
                settings = settings || {
                    filters: undefined,
                    onSubmit: undefined,
                    onReset: undefined
                };

                var stateFilters = ctx.state.filters || {};

                // Store the first field with a group of Tire for later use
                var firstTireGroupIndex = false;

                // Grab all applicable filters for use as fields in the filter form
                var fields = $.map(rstools.tire.settings.filters, function (filter, i) {
                    if (filter.for && !~$.inArray(pageName, filter.for)) return undefined;
                    if (filter.group === 'Tire' && firstTireGroupIndex === false) firstTireGroupIndex = i;
                    return filter;
                });

                var filterFieldMap = rstools.tire.utils.createStaticFilterFields(stateFilters);

                // Move filters from filterFieldMap if they're active
                // to be in the first Tire group spot
                for (var k in filterFieldMap)
                    if (k in stateFilters) 
                        fields.splice(firstTireGroupIndex, 0, filterFieldMap[k]);

                return uifactory.create.filterForm({
                    filters: settings.filters,
                    fields: fields,
                    onSubmit: settings.onSubmit,
                    onReset: settings.onReset
                });
            },

            loosenFilters: loosenFilters,

			/**
			 * Converts the tools wheel filter form data to RS WheelFilterModel filters to simplify
			 * the FitmentFilter syntax (and keep it away from rstool's state/URL).
			 * @param {object} formData The form data to convert
			 * @returns {object} WheelFilterModel 
			 */
			filterFormDataToTireFilters: function (formData) {
				return rstools.utils.filterFormDataToFilters(formData, rstools.tire.settings.filters);
			}
        }
    };
})();