(function ($) {
    rter.mapController('app.tire.model', function (context) {
        var grid = null;
        var filters = {};
        var $filterForm, $filterButton;
        var $backToBrandsButton;

        /** @type {TireFitmentResourceType} */
        var TireFitmentResourceType = rstools.constants.TireFitmentResourceType;

        var createCombinedFilters = function (rows, forAPI, loosenFilters) {
            return rstools.utils.getFiltersFor('tire.model', {
                rows: rows,
                forAPI: forAPI,
                loosenFilters: loosenFilters,
                filters: filters
            });
        };

        return {
            viewWillLoad: function (ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($outlet) {
                grid =
                    datagrid.create({
                        container: $outlet.find('div.data-wrapper'),
                        allowShowAll: true,

                        headerActions: [
                            rstools.shared.datagrid.headerActions.manageColumns
                        ],
                        getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
                        
                        fields: [
                            { name: 'TireBrandName', caption: 'Brand' },
                            { name: 'DataSourceCode', caption: 'Data Source' },
                            { name: 'TireModelName', caption: 'Model' },
                            {
                                name: 'TireModelResources',
                                sort: false,
                                caption: 'Images',
                                colClass: 'no-padding',
                                format: function (/** @type {TireModelDescriptionModel} */ model) {
                                    var container = $('<div/>', { 'class': 'thumbnail-grid' });

                                    var side = $('<span/>').text('Side');
                                    var catalog = $('<span/>').text('Catalog');
                                    var tread = $('<span/>').text('Tread');

                                    if (rstools.user.isSystemAdmin()) {
                                        if (model.HasSideImage) {
                                            $('<img/>', {
                                                'src': 'images/gui/transparent.gif',
                                                'data-original': ridestyler.ajax.url('Tire/Image', {
                                                    TireModel: model.TireModelID,
                                                    Width: 50,
                                                    Height: 50,
                                                    PositionX: 1,
                                                    PositionY: 1,
                                                    TireFitmentResourceType: TireFitmentResourceType.Side
                                                })
                                            }).lazyload().prependTo(side);

                                            if (model.SideImageCount > 1)
                                                side.append([' ', uifactory.create.icon('th').addClass('text-warning')]);
                                        } else side.addClass('text-muted');

                                        if (model.HasCatalogImage) {
                                            $('<img/>', {
                                                'src': 'images/gui/transparent.gif',
                                                'data-original': ridestyler.ajax.url('Tire/Image', {
                                                    TireModel: model.TireModelID,
                                                    Width: 50,
                                                    Height: 50,
                                                    PositionX: 1,
                                                    PositionY: 1,
                                                    TireFitmentResourceType: TireFitmentResourceType.Catalog
                                                })
                                            }).lazyload().prependTo(catalog);

                                            if (model.CatalogImageCount > 1)
                                                catalog.append([' ', uifactory.create.icon('th').addClass('text-warning')]);
                                        } else catalog.addClass('text-muted');

                                        if (model.HasTreadImage) {
                                            $('<img/>', {
                                                'src': 'images/gui/transparent.gif',
                                                'data-original': ridestyler.ajax.url('Tire/Image', {
                                                    TireModel: model.TireModelID,
                                                    Width: 50,
                                                    Height: 50,
                                                    PositionX: 1,
                                                    PositionY: 1,
                                                    TireFitmentResourceType: TireFitmentResourceType.Tread
                                                })
                                            }).lazyload().prependTo(tread);

                                            if (model.TreadImageCount > 1)
                                                tread.append([' ', uifactory.create.icon('th').addClass('text-warning')]);
                                        } else tread.addClass('text-muted');
                                    } else {
                                        if (!model.HasSideImage) side.addClass('text-muted');
                                        if (!model.HasCatalogImage) catalog.addClass('text-muted');
                                        if (!model.HasTreadImage) tread.addClass('text-muted');

                                        $(side).add(catalog).add(tread).addClass('label label-default');
                                    }

                                    return container.append([side, catalog, tread]);
                                }
                            },
                            {
                                name: 'Attributes',
                                caption: 'Attributes',
                                colClass: 'text-small',
                                format: function (/** @type {TireModelDescriptionModel} */ model) {
                                    return model.Attributes && model.Attributes.length ? 
                                        model.Attributes.join(', ') : 
                                        uifactory.create.text.muted('None');
                                }
                            },
                            {
                                name: 'Sidewalls',
                                caption: 'Sidewalls',
                                format: function (r) {
                                    return r.Sidewalls ?
                                        $.map(r.Sidewalls, rstools.tire.sidewall.createAbbrElement) :
                                        uifactory.create.text.muted('None');
                                },
                                sort: false
                            },
                            { 
                                name: 'TireModelDataStatus', 
                                caption: 'Status',
                                colClass: 'text-center',
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false,
                                format: rstools.datastatus.createColumn('tire.model')
                            },
                            rstools.shared.columns.createLastUpdatedColumn('TireModelUpdated'),
                            {
                                name: 'TireModelNotes',
                                caption: 'Notes',
                                colClass: 'text-center',
                                sort: false,
                                columnRenderer: rstools.notes.createColumnRenderer('tire.model'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
                            },
                            { name: 'actions', caption: '', width: '100px', sort: false }
                        ],

                        actions: [
                            {
                                title: 'View Fitments',
                                id: 'list',
                                icon: 'fa fa-th-list',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rter.routes.go('/app/tire/fitment', {
                                        filters: createCombinedFilters(rows)
                                    });
                                }
                            },
                            
                            {
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: true,
                                forMenu: true,
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.tire.model.edit(createCombinedFilters(rows, true));
                                }
                            },

                            {
								title: 'Manage Promotions',
								icon: 'fa fa-tags',
								multiple: true,
								forMenu: true,
								fields: ['actions'],
								requiredRoles: [rstools.roles.TIRES],
								callback: function (rows) {
                                    rstools.promotion.manage('tire.model', createCombinedFilters(rows, true));
								}
                            },
                            
                            rstools.shared.actions.createPasteAction('tire.fitment', 'tire.model', {
                                callback: function () {
                                    grid.page.refresh();
                                }
                            }),

                            rstools.meta.actions.edit('tire.model'),

                            

                            {
                                title: 'Duplicate',
                                icon: 'fa fa-clone',
                                multiple: true,
                                fields: ['actions'],
                                forMenu: true,
                                callback: function (rows) {
                                    rstools.tire.model.duplicate(createCombinedFilters(rows, true));
                                }
                            },
                            {
                                title: 'Merge',
                                icon: 'fa fa-code-fork',
                                multiple: true,
                                single: false,
                                fields: ['actions'],
                                forMenu: true,
                                callback: function (rows) {
                                    rstools.tire.model.merge(createCombinedFilters(rows, true));
                                }
                            },
                            {
                                title: 'Create Fitment',
                                icon: 'fa fa-plus',
                                forMenu: true,
                                multiple: false,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                callback: function(rows) {
                                    rstools.tire.fitment.create(createCombinedFilters(rows, true));
                                }
                            },

                            rstools.shared.actions.createArchiveAction("tire.model", {
								defaultFilters: rstools.tire.utils.loosenFilters('models')
							}),
                            rstools.shared.actions.createUnarchiveAction("tire.model", {
								defaultFilters: rstools.tire.utils.loosenFilters('models')
							}),

                            rstools.datastatus.actions.markValid('tire.model'),
                            rstools.datastatus.actions.markInvalid('tire.model'),
                            rstools.datastatus.actions.markNeedsReviewed('tire.model'),
                            rstools.datastatus.actions.markReviewed('tire.model')
                        ],

                        getAction: 'Tire/GetModelDescriptions',
                        countAction: 'Tire/CountModelDescriptions',
                        resultListField: 'Models',
                        baseQueryData: {
                            SelectBrandsOnly: false,
                            UsePromotionOrder: false,
                            UseBrandPriorities: false,
                            HasFitments: false,
                            IncludeResources: true
                        },
                        
                        rowCallback: function ($tr, /** @type {TireModelDescriptionModel} */ modelDescription) {
                            if (modelDescription.TireModelArchived) $tr.addClass('archived-data-object');
                        },

                        search: rstools.tire.utils.filterFormDataToTireFilters(filters),
                        namespace: 'tire-model',
                        rowSelect: function (model) {
                            grid.action.getByID('list').callback([model]);
                        }
                    });
                
                grid.page.on('pageLoaded', function () {
                    // Trigger a window scroll event to update lazyload images
                    $(window).trigger('scroll');
                });

                $filterForm = rstools.tire.utils.createTireFilterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });

                var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

                $backToBrandsButton = $('<a/>', {
                    'class': 'btn btn-info',
                    'append': [
                        uifactory.create.icon('caret-left'),
                        ' Brands'
                    ],
                    'href': '#!/app/tire/brand'
                }).appendTo($leftButtons);

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .appendTo($leftButtons)
                    .trigger('filters-updated', [Object.keys(filters).length]);

                var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

                rstools.user.isSystemAdmin() && $('<button/>', {
                    'class': 'btn btn-primary pull-right',
                    'append': [
                        'Create Model &nbsp;',
                        uifactory.create.icon('plus')
                    ],
                    'appendTo': $rightButtons,
                    'click': function () {
                        var data = {};

                        if ('TireBrands' in filters && filters.TireBrands.length)
                            data.TireBrandID = filters.TireBrands[0];

                        rstools.tire.model.create(data);
                    }
                });
            },
            viewLoaded: function (ctx) {
                $filterForm.syncObjectToForm(filters);

                if (ctx.previousContext && ctx.previousContext.path === '/app/tire/brand') {
                    var previousState = rter.utils.toParamString(ctx.previousState);

                    $backToBrandsButton.attr('href', '#!/app/tire/brand' + (previousState ? "?" + previousState : ""));
                } else {
                    $backToBrandsButton.attr('href', '#!/app/tire/brand');
                }
            }
        };

        function filtersUpdated() {
            $filterForm.syncFormToObject(filters);
            $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(rstools.tire.utils.filterFormDataToTireFilters(filters));
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);