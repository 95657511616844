rter.mapController('app.organization.import', function (context) {
    var parameterFactory = (function () {
        function createParameter(data, settings) {
            if (typeof settings === 'string') settings = {
                helpText: settings
            };

            return $.extend({
                group: 'Parameters'
            }, data, settings);
        }

        var activeOrganizationID = rstools.auth.user.getActiveOrganizationID();

        return {
            allowSharedBrands: function (settings) {
                return createParameter({
                    label: 'Allow Shared Brands',
                    name: 'Parameters.AllowSharedBrands',
                    type: 'checkbox',
                    helpText: 'If true, also find part numbers from shared brands'
                }, settings);
            },
            disableSnapshot: function (settings) {
                return createParameter({
                    label: 'Disable Row Snapshot',
                    name: 'Parameters.DisableSnapshot',
                    type: 'checkbox',
                    checked: true,
                    helpText: 'If true, disable the row snapshot logic forcing any changed data to be updated from the sheet'
                }, settings);
            },
            dataSource: function (settings) {
                return createParameter({
                    label: 'Data Source',
                    name: 'Parameters.DataSource',
                    type: 'dropdown',
                    required: true,
                    group: 'Parameters',

                    url: 'DataSource/GetDataSources',
                    responseKey: 'DataSources',
                    resultMap: function(dataSource) {
                        return {
                            value: dataSource.DataSourceID,
                            label: dataSource.DataSourceDescription
                        };
                    },
                    initialOptions: [{ label: 'Choose a data source', value: false }, '-'],
                    helpText: 'Any new brands will be created with this data source'
                }, settings);
            },
            mode: function (settings) {
                return createParameter({
                    label: 'Mode',
                    name: 'Parameters.Mode',
                    type: 'dropdown',
                    required: 'true',
                    group: 'Parameters',

                    multiple: 'true',
                    value: [
                        'Insert', 'Update', 'Delete'
                    ],
                    options: rstools.utils.dropdownOptionsFromArray([
                        'Insert',
                        'Update',
                        'Delete'
                    ]),
                    helpText: 'Whether or not data should data be inserted, deleted and/or updated'
                }, settings);
            },
            organizationPermission: function (settings) {
                return createParameter({
                    label: 'Organization Permission',
                    name: 'Parameters.OrganizationPermission',
                    type: 'dropdown',
                    required: true,
                    group: 'Parameters',
                    
                    url: 'Organization/GetOrganizationPermissions',
                    responseKey: 'Results',
                    data: {
                        Organization: activeOrganizationID
                    },
                    resultMap: function(organizationPermission) {
                        return {
                            value: organizationPermission.OrganizationPermissionID,
                            label: organizationPermission.OrganizationPermissionName
                        };
                    },
                    initialOptions: [{ label: 'Choose a organization permission', value: false }, '-'],
                    helpText: 'Only import to brands with this organization permission, new brands will be created with this permission'
                }, settings);
            },
            pricingGroup: function (settings) {
                return createParameter({
                    label: 'Default Pricing Group',
                    name: 'Parameters.PricingGroup',
                    type: 'search',
                    required: false,

                    url: 'Organization/GetPricingGroups',
                    responseKey: 'Results',
                    resultMap: function(pricingGroup) {
                        return {
                            id: pricingGroup.PricingGroupID,
                            name: pricingGroup.PricingGroupName
                        };
                    },
                    requestData: {
                        Organization: rstools.auth.user.getActiveOrganizationID() 
                    },
                    idColumnName: 'PricingGroupID'
                }, settings);
            },
            replaceBrands: function (settings) {
                return createParameter({
                    label: 'Replace Brands',
                    name: 'Parameters.ReplaceBrands',
                    type: 'checkbox',
                    helpText: 'If true, all existing fitments in the encountered models will be archived. This also disables the requirement for a fitment to have a part number.'
                }, settings);
            },
            modelMeta: function (settings) {
                return createParameter({
                    label: 'Model Meta Columns',
                    name: 'Parameters.ModelMetaColumns',
                    group: 'Meta Settings',
                    type: 'text',
                    dataType: 'string-list-to-json',
                    helpText: 'Specify a comma-separated list of model meta column names.',
                    required: false
                }, settings);
            },
            fitmentMeta: function (settings) {
                return createParameter({
                    label: 'Fitment Meta Columns',
                    name: 'Parameters.FitmentMetaColumns',
                    group: 'Meta Settings',
                    type: 'text',
                    dataType: 'string-list-to-json',
                    helpText: 'Specify a comma-seperated list of fitment meta column names.',
                    required: false
                }, settings);
            }
        };
    })();

    var ruleOptions = [
        {
            name: 'manual-tire-fitment-import',
            label: 'Generic Tire Fitment Import',
            filename: 'tire-fitment.csv',
            accept: '.csv',
            fields: [
                parameterFactory.organizationPermission(),
                parameterFactory.dataSource(),
                parameterFactory.mode(),
                parameterFactory.modelMeta(),
                parameterFactory.fitmentMeta(),
                parameterFactory.replaceBrands(),
                parameterFactory.disableSnapshot()
            ]
        },
        {
            name: 'manual-tire-pricing-import',
            label: 'Generic Tire Pricing Import',
            filename: 'tire-fitment-pricing.csv',
            accept: '.csv',
            fields: [
                parameterFactory.organizationPermission('Match up part numbers to fitments in brands with this organization permission'),
                parameterFactory.pricingGroup('If not specified in the sheet, use this pricing group when creating or updating pricing'),
                parameterFactory.allowSharedBrands(),
                parameterFactory.mode()
            ],
            baseData: {
                "Parameters.AllowSharedBrands": false
            }
        },
        {
            name: 'manual-wheel-fitment-import',
            label: 'Generic Wheel Fitment Import',
            filename: 'wheel-fitment.csv',
            accept: '.csv',
            fields: [
                parameterFactory.organizationPermission(),
                parameterFactory.dataSource(),
                parameterFactory.mode(),
                parameterFactory.modelMeta(),
                parameterFactory.fitmentMeta(),
                parameterFactory.replaceBrands(),
                parameterFactory.disableSnapshot()
            ]
        },
        {
            name: 'manual-wheel-pricing-import',
            label: 'Generic Wheel Pricing Import',
            filename: 'wheel-fitment-pricing.csv',
            accept: '.csv',
            fields: [
                parameterFactory.organizationPermission('Match up part numbers to fitments in brands with this organization permission'),
                parameterFactory.pricingGroup('If not specified in the sheet, use this pricing group when creating or updating pricing'),
                parameterFactory.allowSharedBrands(),
                parameterFactory.mode()
            ],
            baseData: {
                "Parameters.AllowSharedBrands": false
            }
        },
        '-',
        {
            name: 'atd-wheel-fitment-import',
            label: 'ATD (Wheels)',
            filename: 'wheel-fitment.csv',
            accept: '.csv'
        },
        {
            name: 'go-tire-wheel-fitment-import',
            label: 'GoTire (Wheels)',
            filename: 'wheel-fitment.csv',
            accept: '.csv'
        },
        {
            name: 'kal-tire-wheel-fitment-import',
            label: 'KalTire (Wheels)',
            filename: 'kal-tire.csv',
            accept: '.csv'
        },
        '-',
        {
            name: 'wheel-pros-import',
            label: 'Wheel Pros (Wheels & Pricing)',
            filename: 'wheel-pros-fitments-in',
            accept: 'text/*',
            fields: [
                parameterFactory.disableSnapshot()
            ]
        },
        {
            name: 'wheel-pros-fitment-import',
            label: 'Wheel Pros (Wheels Only)',
            filename: 'wheel-pros-fitments-in',
            accept: 'text/*',
            fields: [
                parameterFactory.disableSnapshot()
            ]
        },
        {
            name: 'wheel-pros-pricing-import',
            label: 'Wheel Pros (Pricing Only)',
            filename: 'wheel-pros-fitments-in',
            accept: 'text/*'
        }
    ];

    var rules = (function () {
        var rules = {};
        var option;

        for (var i = 0; i < ruleOptions.length; i++) {
            option = ruleOptions[i];

            if (typeof option !== 'object' || 'name' in option === false) continue;

            rules[option.name] = option;
        }

        return rules;
    })();

    function generateImportDropdownOptions() {
        var options = [
            {
                label: 'Select an import',
                value: false
            },
            '-'
        ];

        for (var i = 0; i < ruleOptions.length; i++) {
            var option = ruleOptions[i];

            if (typeof option === 'object') options.push({
                label: option.label,
                value: option.name
            });
            else
                options.push(option);
        }
        
        return options;
    }


    return {
        viewWillCreate: function ($view) {
            var createImportForm = function (rule) {
                var fields = [
                    {
                        name: 'Rule',
                        type: 'dropdown',
                        label: 'Import',
                        options: generateImportDropdownOptions(),
                        value: rule && rule.name,
                        onChange: function () {
                            var selectedOption = $(this).data('rsDropdown').selectedOption;
                            var value = selectedOption && selectedOption.value;
                            var rule = value in rules && rules[value];

                            $view.find('#import-form')
                                .empty()
                                .append(createImportForm(rule));
                        },
                        required: true,
                        helpText: 'Select an import rule to start'
                    }
                ];

                if (rule) {
                    if (rule.fields) fields = fields.concat(rule.fields);

                    fields.push({
                        name: rule.filename,
                        accept: rule.accept,
                        type: 'file',
                        label: 'File',
                        required: true,
                        helpText: 'Choose a file to import'
                    });
                }

                return uifactory.create.form({
                    formClass: 'form-horizontal',

                    fields: fields,

                    labelColumns: 3,
                    fieldColumns: 9,

                    fieldGroupsCollapsable: false,

                    baseData: rule.baseData,

                    actions: [
                        {
                            id: 'start-import-button',
                            label: 'Start Import',
                            buttonClass: 'btn btn-primary',
                            action: 'submit',
                            disabled: true
                        }
                    ],

                    urlAction: 'upload',
                    url: 'Import/Start',

                    submitErrorMessage: 'There was a problem starting the import: {Message}',
                    submitSuccessCallback: function () {
                        uifactory.alert.show({text: 'The import has been queued to start.', type: 'success'});
                        
                        $view.find('#import-form')
                            .children()
                            .animate({opacity: 0}, function () {
                                var $newImportForm = createImportForm(false).css('opacity', 0);
                                
                                $(this).replaceWith($newImportForm);
                                
                                $newImportForm.animate({opacity: 1});
                            });
                    }
                });
            };

            $view.find('#import-form')
                .append(createImportForm(false))
                .on('change', function () {
                    var $form = $(this).children('.rs-form');
                    var form = $form.data('form');

                    if (form) {
                        var $startButton = $form.find('#start-import-button');

                        form.validate()
                            .done(function () {
                                // The form is valid, enable the submit button
                                $startButton.prop('disabled', false);
                            })
                            .fail(function () {
                                // The form is invalid, disable the submit button
                                $startButton.prop('disabled', true);
                            });
                    }
                });
        }
    };
});