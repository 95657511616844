(function ($) {
    var InclusionState = rstools.constants.InclusionState;

    uifactory.create.brandInclusionButtons = function (settings) {
        settings = settings || {
            brand: undefined,
            objectType: undefined,
            action: undefined
        };

        var brand = settings.brand;

        var statusButton = document.createElement('button');
        var lockButton = document.createElement('button');

        function changeStatus(status) {
            var hasFlag = function (flag) { return (status & flag) === flag; };

            var enabled = hasFlag(InclusionState.Optional) || hasFlag(InclusionState.Required);
            var locked = hasFlag(InclusionState.Required) || hasFlag(InclusionState.Excluded);

            statusButton.className = 'circle-icon-button circle-icon-button-' + (enabled ? 'enabled' : 'disabled');
            statusButton.title = enabled ? 'Enabled - Click to Disable' : 'Disabled - Click to Enable';
            lockButton.className = 'circle-icon-button circle-icon-button-' + (locked ? 'locked' : 'unlocked');
            lockButton.title = locked ? 'Locked - Click to Unlock' : 'Unlocked - Click to Lock';

            statusButton.setAttribute('data-original-title', statusButton.title);
            lockButton.setAttribute('data-original-title', lockButton.title);
            
            statusButton.disabled = lockButton.disabled = false;

            if (locked && hasFlag(InclusionState.ByParent)) {
                statusButton.className += ' disabled';
                lockButton.className += ' disabled';
                statusButton.title = enabled ? 'Enabled' : 'Disabled';
                lockButton.title = 'Locked by Parent Organization';
            }
        }

        changeStatus(brand.InclusionStatus);

        $(statusButton).add(lockButton)
            .tooltip()
            .on('click', function (e) {
                e.stopPropagation();

                if (this.disabled || $.fn.hasClass.call([this], 'disabled')) return;

                var disabled = statusButton.className.indexOf('disabled') >= 0;
                var unlocked = lockButton.className.indexOf('unlocked') >= 0;

                if (this === statusButton) disabled = !disabled;
                else if (this === lockButton) unlocked = !unlocked;

                var status;

                if (disabled) status = unlocked ? InclusionState.None : InclusionState.Excluded;
                else status = unlocked ? InclusionState.Optional : InclusionState.Required;

                statusButton.disabled = lockButton.disabled = true;
                statusButton.className += ' waiting';
                lockButton.className += ' waiting';

                ridestyler.ajax.send({
                    action: settings.action,
                    data: {
                        Objects: [brand],
                        Status: status
                    },
                    callback: function (response) {
                        if (response.Success) {
                            changeStatus(brand.InclusionStatus = status);
                            rstools.events.dataChanged(settings.objectType, 'inclusion-changed');
                        } else {
                            changeStatus(brand.InclusionStatus);

                            uifactory.alert.show({
                                type: 'error',
                                text: response.Message || "There was a problem changing the inclusion status."
                            });
                        }
                    }
                });
            });

        return [statusButton, lockButton];
    };
})(jQuery);