(function ($) {
    rter.mapController('app.organization.key.create', function (context) {
        var $submitButton, $originURL;

        return {
            actions: {
                create: function (e) {
                    var isLoading = $submitButton.is(':disabled'),
                        $form = $(this),
                        dataShard = rstools.auth.user.get('DataShard');

                    e.preventDefault();

                    if (isLoading) return false;

                    $submitButton.prop('disabled', true);

                    var createKeyData = {
                        Origin: $originURL.val(),
                        OrganizationID: dataShard.DataShard_OrganizationID
                    };

                    ridestyler.ajax.send({
                        action: 'ApiAccessKey/CreateKey',
                        data: createKeyData,
                        callback: function (response) {
                            if (response.Success) {
                                uifactory.alert.show({
                                    text: "Key created."
                                });

                                rter.routes.go("/app/organization/key");
                            } else {
                                uifactory.alert.show({
                                    text: "<strong>There was a problem creating the key:</strong> " + response.Message,
                                    type: 'error'
                                });
                            }

                            $submitButton.prop('disabled', false);
                        }
                    });
                }
            },

            viewCreated: function ($view) {
                $submitButton = $view.find('button[type=submit]');

                $originURL = $('#origin-url');

                var $referrerDefaultRadio = $('#referrer-default').on('ifChecked', function () {
                    $originURL.val('*');
                    $referrerCustomCollapse.removeClass('in');
                });

                var $referrerCustomRadio = $('#referrer-custom').on('ifChecked', function () {
                    $referrerCustomCollapse.addClass('in');
                    $originURL.focus();
                });

                var $referrerCustomCollapse = $('#referrer-custom-collapse');

            }
        };
    });
})(jQuery);