(function ($) {
    rstools.events.on('userchanged', function() {
        var user = rstools.auth.user.get();

        rstools.user.subscription = user.HasSubscription ? user.Subscription : undefined;

        if (user.User) {
            rstools.user.email = user.User.UserEmail;
            rstools.user.id = user.User.UserID;
            rstools.user.name = user.User.UserName;
        }
    });

    function createUserForm(type, settings) {
        var url;

        settings = $.extend({
            data: {},
            filters: {},
            submitSuccessCallback: undefined,
            onCancel: undefined
        }, settings);

        var data = settings.data;

        switch (type) {
            case "edit":
                url = 'User/UpdateUser';
                break;
            case "create":
                url = 'User/CreateUser';
                break;
        }

        if (typeof url !== 'string') return undefined;

        var fields = type === 'edit' ? [
            {
                label: 'Email Address',
                type: 'static',
                text: data.UserEmail
            },
            {
                label: 'Organization',
                type: 'static',
                ridestyler: {
                    action: 'Organization/GetOrganizations',
                    data: {
                        OrganizationID: data.User_OrganizationID
                    },
                    responseKey: 'Organizations',
                    textKey: 'OrganizationName'
                }
            },
            {
                type: 'suggest',
                label: 'Roles',
                name: 'Roles',
                valueType: 'array',
                multiple: true,
                magicSuggest: {
                        data: [
                        rstools.roles.ORGANIZATION_ADMIN,
                        rstools.roles.WHEELS,
                        rstools.roles.TIRES,
                        rstools.roles.VEHICLES
                    ],
                    maxSelection: null,
                    value: data.Roles
                }
            }
        ] : [
            {
                label: 'Email Address',
                type: 'email',
                name: 'UserEmail',
                value: data.UserEmail,
                required: true
            },
            {
                label: 'Organization',
                type: 'static',
                ridestyler: {
                    action: 'Organization/GetOrganizations',
                    data: {
                        OrganizationID: settings.filters.UserOrganization
                    },
                    responseKey: 'Organizations',
                    textKey: 'OrganizationName'
                }
            },
            {
                label: 'Password',
                name: 'UserPassword',
                type: 'password',
                text: data.UserPassword,
                maxLength: 32,
                validate: function (formData) {
                    var password = formData['UserPassword'];

                    if (!password) return "This field is required.";
                    if (password.length < 6) return "Your password must be at least 6 characters.";
                    if (!rstools.utils.passwordIsValid(password)) return "Your password is invalid. It must be 6-32 characters and only include letters, numbers or one of the following symbols: .,-!$&?"
                }
            },
            {
                label: 'Confirm Password',
                name: 'UserPasswordConfirm',
                type: 'password',
                text: data.UserPassword,
                required: true,
                validate: function (formData) {
                    if (formData['UserPasswordConfirm'] !== formData['UserPassword'])
                        return "The passwords do not match.";
                }
            }
        ];

        var $form = uifactory.create.form({
            url: url,
            submitErrorMessage: 'There was a problem ' + (type === 'create' ? 'creat' : 'edit') + 'ing the user: {Message}.',

            submitSuccessCallback: function () {
                var verbPast = (type === 'create' ? 'created.' : 'edited.');

                uifactory.alert.show({
                    text: "The user has been " + verbPast
                });

                if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback();
            },

            fields: fields,
            baseData: settings.filters,

            actions: [
                {
                    label: type === 'create' ? 'Create' : 'Save',
                    icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
                    buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
                    action: 'submit'
                },

                {
                    label: 'Cancel',
                    action: settings.onCancel
                }
            ]
        });

        return $form;
    }

    rstools.user = {
        edit: function (userID) {
            if (!rstools.user.verifyRole(rstools.roles.ORGANIZATION_ADMIN) && userID != rstools.auth.user.get('User').UserID) return;

            uifactory.modal.create({
                cancelable: true, footer: false,
                loadData: {
                    user: {
                        action: 'User/Get',
                        data: { UserID: userID },
                        responseKey: 'Users',
                        first: true,
                        required: true
                    }
                },
                headerFormat: function (data) {
                    return "<strong>Edit User:</strong> " + data.user.UserEmail;
                },
                bodyFormat: function (data) {
                    var modal = this;

                    return createUserForm('edit', {
                        data: data.user,
                        filters: {
                            UserID: userID
                        },
                        submitSuccessCallback: function () {
                            rstools.events.dataChanged('user', 'edit');
                            modal.hide();
                        },
                        onCancel: function () {
                            modal.hide();
                        }
                    });
                },
                onFail: function (message) {
                    if (!message) {
                        if (!this.data.user) message = 'The user could not be found.';
                    }

                    var baseMessage = 'There was a problem retrieving the user';

                    uifactory.alert.show({
                        type: 'error',
                        text: message ? baseMessage + ': ' + message : baseMessage
                    });
                }
            }).show();
        },
        create: function (user) {
            if (!rstools.user.verifyRole(rstools.roles.ORGANIZATION_ADMIN)) return;

            user = user || {};

            var orgID = user.User_OrganizationID || rstools.auth.user.getActiveOrganizationID();

            var modal = uifactory.modal.create({
                cancelable: true, footer: false,
                header: 'Create a User',
                body: createUserForm('create', {
                    filters: {
                        UserOrganization: orgID
                    },
                    data: user,
                    submitSuccessCallback: function () {
                        rstools.events.dataChanged('user', 'create');
                        modal.hide();
                    },
                    onCancel: function () {
                        modal.hide();
                    }
                })
            });

            modal.show();
        },
        resetPassword: function (options) {
            if (typeof options === 'string') options = {email: options};

            options = $.extend({
                email: undefined,
                showFullLoader: true,
                showStatusAlerts: true,
                onSuccess: undefined,
                onError: undefined
            }, options);

            var d = $.Deferred();

            if ('email' in options === false) return d.reject();

            if (options.showFullLoader) uifactory.loading.show("Resetting Password");

            ridestyler.ajax.send({
                action: 'User/ResetPassword',
                data: {
                    Email: options.email
                },
                callback: function (response) {
                    if (response.Success) {
                        if (options.showStatusAlerts)
                            uifactory.alert.show({text: 'Password reset requested.', type: 'success'});

                        d.resolve(response);
                    } else {
                        if (options.showStatusAlerts)
                            rstools.api.utils.showResponseError("There was a problem requesting the password reset", response);

                        d.reject(response);
                    }

                    if (options.showFullLoader) uifactory.loading.hide();
                }
            });

            return d;
        },
        settings: (function () {
            return {
                get: function (k) {
                    return new rstools.data.provider.RstoolsUserSingleSettingDataProvider(k).getAsync();
                },
                set: function(k, v) {
                    return new rstools.data.provider.RstoolsUserSingleSettingDataProvider(k).setAsync(v);
                }
            };
        })(),
        subscription: undefined,
        hasRole: rstools.auth.user.hasRole,
        verifyRole: rstools.auth.user.verifyRole,
        isSystemAdmin: function() { return rstools.user.hasRole(rstools.roles.SYSTEM_ADMIN); }
    };
})(jQuery);