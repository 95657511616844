(function ($) {
    var paneID = 0;

    function RsTabPanel(settings) {
        this.settings = $.extend({
            tabs: 0,
            firstTab: 0,
            fade: true
        }, settings);

        this.$panel = $('<div/>', { 'role': 'tabpanel' });

        this.tabs = [];

        this.createTabList();
        this.createTabContainer();

        this.updateTabs();
        this.setActiveTab(this.settings.firstTab);
    }
    
    RsTabPanel.prototype = {
        createTabList: function () {
            var thisPane = this;

            this.$tabList = $('<ul/>', {
                'class': 'nav nav-tabs',
                'role': 'tablist'
            }).appendTo(this.$panel);

            this.$tabList.on('show.bs.tab', function (e) {
                thisPane.eachTab(function (i, t) {
                    if (t.$tabLink.is(e.target)) t.isActive = true;
                    else t.isActive = true;
                });
            });
        },
        createTabContainer: function () {
            this.$container = $('<div/>', { 'class': 'tab-content' }).appendTo(this.$panel);
        },
        updateTabs: function (tabs) {
            var tabs = tabs || this.settings.tabs,
                isNumeric = !isNaN(tabs),
                thisPanel = this;

            this.clearTabs();

            if (isNumeric) {
                for (var i = 0; i < tabs; i++)
                    tabs.push(new RsTabPanelTab(undefined, thisPanel));
            } else {
                this.tabs = $.map(tabs, function (tabSettings) {
                    return new RsTabPanelTab(tabSettings, thisPanel);
                });
            }
        },

        eachTab: function (f) {
            $.each(this.tabs, f);
        },

        setActiveTab: function (i) {
            if (i < 0 || i >= this.tabs.length) return;

            this.eachTab(function (ti, tab) {
                tab.setIsActive(ti == i);
            });
        },

        clearTabs: function () {
            this.eachTab(function (i, tab) {
                tab.remove();
            });

            this.tabs = [];
        }
    };

    function RsTabPanelTab(settings, panel) {
        this.settings = $.extend({
            'id': 'rs-tab-panel-pane-' + paneID++,
            'fade': panel.settings.fade,
            'label': ''
        }, settings);

        this.isActive = false;
        this.panel = panel;

        this.createTab();
        this.createPane();
    }

    RsTabPanelTab.prototype = {
        createTab: function () {
            var id = this.settings.id;

            this.$tab = $('<li/>', {
                'role': 'presentation'
            }).appendTo(this.panel.$tabList);

            this.$tabLink = $('<a/>', {
                'href': '#' + id,
                'aria-controls': id,
                'role': 'tab',
                'data-toggle': 'tab',
                'html': this.settings.label
            }).appendTo(this.$tab);
        },

        createPane: function () {
            this.$pane = $('<div/>', {
                'role': 'tabpanel',
                'class': 'tab-pane',
                'id': this.settings.id
            }).appendTo(this.panel.$container);

            if (this.settings.fade)
                this.$pane.addClass('fade');
        },

        setIsActive: function (isActive) {
            isActive = !!isActive;

            if (this.isActive == isActive) return;

            this.$pane.add(this.$tab).toggleClass('active', isActive);
            
            if (this.settings.fade)
                this.$pane.toggleClass('in', isActive);

            this.isActive = isActive;
        },

        remove: function () {
            this.$tab.remove();
            this.$pane.remove();
        }
    };


    uifactory.create.tabPanel = function (settings) {
        return new RsTabPanel(settings);
    };
})(jQuery);