(function () {
	rstools.vehicle.fitment = {
		utils: {
			getIDs: function (fitments) {
				return $.map(fitments, function (fitment) {
					return fitment.VehicleFitmentID;
				});
			},
			getTitles: function (fitments) {
				return $.map(fitments, function (fitment) {
					return fitment.VehicleFitmentID + ' (' + rstools.constants.VehicleFitmentPositionType[fitment.VehicleFitmentPosition] + ')';
				});
			}
		}
	};
})();