uifactory.create.chart = function(settings, data, options) {
    settings = $.extend({
        width: 200,
        height: 200,
        type: 'Doughnut',
        container: undefined,
        legend: true
    }, settings);

    if (typeof Chart === 'undefined') throw "Could not find Chart.js";
    if (settings.type in Chart.types === false) throw "Invalid chart type. See http://www.chartjs.org/docs";
    if (!settings.container) throw "You must specify a container to put chart in";

    var $chart = $('<canvas/>', {
        attr: {
            width: settings.width,
            height: settings.height
        },
        appendTo: settings.container
    });
    var renderingContext = $chart.get(0).getContext('2d');
    var chart = new Chart(renderingContext)[settings.type](data);

    if (settings.legend) settings.container.append(chart.generateLegend());

    return $chart.data('chart', chart);
};