uifactory.create.splitButtonDropdown = function (options) {
    options = $.extend({
        action: undefined,
        otherActions: [],
        right: false,
        className: 'btn-primary'
    }, options);

    var buttonGroup = document.createElement('div');
        buttonGroup.className = 'btn-group';

    var mainAction = document.createElement('button');
        mainAction.className = 'btn ' + options.className;
        mainAction.type = 'button';

    if (options.action.title instanceof $) options.action.title.appendTo(mainAction);
    else if (typeof options.action.title === "string") mainAction.appendChild(document.createTextNode(options.action.title));
    else $(mainAction).append(options.action.title);

    mainAction.onclick = options.action.callback;
    buttonGroup.appendChild(mainAction);

    if (options.otherActions && options.otherActions.length) {
        var dropdownButton = document.createElement('button');
            dropdownButton.className = 'btn dropdown-toggle ' + options.className;
            dropdownButton.setAttribute('data-toggle', 'dropdown');
            dropdownButton.type = 'button';

        var caret = document.createElement('span');
            caret.className = 'caret';
            dropdownButton.appendChild(caret);

        var dropdownMenu = document.createElement('ul');
            dropdownMenu.className = 'dropdown-menu';

        if (options.right) dropdownMenu.className += ' dropdown-menu-right';

        var action, itemLi, itemButton;

        for (var i = 0; i < options.otherActions.length; i++) {
            action = options.otherActions[i];

            itemLi = document.createElement('li');
            itemButton = document.createElement('a');

            if (action.className) itemButton.className = action.className;

            itemButton.onclick = function (e) {
                if (typeof action.callback === "function") action.callback.call(this, e);
                
                e.preventDefault();
            };

            if (action.title instanceof $) action.title.appendTo(itemButton);
            else if (typeof action.title === "string") itemButton.appendChild(document.createTextNode(action.title));
            else $(itemButton).append(action.title);

            itemLi.appendChild(itemButton);
            dropdownMenu.appendChild(itemLi);
        }

        buttonGroup.appendChild(dropdownButton);
        buttonGroup.appendChild(dropdownMenu);
    }

    return buttonGroup;
};