(function($) {
    window.uifactory.create.resourceThumbnail = function(settings) {
        if (typeof settings !== 'object')
            throw new Error("Invalid settings");

        settings = $.extend({
            resources: [],
            imageAction: 'Resource/Image',
            imageParameters: undefined,
            title: undefined,
            caption: undefined,
            resourceIndex: 0,
            linkAction: undefined,
            linkParameters: undefined,
            showInfoButton: rstools.user.isSystemAdmin()
        }, settings);

        if (typeof settings.imageAction !== "string" || !settings.imageAction.length || !settings.imageParameters)
            throw new Error("You must specify imageAction and imageParameters in settings.");

        var $thumbnail = $('<div/>', {
            'class': 'thumbnail'
        });
        var $noImage = $('<div/>', { 'class': 'no-image', 'text': 'No Image' }).hide();

        var $img = $('<img/>')
            .css('height', 179)
            .on({
                'error': function() {
                    noImage("Error");
                    $img.css('height', '');
                },
                'load': function() {
                    $thumbnail.removeClass('loading');
                    $img.show();
                    $noImage.hide();
                    $img.css('height', '');
                }
            });

        function noImage(text) {
            $thumbnail.removeClass('loading');
            $img.hide();
            $noImage.text(typeof text === 'string' ? text : "No Image").show();
        };

        function updateImage() {
            if (!settings.resources.length) return noImage();

            $thumbnail.addClass('loading');

            $img.prop('src', ridestyler.ajax.url(settings.imageAction, $.extend({
                Resource: settings.resources[settings.resourceIndex]
            }, settings.imageParameters)));
        };

        var $caption;
        if (settings.caption) $caption = settings.caption;
        else {
            $caption = $('<div/>', { 'class': 'caption' });

            if (settings.title) {
                var $title = $('<h5/>', { 'text': settings.title });

                if (settings.resources.length > 1)
                    $title.append(' ', uifactory.create.icon('th').attr('title', 'Multiple Resources Specified').addClass('small text-warning'));

                $caption.append($title);
            }
        }

        var $buttonGroup = $('<div/>', { 'class': 'btn-group pull-right', 'role': 'group' });

        settings.resources.length && settings.showInfoButton && $('<button/>', {
            'class': 'btn btn-sm btn-default',
            'append': uifactory.create.icon('info'),
            'on': {
                'click': function() {
                    rstools.resource.info(settings.resources[settings.resourceIndex]);
                }
            },
            'appendTo': $buttonGroup
        });

        updateImage();

        $thumbnail.append($img, $noImage, $caption);

        if (settings.resources.length > 1)
        (function() {
            var $prev = $('<button/>', { 'append': uifactory.create.icon('prev'), 'class': 'prev' });
            var $next = $('<button/>', { 'append': uifactory.create.icon('next'), 'class': 'next' });

            $prev.add($next).on('click', function() {
                var $this = $(this);

                noImage("");

                if ($this.is($prev)) {
                    if (--settings.resourceIndex < 0)
                        settings.resourceIndex = settings.resources.length - 1;
                } else {
                    if (++settings.resourceIndex >= settings.resources.length)
                        settings.resourceIndex = 0;
                }

                updateImage();
            });

            $thumbnail.prepend($prev, $next);
        })();

        if (rstools.user.isSystemAdmin()) {
            if (typeof settings.onLink === 'function' && !settings.resources.length) {
                $('<button/>', { 'class': 'btn btn-sm btn-success', 'append': uifactory.create.icon('link') })
                    .appendTo($buttonGroup)
                    .on('click', function() {
                        settings.onLink();
                    });
            } else if (typeof settings.onUnlink === 'function') {
                $('<button/>', { 'class': 'btn btn-sm btn-danger', 'append': uifactory.create.icon('unlink') })
                    .appendTo($buttonGroup)
                    .on('click', function() {
                        settings.onUnlink();
                    });
            }
        }

        if ($buttonGroup[0].childNodes.length)
            $buttonGroup.prependTo($caption);

        return $thumbnail;
    };
})(jQuery);