(function () {
    function createTextFunction(type) {
        return function (text, settings) {
            if (typeof text === 'object') {
                settings = text;
            }

            if (typeof text !== 'string' && 'text' in settings)
                text = settings.text;
                
            settings = settings || {};

            var elType = settings.type || 'span';
            var className = type;

            if (settings.className) className += ' ' + settings.className;

            /** @type {HTMLElement} */
            var el = document.createElement(elType);
                el.className = className;
            
            if (typeof text === 'string')
                el.appendChild(document.createTextNode(text));

            return el;
        };
    }

    uifactory.create.text = {
        muted: createTextFunction('text-muted')
    };
})();