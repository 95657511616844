rstools.plugins.register({
    type: 'page',
    url: '/plugins/angled-vehicle-image-fix',
    target: 'blank',
    menuSettings: {
        selector: '#vehicle-menu',
        label: 'Angled Vehicle Image Utility'
    },
    gridSettings: {
        match: {
            namespace: 'vehicle-configuration'
        },
        actions: [
            {
                icon: 'fa fa-wrench',
                title: 'Fix Angled Images',
                appliesTo: function (row) {
                    if ('HasAngledImage' in row === false)
                        return true;
                    return row['HasAngledImage'] === true;
                },
                fields: ['actions'],
                multiple: false,
                forMenu: true,
                callback: function (rows) {
                    var vehicle = rows[0];
                    var vehicleConfigurationID = vehicle['ConfigurationID'] || vehicle['VehicleConfigurationID'];
                    this.activate({
                        VehicleConfiguration: vehicleConfigurationID
                    });
                }
            }
        ]
    }
});
rstools.plugins.register({
    type: 'page',
    url: '/plugins/bulk-resource-uploader',
    target: 'blank',
    menuSettings: {
        selector: '#organization-menu',
        label: 'Bulk Resource Uploader'
    }
});
rstools.plugins.register({
    type: 'page',
    url: '/plugins/bulk-data-audit/dist',
    target: 'blank',
    menuSettings: {
        selector: '#organization-menu',
        label: 'Audit Data'
    }
});
