(function ($) {
    rter.mapController('app.organization.promotion', function (context) {
        var grid = null;
        var filters = {};
        var $filterForm, $filterButton;

        var PromotionType = rstools.constants.PromotionType;


        return {
            viewWillLoad: function (ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($view) {
                grid =
                    datagrid.create({
                        container: $view.find('div.data-wrapper'),
                        namespace: 'promotion',
                        fields: [
                            { name: 'PromotionName', caption: 'Name' },
                            { name: 'PromotionDescription', caption: 'Description' },
                            {
                                name: 'PromotionNotes', caption: 'Notes',
                                format: function (promotion) {
                                if (!promotion.PromotionNotes) return;
                                    return uifactory.create.icon('comment').attr('title', promotion.PromotionNotes);
                                }
                            },
                            {
                                name: 'PromotionPriority', caption: 'Priority'
                            },
                            {
                                name: 'PromotionType',
                                caption: 'Type',
                                format: function(promo) { return PromotionType[promo.PromotionType]; }
                            },
                            //{
                            //    name: 'PromotionStartDate', caption: 'Starts',
                            //    colClass: 'small text-center',
                            //    format: function (promotion) {
                            //        if (!promotion.PromotionStartDate) return undefined;
                            //        return moment(promotion.PromotionStartDate).format("lll");
                            //    }
                            //},
                            //{
                            //    name: 'PromotionEndDate', caption: 'Ends',
                            //    colClass: 'small text-center',
                            //    format: function (promotion) {
                            //        if (!promotion.PromotionEndDate) return undefined;
                            //        return moment(promotion.PromotionEndDate).format("lll");
                            //    }
                            //},
                            {
                                caption: '# of Wheel Fitments',
                                sort: false,
                                name: 'PromotionLinkCounts.WheelFitmentPromotionLink'
                            },
                            { name: 'actions', caption: '', width: 85, sort: false }
                        ],
                        actions: [
                            {
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.promotion.edit({
                                        Promotions: $.map(rows, function (promo) {
                                            return promo.PromotionID;
                                        })
                                    });
                                }
                            },
                            {
                                title: 'Delete',
                                icon: 'fa fa-trash',
                                forMenu: true,
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    var promoIDs = [],
                                        titles = [];

                                    for (var i = 0; i < rows.length; i++) {
                                        promoIDs.push(rows[i].PromotionID);
                                        titles.push(rows[i].PromotionName);
                                    }

                                    var modal = uifactory.create.confirmActionModal({
                                        titles: titles,
                                        verb: 'delete',
                                        noun: 'promotion',
                                        actionRequest: {
                                            action: 'Promotion/DeletePromotions',
                                            data: {
                                                Promotions: promoIDs
                                            }
                                        },
                                        onActionCompleted: function () {
                                            rstools.events.dataChanged('promotion', 'delete');
                                        },
                                        description: 'You cannot undo this action.'
                                    });

                                    modal && modal.show();
                                }
                            }
                        ],
                        getAction: 'Promotion/GetPromotionDescriptions',
                        countAction: 'Promotion/CountPromotionDescriptions',
                        resultListField: 'Promotions',
                        baseQueryData: {
                            DisableRestrictions: true,
                            IncludeLinkCounts: true
                        },
                        search: filters
                    });

                $filterForm = uifactory.create.filterForm({
                    filters: filters,
                    fields: [
                        {
                            name: 'Search',
                            type: 'text-search',
                            label: 'Search',
                            required: false
                        },
                        {
                            name: 'DisableRestrictions',
                            required: false,
                            type: 'checkbox',
                            value: false,
                            label: 'Active Promotions Only',
                            dataType: 'data'
                        }
                    ],
                    onSubmit: filtersUpdated,
                    onReset: filtersUpdated
                });
                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .prependTo(grid.topControls)
                    .trigger('filters-updated', [Object.keys(filters).length]);

                var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

                $('<button/>', {
                    'class': 'btn btn-primary pull-right',
                    'append': [
                        'Create Promotion &nbsp;',
                        uifactory.create.icon('plus')
                    ],
                    'appendTo': $rightButtons,
                    'click': function () {
                        rstools.promotion.create();
                    }
                });
            }
        };

        function filtersUpdated() {
            $filterForm.syncFormToObject(filters);
            $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(filters);
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);