rstools.vehicle = {
	settings: {
		filters: [
			{
				name: 'Search',
				type: 'text-search',
				label: 'Search',
				required: false
			},
			{
				'for': ['model', 'configuration'],
				type: 'dropdown',
				label: 'Require Tire Option',
				group: 'Tire',
				name: 'TireOptionRequirement',
				required: false,
				options: [
					{ 'label': 'No', value: undefined },
					'-',
					{ 'label': 'Yes', value: 'Exists' },
					{ 'label': 'Require Missing', value: 'Missing' }
				]
			},
			{
				label: 'Year',
				required: false,
				group: 'Vehicle',
				name: 'Year',
				parseAsRange: true,
				labelHintSummary: 'Range',
				labelHintText: 'You may enter a range into this filter.'
			},
			{
				label: 'Doors',
				required: false,
				group: 'Vehicle',
				name: 'Doors',
				type: 'dropdown',
				multiple: true,
				clearWhenFalse: true,

				parseAsMultiple: 'Doors',
				initialOptions: [{ label: 'Choose doors', value: false }, '-'],
				options: rstools.utils.dropdownOptionsFromObject(rstools.constants.VehicleDoorType),
				labelHintSummary: 'Multiple',
				labelHintText: 'You may select multiple values for this filter.'
			},
			{
				label: 'Style',
				required: false,
				group: 'Vehicle',
				name: 'Style',
				type: 'dropdown',

				initialOptions: [{ label: 'Choose a style', value: false }, '-'],
				options: rstools.utils.dropdownOptionsFromObject(rstools.constants.VehicleStyleType)
			},
			{
				label: 'Style Description',
				required: false,
				group: 'Vehicle',
				name: 'StyleDescription',
				type: 'dropdown',

				initialOptions: [{ label: 'Choose a style description', value: false }, '-'],
				options: rstools.utils.dropdownOptionsFromObject(rstools.constants.VehicleStyleDescription)
			},
			{
				label: 'Cab',
				required: false,
				name: 'Cabs',
				group: 'Vehicle',
				type: 'dropdown',
				multiple: true,
				clearWhenFalse: true,

				parseAsMultiple: 'Cabs',
				initialOptions: [{ label: 'Choose a cab type', value: false }, '-'],
				options: rstools.utils.dropdownOptionsFromObject(rstools.constants.VehicleCabType),
				labelHintSummary: 'Multiple',
				labelHintText: 'You may select multiple values for this filter.'
			},
			{
				label: 'Bed Length',
				required: false,
				group: 'Vehicle',
				name: 'BedLength',
				type: 'dropdown',

				initialOptions: [{ label: 'Choose a bed length', value: false }, '-'],
				options: rstools.utils.dropdownOptionsFromObject(rstools.constants.VehicleBedLength)
			},
			{
				label: 'Drive',
				required: false,
				group: 'Vehicle',
				name: 'Drive',
				type: 'dropdown',

				initialOptions: [{ label: 'Choose a drive', value: false }, '-'],
				options: rstools.utils.dropdownOptionsFromObject(rstools.constants.VehicleDriveType)
			},
			{
				'for': ['model', 'configuration'],
				type: 'dropdown',
				label: 'Require Fitment',
				group: 'Vehicle',
				name: 'FitmentRequirement',
				required: false,
				options: [
					{ 'label': 'No', value: undefined },
					'-',
					{ 'label': 'Yes', value: 'Exists' },
					{ 'label': 'Require Missing', value: 'Missing' }
				]
			},
			{
				'for': ['model', 'configuration'],
				type: 'dropdown',
				label: 'Dually',
				group: 'Vehicle',
				name: 'Dually',
				required: false,
				options: [
					{ 'label': 'Any', value: undefined },
					'-',
					{ 'label': 'Yes', value: 'true' },
					{ 'label': 'No', value: 'false' }
				]
			},
			{
				'for': ['model', 'configuration'],
				type: 'dropdown',
				label: 'Has Image',
				group: 'Resources',
				name: 'HasImage',
				required: false,
				options: [
					{ 'label': 'Any', value: undefined },
					'-',
					{ 'label': 'Required', value: 'true' },
					{ 'label': 'Missing', value: 'false' }
				]
			},
			{
				'for': ['model', 'configuration'],
				type: 'dropdown',
				label: 'Has Side Image',
				group: 'Resources',
				name: 'HasSideImage',
				required: false,
				options: [
					{ 'label': 'Any', value: undefined },
					'-',
					{ 'label': 'Required', value: 'true' },
					{ 'label': 'Missing', value: 'false' }
				]
			},
			{
				'for': ['model', 'configuration'],
				type: 'dropdown',
				label: 'Has Angled Image',
				group: 'Resources',
				name: 'HasAngledImage',
				required: false,
				options: [
					{ 'label': 'Any', value: undefined },
					'-',
					{ 'label': 'Required', value: 'true' },
					{ 'label': 'Missing', value: 'false' }
				]
			},
			{
				label: 'Bolt Pattern',
				name: 'BoltPattern',
				group: 'Fitment',
				fitmentFilter: true,

				type: 'search',
				required: false,

				url: 'BoltPattern/GetBoltPatterns',
				responseKey: 'BoltPatterns',
				idColumnName: 'BoltPatternID',
				requestData: {},
				resultMap: function(boltPattern) {
					return {
						id: boltPattern.BoltPatternID,
						name: boltPattern.BoltPatternDescription
					};
				}
			},
			{
				label: 'Width',
				required: false,
				name: 'Width',
				group: 'Fitment',
				fitmentFilter: true,
				parseAsRange: true,
				labelHintSummary: 'Range',
				labelHintText: 'You may enter a range into this filter.'
			},
			{
				label: 'Offset',
				required: false,
				group: 'Fitment',
				name: 'Offset',
				fitmentFilter: true,
				parseAsRange: true,
				labelHintSummary: 'Range',
				labelHintText: 'You may enter a range into this filter.'
			},
			{
				label: 'Weight',
				required: false,
				group: 'Fitment',
				name: 'Weight',
				fitmentFilter: true,
				parseAsRange: true,
				labelHintSummary: 'Range',
				labelHintText: 'You may enter a range into this filter.'
			},
			{
				label: 'Hub',
				required: false,
				group: 'Fitment',
				name: 'Hub',
				fitmentFilter: true,
				parseAsRange: true,
				labelHintSummary: 'Range',
				labelHintText: 'You may enter a range into this filter.'
			},
			{
				label: 'Centerbore',
				required: false,
				group: 'Fitment',
				name: 'Hub',
				fitmentFilter: true,
				parseAsRange: true,
				labelHintSummary: 'Range',
				labelHintText: 'You may enter a range into this filter.'
			},
			{
				label: 'Center Cap',
				name: 'CenterCap',
				group: 'Fitment',
				fitmentFilter: true,
				type: 'dropdown',
				required: false,
				initialOptions: [{ label: 'Choose a center cap', value: false }, '-'],
				options: rstools.utils.dropdownOptionsFromObject(rstools.constants.WheelCenterCapType)
			},
			{
				label: 'Wheel Hole Type',
				name: 'WheelHoleType',
				group: 'Fitment',
				fitmentFilter: true,
				type: 'dropdown',
				required: false,
				initialOptions: [{ label: 'Choose a wheel hole type', value: false }, '-'],
				options: rstools.utils.dropdownOptionsFromObject(rstools.constants.WheelHoleType)
			},
			{
				label: 'Include Archived',
				required: false,
				group: 'Other',
				name: 'IncludeArchived',
				type: 'checkbox',
				requiredRoles: [rstools.roles.SYSTEM_ADMIN]
			}
		]
	},

	utils: {
		createVehicleFilterForm: function (ctx, settings) {
			// Grab the page name from the context
			var pageName = ctx.routes[ctx.routes.length - 1].name;

			settings = settings || {
				filters: undefined, // Current filters to init with
				onReset: undefined, // Action to call when the reset button is clicked
				onSubmit: undefined // Action to call when filters are triggered
			};

			// Filters from the current context
			var stateFilters = ctx.state.filters || {};

			// Store the first field with a group of Vehicle so we can decide where to insert dynamic filters later
			var firstVehicleGroupIndex = false;

			// Grab all applicable filters for use as fields in the filter form
			var fields = $.map(rstools.vehicle.settings.filters, function(filter, i) {
				if (filter.for && !~$.inArray(pageName, filter.for)) return undefined;
				if (filter.group === 'Vehicle' && firstVehicleGroupIndex === false) firstVehicleGroupIndex = i;
				return filter;
			});

			// Defines filters to show when filters are passed in to the URL
			// that normally wouldn't show in the filter list
			var filterFieldMap = {
				'VehicleMakes': {
					type: 'static',
					label: 'Make',
					group: 'Vehicle',
					fieldClass: 'static-filter',
					ridestyler: {
						action: 'Vehicle/GetMakes',
						data: {
							VehicleMakes: stateFilters.VehicleMakes,

							TireOptionRequirement: 0,
							FitmentRequirement: 0
						},
						responseKey: 'Makes',
						format: function (r) {
							return r.VehicleMakeName;
						}
					}
				}
			};

			// Move filters from filterFieldMap if they're active
			// to be in the first Wheel group spot
			for (var k in filterFieldMap) {
				if (k in stateFilters) {
					if (firstVehicleGroupIndex !== false) fields.splice(firstVehicleGroupIndex - 1, 0, filterFieldMap[k]);
					else fields.push(filterFieldMap[k]);
				}
			}

			return uifactory.create.filterForm({
				filters: settings.filters,
				fields: fields,
				onSubmit: settings.onSubmit,
				onReset: settings.onReset
			});
		},
		filterFormDataToVehicleFilters: function (formData) {
			return rstools.utils.filterFormDataToFilters(formData, rstools.vehicle.settings.filters);
		},
		loosenFilters: function (type, filters) {
			filters = filters || {};
	
			filters.TireOptionRequirement = 'None';
			filters.FitmentRequirement = 'None';
	
			return filters;
		}
	}
};