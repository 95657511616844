(function ($) {
    rter.mapController('app.wheel.model', function (context) {
        var grid = null;
        var $filterForm, $filterButton;
        var $backToBrandsButton;
        var filters = {};

        var createCombinedFilters = function (rows, forAPI, loosenFilters) {
            return rstools.utils.getFiltersFor('wheel.model', {
                rows: rows,
                forAPI: forAPI,
                loosenFilters: loosenFilters,
                filters: filters
            });
        };

        return {
            viewWillLoad: function (ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($view) {
                $filterForm = rstools.wheel.utils.createWheelFilterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });

                grid =
                    datagrid.create({
                        container: $view.find('div.data-wrapper'),
                        allowShowAll: true,

                        headerActions: [
                            rstools.shared.datagrid.headerActions.manageColumns
                        ],
                        getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,

                        fields: [
                            { name: 'WheelBrandName', caption: 'Brand' },
                            { name: 'DataSourceDescription', caption: 'Data Source', sort: false },
                            { name: 'WheelModelName', caption: 'Model' },
                            {
                                name: 'WheelModelResources',
                                sort: false,
                                caption: 'Images',
                                colClass: 'no-padding',
                                format: function (model) {
                                    var container = $('<div/>', { 'class': 'thumbnail-grid' });

                                    var angled = $('<span/>').text('Angle');
                                    var catalog = $('<span/>').text('Catalog');
                                    var side = $('<span/>').text('Side');

                                    if (rstools.user.isSystemAdmin()) {
                                        if (model.HasAngleImage) {
                                            $('<img/>', {
                                                'src': 'images/gui/transparent.gif',
                                                'data-original': ridestyler.ajax.url('Wheel/Image', {
                                                    WheelModel: model.WheelModelID,
                                                    Width: 50,
                                                    Height: 50,
                                                    PositionX: 1,
                                                    PositionY: 1,
                                                    WheelFitmentResourceType: rstools.constants.WheelFitmentResourceType.Angled
                                                })
                                            }).lazyload().prependTo(angled);

                                            if (model.AngleImageCount > 1)
                                                angled.append([' ', uifactory.create.icon('th').addClass('text-warning')]);
                                        } else angled.addClass('text-muted');

                                        if (model.HasCatalogImage) {
                                            $('<img/>', {
                                                'src': 'images/gui/transparent.gif',
                                                'data-original': ridestyler.ajax.url('Wheel/Image', {
                                                    WheelModel: model.WheelModelID,
                                                    Width: 50,
                                                    Height: 50,
                                                    PositionX: 1,
                                                    PositionY: 1,
                                                    WheelFitmentResourceType: rstools.constants.WheelFitmentResourceType.Catalog
                                                })
                                            }).lazyload().prependTo(catalog);

                                            if (model.CatalogImageCount > 1)
                                                catalog.append([' ', uifactory.create.icon('th').addClass('text-warning')]);
                                        } else catalog.addClass('text-muted');

                                        if (model.HasSideImage) {
                                            $('<img/>', {
                                                'src': 'images/gui/transparent.gif',
                                                'data-original': ridestyler.ajax.url('Wheel/Image', {
                                                    WheelModel: model.WheelModelID,
                                                    Width: 50,
                                                    Height: 50,
                                                    PositionX: 1,
                                                    PositionY: 1,
                                                    WheelFitmentResourceType: rstools.constants.WheelFitmentResourceType.Side
                                                })
                                            }).lazyload().prependTo(side);

                                            if (model.SideImageCount > 1)
                                                side.append([' ', uifactory.create.icon('th').addClass('text-warning')]);
                                        } else side.addClass('text-muted');
                                    } else {
                                        if (!model.HasAngleImage) angled.addClass('text-muted');
                                        if (!model.HasCatalogImage) catalog.addClass('text-muted');
                                        if (!model.HasSideImage) side.addClass('text-muted');

                                        $(angled).add(catalog).add(side).addClass('label label-default');
                                    }

                                    return container.append([side, angled, catalog]);
                                }
                            },
                            { name: 'WheelModelFinishDescription', caption: 'Finish' },
                            { 
                                name: 'WheelModelDataStatus', 
                                caption: 'Status',
                                colClass: 'text-center',
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false,
                                format: rstools.datastatus.createColumn('wheel.model')
                            },
                            rstools.shared.columns.createLastUpdatedColumn('WheelModelUpdated'),
                            {
								name: 'WheelModelNotes',
								caption: 'Notes',
								colClass: 'text-center',
								sort: false,
								columnRenderer: rstools.notes.createColumnRenderer('wheel.model'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
							},
                            { name: 'actions', caption: '', width: 155, sort: false, colClass: 'text-center' }
                        ],
                        actions: [
                            {
                                title: 'Manage Promotions',
                                icon: 'fa fa-tags',
                                multiple: true,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.WHEELS],
                                callback: function (rows) {
                                    rstools.promotion.manage('wheel.model', createCombinedFilters(rows, true));
                                }
                            },
                            {
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: true,
                                forMenu: true,
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.wheel.model.edit(createCombinedFilters(rows, true));
                                }
                            },
                            {
                                title: 'Images',
                                icon: 'fa fa-picture-o',
                                multiple: true,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                callback: function (rows) {
                                    var modalSettings = {};

                                    if (rows.length === 1)
                                        modalSettings.header = rows[0].WheelModelName + " <small>" + rows[0].WheelModelFinishDescription + "</small>";

                                    rstools.wheel.fitment.manageResources(createCombinedFilters(rows, true), modalSettings);
                                }
                            },
                            rstools.shared.actions.createArchiveAction("wheel.model", {
								defaultFilters: rstools.wheel.utils.loosenFilters('models')
							}),
                            rstools.shared.actions.createUnarchiveAction("wheel.model", {
								defaultFilters: rstools.wheel.utils.loosenFilters('models')
							}),
                            {
                                title: 'Duplicate',
                                icon: 'fa fa-clone',
                                multiple: true,
                                fields: ['actions'],
                                forMenu: true,
                                callback: function (rows) {
                                    rstools.wheel.model.duplicate(createCombinedFilters(rows, true, true));
                                }
                            },
                            {
                                title: 'Merge',
                                icon: 'fa fa-code-fork',
                                multiple: true,
                                single: false,
                                fields: ['actions'],
                                forMenu: true,
                                callback: function (rows) {
                                    rstools.wheel.model.merge(createCombinedFilters(rows, true, true));
                                }
                            },
                            rstools.shared.actions.createPasteAction('wheel.fitment', 'wheel.model', {
                                callback: function () {
                                    grid.page.refresh();
                                }
                            }),
                            {
                                title: 'View Fitments',
                                id: 'list',
                                icon: 'fa fa-th-list',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rter.routes.go('/app/wheel/fitment', { 
                                        filters: createCombinedFilters(rows, true)
                                    });
                                }
                            },
                            {
                                title: 'Create Fitment',
                                icon: 'fa fa-plus',
                                forMenu: true,
                                multiple: false,
                                fields: ['actions'],
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                callback: function(rows) {
                                    rstools.wheel.fitment.create(createCombinedFilters(rows, true));
                                }
                            },
							rstools.meta.actions.edit('wheel.model'),
                            rstools.datastatus.actions.markValid('wheel.model'),
                            rstools.datastatus.actions.markInvalid('wheel.model'),
                            rstools.datastatus.actions.markNeedsReviewed('wheel.model'),
                            rstools.datastatus.actions.markReviewed('wheel.model')
                        ],
                        rowCallback: function ($tr, modelDescription) {
                            if (modelDescription.WheelModelArchived) $tr.addClass('archived-data-object');
                        },
                        getAction: 'Wheel/GetModelDescriptions',
                        countAction: 'Wheel/CountModelDescriptions',
                        resultListField: 'Models',
                        namespace: 'wheel-model',
                        baseQueryData: {
                            SelectBrandsOnly: false,
                            UsePromotionOrder: false,
                            UseBrandPriorities: false,
                            HasFitments: false
                        },
                        search: filters,
                        rowSelect: function (model) {
                            grid.action.getByID('list').callback([model]);
                        }
                    });
                
                grid.page.on('pageLoaded', function () {
                    // Trigger a window scroll event to update lazyload images
                    $(window).trigger('scroll');
                });

                var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

                $backToBrandsButton = $('<a/>', {
                    'class': 'btn btn-info',
                    'append': [
                        uifactory.create.icon('caret-left'),
                        ' Brands'
                    ],
                    'href': '#!/app/wheel/brand'
                }).appendTo($leftButtons);

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .appendTo($leftButtons)
                    .trigger('filters-updated', [Object.keys(filters).length]);

                var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

                rstools.user.isSystemAdmin() && $('<button/>', {
                    'class': 'btn btn-primary pull-right',
                    'append': [
                        'Create Model &nbsp;',
                        uifactory.create.icon('plus')
                    ],
                    'appendTo': $rightButtons,
                    'click': function () {
                        var data = {};

                        if ('WheelBrands' in filters && filters.WheelBrands.length)
                            data.WheelBrandID = filters.WheelBrands[0];

                        rstools.wheel.model.create(data);
                    }
                });
            },
            viewLoaded: function (ctx) {
                // Update filters from the current ctx
                // or grab them from the original context
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

                $filterForm.syncObjectToForm(filters);
                rstools.utils.setBackButtonTargetFromContext($backToBrandsButton, ctx, '/app/wheel/brand');
            }
        };

        function filtersUpdated() {
            $filterForm && $filterForm.syncFormToObject(filters);
            $filterButton && $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(rstools.wheel.utils.filterFormDataToWheelFilters(filters));
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);