uifactory.create.objectList = function (settings) {
	var table = document.createElement('table');
		table.className = 'table table-striped';

	settings = $.extend({
		headers: [],
		objects: [],
		columns: [],

		noImageElement: "No Image"
	}, settings);

	var thead, tr, th, td, i, object;

	if (settings.headers && settings.headers.length) {
		thead = document.createElement('thead');
		tr = document.createElement('tr');

		for (i = 0; i < settings.headers.length; i++) {
			th = document.createElement('th');

			th.appendChild(document.createTextNode(settings.headers[i]));
			thead.appendChild(th);
		}

		thead.appendChild(tr);
		table.appendChild(thead);
	}

	var tbody = document.createElement('tbody');

	for (i = 0; i < settings.objects.length; i++) {
		object = settings.objects[i];
		tr = document.createElement('tr');

		if (typeof settings.image === 'function') {
			td = document.createElement('td');

			var src = settings.image(object);

			if (src) {
				var image = document.createElement('img');
					image.src = src;

				td.appendChild(image);
			} else if (settings.noImageElement) {
				$(td).html(settings.noImageElement);
			}
			
			tr.appendChild(td);
		}

		for (var j = 0; j < settings.columns.length; j++) {
			var column = settings.columns[j];

			td = document.createElement('td');

			$(td)
				.append(rstools.utils.getValueFromFormat(column, object))
				.appendTo(tr);
		}

		tbody.appendChild(tr);
	}

	table.appendChild(tbody);

	return $(table);
};