(function ($) {
    rter.mapController('app.setup', function (context) {
        var settings;
        var currentStep;

        function getStepContainer(step, $view) { return $view.find('#' + step.container); }
        function updateProgress($view) {
            var $bar = $view.find('.progress-bar');
            var p = rstools.setup.getProgress(settings),
                progress = p.progress,
                total = p.total;

            if (progress) $bar.width((progress / total * 100) + '%');
            else $bar.width(40);

            $bar.text(progress + '/' + total);
        }
        function showStep(step, $view) {
            if (!step) return;

            var oldStepHidden = $.Deferred();

            if (currentStep) getStepContainer(currentStep, $view).fadeOut(function() { oldStepHidden.resolve() });
            else oldStepHidden.resolve();

            oldStepHidden.done(function () {
                var $newStepContainer = getStepContainer(step, $view);

                currentStep = step;

                if (typeof step.update === 'function') step.update($newStepContainer, settings);

                $newStepContainer.fadeIn(function() {
                    if (typeof step.onShown === 'function') step.onShown($newStepContainer, settings);
                });
            });

            updateProgress($view);
        }
        function showNextStep($view) { showStep(rstools.setup.getNextStep(settings), $view); }

        return {
            viewWillCreate: function ($view) {
                var steps = rstools.setup.steps;

                settings = rstools.data.provider.get({
                    preferredProvider: 'organizationsettings',
                    namespace: 'SetupSettings'
                });

                settings.retrieveCache().done(function () {
                    $view.find('#setup-steps').removeClass('loading');
                    showNextStep($view);

                    for (var i = 0; i < steps.length; i++) {
                        var step = steps[i];

                        if (typeof step.onSettingsLoaded === 'function')
                            step.onSettingsLoaded(getStepContainer(step, $view), settings);
                    }
                });

                for (var i = 0; i < steps.length; i++) {
                    var step = steps[i];

                    if (typeof step.initialize === 'function')
                        step.initialize(getStepContainer(step, $view), settings);
                }

                $view
                    .on('click', '#start-setup-over', function() {
                        settings.clear();
                        showNextStep($view);
                    })
                    .on('click', '[data-type="next-step"]', function() {
                        var currentStepContainer = getStepContainer(currentStep, $view);

                        var completeResult = undefined;
                        if (typeof currentStep.complete === 'function') completeResult = currentStep.complete(settings, currentStepContainer, $(this));

                        if (completeResult && typeof completeResult.done === 'function') {
                            currentStepContainer.fadeOut(function() {
                                 completeResult.always(function() {
                                     showNextStep($view);
                                     currentStepContainer.parent().removeClass('loading');
                                 });
                            }).parent().addClass("loading");
                        } else {
                            showNextStep($view);
                        }
                    })
                    .on('click', '[data-type="prev-step"]', function() {
                        settings.startBatch();

                        if (typeof currentStep.clearComplete === 'function') currentStep.clearComplete(settings, getStepContainer(currentStep, $view));

                        var foundCurrentStep = false;
                        for (var j = steps.length - 1; j >= 0; j--) {
                            var step = steps[j];

                            if (step === currentStep) foundCurrentStep = true;
                            else if (foundCurrentStep) {
                                if (typeof step.willApply === 'function' && !step.willApply(settings)) continue;
                                if (typeof step.applies === 'function' && !step.applies(settings)) continue;

                                if (typeof step.clearComplete === 'function') step.clearComplete(settings, getStepContainer(step, $view));

                                break;
                            }
                        }

                        settings.endBatch();

                        getStepContainer(currentStep, $view).fadeOut(function() { showNextStep($view); });
                    });
            },
            viewRemoved: function ($view) {
                settings = undefined;
            }
        };
    });
})(jQuery);