rstools.boltpattern = {
    settings: {

    },
	columns: {
		description: function ($tds, data, idKey) {
            $tds.addClass('light loading loading-small');

            ridestyler.ajax.send({
                action: 'BoltPattern/GetBoltPatterns',
                data: {
                    BoltPatterns: $.map(data, function (d) {
                        return d[idKey];
                    }).unique()
                },
                callback: function (response) {
                    if (response.Success) {
                        var boltPatternDescriptionMap = rstools.utils.createMap(response.BoltPatterns, 'BoltPatternID', 'BoltPatternDescription');

                        for (i = data.length - 1; i >= 0; i--) {
                            var boltPatternID = data[i][idKey];

                            if (boltPatternID < 0) {
                                $tds.eq(i).addClass('text-muted').text('Not Found');
                                continue;
                            }

                            $tds.eq(i).html(rstools.utils.addAbbreviations(boltPatternDescriptionMap[boltPatternID], [
                                ['"', 'in', 'inches'],
                                ["mm", "millimeters"]
                            ]));
                        }
                    } else {
                        $tds.text('Error');
                        console.error("Error retrieving bolt pattern descriptions: ", response);
                    }

                    $tds.removeClass('loading');
                }
            });
        }
	}
};