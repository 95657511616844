(function ($) {
    rter.mapController('app.data.aces', function (context) {
        //#region Variable Definitions

        //#region jQuery Variables
        var $topContainer;
        var $rsSection, $acesSection;
        var $rsCount, $acesCount;

        // Modals & children
        var $rsFilterModal, $rsFilterModalForm, $acesFilterBtn, $rsFilterBtn;
        var $acesFilterModal, $acesFilterModalForm;
        var $rsYearInput, $rsMakeInput, $rsModelInput, $rsModelFilterGroup;
        var $settingsModal;

        // Tables & children
        var $rsTable, $acesTable, $rsTbody, $acesTbody, $rsTableWrap, $acesTableWrap, $rsPreviewTable, $acesTableLoading, $rsTableLoading;
        var $rsMoreResults, $rsMoreResultsText, $rsMoreResultsButton;
        var $acesCurFilters;

        // Buttons
        var $saveLinkagesButton;
        // #endregion

        var FieldComparisonStatus = rstools.constants.FieldComparisonStatus;

        // Filters
        var rsFilters = {}, defaultRsFilters = {
            HasImage: true,
            UnlinkStatus: false,
            TireOptionRequirement: true,
            FitmentRequirement: true
        }, rsCurModalFilters = {};


        var settings = {}, defaultSettings = {
            AutoAdvance: true
        };

        var skippedVehicles = 0;

        var acesFilters = {};

        // RideStyler Enums
        var VcDbReferenceFields = rstools.constants.VcDbReferenceFields;

        var UpdateVehicleReferenceMode = {
            Combine: 1,
            Replace: 2
        };

        var SortDirection = {
            Asc: 0,
            Desc: 1
        };

        var VehicleReferenceType = rstools.constants.VehicleReferenceType;

        // Regular Expressions
        var yearRangeRegExp = /(?:\s*from\s*)?([\d]{4})\s*(?:-|to)\s*([\d]{4})/i;
        var litersRegExp = /^\d\.\d\w{1,2}?$/;

        // Data Grids
        var rsDatagrid, acesDatagrid;
        //#endregion

        //#region Filter Functions
        function isTrue(b) {
            if (!b) return false;

            if (typeof b === 'string') {
                if (b === 'false') return false;
                return true;
            }

            return true;
        }

        function getRSFilters(forForm) {
            var filters = $.extend({}, defaultRsFilters, rsFilters);

            filters.UnlinkStatus = isTrue(filters.UnlinkStatus);
            filters.FitmentRequirement = isTrue(filters.FitmentRequirement);
            filters.TireOptionRequirement = isTrue(filters.TireOptionRequirement);
            filters.HasImage = isTrue(filters.HasImage);

            if (!forForm) {
                if (filters.UnlinkStatus) {
                    filters.WithoutVehicleReferences = true;
                }

                filters.FitmentRequirement = filters.FitmentRequirement ? 1 : 0;
                filters.TireOptionRequirement = filters.TireOptionRequirement ? 1 : 0;

                if (filters.Year) {
                    var rangeResult = yearRangeRegExp.exec(filters.Year);

                    if (rangeResult) {
                        delete filters.Year;
                        filters.YearMin = rangeResult[1];
                        filters.YearMax = rangeResult[2];
                    }
                }
            }

            if (!filters.HasImage) delete filters.HasImage;

            return filters;
        }

        function getSettings() {
            var s = $.extend({}, defaultSettings, settings);
            return s;
        }

        function removeDefaultProperties(o, defo, strict) {
            for (var k in defo) {
                if (!strict && o[k] == defo[k] || o[k] === defo[k])
                    delete o[k];
            }
        } //#endregion

        //#region Keyboard Helper
        var keyboardCtrlChars = {};
        var KeyboardHelper = {
            ctrlDown: false,

            down: function (e) {
                KeyboardHelper.updateCtrlKeyStatus(e);

                if (e.ctrlKey || e.metaKey) {
                    if (e.which in keyboardCtrlChars) {
                        var target = keyboardCtrlChars[e.which];

                        if (target instanceof $) {
                            if (target.is(':disabled') === false)
                                target.click();

                            e.preventDefault();
                        } else if (typeof target === 'function') target(e);
                    }
                }
            },
            up: function (e) {
                KeyboardHelper.updateCtrlKeyStatus(e);
            },
            updateCtrlKeyStatus: function (e) {
                var ctrlKey = e.ctrlKey || e.metaKey;

                if (this.ctrlDown != ctrlKey) {
                    this.ctrlDown = ctrlKey;

                    $rsSection.add($acesSection).toggleClass('ctrlDown', this.ctrlDown);
                }
            }
        };
        //#endregion

        //#region Layout Functions
        function updateLayout() {
            var containerHeight = $(window).height() - $topContainer.offset().top - 30;

            $topContainer
                .height(containerHeight);

            $rsTableWrap
                .height(containerHeight - $rsTableWrap.position().top - 60 - (rsFilters.UnlinkStatus ? $rsMoreResults.outerHeight() : 0));

            $acesTableWrap
                .height(containerHeight - $acesTableWrap.position().top - 60);
        }
        //#endregion

        //#region Table Actions
        function saveLinkages() {
            var curVehicle = rsDatagrid.page.getSelectedObjects(),
                acesVehicles = acesDatagrid.page.getSelectedObjects(),
                isLinkingVehicle = !!acesVehicles.length,
                $selectedVehicleRow = $rsTbody.find('.selected'),
                $nextVehicleRow = $selectedVehicleRow.next();

            curVehicle = curVehicle.length && curVehicle[0];

            if (isLinkingVehicle)
                rstools.log('Linking %o to %o', acesVehicles, curVehicle);
            else
                rstools.log('Unlinking vehicles from %o', curVehicle);

            $acesTableLoading.show();
            $rsTableLoading.show();

            var linkageReferences = [];
            for (var i = 0; i < acesVehicles.length; i++) {
                var v = acesVehicles[i];

                for (var k in VcDbReferenceFields) {
                    if (k in v) {
                        for (var j = 0; j < linkageReferences.length; j++) {
                            if (linkageReferences[j].Field == VcDbReferenceFields[k] && linkageReferences[j].Value == v[k])
                                break;
                        }

                        if (j == linkageReferences.length) {
                            linkageReferences.push({
                                Field: VcDbReferenceFields[k],
                                Value: v[k]
                            });
                       }
                    }
                }
            }

            ridestyler.ajax.send({
                action: 'Vehicle/UpdateReferences',
                data: {
                    VehicleConfiguration: curVehicle.ConfigurationID,
                    VehicleReferenceType: VehicleReferenceType.ACES,
                    Mode: UpdateVehicleReferenceMode.Replace,
                    References: linkageReferences
                },
                callback: function (response) {

                    $acesTableLoading.hide();
                    $rsTableLoading.hide();

                    if (response.Success) {
                        uifactory.alert.show({
                            text: 'Linkages saved.',
                            duration: 2500
                        });

                        $selectedVehicleRow.toggleClass('linked', isLinkingVehicle);

                        if (getSettings().AutoAdvance)
                            $nextVehicleRow.click();
                        else
                            updateAcesTable();

                    } else {
                        uifactory.alert.show({
                            text: 'There was a problem saving the linkages: ' + response.Message,
                            type: 'error',
                            duration: 2500
                        });
                    }
                }
            });
        }
        function nextResult() {
            var $nextRow = $rsTbody.find('.selected').next();
            if ($nextRow.length) $nextRow.click();
        }
        function prevResult() {
            var $prevRow = $rsTbody.find('.selected').prev();
            if ($prevRow.length) $prevRow.click();
        }
        function jumpToCurrentResult() {
            $rsTableWrap.scrollTop(
                $rsTableWrap.scrollTop() + $rsTbody.find('.selected').position().top - $rsTable.find('.tableFloatingHeader').height() - 118
            );
        }

        function noAcesResults() {
            var curVehicle = rsDatagrid.page.getSelectedObjects();
            curVehicle = curVehicle.length && curVehicle[0];

            if (curVehicle) {
                if ('Search' in acesFilters === false && acesFilters.ModelName == curVehicle.ModelName && acesFilters.SubmodelName == curVehicle.TrimName)  {
                    acesFilters.Search = curVehicle.ModelName + ' ' + curVehicle.TrimName;

                    delete acesFilters.ModelName;
                    delete acesFilters.SubmodelName;

                    uifactory.alert.show({
                        text: 'No ACES Vehicles found. Loosening your filters to find more results.',
                        type: 'info'
                    });

                    acesDatagrid.search(acesFilters);
                    acesFiltersUpdated();
                } else if ('ModelName' in acesFilters || 'Search' in acesFilters) {
                    delete acesFilters.ModelName;
                    delete acesFilters.Search;

                    uifactory.alert.show({
                        text: 'Still no ACES Vehicles found. Loosening your filters even more to find more results.',
                        type: 'info'
                    });

                    acesDatagrid.search(acesFilters);
                    acesFiltersUpdated();
                }
            }
        }

        var previousVehicle = {
            Year: undefined,
            MakeName: undefined,
            ModelName: undefined,
            TrimName: undefined
        };
        function updateAcesFiltersWithVehicle(v) {
            if (previousVehicle.Year != v.Year || previousVehicle.MakeName != v.MakeName || previousVehicle.ModelName != v.ModelName) {
                acesFilters = {
                    Year: v.Year,
                    MakeName: v.MakeName,
                    ModelName: v.ModelName
                };

                acesDatagrid.search(acesFilters);
                acesFiltersUpdated();
            } else {
                if (v.TrimName != previousVehicle.TrimName) {
                    delete acesFilters.SubmodelName;
                    acesFilters.ModelName = v.ModelName;
                }

                acesDatagrid.search(acesFilters);
                acesFiltersUpdated();
            }

            previousVehicle = v;
        }

        function updateAcesTable() {
            var curVehicle = rsDatagrid.page.getSelectedObjects();
            curVehicle = curVehicle.length && curVehicle[0];

            if (!curVehicle) return;

            if (acesDatagrid.page.getTotalCount() == 0)
                return noAcesResults();

            $acesTableLoading.show();

            ridestyler.ajax.send({
                action: 'Vehicle/GetReferences',
                data: {
                    VehicleConfiguration: curVehicle.ConfigurationID,
                    VehicleReferenceType: 1,
                    VehicleReferenceFields: [
                        VcDbReferenceFields.VehicleID,
                        VcDbReferenceFields.EngineConfigID,
                        VcDbReferenceFields.BodyStyleConfigID,
                        VcDbReferenceFields.BedConfigID,
                        VcDbReferenceFields.DriveTypeID
                    ]
                },
                callback: function (response) {
                    $acesTableLoading.hide();

                    if (response.Success) {
                        var links = response.References,
                            selectedRows = [],
                            autoLinkedRows = [];

                        $acesTbody.children().each(function (i) {
                            var $tr = $(this).removeClass('linked');

                            var v = acesDatagrid.page.getObjectAtIndex($tr.data('row'));
                            var fieldStatuses = acesVehicleMatchesStatus(v);

                            if (fieldStatuses) {
                                for (var fieldName in fieldStatuses) {
                                    var fieldIndex = acesDatagrid.field.getFieldIndex(fieldName);

                                    if (fieldIndex > 0) {
                                        var status = fieldStatuses[fieldName] == FieldComparisonStatus.Warning ? 'warning' : 'success';

                                        $tr.children().eq(fieldIndex).addClass('status status-' + status);
                                    }
                                }
                            }

                            var shouldBeSelected = false, isSelected = $tr.hasClass('selected'),
                                requiredFields = ['VehicleID', 'EngineConfigID', 'BodyStyleConfigID', 'BedConfigID', 'DriveTypeID'];

                            $.each(links, function (x, link) {
                                for (var i = 0; i < requiredFields.length; i++) {
                                    var field = requiredFields[i];

                                    if (VcDbReferenceFields[field] == link.VehicleReferenceField && v[field] == link.VehicleReferenceValue)
                                        requiredFields.splice(i, 1);
                                }

                                if (!requiredFields.length) {
                                    shouldBeSelected = true;
                                    $tr.addClass('linked');

                                    return false;
                                }
                            });

                            if (fieldStatuses)
                                autoLinkedRows.push($tr);

                            if (fieldStatuses && !links.length)
                                shouldBeSelected = true;

                            if (!shouldBeSelected && isSelected)
                                $tr.click();

                            if (shouldBeSelected)
                                selectedRows.push($tr);
                        });

                        //#region Bed Length Matching
                        if (curVehicle.BedLength != 'N/A') {
                            var selectedBedLengths = {},
                                matchedBedLengthCount = 0;

                            // Get a unique list of bed lengths from the ACES table and store them in
                            // selectedBedLengths, keeping a count in matchedBedLengthCount
                            for (var i = 0; i < autoLinkedRows.length; i++) {
                                var v = acesDatagrid.page.getObjectAtIndex(autoLinkedRows[i].data('row'));
                                
                                if (isNaN(v.BedLength)) continue;

                                if (typeof selectedBedLengths[v.BedLength] === 'undefined') {
                                    selectedBedLengths[v.BedLength] = true;
                                    matchedBedLengthCount++;
                                }
                            }

                            if (matchedBedLengthCount != 1) {
                                var fieldIndex = acesDatagrid.field.getFieldIndex('BedLength');
                                if (fieldIndex > 0) {
                                    $.each(autoLinkedRows, function (j, $row) {
                                        var v = acesDatagrid.page.getObjectAtIndex($row.data('row')),
                                            bedLength = $.trim(curVehicle.BedLength),
                                            $bedField = $row.children().eq(fieldIndex);

                                        if (bedLength === 'Long Bed' && v.BedLength >= 90 || bedLength === 'Short Bed' && v.BedLength < 90)
                                            $bedField.addClass('status-success').removeClass('status-warning');
                                        else
                                            $bedField.addClass('status-warning').removeClass('status-success');
                                    });
                                }
                            } else {
                                var longBed = -Infinity,
                                    shortBed = Infinity;

                                for (var l in selectedBedLengths) {
                                    longBed = Math.max(longBed, l);
                                    shortBed = Math.min(shortBed, l);
                                }

                                autoLinkedRows = $.map(autoLinkedRows, function ($row) {
                                    var v = acesDatagrid.page.getObjectAtIndex($row.data('row')),
                                        bedLength = $.trim(curVehicle.BedLength),
                                        matched = false;

                                    if (bedLength == 'Long Bed')
                                        matched = v.BedLength == longBed;
                                    else if (bedLength == 'Short Bed')
                                        matched = v.BedLength == shortBed;

                                    if (matched) return $row;

                                    $row.find('.status').removeClass('status status-warning status-success');
                                    return null;
                                });

                                if (!links.length)
                                    selectedRows = autoLinkedRows;
                            }
                        }
                        //#endregion

                        if (!links.length) {
                            var rowOrderMap = {},
                                rowOrderKeys = [];

                            $.each(selectedRows, function (i, $row) {
                                var successes = $row.find('.status-success').length;

                                if (typeof rowOrderMap[successes] === 'undefined')
                                    rowOrderMap[successes] = [];

                                rowOrderMap[successes].push($row);
                            });

                            for (var k in rowOrderMap)
                                rowOrderKeys.push(k);

                            rowOrderKeys.sort(function (a, b) { return b - a; });

                            selectedRows = [];

                            $.each(rowOrderKeys, function (i, k) {
                                selectedRows = selectedRows.concat(rowOrderMap[k]);
                            });
                        }

                        $.each(selectedRows, function (i, $row) {
                            if ($row.hasClass('selected') == false) $row.click();
                        });

                        $acesTbody.prepend(selectedRows).children().each(function (i, row) {
                            var odd = i % 2 === 0;

                            $(row)
                                .toggleClass('odd', odd)
                                .toggleClass('even', !odd);
                        });
                    }
                }
            });
        }
        //#endregion

        // #region Filters Updated Callbacks
        function filtersUpdated() {
            rstools.state.set('filters', rsFilters);
            updateRidestyerDataGridLayout();
            updateLayout();
            skippedVehicles = 0;
        }

        function acesFiltersUpdated() {
            var hasFilters = false;

            $acesCurFilters.find('.label').remove();

            for (var k in acesFilters) {
                var value = acesFilters[k];

                if (k === 'Search') {
                    value = value.split(/[ ,-]/g).join(' OR ');
                }

                $acesCurFilters.append(
                    $('<small/>', {
                        'class': 'label label-default',
                        'text': value,
                        'title': k
                    })
                );

                hasFilters = true;
            }

            if (!hasFilters) {
                $acesCurFilters.append(
                    $('<small/>', { 'class': 'label' }).text('None')
                );
            }
        }

        function settingsUpdated(updateModal) {
            rstools.storage.set('al-settings', settings);

            if (updateModal)
                $settingsModal.syncObjectToForm(getSettings());
        }

        function updateRidestyerDataGridLayout() {
            if (rsFilters.UnlinkStatus) {
                $rsSection.addClass('unlinked-vehicles');
            } else {
                $rsSection.removeClass('unlinked-vehicles');
            }
        }
        // #endregion

        // #region Vehicle Comparison Functions
        function acesVehicleMatchesStatus(v) {
            var selectedObjects = rsDatagrid.page.getSelectedObjects(),
                curVehicle = selectedObjects.length && selectedObjects[0];

            if (curVehicle) {
                if (!v) return false;

                delete v._litersMatched;

                var fieldStatuses = {
                    'ModelName': [
                        compareYears(v.YearID, curVehicle.Year),
                        compareMakes(v.MakeName, curVehicle.MakeName),
                        compareModels(curVehicle.ModelName, v.ModelName)
                    ],
                    'SubmodelName': compareTrims(curVehicle, v),
                    'BodyTypeName': [
                        compareCabTypes(curVehicle.CabType, curVehicle.CabTypeClass, v.BodyTypeName),
                        compareVehicleTypes(curVehicle.StyleType, curVehicle.StyleDescription, v.VehicleTypeName, v.BodyTypeName)
                    ],
                    'BodyNumDoors': compareDoors(curVehicle.DoorType, v.BodyNumDoors),
                    'DriveTypeName': compareDriveTypes(curVehicle.DriveType, v.DriveTypeName),
                    'BedLength': FieldComparisonStatus.Success // This is handled later
                };

                if ('_litersMatched' in v) {
                    fieldStatuses.Engine = v._litersMatched ? FieldComparisonStatus.Success : FieldComparisonStatus.Invalid;
                } else {
                    var trimTokens = TokenScorer.prototype.tokenize(curVehicle.TrimName);
                    var litersFromTrim;

                    $.each(trimTokens, function (i, token) {
                        if (litersRegExp.test(token)) {
                            litersFromTrim = /\d\.\d/.exec(token)[0];
                            return false;
                        }
                    });

                    if (v.Liter == litersFromTrim)
                        fieldStatuses.Engine = FieldComparisonStatus.Success;
                    else
                        fieldStatuses.Engine = FieldComparisonStatus.Warning;
                }

                for (var k in fieldStatuses) {
                    var s = fieldStatuses[k];

                    if ($.isArray(s))
                        s = fieldStatuses[k] = Math.min.apply(Math, s);

                    if (s === FieldComparisonStatus.Invalid) return false;
                }

                return fieldStatuses;
            }

            return false;
        }

        function compareYears(a, b) {
            return strictComparisonStatus(a, b);
        }

        function compareMakes(a, b) {
            return looseComparisonStatus(a, b);
        }

        function compareModels(a, b) {
            if (notEqual(a, b)) {
                if (doesNotContain(a, b) && doesNotContain(b, a)) return FieldComparisonStatus.Invalid;
                return FieldComparisonStatus.Warning;
            }
            return FieldComparisonStatus.Success;
        }

        function compareTrims(rsVehicle, acesVehicle) {
            var modelName = rsVehicle.ModelName,
                trimName = rsVehicle.TrimName,
                acesModelName = acesVehicle.ModelName,
                submodelName = $.trim(acesVehicle.SubmodelName);

            if (trimName.replace(' ', '').toLowerCase() == submodelName.replace(' ', '').toLowerCase()) {
                return FieldComparisonStatus.Success;
            }

            var scorer = new TokenScorer([modelName, trimName], [acesModelName, submodelName]);

            scorer.tokenMatchingRules = [
                { match: /^(?:\/|-)$/, mode: 'ignore' },
                {
                    match: litersRegExp,
                    mode: 'ignore',
                    rule: function (token, from) {
                        var liters = /\d\.\d/.exec(token)[0];

                        // The token came from RS
                        if (from == 'a' && liters == acesVehicle.Liter) {
                            acesVehicle._litersMatched = true;
                            return true;
                        }

                        if (!acesVehicle._litersMatched)
                            acesVehicle._litersMatched = false;

                        return false;
                    }
                },
                {
                    match: ['standard', 'base'],
                    mode: 'ignore'
                },
                {
                    match: /^\d\.\d$/, // Ex: 1/2 or 1/4
                    rule: function () {
                        // If this token wasn't matched then we can't really look
                        // for this anywhere else; we don't want this case to fall through
                        // to any of the other rules
                        return false;
                    }
                },
                {
                    match: /^.{2,}\/.{2,}$/,
                    rule: function (token, from) {
                        var comparisonTokens = from == 'a' ? this.b : this.a,
                            splitToken = token.split('/'),
                            matched = false;

                        $.each(splitToken, function (i, t) {
                            if ($.inArray(t, comparisonTokens) >= 0) {
                                matched = true;
                                return false;
                            }
                        });

                        // If we matched we return 2 results 
                        return matched ? 2 : false;
                    }
                }
            ];

            // Tokens that match if any of the tokens from the list are matched
            scorer.likeTokens = [
            ];

            var score = scorer.score();

            if (score.score >= 1) return FieldComparisonStatus.Success;

            var modelTokens = scorer.tokenize(modelName);

            score = scorer.adjust(score, -modelTokens.length, -modelTokens.length);

            if (score.score > 0) {
                if (score.matches.a.length == scorer.a.length || score.matches.b.length == scorer.b.length)
                    return FieldComparisonStatus.Warning;
            }

            return FieldComparisonStatus.Invalid;
        }

        function compareDoors(a, b) {
            a = a.substr(0, 1); b = b.substr(0, 1);

            if (a == b) return FieldComparisonStatus.Success;
            else if (
                b == 4 && a == 5 ||
                b == 2 && a == 3 ||
                a == 2 && b == 3 ||
                a == 4 && b == 5) return FieldComparisonStatus.Warning;
            else return FieldComparisonStatus.Invalid;
        }

        function compareDriveTypes(rsDrive, acesDrive) {
            rsDrive = $.trim(rsDrive);
            acesDrive = $.trim(acesDrive);

            var equal;
            switch (acesDrive) {
                case 'FWD':
                case 'RWD':
                    equal = rsDrive == '2WD';
                    break;

                case 'AWD':
                    equal = rsDrive == 'AWD';
                    break;

                case '4WD':
                    equal = rsDrive == '4WD';
                    break;

                case 'U/K':
                default:
                    return FieldComparisonStatus.Warning;
            }

            if (equal) return FieldComparisonStatus.Success;
            else return FieldComparisonStatus.Invalid;
        }

        function compareCabTypes(cabType, cabTypeClass, bodyType) {
            bodyType = $.trim(bodyType);

            var equal;
            switch (cabTypeClass) {
                case "Standard Cab":
                    equal = bodyType == 'Standard Cab Pickup';
                    break;

                case "Extended Cab":
                    equal = bodyType == 'Extended Cab Pickup';
                    break;

                case "Crew Cab":
                    equal = bodyType == 'Crew Cab Pickup';
                    break;

                case "Extended Crew Cab":
                    equal = bodyType == 'Extended Crew Cab Pickup';
                    break;

                case "None":
                    return FieldComparisonStatus.Success;

                default:
                    return FieldComparisonStatus.Invalid;
            }

            if (equal) return FieldComparisonStatus.Success;
            return FieldComparisonStatus.Invalid;
        }

        function compareVehicleTypes(styleType, styleDescription, vehicleType, bodyType) {
            styleType = $.trim(styleType);
            styleDescription = $.trim(styleDescription);
            vehicleType = $.trim(vehicleType);
            bodyType = $.trim(bodyType);

            var styleTypesEqual;
            switch (styleType) {
                case 'Car':
                    styleTypesEqual = vehicleType == 'Car'; break;
                case 'Van':
                    styleTypesEqual = vehicleType == 'Van';

                    if (styleTypesEqual && bodyType.indexOf('Van') >= 0) return FieldComparisonStatus.Success;
                    return FieldComparisonStatus.Invalid;
                case 'Truck':
                    if (vehicleType.indexOf('Truck') >= 0) return FieldComparisonStatus.Success;
                    break;
                case 'SUV':
                    if (bodyType == 'Sport Utility') return FieldComparisonStatus.Success;
                    break;
                case 'Crossover':
                    if (['Sport Utility','Wagon','Hatchback'].indexOf(bodyType) >= 0) return FieldComparisonStatus.Success;
                    return FieldComparisonStatus.Invalid;
                default:
                    return FieldComparisonStatus.Invalid;
            }

            if (styleTypesEqual) {
                if (styleDescription == bodyType) return FieldComparisonStatus.Success;
                if (styleDescription == "Roadster" && bodyType == "Convertible") return FieldComparisonStatus.Warning;
                if (contains(bodyType, styleDescription) || contains(styleDescription, bodyType)) return FieldComparisonStatus.Warning;
            }

            return FieldComparisonStatus.Invalid;
        }

        function strictComparisonStatus(a, b) {
            if (notEqual(a, b)) return FieldComparisonStatus.Invalid;
            return FieldComparisonStatus.Success;
        }

        function looseComparisonStatus(a, b, kindaEqualState) {
            if (notEqual(a, b)) {
                if (doesNotContain(a, b)) return FieldComparisonStatus.Invalid;
                return kindaEqualState || FieldComparisonStatus.Warning;
            }

            return FieldComparisonStatus.Success;
        }

        function contains(a, b) {
            return !doesNotContain(a, b);
        }

        function doesNotContain(a, b) {
            b = $.trim(b);
            return a.toLowerCase().indexOf(b.toLowerCase()) < 0;
        }

        function notEqual(a, b) {
            a = $.trim(a);
            b = $.trim(b);

            return a.toLowerCase() != b.toLowerCase();
        }

        // #endregion
       

        return {
            // #region View Actions
            actions: {
                'filter': function () {
                    $rsFilterModalForm.syncFormToObject(rsFilters);

                    $rsMakeInput.add($rsModelInput).each(function () {
                        var $filter = $(this);

                        if ($filter.is(':visible') && $filter.hasClass('loading-options')) {
                            var name = $filter.data('name'),
                                oldValue = rsCurModalFilters[name];

                            if (oldValue) rsFilters[name] = oldValue;
                            else delete rsFilters[name];
                        }
                    });

                    if ('UnlinkStatus' in rsFilters === false) rsFilters.UnlinkStatus = false;
                    if ('HasImage' in rsFilters === false) rsFilters.HasImage = false;
                    if ('TireOptionRequirement' in rsFilters === false) rsFilters.TireOptionRequirement = false;
                    if ('FitmentRequirement' in rsFilters === false) rsFilters.FitmentRequirement = false;

                    removeDefaultProperties(rsFilters, defaultRsFilters);

                    rsDatagrid.search(getRSFilters());
                    filtersUpdated();
                },
                'filter-aces': function () {
                    $acesFilterModalForm.syncFormToObject(acesFilters);
                    acesDatagrid.search(acesFilters);
                    acesFiltersUpdated();
                }
            },
            // #endregion

            // #region View Load Event Binding
            viewWillLoad: function () {
                $(document).on({
                    'keydown': KeyboardHelper.down,
                    'keyup': KeyboardHelper.up
                });

                $(window).on({
                    'resize': updateLayout
                });
            },
            viewWillUnload: function () {
                $(document)
                    .off('keydown', KeyboardHelper.down)
                    .off('keyup', KeyboardHelper.up);

                $(window)
                    .off('resize', updateLayout);
            },
            // #endregion

            viewWillCreate: function ($view) {
                var state = context.state || {};

                // Extend settings/filters with their default properties
                $.extend(rsFilters, state.filters);
                $.extend(settings, rstools.storage.get('al-settings'));

                // #region Define jQuery Elements
                $topContainer = $view.find('.container:first');

                $rsSection = $view.find('#al-rs-section');
                $acesSection = $view.find('#al-aces-section');
                $rsCount = $rsSection.find('.vehicle-count');
                $acesCount = $acesSection.find('.vehicle-count');
                $acesCurFilters = $acesSection.find('.cur-filters');

                $rsPreviewTable = $view.find('#al-rs-preview-table');

                $settingsModal = $view.find('#al-settings-modal');

                $rsFilterModal = $view.find('#al-rs-filter-modal');
                $rsFilterModalForm = $rsFilterModal.find('form');
                $rsFilterBtn = $view.find('#al-rs-filter-btn');

                $acesFilterModal = $view.find('#al-aces-filter-modal');
                $acesFilterModalForm = $acesFilterModal.find('form');
                $acesFilterBtn = $view.find('#al-aces-filter-btn');

                $rsYearInput = $('#al-rs-year-input', $view);
                $rsMakeInput = uifactory.create.dropdown({ root: $('#al-rs-make-input', $view) });
                $rsModelInput = uifactory.create.dropdown({ root: $('#al-rs-model-input', $view) });
                $rsModelFilterGroup = $('#al-rs-model-filter-group', $view);

                $saveLinkagesButton = $('#al-save-linkages-button', $view);
                //#endregion

                //#region RideStyler Vehicle Data Grid
                rsDatagrid = datagrid.create({
                    container: $rsSection,
                    singleSelect: true,
                    bottomControlsEnabled: false,
                    compact: true,
                    saveState: true,
                    fields: [
                        //#region RideStyler Data Grid Fields
                        {
                            name: 'VehicleConfigurationYear',
                            caption: 'Year',
                            format: ['Year']
                        },
                        {
                            name: 'MakeName',
                            caption: 'Make',
                            sort: false
                        },
                        {
                            name: 'VehicleConfiguration_VehicleModelID',
                            caption: 'Model',
                            format: ['ModelName']
                        },
                        {
                            name: 'VehicleConfigurationName',
                            caption: 'Trim',
                            format: ['TrimName'],
                            colClass: 'breakWord'
                        },
                        {
                            name: 'StyleType',
                            sort: false,
                            caption: 'Type',
                            format: function (v) {
                                return v.StyleDescription != 'Unspecified' ? v.StyleDescription : v.StyleType;
                            }
                        },
                        {
                            name: 'VehicleConfiguration_VehicleDoorTypeID',
                            caption: 'Doors',
                            format: ['DoorType']
                        },
                        {
                            name: 'VehicleConfiguration_VehicleDriveTypeID',
                            caption: 'Drive',
                            format: ['DriveType']
                        },
                        {
                            name: 'BedType',
                            sort: false,
                            caption: 'Bed',
                            format: function (v) {
                                var bedType = v.BedType == 'N/A' ? false : v.BedType,
                                    bedLength = v.BedLength == 'N/A' ? false : v.BedLength;

                                if (bedType && bedLength) return bedType + ' (' + bedLength + ')';
                                else if (bedType) return bedType;
                                else if (bedLength) return bedLength;
                                else return 'N/A';
                            }
                        },
                        {
                            name: 'CabType',
                            sort: false,
                            caption: 'Cab'
                        },

                        { name: 'actions', caption: '', sort: false }
                        //#endregion
                    ],
                    actions: [
                        {
                            title: 'Details',
                            icon: 'fa fa-info',
                            fields: ['actions'],
                            callback: function (vehicleConfiguration) {
                                if (!vehicleConfiguration || !vehicleConfiguration.length) return;

                                rstools.vehicle.showDescription(vehicleConfiguration[0]);
                            }
                        }
                    ],
                    getAction: 'Vehicle/GetDescriptions',
                    countAction: 'Vehicle/CountDescriptions',
                    resultListField: 'Descriptions',
                    baseQueryData: {
                        IncludeReferenceCounts: true
                    },
                    search: getRSFilters(),
                    sort: {
                        'VehicleConfigurationYear': SortDirection.Desc,
                        'VehicleConfiguration_VehicleModelID': SortDirection.Asc,
                        'VehicleConfigurationName': SortDirection.Asc
                    },
                    rowCallback: function ($tr, v) {
                        var referenceCounts = v.ReferenceCounts;

                        if (referenceCounts && referenceCounts[VehicleReferenceType.ACES] && referenceCounts[VehicleReferenceType.ACES] > 0) {
                            $tr.addClass('linked');
                        }
                    }
                });
                //#endregion

                //#region ACES Vehicle Data Grid
                acesDatagrid = datagrid.create({
                    container: $acesSection,
                    compact: true,
                    rowSelect: true,
                    saveState: false,
                    fields: [
                        {
                            name: 'ModelName',
                            caption: 'ACES Vehicle',
                            width: 130,
                            format: ['YearID', 'MakeName', 'ModelName']
                        },
                        {
                            name: 'SubmodelName',
                            caption: 'Submodel'
                        },
                        {
                            name: 'BodyTypeName',
                            sort: false,
                            caption: 'Type'
                        },
                        {
                            name: 'Engine',
                            sort: false,
                            caption: 'Engine',
                            format: function (v) {
                                var engineConfig = [];

                                if (v.Liter != '-') engineConfig.push(v.Liter + ' L');
                                if (v.Cylinders != '-') engineConfig.push(v.Cylinders + ' Cyl.');
                                if (v.FuelTypeName != 'U/K' && v.FuelTypeName != 'N/A') engineConfig.push(v.FuelTypeName);

                                return engineConfig.join(' ');
                            }
                        },
                        {
                            name: 'BodyNumDoors',
                            caption: 'Doors'
                        },
                        {
                            name: 'DriveTypeName',
                            caption: 'Drive'
                        },
                        {
                            name: 'BedLength',
                            caption: 'Bed',
                            format: function (v) {
                                var bedType = $.trim(v.BedTypeName);
                                if (bedType == 'N/A' || bedType == 'N/R') return bedType;
                                return bedType + ': ' + v.BedLength + '&Prime;';
                            }
                        }
                    ],
                    getAction: 'Aces/GetVehicleDescriptions',
                    countAction: 'Aces/CountVehicleDescriptions',
                    resultListField: 'Results',
                    baseQueryData: {
                        Region: 1,
                        VehicleTypes: [5, 6, 7]
                    },
                    search: {},
                    pageSize: 499,
                    paginationEnabled: false,
                    useLoadingProgressBar: false,
                    loadFirstResultSet: false,
                    topControlsEnabled: false,
                    sort: {
                        'YearID': SortDirection.Desc,
                        'MakeName': SortDirection.Asc,
                        'ModelName': SortDirection.Asc,
                        'SubmodelName': SortDirection.Asc
                    }
                });
                //#endregion

                // #region Define more jQuery Elements - now that the tables are built
                $rsTable = $rsSection.find('.data-table').not($rsPreviewTable);
                $acesTable = $acesSection.find('.data-table').stickyTableHeaders({ scrollableArea: $acesSection.get(0) });

                $rsTableWrap = $('<div/>', { 'class': 'table-wrapper' }).insertBefore($rsTable);
                $acesTableWrap = $('<div/>', { 'class': 'table-wrapper' }).insertBefore($acesTable);

                $rsTable.stickyTableHeaders({ scrollableArea: $rsTableWrap.get(0) }).appendTo($rsTableWrap);
                $acesTable.stickyTableHeaders({ scrollableArea: $acesTableWrap.get(0) }).appendTo($acesTableWrap);

                $rsTbody = $rsTable.find('tbody');
                $acesTbody = $acesTable.find('tbody');
                $acesTableLoading = $acesSection.find('.data-table-loading');
                $rsTableLoading = $rsSection.find('.data-table-loading');

                $rsMoreResults = $('<div/>', {
                    'class': 'more-results',
                    'append': [
                        $rsMoreResultsText = $('<span/>'),
                        $rsMoreResultsButton = $('<button/>', {
                            'class': 'btn btn-primary',
                            'text': 'Load next page'
                        })
                    ],
                    'appendTo': $rsSection,
                    'css': {
                        'display': 'none'
                    }
                });
                //#endregion

                // #region Bind Events
                rsDatagrid.page.on('selectionChanged', function () {
                    var selectedObjects = rsDatagrid.page.getSelectedObjects(),
                        v = selectedObjects.length && selectedObjects[0];

                    if (v) {
                        updateAcesFiltersWithVehicle(v);
                    }

                    $rsPreviewTable.empty().append($rsTbody.find('.selected').clone(true).removeClass('selected linked'));

                }).on('pageLoaded', function () {
                    if (!rstools.state.get('s')) {
                        $rsTbody.children().first().click();
                        $rsFilterBtn.prop('disabled', false);
                        updateLayout();
                    }
                }).on('pageChanged', function () {
                    var totalResults = rsDatagrid.page.getTotalCount(),
                        resultOffset = rsDatagrid.page.getResultOffset(),
                        pageSize = rsDatagrid.getPageSize(),
                        resultsOffPage = totalResults - pageSize - resultOffset;

                    $rsTableWrap.scrollTop(0);

                    $rsMoreResults.css('display', resultsOffPage > 0 ? '' : 'none');
                    $rsMoreResultsText.text('There are ' + resultsOffPage + ' more results');

                    $rsFilterBtn.prop('disabled', true);
                    $saveLinkagesButton.prop('disabled', true);
                    $acesFilterBtn.prop('disabled', true);
                }).on('totalCountChanged', function (totalVehicles) {
                    if (totalVehicles) $rsCount.text(totalVehicles + ' vehicles').fadeIn();
                    else $rsCount.fadeOut();
                });

                acesDatagrid.page
                    .on('pageLoaded', function () {
                        updateAcesTable();

                        var selectedObjects = rsDatagrid.page.getSelectedObjects(),
                            v = selectedObjects.length && selectedObjects[0];

                        $saveLinkagesButton.prop('disabled', !v);
                        $acesFilterBtn.prop('disabled', false);
                    })
                    .on('pageChanged', function () {
                        $acesTableWrap.scrollTop(0);

                        $saveLinkagesButton.prop('disabled', true);
                        $acesFilterBtn.prop('disabled', true);
                    })
                    .on('selectionChanged', function () {
                    })
                    .on('totalCountChanged', function (totalVehicles) {
                        if (totalVehicles) $acesCount.text(totalVehicles + ' vehicles').show();
                        else $acesCount.hide();
                    });

                $rsFilterModal.on('show.bs.modal', function () {
                    rsCurModalFilters = getRSFilters(true);

                    $rsFilterModalForm.syncObjectToForm(rsCurModalFilters);
                }).on('click', '#al-rs-reset-btn', function () {
                    rsCurModalFilters = $.extend({
                        VehicleMake: false,
                        VehicleModel: false
                    }, defaultRsFilters);
                    $rsFilterModalForm.syncObjectToForm(rsCurModalFilters);
                });

                $acesFilterModal.on('show.bs.modal', function () {
                    $acesFilterModalForm.syncObjectToForm(acesFilters);
                });

                $rsMakeInput.on('change', function () {
                    var rsDropdown = $rsMakeInput.data('rsDropdown'),
                        makeID = rsDropdown.selectedOption && rsDropdown.selectedOption.value;

                    $rsModelInput.trigger('rs-dropdown-loaded', [undefined, true]);

                    if (makeID) {
                        $rsModelFilterGroup.show();

                        ridestyler.ajax.send({
                            action: 'Vehicle/GetModels',
                            data: {
                                VehicleMake: makeID
                            },
                            callback: function (response) {
                                var models = $.map(response.Models, function (model) {
                                    return {
                                        label: model.VehicleModelName,
                                        value: model.VehicleModelID
                                    };
                                });

                                models = [{ label: 'All Models', value: false }, '-'].concat(models);

                                $rsModelInput.trigger('rs-dropdown-loaded', [models, true]);
                                $rsModelInput.syncObjectToForm(rsFilters);
                            }
                        });
                    } else {
                        $rsModelFilterGroup.hide();
                    }
                });

                $saveLinkagesButton.on('click', saveLinkages);

                $rsMoreResultsButton.on('click', function () {
                    var pageSize = rsDatagrid.getPageSize(),
                        numLinked = $rsTbody.find('.linked').length;

                    skippedVehicles += pageSize - numLinked;

                    rsDatagrid.page.setResultOffset(skippedVehicles);
                    rsDatagrid.page.reset();
                });

                $settingsModal.on('show.bs.modal', function () {
                    $settingsModal.syncObjectToForm(getSettings());
                }).on('ifToggled', function () {
                    $settingsModal.syncFormToObject(settings);
                    if ('AutoAdvance' in settings === false) settings.AutoAdvance = false;

                    removeDefaultProperties(settings, defaultSettings);

                    settingsUpdated();
                });

                // #endregion

                // #region Get data for Vehicle Filter Modals
                ridestyler.ajax.send({
                    action: 'Vehicle/GetMakes',
                    data: {
                        TireOptionRequirement: 'none',
                        FitmentRequirement: 'none'
                    },
                    callback: function (response) {
                        if (!response.Success || !response.Makes) return;

                        var makes = $.map(response.Makes, function (make) {
                            return {
                                label: make.VehicleMakeName,
                                value: make.VehicleMakeID
                            };
                        });

                        makes = [{ label: 'All Makes', value: false }, '-'].concat(makes);

                        $rsMakeInput.trigger('rs-dropdown-loaded', [makes, true]);
                        $rsMakeInput.syncObjectToForm(rsCurModalFilters);
                    }
                });
                // #endregion

                //#region Setup Keyboard Linkages
                $view.find('.ctrlChar').each(function () {
                    var $char = $(this),
                        $target = $char.closest('.btn'),
                        code = $char.text()[0].toUpperCase().charCodeAt(0);

                    if ($char.data('shift')) {
                        keyboardCtrlChars[code] = function (e) {
                            if (e.shiftKey) {
                                $target.click();
                                e.preventDefault();
                            }
                        };
                    } else {
                        keyboardCtrlChars[code] = $target;
                    }
                });

                keyboardCtrlChars = $.extend({
                    38: function (e) { // Up
                        prevResult();
                        e.preventDefault();
                    },
                    40: function (e) { // Down
                        nextResult();
                        e.preventDefault();
                    },
                    32: function (e) { // Space
                        jumpToCurrentResult();
                        e.preventDefault();
                    },
                    65: function (e) { // A
                        if (e.shiftKey) {
                            var autoAdvance = getSettings().AutoAdvance;
                            settings.AutoAdvance = !autoAdvance;

                            uifactory.alert.show({
                                text: 'Auto advance has been <strong>' + (settings.AutoAdvance ? 'enabled' : 'disabled') + '</strong>.',
                                type: 'info',
                                stack: 'al-auto-advance'
                            });

                            removeDefaultProperties(settings, defaultSettings);
                            settingsUpdated(true);

                            e.preventDefault();
                        }
                    },
                    80: function (e) { // P
                        if (e.shiftKey) {
                            $rsPreviewTable.fadeToggle();
                            e.preventDefault();
                        }
                    },
                    82: function (e) { // R
                        if (e.shiftKey) {
                            updateAcesTable();
                            e.preventDefault();
                        }
                    },
                    191: function (e) { // ?
                        if (e.shiftKey) {
                            $('#al-help-modal').modal('toggle');
                            e.preventDefault();
                        }
                    }
                }, keyboardCtrlChars);

                if (navigator.platform.toLowerCase().indexOf('mac') > -1) {
                    $view.find('.label-ctrl').html('&#8984;');
                }
                //#endregion

                //#region Get the linkage statistics
                var $toolbar = $('<span/>').appendTo($view.find('#al-top-toolbar')).append($('<i/>', { 'class': 'fa fa-spinner fa-spin' }));
                ridestyler.ajax.send({
                    action: 'Vehicle/GetReferenceStatistics',
                    data: {
                        VehicleReferenceType: VehicleReferenceType.ACES,
                        VehicleReferenceField: VcDbReferenceFields.BaseVehicleID
                    },
                    callback: function(response) {
                        $toolbar.empty();

                        if (response.Success) {
                            var referenceStatisticDescriptionMap = {
                                All: 'All Linked Vehicles',
                                WithImages: 'With Images',
                                WithoutImages: 'Without Images'
                            };

                            for (var k in referenceStatisticDescriptionMap) {
                                var $container = $('<span/>', {'class': 'inline-statistic'}).appendTo($toolbar);
                                $('<strong/>', { text: referenceStatisticDescriptionMap[k]}).appendTo($container);

                                var $fraction = $('<span/>', { 'class': 'fraction' }).appendTo($container);

                                $('<span/>').text(response[k].Linked).appendTo($fraction);
                                $('<span/>').text(response[k].Total).appendTo($fraction);

                                $('<span/>', { 'class': 'percentage' }).text(Math.floor(response[k].Linked / response[k].Total * 100) + '%').appendTo($container);
                            }

                            var $countContainer = $('<span/>', { 'class': 'inline-statistic' }).appendTo($toolbar);
                            $('<strong/>', { text: 'Linked ACES Vehicles' }).appendTo($countContainer);
                            
                            var $countFraction = $('<span/>', { 'class': 'fraction' }).appendTo($countContainer);
                            $('<span/>').text(response.Count).appendTo($countFraction);
                            
                            var acesVehicleCount = 46778;
                            $('<span/>', { text: '~' + acesVehicleCount, title: 'Approximate. Last count was done 8/27/2015' }).appendTo($countFraction);
                            $('<span/>', { 'class': 'percentage' }).text('~' + Math.floor(response.Count / acesVehicleCount * 100) + '%').appendTo($countContainer);
                        }
                    }
                });
                //#endregion

                // Update the rsDatagrid controls if need be
                updateRidestyerDataGridLayout();
            },
            viewCreated: function () {
                // Update page layout
                updateLayout();
            }
        };
    });
})(jQuery);