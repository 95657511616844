(function ($) {
    rter.mapController('app.organization.edit', function (context) {
        var filters;

        var $bar,
            $form,
            $name,

            $nameInput, $emailInput;

        return {
            actions: {
                save: function () {
                    setLoading(true);

                    ridestyler.ajax.send({
                        action: 'Organization/Update',
                        data: {
                            OrganizationID: filters.OrganizationID,
                            OrganizationName: $nameInput.val(),
                            OrganizationContactEmail: $emailInput.val()
                        },
                        callback: function (response) {
                            if (response.Success) {
                                uifactory.alert.show({
                                    text: "Organization settings updated."
                                });

                                $name.text($nameInput.val());
                            } else {
                                uifactory.alert.show({
                                    text: "There was a problem saving your organization settings.",
                                    type: 'error'
                                });
                            }

                            setLoading(false);
                        }
                    });
                }
            },
            viewWillCreate: function ($view) {
                filters = $.extend(filters, context.state ? context.state['filters'] : {});

                $bar = $('.bar', $view);
                $name = $('#organization-name', $view);
                $form = $('form', $view);

                $nameInput = $('#organization-enter-name', $view);
                $emailInput = $('#organization-contact-email', $view);

                filters.OrganizationID && ridestyler.ajax.send({
                    action: "Organization/GetOrganizations",
                    data: {
                        OrganizationID: filters.OrganizationID
                    },
                    callback: function (response) {
                        if (response.Success && response.Organizations.length) {
                            var org = response.Organizations[0];

                            setLoading(false);
                            $name.text(org.OrganizationName).removeClass('hide');

                            $nameInput.val(org.OrganizationName);
                            $emailInput.val(org.OrganizationContactEmail);
                        }
                    }
                });
            }
        };

        function setLoading(loading) {
            $form.toggleClass('hide', loading);
            $bar.toggleClass('hide', !loading);
        }
    });
})(jQuery);