(function ($) {
    rter.mapController('app.organization.create', function (context) {
        var parentOrganization;

        var $bar, $form,
            $name, $email;

        return {
            actions: {
                create: function (e) {
                    e.preventDefault();

                    setLoading(true);

                    ridestyler.ajax.send({
                        action: 'Organization/CreateOrganization',
                        data: {
                            Organization_OrganizationID: parentOrganization.OrganizationID,
                            OrganizationName: $name.val(),
                            OrganizationContactEmail: $email.val()
                        },
                        callback: function (response) {
                            if (response.Success) {
                                uifactory.alert.show({
                                    text: "Organization created."
                                });

                                rter.routes.go("/app/organization/list");
                            } else {
                                uifactory.alert.show({
                                    text: "There was a problem creating the organization.",
                                    type: 'error'
                                });
                            }

                            setLoading(false);
                        }
                    });
                }
            },
            viewWillCreate: function ($view) {
                var parentOrganizationID = context.state.ParentOrganizationID;

                $bar = $('.bar', $view);
                $form = $('form', $view);
                $name = $('#organization-enter-name', $view);
                $email = $('#organization-enter-email', $view);

                ridestyler.ajax.send({
                    action: 'Organization/GetOrganizations',
                    data: {
                        OrganizationID: parentOrganizationID
                    },
                    callback: function (response) {
                        if (response.Success && response.Organizations.length) {
                            setLoading(false);

                            parentOrganization = response.Organizations[0];

                            $('#parent-organization-name', $view).text(parentOrganization.OrganizationName);
                        }
                    }
                });
            }
        };

        function setLoading(isLoading) {
            $bar.toggleClass('loading', isLoading)
                .children().toggleClass('hide', isLoading);

            $form.toggleClass('hide', isLoading);
        }
    });
})(jQuery);