(function ($) {
    var reports = [
        {
            name: 'Tire Data Image Report',
            params: {
                ReportType: 'TireDataImageReport'
            }
        },
        {
            name: 'Tire Model Data Status Report',
            params: {
                ReportType: 'TireModelDataStatusReport'
            }
        },
        {
            name: 'Wheel Data Image Report',
            params: {
                ReportType: 'WheelDataImageReport'
            }
        },
        {
            name: 'Wheel Model Data Status Report',
            params: {
                ReportType: 'WheelModelDataStatusReport'
            }
        }
    ];

    function createReportElement(report, viewReportCallback) {
        var panel = document.createElement('div');
            panel.className = 'panel panel-default';
        
        var panelBody = document.createElement('div');
            panelBody.className = 'panel-body';
            panel.appendChild(panelBody);
        
        var icon = document.createElement('i');
            icon.className = 'fa fa-2x align-middle ' + (report.icon || 'fa-file');
            panelBody.appendChild(icon);
        
        var title = document.createElement('h3');
            title.className = 'align-middle inline';
            title.appendChild(document.createTextNode(report.name));
            panelBody.appendChild(title);
        
        var button = document.createElement('button');
            button.className = 'btn btn-primary pull-right';
            button.appendChild(document.createTextNode('View'));
            panelBody.appendChild(button);

        button.onclick = function () {
            viewReportCallback(button, report);
        };
        
        return panel;
    }

    /**
     * @param {string} value 
     */
    function splitUserEnteredValue(value) {
        if (!value) return [];

        return value.split(/(?:\s*,\s*)+/g);
    }

    rter.mapController('app.report.start', function (context) {
        return {
            viewLoaded: function ($context) {
                /** @type {JQuery} */
                var $container = $context.$outlet;

                /**
                 * 
                 * @param {HTMLButtonElement} button 
                 * @param {any} report 
                 */
                var viewReportCallback = function (button, report) {
                    button.disabled = true;

                    var spinner = document.createElement('i');
                        spinner.className = 'fa fa-spinner fa-spin';

                    button.prepend(spinner);

                    var brands = splitUserEnteredValue($container.find('#reports-brands').val());
                    var dataSources = splitUserEnteredValue($container.find('#reports-data-sources').val());
                    ridestyler.ajax.authenticatedUrl('Report/Download', $.extend({}, report.params, {
                        Brands: brands,
                        DataSourceCodes: dataSources
                    })).then(function (url) {
                        window.open(url, '_blank');

                        button.removeChild(spinner);
                        button.disabled = false;
                    });

                };

                $container.find('.reports-container').html($.map(reports, function (report) {
                    return createReportElement(report, viewReportCallback);
                }));
            },
        };
    });
})(jQuery);