(function() {
    var assert = rstools.utils.assert;

    rstools.notes = {
        generateKey: function (namespaceTokens, dataObjectID) {
            if (typeof dataObjectID === 'object')
                dataObjectID = rstools.utils.getNamespace(namespaceTokens).utils.getIDs([dataObjectID])[0];

            return namespaceTokens.replace('.', '-') + '-' + dataObjectID;
        },
        generateKeys: function (namespaceTokens, dataObjectIDs) {
            if (!dataObjectIDs || !dataObjectIDs.length) return [];

            if (typeof dataObjectIDs[0] === 'object')
                dataObjectIDs = rstools.utils.getNamespace(namespaceTokens).utils.getIDs(dataObjectIDs);

            return $.map(dataObjectIDs, function (dataObjectID) {
                return rstools.notes.generateKey(namespaceTokens, dataObjectID);
            });
        },

        getIDFromGeneratedKey: function (namespaceTokens, key) {
            var baseKey = rstools.notes.generateKey(namespaceTokens, '');
            return key.substr(0, baseKey.length) === baseKey ? key.substr(baseKey.length) : undefined;
        },

        createColumnRenderer: function (namespaceTokens) {
            var namespace = rstools.utils.getNamespace(namespaceTokens);

            assert(namespace.utils, namespaceTokens + ' must have a utils namespace');
            assert(typeof namespace.utils.getIDs === 'function', namespaceTokens + ' must have a utils.getIDs function');
            assert(typeof namespace.utils.getTitles === 'function', namespaceTokens + ' must have a utils.getTitles function');

            return function ($tds, dataObjects) {
                $tds.addClass('light loading loading-small');

                var dataObjectIDs = namespace.utils.getIDs(dataObjects);
               
                ridestyler.ajax.send({
                    action: 'Note/CountForKeys',
                    data: {
                        Keys: rstools.notes.generateKeys(namespaceTokens, dataObjectIDs)
                    },
                    callback: function (response) {
                        if (response.Success) {
                            var result = response.Result;
                            var noteCountMap = {};

                            for (var noteKey in result)
                                if (result.hasOwnProperty(noteKey))
                                    noteCountMap[rstools.notes.getIDFromGeneratedKey(namespaceTokens, noteKey)] = result[noteKey];

                            for (var i = dataObjectIDs.length - 1; i >= 0; i--) (function ($td, dataObjectID, dataObject) {
                                var noteCount = dataObjectID in noteCountMap ? noteCountMap[dataObjectID] : 0;

                                var button = document.createElement("button");
                                    button.type = 'button';
                                    button.className = 'btn-link no-underline';
                                    uifactory.create.icon('sticky-note').appendTo(button);
                                    button.appendChild(document.createTextNode(" (" + noteCount + ")"));

                                $td.append(button).click(function (e) {
                                    var key = rstools.notes.generateKey(namespaceTokens, dataObjectID);

                                    rstools.notes.showModal({
                                        key: key,
                                        title: "<strong>Notes:</strong> " + namespace.utils.getTitles([dataObject])[0],
                                        onCountChanged: function () {
                                            ridestyler.ajax.send({
                                                action: "Note/Count",
                                                data: {
                                                    Key: key
                                                },
                                                callback: function (response) {
                                                    if (response.Success) {
                                                        button.removeChild(button.lastChild);
                                                        button.appendChild(document.createTextNode(" (" + response.Count + ")"));
                                                    }
                                                }
                                            });
                                        }
                                    });

                                    e.stopPropagation();
                                });
                            })($tds.eq(i), dataObjectIDs[i], dataObjects[i]);
                        } else {
                            $tds.text('Error');
                            console.error("Error retrieving HasNotes: ", response);
                        }

                        $tds.removeClass('loading');
                    }
                });
            };
        },
        showModal: function (settings) {
            settings = $.extend({
                key: undefined,
                archived: false
            }, settings);

            var key = settings.key;
            var includeArchived = settings.archived;
            
            var noteList = document.createElement('ul');
                noteList.className = 'note-list';

            var addNoNotesNotice = function () {
                if (!noteList.hasChildNodes()) 
                    $('<li/>', {text: 'No Notes Found'})
                        .addClass('note-list-no-notes-message')
                        .appendTo(noteList);
            };

            var countChanged = function () {
                if (typeof settings.onCountChanged === 'function')
                    settings.onCountChanged();
            };
            
            var createNoteListItem = function (note) {
                var li = document.createElement('li');
                    li.className = 'note-list-item' + (note.NoteArchived ? ' note-list-item-archived' : '');

                var header = document.createElement('div');
                    header.className = 'note-list-item-header';
                    li.appendChild(header);

                var author = document.createElement('strong');
                	author.className = 'note-list-item-author';
                    author.appendChild(document.createTextNode(note.NoteAuthorName));
                    header.appendChild(author);

                var updated = document.createElement('time');
                    updated.datetime = note.NoteTextUpdated;
                    updated.className = 'note-list-item-time';
                    updated.appendChild(document.createTextNode(rstools.utils.momentFromDatabaseDateTime(note.NoteTextUpdated).calendar()));
                    header.appendChild(updated);

                var text = document.createElement('div');
                    text.className = 'note-list-item-text';
                    text.appendChild(document.createTextNode(note.NoteText));
                    li.appendChild(text);

                var menuOptions = [];

                if (note.NoteArchived) {
                	menuOptions.push({
                        html: [
                            uifactory.create.icon('trash'),
                            ' Delete'
                        ],
                        action: function () {
                            var $menuButton = $menu.find('button').prop('disabled', true);

                            ridestyler.ajax.send({
                                action: 'Note/Delete',
                                data: {
                                    NoteID: note.NoteID
                                },
                                callback: function (response) {
                                    if (response.Success) {
                                        uifactory.alert.show({
                                            type: 'success',
                                            text: 'The note has been deleted.'
                                        });

                                        noteList.removeChild(li);
                                        addNoNotesNotice();

                                        countChanged();
                                    } else {
                                        $menuButton.prop('disabled', false);
                                        rstools.api.utils.showResponseError("There was an error deleting the note", response);
                                    }
                                }
                            });
                        }
                    });
                	menuOptions.push({
                        html: [
                            'Unarchive'
                        ],
                        action: function () {
                            var $menuButton = $menu.find('button').prop('disabled', true);

                            ridestyler.ajax.send({
                                action: 'Note/Unarchive',
                                data: {
                                    NoteID: note.NoteID
                                },
                                callback: function (response) {
                                    if (response.Success) {
                                        uifactory.alert.show({
                                            type: 'success',
                                            text: 'The note has been unarchived.'
                                        });

                                    	note.NoteArchived = false;
                                        noteList.replaceChild(createNoteListItem(note), li);

                                        countChanged();
                                    } else {
                                        $menuButton.prop('disabled', false);
                                        rstools.api.utils.showResponseError("There was an error unarchiving the note", response);
                                    }
                                }
                            });
                        }
                    });
                } else {
                	menuOptions.push({
                        html: [
                            uifactory.create.icon('check'),
                            ' Archive'
                        ],
                        action: function () {
                            var $menuButton = $menu.find('button').prop('disabled', true);

                            ridestyler.ajax.send({
                                action: 'Note/Archive',
                                data: {
                                    NoteID: note.NoteID
                                },
                                callback: function (response) {
                                    if (response.Success) {
                                        uifactory.alert.show({
                                            type: 'success',
                                            text: 'The note has been archived.'
                                        });

                                        if (!includeArchived) {
                                            noteList.removeChild(li);
                                            addNoNotesNotice();
                                        } else {
                                        	note.NoteArchived = true;
                                            noteList.replaceChild(createNoteListItem(note), li);
                                        }

                                        countChanged();
                                    } else {
                                        $menuButton.prop('disabled', false);
                                        rstools.api.utils.showResponseError("There was an error archiving the note", response);
                                    }
                                }
                            });
                        }
                    });
                }

                if (rstools.user.id === note.NoteAuthor_UserID)
                    menuOptions.unshift({
                        html: [
                            uifactory.create.icon('pencil'),
                            ' Edit'
                        ],
                        action: function () {
                            var $text = $(text).empty();

                            var textarea = document.createElement('textarea');
                                textarea.className = 'form-control note-list-item-edit-control';
                                textarea.value = note.NoteText;
                                textarea.rows = 4;
                                text.appendChild(textarea);

                            var actions = document.createElement('div');
                                actions.className = 'note-list-item-edit-actions';
                                text.appendChild(actions);

                            var saveButton = document.createElement('button');
                                saveButton.className = 'btn btn-success';
                                uifactory.create.icon('floppy-o').appendTo(saveButton);
                                saveButton.appendChild(document.createTextNode(' Save'));
                                actions.appendChild(saveButton);

                            saveButton.onclick = function () {
                                textarea.disabled = saveButton.disabled = true;

                                var noteText = textarea.value;

                                ridestyler.ajax.send({
                                    action: 'Note/Update',
                                    data: {
                                        NoteID: note.NoteID,
                                        Text: noteText
                                    },
                                    callback: function (response) {
                                        if (response.Success) {
                                            $text.text(noteText);
                                        } else {
                                            textarea.disabled = saveButton.disabled = false;
                                            rstools.api.utils.showResponseError("There was an error saving your note", response);
                                        }
                                    }
                                });
                            };
                        }
                    });
                
                var $menu = uifactory.create.button({
                    content: ' ',
                    'class': false,
                    options: menuOptions,
                    caret: false,
                    rightMenu: true
                }).addClass('note-list-item-menu').prependTo(header);

                return li;
            };

            var modal = uifactory.modal.create({
                header: settings.title || "Notes",
                startLoading: true,
                cancelable: true,
                actions: [
                    {
                        'class': 'btn btn-success',
                        'icon': 'plus',
                        'title': 'New Note',
                        'callback': function () {
                            var newNoteButton = this;

                            newNoteButton.disabled = true;

                            var li = document.createElement('li');
                                li.className = 'note-list-item';

                            var header = document.createElement('div');
                                header.className = 'note-list-item-header';
                                li.appendChild(header);

                            var author = document.createElement('strong');
                                author.appendChild(document.createTextNode('Add a New Note'));
                                header.appendChild(author);

                            var text = document.createElement('div');
                                text.className = 'note-list-item-text';
                                li.appendChild(text);

                            var textarea = document.createElement('textarea');
                                textarea.className = 'form-control note-list-item-edit-control';
                                textarea.rows = 4;
                                textarea.onkeyup = function () {
                                    saveButton.disabled = !this.value.length;
                                };
                                text.appendChild(textarea);

                            var actions = document.createElement('div');
                                actions.className = 'note-list-item-edit-actions';
                                text.appendChild(actions);

                            var cancelButton = document.createElement('button');
                                cancelButton.className = 'btn btn-danger';
                                cancelButton.appendChild(document.createTextNode('Cancel'));
                                actions.appendChild(cancelButton);
                                cancelButton.onclick = function () {
                                    noteList.removeChild(li);
                                    addNoNotesNotice();
                                    
                                    newNoteButton.disabled = false;
                                };

                            var saveButton = document.createElement('button');
                                saveButton.className = 'btn btn-success';
                                uifactory.create.icon('floppy-o').appendTo(saveButton);
                                saveButton.appendChild(document.createTextNode(' Save'));
                                actions.appendChild(saveButton);
                                saveButton.disabled = true;
                                saveButton.onclick = function () {
                                    textarea.disabled = cancelButton.disabled = saveButton.disabled = true;

                                    ridestyler.ajax.send({
                                        action: 'Note/Add',
                                        data: {
                                            Key: key,
                                            Text: textarea.value
                                        },
                                        callback: function (response) {
                                            if (response.Success) {
                                                newNoteButton.disabled = false;
                                                noteList.removeChild(li);
                                                noteList.insertBefore(createNoteListItem(response.Result), noteList.firstChild);

                                                countChanged();
                                            } else {
                                                rstools.api.utils.showResponseError("There was an error adding the note", response);
                                                textarea.disabled = cancelButton.disabled = saveButton.disabled = false;
                                            }
                                        }
                                    });
                                };

                            if (noteList.hasChildNodes() && noteList.firstChild.className.indexOf('note-list-no-notes-message') >= 0)
                                noteList.removeChild(noteList.firstChild);

                            noteList.insertBefore(li, noteList.firstChild);
                            noteList.scrollTop = 0;
                            textarea.focus();
                        }
                    }
                ]
            });

			var updateList = function () {
				ridestyler.ajax.send({
	                action: 'Note/Get',
	                data: {
	                    Key: key,
	                    IncludeArchived: includeArchived
	                },
	                callback: function (response) {
	                    if (response.Success) {
	                        var notes = response.Notes;

	                        for (var i = 0; i < notes.length; i++) 
	                            noteList.appendChild(createNoteListItem(notes[i]));

	                        modal.$body.removeClass('loading');

	                        addNoNotesNotice();
	                        modal.$modal.removeClass('loading');
	                    } else {
	                        rstools.api.utils.showResponseError("There was an error retrieving notes", response);
	                        modal.hide();
	                    }
	                }
	            });
			};

            modal.$body
                .addClass('scrollable')
                .css('minHeight', '133px')
                .append(noteList);

           	var includeArchivedCheck = document.createElement('input');
           		includeArchivedCheck.type = 'checkbox';
           		includeArchivedCheck.checked = includeArchived;
           		includeArchivedCheck.onchange = function () {
           			includeArchived = this.checked;

           			modal.$body.addClass('loading');

           			while (noteList.hasChildNodes()) noteList.removeChild(noteList.firstChild);

           			modal.$footer.find('button:contains(New Note)').prop('disabled', false);

           			updateList();
           		};
            var includeArchivedLabel = document.createElement('label');
            	includeArchivedLabel.className = 'pull-left';            
           		includeArchivedLabel.appendChild(includeArchivedCheck);
           		includeArchivedLabel.appendChild(document.createTextNode(' Include Archived'));

            modal.$footer.prepend(includeArchivedLabel);


            updateList();

            modal.show();
        }
    };
})();