(function ($) {
	var ResourceFileType = rstools.constants.ResourceFileType;
	var ResourceAssetType = rstools.constants.ResourceAssetType;

	var resourceAssetTypeAccepts = {
		1: undefined,   // Unspecified
		10: undefined,   // VehicleImageBundleSide
		11: undefined,   // VehicleImageBundleAngled
		20: "image/\*",   // WheelImageSide
		21: "image/\*",   // WheelImageAngled
		22: "image/\*",   // WheelImageCatalog
		29: "image/\*",   // WheelBrandImage
		30: "image/\*",   // TireImageSide         
		32: "image/\*",   // TireImageCatalog
		33: "image/\*",   // TireImageTread        
		40: "image/\*"    // OrganizationImage
	};

	var dropdownOptionsFromObject = rstools.utils.dropdownOptionsFromObject;

	window.rstools.resource = {
		info: function (resourceID) {
			if (!resourceID) return;

			var modal = uifactory.modal.create({
				cancelable: true,
				header: 'Edit Resource Information',
				footer: false
			});

			modal.$modal.addClass('loading');

			rstools.utils.apiDeferred({ action: 'Resource/GetResourceDescriptions', data: { Resource: resourceID }, responseKey: 'Resources' })
				.done(function (resource) {
					resource = resource[0];

					var isImage = resource.ResourceFileType === ResourceFileType.Image;

					var $form = uifactory.create.form({
						fields: [
							{
								name: 'ResourceID',
								type: 'hidden',
								value: resource.ResourceID
							},
							{
								name: 'ResourceName',
								label: 'Name',
								value: resource.ResourceName,
								groupClass: 'form-group'
							},
							{
								type: 'static',
								label: 'File Size',
								text: rstools.utils.fileSizeFormat(resource.ResourceFileSize)
							},
							{
								type: 'static',
								label: 'Metrics',
								text: (function (metrics) {
									if (metrics) {
										return rstools.resource.utils.createMetricsUI(resource);
									} else {
										return "No Metrics";
									}
								})(resource.ResourceMetrics)
							}
						],

						actions: [
							{
								label: 'Save',
								buttonClass: 'btn btn-success',
								action: 'submit'
							},
							{
								label: 'Cancel',
								action: function() {
									modal.hide();
								}
							},
							{
								label: 'Download',
								buttonClass: 'btn btn-default pull-left',
								action: 'link',
								icon: 'cloud-download',
								href: resource.ResourceDownloadURL
							}
						],
						actionContainerClass: 'text-right clear-both',
						urlAction: 'ridestyler',
						url: 'Resource/EditResource',
						submitErrorMessage: 'There was a problem editing your resource: {Message}',
						submitSuccessCallback: function() {
							rstools.events.dataChanged('resource', 'edit');
							modal.hide();
							uifactory.alert.show({ text: 'The resource has been saved.' });
						}
					});

					if (isImage) {
						uifactory.create.resourceThumbnail({
							imageParameters: {
								Width: 200,
								Height: 200,
								PositionX: 1,
								PositionY: 1
							},
							resources: [resource.ResourceID],
							showInfoButton: false
						}).addClass('pull-left').appendTo(modal.$body);

						$form.css('marginLeft', '220px');
					}

					$form.appendTo(modal.$body);

					modal.$modal.removeClass('loading');
				})
				.fail(function () {
					uifactory.alert.show({
						text: 'There was an error loading the description for the resource.',
						type: 'error'
					});

					modal.hide();
				});

			modal.show();
		},
		list: function (settings) {
			var grid, modal;
			var $uploadForm, $filterForm;

			var actions = [
				{
					'title': 'Upload',
					'class': 'btn btn-default pull-left',
					'icon': 'cloud-upload',
					'callback': function () {
						toggleOverlays($uploadForm);
					}
				}
			];

			settings = $.extend({
				mode: 'manage',
				onSelect: undefined,
				filters: undefined,
				organization: undefined,
				disableFilters: false
			}, settings);

			var selectAction;
			if (settings.mode === 'select') {
				selectAction = {
					'title': "Select",
					'class': 'btn btn-primary',
					'callback': function () {
						var selectedObjects = grid.page.getSelectedObjects();
						var selectedResource = selectedObjects.length && selectedObjects[0];

						if (typeof settings.onSelect === 'function') settings.onSelect(selectedResource);

						modal.hide();
					}
				};

				actions.push(selectAction);
			}

			modal = uifactory.modal.create({
				cancelable: true,
				header: settings.mode === "manage" ? "Manage Resources" : "Select a Resource",
				actions: actions,
				footer: settings.mode === "select"
			});


			var $closeButton = modal.$header.find('.minimize');

			var uploadFields = [
				{
					type: 'dropdown',
					label: 'Asset Type',
					buttonClass: 'btn-sm btn-default',
					labelClass: 'control-label small',
					options: $.map(dropdownOptionsFromObject(ResourceAssetType), function (o) {
						if (typeof settings.filters !== 'object' || !settings.filters['AssetType']) return o;
						return o.value == settings.filters['AssetType'] ? o : undefined;
					}),
					name: 'ResourceAssetType'
				},
				{
					type: 'file',
					labelClass: 'control-label small',
					label: 'File'
				}
			];

			if (settings.organization) uploadFields.push({
				type: 'hidden',
				name: 'ResourceOrganization',
				value: settings.organization
			});

			$uploadForm = uifactory.create.form({
				formClass: 'form-horizontal modal-overlay',
				fields: uploadFields,
				actions: [
					{
						label: 'Upload',
						buttonClass: 'btn btn-primary',
						action: 'submit'
					}
				],

				urlAction: 'upload',
				url: 'Resource/Upload',

				submitErrorMessage: 'There was a problem uploading your resource: {Message}',
				submitSuccessCallback: function () {
					var $uploadInput = $uploadForm.find('input[type=file]');
					var uploadedFileName = $uploadInput.val().split('/').pop().split('\\').pop();

					uploadedFileName = uploadedFileName.substr(0, uploadedFileName.lastIndexOf('.'));

					$headerSearch.val(uploadedFileName);
					$filterForm.submit();

					$uploadInput.val('');
					hideOverlays();
				}
			}).hide().insertAfter(modal.$header);

			$uploadForm.prepend($('<p/>', { text: 'Use this form to upload a resource.' }));

			{
				var $uploadFileInput = $uploadForm.find('input[type=file]');

				function updateUploadFormFileAccepts(resourceAssetType) {
					var accepts = resourceAssetType in resourceAssetTypeAccepts ? resourceAssetTypeAccepts[resourceAssetType] : undefined;

					if (accepts) $uploadFileInput.prop('accept', accepts);
					else $uploadFileInput.removeProp('accept');
				}

				if (settings.filters && settings.filters['AssetType'])
					updateUploadFormFileAccepts(settings.filters['AssetType']);

				$uploadForm.on('change', function () {
					var data = {};
					$uploadForm.syncFormToObject(data);
					updateUploadFormFileAccepts(data.ResourceAssetType);
				});
			}

			$filterForm = uifactory.create.form({
				formClass: 'form-horizontal modal-overlay',
				beforeSubmit: function (formData) {
					var search = $headerSearch.val();

					if (search) formData.Search = search;

					grid.search(formData);
					hideOverlays();
				},
				fields: [
					{
						type: 'dropdown',
						label: 'Asset Type',
						buttonClass: 'btn-sm btn-default',
						labelClass: 'control-label small',
						options: [{ label: 'All', value: false }].concat(dropdownOptionsFromObject(ResourceAssetType)),
						name: 'AssetType',
						required: false
					}
				],
				actions: [
					{
						label: 'Filter',
						buttonClass: 'btn btn-primary',
						action: 'submit'
					}
				]
			}).hide().insertAfter(modal.$header);

			if (!settings.disableFilters) {
				$('<button/>', {
					'class': 'circle-icon-button circle-icon-button-filter pull-right',
					'css': {
						'marginRight': 10
					},
					'insertAfter': $closeButton,
					'click': function () {
						toggleOverlays($filterForm);
					}
				});
			}

			var $allOverlays = $filterForm.add($uploadForm);
			function hideOverlays() { $allOverlays.slideUp(); }
			function toggleOverlays($toggleOverlay) {
				var $upOverlays = $toggleOverlay ? $allOverlays.not($toggleOverlay) : $allOverlays;
				$upOverlays.slideUp();
				$toggleOverlay && $toggleOverlay.slideToggle();
			}

			if (settings.filters) $filterForm.syncObjectToForm(settings.filters);

			grid = datagrid.create({
				container: modal.$body.addClass('scrollable'),
				stickyTableHeadersFixedOffset: -31,
				stickyTableHeadersScrollableArea: modal.$body,
				namespace: 'resource',
				saveState: false,
				fields: [
					{
						name: 'ResourceThumbnail',
						caption: '',
						format: function (r) { return rstools.resource.utils.createPreview(r); }
					},
					{ name: 'ResourceName', caption: 'Name', colClass: 'breakWord' },
					{
						name: 'ResourceAssetType', caption: 'Asset Type',
						format: function (r) {
							return ResourceAssetType[r.ResourceAssetType];
						}
					},
					{
						name: 'ResourceFileSize', caption: 'Size',
						format: function (r) {
							return rstools.utils.fileSizeFormat(r.ResourceFileSize);
						}
					},
					{
						name: 'actions', caption: ''
					}
				],
				actions: [
					{
						title: 'Info',
						icon: 'fa fa-info',
						multiple: false,
						fields: ['actions'],
						callback: function (rows) { rstools.resource.info(rows[0].ResourceID); }
					}
				],
				getAction: 'Resource/GetResources',
				countAction: 'Resource/CountResources',
				resultListField: 'Resources',
				baseQueryData: {
					Organization: settings.organization
				},
				search: settings.filters,
				compact: true,
				singleSelect: settings.mode === 'select',
				rowCallback: function ($tr, resource) {
					if (settings.mode === 'select' && typeof settings.selectFilter === "function")
						$tr.toggleClass('text-muted', !settings.selectFilter(resource));
				}
			});

			var $headerSearch = $('<input/>', {
				'type': 'search',
				'results': 5,
				'class': 'form-control',
				'on': {
					'keypress': function (e) {
						if (e.keyCode === 13)
							$filterForm.submit();
					}
				},
				'placeholder': 'Search resources',
				'appendTo': grid.topControls
			});

			if (settings.mode === 'select')
				grid.page.on('selectionChanged', function () {
					var selectedRows = grid.page.getSelectedObjects();
					var resource = selectedRows.length && selectedRows[0];
					var isDisabled = !resource;

					selectAction.$button.prop('disabled', isDisabled);
				});

			modal.show();
		},

		utils: {
			createPreview: function (resource, settings) {
				settings = $.extend({
					Width: 50,
					Height: 50
				}, settings);

				if (resource.ResourceFileType === ResourceFileType.Image) {
					return $('<img/>', {
						'class': 'img-responsive img-center',
						'src': ridestyler.ajax.url("Resource/Image", {
							Width: settings.Width,
							Height: settings.Height,
							PositionX: 1,
							PositionY: 1,
							Resource: resource.ResourceID
						})
					});
				}

				return $('<div/>');
			},
			imagePopover: function ($target, resource, settings) {
				$target.popover($.extend({
					html: true,
					delay: {
						hide: 0,
						show: 500
					},
					content: function () {
						var $loader = $('<div/>', { 'class': 'loading light' });
						var $img = $('<img/>', {
							'class': 'img-responsive img-center',
							'src': ridestyler.ajax.url("Resource/Image", {
								Width: settings.width || 50,
								Height: settings.height || settings.width || 50,
								PositionX: settings.positionX || 1,
								PositionY: settings.positionY || settings.positionX || 1,
								Resource: resource.ResourceID
							}),
							'on': {
								'error': function () {
									$target.popover('destroy');

									uifactory.alert.show({
										text: 'There was an error loading image for the resource: ' + resource.ResourceName,
										type: 'error'
									});
								}
							}
						});
						var img = $img[0];

						if (!img.complete || typeof img.naturalWidth === 'undefined' || img.naturalWidth === 0) {
							$img.hide().on('load', function () {
								var popover = $target.data('bs.popover');
								if (popover && popover.$tip.hasClass('in')) popover.show();
							});
						} else
							return $img;

						return [
							$loader,
							$img
						];
					},
					placement: 'auto bottom',
					title: resource.ResourceName,
					trigger: 'hover'
				}, settings));
			},
			createMetricsUI: function (resourceDescription) {
				var metrics = resourceDescription.ResourceMetrics;

				if (resourceDescription.ResourceFileType === ResourceFileType.Image) {
					return [
						$('<div/>', {
							append: [
								$("<strong/>", {
									'append': [
										uifactory.create.icon({
											size: 'fw',
											name: 'arrows-h'
										}),
										' Width: '
									]
								}),
								metrics.Width
							]
						}),
						$('<div/>', {
							append: [
								$("<strong/>", {
									'append': [
										uifactory.create.icon({
											size: 'fw',
											name: 'arrows-v'
										}),
										' Height: '
									]
								}),
								metrics.Height
							]
						}),
						$('<div/>', {
							append: [
								$("<strong/>", {
									'append': [
										uifactory.create.icon({
											size: 'fw',
											name: 'tint'
										}),
										' Color Depth: '
									]
								}),
								metrics.ColorDepth
							]
						}),
						$('<div/>', {
							append: [
								$("<strong/>", {
									'append': [
										uifactory.create.icon({
											size: 'fw',
											name: 'info'
										}),
										' Format: '
									]
								}),
								metrics.ImageFormat
							]
						}),
					];
				}
			},
			createResourceFilterForm: function (ctx, settings) {
				settings = settings || {
					filters: undefined,
					onReset: undefined,
					onSubmit: undefined
				};

				return uifactory.create.filterForm({
					filters: settings.filters,
					fields: [
						{
							name: 'Search',
							type: 'text-search',
							label: 'Search',
							required: false
						},
						{
							type: 'dropdown',
							label: 'Asset Type',
							buttonClass: 'btn-sm btn-default',
							labelClass: 'control-label small',
							options: [{ label: 'All', value: false }].concat(dropdownOptionsFromObject(ResourceAssetType)),
							name: 'AssetType',
							required: false
						}
					],
					onSubmit: settings.onSubmit,
					onReset: settings.onReset
				});
			},
			columnRenderer: function ($tds, data, settings) {
				if (!data || !data.length) return;

				$tds.empty().addClass('loading light loading-small');

				settings = $.extend({
					// A function returning true if the resource (param 1) returned by the request
					// matches one of the data objects passed in (param 2)
					resourceMatchesObject: undefined,

					// A function returning a resource type defined in resourceTypes
					// for a given resource returned by the request
					getResourceType: undefined,

					// A function returning a ResourceID for a given resource returned by the request
					getResourceID: undefined,

					// A request object to pass into apiDeferred
					request: undefined,

					// If true, for each type only show if it has resources or not
					showStatusOnly: false,

					// The settings to use for showing resource images
					resourceSettings: {
						Width: 50,
						Height: 50,
						PositionX: 1,
						PositionY: 1
					},

					// A list of type names to show for resources
					resourceTypes: []
				}, settings);

				rstools.utils.apiDeferred(settings.request)
					.done(function (resources) {
						$tds.each(function (i) {
							var $td = $(this);
							var object = data[i];
							var matchingResources = {};

							$.each(settings.resourceTypes, function (i, type) {
								matchingResources[type] = [];
							});

							$.each(resources, function (i, resource) {
								if (settings.resourceMatchesObject(resource, object)) {
									matchingResources[settings.getResourceType(resource)].push(resource);
								}
							});

							var container = $('<div/>', { 'class': 'thumbnail-grid' }).appendTo($td);
							var containers = {};

							$.each(settings.resourceTypes, function (i, type) {
								containers[type] = $('<span/>').text(type).appendTo(container);
							});

							if (settings.showStatusOnly) {
								$.each(settings.resourceTypes, function (i, type) {
									var c = containers[type].addClass('label label-default');

									if (!matchingResources[type].length)
										c.addClass('text-muted');
								});
							} else {
								$.each(settings.resourceTypes, function (i, type) {
									var c = containers[type];
									var rs = matchingResources[type];

									if (rs.length) {
										var imageData = $.extend({
											Resource: settings.getResourceID(rs[0])
										}, settings.resourceSettings);

										$('<img/>', {
											'src': 'images/gui/transparent.gif',
											'data-original': ridestyler.ajax.url('Resource/Image', imageData),
											on: {
												'load error': function(e) {
													var $this = $(this);

													$this.parent().removeClass('loading loading-image');
													if (e.type === 'error') $this.replaceWith('Error');
												}
											}
										}).lazyload().prependTo(c);

										if (rs.length > 1)
											c.append([' ', uifactory.create.icon('th').addClass('text-warning')]);
									} else {
										c.addClass('text-muted');
									}
								});
							}

							$td.removeClass('loading');

							var hasImages = false;

							// Detect if there were any images returned
							for (var resourceType in matchingResources) {
								if (matchingResources[resourceType].length) {
									hasImages = true;
									break;
								}
							}

							if (!settings.showStatusOnly && hasImages)
								$td.addClass('loading-image');
						});

						$(window).trigger('scroll');
					})
					.always(function () {
						var container = $('<div/>', { 'class': 'thumbnail-grid' });

						$('<span/>').text('Side').addClass('text-muted').appendTo(container);
						$('<span/>').text('Angle').addClass('text-muted').appendTo(container);
						$('<span/>').text('Catalog').addClass('text-muted').appendTo(container);

						$tds.filter('.loading').removeClass('loading').append(container);
					});
			}
		}
	};
})(jQuery);