(function ($) {
    rter.mapController('app.vehicle.configuration', function (context) {
        var grid = null;
        var $filterForm, $filterButton;
        var $backToModelsButton;

        var filters = {};

        return {
            viewWillLoad: function(ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
            },
            viewCreated: function ($view) {
                $filterForm = rstools.vehicle.utils.createVehicleFilterForm(context, {
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters
                });

                grid =
                    datagrid.create({
                        container: $view.find('div.data-wrapper'),
                        allowShowAll: true,
                        fields: [
                            { name: 'VehicleConfigurationYear', format: ['Year'], caption: 'Year' },
                            { name: 'MakeName', caption: 'Make', sort: false },
                            { name: 'ModelName', caption: 'Model', sort: false },
                            { name: 'TrimName', caption: 'Trim', sort: false },
                            {
                                name: 'VehicleConfigurationResources',
                                caption: 'Images',
                                sort: false,
                                format: function (configuration) {
                                    var container = document.createElement('div');
                                        container.className = 'thumbnail-grid';
                                    
                                    var side = document.createElement('span');
                                        side.appendChild(document.createTextNode('Side'));
                                    
                                    var angled = document.createElement('span');
                                        angled.appendChild(document.createTextNode('Angled'));
                                    
                                    if (configuration.HasAngledImage) {
                                        $('<img/>', {
                                            'src': 'images/gui/transparent.gif',
                                            'data-original': ridestyler.ajax.url('Vehicle/Render', {
                                                VehicleConfiguration: configuration.ConfigurationID,
                                                Width: 50,
                                                Height: 50,
                                                PositionX: 1,
                                                PositionY: 1,
                                                Type: 'Angle'
                                            })
                                        }).lazyload().prependTo(angled);
                                    } else angled.className += ' text-muted';
                                    
                                    if (configuration.HasSideImage) {
                                        $('<img/>', {
                                            'src': 'images/gui/transparent.gif',
                                            'data-original': ridestyler.ajax.url('Vehicle/Render', {
                                                VehicleConfiguration: configuration.ConfigurationID,
                                                Width: 50,
                                                Height: 50,
                                                PositionX: 1,
                                                PositionY: 1,
                                                Type: 'Side'
                                            })
                                        }).lazyload().prependTo(side);
                                    } else side.className += ' text-muted';

                                    container.appendChild(side);
                                    container.appendChild(angled);

                                    return container;
                                }
                            },
                            { name: 'DoorType', caption: 'Doors', sort: false },
                            { name: 'DriveType', caption: 'Drive', sort: false },
                            { name: 'StyleType', caption: 'Style', sort: false },
                            { name: 'StyleDescription', caption: 'Style Description', sort: false },
                            { name: 'CabType', caption: 'Cab', sort: false },
                            { name: 'CabTypeClass', caption: 'Cab Class', sort: false },
                            { name: 'BedType', caption: 'Bed Type', sort: false },
                            { name: 'BedLength', caption: 'Bed Length', sort: false },
                            {
                                name: 'VehicleConfigurationNotes',
                                caption: 'Notes',
                                colClass: 'text-center',
                                sort: false,
                                width: 60,
                                columnRenderer: rstools.notes.createColumnRenderer('vehicle.configuration'),
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false
                            },
                            { 
                                name: 'VehicleConfigurationDataStatus', 
                                caption: 'Status',
                                colClass: 'text-center',
                                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                                visible: false,
                                format: rstools.datastatus.createColumn('vehicle.configuration')
                            },
                            { name: 'actions', caption: '', width: 80, sort: false }
                        ],
                        rowCallback: function ($tr, makeDescription) {
                            if (makeDescription.VehicleMakeArchived) $tr.addClass('archived-data-object');
                        },
                        headerActions: [
                            rstools.shared.datagrid.headerActions.manageColumns
                        ],
                        getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
                        getAction: 'Vehicle/GetDescriptions',
                        countAction: 'Vehicle/CountConfigurations',
                        resultListField: 'Descriptions',
                        baseQueryData: {
                            TireOptionRequirement: 0,
                            FitmentRequirement: 0
                        },
                        actions: [
                            {
                                title: 'List Fitments',
                                icon: 'fa fa-th-list',
                                id: 'list',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    var filters = {
                                        VehicleConfigurations: []
                                    };

                                    for (var i = 0; i < rows.length; i++)
                                        filters.VehicleConfigurations.push(rows[i].ConfigurationID);

                                    rter.routes.go('/app/vehicle/fitment', { filters: filters });
                                }
                            },
                            {
                                title: 'Details',
                                icon: 'fa fa-info',
                                multiple: false,
                                fields: ['actions'],
                                forMenu: true,
                                callback: function (rows) {
                                    rstools.vehicle.showDescription(rows[0]);
                                }
                            }
                        ],
                        search: filters,
                        namespace: 'vehicle-configuration'
                    });
                
                grid.page.on('pageLoaded', function () {
                    // Trigger a window scroll event to update lazyload images
                    $(window).trigger('scroll');
                });

                grid.body.addClass('rows-clickable').on('click', 'tr', function () {
                    var action = grid.action.getByID('list');

                    if (action) {
                        var index = $(this).data('row');
                        var model = grid.page.getObjectAtIndex(index);

                        action.callback([model]);
                    }
                });

                var $leftButtons = $('<div/>', { 'class': 'left-buttons' }).prependTo(grid.topControls);

                $backToModelsButton = $('<a/>', {
                    'class': 'btn btn-info',
                    'append': [
                        uifactory.create.icon('caret-left'),
                        ' Models'
                    ],
                    'href': '#!/app/vehicle/model'
                }).appendTo($leftButtons);

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .appendTo($leftButtons)
                    .trigger('filters-updated', [Object.keys(filters).length]);

                var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

                // rstools.user.isSystemAdmin() && $('<button/>', {
                //  'class': 'btn btn-primary pull-right',
                //  'append': [
                //      'Create Configuration &nbsp;',
                //      uifactory.create.icon('plus')
                //  ],
                //  'appendTo': $rightButtons,
                //  'click': function() {
                //      rstools.vehicle.configuration.create();
                //  }
                // });
            },
            viewLoaded: function (ctx) {
                // Update filters from the current ctx
                // or grab them from the original context
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

                rstools.utils.setBackButtonTargetFromContext($backToModelsButton, ctx, '/app/vehicle/model');

                $filterForm.syncObjectToForm(filters);
            }
        };

        function filtersUpdated() {
            $filterForm && $filterForm.syncFormToObject(filters);
            $filterButton && $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(filters);
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);