rter.mapController('app.admin.resource', function (context) {
	var filters = {};
	var grid;

	var $filterForm, $filterButton;

	// Constants
	var ResourceFileType = rstools.constants.ResourceFileType;
	var ResourceAssetType = rstools.constants.ResourceAssetType;

	return {
		viewLoaded: function (ctx) {
			// Update filters from the current ctx
			// or grab them from the original context
			filters = $.extend(filters, ctx.state ? context.state['filters'] : {});
		},
		viewCreated: function ($view) {
			// Create the datagrid
			grid =
				datagrid.create({
					container: $view.find('div.data-wrapper'),
					fields: [
						{
							name: 'ResourceThumbnail',
							caption: 'Preview',
							sort: false,
							format: function (r) { return rstools.resource.utils.createPreview(r); }
						},
						{ name: 'ResourceName', caption: 'Name', colClass: 'breakWord' },
						{
							name: 'ResourceAssetType', caption: 'Asset Type',
							format: function (r) {
								return ResourceAssetType[r.ResourceAssetType];
							}
						},
						{
							name: 'ResourceFileSize', caption: 'Size',
							format: function (r) {
								return rstools.utils.fileSizeFormat(r.ResourceFileSize);
							}
						},
						{
							name: 'actions', caption: '', sort: false
						}
					],
					actions: [
						{
							title: 'Info',
							icon: 'fa fa-info',
							multiple: false,
							fields: ['actions'],
							callback: function (rows) { rstools.resource.info(rows[0].ResourceID); }
						}
					],
					headerActions: [
						rstools.shared.datagrid.headerActions.manageColumns
					],
					getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
					getAction: 'Resource/GetResources',
					countAction: 'Resource/CountResources',
					resultListField: 'Resources',
					search: filters,
					namespace: 'resource',
					selectEnabled: false
				});

			// Create the filter form
			$filterForm = rstools.resource.utils.createResourceFilterForm(context, {
				onReset: filtersUpdated,
				onSubmit: filtersUpdated,
				filters: filters
			});

			$filterButton = uifactory.create.filterButton({
				menu: $filterForm
			})
				.prependTo(grid.topControls)
				.trigger('filters-updated', [Object.keys(filters).length]);
		}
	};

	function filtersUpdated() {
		$filterForm.syncFormToObject(filters);
		$filterButton.trigger('filters-updated', [Object.keys(filters).length]);

		grid.search(filters);
		rstools.state.set('filters', filters);
	}
});