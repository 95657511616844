(function ($) {
    window.uifactory.modal = (function() {
        var rsModalID = 0;
        var $minimizedModalContainer = $("<div/>", { "class": "minimized-modal-container", "appendTo": "body" }).on("click", "button", function () {
            $.data(this, "target").unminimize();
            this.parentNode.removeChild(this);
        });

        function RidestylerModal(settings) {
            if (settings && settings.nodeType > 0) settings = $(settings);
            if (settings instanceof $) {
                var rsModal = settings.data("rsModal");

                if (rsModal instanceof RidestylerModal) return rsModal;
                else settings = undefined;
            }

            // RidestylerModal Default Settings
            this.settings = $.extend({
                "rsModalID": rsModalID,
                "id": "rs-modal-" + rsModalID,
                "animationClass": "fade",
                "animationDirection": "top",
                "headerId": "rs-modal-" + rsModalID + "-header",
                "startHidden": true,
                "startLoading": false,
                "cancelable": false,
                "minimizable": true,
                "destroyOnHidden": true,
                "customHeader": undefined,
                "header": "",
                "footer": true,
                "body": undefined,
                "actions": [],
                "type": undefined,
                "displayID": undefined,

                "loadData": undefined,
                "bodyFormat": undefined,
                "headerFormat": undefined,
                "footerFormat": undefined,
                "idFormat": undefined,
                "onFail": undefined,
                "onLoad": undefined,
                "hideOnFail": true,

                "template": undefined,
                "model": undefined,

                "confirmationNo": "No",
                "confirmationYes": "Yes",
                "confirmationYesClass": "btn btn-success",
                "confirmationCallback": undefined,
                "hideAfterConfirmation": true
            }, settings);

            rsModalID++;

            this.ready = new $.Deferred();

            this.init();

            return this;
        }

        //#region RideStylerModal Prototype
        RidestylerModal.prototype = {
            init: function() {
                var settings = this.settings;

                if (settings.template)
                    return rstools.template.get(settings.template).done($.proxy(this, "initWithTemplate"));

                this.$modal = $("<div/>", {
                    "class": "modal",
                    "id": settings.id,
                    "tabindex": -1,
                    "role": "dialog",
                    "aria-labeledby": settings.headerId,
                    "aria-hidden": !settings.startHidden,
                    "css": {
                        "zIndex": 1050 + settings.rsModalID + 1
                    }
                });

                if (settings.animationClass) {
                    this.$modal.addClass(settings.animationClass);

                    if (settings.animationDirection)
                        this.$modal.addClass(settings.animationDirection);
                }

                this.initModal();

                this.$dialog = $("<div/>", { "class": "modal-dialog" }).appendTo(this.$modal);
                this.$content = $("<div/>", { "class": "modal-content" }).appendTo(this.$dialog);

                this.$header = $("<div/>", {
                    "class": "modal-header"
                }).appendTo(this.$content);
                this.initHeader();

                this.$body = $("<div/>", {
                    "class": "modal-body"
                }).appendTo(this.$content);
                this.initBody();

                this.$footer = $("<div/>", {
                    "class": "modal-footer"
                }).appendTo(this.$content);
                this.initFooter();

                if (settings.displayID) this.setDisplayID(settings.displayID);

                if (settings.loadData) this.loadData();
                else this.ready.resolve();

                return undefined;
            },

            initModal: function() {
                var settings = this.settings;
                var context = this;

                this.$modal.modal({
                    "backdrop": settings.cancelable ? true : "static",
                    "keyboard": settings.cancelable,
                    "show": !settings.startHidden
                }).data("rsModal", this);

                if (settings.startLoading || settings.loadData)
                    this.$modal.addClass("loading");

                if (settings.destroyOnHidden) {
                    this.$modal.on("hidden.bs.modal", function () {
                        if (context.$modal.hasClass("minimized")) return;

                        context.destroy();
                    });
                }
            },

            initHeader: function() {
                var settings = this.settings;

                if (settings.header === false)
                    this.$header.remove();
                else {

                    if (settings.cancelable)
                        this.$header.append($("<button/>", {
                            "type": "button",
                            "class": "close",
                            "data-dismiss": "modal",
                            "append": [
                                $("<span/>", { "aria-hidden": true, "html": "&times;" }),
                                $("<span/>", { "class": "sr-only", "text": "Close" })
                            ]
                        }));

                    if (settings.minimizable)
                        this.$header.append($("<button/>", {
                            "type": "button",
                            "class": "minimize",
                            "append": [
                                $("<span/>", { "aria-hidden": true, "html": "_" }),
                                $("<span/>", { "class": "sr-only", "text": "Minimize" })
                            ],
                            "click": $.proxy(this, "minimize")
                        }));

                    if (settings.customHeader)
                        this.$header.append(settings.customHeader);
                    else if (settings.header)
                        this.$title = $("<h4/>", { "class": "modal-title", "id": settings.headerId, "html": settings.header })
                            .appendTo(this.$header);
                    else
                        this.$header.addClass('modal-header-empty');
                }
            },

            initBody: function() {
                var settings = this.settings;

                if (settings.body)
                    this.$body.append(settings.body);
            },

            initFooter: function() {
                var settings = this.settings;

                if (settings.footer === false) {
                    this.$footer.remove();
                    this.$footer = null;

                    return;
                } else if (typeof settings.footer === "string" || settings.footer instanceof $ || $.isArray(settings.footer)) {
                    this.$footer.html(settings.footer);
                }

                if (settings.type === "confirmation") {
                    $("<button/>", { "class": "btn btn-default", "type": "button", "data-dismiss": "modal" })
                        .html(settings.confirmationNo)
                        .appendTo(this.$footer);

                    var $yesButton = $("<button/>", { "class": settings.confirmationYesClass, "type": "button" })
                        .html(settings.confirmationYes)
                        .appendTo(this.$footer);

                    var rsModal = this;

                    $yesButton.on("click", function() {
                        triggerConfirmation.call(rsModal, true);
                    });

                    this.$modal.on("hide.bs.modal", function() {
                        triggerConfirmation.call(rsModal, false);
                    });
                }

                if (settings.actions && settings.actions.length)
                    this.$footer.append(this.$actionButtons = $(settings.actions).map(function() {
                        var action = $.extend({
                            "class": "btn btn-default"
                        }, this);

                        var $button = $("<button/>", {
                            "class": action["class"],
                            "text": action.title
                        });

                        this.$button = $button;

                        if (typeof action.callback === "function")
                            $button.click(action.callback);

                        if (action.icon) {
                            var icon = action.icon instanceof $ ? action.icon : uifactory.create.icon(action.icon);
                            if (action.iconPlacement === "right") $button.append([" ", icon]);
                            else $button.prepend([icon, " "]);
                        }

                        return $button[0];
                    }));

                return undefined;
            },

            initWithTemplate: function() {
                var $modal = rstools.template.render(this.settings.template, this.settings.model, { async: false });
                this.initWithDOM($modal);
            },

            initWithDOM: function($modal) {
                this.$modal = $modal;
                this.initModal();

                this.$dialog = $modal.find(".modal-dialog");
                this.$content = $modal.find(".modal-content");
                this.$header = $modal.find(".modal-header");
                this.$body = $modal.find(".modal-body");
                this.$footer = $modal.find(".modal-footer");
                this.$title = $modal.find(".modal-title");

                this.ready.resolve();
            },

            loadData: function () {
                var thisModal = this;
                var settings = this.settings;
                var ready = this.ready;

                if (!settings.loadData) return ready.resolve();

                var data = this.data = {};

                var deferreds = $.map(settings.loadData, function (request, dataName) {
                    // If the request is a jQuery deferred
                    if (request && typeof request.done === "function" && typeof request.fail === "function")
                        return request.done(function (result) {
                            data[dataName] = result;
                        });

                    // Otherwise we're expecting an apiDeferred request
                    if (!request || !request.responseKey) return undefined;

                    var d = $.Deferred();

                    rstools.utils.apiDeferred(request)
                        .done(function (response) {
                            if (request.required && (!response || $.isArray(response) && response.length < 1)) 
                                return d.reject(response);

                            if (request.first) response = response[0];

                            data[dataName] = response;
                            d.resolve(response);
                        })
                        .fail(function (response) {
                            d.reject(response);
                        });

                    return d;
                });

                $.when.apply($, deferreds)
                    .done(function () {
                        if (typeof settings.headerFormat === "function") thisModal.$header.html(settings.headerFormat.call(thisModal, data));
                        if (typeof settings.bodyFormat === "function") thisModal.$body.html(settings.bodyFormat.call(thisModal, data));
                        if (typeof settings.footerFormat === "function") thisModal.$footer.html(settings.footerFormat.call(thisModal, data));
                        if (typeof settings.idFormat === "function") thisModal.setDisplayID(settings.idFormat.call(thisModal, data));
                        if (typeof settings.onLoad === "function") settings.onLoad.call(thisModal, data);

                        ready.resolve();
                    })
                    .fail(function (response) {
                        if (typeof settings.onFail === "function") settings.onFail.call(thisModal, response);
                        if (settings.hideOnFail) thisModal.hide();

                        ready.reject();
                    })
                    .always(function () {
                        thisModal.$modal.removeClass("loading");
                    });
            },

            setAnimationDirection: function(d) {
                var settings = this.settings;

                this.$modal
                    .removeClass(settings.animationDirection)
                    .addClass(settings.animationDirection = d);
            },

            show: function() {
                this.$modal.modal("show");
                this.$modal.data("bs.modal").$backdrop.css("zIndex", 1050 + this.settings.rsModalID);
            },

            hide: function() {
                this.$modal.modal("hide");
            },

            destroy: function() {
                this.$modal.remove();
            },

            minimize: function () {
                if (this.$modal.hasClass("minimized")) return;

                this.$modal.addClass("minimized");
                this.hide();

                $("<button/>", {
                    "class": "minimized-modal-button",
                    "text": typeof this.settings.header === "string" ? this.settings.header : this.$header.find("h1,h2,h3,h4,h5,h6").first().text(),
                    "appendTo": $minimizedModalContainer
                }).data("target", this);
            },
            unminimize: function () {
                if (!this.$modal.hasClass("minimized")) return;

                this.show();
                this.$modal.removeClass("minimized");
            },
            setDisplayID: function(displayID) {
                var ids = $.makeArray(displayID);

                if (!this.$id)
                    this.$id = $("<div/>", { "class": "modal-id" });
                else
                    this.$id.empty();

                if (ids.length > 0) {
                    if (ids.length === 1) this.$id.text(ids[0]);
                    else {
                        $("<a/>", {
                            "text": "#",
                            "href": "#"
                        }).click(false).tooltip({
                            html: true,
                            trigger: "click",
                            title: ids.join("<br/>")
                        }).appendTo(this.$id);
                    }

                    this.$id.appendTo(this.$content);
                }

                if (this.$footer)
                    this.$footer.toggleClass("with-id", ids.length > 0);
                else
                    this.$content.toggleClass("with-id", ids.length > 0);
            },

            shake: function() {
                this.$dialog.addClass("shake-once");
                setTimeout($.proxy(this.$dialog, "removeClass", "shake-once"), 1500);
            },

            showFooterError: function(errorContent) {
                var $footer = this.$footer;

                $footer.find(".footer-error").remove();

                errorContent && $("<div/>", {
                    "class": "footer-error pull-left",
                    "append": errorContent,
                    "appendTo": $footer
                });
            }
        };
        //#endregion

        function triggerConfirmation(confirmed) {
            if (typeof this.confirmed !== "undefined") return;
            this.confirmed = confirmed;

            if (typeof this.settings.confirmationCallback === "function")
                this.settings.confirmationCallback.call(this, confirmed);

            if (this.settings.hideAfterConfirmation)
                this.hide();

            this.$modal.trigger("confirmed", [confirmed]);
        }

        return {
            create: function(settings) {
                var rsModal = new RidestylerModal(settings);

                return rsModal;
            },
            show: function(settings) {
                var rsModal = new RidestylerModal(settings); // Create a new modal, or return an existing one

                if (rsModal.settings.startHidden) // Skip calling show twice
                    rsModal.show();

                return rsModal.$modal;
            },
            hide: function(settings) {
                var rsModal = new RidestylerModal(settings); // Create a new modal, or return an existing one

                rsModal.hide();

                return rsModal.$modal;
            }
        };
    })();
})(jQuery);