(function ($) {
    window.uifactory = function () {
        return {
            alert: function () {
                var $alertContainer = null;
                var alerts = [];

                return {
                    show: function (options, type) {
                        if (typeof options === 'string') {
                            options = {
                                text: options
                            };
                        }

                        if (typeof type === 'string') options.type = type;

                        options = $.extend({
                            text: 'This is your alert message!',
                            type: 'success',
                            stack: null,
                            dismiss: true,
                            duration: 5000
                        }, options);

                        if ($alertContainer == null) {
                            $alertContainer = $('<div/>').css({
                                left: '20%',
                                position: 'fixed',
                                bottom: '50px',
                                width: '60%',
                                'z-index': 9999
                            }).fadeIn().appendTo('body');
                        }

                        var $alert = getAlertForStack(options.stack);
                        if ($alert != null) {
                            setupAlertTimeout($alert.html(options.text), options);
                        } else {
                            $alert = $('<div class="alert"/>').html(options.text);
                            $alert.data('alert-stack', options.stack);

                            // Figure out what treatment we should use for this message
                            if (options.type == 'success')
                                $alert.addClass('alert-success');
                            else if (options.type == 'error' || options.type == 'danger')
                                $alert.addClass('alert-danger');
                            else if (options.type == 'warning')
                                $alert.addClass('alert-warning');
                            else
                                $alert.addClass('alert-info');

                            if (options.dismiss) {
                                $alert.prepend('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                                $alert.on('close.bs.alert', function () {
                                    removeAlert($alert);
                                });
                            }

                            $alert.hide().prependTo($alertContainer).fadeIn();
                            registerAlert($alert);

                            setupAlertTimeout($alert, options);
                        }
                    }
                };

                function registerAlert($alert) {
                    alerts.push($alert);
                }

                function getAlertForStack(stack) {
                    if (stack == null) return null;
                    for (var i = 0; i < alerts.length; i++) {
                        if (alerts[i].data('alert-stack') == stack) {
                            return alerts[i];
                        }
                    }
                }

                function setupAlertTimeout($alert, options) {
                    var tid = $alert.data('alert-timeout');
                    if (tid) { clearTimeout(tid); }

                    tid = setTimeout(function () {
                        unregisterAlert($alert);
                        $alert.fadeTo(250, 0).animate({ height: 0, 'margin-bottom': 0 }, function () {
                            removeAlert($alert);
                        });
                    }, options.duration);

                    $alert.data('alert-timeout', tid);
                }

                function unregisterAlert($alert) {
                    for (var i = 0; i < alerts.length; i++) {
                        if (alerts[i] == $alert) {                          
                            alerts.splice(i, 1);
                            return true;
                        }
                    }

                    return false;
                }

                function removeAlert($alert) {
                    unregisterAlert($alert);

                    var tid = $alert.data('alert-timeout');
                    if (typeof tid != 'undefined')
                        clearTimeout(tid)

                    $alert.off();
                    $alert.find('*').off();

                    $alert.remove();
                }
            }(),
            confirm: function(options) {
                options = $.extend({
                    cancelable: true,
                    title: 'Title',
                    message: 'Message'
                }, options);

                if (options.title) options.header = options.title;
                if (options.message) options.body = options.message;
                if (options.callback) options.confirmationCallback = options.callback;

                options.type = 'confirmation';

                uifactory.modal.show(options);
            },
            create: {
                abbr: function(settings) {
                    settings = settings || {};

                    if (typeof settings === 'string') settings = { abbr: settings };
                    if (arguments.length > 1 && typeof arguments[1] === 'string') settings.title = arguments[1];

                    var abbr = document.createElement('abbr');
                    abbr.innerText = settings.abbr;

                    if (settings.title) abbr.title = settings.title;

                    return abbr;
                },
                button: function (options) {
                    options = $.extend({
                        text: 'New Button',
                        disabled: false,
                        content: undefined,
                        attr: {
                            type: 'button'
                        },
                        'class': 'btn-default',
                        states: undefined,
                        options: null,
                        rightMenu: false
                    }, options);

                    // Create our base button object
                    var $button = $('<button/>')
                        .attr(options.attr);
                    
                    if (options['class'] !== false)
                        $button.attr('class', options['class'] + ' btn');

                    if (options.content)
                        $button.append(options.content);
                    else
                        $button.text(options.text);

                    if (options.disabled)
                        $button.addClass('disabled');

                    if (typeof options.states === "object") {
                        if ('default' in options.states === false)
                            options.states.default = options;

                        $button.on('button-state-changed', function(e, state) {
                            state = state || 'default';

                            if (state in options.states) {
                                state = options.states[state];

                                if (typeof state['class'] === 'string') $button.attr('class', state['class'] + ' btn');
                                if (typeof state['text'] === 'string') $button.text(state['text']);
                                if (state['content']) $button.empty().append(state['content']);
                                if (!!state['disabled'] !== $button.prop('disabled')) $button.prop('disabled', !!state['disabled']);
                            }
                        });
                    }

                    // Check to see if this should be a dropdown button or not
                    if (options.options != null && 'length' in options.options) {
                        // Create a group for our wrapper and setup the list object for the menu
                        var $group = $('<div class="btn-group"/>').append($button);
                        var $list = $('<ul class="dropdown-menu" role="menu"/>').appendTo($group);

                        if (options.rightMenu) $list.addClass("pull-right");

                        // Add required dropdown markup to the button object
                        $button.addClass('dropdown-toggle').attr('data-toggle', 'dropdown');

                        if (options.caret !== false)
                            $button.append(' <span class="caret"></span>');

                        var opts = options.options;
                        for (var i = opts.length - 1; i >= 0; i--) (function (o) {
                            if (o == '-')
                                $('<li class="divider"/>').prependTo($list);
                            else
                                $("<a/>", {
                                    "href": "#",
                                    "text": o.text,
                                    'html': o.html,
                                    "click": function (e) { 
                                        o.action.call(this);
                                        e.preventDefault();
                                     },
                                    "appendTo": $("<li/>").prependTo($list)
                                });
                        })(opts[i]);

                        return $group;
                    }

                    return $button;
                },
                descriptionList: function (options) {
                    var $dl = $('<dl/>', { 'class': 'dl-horizontal' });

                    // An array of properties to describe
                    var properties = options.properties;

                    // Description object to look up keys/values in
                    var description = options.description;

                    // Configuration object to lookup IDs in (optional)
                    var configuration = options.configuration || options.description;

                    // Property options:
                    //  key:    key of description to retrieve value from
                    //  format: function to execute that returns value
                    //  label:  string to label the value with
                    //  idKey:  key from configuration to retrieve ID from

                    $.each(properties, function (i, p) {
                        var $dd = $('<dd/>'),
                            value;

                        if (typeof p.format === 'function') value = p.format();
                        else if (typeof p.key === 'string') value = description[p.key];

                        if (value) {
                            if (value === 'N/A') $dd.addClass('text-muted');

                            $dd.append(value);
                            $dl.append($('<dt/>', { 'text': p.label }), $dd);

                            if (typeof p.idKey === 'string') {
                                var $id = $('<small/>', {
                                    'class': 'text-muted',
                                    'text': configuration[p.idKey],
                                    'css': {
                                    }
                                }).hide();

                                var $idToggle = $('<a/>', {
                                    'href': '#',
                                    'text': 'Show ID',
                                    'class': 'small',
                                    'click': function (e) {
                                        e.preventDefault();

                                        $id.fadeToggle()
                                    },
                                    'css': {
                                        'marginLeft': 10
                                    }
                                });

                                $dd.append($idToggle, "&nbsp;", $id);
                            }
                        }
                    });

                    return $dl;
                },
                dropdown: (function () {
                    var loadingIconClass = 'fa fa-spinner fa-spin';

                    return function (settings) {
                        // Registered to the root of the the dropdown (.rs-dropdown) as .data('dropdown')
                        // options can be passed in through settings.options or updated through the rs-dropdown-loaded event below
                        var dropdown = {
                            elements: {},

                            selectedOption: undefined,
                            selectedOptions: []
                        };

                        // Update the options using dropdown.settings.options
                        dropdown.updateOptions = function (quiet) {
                            dropdown.elements.menu.empty();

                            var hasOptions = dropdown.settings.options && dropdown.settings.options.length > 0,
                                numberOfOptions = hasOptions ? dropdown.settings.options.length : 0;

                            // If we have options, set up the menu with them,
                            // otherwise go into loading mode
                            if (hasOptions) {
                                // We'll save a reference to the first link we generate
                                // so we don't have to go back and find it after creating it
                                var $firstOption;
                                var $selectedOption;

                                // We'll save a reference to the links we generate because it's faster to add them
                                // all to the DOM at once
                                var rows = [];

                                // Loop through the options and create them
                                for (var i = 0; i < numberOfOptions; i++) {
                                    var option = dropdown.settings.options[i];

                                    // Options should usually look like {label: 'Option', value: 'server-value'};
                                    // Create a row and add it to the menu
                                    if (typeof option === 'object') {
                                        var $li = $('<li/>'),
                                            $a = $('<a/>', {
                                                'html': option.label,
                                                'href': '#',
                                                'data-value': option.value,
                                                'appendTo': $li
                                            });

                                        if (option.append) $a.append(option.append);

                                        $a.data('rs-option', option);

                                        var isSelected = false;

                                        
                                        if (dropdown.settings.multiple) {
                                            if (selectedValueFilter && selectedValueFilter.call(dropdown, option.value, dropdown.settings.value)) {
                                                isSelected = true;
                                            } else if (typeof dropdown.settings.value !== 'undefined') {
                                                for (var j = 0; j < dropdown.settings.value.length; j++) {
                                                    if (dropdown.settings.value[j] == option.value) {
                                                        isSelected = true;
                                                        break;
                                                    }
                                                }
                                            }                                            
                                        } else if (dropdown.settings.value == option.value || (selectedValueFilter && selectedValueFilter.call(dropdown, option.value, dropdown.settings.value))) {
                                            isSelected = true;
                                        }

                                        if (isSelected) {
                                            if (dropdown.settings.multiple) $selectedOption = $selectedOption ? $selectedOption.add($a) : $a;
                                            else $selectedOption = $a;
                                        }

                                        if (!$firstOption) $firstOption = $a;

                                        rows.push($li);
                                    } else if (option === '-') {
                                        rows.push($('<li class="divider"/>'));
                                    }
                                }

                                dropdown.elements.menu.append(rows);

                                if ($selectedOption && $selectedOption.length > 1) $selectedOption.each(function () { dropdown.setSelectedOption($(this), !quiet); });
                                else dropdown.setSelectedOption($selectedOption || $firstOption, !quiet);
                            } else {
                                // We have no options, go into loading mode

                                // If the selectedOption wasn't falsish before trigger a change
                                if (dropdown.selectedOption && !quiet)
                                    dropdown.elements.group.trigger("change");

                                dropdown.selectedOption = undefined;
                                dropdown.elements.buttonText.text("Loading...");
                            }


                            dropdown.elements.group.toggleClass('loading-options', !hasOptions);

                            dropdown.elements.icon
                                .toggleClass('caret', !!hasOptions)
                                .toggleClass(loadingIconClass, !hasOptions);
                        };

                        // Set the selected option using an A element in a dropdown row;
                        // if $a is a value, a matching A element will selected
                        dropdown.setSelectedOption = function ($a, triggerChange) {
                            if ($a instanceof $ === false) {
                                var $allAs = dropdown.elements.menu.find('a');
                                var findValue = dropdown.settings.value = $a;
                                var valueMatchesOption = function ($option, v) {
                                    var val = $option.data('value');

                                    if ((val === 'true' || val === true) && (v === true || v === 'true')) return true;
                                    if ((val === 'false' || val === false) && (v === false || v === 'false')) return true;

                                    return val == v;
                                };

                                if (dropdown.settings.multiple) findValue = $.makeArray(findValue);

                                var $matchedA = $allAs.map(function () {
                                    var optionMatches = false;
                                    var $option = $(this);

                                    if (dropdown.settings.multiple)
                                        $.each(findValue, function (i, v) {
                                            if (selectedValueFilter) {
                                                if (!selectedValueFilter.call(dropdown, $option.data('value'), v)) return;

                                                optionMatches = true;
                                                return false; // break
                                            }
                                            else if (valueMatchesOption($option, v)) {
                                                optionMatches = true;
                                                return false; // break
                                            }
                                        });
                                    else {
                                        optionMatches = selectedValueFilter ? 
                                            selectedValueFilter.call(dropdown, $option.data('value'), findValue) :
                                            valueMatchesOption($option, findValue);
                                    }

                                    return optionMatches ? this : undefined;
                                });

                                if (!dropdown.settings.multiple) $matchedA = $matchedA.first();

                                // Match false-ish values if search value is false-ish and no exact matches were found
                                if (!$matchedA.length && typeof findValue === "undefined")
                                    $matchedA = $allAs.first();

                                if ($matchedA.length) dropdown.setSelectedOption($matchedA, triggerChange);

                                return undefined;
                            }

                            // If there are multiple options selected by this call,
                            // call this function once for each of them
                            if (dropdown.settings.multiple && $a.length > 1) {
                                $a.each(function () {
                                    dropdown.setSelectedOption($(this), false);
                                });

                                // Trigger the change event
                                if (typeof triggerChange === 'undefined' || triggerChange)
                                    dropdown.elements.group.trigger("change", $a.map(function () {
                                        return $.data(this, 'rs-option');
                                    }).get());

                                return;
                            }

                            var option = $a && $a.data('rs-option');

                            // If a row wasn't passed in or we don't have an option, do nothing
                            if (!option) return false;

                            if (!dropdown.settings.multiple || dropdown.settings.clearWhenFalse && option.value === false) {
                                // If the option has changed
                                if (option !== dropdown.selectedOption) {
                                    // Update the selected option
                                    dropdown.selectedOption = option;
                                    dropdown.selectedOptions = [option];

                                    // Trigger the change event
                                    if (typeof triggerChange === 'undefined' || triggerChange)
                                        dropdown.elements.group.trigger("change", option);

                                    // Update the active states
                                    dropdown.elements.menu.children().removeClass('active');
                                    $a.closest('li').addClass('active');

                                    // Update the button's text to reflect the selected label
                                    dropdown.elements.buttonText.text(option.label);
                                }
                            } else {
                                var optionIndex = $.inArray(option, dropdown.selectedOptions);
                                if (optionIndex > -1) dropdown.selectedOptions.splice(optionIndex, 1);
                                else dropdown.selectedOptions.push(option);
                                dropdown.selectedOption = undefined;

                                if (dropdown.settings.clearWhenFalse) {
                                    dropdown.selectedOptions = $.map(dropdown.selectedOptions, function (o) { return o.value === false ? undefined : o; });

                                    dropdown.elements.menu.find('a').each(function() {
                                        var $this = $(this);
                                        var v = $this.data('value');
                                 
                                        if (v === false)  {
                                            var $li = $this.closest('li');

                                            if (dropdown.selectedOptions.length) {
                                                $li.removeClass('active');
                                            } else {
                                                $li.addClass('active');
                                                dropdown.elements.buttonText.text($li.text());
                                            }
                                            return false;
                                        }
                                    });
                                }
                                
                                // Update the active states
                                $a.closest('li').toggleClass('active', optionIndex < 0);

                                if (dropdown.selectedOptions.length) {
                                    // Update the button's text to reflect the selected labels
                                    dropdown.elements.buttonText.text($.map(dropdown.selectedOptions, function(o) { return o.label; }).join(', '));
                                }

                                // Trigger the change event
                                if (typeof triggerChange === 'undefined' || triggerChange)
                                    dropdown.elements.group.trigger("change", option);
                            }
                        };

                        // Updates the selected option without triggering a change
                        dropdown.updateSelectedOption = function ($a) {
                            dropdown.setSelectedOption($a, false);
                        };

                        // Update the selected option using a search string
                        dropdown.search = function (search, triggerChange) {
                            var foundMatch = false;

                            dropdown.elements.menu.find('a').each(function () {
                                var $a = $(this);
                                if ($a.text().toLowerCase() === search.toLowerCase()) {
                                    dropdown.setSelectedOption($a, triggerChange);
                                    foundMatch = true;
                                    return false;
                                }

                                return undefined;
                            });

                            return foundMatch;
                        };

                        /**
                         * Returns a list of all selectable options in the dropdown menu
                         * @returns {Array}
                         */
                        dropdown.getOptions = function() {
                            return dropdown.elements.menu.find('a').map(function() {
                                var $a = $(this),
                                    value = $a.data('value');

                                if (typeof value !== "undefined")
                                    return {
                                        label: $a.text(),
                                        value: value
                                    };
                            }).get();
                        };

                        // Default settings
                        dropdown.settings = $.extend({
                            'buttonClass': 'btn-default', // A class to add to the dropdown button,
                            'buttonID': false,
                            'options': false,             // Options to initialize the dropdown with
                            'root': false,                // If specified, use this as the root object
                                                          // (use this when the DOM is already generated)
                            'right': false
                        }, settings);

                        var hasOptions = dropdown.settings.options && dropdown.settings.options.length, // Options are defined and not just an empty array
                            firstOption = hasOptions ? dropdown.settings.options[0] : undefined;        // Reference to the firstOption if there is one, or undefined

                        
                        var selectedValueFilter = dropdown.settings.selectedValueFilter;

                        if (typeof selectedValueFilter !== 'function') selectedValueFilter = undefined;

                        /// Build the DOM elements ///
                        if (!dropdown.settings.root) {
                            // Base container object
                            dropdown.elements.group = $('<div/>', { 'class': 'rs-dropdown btn-group' });
                            dropdown.settings.root = dropdown.elements.group;

                            // Button that triggers the dropdown menu
                            dropdown.elements.button = $('<button/>', {
                                'class': 'btn dropdown-toggle ' + dropdown.settings.buttonClass,
                                'data-toggle': 'dropdown',
                                'appendTo': dropdown.elements.group,
                                'type': 'button'
                            });

                            if (dropdown.settings.buttonID)
                                dropdown.elements.button.attr('id', dropdown.settings.buttonID);

                            // Text inside of the dropdown button
                            dropdown.elements.buttonText = $('<span/>', {
                                'text': hasOptions ? firstOption.label : "Loading...",
                                'appendTo': dropdown.elements.button
                            });

                            // Space in-between text & caret/loading-icon
                            dropdown.elements.button.append(' ');

                            // Icon after the text (either a caret or the loading-icon)
                            dropdown.elements.icon = $('<i/>', {
                                'class': hasOptions ? 'caret' : loadingIconClass,
                                'appendTo': dropdown.elements.button
                            });

                            // The dropdown menu
                            dropdown.elements.menu = $('<ul/>', {
                                'class': 'dropdown-menu',
                                'role': 'menu',
                                'appendTo': dropdown.elements.group
                            });

                            if (dropdown.settings.right) dropdown.elements.menu.addClass('dropdown-menu-right');
                        } else {
                            // We were passed a reference to the DOM element, find all the references
                            // we need to continue working
                            dropdown.elements.group = dropdown.settings.root;
                            dropdown.elements.button = dropdown.elements.group.children('.dropdown-toggle');
                            dropdown.elements.buttonText = dropdown.elements.button.children('span');
                            dropdown.elements.icon = dropdown.elements.button.children('i');
                            dropdown.elements.menu = dropdown.elements.group.children('.dropdown-menu');
                        }

                        /// Set the options ///
                        // If there's options run updateOptions, either set the loading class
                        if (hasOptions) dropdown.updateOptions();
                        else dropdown.elements.group.addClass('loading-options');

                        // Set the dropdown data object
                        dropdown.elements.group.data('rs-dropdown', dropdown);

                        // Set up the rs-dropdown-loaded event; When rs-dropdown-loaded is triggered
                        // options are updated (passed as the first argument after the event), and updateOptions is called
                        dropdown.elements.group.on('rs-dropdown-loaded', function (e, options, quiet) {
                            dropdown.settings.options = options;
                            dropdown.updateOptions(quiet);
                        });

                        // Set up keyboard handler
                        (function () {
                            var searchTimer = false,
                                search = "",
                                onSearchTimerElapsed = function () {
                                    search = "";
                                    searchTimer = false;
                                };
                            dropdown.elements.group.on('keypress', function (e) {
                                var char = String.fromCharCode(e.which);

                                search += char;

                                if (searchTimer) clearTimeout(searchTimer);
                                setTimeout(onSearchTimerElapsed, 1000);

                                dropdown.search(search);
                            });
                        })();

                        // Setup disabling the button when the menu is loading
                        dropdown.elements.button.on('click', function (e) {
                            if (dropdown.elements.group.hasClass('loading-options'))
                                return false;
                        });

                        // Setup seleting options
                        dropdown.elements.menu.on('click', 'a', function (e) {
                            dropdown.setSelectedOption($(this));
                            e.preventDefault();
                        });

                        // Return the base jQuery object
                        return dropdown.elements.group;
                    };
                })(),
                icon: function (options) {
                    if (typeof options === 'string') options = {
                        name: options
                    };

                    options = $.extend({
                        type: 'fa',
                        name: 'heartbeat',
                        stack: undefined,
                        size: undefined,
                        inverse: false
                    }, options);

                    var icon = document.createElement('i');

                    if (options.name === 'caret') {
                        icon.className = 'caret';
                        return $(icon);
                    }

                    var nameOverrides = {
                        'prev': 'chevron-left',
                        'next': 'chevron-right'
                    };

                    if (options.name in nameOverrides)
                        options.name = nameOverrides[options.name];

                    var nameTokens = options.name.split(' '), token, i;
                    if (nameTokens.length > 1) {
                        for (i = 0; i < nameTokens.length; i++) {
                            token = nameTokens[i].substr(options.type.length + 1);
                            if (token) {
                                options.name = token;
                                break;
                            }
                        }
                    }

                    icon.className = options.type + ' ' + options.type + '-' + options.name;

                    if (options.stack) {
                        var stack = document.createElement('span');

                        icon.className += ' ' + options.type + '-stack-1x';
                        stack.className = options.type + '-stack';
                        stack.appendChild(icon);

                        if (typeof options.stack === 'string') options.stack = $.extend({}, options, {
                            name: options.stack,
                            stack: false,
                            size: false
                        });


                        uifactory.create.icon(options.stack).addClass(options.type + '-stack-2x').appendTo(stack);
                        icon = stack;
                    }

                    if (options.spin)    icon.className += ' ' + options.type + '-spin';
                    if (options.size)    icon.className += ' ' + options.type + '-' + options.size;
                    if (options.inverse) icon.className += ' ' + options.type + '-inverse';

                    return $(icon);
                },
                filterButton: function(settings) {
                    settings = $.extend({ menu: undefined }, settings);

                    var $count;
                    var $filterButton = $('<button/>', {
                        'type': 'button',
                        'class': 'btn btn-default dropdown-toggle',
                        'data-toggle': 'dropdown',
                        'aria-haspopup': 'true',
                        'aria-expanded': 'false',
                        'append': [
                            uifactory.create.icon('filter'),
                            ' Filters ',
                            $count = $('<strong/>', { 'class': 'small' }),
                            uifactory.create.icon('caret')
                        ]
                    });

                    var skipNextHide = false;
                    var $group = $('<div/>', {
                            'class': 'btn-group filter-dropdown',
                            'append': $filterButton
                        })
                        .on('hide.bs.dropdown', function(e) {
                            // If we have identified that we need to stop the next hide, do so
                            if (skipNextHide) {
                                e.preventDefault();
                                skipNextHide = false;
                            }
                        })
                        .on('show.bs.dropdown', '.btn-group', function (e) {
                            var $button = $(this),
                                $menu = $button.find('.dropdown-menu');

                            var position = $button.offset(),
                                size = {
                                    width: $button.width(),
                                    height: $button.height()
                                };

                            $menu.css({
                                position: 'fixed',
                                left: position.left,
                                top: position.top - (window.pageYOffset || document.scrollTop || 0) + size.height,
                                width: size.width
                            });
                        })
                        .on('fp:rs:show', '.form-group', function (e) {
                            var $input = $(e.target),
                                $fp = $(this).find('.fp-container');

                            var position = $input.offset(),
                                size = {
                                    width: $input.outerWidth(),
                                    height: $input.outerHeight()
                                };

                            $fp.css({
                                position: 'fixed',
                                left: position.left + size.width / 2,
                                top: position.top - (window.pageYOffset || document.scrollTop || 0) + size.height
                            });
                        })
                        .on('click', function(e) {
                            var $target = $(e.target);

                            // Grab all clicks bubbled up from the children
                            // If the click was on the button then go ahead with it
                            if ($target.is($filterButton) || $target.closest($filterButton).length) return;

                            // Otherwise the click will trigger the parent menu to close so we need to stop it
                            skipNextHide = true;
                        });

                    $(settings.menu)
                        .appendTo($group)
                        .addClass('dropdown-menu')
                        .on('submit reset', function () {
                            $group.removeClass('open');
                        });

                    $group.on('filters-updated', function (e, count) {
                        $count.text(!count ? '' : '(' + count + ') ');

                        $filterButton
                            .toggleClass('btn-default', !count)
                            .toggleClass('btn-primary', count > 0);
                    });

                    return $group;
                }
            }
        };
    }();
})(jQuery);