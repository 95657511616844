(function () {
	function createForm(type, settings) {
		var url;

		settings = $.extend({
			data: {},
			filters: {},
			submitSuccessCallback: undefined,
			hideParentOrganization: false,
			hideLogoManagement: false,
			onCancel: undefined
		}, settings);

		var data = settings.data;
		var baseData = settings.filters;

		switch (type) {
			case "edit":
				url = 'Organization/Update';
				break;
			case "create":
				url = 'Organization/CreateOrganization';
				break;
		}

		if (typeof url !== 'string') return undefined;

		var fields = [
			{
				type: 'text',
				name: 'OrganizationName',
				label: 'Name',
				value: data.OrganizationName,
				required: true
			},
			{
				type: 'email',
				name: 'OrganizationContactEmail',
				label: 'Contact Email',
				value: data.OrganizationContactEmail,
				required: true
			}
		];

		if (!settings.hideParentOrganization) {
			if (type === 'edit') fields.push({
				type: 'static',
				for: 'edit',
				label: 'Parent Organization',

				ridestyler: {
					action: 'Organization/GetOrganizations',
					data: {
						OrganizationID: data.Organization_OrganizationID
					},
					responseKey: 'Organizations',
					textKey: 'OrganizationName'
				}
			});

			if (type === 'create') fields.push({
				type: 'search',
				for: 'create',
				label: 'Parent Organization',
				name: 'Organization_OrganizationID',

				url: 'Organization/GetOrganizations',
				responseKey: 'Organizations',
				requestData: {
					OrganizationID: rstools.auth.user.getOrganizationID(),
					IncludeNetwork: true
				},
				textKey: 'OrganizationName',
				idColumnName: 'OrganizationID',
				value: data.Organization_OrganizationID,

				resultMap: function (organization) {
					return {
						id: organization.OrganizationID,
						name: organization.OrganizationName
					};
				},
				applySearch: function (search, request) {
					request.Search = '[OrganizationName[Like]%' + search + '%]';
				}
			});
		}

		if (type === 'edit' && !settings.hideLogoManagement) {
			fields.push({
				type: 'container',
				group: 'Logos',
				noLabel: true,
				value: $('<div/>', {
					'class': 'row',
					'append': [
						$('<div/>', {
							'class': 'col-sm-6',
							'append': rstools.organization.utils.createManageLogoThumbnail({
								organizationID: baseData.OrganizationID,
								title: 'Light Logo',
								assetKey: 'app_logo_small_light', 
								backgroundColor: '#35393a',
								color: 'white',
								recommendedResolution: 'Recommended: 165x60',
								usedIn: [
									'The navigation bar of the gallery interface',
									'The showcase interface'
								]
							})
						}),
						$('<div/>', {
							'class': 'col-sm-6',
							'append': rstools.organization.utils.createManageLogoThumbnail({
								organizationID: baseData.OrganizationID,
								title: 'Dark Logo',
								assetKey: 'app_logo_small_dark',
								color: '#333',
								recommendedResolution: 'Max: 530x75',
								usedIn: [
									'The intro modal of the gallery interface'
								]
							})
						})
					]
				})
			});
		}

		var settingsFields = rstools.organization.settings.settingsFields;
		
		if (type === 'edit') {
			for (var i = 0; i < settingsFields.length; i++) {
				var settingName = 'Settings.' + settingsFields[i].key;
				fields.push({
					for: 'edit',
					type: 'checkbox',
					label: settingsFields[i].label,
					name: settingName,
					group: 'Settings',
					checked: !!data[settingsFields[i].key]
				});

				if (settingName in baseData === false)
					baseData[settingName] = false;
			}
		}

		fields = $.map(fields, function (field) {
			if (field.for && field.for !== type) return undefined;

			return field;
		});

		return uifactory.create.form({
			url: url,
			submitErrorMessage: 'There was a problem ' + (type === 'create' ? 'creat' : 'edit') + 'ing the organization: {Message}.',

			submitSuccessCallback: function () {
				var verbPast = (type === 'create' ? 'created.' : 'edited.');

				uifactory.alert.show({
					text: "The organization has been " + verbPast
				});

				if (typeof settings.submitSuccessCallback === 'function') settings.submitSuccessCallback();
			},

			fields: fields,
			baseData: baseData,

			actions: [
				{
					label: type === 'create' ? 'Create' : 'Save',
					icon: type === 'create' ? 'fa fa-plus' : 'fa fa-floppy-o',
					buttonClass: type === 'create' ? 'btn btn-success' : 'btn btn-primary',
					action: 'submit'
				},

				typeof settings.onCancel === 'function' ? {
					label: 'Cancel',
					action: settings.onCancel
				} : undefined
			]
		});
	}

	rstools.organization = {
		edit: function (organizationID) {
			if (!rstools.user.verifyRole(rstools.roles.ORGANIZATION_ADMIN)) return;

			var modal = uifactory.modal.create({
				cancelable: true, footer: false,
				loadData: {
					organization: {
						action: 'Organization/GetOrganizations',
						data: { OrganizationID: organizationID },
						responseKey: 'Organizations',
						first: true,
						required: true
					},
					settings: {
						action: 'Organization/GetSettings',
						data: {
							OrganizationID: organizationID,
							Keys: $.map(rstools.organization.settings.settingsFields, function (settingField) {
								return settingField.key;
							})
						},
						responseKey: 'Settings',
						required: true
					}
				},
				headerFormat: function (data) {
					return "<strong>Edit Organization:</strong> " + data.organization.OrganizationName;
				},
				bodyFormat: function (data) {
					var modal = this;

					var settings = data.settings;

					for (var k in settings)
						if (settings.hasOwnProperty(k))
							settings[k] = rstools.utils.parse.bool(settings[k]);

					var formData = $.extend(settings, data.organization);

					return createForm('edit', {
						data: formData,
						filters: {
							OrganizationID: organizationID
						},
						submitSuccessCallback: function () {
							rstools.events.dataChanged('organization', 'edit');
							modal.hide();
						},
						onCancel: function () {
							modal.hide();
						}
					});
				},
				onFail: function (message) {
					if (!message) {
						if (!this.data.organization) message = 'The organization could not be found.';
					}

					var baseMessage = 'There was a problem retrieving the organization';

					uifactory.alert.show({
						type: 'error',
						text: message ? baseMessage + ': ' + message : baseMessage
					});
				}
			});

			modal.show();
		},

		create: function (organization) {
			if (!rstools.user.verifyRole(rstools.roles.ORGANIZATION_ADMIN)) return;

			organization = organization || {
				Organization_OrganizationID: rstools.auth.user.getActiveOrganizationID()
			};

			var modal = uifactory.modal.create({
				cancelable: true, footer: false,
				header: 'Create an Organization',
				body: createForm('create', {
					data: organization,
					submitSuccessCallback: function () {
						rstools.events.dataChanged('organization', 'create');
						modal.hide();
					},
					onCancel: function () {
						modal.hide();
					}
				})
			});

			modal.show();
		},

		manageLogos: function (organizationID) {
			if (!rstools.user.verifyRole(rstools.roles.ORGANIZATION_ADMIN)) return;

			var $row = $('<div/>', { 'class': 'row' });
			var $columns = {
				left: $('<div/>', { 'class': 'col-sm-6' }).appendTo($row),
				right: $('<div/>', { 'class': 'col-sm-6' }).appendTo($row)
			};

			var modal = uifactory.modal.create({
				cancelable: true, footer: false,
				loadData: {
					organization: {
						action: 'Organization/GetOrganizations',
						data: { OrganizationID: organizationID },
						responseKey: 'Organizations',
						first: true,
						required: true
					}
				},
				headerFormat: function (data) {
					return [
						$('<strong/>', {text: 'Manage Logos: '}),
						data.organization.OrganizationName
					];
				},
				body: $row
			});

			var createThumbnail = rstools.organization.utils.createManageLogoThumbnail;

			$columns.left.append(createThumbnail({
				title: 'Light Logo',
				organizationID: organizationID,
				assetKey: 'app_logo_small_light', 
				backgroundColor: '#35393a',
				color: 'white',
				recommendedResolution: 'Recommended: 165x60',
				usedIn: [
					'The navigation bar of the gallery interface',
					'The showcase interface'
				]
			}));
			$columns.right.append(createThumbnail({
				title: 'Dark Logo',
				organizationID: organizationID,
				assetKey: 'app_logo_small_dark',
				color: '#333',
				recommendedResolution: 'Max: 530x75',
				usedIn: [
					'The intro modal of the gallery interface'
				]
			}));

			modal.show();
		},

		settings: {
			settingsFields: [
				{
					label: 'Allow Sharing Vehicles',
					key: 'ENABLE_SHARING'
				}
			]
		},

		columns: {
			createOrganizationPermissionIDRenderer: (function () {
				var renderOrganizationPermissionColumn = function ($tds, organizationPermissionIDs) {
                	$tds.empty().addClass('light loading loading-small');

					ridestyler.ajax.send({
						action: 'Organization/GetOrganizationPermissions',
	                    data: {
	                        OrganizationPermissions: organizationPermissionIDs.unique()
	                    },
	                    callback: function (response) {
	                    	if (response.Success) {
	                    		var organizationPermissionLookup = {};

	                    		$.each(response.Results, function (i, organizationPermission) {
	                    			organizationPermissionLookup[organizationPermission.OrganizationPermissionID] = organizationPermission.OrganizationPermissionName;
	                    		});

	                    		for (var i = organizationPermissionIDs.length - 1; i >= 0; i--) (function ($td, organizationPermissionID) {
	                    			var organizationPermissionName = organizationPermissionLookup[organizationPermissionID];

	                    			organizationPermissionName ?
	                    				$td.text(organizationPermissionName) : $td.html('<span class="text-muted">-</span>');
	                    		})($tds.eq(i), organizationPermissionIDs[i]);
	                    	} else {
	                            $tds.text('Error');
	                            console.error("Error retrieving OrganizationPermissions: ", response);
	                        }

	                        $tds.removeClass('loading');
	                    }
					});
				};

				return function (organizationPermissionIDKey) {
					return function ($tds, data) {
						if (!data || !data.length) return;

						if (!organizationPermissionIDKey) {
							var row = data[0];

							for (var k in row) if (row.hasOwnProperty(k) && k.substr(-24) === 'OrganizationPermissionID') {
								organizationPermissionIDKey = k;
								break;
							}

							if (!organizationPermissionIDKey)
								return console.error('Missing organizationPermissionIDKey and one could not be picked automatically.', row);
						}

						renderOrganizationPermissionColumn($tds, $.map(data, function (row) {
							return row[organizationPermissionIDKey];
						}));
					};
				};
			})()
		},

		utils: {
			createForm: createForm,
			createManageLogoThumbnail: function (thumbnailSettings) {
				var thumbnail = document.createElement('div');
					thumbnail.className = 'thumbnail';

				var image = document.createElement('img'); 
					image.className = 'img-original-size';
					image.onload = function (e) {
						$(this).show().siblings().remove();
					};
					image.onerror = function (e) {
						$(this).hide().after($('<span/>', {text: 'No Logo'}));
					}
					image.src = ridestyler.ajax.url('Organization/Image', {
						AssetKey: thumbnailSettings.assetKey,
						Organization: thumbnailSettings.organizationID
					});

				var a = document.createElement('a');
					a.className = 'text-center';
					a.appendChild(image);

				if ('backgroundColor' in thumbnailSettings) a.style.backgroundColor = thumbnailSettings.backgroundColor;
				if ('color' in thumbnailSettings) a.style.color = thumbnailSettings.color;

				var caption = document.createElement('div');
					caption.className = 'caption';

				var captionTitle = document.createElement('h4');

				captionTitle.appendChild(document.createTextNode(thumbnailSettings.title));

				caption.appendChild(captionTitle);

				var usedInLabel = document.createElement('div');
					usedInLabel.appendChild(document.createTextNode('Used In:'));
					usedInLabel.style.margin = '10px 0 5px';

				var usedIn = document.createElement('ul');

				for (var i = 0; i < thumbnailSettings.usedIn.length; i++) {
					var li = document.createElement('li');
						li.appendChild(document.createTextNode(thumbnailSettings.usedIn[i]));

					usedIn.appendChild(li);
				}

				caption.appendChild(usedInLabel)
				caption.appendChild(usedIn);

				var recommendedResolution = document.createElement('p');
					recommendedResolution.className = 'text-muted pull-left';
					recommendedResolution.appendChild(document.createTextNode(thumbnailSettings.recommendedResolution));
					recommendedResolution.style.marginTop = '7px';

				var buttonContainer = document.createElement('div');
					buttonContainer.className = 'text-right';

				buttonContainer.appendChild(uifactory.create.splitButtonDropdown({
					action: {
						title: uifactory.create.icon('picture-o'),
						callback: function () {
							var $buttonGroup = $(this).closest('.btn-group');

							rstools.resource.list({
								mode: 'select',
								organization: thumbnailSettings.organizationID,
								filters:{
									AssetType: rstools.constants.ResourceAssetType.OrganizationImage
								},
								disableFilters: true,
								onSelect: function (resource) {
									uifactory.loading.buttonGroup.setLoading($buttonGroup, true);

									ridestyler.ajax.send({
										action: 'Organization/LinkResource',
										data: {
											Key: thumbnailSettings.assetKey,
											OrganizationID: thumbnailSettings.organizationID,
											ResourceID: resource.ResourceID
										},
										callback: function (response) {
											uifactory.loading.buttonGroup.setLoading($buttonGroup, false);

											if (response.Success) {
												image.src = image.src;

												var parent = image.parentElement,
													siblings = parent.childNodes;

												for (var i = 0; i < siblings.length; i++)
													if (siblings[i] !== image) 
														parent.removeChild(siblings[i]);
											} else
												rstools.api.utils.showResponseError("There was a problem linking the logo", response);
										}
									})
								}
							})
						}
					},
					otherActions: [
						{
							title: [uifactory.create.icon('times'), ' Remove Logo'],
							callback: function () {
								var $btnGroup = $(this).closest('.btn-group');

								uifactory.loading.buttonGroup.setLoading($btnGroup, true);

								ridestyler.ajax.send({
									action: 'Organization/UnlinkResource',
									data: {
										Key: thumbnailSettings.assetKey,
										OrganizationID: thumbnailSettings.organizationID
									},
									callback: function (response) {
										uifactory.loading.buttonGroup.setLoading($btnGroup, false);

										if (response.Success) {
											image.style.display = 'none';

											var noLogo = document.createElement('span');
												noLogo.appendChild(document.createTextNode("No Logo"));

											image.parentElement.appendChild(noLogo);
										} else
											rstools.api.utils.showResponseError("There was a problem unlinking the logo", response);
									}
								})
							}
						}
					],
					right: true
				}));

				caption.appendChild(recommendedResolution);
				caption.appendChild(buttonContainer);

				thumbnail.appendChild(a);
				thumbnail.appendChild(caption);

				return thumbnail;
			}
		}
	};
})();