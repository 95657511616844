(function($) {
    var events = rstools.events = $({});


    function createEventProxyFunction (eventName) {
        return function (callback, $elementBound) {
            if (typeof callback === 'function') {
                if ($elementBound instanceof $) $elementBound.on('remove', $.proxy(events, 'off', eventName, callback));
                return events.on(eventName, callback);
            }

            return events.trigger.call(events, eventName, arguments);
        };
    }

    rstools.events.dataChanged = createEventProxyFunction('rs-data-changed');
})(jQuery);