(function ($) {
    rter.mapController('app.organization.pricing', function (context) {
        var grid = null;
        var $filterForm, $filterButton;

        var filters = {};

        var organizationID;

        return {
            viewWillLoad: function(ctx) {
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

                organizationID = rstools.auth.user.getActiveOrganizationID();
            },
            viewCreated: function ($view) {
                $filterForm = uifactory.create.filterForm({
                    filters: filters,
                    fields: [
                        {
                            name: 'Search',
                            type: 'text-search',
                            label: 'Search',
                            required: false
                        },
                        {
                            label: 'Organization Permission',
                            name: 'OrganizationPermission',
                            type: 'search',
                            group: 'Organization',
                            required: false,
                            
                            url: 'Organization/GetOrganizationPermissions',
                            responseKey: 'Results',
                            resultMap: function(organizationPermission) {
                                return {
                                    id: organizationPermission.OrganizationPermissionID,
                                    name: organizationPermission.OrganizationPermissionName
                                };
                            }
                        },
                        {
                            name: 'Organization',
                            type: 'checkbox',
                            label: 'For Any Organization',
                            group: 'Organization',
                            requiredRoles: rstools.roles.SYSTEM_ADMIN,
                            required: false
                        },
                        {
                            label: 'Include Archived',
                            required: false,
                            group: 'Other',
                            name: 'IncludeArchived',
                            type: 'checkbox',
                            requiredRoles: [rstools.roles.SYSTEM_ADMIN]
                        }
                    ],
                    onSubmit: filtersUpdated,
                    onReset: filtersUpdated
                });

                // #region Create the datagrid
                grid =
                    datagrid.create({
                        container: $view.find('div.data-wrapper'),
                        allowShowAll: true,

						headerActions: [
							rstools.shared.datagrid.headerActions.manageColumns
						],
						getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,

                        fields: [
                            { name: 'PricingGroupName', caption: 'Name' },
                            { 
                                name: 'PricingGroup_OrganizationPermissionID',
                                caption: 'Permission Group',
                                columnRenderer: rstools.organization.columns.createOrganizationPermissionIDRenderer()
                            },
                            {
                                name: 'TireFitmentCount',
                                caption: '# of Tires',
                                format: function (pricingGroup) {
                                    var tireFitmentCount = pricingGroup.TireFitmentCount;

                                    if (!tireFitmentCount) return tireFitmentCount;

                                    var modelsLink = document.createElement("a");
                                        modelsLink.href = '#!/app/tire/pricing/model?' + rter.utils.toParamString({
                                            filters: {
                                                PricingGroupID: pricingGroup.PricingGroupID
                                            }
                                        });
                                        modelsLink.appendChild(document.createTextNode(tireFitmentCount));
                                        modelsLink.title = 'View Model Pricing';

                                    return modelsLink;
                                }
                            },
                            {
                                name: 'WheelFitmentCount',
                                caption: '# of Wheels',
                                format: function (pricingGroup) {
                                    var wheelFitmentCount = pricingGroup.WheelFitmentCount;

                                    if (!wheelFitmentCount) return wheelFitmentCount;

                                    var modelsLink = document.createElement("a");
                                        modelsLink.href = '#!/app/wheel/pricing/model?' + rter.utils.toParamString({
                                            filters: {
                                                PricingGroupID: pricingGroup.PricingGroupID
                                            }
                                        });
                                        modelsLink.appendChild(document.createTextNode(wheelFitmentCount));
                                        modelsLink.title = 'View Model Pricing';

                                    return modelsLink;
                                }
                            },
                            { name: 'actions', caption: '', width: 115, sort: false }
                        ],
                        rowCallback: function ($tr, pricingGroup) {
                            if (pricingGroup.PricingGroupArchived) $tr.addClass('archived-data-object');
                        },
                        actions: [
                            {
                                title: 'Edit',
                                icon: 'fa fa-pencil',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    rstools.pricing.group.edit({
                                        PricingGroups: $.map(rows, function (pricingGroup) {
                                            return pricingGroup.PricingGroupID;
                                        })
                                    });
                                }
                            },
                            {
                                title: 'Export',
                                icon: 'fa fa-download',
                                multiple: true,
                                fields: ['actions'],
                                callback: function (rows) {
                                    var params = {
                                        t: 'text/csv',
                                        filename: 'pricing'
                                    };

                                    if (rows.length === 1) {
                                        var row = rows[0];

                                        params.filename = "Pricing (" + row.PricingGroupName + ")";
                                        params.PricingGroup = row.PricingGroupID;
                                    } else {
                                        params.PricingGroups = $.map(rows, function (pricingGroup) {
                                            return pricingGroup.PricingGroupID;
                                        });
                                    }

                                    window.open(ridestyler.ajax.url('Organization/GetPricingData', params), "_blank");
                                }
                            },
							{
								title: 'Archive',
								forMenu: true,
								icon: 'fa fa-trash',
								multiple: true,
								appliesTo: function (pricingGroup) {
									return !pricingGroup.PricingGroupArchived;
								},
								fields: ['actions'],
								callback: function (rows) {
									var modal = uifactory.create.confirmActionModal({
										titles: rstools.pricing.group.utils.getTitles(rows),
										noun: 'pricing group',
										actionRequest: {
											action: 'Organization/ArchivePricingGroup',
											data: {
												PricingGroups: rstools.pricing.group.utils.getIDs(rows)
											}
										},
										onActionCompleted: function () {
											grid.refresh();
										}
									});

									modal && modal.show();
								}
							},
							{
								title: 'Unarchive',
								forMenu: true,
								icon: uifactory.create.icon({
									name: 'trash',
									stack: 'ban'
								}),
								multiple: true,
								appliesTo: function (pricingGroup) {
									return pricingGroup.PricingGroupArchived;
								},
								fields: ['actions'],
								callback: function (rows) {
									var modal = uifactory.create.confirmActionModal({
										titles: rstools.pricing.group.utils.getTitles(rows),
										noun: 'pricing group',
										verb: 'unarchive',
										actionButtonClass: 'btn btn-success',
										actionRequest: {
											action: 'Organization/UnarchivePricingGroup',
											data: {
												PricingGroups: rstools.pricing.group.utils.getIDs(rows)
											}
										},
										onActionCompleted: function () {
											grid.refresh();
										}
									});

									modal && modal.show();
								}
							}
                        ],
                        getAction: 'Organization/GetPricingGroupDescriptions',
                        countAction: 'Organization/CountPricingGroupDescriptions',
                        resultListField: 'Results',
                        baseQueryData: {
                            Organization: organizationID
                        },
                        search: filters,
                        namespace: 'pricing-group'
                    });
                // #endregion

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .prependTo(grid.topControls)
                    .trigger('filters-updated', [Object.keys(filters).length]);

                var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

                $('<button/>', {
                    'class': 'btn btn-primary pull-right',
                    'append': [
                        'Create Pricing Group &nbsp;',
                        uifactory.create.icon('plus')
                    ],
                    'appendTo': $rightButtons,
                    'click': function () {
                        rstools.pricing.group.create();
                    }
                });
            },
            viewLoaded: function (ctx) {
                // Update filters from the current ctx
                // or grab them from the original context
                filters = $.extend(filters, ctx.state ? context.state['filters'] : {});

                $filterForm.syncObjectToForm(filters);
            }
        };

        function filtersUpdated() {
            $filterForm && $filterForm.syncFormToObject(filters);
            $filterButton && $filterButton.trigger('filters-updated', [Object.keys(filters).length]);

            grid.search(filters);
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);