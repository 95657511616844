(function () {
    rstools.tire.fitment.resource = {
        utils: {
            getResourceID: function (tireFitmentResource) {
                return tireFitmentResource.TireFitmentResource_ResourceID;
            },
            getResourceIDs: function (rows, filter) {
                return $.map(rows, function (row) {
                    if (filter && !filter(row)) return undefined;
                    return rstools.tire.fitment.resource.utils.getResourceID(row);
                });
            },
            getID: function (tireFitmentResource) {
                return tireFitmentResource.TireFitmentResourceID;
            },
            getIDs: function (rows, filter) {
                return $.map(rows, function (row) {
                    if (filter && !filter(row)) return undefined;
                    return rstools.tire.fitment.resource.utils.getID(row);
                });
            }
        } 
    };
})();