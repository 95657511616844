(function ($) {
    function UifactorySelectionList(options) {
        if (options instanceof $)
            return options.data('selectionList');

        this.initialize(options);
    }

    function onSelectionListClick() {
        $(this).toggleClass('selected').parent().change();
    }

    UifactorySelectionList.prototype = {
        initialize: function(options) {
            var settings = this.settings = $.extend({}, this.defaultSettings, options);
            var ul = this.list = document.createElement('ul');
            var $ul = this.$list = $(ul);

            ul.className = "selection-list";

            if (settings.options) {
                if (typeof settings.options.length === 'number') this.buildOptions(settings.options);
                else if (typeof settings.options.action === 'string') this.loadOptions(settings.options);
            }

            if (settings.value)
                this.setSelections(settings.value);
            
            if (settings.sortable) {
                var sortableSettings = typeof settings.sortable === 'object' ? settings.sortable : {};

                sortableSettings = $.extend({
                    change: function() {
                        $ul.change();
                    }
                }, this.defaultSortableSettings, sortableSettings);

                $ul.sortable(sortableSettings);
            }

            return $ul.data('selectionList', this);
        },
        defaultSettings: {
            options: [],
            sortable: false,
            valueType: 'object' // object, array
        },
        defaultSortableSettings: {
            axis: 'y'
        },
        buildOptions: function (options) {
            var ul = this.list;

            this.$list.empty();

            for (var i = 0; i < options.length; i++) {
                var option = options[i];
                var label, value, selected = false;

                if (typeof option === "string") label = value = option;
                else {
                    label = option.label;
                    value = option.value;
                    selected = option.selected;
                }

                var li = document.createElement('li');

                li.onclick = onSelectionListClick;
                li.setAttribute('data-value', value);

                if (typeof label === 'function') label.apply(li);
                else if (typeof label === 'string') li.innerHTML = label;
                else if (label instanceof $) label.appendTo(ul);
                else if (label && label.nodeType === 1) ul.appendChild(label);

                if (selected) li.className = 'selected';

                ul.appendChild(li);
            }

        },
        loadOptions: function(rsSettings) {
            var $list = this.$list.addClass('loading');
            var selectList = this;

            return rstools.utils.apiDeferred(rsSettings)
                .done(function(data) {
                    selectList.buildOptions(rsSettings.buildOptions.call(selectList, data));

                    if (typeof selectList.settings.onLoad === 'function')
                        selectList.settings.onLoad.call(selectList, data);
                })
                .always(function() { $list.removeClass('loading'); });
        },
        hasSelections: function() {
            var listChildren = this.list.children;

            for (var i = 0; i < listChildren.length; i++)
                if (listChildren[i].className.indexOf('selected') >= 0)
                    return true;

            return false;
        },
        setSelections: function (values) {
            var valueArray = $.isArray(values);

            var listChildren = this.list.children;

            var $child, childValue;

            for (var i = 0; i < listChildren.length; i++) {
                $child = $(listChildren[i]);
                childValue = $child.data('value');

                if (childValue) 
                    $child.toggleClass('selected', valueArray ? $.inArray(childValue, values) >= 0 : values[childValue] === true);
            }
        },
        value: function () {
            var listChildren = this.list.children;
            var type = this.settings.valueType;
            var values;
            var value, child, selected;

            switch (type) {
                case "object":
                    values = {};
                    break;
                case "array":
                    values = [];
                    break;
                default:
                    return undefined;
            }

            for (var i = 0; i < listChildren.length; i++) {
                child = listChildren[i];
                value = child.getAttribute('data-value');

                if (value) {
                    selected = child.className.indexOf('selected') >= 0;

                    if (type === 'object') values[value] = selected;
                    else if (type === 'array') {
                        if (selected) values.push(value);
                    }
                }
            }

            return values;
        }
    };

    uifactory.create.selectionList = function(settings) {
        return new UifactorySelectionList(settings).$list;
    };
})(jQuery);