rter.mapController('app.data.wheelpros', function (context) {
    var apiDeferred = rstools.utils.apiDeferred;

    var SortDirection = {
        Asc: 0,
        Desc: 1
    };
    var WheelProsReferenceFields = {
        VehicleCD: 1
    };
    var VehicleReferenceType = {
        ACES: 1,
        WheelPros: 2
    };

    var UpdateVehicleReferenceMode = {
        Combine: 1,
        Replace: 2
    };

    var $topContainer,
        $rsGridContainer, $modelGridContainer, $submodelGridContainer;

    //#region Layout Functions
    function updateLayout() {
        var containerHeight = $(window).height() - $topContainer.offset().top - 30;

        $topContainer
            .height(containerHeight);

        $rsGridContainer
            .height(containerHeight - $rsGridContainer.position().top - 60);

        $modelGridContainer
            .height(containerHeight - $modelGridContainer.position().top - 60);

        $submodelGridContainer
            .height(containerHeight - $submodelGridContainer.position().top - 60);
    }
    //#endregion

    return {
        viewWillCreate: function ($view) {
            $topContainer = $view.find('.container:first');

            var $wpContainer = $('#wl-vehicles-container', $view);
            var $rsContainer = $('#wl-rs-vehicles-container', $view);

            var $noFiltersMessage = $('#wl-no-select-filters-message', $wpContainer);
            var $rsTableMessage = $('#wl-wp-vehicle-message', $rsContainer);

            var $saveLinkagesButton = $('#wl-save-linkages-button', $rsContainer);

            var rsModelCount = 0;

            //#region Create the WheelPros toolbar
            var $wpToolbar = $('#wl-vehicles-toolbar', $wpContainer);
            var $wpFilterButtonGroup = $('<div/>', { 'class': 'btn-group', 'role': 'group' }).appendTo($wpToolbar);

            var $wpYearDropdown = uifactory.create.dropdown().appendTo($wpFilterButtonGroup);
            var wpYearDropdown = $wpYearDropdown.data('rs-dropdown');

            apiDeferred({
                action: 'ExternalData/GetVehicleYears',
                responseKey: 'Years',
                data: {
                    Provider: 'WheelPros'
                }
            }).done(function (years) {
                $wpYearDropdown.trigger('rs-dropdown-loaded', [
                    [{label: 'Year', value: false}, '-'].concat($.map(years, function(year) {
                        return { label: year.Year, value: year.YearID };
                    })), true
                ]);
            });

            var $wpMakeDropdown = uifactory.create.dropdown().appendTo($wpFilterButtonGroup).hide();
            var wpMakeDropdown = $wpMakeDropdown.data('rs-dropdown');

            var $wpModelLabel = $('<span/>', {
                'css': {
                    margin: '0 10px 0 20px'
                }
            }).appendTo($wpToolbar).hide();
            var $wpModelRemoveButton = $('<button/>', { 'class': 'btn btn-tiny btn-primary', text: 'Back to Model' }).appendTo($wpToolbar).hide();
            var $wpModelEls = $wpModelLabel.add($wpModelRemoveButton);
            //#endregion

            //#region Create the RS toolbar
            var $rsToolbar = $('#wl-rs-toolbar', $rsContainer);
            var $rsFilterButtonGroup = $('<div/>', { 'class': 'btn-group', 'role': 'group' }).appendTo($rsToolbar);

            var $rsYearDropdown = uifactory.create.dropdown().appendTo($rsFilterButtonGroup);
            var rsYearDropdown = $rsYearDropdown.data('rs-dropdown');

            apiDeferred({
                action: 'Vehicle/GetYears',
                responseKey: 'Years',
                data: {
                    FitmentRequirement: 0,
                    TireOptionRequirement: 0
                }
            }).done(function (years) {
                $rsYearDropdown.trigger('rs-dropdown-loaded', [
                    [{ label: 'Year', value: false }, '-'].concat($.map(years, function (year) {
                        return { label: year, value: year };
                    })), true
                ]);
            });

            var $rsMakeDropdown = uifactory.create.dropdown().appendTo($rsFilterButtonGroup);
            var rsMakeDropdown = $rsMakeDropdown.data('rs-dropdown');

            apiDeferred({
                action: 'Vehicle/GetMakes',
                responseKey: 'Makes',
                data: {
                    FitmentRequirement: 0,
                    TireOptionRequirement: 0
                }
            }).done(function (makes) {
                if (!makes || !makes.length)
                    $rsMakeDropdown.trigger('rs-dropdown-loaded', [[
                        { label: 'No Makes', value: false }
                    ], true]);
                else
                    $rsMakeDropdown.trigger('rs-dropdown-loaded', [
                        [{ label: 'Make', value: false }, '-'].concat($.map(makes, function(make) {
                            return { label: make.VehicleMakeName, value: make.VehicleMakeID };
                        })), true
                    ]);
            });

            var $rsModelDropdown = uifactory.create.dropdown().hide().appendTo($rsFilterButtonGroup);
            var rsModelDropdown = $rsModelDropdown.data('rs-dropdown');

            //#endregion

            //#region Create the WheelPros datagrids
            var wheelProsVehicleReferenceCountField = {
                caption: '# of Linkages',
                name: 'NumLinkages',
                columnRenderer: function ($tds, wpVehicles) {
                    var $trs = $tds.parent();
                    var vehicleCDs = $.map(wpVehicles, function (r, i) {
                        var vehicleCD = r.Data.VehicleCD;

                        if (vehicleCD === '0') vehicleCD = undefined;

                        if (vehicleCD) {
                            var $tr = $trs.eq(i).addClass('status-loading');
                            return vehicleCD;
                        } else {
                            retrieveWPSubmodelLinkageStatus(r, $tds.eq(i), $trs.eq(i));
                        }

                        return undefined;
                    });
                    var datagrid = this;
                    var fieldIndex = datagrid.field.getFieldIndex('NumLinkages');

                    // If there are no vehicleCDs in the table then we don't need to look for references 
                    if (!vehicleCDs.length) return;

                    apiDeferred({
                        action: 'Vehicle/GetReferences',
                        data: {
                            VehicleReferenceType: rstools.constants.VehicleReferenceType.WheelPros,
                            VehicleReferenceField: WheelProsReferenceFields.VehicleCD,
                            VehicleReferenceValues: vehicleCDs
                        },
                        responseKey: 'References'
                    }).done(function (references) {
                        var vehicleCDCounts = {};

                        for (var i = 0; i < references.length; i++) {
                            var vehicleCD = references[i].VehicleReferenceValue;

                            if (vehicleCD in vehicleCDCounts) vehicleCDCounts[vehicleCD]++;
                            else vehicleCDCounts[vehicleCD] = 1;
                        }

                        $trs.each(function(i) {
                            var $tr = $(this);

                            vehicleCD = wpVehicles[i].Data.VehicleCD;

                            if (vehicleCD) {
                                $tr.removeClass('status-loading');

                                if (vehicleCD in vehicleCDCounts)
                                    $tr.addClass('linked-green').find('td:eq(' + fieldIndex + ')').text(vehicleCDCounts[vehicleCD]);
                            }
                        });
                    });
                },
                sort: false
            };

            //#region Create the WheelPros model datagrid
            $modelGridContainer = $('<div/>', { 'class': 'table-wrapper' }).hide().appendTo($wpContainer);

            var wheelProsGridSettings = {
                baseQueryData: {
                    Provider: 'WheelPros'
                },

                compact: true,
                bottomControlsEnabled: false,
                topControlsEnabled: false,
                paginationEnabled: false,
                singleSelect: true,
                loadFirstResultSet: false
            };

            var wpModelTable = datagrid.create($.extend({}, wheelProsGridSettings, {
                container: $modelGridContainer,
                fields: [
                    {
                        name: 'ModelName',
                        caption: 'Model',
                        sort: false
                    },
                    {
                        name: 'Data.VehicleCD',
                        caption: 'VehicleCD',
                        format: function (r) {
                            if (r.Data.MoreData) return ['See submodels ',uifactory.create.icon('hand-o-right')];

                            return r.Data.VehicleCD;
                        },
                        sort: false
                    },
                    wheelProsVehicleReferenceCountField,
                    {
                        name: 'actions',
                        caption: '',
                        width: 50,
                        sort: false
                    }
                ],
                actions: [
                    {
                        title: 'Submodels',
                        icon: 'fa fa-th-list',
                        fields: ['actions'],
                        appliesTo: function(r) {
                            return !!r.Data.MoreData;
                        },
                        multiple: false,
                        callback: function (r) {
                            showWPSubmodelGrid(r[0]);
                        }
                    }
                ],

                getAction: 'ExternalData/GetVehicleModels',
                resultListField: 'Models',
                stickyTableHeadersScrollableArea: $modelGridContainer.get(0)
            }));
            //#endregion

            //#region Create the WheelPros Submodel datagrid
            $submodelGridContainer = $('<div/>', {'class': 'table-wrapper'}).hide().appendTo($wpContainer);

            var wpSubmodelGrid = datagrid.create($.extend({}, wheelProsGridSettings, {
                container: $submodelGridContainer,
                fields: [
                    {
                        name: 'ModelID',
                        caption: 'Model',
                        sort: false
                    },
                    {
                        name: 'SubmodelID',
                        caption: 'Submodel',
                        sort: false
                    },
                    {
                        name: 'Data.VehicleCD',
                        caption: 'VehicleCD',
                        sort: false
                    },
                    wheelProsVehicleReferenceCountField
                ],

                getAction: 'ExternalData/GetVehicleSubmodels',
                resultListField: 'Submodels',
                stickyTableHeadersScrollableArea: $submodelGridContainer.get(0)
            }));
            //#endregion

            var $wpGridLoaders = $wpContainer.find('.data-table-loading');
            //#endregion

            //#region Create the RS Vehicle datagrid
            $rsGridContainer = $('<div/>', { 'class': 'table-wrapper' }).hide().appendTo($rsContainer);

            var rsGrid = datagrid.create({
                container: $rsGridContainer,
                fields: [
                    //#region RS Vehicle grid fields
                    {
                        name: 'VehicleConfigurationYear',
                        caption: 'Year',
                        format: ['Year']
                    },
                    {
                        name: 'MakeName',
                        caption: 'Make',
                        sort: false
                    },
                    {
                        name: 'VehicleConfiguration_VehicleModelID',
                        caption: 'Model',
                        format: ['ModelName']
                    },
                    {
                        name: 'VehicleConfigurationName',
                        caption: 'Trim',
                        format: ['TrimName']
                    },
                    {
                        name: 'StyleType',
                        sort: false,
                        caption: 'Type',
                        format: function (v) {
                            return v.StyleDescription != 'Unspecified' ? v.StyleDescription : v.StyleType;
                        }
                    },
                    {
                        name: 'VehicleConfiguration_VehicleDoorTypeID',
                        caption: 'Doors',
                        format: ['DoorType']
                    },
                    {
                        name: 'VehicleConfiguration_VehicleDriveTypeID',
                        caption: 'Drive',
                        format: ['DriveType']
                    },
                    {
                        name: 'BedType',
                        sort: false,
                        caption: 'Bed',
                        format: function (v) {
                            var bedType = v.BedType == 'N/A' ? false : v.BedType,
                                bedLength = v.BedLength == 'N/A' ? false : v.BedLength;

                            if (bedType && bedLength) return bedType + ' (' + bedLength + ')';
                            else if (bedType) return bedType;
                            else if (bedLength) return bedLength;
                            else return 'N/A';
                        }
                    },
                    {
                        name: 'CabType',
                        sort: false,
                        caption: 'Cab'
                    },

                    { name: 'actions', caption: '', sort: false }
                    //#endregion
                ],
                actions: [
                    {
                        title: 'Details',
                        icon: 'fa fa-info',
                        fields: ['actions'],
                        callback: function (vehicleConfiguration) {
                            if ((vehicleConfiguration = vehicleConfiguration.length && vehicleConfiguration[0]))
                                rstools.vehicle.showDescription(vehicleConfiguration);
                        }
                    }
                ],
                getAction: 'Vehicle/GetDescriptions',
                resultListField: 'Descriptions',
                baseQueryData: {
                    IncludeReferenceCounts: true,
                    FitmentRequirement: 0,
                    TireOptionRequirement: 0
                },
                sort: {
                    'VehicleConfigurationYear': SortDirection.Desc,
                    'VehicleConfiguration_VehicleModelID': SortDirection.Asc,
                    'VehicleConfigurationName': SortDirection.Asc
                },
                paginationEnabled: false,
                compact: true,
                loadFirstResultSet: false,
                topControlsEnabled: false,
                stickyTableHeadersScrollableArea: $rsGridContainer.get(0)
            });

            var $rsGridLoader = $rsGridContainer.find('.data-table-loading');
            //#endregion

            //#region Shared functions
            var getActiveWPGrid = function() {
                if ($submodelGridContainer.is(':visible')) return wpSubmodelGrid;
                return wpModelTable;
            };
            var activeWPVehicle = function() {
                var v = getActiveWPGrid().page.getSelectedObjects();
                if (v.length && !v[0].Data.MoreData) return v[0];
                return undefined;
            };
            var setRSTableMessage = function (message) {
                $rsTableMessage.empty().append(message)[message ? 'show' : 'hide']();
            };
            var updateRSTableMessage = function() {
                if (!rsModelCount) {
                    var selectedYearOption = rsYearDropdown.selectedOption;
                    var selectedMakeOption = rsMakeDropdown.selectedOption;

                    var selectedYear = selectedYearOption && selectedYearOption.value;
                    var selectedMake, selectedMakeName;
                    if (selectedMakeOption) {
                        selectedMake = selectedMakeOption.value;
                        selectedMakeName = selectedMakeOption.label;
                    }

                    setRSTableMessage('No RideStyler Models in the ' + selectedYear + ' ' + selectedMakeName + ' brand.');

                    return;
                }

                setRSTableMessage();
            };
            var setRSButtonsEnabled = function(enabled) {
                rsYearDropdown.elements.button
                    .add(rsMakeDropdown.elements.button)
                    .add(rsModelDropdown.elements.button)
                    .prop('disabled', !enabled);
            }
            var resetRSDatagrid = function () {
                $rsGridContainer.hide();

                if (activeWPVehicle()) {
                    updateRSTableMessage();
                    setRSButtonsEnabled(true);
                } else {
                    setRSTableMessage('No WheelPro&apos;s vehicle selected.');
                    setRSButtonsEnabled(false);
                }

                if (!rsYearDropdown.selectedOption || !rsYearDropdown.selectedOption.value)
                    $rsModelDropdown.hide();
            };

            var onSaveLinkagesFailed = function () {
                uifactory.alert.show({
                    text: 'There was a problem saving the linkages: ' + response.Message,
                    type: 'error',
                    duration: 2500
                });
            };

            var updateSelectedRSModelWithWP = function () {
                var wpVehicle = activeWPVehicle();

                if (wpVehicle && rsModelCount) {
                    if (rsModelDropdown.search(wpVehicle.ModelID.toLowerCase(), false)) {
                        $saveLinkagesButton.prop('disabled', false);
                        $rsModelDropdown.trigger('change');
                    } else {
                        rsModelDropdown.setSelectedOption('all');
                        updateRSGridWithModel();
                    }
                } else {
                    updateRSTableMessage();
                }

            };

            var prevRSYearOption, prevRSMakeOption;
            var updateRSModelOptions = function () {
                var selectedYearOption = rsYearDropdown.selectedOption;
                var selectedMakeOption = rsMakeDropdown.selectedOption;

                var selectedYear = selectedYearOption && selectedYearOption.value;
                var selectedMake, selectedMakeName;
                if (selectedMakeOption) {
                    selectedMake = selectedMakeOption.value;
                    selectedMakeName = selectedMakeOption.label;
                }
                var yearWasUpdated = prevRSYearOption !== selectedYear;
                var yearMakeWasUpdated = prevRSMakeOption !== selectedMake || yearWasUpdated;

                if (yearWasUpdated) {
                    rsMakeDropdown.setSelectedOption(false);
                    rsModelDropdown.setSelectedOption(false);
                    $rsModelDropdown.hide();

                    selectedMake = false;
                    yearMakeWasUpdated = true;
                }
                
                if (yearMakeWasUpdated) {
                    prevRSMakeOption = selectedMake;
                    prevRSYearOption = selectedYear;

                    $wpModelEls.hide();

                    if (!selectedMake || !selectedYear) {
                        resetRSDatagrid();
                        return;
                    }
                } else {
                    if (selectedMake && selectedYear) {
                        $rsModelDropdown.show();
                        updateSelectedRSModelWithWP();
                        return;
                    }
                }

                // Clear options from model dropdown
                $rsModelDropdown.show().trigger('rs-dropdown-loaded', [false, true]);

                // Load model options
                apiDeferred({
                    action: 'Vehicle/GetModels',
                    responseKey: 'Models',
                    data: {
                        Year: selectedYearOption.value,
                        VehicleMake: selectedMakeOption.value,
                        TireOptionRequirement: 0,
                        FitmentRequirement: 0
                    }
                }).done(function (models) {
                    rsModelCount = models && models.length;

                    if (!rsModelCount) {
                        $rsModelDropdown.trigger('rs-dropdown-loaded', [
                            [
                                { label: 'No Models', value: false }
                            ], true
                        ]);
                    } else {
                        $rsModelDropdown.trigger('rs-dropdown-loaded', [
                            [{ label: 'Model', value: false }, '-', { label: 'All Models', value: 'all'}, '-'].concat($.map(models, function (model) {
                                return { label: model.VehicleModelName, value: model.VehicleModelID };
                            })), true
                        ]);
                    }

                    updateSelectedRSModelWithWP();
                });
            };
            var searchRSDatagrid = function(vehicle) {
                setRSTableMessage();

                $saveLinkagesButton.prop('disabled', true);
                setRSButtonsEnabled(true);

                var $selectedYear = $rsYearDropdown.find('a').filter(function() {
                    return $(this).text() === vehicle.Data.Year.toString();
                });
                rsYearDropdown.setSelectedOption($selectedYear.length ? $selectedYear : false);

                var $selectedMake = $rsMakeDropdown.find('a').filter(function () {
                    var make = vehicle.MakeID || vehicle.Data.MakeID;
                    return $(this).text() === make;
                });
                rsMakeDropdown.setSelectedOption($selectedMake.length ? $selectedMake : false);

                updateRSModelOptions();
            };
            var updateRSGridWithModel = function () {
                var selectedModelOption = rsModelDropdown.selectedOption;
                var selectedYearOption = rsYearDropdown.selectedOption;
                var selectedMakeOption = rsMakeDropdown.selectedOption;

                var selectedModel = selectedModelOption && selectedModelOption.value;
                var selectedYear = selectedYearOption && selectedYearOption.value;
                var selectedMake = selectedMakeOption && selectedMakeOption.value;

                if (!selectedYear || !selectedModel) return resetRSDatagrid();

                var search = {
                    Year: selectedYear,
                    VehicleMake: selectedMake
                };

                if (selectedModel !== 'all') search.VehicleModel = selectedModel;

                rsGrid.search(search);
            };

            var $linkageCountWarning;
            var updateRSRowLinkageStatuses = function($trs) {
                var wpVehicle = activeWPVehicle();

                // If there's no WheelPros vehicle there's no way to get linkages; do nothing.
                if (!wpVehicle) return $.Deferred().resolve();

                // Disable the Save Linkages button so the user can't accidently click it while this is loading
                $saveLinkagesButton.prop('disabled', false);

                // Add loading indicators to all of the RideStyler vehicle rows
                $trs.addClass('status-loading-right');

                // Get all of the references for the current WheelPros VehicleCD,
                // return the deferred so we can run actions after this returns in any
                // calling functions
                return apiDeferred({
                    action: 'Vehicle/GetReferences',
                    responseKey: 'References',
                    data: {
                        VehicleReferenceType: VehicleReferenceType.WheelPros,
                        VehicleReferenceValue: wpVehicle.Data.VehicleCD
                    }
                }).done(function (references) {
                    var linkedCount = 0;

                    // Add linked symbols to any linked rows
                    $trs.each(function() {
                        var $tr = $(this).removeClass('status-loading-right');
                        var v = rsGrid.page.getObjectAtIndex($tr.data('row'));

                        for (var j = 0; j < references.length; j++)
                            if (v.ConfigurationID === references[j].VehicleReference_VehicleConfigurationID) {
                                $tr.addClass('linked-green linked-green-right');
                                linkedCount++;
                            }
                    });

                    if ($linkageCountWarning) {
                        $linkageCountWarning.remove();
                        $linkageCountWarning = undefined;
                    }

                    var extraReferences = references.length - linkedCount;
                    if (extraReferences > 0) {
                        $linkageCountWarning = $('<span/>', { 'class': 'text-warning' });

                        $linkageCountWarning
                            .css({
                                marginLeft: 10
                            })
                            .append([uifactory.create.icon('exclamation-triangle'), ' ', extraReferences])
                            .attr('title', 'There are ' + extraReferences + ' additional vehicle configurations linked to this VehicleCD.')
                            .tooltip()
                            .appendTo($rsToolbar);
                    }

                    // Select any linked rows
                    $trs.filter('.linked-green').click();
                });
            };
            var updateHighlightedRSRows = function($trs) {
                var fieldIndexes = rsGrid.field.getFieldIndex(['VehicleConfigurationYear', 'MakeName', 'VehicleConfiguration_VehicleModelID', 'VehicleConfigurationName', 'VehicleConfiguration_VehicleDriveTypeID']);
                var wpVehicle = activeWPVehicle();
                var wpVehicleMakeName = wpVehicle.MakeID || wpVehicle.Data.MakeID;
                var FieldComparisonStatus = rstools.constants.FieldComparisonStatus;

                if (!wpVehicle) return;

                $trs.each(function (i) {
                    var rsVehicle = rsGrid.page.getObjectAtIndex($(this).data('row'));
                    var $tds = $(this).children();

                    if (!rsVehicle) return;

                    // Get field comparison statuses for year, make and model
                    var statusFieldLookup = {
                        VehicleConfigurationYear: rstools.field.comparison.compareYears(rsVehicle.Year, wpVehicle.Data.Year),
                        MakeName: rstools.field.comparison.compareMakes(rsVehicle.MakeName, wpVehicleMakeName),
                        VehicleConfiguration_VehicleModelID: rstools.field.comparison.all(
                            rstools.field.comparison.compareModels(wpVehicle.ModelID, rsVehicle.ModelName, true),
                            rstools.field.comparison.compareTokens(wpVehicle.ModelID, rsVehicle.ModelName)
                        )
                    };
                    
                    // Get field comparison statuses for submodel if need be
                    if ('SubmodelID' in wpVehicle)
                        statusFieldLookup['VehicleConfigurationName'] =
                            rstools.field.comparison.compareTokens([rsVehicle.ModelName, rsVehicle.TrimName], [wpVehicle.ModelID, wpVehicle.SubmodelID], {
                                tokenMatchingRules: [
                                { match: /^(?:\/|-)$/, mode: 'ignore' },
                                {
                                    match: ['standard', 'base'],
                                    mode: 'ignore'
                                },
                                {
                                    match: /^\d\.\d$/, // Ex: 1/2 or 1/4
                                    rule: function () {
                                        // If this token wasn't matched then we can't really look
                                        // for this anywhere else; we don't want this case to fall through
                                        // to any of the other rules
                                        return false;
                                    }
                                },
                                {
                                    match: ['awd', '2wd', '4wd', 'rwd'],
                                    rule: function(token, from) {
                                        if (from === 'b') {
                                            var rsDriveType = rsVehicle.DriveType.toLowerCase();
                                            var equal;

                                            switch (token.toLowerCase()) {
                                                case 'fwd':
                                                case 'rwd':
                                                    equal = rsDriveType == '2wd';
                                                    break;

                                                case 'awd':
                                                    equal = rsDriveType == 'awd';
                                                    break;

                                                case '4wd':
                                                    equal = rsDriveType == '4wd';
                                                    break;

                                                default:
                                                    return false;
                                            }

                                            if (equal) statusFieldLookup['VehicleConfiguration_VehicleDriveTypeID'] = FieldComparisonStatus.Success;

                                            return equal;
                                        }
                                    }
                                },
                                {
                                    match: /^.{2,}\/.{2,}$/,
                                    rule: function (token, from) {
                                        var comparisonTokens = from === 'a' ? this.b : this.a,
                                        splitToken = token.split('/'),
                                        matched = false;

                                        $.each(splitToken, function (i, t) {
                                            if ($.inArray(t, comparisonTokens) >= 0) {
                                                matched = true;
                                                return false;
                                            }
                                        });

                                        // If we matched we return 2 results 
                                        return matched ? 2 : false;
                                    }
                                }
                            ]
                            });

                    for (var k in statusFieldLookup) {
                        var index = fieldIndexes[k];
                        
                        if (statusFieldLookup[k] === FieldComparisonStatus.Invalid) {
                            $tds.removeClass('status status-warning status-success');
                            return;
                        }

                        var status = statusFieldLookup[k] === FieldComparisonStatus.Warning ? 'warning' : 'success';
                        $tds.eq(index).addClass('status status-' + status);
                    }
                });
            };
            //#endregion

            //#region Functions shared between the WP model/submodel grids
            var updateRowsLinkageStatus = function () {
                var datagrid = this;

                var vehicleCDs = $.map(datagrid.page.getObjects(), function(r) {
                    return r.Data.VehicleCD || undefined;
                });

                // If there are no vehicleCDs in the table then we don't need to look for references 
                if (!vehicleCDs.length) return;

                var $trs = datagrid.table.find('tbody tr').addClass('status-loading');

                apiDeferred({
                    action: 'Vehicle/GetReferences',
                    data: {
                        VehicleReferenceType: rstools.constants.VehicleReferenceType.WheelPros,
                        VehicleReferenceField: WheelProsReferenceFields.VehicleCD,
                        VehicleReferenceValues: vehicleCDs
                    },
                    responseKey: 'References'
                }).done(function(references) {
                    var linkMap = {};

                    for (var i = 0; i < references.length; i++)
                        linkMap[references[i].VehicleReferenceValue] = true;

                    $trs.each(function (i) {
                        var $tr = $(this).removeClass('status-loading');
                        var r = datagrid.page.getObjectAtIndex($tr.data('row'));

                        if (r.Data.VehicleCD in linkMap) $tr.addClass('linked-green');
                    });
                });
            };
            var showWPSubmodelGrid = function (model) {
                $modelGridContainer.hide();
                $submodelGridContainer.show();

                $wpModelLabel.empty().append([$('<strong/>', { text: 'Model: ' }), model.ModelID]);
                $wpModelEls.show();

                wpSubmodelGrid.search({
                    Year: model.Data.Year,
                    MakeID: model.MakeID,
                    ModelID: model.ModelID
                });
            };
            var onWPSelectionChanged = function() {
                var selectedVehicle = this.page.getSelectedObjects();

                if (selectedVehicle.length > 0) {
                    selectedVehicle = selectedVehicle[0];

                    if (!selectedVehicle.Data.MoreData) {
                        searchRSDatagrid(selectedVehicle);
                        return;
                    } else {
                        showWPSubmodelGrid(selectedVehicle);
                        return;
                    }
                } 

                resetRSDatagrid();
            };
            var retrieveWPSubmodelLinkageStatus = function (model, $td, $tr) {
                $tr.addClass('status-loading')

                apiDeferred({
                    action: 'ExternalData/GetVehicleSubmodels',
                    responseKey: 'Submodels',
                    data: {
                        Provider: 'WheelPros',
                        Year: model.Data.Year,
                        MakeID: model.MakeID,
                        ModelID: model.ModelID
                    }
                })
                    .done(function (submodels) {
                        var vehicleCDs = $.map(submodels, function(submodel) {
                            return submodel.Data.VehicleCD
                        });

                        apiDeferred({
                                action: 'Vehicle/GetReferences',
                                data: {
                                    VehicleReferenceType: rstools.constants.VehicleReferenceType.WheelPros,
                                    VehicleReferenceField: WheelProsReferenceFields.VehicleCD,
                                    VehicleReferenceValues: vehicleCDs
                                },
                                responseKey: 'References'
                            })
                            .done(function(references) {
                                var vehicleCDCounts = {};

                                for (var i = 0; i < references.length; i++) {
                                    var vehicleCD = references[i].VehicleReferenceValue;

                                    if (vehicleCD in vehicleCDCounts) vehicleCDCounts[vehicleCD]++;
                                    else vehicleCDCounts[vehicleCD] = 1;
                                }

                                var linkedVehicleCDsCount = Object.keys(vehicleCDCounts).length;

                                $td.empty().text(linkedVehicleCDsCount + '/' + submodels.length + ' submodels linked');

                                if (linkedVehicleCDsCount >= submodels.length)
                                    $tr.addClass('linked-green');
                            })
                            .always(function() {
                                $tr.removeClass('status-loading');
                            });
                    })
                    .fail(function () {
                        $tr.removeClass('status-loading');
                    });
            };
            //#endregion

            //#region Setup WheelPro's grids events
            var bindToWheelProsGridEvent = function(name, callback) {
                wpModelTable.page.on(name, callback);
                wpSubmodelGrid.page.on(name, callback);
            };

            wpModelTable.page.on('pageChanged', function() {
                $wpModelEls.hide();
            });
            bindToWheelProsGridEvent('selectionChanged', onWPSelectionChanged);

            $wpModelRemoveButton
                .on('click', function() {
                    $wpMakeDropdown.change();
                })
                .on('mouseenter mouseleave', function(e) {
                    $wpModelLabel.toggleClass('line-through', e.type === 'mouseenter');
                });
            //#endregion

            //#region Setup RS grid events
            setRSButtonsEnabled(false);

            function reorderRSRows(references) {
                var hasReferences = references.length > 0;
                var $rsTbody = rsGrid.table.find('tbody');
                var selectedRows = $rsTbody.children('.selected');

                // If we don't have references re-order the rows by the number of statuses they have
                // and then the number of marked words and select those rows (retain old order)
                if (!hasReferences) {
                    var rowOrderMap = {},
                        rowOrderKeys = [];
                    
                    // Fill rowOrderMap with keys of how many statuses there are in a row
                    // and values of an array of rows with that many statuses
                    $rsTbody.find('.status-success').parent().each(function () {
                        var $row = $(this);
                        var successes = $row.find('.status-success').length;
                        
                        if (typeof rowOrderMap[successes] === 'undefined')
                            rowOrderMap[successes] = [];

                        rowOrderMap[successes].push($row);
                    });

                    // Build an array of keys
                    for (var k in rowOrderMap) rowOrderKeys.push(k);

                    // Sort that array by greatest to smallest
                    rowOrderKeys.sort(function (a, b) { return b - a });

                    // Sort the arrays inside the map by the number of marks they have, greatest to least
                    $.each(rowOrderMap, function(k, rows) {
                        rows.sort(function($rowA, $rowB) {
                            return $rowB.find('mark').length - $rowA.find('mark').length;
                        });
                    });

                    // Set the rows as selected
                    selectedRows = [];

                    $.each(rowOrderKeys, function (i, j) {
                        selectedRows = selectedRows.concat(rowOrderMap[j]);
                    });

                    $.each(selectedRows, function (i, $row) {
                        if ($row.hasClass('selected') === false) $row.click();
                    });
                }

                $rsTbody.prepend(selectedRows).children().each(function (i, row) {
                    var odd = i % 2 === 0;

                    $(row)
                        .toggleClass('odd', odd)
                        .toggleClass('even', !odd);
                });
            }

            rsGrid.page.on('pageChanged', function () {
                $rsGridContainer.show();
                $saveLinkagesButton.prop('disabled', true);
            });
            rsGrid.page.on('pageLoaded', function () {
                var $trs = $rsGridContainer.find('tbody tr');
                
                // Get the status of any rows that should be linked,
                // and then reorder the rows by bringing any linked rows to the top,
                // or if there are none bring the highlighted rows to the top.
                updateRSRowLinkageStatuses($trs).done(reorderRSRows);

                // Highlight any rows that match the selected WheelPros Vehicle Model or Sub-model
                updateHighlightedRSRows($trs);

                updateRSTableMessage();
            });
            rsGrid.page.on('selectionChanged', function () {
                if (!activeWPVehicle() || rsGrid.page.getSelectedObjects().length) $saveLinkagesButton.html('<i class="fa fa-save"></i> Save Linkages');
                else $saveLinkagesButton.html('<i class="fa fa-chain-broken"></i> Clear Linkages');
            });
            //#endregion

            //#region Setup event listeners for the WP dropdowns
            $wpYearDropdown.on('change', function () {
                var selectedYearOption = wpYearDropdown.selectedOption;
                var selectedYear = selectedYearOption && selectedYearOption.value;

                $modelGridContainer.hide();
                $submodelGridContainer.hide();

                if (!selectedYear) {
                    $noFiltersMessage.show();
                    $wpMakeDropdown.hide();
                    return;
                }

                // Clear options from make dropdown
                $wpMakeDropdown.show().trigger('rs-dropdown-loaded', [false, true]);

                // Select the corresponding year in the RS year dropdown
                rsYearDropdown.setSelectedOption(selectedYear);

                // Load make options
                apiDeferred({
                    action: 'ExternalData/GetVehicleMakes',
                    responseKey: 'Makes',
                    data: {
                        Year: selectedYear,
                        Provider: 'WheelPros'
                    }
                }).done(function (makes) {
                    if (!makes || !makes.length)
                        $wpMakeDropdown.trigger('rs-dropdown-loaded', [[
                            { label: 'No Makes', value: false }
                        ], true]);
                    else
                        $wpMakeDropdown.trigger('rs-dropdown-loaded', [
                            [{ label: 'Make', value: false }, '-'].concat($.map(makes, function(make) {
                                return { label: make.MakeName, value: make.MakeID };
                            })), true
                        ]);
                });
            });
            $wpMakeDropdown.on('change', function() {
                var selectedMakeOption = wpMakeDropdown.selectedOption;
                var selectedMake = selectedMakeOption && selectedMakeOption.value;

                $submodelGridContainer.hide();

                if (!selectedMake) {
                    $noFiltersMessage.show();
                    $modelGridContainer.hide();
                    return;
                }

                // Search for the make in the rs make dropdown
                rsMakeDropdown.search(selectedMake);

                $noFiltersMessage.hide();
                $modelGridContainer.show();
                wpModelTable.search({
                    Year: wpYearDropdown.selectedOption.value,
                    MakeID: selectedMake
                });
            });
            //#endregion

            //#region Setup event listeners for the RS dropdowns
            $rsYearDropdown.on('change', updateRSModelOptions);
            $rsMakeDropdown.on('change', updateRSModelOptions);
            $rsModelDropdown.on('change', updateRSGridWithModel);
            //#endregion

            //#region Save Linkages functionality
            $saveLinkagesButton.on('click', function() {
                var wpVehicle = activeWPVehicle();

                if (wpVehicle) {
                    var selectedRSVehicles = rsGrid.page.getSelectedObjects();

                    $rsGridLoader.add($wpGridLoaders).show();

                    var updateVehicleReferenceBatch = ridestyler.ajax.batch();
                    var vehicleReferences = [];

                    if (selectedRSVehicles.length) {
                        for (var i = 0; i < selectedRSVehicles.length; i++) {
                            var configurationID = selectedRSVehicles[i].ConfigurationID;

                            updateVehicleReferenceBatch.send(configurationID, {
                                action: 'Vehicle/UpdateReferences',
                                data: {
                                    VehicleConfiguration: configurationID,
                                    VehicleReferenceType: VehicleReferenceType.WheelPros,
                                    Mode: UpdateVehicleReferenceMode.Combine,
                                    References: [
                                        {
                                            Field: WheelProsReferenceFields.VehicleCD,
                                            Value: wpVehicle.Data.VehicleCD
                                        }
                                    ]
                                }
                            });

                            vehicleReferences.push({
                                VehicleReference_VehicleConfigurationID: configurationID,
                                VehicleReferenceType: VehicleReferenceType.WheelPros,
                                VehicleReferenceField: WheelProsReferenceFields.VehicleCD,
                                VehicleReferenceValue: wpVehicle.Data.VehicleCD
                            });
                        }
                    }

                    updateVehicleReferenceBatch
                        .done(function () {
                            var datagrid = getActiveWPGrid();
                            var fieldIndex = datagrid.field.getFieldIndex('NumLinkages');

                            datagrid.table.find('tbody tr').each(function () {
                                var $tr = $(this);
                                var v = datagrid.page.getObjectAtIndex($tr.data('row'));

                                if (v.Data.VehicleCD === wpVehicle.Data.VehicleCD) {

                                    if (selectedRSVehicles.length) {
                                        $tr.addClass('linked-green').find('td:eq(' + fieldIndex + ')').text(selectedRSVehicles.length);
                                    } else {
                                        $tr.removeClass('linked-green').find('td:eq(' + fieldIndex + ')').text('');
                                    }
                                }
                            });

                            if (selectedRSVehicles.length) {
                                rsGrid.page.refresh();
                                $wpGridLoaders.hide();
                            } else {
                                rsGrid.table.find('tbody tr').removeClass('linked-green linked-green-right');
                                $rsGridLoader.add($wpGridLoaders).hide();
                            }
                        })
                        .fail(onSaveLinkagesFailed)
                        .fail(function () { $rsGridLoader.add($wpGridLoaders).hide(); });

                    ridestyler.ajax.send({
                        action: 'Vehicle/ClearReferences',
                        data: {
                            VehicleReferenceType: VehicleReferenceType.WheelPros,
                            VehicleReferenceField: WheelProsReferenceFields.VehicleCD,
                            VehicleReferenceValue: wpVehicle.Data.VehicleCD
                        },
                        callback: function(response) {
                            if (!response.Success) {
                                onSaveLinkagesFailed();
                                updateVehicleReferenceBatch.reject();
                            } else {
                                if (vehicleReferences.length) updateVehicleReferenceBatch.execute();
                                else updateVehicleReferenceBatch.resolve();
                            }
                        }
                    });
                }
            });
            //#endregion

            //#region Get the linkage statistics
            var $toolbar = $('<span/>').appendTo($view.find('#wl-top-toolbar')).append($('<i/>', { 'class': 'fa fa-spinner fa-spin' }));

            ridestyler.ajax.send({
                action: 'Vehicle/GetReferenceStatistics',
                data: {
                    VehicleReferenceType: VehicleReferenceType.WheelPros,
                    VehicleReferenceField: WheelProsReferenceFields.VehicleCD
                },
                callback: function (response) {
                    $toolbar.empty();

                    if (response.Success) {
                        var referenceStatisticDescriptionMap = {
                            All: 'All Linked Vehicles',
                            WithImages: 'With Images',
                            WithoutImages: 'Without Images'
                        };
                        for (var k in referenceStatisticDescriptionMap) {
                            var $container = $('<span/>', { 'class': 'inline-statistic' }).appendTo($toolbar);
                            $('<strong/>', { text: referenceStatisticDescriptionMap[k] }).appendTo($container);

                            var $fraction = $('<span/>', { 'class': 'fraction' }).appendTo($container);

                            $('<span/>').text(response[k].Linked).appendTo($fraction);
                            $('<span/>').text(response[k].Total).appendTo($fraction);

                            $('<span/>', { 'class': 'percentage' }).text(Math.floor(response[k].Linked / response[k].Total * 100) + '%').appendTo($container);
                        }

                        var $countContainer = $('<span/>', { 'class': 'inline-statistic' }).appendTo($toolbar);
                        $('<strong/>', { text: 'Linked WheelPros VehicleCDs' }).appendTo($countContainer);
                        var $countFraction = $('<span/>', { 'class': 'fraction' }).appendTo($countContainer);
                        $('<span/>').text(response.Count).appendTo($countFraction);
                        var $countTotal = $('<span/>', {text: '~55493', title: 'Approximate. Last count was done 8/25/2015'}).appendTo($countFraction);
                        var $countPercentage = $('<span/>', { 'class': 'percentage' }).text('~' + Math.floor(response.Count / 55493 * 100) + '%').appendTo($countContainer);
                    }
                }
            });
            //#endregion
        },
        viewCreated: function() {
            updateLayout();
        },
        viewWillLoad: function () {
            $(window).on({
                'resize': updateLayout
            });
        },
        viewWillUnload: function () {
            $(window)
                .off('resize', updateLayout);
        },
    };
});