(function () {
	rstools.tire.size = {
		utils: {
			getIDs: function (tireSizes) {
				return $.map(tireSizes, function (tireSize) {
					return tireSize.TireSizeID;
				});
			},
			getTitles: function (tireSizes) {
				return $.map(tireSizes, function (tireSize) {
					if ('Description' in tireSize) return tireSize.Description;

					return tireSize.TireSizeDescription;
				})
			}
		}
	};
})();