
(function ($) {
    rter.mapController('app.dashboard.beta', function (context) {
        return {
            viewWillLoad: function (ctx) {
            },
            viewWillCreate: function ($view) {
            },
            viewCreated: function ($view) {
                function createCircle(id) {
                    Circles.create({
                        id: id,
                        radius: 60,
                        value: 43,
                        maxValue: 100,
                        colors: ['#eee', '#428bca'],
                        text: function() { return this.getPercent() + '%'; }
                    });
                }

                createCircle('circle-1');
                createCircle('circle-2');

                datagrid.create({
                    container: $view.find('#dashboard-user-grid'),
                    fields: [
                        { name: 'UserName', caption: 'Name', sort: false}
                    ],
                    getAction: 'User/GetUsers',
                    countAction: 'User/CountUsers',
                    resultListField: 'Users',
                    baseQueryData: {
                        Organization: rstools.auth.user.getActiveOrganizationID()
                    },
                    pageSize: 10,
                    topControlsEnabled: false,
                    bottomControlsEnabled: false,
                    selectEnabled: false,
                    compact: true
                });

                uifactory.create.chart({
                    type: 'Doughnut',
                    container: $view.find('#dashboard-vehicle-requests-chart')
                }, [
                    {
                        value: 300,
                        color: "#1f77b4",
                        highlight: "#3295DC",
                        label: "Audi"
                    },
                    {
                        value: 50,
                        color: "#2ca02c",
                        highlight: "#3FCA3F",
                        label: "Ford"
                    },
                    {
                        value: 100,
                        color: "#d62728",
                        highlight: "#E15B5B",
                        label: "BMW"
                    },
                    {
                        value: 10,
                        color: "#ff7f0e",
                        highlight: "#FFA04D",
                        label: "Other"
                    }
                ]);
            },
            viewWillResume: function ($view) {
            },
            viewResumed: function ($view) {
            },
            viewLoaded: function (ctx) {
            },
            viewWillUnload: function ($view) {
            },
            viewWillDetatch: function ($view) {
            },
            viewDetatched: function ($view) {
            },
            viewWillRemove: function ($view) {
            },
            viewRemoved: function ($view) {
            },
            viewUnloaded: function ($view) {
            }
        };
    });
})(jQuery);