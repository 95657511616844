rstools.message = {
    /**
     * Returns a list of grouped tags
     * @param {string|{MessageTags:string}} messageTags
     * @returns {{group: string, ids: string[]}[]}
     */
    getTags: function (messageTags) {
        /** @type {{[dataObjectType: string]: string}} */
        var tagGroupings = {};

        // Accept a string, or a MessageDataObject
        if (typeof messageTags === "object" && "MessageTags" in messageTags)
            messageTags = messageTags.MessageTags;
        
        // There's nothing here, return an empty array
        if (typeof messageTags !== "string" || !messageTags)
            return [];
        
        // Split into a list of tags
        messageTags = messageTags.split(",");

        var numberOfTags = messageTags.length;
        var i, tag, tagGrouping, group;

        // Iterate through the tags
        for (i = 0; i < numberOfTags; i++) {
            tag = messageTags[i].split(":");

            // Skip invalid tags
            if (tag.length < 2) continue;

            tagGrouping = tag[0];
            
            if (tagGrouping in tagGroupings === false)
                group = tagGroupings[tagGrouping] = [];
            else
                group = tagGroupings[tagGrouping];
            
            group.push(tag[1]);
        }

        var groupedTags = [];

        $.each(Object.keys(tagGroupings), function (i, tagGrouping) {
            groupedTags.push({
                group: tagGrouping, 
                ids: tagGroupings[tagGrouping]
            });
        });

        return groupedTags;
    },

    /**
     * Create message tag label elements for the specified message tags
     * @param {string|{MessageTags:string}|{group: string, ids: string[]}[]} messageTags
     */
    createTagLabels: function (messageTags, options) {
        options = $.extend({
            showNoTagsMessage: true // Show a message when no tags are found
        }, options);

        // Allow passing in the response from getTags, or its parameters
        if (!$.isArray(messageTags)) messageTags = rstools.message.getTags(messageTags);

        // Show a message if no tags are found
        if (!messageTags.length) {
            if (options.showNoTagsMessage) {
                var noTagsElement = document.createElement("span");
                noTagsElement.className = "text-muted";
                noTagsElement.appendChild(document.createTextNode("No Tags"));
                
                return noTagsElement;
            }

            return "";
        }

        /** @type {{group: string, ids: string[]}[]} */
        var groupedMessageTags = messageTags;
        var i, tagGroup, labelElement, countElement;

        var labelFrag = document.createDocumentFragment();
        
        function cleanGroupName(groupName) {
            return groupName
                .replace(/DataObject$/i, "")
                .replace(/([A-Z])/g, " $1")
                .replace(/^ /, "");
        }

        for (i = 0; i < groupedMessageTags.length; i++) {
            tagGroup = groupedMessageTags[i];

            labelElement = document.createElement("span");
            labelElement.className = "label label-primary";
            labelElement.appendChild(document.createTextNode(cleanGroupName(tagGroup.group) + ": "));
            
            countElement = document.createElement("span");
            countElement.style.color = "#ddd";
            countElement.appendChild(document.createTextNode(tagGroup.ids.length));

            labelElement.appendChild(countElement);
            labelFrag.appendChild(labelElement);
        }

        return labelFrag;
    },

    view: function (message) {
        var modal = uifactory.modal.create({
            cancelable: true,
            header: message.MessageTitle,
            body: $("<pre/>", {
                "class": "scrollable",
                "css": {
                    "whiteSpace": "pre-wrap"
                },
                "html": anchorme(message.MessageBody)
            }),
            actions: [
                {
                    "class": "btn btn-danger",
                    "title": "Archive",
                    "icon": "trash",
                    "callback": function () {
                        rstools.shared.modals.archive("message", [message], {
                            onArchived: function () {
                                modal.hide();
                            }
                        });
                    }
                }
            ],
            footer: [
                $("<label/>", {
                    "class": "pull-left",
                    "append": [
                        $("<input/>", {
                            "type": "checkbox",
                            "on": {
                                "ifToggled": function () {
                                    modal.$body.children("pre")
                                        .css("whiteSpace", this.checked ? "pre-wrap" : "");
                                }
                            },
                            "checked": true
                        }),
                        " Wrap"
                    ]
                })
            ]
        });

        modal.$modal.on("show.bs.modal", function () {
            modal.$modal.find("input[type=checkbox]").iCheck({
                checkboxClass: "icheckbox_square-blue",
                radioClass: "iradio_square-blue",
                increaseArea: "20%"
            });
        });

        modal.show();
    },

    settings: {
        filters: [
            {
                name: "Search",
                type: "text-search",
                label: "Search",
                required: false
            },
            {
                name: "IncludeArchived",
                type: "checkbox",
                label: "Include Archived",
                group: "Other",
                requiredRoles: rstools.roles.SYSTEM_ADMIN,
                required: false
            }
        ]
    },

    utils: {
        getIDs: function (messages) {
            return $.map(messages, function (message) {
                return message.MessageID;
            });
        },
        getTitles: function (messages) {
            return $.map(messages, function (message) {
                return message.MessageTitle;
            });
        },
        createFilters: function (messages) {
            return {
                Messages: rstools.message.utils.getIDs(messages)
            };
        },
        isUnarchived: function (message) { return !message.MessageArchived; },
        isArchived: function (message) { return message.MessageArchived; },

        createFilterForm: function (ctx, settings) {
            var pageName = ctx.routes[ctx.routes.length - 1].name;

            settings = settings || {
                filters: undefined,
                onReset: undefined,
                onSubmit: undefined
            };

            var stateFilters = ctx.state.filters || {};

            var firstMessageGroupIndex = false;
            var searchFieldIndex = false;

            // Grab all applicable filters for use as fields in the filter form
            var fields = $.map(rstools.message.settings.filters, function(filter, i) {
                if (filter.for && !~$.inArray(pageName, filter.for)) return undefined;

                if (filter.group === "Message" && firstMessageGroupIndex === false) firstMessageGroupIndex = i;
                if (filter.name === "Search") searchFieldIndex = i;
                
                return filter;
            });

            var filterFieldMap = {
                "MessageChannels": {
                    type: "static",
                    label: "Channel",
                    group: "Message",
                    fieldClass: "static-filter",
                    ridestyler: {
                        action: "Message/GetChannels",
                        data: {
                            MessageChannels: stateFilters.MessageChannels
                        },
                        responseKey: "Channels",
                        format: function (channel) {
                            return channel.MessageChannelName;
                        }
                    }
                }
            };

            var staticFiltersIndex = firstMessageGroupIndex || searchFieldIndex + 1;

            // Move filters from filterFieldMap if they're active
            // to be in the first "Message" group spot
            for (var k in filterFieldMap)
                if (k in stateFilters)
                    fields.splice(staticFiltersIndex, 0, filterFieldMap[k]);

            return uifactory.create.filterForm({
                filters: settings.filters,
                fields: fields,
                onSubmit: settings.onSubmit,
                onReset: settings.onReset
            });
        }
    },

    noun: "message",
    dataNamespace: "message",

    archiveAction: "Message/Archive",
    unarchiveAction: "Message/Unarchive"
};