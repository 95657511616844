(function ($) {
	// TODO: Finish this

	function uiFactoryImage(settings) {
		this.settings = $.extend({
			src: undefined,
			noImageText: 'No Image',
			noImageTextSize: 14
		}, settings);
	}

	uiFactoryImage.prototype = {
		createDOM: function() {
		    if (this.$container) this.$container.empty();
		    else this.$container = $('<div/>', {
		        'class': 'uifactory-image'
		    });

		    this.$img = $('<img/>', {
		    	src: this.settings.src
		    });

		    this.$img.appendTo($container);

		    this.bindImageEvents();
		},
		bindImageEvents: function () {
		    var me = this;

		    this.$img.on({
		        'error': function() {
		            me.noImageText();
		        }
		    });
		},
		noImageText: function() {
			this.setRelativePositioning();

		    this.$noImageText = $('<p/>', {
		        html: this.settings.noImageText,
		        css: {
		            'position': 'absolute',
		            'top': '50%',
		            'left': 0,
		            'right': 0,
		            'fontSize': this.settings.noImageTextSize,
		            'marginTop': this.settings.noImageTextSize / 2
		        }
		    }).appendTo($container);
		},
		setRelativePositioning: function() {
			if (this.$container.css('position') !== 'absolute') 
				this.$container.css('position', 'relative');
		}
    };

	window.uifactory.create.image = function(settings) {
	    return new uiFactoryImage(settings).$container;
	}
})(jQuery);