(function ($) {
    rter.mapController('app.organization.datasource', function (context) {
        var grid = null;
        var $filterForm, $filterButton;
        var filters = {};

        return {
            viewWillLoad: function (context) {
                filters = $.extend(filters, context.state ? context.state['filters'] : {});
            },
            viewWillCreate: function ($view) {
                $filterForm = uifactory.create.filterForm({
                    onReset: filtersUpdated,
                    onSubmit: filtersUpdated,
                    filters: filters,
                    fields: [
                        {
                            name: 'Search',
                            type: 'text-search',
                            label: 'Search',
                            required: false
                        },
                        {
                            name: 'Owner',
                            type: 'checkbox',
                            label: 'From Any Organization',
                            group: 'Organization',
                            requiredRoles: rstools.roles.SYSTEM_ADMIN,
                            required: false
                        },
                        {
                            name: 'IncludeArchived',
                            type: 'checkbox',
                            label: 'Include Archived',
                            group: 'Other',
                            requiredRoles: rstools.roles.SYSTEM_ADMIN,
                            required: false
                        }
                    ]
                });

                grid = datagrid.create({
                    container: $view.find('div.data-wrapper'),
                    allowShowAll: true,

                    headerActions: [
                        rstools.shared.datagrid.headerActions.manageColumns
                    ],
                    getColumnSettings: rstools.shared.datagrid.retrieveColumnSettings,
                    fields: [
                        {
                            name: 'DataSourceCode',
                            caption: 'Code'
                        },
                        {
                            name: 'DataSourceDescription',
                            caption: 'Description'
                        },
                        { name: 'actions', caption: '', width: 160, sort: false, colClass: 'text-center' }
                    ],
                    rowCallback: function ($tr, dataSource) {
                        if (dataSource.DataSourceArchived) $tr.addClass('archived-data-object');
                    },

                    baseQueryData: {
                        Owner: rstools.auth.user.getActiveOrganizationID()
                    },

                    actions: [
                        {
                            title: 'Edit',
                            icon: 'fa fa-pencil',
                            multiple: false,
                            fields: ['actions'],
                            requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                            callback: function (rows) {
                                rstools.datasource.edit(rows[0]);
                            }
                        },
                        {
                            title: 'Archive',
                            icon: 'fa fa-trash',
                            multiple: true,
                            requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                            appliesTo: function(dataSource) {
                                return !dataSource.DataSourceArchived;
                            },
                            fields: ['actions'],
                            callback: function(rows) {
                                var dataSourceIDs = [],
                                    titles = [];

                                for (var i = 0; i < rows.length; i++) {
                                    if (rows[i].DataSourceArchived) continue;

                                    dataSourceIDs.push(rows[i].DataSourceID);
                                    titles.push(rows[i].DataSourceDescription);
                                }

                                var modal = uifactory.create.confirmActionModal({
                                    titles: titles,
                                    noun: 'data source',
                                    actionRequest: {
                                        action: 'DataSource/ArchiveDataSources',
                                        data: {
                                            DataSources: dataSourceIDs
                                        }
                                    },
                                    onActionCompleted: function() {
                                        grid.refresh();
                                    }
                                });

                                modal && modal.show();
                            }
                        },
                        {
                            title: 'Unarchive',
                            icon: uifactory.create.icon({
                                name: 'trash',
                                stack: 'ban'
                            }),
                            multiple: true,
                            requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                            appliesTo: function(dataSource) {
                                return dataSource.DataSourceArchived;
                            },
                            fields: ['actions'],
                            callback: function(rows) {
                                var dataSourceIDs = [],
                                    titles = [];

                                for (var i = 0; i < rows.length; i++) {
                                    if (!rows[i].DataSourceArchived) continue;

                                    dataSourceIDs.push(rows[i].DataSourceID);
                                    titles.push(rows[i].DataSourceDescription);
                                }

                                var modal = uifactory.create.confirmActionModal({
                                    titles: titles,
                                    noun: 'data source',
                                    verb: 'unarchive',
                                    actionButtonClass: 'btn btn-success',
                                    actionRequest: {
                                        action: 'DataSource/UnarchiveDataSources',
                                        data: {
                                            DataSources: dataSourceIDs
                                        }
                                    },
                                    onActionCompleted: function() {
                                        grid.refresh();
                                    }
                                });

                                modal && modal.show();
                            }
                        }
                    ],

                    getAction: 'DataSource/GetDataSources',
                    countAction: 'DataSource/CountDataSources',
                    resultListField: 'DataSources',
                    search: filters
                });

                $filterButton = uifactory.create.filterButton({
                    menu: $filterForm
                })
                    .prependTo(grid.topControls)
                    .trigger('filters-updated', [Object.keys(filters).length]);


                var $rightButtons = $('<div/>', { 'class': 'right-buttons' }).prependTo(grid.topControls);

                $('<button/>', {
                    'class': 'btn btn-primary pull-right',
                    'append': [
                        'Create Data Source &nbsp;',
                        uifactory.create.icon('plus')
                    ],
                    'appendTo': $rightButtons,
                    'click': function () {
                        rstools.datasource.create();
                    }
                });
            },
            viewLoaded: function () {
                $filterForm.syncObjectToForm(filters);
            }
        };

        function filtersUpdated() {
            $filterForm.syncFormToObject(filters);
            $filterButton.trigger('filters-updated', [Object.keys(filters).length]);
            grid.search(filters);
            rstools.state.set('filters', filters);
        }
    });
})(jQuery);