(function ($) {
    rter.mapController('app.organization.key.edit', function (context) {
        var $submitButton, $organizationDropdown, $originURL;
        var keyID, key;

        return {
            actions: {
                save: function (e) {
                    var isLoading = $submitButton.is(':disabled'),
                        $form = $(this);

                    e.preventDefault();

                    if (isLoading) return false;

                    $submitButton.prop('disabled', true);

                    var createKeyData = {
                        ApiAccessKeyID: keyID,
                        Origin: $originURL.val()
                    };

                    ridestyler.ajax.send({
                        action: 'ApiAccessKey/UpdateKey',
                        data: createKeyData,
                        callback: function (response) {
                            if (response.Success) {
                                uifactory.alert.show({
                                    text: "Key updated."
                                });

                                rter.routes.go("/app/organization/key");
                            } else {
                                uifactory.alert.show({
                                    text: "<strong>There was a problem updating the key:</strong> " + response.Message,
                                    type: 'error'
                                });
                            }

                            $submitButton.prop('disabled', false);
                        }
                    });
                }
            },

            viewCreated: function ($view) {
                $submitButton = $view.find('button[type=submit]');
                keyID = rstools.state.get('ApiAccessKeyID');

                var $orgContainer = $view.find('#create-location-key-organization');

                var $org = $('<div/>', { 'class': 'form-control-static loading' }).appendTo($orgContainer);

                var $referrerDefaultRadio = $('#referrer-default').on('ifChecked', function () {
                    $originURL.val('*');
                    $referrerCustomCollapse.removeClass('in');
                });

                var $referrerCustomRadio = $('#referrer-custom').on('ifChecked', function () {
                    $referrerCustomCollapse.addClass('in');
                    $originURL.val(key.ApiAccessKeyOrigin).focus();
                });

                var $referrerCustomCollapse = $('#referrer-custom-collapse');
                $originURL = $('#origin-url');

                ridestyler.ajax.send({
                    action: 'ApiAccessKey/GetKeys',
                    data: {
                        ApiAccessKeyID: keyID
                    },
                    callback: function (response) {
                        if (response.Success && response.Keys.length) {
                            key = response.Keys[0];

                            $org.removeClass('loading').text(key.ApiAccessKey_Organization.OrganizationName);
                            $originURL.val(key.ApiAccessKeyOrigin);

                            if (key.ApiAccessKeyOrigin == '*') {
                                $referrerDefaultRadio.iCheck('check');
                                $referrerCustomRadio.iCheck('uncheck');
                            } else {
                                $referrerDefaultRadio.iCheck('uncheck');
                                $referrerCustomRadio.iCheck('check');
                            }
                        }
                    }
                });


            }
        };
    });
})(jQuery);