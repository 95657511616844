rstools.meta = {
    edit: function (namespace, filters, settings) {
        namespace = rstools.utils.getNamespace(namespace);
        
        if (!namespace.clearMetaAction) throw "Missing clearMetaAction from namespace";
        if (!namespace.setMetaAction) throw "Missing setMetaAction from namespace";
        if (!namespace.getMetaAction) throw "Missing getMetaAction from namespace";

        if (!namespace.utils.getIDs) throw "Missing utils.getIDs from namespace";

        if (!namespace.getActionResponseKey) throw "Missing getActionResponseKey from namespace";
        if (!namespace.getAction) throw "Missing getAction from namespace";
        if (!namespace.countAction) throw "Missing countAction from namespace";

        settings = $.extend({
            header: 'Edit Meta'
        }, settings);

        var modal = uifactory.modal.create({
            header: settings.header,
            cancelable: true,
            startLoading: true
        });

        var getMetaDeferred = $.when(
            rstools.utils.apiDeferred({
                action: namespace.getMetaAction,
                data: filters,
                responseKey: 'Meta'
            }),
            rstools.utils.loadPaginatedList(namespace.getAction, namespace.countAction, namespace.getActionResponseKey, filters)
        );

        modal.show();

        getMetaDeferred
            .then(function (metaLookup, dataObjects) {
                var dataObjectIDs = namespace.utils.getIDs(dataObjects);

                for (var i = 0; i < dataObjectIDs.length; i++) {
                    var id = dataObjectIDs[i];
                    
                    if (id in metaLookup === false)
                        metaLookup[id] = {};
                }

                return metaLookup;
            })
            .then(function (metaLookup) {
                var meta = [];

                for (var id in metaLookup)
                    meta.push(metaLookup[id]);
                
                return rstools.utils.findLikeData(meta, true);
            })
            .done(function (meta) {
                var table = document.createElement('table');

                table.className = 'table';

                var noMetaBody = document.createElement('tbody');
                    noMetaBody.style.display = 'none';

                {
                    var row = document.createElement('tr');
                    var noMetaCell = document.createElement('td');

                    noMetaCell.appendChild(document.createTextNode("No meta found"));
                    noMetaCell.colSpan = 3;

                    row.appendChild(noMetaCell);
                    noMetaBody.appendChild(row);
                }

                var body = document.createElement('tbody');
                var $body = $(body);

                function renderRows (meta) {
                    var tbody = document.createDocumentFragment();

                    var metaKeys = Object.keys(meta);

                    metaKeys.sort();

                    for (var i=0; i < metaKeys.length; i++) {
                        var key = metaKeys[i];
                        var value = meta[key];
                        var hasMultipleValues = false;

                        if (typeof value === 'undefined') {
                            value = '';
                            hasMultipleValues = true;
                        }

                        var row = document.createElement('tr');
                            row.className = 'hover-highlight-row';

                        var keyCell = document.createElement('td');
                        var valueCell = document.createElement('td');
                        var deleteCell = document.createElement('td');

                        // Render key
                        {
                            keyCell.appendChild(document.createTextNode(key));

                            if (hasMultipleValues) {
                                $('<span/>', { 'class': 'text-warning', 'append': uifactory.create.icon('th') })
                                    .css('marginLeft', 5)
                                    .attr('title', 'There are multiple values for this meta key.')
                                    .appendTo(keyCell);
                            }
                        }

                        // Render value
                        {
                            var valueInput = document.createElement('input');
                                valueInput.value = value;
                                valueInput.setAttribute('data-original-value', value);
                                valueInput.setAttribute('data-has-multiple-values', hasMultipleValues);
                                valueInput.name = key;
                                valueInput.className = 'form-control';

                            valueCell.appendChild(valueInput);

                            valueCell.onchange = valueCell.oninput = updateHasChanges;
                        }

                        // Render delete
                        {
                            var deleteButton = document.createElement('button');
                                deleteButton.className = 'btn btn-link primary-link hover-highlight-row-element';
                            
                            var $deleteIcon = uifactory.create.icon('times').appendTo(deleteButton);

                                deleteButton.onclick = function () {
                                    var data = $.extend({}, filters);

                                    data.Keys = [key];

                                    deleteButton.disabled = true;
                                    $deleteIcon.removeClass('fa-times').addClass('fa-spinner fa-spin');

                                    rstools.utils.apiDeferred({
                                        action: namespace.clearMetaAction,
                                        data: data
                                    })
                                        .done(function () {
                                            $deleteIcon.addClass('fa-check').removeClass('fa-spinner fa-spin');

                                            $(row).fadeOut(500, function () {
                                                body.removeChild(row);

                                                if (!body.children.length) setNoMetaFoundMessageVisibility(true);

                                                updateHasChanges();
                                            });
                                        })
                                        .fail(function (response) {
                                            deleteButton.disabled = false;
                                            $deleteIcon.addClass('fa-times').removeClass('fa-spinner fa-spin');

                                            rstools.api.utils.showResponseError('There was an error removing the meta', response);
                                        });
                                };
                            
                            deleteCell.appendChild(deleteButton);
                        }
                        
                        row.appendChild(keyCell);
                        row.appendChild(valueCell);
                        row.appendChild(deleteCell);

                        tbody.appendChild(row);
                    }

                    return tbody;
                }

                var setNoMetaFoundMessageVisibility = function (visible) {
                    noMetaBody.style.display = visible ? '' : 'none';
                    body.style.display = visible ? 'none' : '';
                };

                var hasChanges = false;
                function setHasChanges(changes) {
                    if (hasChanges === changes) return;

                    saveButton.disabled = !changes;

                    hasChanges = changes;
                }

                function updateHasChanges() {
                    var $rows = $body.children();
                    var $newRows = $rows.filter('[data-new=true]');
                    var $existingRows = $rows.not($newRows);

                    // If there is new rows with keys
                    var newRowsWithKeys = false;
                    $newRows.each(function () {
                        var keyCell = this.children[0];
                        var $input = $(keyCell).find('input');

                        if ($input.val().length) {
                            newRowsWithKeys = true;
                            return false; // break out of each
                        }
                    });

                    if (newRowsWithKeys) {
                        setHasChanges(true);
                        return;
                    }

                    // If there is new data
                    var newData = false;
                    $existingRows.each(function () {
                        var $input = $(this).find('input');

                        // If the data has changed
                        if ($input.val() !== $input.attr('data-original-value')) {
                            newData = true;
                            return false; // break out of each
                        }
                    });

                    if (newData) {
                        setHasChanges(true);
                        return;
                    }
                    
                    setHasChanges(false);
                }
                
                // Header
                {
                    var header = document.createElement('thead');
                    var headerRow = document.createElement('tr');
                    var headerElement = function (text, width) {
                        var td = document.createElement('th');

                        if (width)
                            td.width = width;

                        td.appendChild(document.createTextNode(text));
                        return td;
                    };
                    
                    headerRow.appendChild(headerElement('Key', 200));
                    headerRow.appendChild(headerElement('Value'));
                    headerRow.appendChild(headerElement('', 60));

                    header.appendChild(headerRow);
                    table.appendChild(header);
                }

                body.appendChild(renderRows(meta));
                
                table.appendChild(noMetaBody);
                table.appendChild(body);
                
                if (!body.children.length) setNoMetaFoundMessageVisibility(true);

                modal.$body.empty().append(table);
                modal.$modal.removeClass('loading');
                
                var addButton = document.createElement('button');
                    addButton.className = 'btn btn-success pull-left';
                    uifactory.create.icon('plus').appendTo(addButton);
                    addButton.appendChild(document.createTextNode(' Add'));

                    addButton.onclick = function () {
                        var row = document.createElement('tr');
                            row.setAttribute('data-new', true);

                        var keyCell = document.createElement('td');
                        var valueCell = document.createElement('td');
                        var deleteCell = document.createElement('td');

                        var keyInput = document.createElement('input');
                            keyInput.className = 'form-control';
                            keyInput.onchange = function () {
                                valueInput.name = this.value;
                                updateHasChanges();
                            };
                            keyInput.oninput = updateHasChanges;
                        
                        var valueInput = document.createElement('input');
                            valueInput.className = 'form-control';

                        var deleteButton = document.createElement('button');
                            deleteButton.className = 'btn btn-link primary-link hover-highlight-row-element';
                            uifactory.create.icon('times').appendTo(deleteButton);
                            deleteButton.onclick = function () {
                                body.removeChild(row);
                                updateHasChanges();
                                if (!body.children.length) setNoMetaFoundMessageVisibility(true);
                            };

                        keyCell.appendChild(keyInput);
                        valueCell.appendChild(valueInput);
                        deleteCell.appendChild(deleteButton);

                        row.appendChild(keyCell);
                        row.appendChild(valueCell);
                        row.appendChild(deleteCell);

                        body.appendChild(row);

                        setNoMetaFoundMessageVisibility(false);
                        updateHasChanges();
                    };

                var saveButton = document.createElement('button');
                    saveButton.className = 'btn btn-primary';
                    uifactory.create.icon('floppy-o').appendTo(saveButton);
                    saveButton.disabled = true;
                    saveButton.appendChild(document.createTextNode(' Save Meta'));

                    saveButton.onclick = function () {
                        modal.$modal.addClass('loading');

                        var data = $.extend({}, filters);
                        var dataMeta = data.Meta = {};
                        var displayMeta = {};

                        var bodyRows = body.children;

                        for (var i = bodyRows.length - 1; i >= 0; i--) {
                            var rowCells = bodyRows[i];

                            if (rowCells.children.length < 2) continue;

                            var valueCell = rowCells.children[1];
                            var valueInput = valueCell.firstChild;
                            var name = valueInput.name;
                            var value = valueInput.value;
                            var hasMultipleValues = valueInput.getAttribute("data-has-multiple-values") === 'true';

                            if (name) {
                                // If there are multiple values for this key, return it back to the meta we're going to the user
                                // but don't send it to the server
                                if (hasMultipleValues && !value) {
                                    displayMeta[name] = undefined;
                                    continue;
                                }

                                dataMeta[name] = displayMeta[name] = value;
                            }
                        }

                        rstools.utils.apiDeferred({
                            action: namespace.setMetaAction,
                            data: data
                        })
                            .done(function () {
                                while (body.firstChild) body.removeChild(body.firstChild);

                                body.appendChild(renderRows(displayMeta));

                                updateHasChanges();
                            })
                            .fail(function (response) {
                                rstools.api.utils.showResponseError('There was an error saving the meta', response);
                            })
                            .always(function () {
                                modal.$modal.removeClass('loading');
                            });
                    };

                modal.$footer.append([
                    addButton,
                    saveButton
                ]);
            })
            .fail(function (response) {
                modal.hide();
                rstools.api.utils.showResponseError('There was an error getting the meta', response);
            });
    },

    actions: {
        edit: function (namespace) {
            namespace = rstools.utils.getNamespace(namespace);

            if (!namespace.utils.createFilters) throw "Missing utils.createFilters from namespace";

            return {
                title: 'Edit Meta',
                icon: 'fa fa-magnet',
                forMenu: true,
                multiple: true,
                requiredRoles: [rstools.roles.SYSTEM_ADMIN],
                fields: ['actions'],
                callback: function (rows) {
                    rstools.meta.edit(namespace, namespace.utils.createFilters(rows, true), {
                        header: rstools.utils.getTitle(namespace, rows, 'Edit Meta')
                    });
                }
            };
        }
    }
};